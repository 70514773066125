import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { spreadsheetSliceName } from '@/redux/constants';
import { EXPAND_ALL, defaultFilterElement } from './constants';

import { createConditionGroupElement } from './helpers';
import { getColumnsMap } from '@/helpers/filterPanelHelper';

import IfConditionsBlock from './IfConditionsBlock';
import { Button } from '@/Components/UI/Button/Button';
import { IfConditionsTopPanel } from './IfConditionsTopPanel';

const IfConditionsBody = ({
  setConditionalLabelColumn,
  setIsValidConditionForInserting,
  isFetching,
}) => {
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const [filterModel, setFilterModel] = useState([createConditionGroupElement(0)]);
  const [isValidConditions, setIsValidConditions] = useState(true);
  const [newColumnNameState, setNewColumnNameState] = useState('');
  const [elseRowValue, setElseRowValue] = useState('');
  const modalBodyRef = useRef();

  const columns = getColumnsMap(columnDefs);
  const modalBodyScrollTop = modalBodyRef?.current?.scrollTop || 0;
  const totalNumberOfConditionsGroup = filterModel.length;

  const onDeleteConditionsGroup = (groupIndex) => {
    const updatedFilterModel = filterModel.filter((_, i) => {
      return i !== groupIndex;
    });
    setFilterModel(updatedFilterModel);
  };

  const onFilterModelElementChange = (andIndex, newSubModelOrNull, ifGroupIndex) => {
    const updatedFilterModel = newSubModelOrNull
      ? filterModel.map((el, i) => {
          if (i === ifGroupIndex) {
            const updatedElements = el.group.map((e, ind) => {
              return ind === andIndex ? newSubModelOrNull : e;
            });
            return { ...el, group: updatedElements };
          }
          return el;
        })
      : filterModel.map((el, i) => {
          if (i === ifGroupIndex) {
            const updatedElements = el.group.filter((e, ind) => {
              return ind !== andIndex;
            });
            return { ...el, group: updatedElements };
          }
          return el;
        });
    setFilterModel(updatedFilterModel);
  };

  const onChangeFilterType = (andIndex, ifGroupIndex, event) => {
    const isChecked = (e) => (event !== null ? event : !e.isChecked);
    const updatedFilterModel = filterModel.map((el, i) => {
      if (ifGroupIndex === i) {
        const updatedElements = el.group.map((e, index) => {
          return index === andIndex ? { ...e, isChecked: isChecked(e) } : e;
        });
        return { ...el, group: updatedElements };
      }
      return el;
    });

    setFilterModel(updatedFilterModel);
  };

  const addNewFilter = (columnOrUndefined, ifGroupIndex) => {
    const updatedFilterModel = filterModel.map((model, i) => {
      if (i === ifGroupIndex) {
        return { ...model, group: [...model.group, defaultFilterElement] };
      }
      return model;
    });

    setFilterModel(updatedFilterModel);
  };

  const addNewConditionGroup = (id) => {
    setFilterModel((filterModel) => [...filterModel, createConditionGroupElement(id)]);
  };

  const isLastConditionGroupIndex = (index) => {
    const length = totalNumberOfConditionsGroup - 1;
    return index === length;
  };

  const setThenRowValue = (thenValue, ifGroupIndex) => {
    const updatedFilterModel = filterModel.map((el, i) => {
      if (ifGroupIndex === i) {
        return { ...el, thenValue };
      }
      return el;
    });

    setFilterModel(updatedFilterModel);
  };

  const toggleGroupView = (ifGroupIndex) => {
    let updatedFilterModel = null;

    if (typeof ifGroupIndex === 'number') {
      updatedFilterModel = filterModel.map((model, i) => {
        if (i === ifGroupIndex) {
          return { ...model, isExpanded: !model.isExpanded };
        }
        return model;
      });
    } else {
      const action = ifGroupIndex?.target?.innerText;
      updatedFilterModel = filterModel.map((model) => {
        return { ...model, isExpanded: action === EXPAND_ALL };
      });
    }
    setFilterModel(updatedFilterModel);
  };

  const buildConditionalLabelColumnBody = (filterModel, elseRowValue, newColumnNameState) => {
    const filterModelCnf = (model) =>
      model.group.reduce((acc, element) => {
        if (!element.isChecked) {
          return [...acc, [element]];
        } else {
          const accLength = acc.length - 1;
          const updatedAcc = acc.map((el, i) => {
            if (i === accLength) {
              return [...el, element];
            }
            return el;
          });

          return updatedAcc;
        }
      }, []);

    const cases = filterModel.map((el) => {
      return {
        filterModel: {
          _cnf_: filterModelCnf(el),
        },
        label: el.thenValue,
      };
    });

    return {
      cases,
      defaultLabel: elseRowValue,
      outputColumn: newColumnNameState,
    };
  };

  const getIsValidConditions = (data) => {
    if (data) {
      return data.every(({ group, thenValue }) => {
        return group.every(({ colId, filterType, type, filter }) => {
          return (
            colId &&
            filterType &&
            type &&
            thenValue.trim() &&
            newColumnNameState.trim() &&
            (type === 'isBlank' || type === 'isNotBlank'
              ? true
              : Array.isArray(filter)
              ? filter?.length
              : filter)
          );
        });
      });
    }
    return false;
  };

  useEffect(() => {
    if (isFetching) {
      const isValidCondition = getIsValidConditions(filterModel);
      setConditionalLabelColumn(
        buildConditionalLabelColumnBody(filterModel, elseRowValue, newColumnNameState)
      );
      setIsValidConditions(isValidCondition);
      setIsValidConditionForInserting(isValidCondition);
    }
  }, [filterModel, elseRowValue, newColumnNameState, setConditionalLabelColumn, isFetching]);

  return (
    <div>
      <IfConditionsTopPanel
        columnNameValue={newColumnNameState}
        onChangeColumnName={(e) => setNewColumnNameState(e.target.value)}
        onButtonClick={toggleGroupView}
        isValidConditions={isValidConditions}
      />
      <div
        className='p-1 overflow-auto border-t border-b max-h-80 border-ui-200'
        ref={modalBodyRef}
      >
        {filterModel.map((model, i) => {
          return (
            <IfConditionsBlock
              key={model.id}
              ifGroupIndex={i}
              columns={columns}
              filterModel={model.group}
              onChangeFilterType={onChangeFilterType}
              onFilterChange={onFilterModelElementChange}
              setAnyFilterData={() => {}}
              modalHeaderRef={null}
              modalBodyScrollTop={modalBodyScrollTop}
              addNewFilter={addNewFilter}
              thenRowValue={model.thenValue}
              onChangeThenRowValue={setThenRowValue}
              elseRowValue={elseRowValue}
              onChangeElseRowValue={(e) => setElseRowValue(e.target.value)}
              onDeleteConditionsGroup={onDeleteConditionsGroup}
              isLastConditionGroup={isLastConditionGroupIndex(i)}
              isExpanded={model.isExpanded}
              toggleGroupView={toggleGroupView}
              totalNumberOfConditionsGroup={totalNumberOfConditionsGroup}
              isValidConditions={isValidConditions}
            />
          );
        })}
      </div>
      <div className='mt-5'>
        <Button
          color='midnight'
          variant='ghost'
          size='small'
          iconName='plus-circle'
          dataCy='add-btn'
          onClick={() => addNewConditionGroup(filterModel.length)}
        >
          Add Condition Group
        </Button>
      </div>
    </div>
  );
};

export default IfConditionsBody;
