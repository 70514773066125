import React from 'react';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import Icon from '@/Components/UI/Icon/Icon';
import { useSelector } from 'react-redux/es/exports';
import { userDataSliceName } from '@/redux/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { deleteCellComment, deleteColumnComment } from '@/Utils/CommentUtils';
import { setRefreshCommentThreads } from '@/redux/reducers/spreadsheetSlice';
import { useDispatch } from 'react-redux';
import { replaceAtSign } from './helpers';
import { inRelativeTime } from '@/Utils/InRelativeTime/inRelativeTime';
import { format } from 'date-fns';

const CommentEntry = ({
  comment,
  column,
  row,
  isColumnComment,
  getThread,
  index,
  thread,
  autofillOptions,
  readOnly = false,
}) => {
  const { comment_id: commentId, created_at: createdAt, author, comment: commentText } = comment;
  const { user } = useSelector((state) => state[userDataSliceName]);
  const dispatch = useDispatch();
  const handle = getFileUuidFromPath();

  const modifiedString = replaceAtSign({ autofillOptions, commentText });

  const deleteComment = () => {
    try {
      const isLastCommentOnThread = thread?.length === 1;
      if (isColumnComment) {
        deleteColumnComment(handle, column, commentId).then(() => {
          getThread();
        });
      } else {
        deleteCellComment(handle, column, row, commentId).then(() => {
          getThread();
        });
      }
      if (isLastCommentOnThread) {
        dispatch(setRefreshCommentThreads(true));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div data-cy='comment-entry' className='pb-2 pl-4'>
      <div className='flex justify-between px-4'>
        <div
          data-cy={`comment-author-${index}`}
          className='text-[13px] font-semibold pb-1 break-all'
        >
          {author}
        </div>
        {readOnly ? null : (
          <div
            role='button'
            tabIndex={0}
            onKeyDown={() => null}
            hidden={author !== user.email}
            onClick={deleteComment}
            data-cy={`delete-comment-btn-${index}`}
          >
            <Tooltip text='Delete comment' side='bottom'>
              <div>
                <Icon name='trash' size={14} />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div data-cy={`comment-text-${index}`} className='text-[13px] text-ui pl-4'>
        {modifiedString.map((part, i) => (
          <React.Fragment key={i}>{part}</React.Fragment>
        ))}
      </div>
      <Tooltip text={format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')} side='bottom'>
        <div className='text-[10px] font-extrabold text-ui-helper pl-4 pt-1'>
          {inRelativeTime(createdAt)}
        </div>
      </Tooltip>
    </div>
  );
};

export default CommentEntry;
