import { useEffect, useState } from 'react';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const withFilterPanel = (FilterPanel) => (ownProps) => {
  const { filterModel, onFilterChange, index, filterModelElement, ifGroupIndex } = ownProps;
  const { type: filterType } = filterModelElement;

  const [isMultiValueEnabled, setIsMultiValueEnabled] = useState(false);

  const blankConditions =
    filterType && (filterType.includes('Blank') || filterType.includes('ternal'));

  const filterOptions = createFilterOptions({
    stringify: (option) => option.name || option.colId || option.value,
  });

  const onRemove = () => {
    if (filterModel.length === 1) return onFilterChange(index, [], ifGroupIndex);
    onFilterChange(index, null, ifGroupIndex);
  };

  useEffect(() => {
    if (!filterType) return;

    setIsMultiValueEnabled(filterType.includes('Any'));
  }, [filterType]);

  return (
    <FilterPanel
      {...ownProps}
      isMultiValueEnabled={isMultiValueEnabled}
      onRemove={onRemove}
      blankConditions={blankConditions}
      filterOptions={filterOptions}
    />
  );
};

export default withFilterPanel;
