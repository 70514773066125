import { ROUTE_SETTINGS } from '@/Utils/constants';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Button } from '../UI/Button/Button';
import Icon from '../UI/Icon/Icon';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { useDispatch } from 'react-redux';

const UserDropdownMenu = ({
  user,
  isMobileExperienceActive,
  isMobileScreen,
  setMobileExperience,
  signOut,
}) => {
  const dispatch = useDispatch();

  const handleSettingClick = () => {
    !location.pathname.includes('settings') &&
      dispatch(
        addMixpanelEvent({
          eventName: 'Settings page',
          eventProps: {},
          userIncrementName: '# of visits',
        })
      );
  };

  return (
    <ul>
      <li className='!text-ui text-[13px] font-semibold'>
        <div className='flex items-center h-8 px-2'>
          {user.org_id && (
            <span id='teams-icon' className='w-4 mr-2 '>
              <Icon dataCy='teams-nav-icon' name='users-three' size={16} alt='team icon' />
            </span>
          )}
          <span id='profileName' className='truncate'>
            {user.email}
          </span>
        </div>
      </li>
      <hr className='my-1 bg-gray' />
      <li>
        <Link
          className={clsx(
            '!flex !justify-start  !text-ui text-[13px] !w-full !p-2 !rounded h-8 hover:bg-midnight-300 hover:!text-white !outline-none !ring-0',
            isMobileScreen && 'hidden'
          )}
          color='transparent'
          to={ROUTE_SETTINGS}
          onClick={handleSettingClick}
        >
          <div className='flex items-center'>
            <span className='w-4 mr-2' data-cy='navbar-settings-btn'>
              <Icon size={16} name='gear' />
            </span>
            Settings
          </div>
        </Link>
      </li>
      {isMobileScreen && !isMobileExperienceActive && (
        <li>
          <Button
            className='!flex !justify-start  !text-ui text-[13px] !w-full !px-2 hover:bg-midnight-300 hover:!text-white !outline-none !ring-0'
            color='transparent'
            onClick={() => setMobileExperience('true')}
          >
            <div className='flex'>
              <span className='w-4 mr-2'>
                <Icon size={16} name='device-mobile' />
              </span>
              Switch to Mobile View
            </div>
          </Button>
        </li>
      )}
      <li>
        <Button
          className='!flex !justify-start  !text-ui text-[13px] !w-full !px-2 hover:bg-midnight-300 hover:!text-white !outline-none !ring-0'
          color='transparent'
          onClick={signOut}
          dataCy='navbar-logout-btn'
        >
          <div className='flex'>
            <span className='w-4 mr-2'>
              <Icon size={16} name='sign-out' />
            </span>
            Sign Out
          </div>
        </Button>
      </li>
    </ul>
  );
};

export default UserDropdownMenu;
