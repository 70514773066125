import { FILE_TYPE_EXPORTER, FILE_STATUS_ERROR } from '@/Utils/fileConstants';
import { functionsSVG, fileTypeSVG } from '@/Assets/icons';
import { format } from 'date-fns';
import { datasetSliceName } from '@/redux/constants';
import { useSelector } from 'react-redux';
import Icon from '@/Components/UI/Icon/Icon';
import { isWorldReadable, isSharedByMe } from '@/Utils/sharing';
import { PUBLIC_LABEL } from '@/Utils/constants';

const MobileFileNameDetail = ({
  isFolder,
  row,
  fileSize,
  columns,
  rowCount,
  isSharedParentFolder,
}) => {
  const { sortingData } = useSelector((state) => state[datasetSliceName]);
  const { columnName } = sortingData;
  const { metadata } = row;

  const isPublic =
    isSharedByMe(row) || isWorldReadable(row) || (isSharedParentFolder && !row.metadata.Type);

  const RowInfo = () => {
    return (
      <p className='text-[10px] leading-[14px] font-extrabold tracking-wider uppercase text-ui-helper px-2 gap-4'>
        {(columnName === 'LastUpdated' || columnName === '' || columnName === 'FileName') && (
          <>
            <span data-cy={`mobile-descr-text-${metadata.FileName}`}>Modified</span>{' '}
            <span data-cy={`mobile-descr-value-${metadata.FileName}`}>
              {format(new Date(metadata.LastUpdated), 'MMM d, yyyy')}
            </span>{' '}
          </>
        )}
        {columnName === 'FileSize' && (
          <>
            <span data-cy={`mobile-descr-text-${metadata.FileName}`}>Size</span>{' '}
            <span data-cy={`mobile-descr-value-${metadata.FileName}`}>{fileSize}</span>
          </>
        )}
        {columnName === 'FileRows' && (
          <>
            <span data-cy={`mobile-descr-text-${metadata.FileName}`}>Rows</span>{' '}
            <span data-cy={`mobile-descr-value-${metadata.FileName}`}>{rowCount}</span>
          </>
        )}
        {columnName === 'FileColumns' && (
          <>
            <span data-cy={`mobile-descr-text-${metadata.FileName}`}>Columns</span>{' '}
            <span data-cy={`mobile-descr-value-${metadata.FileName}`}>{columns}</span>
          </>
        )}
        {columnName === 'Owner' && (
          <>
            <span data-cy={`mobile-descr-text-${metadata.FileName}`}>Owner</span>{' '}
            <span data-cy={`mobile-descr-value-${metadata.FileName}`}>{metadata.Owner}</span>
          </>
        )}
      </p>
    );
  };

  const getFileTypeIcon = () => {
    switch (metadata.Type) {
      case FILE_TYPE_EXPORTER:
        return fileTypeSVG.ExporterIcon;
      default:
        return fileTypeSVG.OmnivoreIcon;
    }
  };
  const renderFolderTitle = () => {
    let folderClassName = fileTypeSVG.FolderIcon;
    if (isPublic) {
      folderClassName = fileTypeSVG.SharedFolderIcon;
    }
    return (
      <>
        <img alt='icon' src={folderClassName} className='inline-block' />
        <div className='flex flex-col px-1 w-full min-w-[270px]  max-w-[310px]'>
          <p
            data-testid='mobile-all-rows'
            data-cy={`mobile_tr_${metadata.FileName}_processed`}
            className='inline-block text-sm font-semibold leading-[18px] text-ui px-2 overflow-hidden overflow-ellipsis whitespace-nowrap'
          >
            {metadata.FileName}
          </p>
          <RowInfo />
        </div>
      </>
    );
  };

  const renderFileTitle = () =>
    metadata.ParentDirectory === '' || metadata.ParentDirectory === undefined ? (
      <>
        <img
          alt='icon'
          src={metadata.Status === FILE_STATUS_ERROR ? fileTypeSVG.ErrorIcon : getFileTypeIcon()}
          className='inline-block'
        />
        <div className='flex flex-col px-1 w-full min-w-[270px]  max-w-[310px]'>
          <p
            data-testid='mobile-all-rows'
            className='inline-block text-sm font-semibold leading-[18px] text-ui px-2 overflow-hidden overflow-ellipsis whitespace-nowrap'
            data-cy={
              'mobile_tr_' +
              metadata.FileName +
              '_' +
              (fileSize === 'Loading' ? 'processing' : 'processed')
            }
          >
            {metadata.FileName}
          </p>
          <RowInfo />
        </div>
        <div className='w-4'>
          {isPublic ? (
            isWorldReadable(row) || isSharedParentFolder === PUBLIC_LABEL ? (
              <Icon dataCy='globe' name='globe' size={16} alt='public file' />
            ) : (
              <Icon dataCy='users' name='users' size={16} alt='public file' />
            )
          ) : null}
        </div>
        {metadata.ThreatDetectionStatus?.detected_row_count > 0 && (
          <div className='ico-indicator'>
            <img
              src={
                metadata.ThreatDetectionStatus.status === 'applied'
                  ? functionsSVG.IoCGrayIcon
                  : functionsSVG.IoCIcon
              }
              alt=''
              className='ioc-icon'
            />
            <span>{metadata.ThreatDetectionStatus.detected_row_count} rows with known IOCs</span>
          </div>
        )}
      </>
    ) : (
      <>
        <img
          alt=''
          src={metadata.Status === FILE_STATUS_ERROR ? fileTypeSVG.ErrorIcon : getFileTypeIcon()}
        />
        <div className='flex flex-col px-1 w-full min-w-[270px]  max-w-[310px]'>
          <p
            data-testid='mobile-all-rows'
            data-cy={`mobile_tr_${metadata.FileName}_processed`}
            className='inline-block text-sm font-semibold leading-[18px] text-ui px-2 overflow-hidden overflow-ellipsis whitespace-nowrap'
          >
            {metadata.FileName}
          </p>
          <RowInfo />
        </div>
        <div className='w-4'>
          {isPublic ? (
            isWorldReadable(row) || isSharedParentFolder === PUBLIC_LABEL ? (
              <Icon dataCy='globe' name='globe' size={16} alt='public file' />
            ) : (
              <Icon dataCy='users' name='users' size={16} alt='public file' />
            )
          ) : null}
        </div>
      </>
    );

  return (
    <div className='flex items-center'>{isFolder ? renderFolderTitle() : renderFileTitle()}</div>
  );
};

export default MobileFileNameDetail;
