import { useSelector } from 'react-redux';

import { Button } from '@/Components/UI/Button/Button';

import { spreadsheetSliceName } from '@/redux/constants';

import { FILTER_MODEL_AGGREGATE_CNF_, FILTER_MODEL_CNF_ } from '@/Utils/constants';
import Icon from '@/Components/UI/Icon/Icon';
import clsx from 'clsx';

const FilterPanelTypeButtons = ({
  switchFilterModel,
  activePanel,
  canUserUseAggregateFilter = false,
}) => {
  const {
    clientState: { columnState },
  } = useSelector((state) => state[spreadsheetSliceName]);

  const isGroupedColumns = columnState.length && columnState.find(({ rowGroup }) => rowGroup);
  const isFilterModelCnf = activePanel === FILTER_MODEL_CNF_;
  const isFilterModelAggregationsCnf = activePanel === FILTER_MODEL_AGGREGATE_CNF_;

  return (
    <div className='filter-pane-type-buttons !min-w-fit px-2 gap-6'>
      <Button
        dataCy='filter-rows'
        variant={isFilterModelCnf ? 'solid' : 'ghost'}
        color={isFilterModelCnf ? 'oceanBlue' : 'shadow'}
        size='small'
        onClick={() => switchFilterModel(FILTER_MODEL_CNF_)}
      >
        <span
          className={clsx(
            'flex items-center !text-xs  !font-semibold',
            isFilterModelCnf ? '!text-white' : '!text-ui-secondary'
          )}
        >
          <Icon name='rows' className='mr-1' size={16} />
          Rows
        </span>
      </Button>
      <Button
        dataCy='filter-groups'
        variant={isFilterModelAggregationsCnf ? 'solid' : 'ghost'}
        color={isFilterModelAggregationsCnf ? 'oceanBlue' : 'shadow'}
        size='small'
        onClick={() => switchFilterModel(FILTER_MODEL_AGGREGATE_CNF_)}
        disabled={!isGroupedColumns}
      >
        <span
          className={clsx(
            'flex items-center !text-xs  !font-semibold',
            isFilterModelAggregationsCnf ? '!text-white' : '!text-ui-secondary'
          )}
        >
          <Icon name='group' className='mr-1' size={16} />
          Groups
          {!canUserUseAggregateFilter && (
            <Icon name='rocket-launch' color='#D9B500' className='ml-1' />
          )}
        </span>
      </Button>
    </div>
  );
};
export default FilterPanelTypeButtons;
