import React from 'react';
import clsx from 'clsx';
import { commonSVG } from '@/Assets/icons';

const LogoLink = React.memo(({ className, logoLink, isIframe }) => (
  <div data-cy='logo-link' className={clsx('w-28', className)}>
    <a href={logoLink} target={isIframe ? '_parent' : '_self'}>
      <img src={commonSVG.LogoWithTextPink} className='navbar__logo' alt={''} />
    </a>
  </div>
));
export default LogoLink;
