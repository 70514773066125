const parseArrayToMatrix = (filterModel) => {
  const groupedArray = [];
  //grouping data in format [ [ {...}, {...}, ... ], [...], [...], ... ] to send on backend
  filterModel
    .filter((e) => !(e.colId === null || e.colId === undefined || e.type === null))
    .forEach((filterModelElement) => {
      // eslint-disable-next-line
      const { isChecked, isValidRegex, ...rest } = filterModelElement;
      if (filterModelElement.isChecked) {
        groupedArray[groupedArray.length - 1].push(rest);
      } else {
        groupedArray.push([rest]);
      }
    });
  return groupedArray;
};
export default parseArrayToMatrix;
