export const initialUserProperties = {
  isPaying: false,
  maxStorageSize: '1GB',
  canExport: false,
  maxFileSize: '1GB',
  hasBillingId: false,
  maxPageSize: '100',
  maxSavedFilters: '1',
  maxFilters: '3',
  useFindReplaceSettings: false,
  canAggregateFilter: false,
  canSplitSheet: false,
  maxFileRows: Infinity,
  canExportFilteredGrouped: false,
  canUseAuditLog: false,
  canLivesharing: false,
  canUseBiTrial: false,
  canScheduleConnectors: false,
  canAccessBusinessConnectors: false,
  canAccessEnterpriseConnectors: false,
  canUseEmbedSheet: false,
};

export const dynamicUserRoles = [
  {
    staticPart: 'max-file-size-gb-',
  },
  { staticPart: 'max-total-storage-gb-' },
  { staticPart: 'max-page-size-' },
  { staticPart: 'max-saved-filters-' },
  { staticPart: 'max-filters-' },
  { staticPart: 'max-file-rows-' },
];

export const userPriorities = ['max-file-size-gb-', 'max-total-storage-gb-', 'gigasheet-support'];

export const userPropertiesMap = {
  'giga-feature-embed-sheet': (userProperties) => {
    userProperties.canUseEmbedSheet = true;
    return userProperties;
  },
  'is-paying': (userProperties) => {
    userProperties.isPaying = true;
    return userProperties;
  },
  'giga-feature-bi_trial': (userProperties) => {
    userProperties.canUseBiTrial = true;
    return userProperties;
  },
  'giga-feature-liveshare': (userProperties) => {
    userProperties.canLivesharing = true;
    return userProperties;
  },
  'giga-feature-connector-schedule': (userProperties) => {
    userProperties.canScheduleConnectors = true;
    return userProperties;
  },
  'giga-feature-connectors-business': (userProperties) => {
    userProperties.canAccessBusinessConnectors = true;
    return userProperties;
  },
  'giga-feature-connectors-enterprise': (userProperties) => {
    userProperties.canAccessEnterpriseConnectors = true;
    return userProperties;
  },
  'can-export-filtered-grouped': (userProperties) => {
    userProperties.canExportFilteredGrouped = true;
    return userProperties;
  },
  'max-file-rows-': (userProperties, { value }) => {
    userProperties.maxFileRows = `${value}`;
    return userProperties;
  },
  'can-aggregate-filter': (userProperties) => {
    userProperties.canAggregateFilter = true;
    return userProperties;
  },
  'can-split-sheet': (userProperties) => {
    userProperties.canSplitSheet = true;
    return userProperties;
  },
  'max-filters-': (userProperties, { value }) => {
    userProperties.maxFilters = `${value}`;
    return userProperties;
  },
  'max-page-size-': (userProperties, { value }) => {
    userProperties.maxPageSize = `${value}`;
    return userProperties;
  },
  'max-saved-filters-': (userProperties, { value }) => {
    userProperties.maxSavedFilters = `${value}`;
    return userProperties;
  },
  'find-and-replace-all-settings': (userProperties) => {
    userProperties.useFindReplaceSettings = true;
    return userProperties;
  },
  'can-export': (userProperties) => {
    userProperties.canExport = true;
    return userProperties;
  },
  'giga-feature-auditlog': (userProperties) => {
    userProperties.canUseAuditLog = true;
    return userProperties;
  },
  'gigasheet-support': (userProperties) => {
    userProperties.maxStorageSize = '2TB';
    userProperties.maxFileSize = '1TB';
    userProperties.canExport = true;
    return userProperties;
  },
  'max-file-size-gb-': (userProperties, { value }) => {
    userProperties.maxFileSize = `${value}GB`;
    return userProperties;
  },
  'max-total-storage-gb-': (userProperties, { value }) => {
    userProperties.maxStorageSize = `${value}GB`;
    return userProperties;
  },
  'test-group-A': (userProperties) => {
    userProperties.testGroupA = true;
    return userProperties;
  },
  'test-group-B': (userProperties) => {
    userProperties.testGroupB = true;
    return userProperties;
  },
};
