import { useDispatch, useSelector } from 'react-redux';

import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import Label from '@/Components/UI/Form/Label/Label';

import { useFunctionModal } from '@/hooks/useFunctionModal';

import { spreadsheetSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  MODAL_EXPLODE_JSON,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import '../style/index.scss';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ExplodeJsonPopup = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const { options, setTargetColumn, targetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: ({ type }) => type === 'String',
  });

  const closeExplodeJsonPopup = () => {
    hideModal();
    setTargetColumn(null);
  };

  const handleExplodeJson = async () => {
    const uuid = getFileUuidFromPath();
    const endpoint = `explode-json/${uuid}/${targetColumn.value}`;
    const payload = {};
    const response = await post(endpoint, payload, true);

    if (response && response?.Success) {
      await dispatch(fetchOpenedFile(uuid));
    } else if (response.Message === 'Failed to apply function') {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: 'No JSON detected',
        errorContext: response,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
        errorContext: response,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const explodeJson = async () => {
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      handleExplodeJson();

      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Explode Json' },
          userIncrementName: '# of function use',
        })
      );
      setTargetColumn(null);
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  return (
    <Modal
      iconName='brackets-curly'
      title='Explode Json'
      articleID='69000837761'
      isOpen={show}
      onClose={() => closeExplodeJsonPopup()}
    >
      <div className='h-30'>
        <Label text='Choose column' />
        <div className='mb-4'>
          <SearchSelect
            options={options}
            onChange={(_targetColumn) => setTargetColumn(_targetColumn)}
            defaultValue={targetColumn}
          />
        </div>

        <div className='flex justify-end gap-4'>
          <Button
            color='shadow'
            variant='ghost'
            dataCy='cancel-btn'
            onClick={closeExplodeJsonPopup}
          >
            Cancel
          </Button>
          <Button dataCy='save-btn' color='oceanBlue' onClick={explodeJson}>
            Apply
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_EXPLODE_JSON })(ExplodeJsonPopup);
