import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteDuplicates from './DeleteDuplicates';
import withModal from '@/Components/Modals/withModalHOC';
import ShowToast from '@/Components/Toast/showToastTemplate';

import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';

import { spreadsheetSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';

import { postRaw } from '@/Utils/API';
import {
  DEDUPLICATE_ROW_COUNT,
  MODAL_DELETE_DUPLICATES,
  MODAL_DELETE_DUPLICATES_CONFIRM,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TEXT_NO_DUPLICATE_VALUES_INFO,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  URL_DATASET,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';

const DeleteDuplicatesModal = ({ show, hideModal }) => {
  const dispatch = useDispatch();

  const {
    columnDefs,
    clientState: { sortModel },
  } = useSelector((state) => state[spreadsheetSliceName]);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkForDuplicates = async () => {
    setIsLoading(true);
    const uuid = getFileUuidFromPath();
    const getDuplicateRowCountResponse = await postRaw(
      `${URL_DATASET}/${uuid}/${DEDUPLICATE_ROW_COUNT}`,
      {
        columns: selectedColumns,
        sortModel: sortModel,
      }
    );

    if (getDuplicateRowCountResponse.status === 200) {
      return getDuplicateRowCountResponse.json();
    } else {
      return {
        deletedRowsCount: -1,
        remainingRowsCount: -1,
      };
    }
  };

  const save = async () => {
    try {
      let response = {
        deletedRowsCount: -1,
        remainingRowsCount: -1,
      };
      try {
        response = await checkForDuplicates();
      } catch (e) {
        console.error(e);
      }

      if (response?.deletedRowsCount === 0) {
        ShowToast({ type: TOAST_TYPE_INFO, text: TOAST_TEXT_NO_DUPLICATE_VALUES_INFO });
      } else {
        const failedToCountDuplicates = response?.deletedRowsCount === -1;
        const { deletedRowsCount, remainingRowsCount } = response;
        dispatch(
          showModal({
            name: MODAL_DELETE_DUPLICATES_CONFIRM,
            props: {
              deduplicateColumns: selectedColumns,
              duplicatesCount: deletedRowsCount,
              remainingRowsCount: remainingRowsCount,
              failedToCountDuplicates: failedToCountDuplicates,
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setSelectedColumns([]);
      setIsLoading(false);
      hideModal();
    }
  };

  const cancel = () => {
    setSelectedColumns([]);
    hideModal();
  };

  const allColumns = useMemo(() => {
    return columnDefs
      .filter((col) => col.colId !== ROW_NUMBER_COLUMN_ID)
      .map((colDef) => ({
        value: colDef.field,
        label: colDef.headerName,
      }));
  }, [columnDefs]);

  const selectButtons = [
    {
      name: 'Select All',
      callback: () => setSelectedColumns(allColumns.map((column) => column.value)),
      cypressAttribute: 'select-all-btn',
    },
    {
      name: 'Unselect All',
      callback: () => setSelectedColumns([]),
      cypressAttribute: 'unselect-all-btn',
    },
  ];

  const toggleColumnSelection = ({ target: { value, checked } }) => {
    checked
      ? setSelectedColumns([...selectedColumns, value])
      : setSelectedColumns(selectedColumns.filter((column) => column !== value));
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title='Remove Duplicates'
      articleID='69000831161'
      iconName='eraser'
      size='medium'
    >
      <div data-cy='delete-duplicates-container' className='delete-duplicates-container'>
        {isLoading ? (
          <SkeletonLoader header={false} loadingBars={2} />
        ) : (
          <DeleteDuplicates
            toggleColumnSelection={toggleColumnSelection}
            allColumns={allColumns}
            selectedColumns={selectedColumns}
            selectButtons={selectButtons}
          />
        )}
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={cancel} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={save}
          disabled={!selectedColumns.length || isLoading}
          dataCy='save-btn'
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_DUPLICATES })(DeleteDuplicatesModal);
