import ContactSupportButton from '@/Components/Modals/UpgradeAccount/ContactSupportButton';
import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import HelperText from '@/Components/UI/HelperText/HelperText';
import { hideModal, showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { get, post } from '@/Utils/API';
import {
  API_KEY_REQUEST,
  DEFAULT_UPGRADE_SIZE,
  LINK_GETTING_STARTED,
  LINK_PRICING_PREMIUM,
  MODAL_IMPORT_FROM_CONNECTOR,
  MODAL_UPGRADE_ACCOUNT,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { openInNewTab } from '@/Utils/utils';

import { useDispatch } from 'react-redux';
import { platformConnectors } from '@/pages/DatasetPage/HeaderControlGroup/connectors/platform-connectors';

export const getChargeBeePlans = async () => {
  return await get('billing/plans').then((plans) => {
    const formattedPlans = Object.entries(plans).map(([, value]) => {
      return {
        maxStorageSize: value.Item.name.match(/(\d+GB)/)[1],
        maxFileSize: value.Item.name.match(/(\d+GB)/)[1],
        prices: value.Prices.reduce((acc, priceItem) => {
          return { ...acc, [priceItem.period_unit]: `$${priceItem.price / 100}` };
        }, {}),
      };
    });

    formattedPlans.push({
      maxStorageSize: '100GB-2TB',
      maxFileSize: '25GB-250GB',
      prices: {
        month: 'Custom',
        year: 'Custom',
      },
    });

    return formattedPlans;
  });
};

export const RequestAPIKey = () => {
  const dispatch = useDispatch();

  const onContactSaleButtonClick = async () => {
    dispatch(
      addMixpanelEvent({
        eventName: 'API key request ("Contact Sales" button)',
        eventProps: {},
        userIncrementName: '# of button click',
      })
    );

    const response = await post(API_KEY_REQUEST);

    if (response.Success) {
      dispatch(hideModal({ name: MODAL_UPGRADE_ACCOUNT }));
      dispatch(
        showModal({
          name: MODAL_IMPORT_FROM_CONNECTOR,
          props: {
            title: 'Connect to Platform',
            connectors: platformConnectors,
          },
        })
      );
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: response,
        endpoint: API_KEY_REQUEST,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };
  return (
    <div className='upgrade-export'>
      <div className='upgrade-export__header'>
        <h3 className='mb-3'>Request API Access</h3>
        <p className='!text-xs'>
          The Gigasheet API requires an Enterprise plan.
          <a
            className='!text-xs !font-semibold'
            href={LINK_GETTING_STARTED}
            target='_blank'
            rel='noopener noreferrer'
          >
            {' Check out the docs '}
          </a>
          and when you’re ready to talk let us know.
        </p>
      </div>
      <div className='upgrade-export__action'>
        <Button onClick={onContactSaleButtonClick} color='sunrise'>
          Contact Sales
        </Button>
      </div>
    </div>
  );
};

export const UpgradeStorage = ({ hideModal }) => {
  return (
    <div className='upgrade-storage'>
      <div className='upgrade-storage__header'>
        <h3>You could use more space</h3>
        <p>Premium plans start at just $95 per month.</p>
      </div>

      <div className='upgrade-storage__action'>
        <UpgradeButton hideModal={hideModal} className='px-4 py-4' text='Upgrade Today' />
      </div>
    </div>
  );
};

export const UpgradeCheckout = () => {
  return (
    <div className='upgrade-checkout'>
      <div className='upgrade-checkout__header'>
        <h3>Need more storage?</h3>
        <p>Upgrade your account to upload bigger files</p>
      </div>

      <div className='upgrade-checkout__action'>
        <ContactSupportButton />
      </div>
    </div>
  );
};

export const UpgradeEnterprise = () => {
  return (
    <div className='upgrade-enterprise'>
      <div className='upgrade-enterprise__header'>
        <h3>Need a Power Boost?</h3>
        <p>Upload files up to 500GB+ with an enterprise plan</p>
      </div>

      <div className='upgrade-enterprise__action'>
        <ContactSupportButton />
      </div>
    </div>
  );
};

export const ContactSupportBody = ({ closeToast }) => (
  <span>
    Unable to process request. Please{' '}
    <a
      href='mailto:sales@gigasheet.com?subject=Tell me more about Gigasheet Enterprise!'
      onClick={closeToast}
    >
      contact support team
    </a>{' '}
    for more information.
  </span>
);

export const openChargeBeeUpgrade = (email = '', upgradeSize = DEFAULT_UPGRADE_SIZE) => {
  const { maxStorageSize, billingPeriod } = upgradeSize;
  const convertedBillingPeriod = () => {
    switch (billingPeriod) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Yearly';
      default:
        return 'Monthly';
    }
  };

  const cbInstance = window.Chargebee.init({
    site: 'gigasheet',
    isItemsModel: true,
  });

  const cart = cbInstance.getCart();
  const customer = {
    email,
  };

  cart.setCustomer(customer);
  const product = cbInstance.initializeProduct(
    `2023-05-Premium-${maxStorageSize}-USD-${convertedBillingPeriod()}`
  );

  cart.replaceProduct(product);
  cart.proceedToCheckout();
};

export const openChargeBeeManageBilling = () => {
  const cbInstance = window.Chargebee.init({
    site: 'gigasheet',
    isItemsModel: true,
  });
  const cbPortal = cbInstance.createChargebeePortal();
  cbPortal.open();
};

export const TemporaryLabel = () => {
  const openUpgradeModal = () => {
    openInNewTab(LINK_PRICING_PREMIUM);
  };

  return (
    <div role='button' tabIndex={0} onKeyDown={() => null} as='button' onClick={openUpgradeModal}>
      <HelperText className='cursor-pointer' text='Temporary' color='shadow' />
    </div>
  );
};
