import LogoWhiteIcon from './logo-white.svg';
import LogoFullIcon from './logo-gigasheet.svg';
import LogoWithTextPink from './logo-with-text-pink.svg';
import IoCIcon from './ioc.svg';
import IoCGrayIcon from './ioc-gray.svg';
import CalendarIcon from './calendar.svg';
import OfficeFileIcon from './office-file.svg';
import FileIcon from './file.svg';
import FileBlackIcon from './file-black.svg';
import FlagQuestion from './flag-question.svg';
import FileLookup from './file-lookup.svg';
import NuclearIcon from './nuclear.svg';
import ChevronRight from './chevron-right.svg';
import ChevronRightBlack from './chevron-right-black.svg';
import CloseCircle from './close-circle.svg';
import PlusIcon from './plus.svg';
import PlusBlueIcon from './plus-blue.svg';
import InfoIcon from './info.svg';
import LeftArrowIcon from './leftArrow.svg';
import RightArrowIcon from './rightArrow.svg';
import ChevronDown from './chevron-down.svg';
import ChevronDownBlack from './chevron-down-black.svg';
import ChevronDownGrey from './chevron-down-grey.svg';
import LockIcon from './lock.svg';
import UnlockIcon from './unlock.svg';
import ShareFile from './share-file.svg';
import SearchLightBlueIcon from './search-light-blue.svg';
import FilterToOnlyIcon from './filter-to-only.svg';
import FilterToExcludeIcon from './filter-to-exclude.svg';
import ChatTextIcon from './chat-text.svg';
import CheckboxDefaultIcon from './checkbox-default.svg';
import FlagIcon from './flag.svg';
import FlagBlackIcon from './flag-black.svg';
import FlagGreenIcon from './flag-green.svg';
import FlagYellowIcon from './flag-yellow.svg';
import FlagVioletIcon from './flag-violet.svg';
import FlagBlueIcon from './flag-blue.svg';
import GoodFlagIcon from './flag-good.svg';
import SuspiciousFlagIcon from './flag-suspicious.svg';
import BadFlagIcon from './flag-bad.svg';
import FlagCheckIcon from './flag-check.svg';
import PublicFileIcon from './public-file.svg';
import CloudIcon from './one-drive.svg';
import ChatIcon from './chat.svg';
import ArchiveIcon from './archive.svg';
import QuestionMarkIcon from './question-mark.svg';
import UploadGreyIcon from './upload-grey.svg';
import NumbersLinkedList from './numbers-linked-list.svg';
import Cross from './cross_tool_panel.svg';
import ChevronLeftBlue from './arrow-left.svg';
import ExpandIcon from './expand.svg';
import RegexSearch from './regex-search.svg';
import RegexSearchActive from './regex-search-active.svg';
import HelpMark from './question-circle-dark-gray.svg';
import ChangeDataTypeBlackIcon from './change-data-type-black.svg';
import SideColumnsIcon from './side-columns.svg';
import SideBarExpandIcon from './left-sidebar-expand.svg';
import EmptyFilesIcon from './empty-files.svg';
import CombineFilesIcon from './combine.svg';
import FolderBlueIcon from './folder-blue.svg';
import TrashBlueIcon from './trash-blue.svg';
import TaskListIcon from './task-list.svg';
import FileGreyIcon from './file-grey.svg';
import TrashcanGrayIcon from './trashcan-gray.svg';
import GearIcon from './gear.svg';
import LoadingIcon from './loading.svg';
import ToastClose from './toast-close.svg';
import ToastInfo from './toast-info.svg';
import ToastSuccess from './toast-success.svg';
import ToastReconnection from './toast-reconnection.svg';
import ToastError from './toast-error.svg';
import DataConnectors from './data-connectors.svg';
import RequestedConnectors from './requested-connectors.svg';
import Rocket from './rocket.svg';
import StepBackward from './step-backward.svg';
import ChevronLeft from './chevron-left.svg';
import AlertIcon from './alert-icon.svg';
import WarningOrange from './warning-orange.svg';
import QuestionMarkGray from './question-circle-gray.svg';
import SearchWhite from './search-white.svg';
import NewFolder from './add-new-folder.svg';
import SmallFileIcon from './small-file_icon.svg';
import SmallFolderIcon from './folder_1.svg';
import PublicFileBlackIcon from './public-file-black.svg';
import DeleteRowIcon from './delete-column.svg';
import UnixTimeIcon from './unix-time.svg';
import ArrowDown from './arrow-down.svg';
import KeyIcon from './key.svg';
import CopyBlueIcon from './copy-blue.svg';
import NextStep from './arrow-next-step.svg';
import StepToTheEnd from './arrow-step-to-the-end.svg';
import PoweredByStripe from './powered_by_stripe.svg';

//Illustrations
import CreateAccountIllustration from './Illustrations/create-account_illustration.svg';
import Planet from './planet.svg';
import Teams from './teams.svg';

// Dataset Icons
import AddNewIcon from './Dataset_Icons/add-new_icon.svg';
import AddNewFolderIcon from './Dataset_Icons/add-new-folder_icon.svg';
import CopyIcon from './Dataset_Icons/copy.svg';
import PasteIcon from './Dataset_Icons/paste.svg';
import RenameIcon from './Dataset_Icons/rename_icon.svg';
import MoveToIcon from './Dataset_Icons/move-to_icon.svg';
import DeleteIcon from './Dataset_Icons/delete_icon.svg';
import NavigateBack from './Dataset_Icons/navigate-back_icon.svg';
import ErrorStatusIcon from './Dataset_Icons/error_status_icon.svg';
import ExpandFolderIcon from './Dataset_Icons/expand-folder_icon.svg';

// Toolbar Icons
import DeleteRowsIcon from './Toolbar_Icons/icon_delete-row.svg';
import DeleteRowsDisabledIcon from './Toolbar_Icons/icon_delete-row-disabled.svg';
import CharacterCountIcon from './Toolbar_Icons/icon_character-count.svg';
import SetFirstRowIcon from './Toolbar_Icons/icon_set-first-row-as-header.svg';
import SetHeaderIcon from './Toolbar_Icons/icon_set-header-as-first-row.svg';
import RenameFileIcon from './Toolbar_Icons/icon_rename.svg';
import CalculationsIcon from './Toolbar_Icons/icon_calculations.svg';
import EnrichmentsIcon from './Toolbar_Icons/icon_enrichments.svg';
import CombineIcon from './Toolbar_Icons/icon_combine.svg';
import SplitIcon from './Toolbar_Icons/icon_split-columns.svg';
import ExplodeDateIcon from './Toolbar_Icons/icon_explode-date.svg';
import ChangeDataTypeIcon from './Toolbar_Icons/icon_change-data-type.svg';
import CleanupUnixTimeIcon from './Toolbar_Icons/icon_cleanup-unix-time.svg';
import DecodeBase64ContentIcon from './Toolbar_Icons/icon_decode-base64-content.svg';
import ExportIcon from './Toolbar_Icons/icon_export.svg';
import ExportDisabledIcon from './Toolbar_Icons/icon_export-disabled.svg';
import SaveAsIcon from './Toolbar_Icons/icon_save-as.svg';
import SaveAsDisabledIcon from './Toolbar_Icons/icon_save-as-disabled.svg';
import CrossFileVLookupIcon from './Toolbar_Icons/icon_crossfile-v-lookup.svg';
import ShareFileIcon from './Toolbar_Icons/icon_share-file.svg';
import FilterIcon from './Toolbar_Icons/icon_filter.svg';
import GroupIcon from './Toolbar_Icons/icon_group.svg';
import SearchIcon from './Toolbar_Icons/icon_search.svg';
import CleanupCompanyNameIcon from './Toolbar_Icons/icon_cleanup-company-name.svg';
import TrimWhitespaceIcon from './Toolbar_Icons/icon_trim-whitespace.svg';
import ExplodeJsonIcon from './Toolbar_Icons/icon_explode-json.svg';

// Filetype Icons
import FolderIcon from './FileType_Icons/folder_icon.svg';
import SharedFolderIcon from './FileType_Icons/shared-folder_icon.svg';
import ExporterIcon from './FileType_Icons/exported-file_icon.svg';
import OmnivoreIcon from './FileType_Icons/file_icon.svg';
import ErrorIcon from './FileType_Icons/file-error_icon.svg';

export const commonSVG = {
  CalendarIcon,
  OfficeFileIcon,
  FlagQuestion,
  NuclearIcon,
  ChevronRight,
  ChevronRightBlack,
  ChevronDown,
  ChevronDownBlack,
  ChevronDownGrey,
  ChevronLeftBlue,
  CloseCircle,
  PlusIcon,
  PlusBlueIcon,
  InfoIcon,
  LeftArrowIcon,
  RightArrowIcon,
  LockIcon,
  UnlockIcon,
  ShareFile,
  CheckboxDefaultIcon,
  FileIcon,
  FileBlackIcon,
  UploadGreyIcon,
  NumbersLinkedList,
  Cross,
  ChangeDataTypeBlackIcon,
  SideColumnsIcon,
  EmptyFilesIcon,
  TaskListIcon,
  FileGreyIcon,
  TrashcanGrayIcon,
  DataConnectors,
  AlertIcon,
  ChevronLeft,
  WarningOrange,
  QuestionMarkGray,
  LogoWithTextPink,
  PublicFileBlackIcon,
  ArrowDown,
  CopyBlueIcon,
};

export const paginationArrowsSVG = {
  StepBackward,
  ChevronLeft,
  NextStep,
  StepToTheEnd,
};

export const functionsSVG = {
  CombineIcon,
  SplitIcon,
  UnixTimeIcon,
  SetFirstRowIcon,
  FileLookup,
  FilterToOnlyIcon,
  FilterToExcludeIcon,
  ChatTextIcon,
  IoCIcon,
  IoCGrayIcon,
  DeleteRowIcon,
};

export const toolbarSVG = {
  SaveAsIcon,
  SaveAsDisabledIcon,
  ShareFileIcon,
  ExportIcon,
  ExportDisabledIcon,
  RenameFileIcon,
  CombineIcon,
  SplitIcon,
  ChangeDataTypeIcon,
  CleanupUnixTimeIcon,
  ExplodeDateIcon,
  DeleteRowsIcon,
  DeleteRowsDisabledIcon,
  SetFirstRowIcon,
  SetHeaderIcon,
  CalculationsIcon,
  CrossFileVLookupIcon,
  EnrichmentsIcon,
  CharacterCountIcon,
  DecodeBase64ContentIcon,
  FilterIcon,
  GroupIcon,
  SearchIcon,
  CleanupCompanyNameIcon,
  TrimWhitespaceIcon,
  ExplodeJsonIcon,
};

export const searchSVG = {
  SearchLightBlueIcon,
  RegexSearch,
  RegexSearchActive,
  HelpMark,
  SearchWhite,
};

export const flagsSVG = {
  FlagIcon,
  GoodFlagIcon,
  SuspiciousFlagIcon,
  BadFlagIcon,
  FlagCheckIcon,
  FlagBlackIcon,
  FlagGreenIcon,
  FlagYellowIcon,
  FlagVioletIcon,
  FlagBlueIcon,
};

export const fileSVG = {
  PublicFileIcon,
  NewFolder,
  ExpandFolderIcon,
  AddNewIcon,
  AddNewFolderIcon,
  CopyIcon,
  PasteIcon,
  RenameIcon,
  MoveToIcon,
  DeleteIcon,
  ErrorStatusIcon,
};

export const fileTypeSVG = {
  FolderIcon,
  SharedFolderIcon,
  ExporterIcon,
  OmnivoreIcon,
  ErrorIcon,
};

export const sidebarSvg = {
  CloudIcon,
  LogoWhiteIcon,
  LogoFullIcon,
  ChatIcon,
  ArchiveIcon,
  QuestionMarkIcon,
  SideBarExpandIcon,
  GearIcon,
  LoadingIcon,
  KeyIcon,
};

export const fileActionsSVG = {
  CombineFilesIcon,
  FolderBlueIcon,
  TrashBlueIcon,
  NavigateBack,
  SmallFolderIcon,
  SmallFileIcon,
};

export const toastSVG = {
  ToastClose,
  ToastInfo,
  ToastSuccess,
  ToastReconnection,
  ToastError,
};
export const RequestedConnectorsSVG = {
  RequestedConnectors,
};

export const PremiumPopupSVG = {
  Rocket,
};

export const IllustrationSVG = {
  CreateAccountIllustration,
  Planet,
  Teams,
};

export const CheckoutSVG = {
  PoweredByStripe,
};
