import React, { useEffect, useState } from 'react';
import { Slider, styled } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { enrichmentTemplatesSliceName } from '@/redux/constants';
import { updatePersistedValues } from '@/redux/reducers/enrichmentTemplates';
import { getPersistedValuesKey } from '@/Utils/enrichmentUtils/EnrichmentUtils';

export const SliderField = ({ field, onChange }) => {
  const DefaultValue = 0.7;
  const [value, setValue] = useState(DefaultValue);
  const { chosenEnrichmentTemplate, persistedValues } = useSelector(
    (state) => state[enrichmentTemplatesSliceName]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let newValue = DefaultValue;
    try {
      const _value = parseFloat(
        persistedValues[
          getPersistedValuesKey(chosenEnrichmentTemplate.id, field.id, field.persisted_values_key)
        ]
      );
      if (typeof _value === 'number' && _value >= 0 && _value <= 1) {
        newValue = _value;
      }
    } finally {
      setValue(newValue);
      onChange(newValue);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange('' + newValue);
    dispatch(
      updatePersistedValues({
        key: getPersistedValuesKey(
          chosenEnrichmentTemplate.id,
          field.id,
          field.persisted_values_key
        ),
        value: '' + newValue,
      })
    );
  };

  const inputChange = (event) => {
    const inputValue = parseFloat(event.target.value);
    const sliderMin = field.slider.min;
    const sliderMax = field.slider.max;
    let newValue = inputValue;
    if (inputValue > sliderMax) {
      newValue = sliderMax;
    } else if (inputValue < sliderMin) {
      newValue = sliderMin;
    }

    if (!(newValue >= sliderMin && newValue <= sliderMax)) {
      newValue = sliderMin;
    }
    handleChange(event, newValue.toFixed(1));
  };

  return (
    <div className='mt-4'>
      {field.slider.labels ? (
        <div className='flex justify-between pb-4 text-[10px] font-extrabold text-ui-helper'>
          <div className='mr-5'>{field.slider.labels.min}</div>
          <div className='mr-10 pr-8'>{field.slider.labels.max}</div>
        </div>
      ) : null}
      <div data-cy='slider' className='flex justify-between pb-4'>
        <div className='mr-5 text-[10px] font-extrabold'>{field.slider.min}</div>
        <CustomSlider
          min={field.slider.min}
          max={field.slider.max}
          step={field.slider.step}
          value={value}
          onChange={handleChange}
        />
        <div className='ml-5 text-[10px] font-extrabold'>{field.slider.max}</div>
        <div className='ml-5 relative bottom-2'>
          <input
            className='w-[55px] h-8 text-sm pl-3 border rounded'
            type='text'
            data-cy='slider-input'
            value={value}
            onChange={inputChange}
          />
        </div>
      </div>
    </div>
  );
};

const CustomSlider = styled(Slider)({
  color: '#DAD8F6',
  height: 8,
  position: 'relative',
  bottom: 10,
  '& .MuiSlider-track': {
    border: '2px solid currentColor',
    borderRadius: 30,
    height: 8,
  },
  '& .MuiSlider-rail': {
    height: 8,
    color: '#EBEBEB',
    borderRadius: 30,
    border: '1px solid #EBEBEB',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#3126AF',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
});
