import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LOCAL_STORAGE_IMPORT_FILE_NAME,
  LOCAL_STORAGE_IMPORT_PROVIDER,
  LOCAL_STORAGE_IMPORT_TOKEN,
  LOCAL_STORAGE_IMPORT_URL,
  URL_DATASETS,
} from '@/Utils/constants';
import showToast from '@/Components/Toast/showToastTemplate';
import { post } from '@/Utils/API';
import LoadingOverlayReact from '@/pages/DatasetPage/LoadingOverlay';

const ImportPage = () => {
  const navigate = useNavigate();

  const sendImportRequest = () => {
    const importProvider = localStorage.getItem(LOCAL_STORAGE_IMPORT_PROVIDER);
    const importToken = localStorage.getItem(LOCAL_STORAGE_IMPORT_TOKEN);
    const importUrl = localStorage.getItem(LOCAL_STORAGE_IMPORT_URL);
    const importFileName = localStorage.getItem(LOCAL_STORAGE_IMPORT_FILE_NAME);

    const payload = {
      url: importUrl,
      token: importToken,
      fileName: importFileName,
    };

    post(`upload/${importProvider}`, payload)
      .then((response) => {
        if (!response.Success) {
          actionOnImportFailed();
        } else {
          actionOnImportSuccess();
        }
      })
      .catch(() => {
        actionOnImportFailed();
      });
  };

  const actionOnImportFailed = () => {
    clearLocalStorage();
    showToast({
      type: 'error',
      text: 'Import failed',
      fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
    });
    navigate(`/${URL_DATASETS}`);
  };

  const actionOnImportSuccess = () => {
    clearLocalStorage();
    showToast({ type: 'info', text: 'File import started' });
    navigate(`/${URL_DATASETS}`);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_IMPORT_PROVIDER);
    localStorage.removeItem(LOCAL_STORAGE_IMPORT_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_IMPORT_URL);
    localStorage.removeItem(LOCAL_STORAGE_IMPORT_FILE_NAME);
  };

  useEffect(() => {
    try {
      sendImportRequest();
    } catch (error) {
      actionOnImportFailed();
    }
  }, []);

  return <LoadingOverlayReact />;
};

export default ImportPage;
