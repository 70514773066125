import {
  BOOLEAN_CONDITIONS,
  DATE_CONDITIONS,
  FLAG_CONDITIONS,
  IPV4_CONDITIONS,
  NUMBER_CONDITIONS,
  STRING_CONDITIONS,
} from '@/Utils/filterConditions';
import { makeStyles } from '@material-ui/core';

import {
  DEFAULT_INPUT_FOR_FILTER_VALUE,
  FILTER_MODEL_AGGREGATE_CNF_,
  FILTER_MODEL_CNF_,
  INPUT_FOR_FILTER_VALUE_WITH_SUGGESTIONS,
  INPUT_FOR_MULTIPLE_FILTER_VALUES,
  NO_INPUT_FOR_FILTER_VALUE,
} from '@/Utils/constants';

export const getFilterConditions = (colId, currFieldType) => {
  if (colId == null) {
    return [];
  }

  switch (currFieldType) {
    case 'Int64':
      return NUMBER_CONDITIONS;
    case 'UInt64':
      return NUMBER_CONDITIONS;
    case 'Float64':
      return NUMBER_CONDITIONS;
    case 'IPv4':
      return IPV4_CONDITIONS;
    case 'DateTime64':
      return DATE_CONDITIONS;
    case 'DateTime':
      return DATE_CONDITIONS;
    case 'Boolean':
      return BOOLEAN_CONDITIONS;
    case 'metadata_tag':
      return FLAG_CONDITIONS;
    default:
      return STRING_CONDITIONS;
  }
};
export const stylesMultiValueNewDesign = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderLeft: '4px solid #808080',
    },
  },
  root: {
    '& .Mui-focused': {
      border: '1px solid #120A6E',
      borderRadius: '5px',
      minHeight: '32px',
    },
  },
  rootError: {
    '& .Mui-focused': {
      border: '1px solid #C420E8',
      borderBottom: 0,
      borderRadius: '5px 5px 0x 0px',
    },
  },
  input: {
    padding: '10px 12px',
    fontFamily: 'Overpass',
    height: '17px',
    fontSize: '14px',
    color: '#151515',
    '&::placeholder': {
      opacity: 1,
      color: '#B0B0B0',
      fontWeight: 400,
    },
    '&.disabled': {
      opacity: '0.6',
    },
    '&.ifFunction': {
      border: 'unset',
    },
  },
  inputRoot: {
    minHeight: '32px',
    border: '1px solid #DCDEDF',
    borderRadius: '0.25rem',
    padding: '0px 10px',
    '&.ifFunction': {
      height: '32px',
      backgroundColor: 'unset',
    },
  },
  inputRootError: {
    border: '1px solid #C420E8',
    borderBottom: 0,
    borderRadius: '5px 5px 0px 0px',
    padding: '6px 10px',
  },
  endAdornment: {
    marginRight: '8px',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: 0,
    border: 0,
    overflow: 'visible',
  },
  listbox: {
    overflow: 'overlay',
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
  },
  option: {
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    opacity: 1,
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '0.5px solid rgba(38, 50, 56, 0.16)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
    },
    '&:first-child': {
      borderTopWidth: '0.5px',
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  loading: {
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontSize: '15px',
    lineHeight: '24px',
  },
});

export const stylesMultiValue = () => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '12px',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderLeft: '4px solid #808080',
    },
  },
  root: {
    '& .Mui-focused': {
      border: '1px solid #120A6E',
      borderRadius: '5px',
      minHeight: '49px',
    },
  },
  rootError: {
    '& .Mui-focused': {
      border: '1px solid #C420E8',
      borderBottom: 0,
      borderRadius: '5px 5px 0x 0px',
    },
  },
  input: {
    padding: '10px 12px',
    fontFamily: 'Overpass',
    height: '27px',
    fontSize: '13px',
    color: '#424242',
    '&::placeholder': {
      opacity: 1,
      color: '#B0B0B0',
      fontWeight: 400,
    },
    '&.disabled': {
      opacity: '0.6',
    },
  },
  inputRoot: {
    minHeight: '49px',
    border: '1px solid #DCDEDF',
    borderRadius: '5px',
    padding: '3px 10px',
  },
  inputRootError: {
    border: '1px solid #C420E8',
    borderBottom: 0,
    borderRadius: '5px 5px 0px 0px',
    padding: '6px 10px',
  },
  endAdornment: {
    marginRight: '8px',
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: 0,
    border: 0,
    overflow: 'visible',
  },
  listbox: {
    overflow: 'overlay',
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
  },
  option: {
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    opacity: 1,
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '0.5px solid rgba(38, 50, 56, 0.16)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
    },
    '&:first-child': {
      borderTopWidth: '0.5px',
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  loading: {
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontSize: '15px',
    lineHeight: '24px',
  },
});

export const stylesSearchSelectNewDesign = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '10px',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      height: '55px',
      backgroundColor: 'transparent',
      borderLeft: '3px solid #DCDEDF',
    },
  },
  input: {
    padding: '7px 12px',
    fontFamily: 'Overpass',
    fontSize: '14px',
    color: '#151515',
    width: '100%',
    minWidth: '112px',
    '&::placeholder': {
      opacity: 1,
      color: '#B0B0B0',
      fontWeight: 400,
    },
  },
  inputRoot: {
    height: '32px',
    border: '1px solid #DCDEDF',
    borderRadius: '0.25rem',
    paddingRight: 0,
    '&.ifFunction': {
      paddingRight: '0',
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: '5px',
    border: 0,
    overflow: 'visible',
    '&.ifFunction': {
      padding: 'unset',
    },
  },
  listbox: {
    overflow: 'overlay',
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
    '&.ifFunction': {
      height: 'unset',
    },
  },
  option: {
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '15px',
    opacity: 1,
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '0.5px solid rgba(38, 50, 56, 0.16)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
    },
    '&:first-child': {
      borderTopWidth: '0.5px',
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  noOptions: {
    fontFamily: 'Overpass',
    fontSize: '14px',
    marginLeft: '-1px',
    marginRight: '-1px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
    textAlign: 'center',
    color: '#120A6E',
    backgroundColor: '#F5F4FF',
    height: '55px',
  },
  endAdornment: {
    marginRight: '8px',
    height: '100%',
    padding: '2px 0 7px',
    '&.ifFunction': {
      border: 'unset',
    },
  },
});

export const stylesSearchSelect = () => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '10px',
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      height: '55px',
      backgroundColor: 'transparent',
      borderLeft: '3px solid #DCDEDF',
    },
  },
  input: {
    padding: '10px 12px',
    fontFamily: 'Overpass',
    fontSize: '15px',
    color: '#424242',
    width: '100%',
    minWidth: '112px',
    '&::placeholder': {
      opacity: 1,
      color: '#B0B0B0',
      fontWeight: 400,
    },
  },
  inputRoot: {
    height: '49px',
    border: '1px solid #DCDEDF',
    borderRadius: '5px',
    paddingRight: 0,
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: '5px',
    border: 0,
    overflow: 'visible',
  },
  listbox: {
    overflow: 'overlay',
    marginLeft: '-1px',
    marginRight: '-1px',
    marginTop: '-1px',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: '2px',
    borderBottomRightRadius: '2px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
  },
  option: {
    backgroundColor: '#FFFFFF !important',
    padding: '10px 12px',
    color: '#424242',
    fontFamily: 'Overpass',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
    opacity: 1,
    borderTop: '0.5px solid rgba(38, 50, 56, 0.16)',
    borderBottom: '0.5px solid rgba(38, 50, 56, 0.16)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      borderColor: '#000000',
    },
    '&:first-child': {
      borderTopWidth: '0.5px',
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  noOptions: {
    fontFamily: 'Overpass',
    fontSize: '14px',
    marginLeft: '-1px',
    marginRight: '-1px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottom: '1px solid #120A6E',
    borderLeft: '1px solid #120A6E',
    borderRight: '1px solid #120A6E',
    textAlign: 'center',
    color: '#120A6E',
    backgroundColor: '#F5F4FF',
    height: '55px',
  },
  endAdornment: {
    marginRight: '8px',
  },
});

export const onColumnIdChange = (
  key,
  index,
  colId,
  currFieldType,
  filterType,
  columns,
  modelValue,
  filterModelElement,
  setIsSowBlock,
  onFilterChange,
  ifGroupIndex
) => {
  // eslint-disable-next-line
  const { filter, filterTo, type, isValidRegex, ...restOfFilterValues } = filterModelElement;
  if (modelValue !== filterModelElement.colId) {
    const isOptionsListChanged =
      JSON.stringify(getFilterConditions(colId, currFieldType)) !==
      JSON.stringify(getFilterConditions(modelValue, columns[modelValue].fieldType));
    if (isOptionsListChanged || modelValue === '*') {
      setIsSowBlock(false);
      onFilterChange(
        index,
        {
          ...filterModelElement,
          type: '',
          filterType,
          [key]: modelValue,
        },
        ifGroupIndex
      );
    } else {
      onFilterChange(
        index,
        {
          ...filterModelElement,
          [key]: modelValue,
        },
        ifGroupIndex
      );
    }
  }
};

export const onColumnFilterTypeChange = (
  key,
  index,
  modelValue,
  filterModelElement,
  onFilterChange,
  ifGroupIndex,
  activePanel = FILTER_MODEL_CNF_
) => {
  // eslint-disable-next-line
  const { filter, filterTo, type, isValidRegex, ...restOfFilterValues } = filterModelElement;

  if (modelValue !== filterModelElement.type) {
    const { filterType } = filterModelElement;
    if (
      getTypeOfValueInput(modelValue, activePanel) !==
      getTypeOfValueInput(filterModelElement.type, activePanel)
    ) {
      onFilterChange(
        index,
        {
          ...restOfFilterValues,
          filterType,
          [key]: modelValue,
        },
        ifGroupIndex
      );
    } else {
      onFilterChange(
        index,
        {
          ...restOfFilterValues,
          filter,
          filterType,
          [key]: modelValue,
        },
        ifGroupIndex
      );
    }
  }
};

const getTypeOfValueInput = (filterType, activePanel) => {
  if (filterType?.includes('Any') && activePanel === FILTER_MODEL_CNF_) {
    return INPUT_FOR_FILTER_VALUE_WITH_SUGGESTIONS;
  }

  if (filterType?.includes('Any') && activePanel === FILTER_MODEL_AGGREGATE_CNF_) {
    return INPUT_FOR_MULTIPLE_FILTER_VALUES;
  }

  if (filterType?.includes('Blank') || filterType?.includes('ternal')) {
    return NO_INPUT_FOR_FILTER_VALUE;
  }
  return DEFAULT_INPUT_FOR_FILTER_VALUE;
};

export const getColumnsMap = (columnDefs) => {
  const columnData = columnDefs;
  const columns = {};
  columnData.forEach((columnDef, i) => {
    const { field } = columnDef;
    columns[field] = {
      index: i,
      colId: field,
      name: columnDef.headerName,
      fieldType: columnDef.fieldType,
    };
  });
  columns['metadata_tag'] = {
    index: columnData.length,
    colId: 'metadata_tag',
    name: 'Row flag',
    fieldType: 'metadata_tag',
  };
  columns['*'] = {
    index: columnData.length + 1,
    colId: '*',
    name: 'Any Column',
    fieldType: 'text',
  };

  return columns;
};

export const flagOptions = [
  { value: '0', label: 'None' },
  { value: '1', label: 'Good' },
  { value: '2', label: 'Suspicious' },
  { value: '3', label: 'Bad' },
];

export const generateFilterValue = (filter) => {
  if (
    filter.type === 'isBlank' ||
    filter.type === 'isNotBlank' ||
    filter.type === 'IPv4-IsBlank' ||
    filter.type === 'IPv4-IsNotBlank' ||
    filter.type === 'IPv4-Internal' ||
    filter.type === 'IPv4-External'
  ) {
    return true;
  } else if (filter.type === 'inRange') {
    return filter.filter && filter.filterTo;
  } else if (filter.isValidRegex === false) {
    return false;
  } else if (filter.type.includes('Any')) {
    return filter.filter?.length;
  } else return filter.filter;
};

export const handleDateModels = (
  dateModels,
  showDateValidationNotification,
  applyFilterModelFromState,
  hideModal
) => {
  const isEmptyInRange = dateModels.some(
    ({ filter, filterTo, type }) =>
      type === 'inRange' && (filter === undefined || filterTo === undefined)
  );

  const isEmptySingle = dateModels.some(
    ({ filter, type }) =>
      type !== 'inRange' && type !== 'isBlank' && type !== 'isNotBlank' && filter === undefined
  );

  if (isEmptyInRange || isEmptySingle) {
    return showDateValidationNotification("The date fields can't be empty", true);
  } else {
    applyFilterModelFromState();
    hideModal();
    showDateValidationNotification('', false);
  }
};
