import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '../withModalHOC';

import { toast } from 'react-toastify';
import { setIsInsertingNewColumn } from '../../../redux/reducers/spreadsheetSlice';

import Toast from '@/Components/Toast/Toast';
import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import NumericInput from '@/Components/UI/Form/NumericInput/NumericInput';
import RadioInput from '@/Components/UI/Form/RadioInput/RadioInput';
import HelperText from '@/Components/UI/HelperText/HelperText';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import { get, post } from '@/Utils/API';
import {
  FILE_STATUS_LOADING,
  FILE_STATUS_PROCESSING,
  FILE_TYPE_EXPORTER,
} from '@/Utils/fileConstants';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import clsx from 'clsx';
import {
  MODAL_PAPERCUT,
  MODAL_SPLIT_SHEET,
  MODAL_UPGRADE_REQUIRED,
  ROUTE_DATASETS,
  TOAST_TEXT_FILE_IS_ALREADY_EXPORTED_ERROR,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_DATASETS,
} from '../../../Utils/constants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';
import ExportFileToastBody from '../ExportFile/ExportFileToastBody';
import { getExportFileStatus } from '@/Utils/getExportFileStatus';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { createGridRequestData } from '@/helpers/datasetsHelper';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { showModal } from '@/redux/reducers/modalsSlice';

const SplitSheetPopup = ({ show, hideModal, getGridRequestData }) => {
  const dispatch = useDispatch();

  const { isInsertingNewColumn, isDeletingRows, isDeletingColumns } = useSelector(
    (state) => state[spreadsheetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { canSplitSheet } = user?.userProperties || {};
  const currentFile = useCurrentFile();
  const { metadata } = currentFile || {};

  const fileUuid = metadata?.FileUuid || getFileUuidFromPath();
  const isOverFileRowsLimit = metadata?.FileRows > user?.userProperties?.maxFileRows;
  const withinQuota =
    (currentFile.hasOwnProperty('WithinQuota') && currentFile?.WithinQuota) ||
    currentFile?.within_quota;
  const isLibraryPage = window.location.href.includes(ROUTE_DATASETS);

  const [splitBy, setSplitBy] = useState('rows');
  const [maxAmount, setMaxAmount] = useState('');

  const [warnInvalidInput, setWarnInvalidInput] = useState('');

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const [shouldDisableActions, setShouldDisableActions] = useState(false);
  const [gridRequestData, setGridRequestData] = useState();

  const { rowGroupCols, pivotMode } = gridRequestData || {};
  const groupingEnabled = rowGroupCols?.length || pivotMode;

  const totalNumberOfRows = metadata?.FileRows;

  const _setGridRequestData = async () => {
    try {
      const { getGridRequestData } = await createGridRequestData({
        fileUuid,
      });

      setGridRequestData(getGridRequestData);
    } catch (error) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const exceedsMaxAmount = useCallback(() => {
    if (splitBy === 'files') {
      return maxAmount > 10000;
    } else {
      return Math.ceil(totalNumberOfRows / maxAmount) > 10000;
    }
  }, [maxAmount, totalNumberOfRows, splitBy]);

  useEffect(() => {
    setShouldDisableActions(isInsertingNewColumn || isDeletingRows || isDeletingColumns);
  }, [isInsertingNewColumn, isDeletingRows, isDeletingColumns, maxAmount]);

  useEffect(() => {
    setIsSaveBtnDisabled(
      groupingEnabled ||
        maxAmount.length === 0 ||
        maxAmount.includes('.') ||
        exceedsMaxAmount() ||
        maxAmount < 0
    );
  }, [maxAmount, groupingEnabled, exceedsMaxAmount]);

  useEffect(() => {
    if (isSaveBtnDisabled && maxAmount.length !== 0) {
      exceedsMaxAmount
        ? setWarnInvalidInput('Unable to split sheet into more than 10,000 files.')
        : setWarnInvalidInput('Must be a whole number.');
    } else {
      setWarnInvalidInput('');
    }
  }, [isSaveBtnDisabled, exceedsMaxAmount]);

  useEffect(() => {
    if (show) {
      if (!getGridRequestData) {
        _setGridRequestData();
      } else {
        setGridRequestData(getGridRequestData);
      }
    }

    if (!show) {
      setGridRequestData(null);
    }
  }, [show]);

  const splitOptions = [
    {
      value: 'rows',
      dataCy: 'rows',
      label: 'Number of Rows',
    },
    {
      value: 'files',
      dataCy: 'files',
      label: 'Number of Files',
    },
  ];

  const getWarningLabel = () => {
    if (groupingEnabled && !pivotMode) {
      return 'Groups';
    } else if (pivotMode && !groupingEnabled) {
      return 'Pivot Mode';
    } else return 'Groups or Pivot Mode';
  };

  const handleSplitSheet = async () => {
    dispatch(hideModal());
    if ((withinQuota || !isOverFileRowsLimit) && !canSplitSheet) {
      return dispatch(showModal({ name: MODAL_PAPERCUT }));
    }
    if (!withinQuota || isOverFileRowsLimit) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    !isLibraryPage && dispatch(setIsInsertingNewColumn(true));

    try {
      setIsSaveBtnDisabled(true);

      let simultaneousExport = false;
      const files = await get(URL_DATASETS);
      files.forEach((file) => {
        if (
          file.Source === fileUuid &&
          file.Type === FILE_TYPE_EXPORTER &&
          (file.Status === FILE_STATUS_PROCESSING || file.Status === FILE_STATUS_LOADING)
        ) {
          simultaneousExport = true;
          showToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_FILE_IS_ALREADY_EXPORTED_ERROR,
            endpoint: URL_DATASETS,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
          return;
        }
      });
      if (simultaneousExport) {
        return;
      }

      if (shouldDisableActions) {
        return;
      }

      const recordsPerFile =
        splitBy === 'rows' ? Number(maxAmount) : Math.ceil(totalNumberOfRows / maxAmount);
      const data = {
        gridState: gridRequestData,
        recordsPerFile,
        filename: `${metadata?.FileName}_exported.csv`,
      };

      const exportResponse = await post(`${URL_DATASET}/${fileUuid}/export`, data, true);
      if (!exportResponse?.handle) {
        return toast(
          ({ closeToast }) => (
            <Toast closeToast={closeToast} type='error'>
              {exportResponse.Message || TOAST_TEXT_GENERAL_SPREADSHEET_ERROR}
            </Toast>
          ),
          { autoClose: false, closeButton: false, closeOnClick: false }
        );
      }
      toast(
        ({ closeToast }) => (
          <Toast closeToast={closeToast} type='info'>
            <ExportFileToastBody closeToast={closeToast} />
          </Toast>
        ),
        { autoClose: false, closeButton: false, closeOnClick: false }
      );
      await getExportFileStatus(() => '', dispatch);

      dispatch(
        addMixpanelEvent({
          eventName: 'File Export',
          eventProps: { 'Export Type': 'Split File' },
          userIncrementName: '# of exports',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      setSplitBy('rows');
      setMaxAmount('');
      setIsSaveBtnDisabled(false);
      !isLibraryPage && dispatch(setIsInsertingNewColumn(false));
    }
  };

  const cancel = () => {
    setSplitBy('');
    setMaxAmount('');
    hideModal();
  };

  return (
    <Modal
      iconName='arrows-split'
      isOpen={show}
      title='Split Sheet'
      subhead='Divide sheet into multiple CVSs'
      onClose={cancel}
      size='small'
    >
      <div className='flex flex-col gap-4 pb-4'>
        <div>
          <div
            className={clsx(
              'flex mb-4 text-sm border rounded bg-carrot-100 bg-opacity-16 border-carrot-900 text-ui-secondary',
              !groupingEnabled && !pivotMode && 'hidden'
            )}
          >
            <Icon name='warning' color='#9E600B' size={28} className='mt-1 ml-2' />
            <div className='m-2'>
              {`Split Sheet does not currently support ${getWarningLabel()} for export. Remove ${getWarningLabel()} before
              proceeding.`}
            </div>
          </div>

          <RadioInput
            label='Split the sheet by'
            options={splitOptions}
            value={splitBy}
            onChange={(e) => {
              setSplitBy(e.target.value);
              setMaxAmount('');
            }}
            disabled={groupingEnabled}
          />
        </div>

        <div className='flex flex-col h-20 gap-2'>
          <NumericInput
            label={`Max Amount of ${splitBy === 'rows' ? 'Rows per File' : 'Files'}`}
            value={maxAmount}
            min='0'
            onChange={(e) => setMaxAmount(e.target.value)}
            className='w-full'
            disabled={groupingEnabled}
            type='number'
          />
          <HelperText text={warnInvalidInput} color='violetWeb' className='w-fit' />
        </div>
      </div>

      <div className='flex flex-row justify-end gap-4 mt-4'>
        <Button onClick={cancel}>Cancel</Button>
        <Button
          color='oceanBlue'
          onClick={handleSplitSheet}
          disabled={isSaveBtnDisabled || !gridRequestData}
          iconName={`${
            !currentFile?.WithinQuota || currentFile?.OverRowQuota || !canSplitSheet
              ? 'rocket-launch'
              : ''
          }`}
        >
          Download
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_SPLIT_SHEET })(SplitSheetPopup);
