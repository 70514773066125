import { toast } from 'react-toastify';

import { store } from '@/redux/store';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import Toast from './Toast';
import ToastBody from './ToastBody';

const ShowToast = ({
  type,
  text,
  onClose = () => {},
  customBody: CustomBody = undefined,
  errorContext,
  endpoint = '',
  payload = {},
  responseStatus = -1,
  fileAndFunction = '',
  other = {},
}) => {
  if (type === 'error') {
    const formattedErrorDetails = {
      errorMessage: errorContext?.message || errorContext?.Message,
      errorStack: errorContext?.stack ? errorContext?.stack.substring(0, 500) : 'No stack trace', // Truncate for brevity
      fileAndFunction: fileAndFunction,
      endpoint: endpoint,
      payload: payload,
      responseStatus: responseStatus,
      other: other,
    };

    store.dispatch(
      addMixpanelEvent({
        eventName: 'Error Toast Appeared',
        eventProps: { ToastText: text, errorDetails: formattedErrorDetails },
      })
    );
  }

  const renderToastBody = (closeToast) => {
    if (CustomBody) {
      return <CustomBody closeToast={closeToast} />;
    }
    return <ToastBody type={type} text={text} />;
  };

  return toast(
    ({ closeToast }) => (
      <Toast
        closeToast={() => {
          closeToast();
          onClose();
        }}
        type={type}
        children={renderToastBody(closeToast)}
      />
    ),
    {
      autoClose: 5000,
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: true,
    }
  );
};
export default ShowToast;
