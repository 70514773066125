import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import showToast from '@/Components/Toast/showToastTemplate';

import {
  InviteUsers,
  VerifyEmail,
  TermsAndConditions,
} from '@/pages/OnboardingPage/OnboardingPages';

import { showModal } from '@/redux/reducers/modalsSlice';
import { setIsOnboardingOpened } from '@/redux/reducers/sharedSlice';
import { post, patch } from '@/Utils/API';
import {
  TOAST_TYPE_INFO,
  TOAST_TYPE_ERROR,
  MODAL_UPLOAD_FILE,
  TOAST_TEXT_UNABLE_TO_RESEND_EMAIL,
  TOAST_TEXT_VERIFICATION_EMAIL_SENT_INFO,
  LOCAL_STORAGE_CHECKOUT_PERIOD,
  LOCAL_STORAGE_COMMENT_THREAD,
  URL_SPREADSHEET,
} from '@/Utils/constants';
import { isValidEmail } from '@/Utils/isValidEmail';
import { formatUrlSafeFileName, openInNewTab } from '@/Utils/utils';

const Onboarding = ({ inviteUsersStepOnly = false, onCloseModal = () => {} }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useSelector((state) => state.userData);

  // Confirm Onboarding
  const setIsOnboarded = async () => {
    const checkoutRequested = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_PERIOD);
    if (!inviteUsersStepOnly) {
      const data = {
        onboarding: {
          actions: 'Onboarded',
        },
      };
      await patch('user/metadata', data);
      dispatch(setIsOnboardingOpened(false));

      // This is needed for google tags conversion tracking.
      if (!searchParams.has('termsaccept')) {
        searchParams.append('termsaccept', true);
        setSearchParams(searchParams);
      }
      openCommentThreadIfRequested();
      if (!location.pathname.includes('spreadsheet') && !checkoutRequested)
        dispatch(showModal({ name: MODAL_UPLOAD_FILE }));
    }
    if (inviteUsersStepOnly) {
      onCloseModal();
    }
  };

  // There is a scenario where the user before signing up to Gigasheet got an email to open a comment thread. If so that request is stored in local storage and we need to open the comment thread after the user has signed up.
  const openCommentThreadIfRequested = () => {
    try {
      const { commentThread, handle, fileName } = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_COMMENT_THREAD)
      );

      if (commentThread?.length > 0 && handle?.length > 0) {
        localStorage.removeItem(LOCAL_STORAGE_COMMENT_THREAD);
        openInNewTab(
          `/${URL_SPREADSHEET}/${formatUrlSafeFileName(
            fileName
          )}/${handle}?comment_thread=${commentThread}`
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Email Verification
  const resendEmailVerification = async () => {
    const endpoint = 'send-verification-email';
    let payload = {};
    try {
      payload = { email: user.email };
      await post(endpoint, payload);
      showToast({ type: TOAST_TYPE_INFO, text: TOAST_TEXT_VERIFICATION_EMAIL_SENT_INFO });
    } catch (e) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UNABLE_TO_RESEND_EMAIL,
        errorContext: e,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  // Terms and Conditions
  const [isChecked, setIsChecked] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState(user.email_verified ? 1 : 0);

  // Invite Users
  const [invitedUsers, setInvitedUsers] = useState([
    { id: Math.random(), value: '', valid: true },
    { id: Math.random(), value: '', valid: true },
  ]);

  const [isInviteSent, setIsInviteSent] = useState(false);
  const [invalidEmailPresent, setInvalidEmailPresent] = useState(false);
  const [enableSendInvites, setIsEnabledSendInvites] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const validateInviteEmail = () => {
    const validateEmails = invitedUsers.map((user) => {
      const { value: email } = user || {};
      if (email.trim() !== '' && !isValidEmail(email)) {
        return { ...user, valid: false };
      } else return { ...user, valid: true };
    });

    setInvitedUsers(validateEmails);
    setInvalidEmailPresent(true);
    setResponseMessage('');
  };

  const handleInviteUsers = (e, i) => {
    const newList = [...invitedUsers];
    newList[i].value = e.target.value;
    newList[i].valid = true;

    setInvalidEmailPresent(false);
    setInvitedUsers(newList);
  };

  const inviteUsers = async () => {
    if (invitedUsers.filter((i) => i.value.length !== 0).length === 0) {
      setIsOnboarded();
      return;
    }

    const multipleInvites = invitedUsers.filter((i) => i.value).length > 1;
    try {
      const res = await post('send-invite', {
        recipients: invitedUsers?.map((i) => i.value.trim()).filter((i) => i),
      });
      if (res.Success) {
        setResponseMessage(`Your invite${multipleInvites ? 's have' : ' has'} been sent!`);
        setIsInviteSent(true);
      } else {
        setResponseMessage(
          `Sorry, we could not send ${
            multipleInvites ? 'these individuals' : 'this individual'
          } an invite.`
        );
        setIsInviteSent(false);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const addInput = () => {
    setInvitedUsers((users) => {
      return [
        ...users,
        {
          id: Math.random(),
          value: '',
          valid: true,
        },
      ];
    });
  };

  const removeInput = (id) => setInvitedUsers(invitedUsers.filter((i) => i.id !== id));

  useEffect(() => {
    if (onboardingStep === 0) {
      window.dataLayer?.push({
        event: 'signup',
        enhanced_conversion_data: {
          email: user?.email,
        },
      });
    }
  }, [onboardingStep]);

  useEffect(() => {
    setInvitedUsers(invitedUsers);
  }, [invalidEmailPresent]);

  useEffect(() => {
    setIsEnabledSendInvites(invitedUsers.filter(({ valid }) => !valid).length !== 0);
  }, [invitedUsers]);

  const getOnboardingStep = () => {
    switch (onboardingStep) {
      case 0:
        return <VerifyEmail user={user} resendEmailVerification={resendEmailVerification} />;
      case 1:
        return (
          <TermsAndConditions
            isChecked={isChecked}
            toggleIsChecked={() => setIsChecked(!isChecked)}
            setOnboardingStep={(v) => setOnboardingStep(v)}
          />
        );
      case 2:
      default:
        return (
          <InviteUsers
            invitedUsers={invitedUsers}
            handleInviteUsers={(e, i) => handleInviteUsers(e, i)}
            validateInviteEmail={validateInviteEmail}
            addInput={addInput}
            removeInput={removeInput}
            inviteUsers={inviteUsers}
            enableSendInvites={enableSendInvites}
            responseMessage={responseMessage}
            isInviteSent={isInviteSent}
            setIsOnboarded={() => setIsOnboarded()}
          />
        );
    }
  };

  return inviteUsersStepOnly ? (
    <InviteUsers
      invitedUsers={invitedUsers}
      handleInviteUsers={(e, i) => handleInviteUsers(e, i)}
      validateInviteEmail={validateInviteEmail}
      addInput={addInput}
      removeInput={removeInput}
      inviteUsers={inviteUsers}
      enableSendInvites={enableSendInvites}
      responseMessage={responseMessage}
      isInviteSent={isInviteSent}
      setIsOnboarded={() => setIsOnboarded()}
      withoutHeader={inviteUsersStepOnly}
    />
  ) : (
    <div className='fixed flex items-center justify-center w-full h-full bg-fixed bg-center bg-no-repeat bg-cover bg-abstract-pattern'>
      <div className='px-8 py-6 bg-white rounded shadow-sm'>{getOnboardingStep()}</div>
    </div>
  );
};

export default Onboarding;
