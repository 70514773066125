import Modal from '@/Components/UI/Modal/Modal';

import {
  UpgradeEnterprise,
  UpgradeStorage,
  RequestAPIKey,
  UpgradeCheckout,
} from '@/helpers/upgradeHelper';

import { MODAL_UPGRADE_ACCOUNT, upgradeAccountTypes } from '@/Utils/constants';

import './style/index.scss';
import withModal from '@/Components/Modals/withModalHOC';

const UpgradeAccountModal = ({ show, hideModal, upgradeType = 'maxStorageSize' }) => {
  const renderBodyComponent = () => {
    switch (upgradeType) {
      case upgradeAccountTypes.maxStorageSize:
        return <UpgradeStorage hideModal={hideModal} />;
      case upgradeAccountTypes.enterprise:
        return <UpgradeEnterprise hideModal={hideModal} />;
      case upgradeAccountTypes.checkout:
        return <UpgradeCheckout hideModal={hideModal} />;
      case upgradeAccountTypes.apiKeyRequest:
        return <RequestAPIKey hideModal={hideModal} />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={show} onClose={hideModal} modalClassName='upgrade-account-modal'>
      {renderBodyComponent()}
    </Modal>
  );
};

export default withModal({ name: MODAL_UPGRADE_ACCOUNT })(UpgradeAccountModal);
