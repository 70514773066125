// sort userFilePermissions by user_id / email
import { PERMISSION_DENIED } from '../../../Utils/constants.js';

export function sortByUserId(userFilePermissions) {
  return userFilePermissions?.sort((a, b) =>
    a.user_id > b.user_id ? 1 : b.user_id > a.user_id ? -1 : 0
  );
}

// filter userFilePermissions so that we keep the highest permission id per user
export function filterHighestPermissionId(userFilePermissions) {
  const userFilePermissionsMap = new Map();

  if (!userFilePermissions) {
    return [];
  }
  userFilePermissions.forEach((element) => {
    if (userFilePermissionsMap.has(element.user_id)) {
      if (
        parseInt(userFilePermissionsMap.get(element.user_id).permission_id) <
        parseInt(element.permission_id)
      ) {
        userFilePermissionsMap.set(element.user_id, element);
      }
    } else {
      userFilePermissionsMap.set(element.user_id, element);
    }
  });

  const newUserFilePermissions = [];
  userFilePermissionsMap.forEach((value) => {
    if (value.permission_id !== `${PERMISSION_DENIED}`) {
      newUserFilePermissions.push(value);
    }
  });

  return newUserFilePermissions;
}
