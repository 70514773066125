export const getFileTypeByName = (name) => {
  const [, fileType] = name.split('.');

  if (!name || typeof name !== 'string' || !fileType) {
    return '';
  }

  const queryIndex = fileType.indexOf('?');

  return fileType.slice(0, queryIndex !== -1 ? queryIndex : fileType.length);
};
