import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import FileToCombine from './FileToCombine';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

import { datasetSliceName } from '@/redux/constants';
import { unSelectAllFiles } from '@/redux/reducers/datasetSlice';

import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';
import { useOverMaxCombineFiles } from '@/hooks/useOverMaxCombineFiles';

const CombineDatasets = () => {
  const { selectedFiles, combineFolderStructure } = useSelector((state) => state[datasetSliceName]);
  const [openedDirectories, setOpenedDirectories] = useState([]);
  const [isExpandAllButtonClicked, setIsExpandAllButtonClicked] = useState(false);
  const [isCollapseAllButtonClicked, setIsCollapseAllButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const { isOverMaxCombineFiles } = useOverMaxCombineFiles();

  const datasetsFiles = useMemo(
    () =>
      combineFolderStructure.filter(
        ({ Status, Type }) => Status !== FILE_STATUS_ERROR && Type !== FILE_TYPE_EXPORTER
      ),
    [combineFolderStructure]
  );

  const onSelectAll = () => {
    if (selectedFiles.length) dispatch(unSelectAllFiles());
  };

  const onExpandAll = () => {
    const allFoldersIds = combineFolderStructure
      .filter((file) => file.IsDirectory)
      .map((file) => file.FileUuid);
    setOpenedDirectories(allFoldersIds);
    setIsExpandAllButtonClicked(true);
  };

  const onCollapseAll = () => {
    setOpenedDirectories([]);
    setIsCollapseAllButtonClicked(true);
  };

  return (
    <div className='combine-datasets'>
      <div className='flex justify-between items-center bg-gray-100 border-b border-gray-300 p-2'>
        <div>
          <Button color='shadow' size='small' onClick={onExpandAll} className='mr-1'>
            Expand All
          </Button>
          <Button color='shadow' size='small' onClick={onCollapseAll}>
            Collapse All
          </Button>
        </div>
        <Tooltip text={isOverMaxCombineFiles && 'Free tier cannot combine more than 5 files.'}>
          <div className={clsx('flex items-center text-sm text-shadow-700')}>
            <>
              {isOverMaxCombineFiles && (
                <span className='mr-1 shrink-0'>
                  <Icon name='rocket-launch' color='#D9B500' />
                </span>
              )}
              {selectedFiles.length}/100 Files
            </>
          </div>
        </Tooltip>
      </div>
      <div className='combine-datasets-row row-header'>
        <label className='combine-datasets-checkbox'>
          <input type='checkbox' onChange={onSelectAll} checked={selectedFiles.length} />
          <span className='custom-checkbox' />
        </label>
        <div className='combine-datasets-cols !text-[13px] font-semibold'>
          <span>Rows</span>
          <span>Cols</span>
        </div>
      </div>
      <div
        id='combine-files-list'
        className='h-[400px] overflow-y-auto combine-datasets-custom-scrollbar'
      >
        {datasetsFiles.map((file) => {
          return (
            <FileToCombine
              key={file.FileUuid}
              file={file}
              openedDirectories={openedDirectories}
              setOpenedDirectories={setOpenedDirectories}
              isExpandAllButtonClicked={isExpandAllButtonClicked}
              isCollapseAllButtonClicked={isCollapseAllButtonClicked}
              setIsExpandAllButtonClicked={setIsExpandAllButtonClicked}
              setIsCollapseAllButtonClicked={setIsCollapseAllButtonClicked}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CombineDatasets;
