import agify from './agify.json';
import apollo from './apollo.json';
import hunter from './hunter.json';
import pdLabs from './pdLabs.json';
import IPOsint from './IPOsint.json';
import placeKey from './placekey.json';
import bulkDeepl from './bulkDeepl.json';
import leadmagic from './leadmagic.json';
import openaigpt from './openaigpt.json';
import ipEnrich from './ipEnrichment.json';
import bulkPlaceKey from './bulkPlaceKey.json';
import extractorapi from './extractorapi.json';
import virusTotalIP from './virusTotalIP.json';
import IPGeolocation from './IPGeolocation.json';
import bulkPDataLabs from './bulkPDataLabs.json';
import customEnrich from './customEnrichment.json';
import virusTotalHash from './virusTotalHash.json';
import emailListVerify from './emaillistverify.json';
import openaifirstname from './openaifirstname.json';
import bulkGreyNoiseIP from './bulkGreyNoiseIP.json';
import emailEnrich from './emailFormatCheckAlexa.json';
import revenueBaseEmail from './revenueBaseEmail.json';
import scraperapisearch from './ScraperAPISearchEngine.json';
import revenueBaseLinkedin from './revenueBaseLinkedIn.json';
import bulkRecordedFutureIP from './bulkRecordedFutureIP.json';
import bulkRecordedFutureHash from './bulkRecordedFutureHash.json';
import revenueBaseCompanyDomain from './revenueBaseCompanyDomain.json';
import openaicompanynameguesser from './openaicompanynameguesser.json';
import organizationApolloEnrich from './organizationApolloEnrichment.json';
import revenueBaseCompanyLinkedIn from './revenueBaseCompanyLinkedin.json';
import openaicompanywebsiteguesser from './openaicompanywebsiteguesser.json';

export const enrichmentRecipes = [
  revenueBaseEmail,
  revenueBaseLinkedin,
  revenueBaseCompanyLinkedIn,
  revenueBaseCompanyDomain,
  leadmagic,
  openaigpt,
  customEnrich,
  emailEnrich,
  openaicompanynameguesser,
  openaicompanywebsiteguesser,
  openaifirstname,
  scraperapisearch,
  agify,
  apollo,
  bulkDeepl,
  bulkGreyNoiseIP,
  bulkPDataLabs,
  bulkPlaceKey,
  bulkRecordedFutureHash,
  bulkRecordedFutureIP,
  emailListVerify,
  extractorapi,
  hunter,
  ipEnrich,
  IPGeolocation,
  IPOsint,
  organizationApolloEnrich,
  pdLabs,
  placeKey,
  virusTotalHash,
  virusTotalIP,
];

export const getEnrichmentByRecipeId = (recipeId) =>
  enrichmentRecipes.find((recipe) => recipe.id === recipeId);

// for adding enrichment template :
// 1. create file with .json extension in recipes folder
// 2. import anyName from './here name of .json file' as in  template 'import emailEnrich from './emailEnrichment.json';'
// 3. add created name to enrichmentRecipes array
// 4. add icon for the vendor as a .webp in assets folder
// 5. add icon name as export in assets/index.js and export with name that matches the endpoint_logo value in recipe
