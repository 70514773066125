import { Button } from '@/Components/UI/Button/Button';
import { StoragePercentageBar, manageBilling } from '@/helpers/settingsHelper';
import { settingsSliceName } from '@/redux/constants';
import { useSelector } from 'react-redux';
import {
  convertBillingPeriod,
  convertTimestampToDate,
  convertToGB,
} from '@/pages/SettingsPage/SettingsPages/SubscriptionAndUsageSettingsUtils';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';

const SubscriptionAndUsageSettings = () => {
  const {
    subscriptionDetails: {
      maxStorageSize,
      filesSize,
      filesSizePercentage,
      isPremium,
      enrichmentCredits: { Limit, Used },
      enrichmentPercentage,
      externalName,
      totalActiveAmount,
      billingPeriodUnit,
      nextBillingAt,
    },
    isLoading,
  } = useSelector((state) => state[settingsSliceName]);

  return (
    <div className='px-16'>
      <div className='flex flex-col gap-4 p-6 mb-8 bg-white w-[670px] border h-[280px]'>
        <div className='pb-2 text-lg font-extrabold text-ui'>Subscription</div>
        {isLoading ? (
          <SkeletonLoader header={false} loadingBars={3} />
        ) : (
          <div className='flex flex-row justify-between w-full'>
            <div className='flex flex-col w-6/12 gap-1'>
              <div className='flex flex-row justify-between text-left text-ui-helper'>
                <span className='font-semibold text-ui'>Current Plan:</span>
                {isPremium ? externalName : 'Free'}
              </div>
              <div className='flex flex-row justify-between text-left text-ui-helper'>
                <span className='font-semibold text-ui'>Amount:</span> ${totalActiveAmount / 100}/
                {convertBillingPeriod(billingPeriodUnit)}
              </div>
              {totalActiveAmount !== 0 && (
                <div className='flex flex-row justify-between text-left text-ui-helper'>
                  <span className='font-semibold text-ui'>Next Billing Date:</span>{' '}
                  {convertTimestampToDate(nextBillingAt)}
                </div>
              )}
              <div className='flex flex-row justify-between text-left text-ui-helper'>
                <span className='font-semibold text-ui'>Storage Limit:</span>
                {maxStorageSize}
              </div>
              <div className='flex flex-row justify-between text-left text-ui-helper'>
                <span className='font-semibold text-ui'>Enrichment Credits:</span>
                {Limit.toLocaleString()}/mo
              </div>
            </div>
            {isPremium ? (
              <Button className='mt-[-50px]' onClick={manageBilling}>
                Manage Billing
              </Button>
            ) : (
              <div className='w-5/12'>
                <div className='mb-2 text-base font-semibold'>Upgrade and receive access to:</div>
                <ul className='flex flex-col gap-1 pl-4 list-disc text-ui-helper'>
                  <li>Advanced Filters</li>
                  <li>Combine up to 100 files</li>
                  <li>More space and larger files</li>
                  <li>View more rows per page</li>
                  <li>Premium support</li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='flex flex-col gap-2 p-6 bg-white border w-[670px] h-44'>
        <div className='text-lg font-extrabold'>Usage</div>
        {isLoading ? (
          <SkeletonLoader header={false} loadingBars={1} />
        ) : (
          <div className='flex flex-row gap-8'>
            <div className='h-[81px] w-full flex flex-col gap-1 py-4'>
              <StoragePercentageBar
                usagePercentage={filesSizePercentage}
                label='Storage'
                limit={convertToGB(maxStorageSize)}
                limitLabel='GB'
                used={convertToGB(filesSize)}
                usedLabel='GB'
              />
            </div>
            <div className='h-[81px] w-full flex flex-col gap-1 py-4'>
              <StoragePercentageBar
                usagePercentage={enrichmentPercentage}
                label='Enrichment Credits'
                limit={Limit}
                used={Used}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionAndUsageSettings;
