import { sidebarSvg } from '../../Assets/icons';

const getSideBarMenu = (isFileListPage, openFreshChat) => {
  const menuItems = [
    {
      href: 'https://gigasheet.freshdesk.com/',
      target: '_blank',
      rel: 'noopener noreferrer',
      onClick: (event) => event.stopPropagation(),
      className: 'question-btn',
      iconSrc: sidebarSvg.QuestionMarkIcon,
      alt: 'question-mark',
      text: 'Help',
      dataCy: 'sidebar-question-btn',
    },
    {
      href: '',
      target: '_self',
      rel: 'nofollow',
      onClick: (event) => {
        event.preventDefault();
        openFreshChat();
        event.stopPropagation();
      },
      className: 'chat-btn',
      iconSrc: sidebarSvg.ChatIcon,
      alt: 'chat',
      text: 'Chat',
      dataCy: 'sidebar-chat-btn',
    },
  ];
  return menuItems;
};
export default getSideBarMenu;
