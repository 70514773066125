import { format, getTimezoneOffset } from 'date-fns-tz';
import zones from '@/Utils/timezonesList';

export type TimezoneOption = {
  value: string;
  label: string;
  offset: number;
};

export const getTimezones = () => {
  const compareDate = new Date();
  const timezones = [];

  for (const zoneName of zones) {
    try {
      const offset = getTimezoneOffset(zoneName, compareDate) / 3_600_000;

      const offsetFormatted = `${offset >= 0 ? '+' : '-'}${Math.abs(offset)}`;
      let label = `(GMT${offsetFormatted}) ${zoneName.replace(/_/g, ' ')}`;
      let abbreviation;

      try {
        abbreviation = format(compareDate, 'zzz', { timeZone: zoneName });
      } catch (formatError) {
        console.error(`Error formatting abbreviation for ${zoneName}:`, formatError);
        abbreviation = '';
      }

      label += abbreviation ? ` - ${abbreviation}` : '';

      const offsetMinutes = offset * 60;

      timezones.push({
        value: zoneName,
        label,
        // offset output is in minutes, difference of UTC and this zone (negative for hours ahead of UTC, positive for hours behind)
        offset: offsetMinutes === 0 ? 0 : offsetMinutes * -1,
      });
    } catch (error) {
      // Ignores timezones that are not available within a user's browser/operating system
      console.error(`Error processing timezone ${zoneName}:`, error);
    }
  }

  timezones.push({
    value: 'Etc/UTC',
    label: '(GMT+0) Etc/UTC',
    offset: 0,
  });

  // Sorts W -> E, prioritizes america. could be more nuanced based on system tz but simple for now
  return timezones.sort((a, b) => {
    const offsetDelta = b.offset - a.offset;
    if (offsetDelta !== 0) return offsetDelta;

    if (typeof a.label === 'string' && typeof b.label === 'string') {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
    }
    return 0;
  });
};
