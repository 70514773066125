const getCurrentAppliedFilters = (current = []) => {
  //parsing data from response to linear array [[{}], [{}, {}], ...]
  return current
    .map((el) =>
      el.length > 1 ? el.map((item, index) => ({ ...item, isChecked: index > 0 })) : el
    )
    .flat();
};

export default getCurrentAppliedFilters;
