import clsx from 'clsx';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';

import useClickOutside from '@/hooks/useClickOutside';

import { getTypeIconPath } from '@/Utils/getTypeIconPath';

import Icon from '@/Components/UI/Icon/Icon';
import DotsLoader from '@/Components/Loader/DotsLoader';
import { Button } from '@/Components/UI/Button/Button';

import './dropdownStyles.scss';
import { spreadsheetSliceName } from '@/redux/constants';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import TextClipper from '@/Components/TextClipper/TextClipper';

const Dropdown = ({ onChange, placeholder = 'Column', helperText = '' }) => {
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const [searchValue, setSearchValue] = useState('');
  const [chosenOption, setChosenOption] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [readOnlyInputValue, setReadOnlyInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [shouldDisplayAbove, setShouldDisplayAbove] = useState(false);

  const btnDropDownRef = useRef();
  const dropdownMenuRef = useRef();

  useEffect(() => {
    const options = columnDefs?.map((colDef) => ({
      label: colDef.headerName,
      value: `****${colDef.field}****`,
      fieldType: colDef.fieldType,
    }));
    setFilteredOptions(options);
    setDefaultOptions(options);
  }, [columnDefs, menuIsOpen]);

  useEffect(() => {
    setMenuIsOpen(false);
  }, [chosenOption]);

  const handleAddOption = (option) => {
    setChosenOption(option);
    onChange(option.value);
    setReadOnlyInputValue(option.label);
    setMenuIsOpen(false);
  };

  const handleResetOptions = () => {
    onChange('');
    setReadOnlyInputValue('');
    setChosenOption(null);
    setFilteredOptions(defaultOptions);
    setMenuIsOpen(false);
  };

  const handleAddOptions = () => {
    const option = {
      label: searchValue,
      value: searchValue,
      fieldType: '',
    };
    setChosenOption(option);
    onChange(searchValue);
    setReadOnlyInputValue(searchValue);
    setMenuIsOpen(false);
  };

  const toggleActionsMenuAndGetColumnValues = () => {
    setMenuIsOpen(!menuIsOpen);
    setSearchValue('');
    setIsLoadingData(false);
  };

  useClickOutside(dropdownMenuRef, toggleActionsMenuAndGetColumnValues);

  const filterListHeight = 177;

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    const filteredOptions = defaultOptions.filter((item) =>
      item.label.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredOptions([
      ...filteredOptions,
      {
        label: event.target.value,
        value: event.target.value,
        fieldType: '',
      },
    ]);
  };

  const handleSearchInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      const chosenOption = {
        label: e.target.value,
        value: e.target.value,
        fieldType: '',
      };
      setChosenOption(chosenOption);
      onChange(e.target.value);
      setReadOnlyInputValue(e.target.value);
      setMenuIsOpen(false);
    }
  };

  const calculateDropdownPosition = () => {
    if (btnDropDownRef.current && dropdownMenuRef.current) {
      const dropdownRect = dropdownMenuRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRect.height;
      const windowHeight = window.innerHeight;
      const inputRect = btnDropDownRef.current.getBoundingClientRect();
      const inputTop = inputRect.top;

      const shouldDisplayAbove = inputTop + inputRect.height + dropdownHeight > windowHeight;
      setShouldDisplayAbove(shouldDisplayAbove);
    }
  };

  useLayoutEffect(() => {
    if (menuIsOpen) {
      calculateDropdownPosition();
    }
  }, [menuIsOpen]);

  const getPositionStyles = () => {
    if (shouldDisplayAbove) {
      return {
        position: 'absolute',
        bottom: `${btnDropDownRef.current.offsetHeight}px`,
      };
    } else {
      return {
        position: 'absolute',
        top: `${btnDropDownRef.current.offsetHeight}px`,
      };
    }
  };

  return (
    <div className='!relative options__container'>
      <TextInput
        ref={btnDropDownRef}
        dataCy='set-option-btn'
        placeholder={placeholder}
        value={readOnlyInputValue}
        onClick={toggleActionsMenuAndGetColumnValues}
        readOnly
        className={clsx(
          'options__values pl-2.5 !h-8 !w-full',
          menuIsOpen && 'options__values-focused'
        )}
        helperText={helperText}
      />

      {menuIsOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            ...getPositionStyles(),
          }}
          className='options__dropdown'
          id='options-dropdown'
        >
          <div className='options__search'>
            <input
              className='!h-8'
              value={searchValue}
              placeholder='Search'
              onChange={handleSearchInputChange}
              onKeyUp={handleSearchInputKeyUp}
              autoFocus
            />

            <Icon name='magnifying-glass' size={18} />
          </div>
          <div className='options__menu'>
            {isLoadingData ? (
              <div className='mx-auto my-5'>
                <DotsLoader />
              </div>
            ) : (
              <div className='options__menu-items'>
                <FixedSizeList
                  itemCount={filteredOptions.length}
                  itemSize={37}
                  height={filterListHeight}
                  width={255}
                >
                  {({ index, style }) => (
                    <SetSelectedOption
                      style={style}
                      option={filteredOptions[index]}
                      handleAddOption={handleAddOption}
                    />
                  )}
                </FixedSizeList>
              </div>
            )}
          </div>
          <div className='options__footer'>
            <Button color='shadow' onClick={handleResetOptions}>
              Reset
            </Button>
            <Button
              dataCy='options-add'
              color='oceanBlue'
              onClick={handleAddOptions}
              // TODO in which cases
              // disabled={isAddDisabled}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const SetSelectedOption = ({ style, option, handleAddOption }) => {
  return (
    <div
      className='options__menu-item'
      style={style}
      onClick={() => handleAddOption(option)}
      onKeyUp={() => {}}
      role='button'
      tabIndex={0}
    >
      <span className='flex gap-2 pl-2 cursor-pointer w-[205px]' htmlFor={`${option.label}-check`}>
        <Icon name={getTypeIconPath(option.fieldType)} size={18} />

        <TextClipper text={option.label} />
      </span>
    </div>
  );
};

export default Dropdown;
