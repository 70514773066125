import { Button } from '@/Components/UI/Button/Button';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import { COLLAPSE_ALL, EXPAND_ALL } from './constants';
import clsx from 'clsx';

export const IfConditionsTopPanel = ({
  columnNameValue,
  onChangeColumnName,
  onButtonClick,
  isValidConditions,
}) => {
  return (
    <div className='flex flex-row items-end justify-between mb-6'>
      <TextInput
        dataCy='new-column-name'
        label='New Column Name'
        value={columnNameValue}
        onChange={onChangeColumnName}
        placeholder={'Column name'}
        className={clsx(
          'w-72',
          !isValidConditions &&
            !columnNameValue &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
        )}
      />
      <div className='flex gap-1'>
        <Button
          iconName='caret-down-regular'
          color='shadow'
          variant='outline'
          onClick={onButtonClick}
          className='!border-ui-200'
        >
          {EXPAND_ALL}
        </Button>
        <Button
          iconName='caret-up'
          color='shadow'
          variant='outline'
          onClick={onButtonClick}
          className='!border-ui-200'
        >
          {COLLAPSE_ALL}
        </Button>
      </div>
    </div>
  );
};
