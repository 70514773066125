import React from 'react';
import Icon from '@/Components/UI/Icon/Icon';
import {
  BROWSE_UPLOAD,
  MODAL_AUTOMATION_API,
  MODAL_IMPORT_FROM_CONNECTOR,
  URL_UPLOAD,
} from '@/Utils/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { cloudConnectors } from './connectors/cloud-connectors.js';
import { platformConnectors } from './connectors/platform-connectors.js';
import { useDispatch } from 'react-redux';

const Card = ({ title, description, icon, dataCy, children, onDragOver, onClick, className }) => {
  const baseClasses =
    'flex flex-col justify-center rounded-xl border border-[#D6D6D6] px-4 overflow-hidden h-20';
  const contentClasses = children
    ? 'bg-[#D6F1FF] bg-opacity-20 border-dashed border-[#85D6FF]'
    : 'border';
  const textContainerClass = 'flex-1 min-w-0'; // Ensures the text container can shrink and respects flexbox rules.

  return (
    <div
      data-cy={dataCy}
      onDragOver={onDragOver}
      className={`${baseClasses} ${contentClasses} ${className}`}
      role='button'
      tabIndex='0'
      onClick={() => onClick?.()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && onClick) onClick();
      }}
    >
      <div className='flex items-center justify-start ml-4 space-x-4'>
        {icon && <div>{icon}</div>}
        <div className={`flex flex-col ${textContainerClass}`}>
          <div className='text-sm font-semibold truncate'>{title}</div>
          <div className='text-sm truncate'>{description}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

const LibraryAutomation = ({ openUploadModal }) => {
  const dispatch = useDispatch();
  const importFromCloudDescription = cloudConnectors.map((connector) => connector.name).join(', ');
  const importFromPlatformDescription = 'Data Warehouses, Databases, CRMs, SaaS';

  const handleBrowseClick = () => {
    openUploadModal({ openUploadType: BROWSE_UPLOAD });
  };

  const handleImportFromLinkClick = () => {
    openUploadModal({ openUploadType: URL_UPLOAD });
  };

  const onDragOver = (event) => {
    event.preventDefault();
    openUploadModal();
  };

  const onClickImportFromCloudCard = () => {
    dispatch(
      showModal({
        name: MODAL_IMPORT_FROM_CONNECTOR,
        props: {
          title: 'Import from Cloud',
          connectors: cloudConnectors,
        },
      })
    );
  };

  const onClickImportFromPlatformCard = () => {
    dispatch(
      showModal({
        name: MODAL_IMPORT_FROM_CONNECTOR,
        props: {
          title: 'Connect to Platform',
          connectors: platformConnectors,
        },
      })
    );
  };

  const onClickAutomateCard = () => {
    dispatch(showModal({ name: MODAL_AUTOMATION_API }));
  };

  return (
    <div className='grid grid-cols-2 gap-4 py-2'>
      <Card dataCy='uppy-loader-card' onDragOver={onDragOver}>
        <div className='flex justify-center text-sm' onDragOver={onDragOver}>
          Drop files,
          <span
            onClick={handleBrowseClick}
            onDragOver={onDragOver}
            className='text-[#0079B6] cursor-pointer underline ml-1 mr-1'
            role='button'
            tabIndex={0}
            data-cy='browse-btn'
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleBrowseClick();
            }}
          >
            Browse
          </span>
          or
          <span
            onClick={handleImportFromLinkClick}
            onDragOver={onDragOver}
            className='text-[#0079B6] cursor-pointer underline ml-1'
            role='button'
            tabIndex={0}
            data-cy='import-from-link-btn'
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleImportFromLinkClick();
            }}
          >
            Import from Link
          </span>
        </div>
      </Card>
      <Card
        dataCy='import-from-cloud-card'
        title='Import from Cloud'
        description={importFromCloudDescription}
        icon={<Icon name='cloud' size={32} color='#009FEF' weight='duotone' />}
        onClick={onClickImportFromCloudCard}
      />
      <Card
        dataCy='import-from-platform-card'
        title='Connect to Platform'
        description={importFromPlatformDescription}
        icon={<Icon name='database' size={32} color='#889121' weight='duotone' />}
        onClick={onClickImportFromPlatformCard}
      />
      <Card
        dataCy='automate-card'
        title='Automate'
        description='Gigasheet API & Zapier'
        icon={<Icon name='webhooks-logo' size={32} color='#CE7C0E' weight='duotone' />}
        onClick={onClickAutomateCard}
      />
    </div>
  );
};

export default LibraryAutomation;
