import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import { showModal } from '@/redux/reducers/modalsSlice';
import { get } from './API';
import {
  MODAL_EXPORT_DOWNLOAD,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  URL_DATASET,
  URL_LIBRARY,
} from './constants';
import { FILE_STATUS_PROCESSED, FILE_TYPE_EXPORTER } from './fileConstants';

export const getExportFileStatus = async (getFolder, dispatch) => {
  const response = await get(`${URL_LIBRARY}/${getFolder()}`);
  const filesWithLoadingStatus = response
    .filter(
      ({ metadata: { Type, Status, FileUuid } }) =>
        Type === FILE_TYPE_EXPORTER &&
        Status !== FILE_STATUS_PROCESSED &&
        !localStorage.getItem(FileUuid)
    )
    .map(({ metadata: { FileUuid } }) => FileUuid);

  const onDownloadFileClick = (FileId) => {
    dispatch(showModal({ name: MODAL_EXPORT_DOWNLOAD, props: { FileId } }));
  };

  const toastText = (id) => {
    return (
      <span className='!font-normal'>
        Your export is ready{' '}
        <Button className='!p-0' variant='ghost' onClick={() => onDownloadFileClick(id)}>
          <span className='!font-semibold '>Click here to download.</span>
        </Button>
      </span>
    );
  };

  const checkFileStatus = async (FileUuid) => {
    const timeout = () =>
      setTimeout(() => {
        checkFileStatus(FileUuid);
      }, 2000);

    let endpoint = '';
    try {
      endpoint = `${URL_DATASET}/${FileUuid}`;
      const { Type, Status, DetailedStatus } = await get(endpoint);

      if (!localStorage.getItem(FileUuid)) {
        localStorage.setItem(FileUuid, FileUuid);
      }

      if (Status === 'error') {
        throw new Error(DetailedStatus);
      }

      if (Type === FILE_TYPE_EXPORTER && Status === FILE_STATUS_PROCESSED) {
        showToast({
          type: TOAST_TYPE_SUCCESS,
          text: toastText(FileUuid),
        });
        localStorage.removeItem(FileUuid);
      } else {
        timeout();
      }
    } catch (error) {
      clearTimeout(timeout());
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      throw new Error(error);
    }
  };

  filesWithLoadingStatus.forEach((FileUuid) => {
    checkFileStatus(FileUuid);
  });
};
