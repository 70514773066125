import { getNumberValueSeparatedByCommas } from '@/Utils/utils';
import '@/pages/SpreadsheetPage/index.scss';

const CustomAutoGroupValue = (params) => {
  const { groupCount } = params.data;
  const { value } = params;
  return (
    <>
      <span className='autoGroupColumn-group-value'>{value}</span>
      <span className='autoGroupColumn-group-count'>{`(${getNumberValueSeparatedByCommas(
        groupCount
      )})`}</span>
    </>
  );
};

export default CustomAutoGroupValue;
