import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '../../Utils/API';
import {
  currentFileErrorTexts,
  FILE_PERMISSIONS,
  GENERAL_ERROR_TEXT,
  PERMISSIONS,
  SESSION_EXPIRED_OR_UNAUTHORIZED,
  URL_DATASET,
} from '../../Utils/constants';
import getColumnsCount from '../../Utils/getColumnsCount';
import sortColumnData from '../../Utils/sortColumnData';
import { datasetSliceName } from '../constants';
import { getFilesSizeInPercentage } from '../../Utils/utils';
import { parseFileSize } from '../../Utils/parseFileSize';
import { filePathEndpoint, libraryEndpoint, noteEndpoint } from '../../Utils/apiEndpoints';
import { getHeaderCommentThreads } from '@/Utils/CommentUtils';
import { StatusCodes } from 'http-status-codes';

const initialState = {
  error: null,
  currentFile: null,
  processingFiles: [],
  loading: false,
  isLoadingLibrary: false,
  filesSize: null,
  filesSizePercentage: null,
  sortingData: {
    direction: 0,
    columnName: '',
    currentDirectory: '',
  },
  selectedFiles: [],
  filePath: [],
  isPublicResource: false,
  libraryFiles: null,
  sharedWithMeFiles: null,
  sharedWithMyTeamFiles: null,
  librarySearchResult: null,
  folderStructure: [],
  sharedWithMeFolderStructure: [],
  sharedWithMyTeamFolderStructure: [],
  combineFolderStructure: [],
  isActiveFolderTree: [],
  currentDataset: 'Library',
  currentDir: '',
  isInvalidFolderHandle: false,
  isUserHasSharedFolderAccess: true,
};

export const updateFilePath = createAsyncThunk(
  `${datasetSliceName}/updateFilePath`,
  async (args, { getState, rejectWithValue }) => {
    const state = getState();
    const { currentDir } = state.dataset;

    let filePath = [];
    if (currentDir?.length > 0) {
      const filePathResponse = await get(filePathEndpoint(currentDir), true);
      if (filePathResponse && filePathResponse.status !== StatusCodes.OK) {
        const errorMessage =
          filePathResponse && filePathResponse.status === StatusCodes.UNAUTHORIZED
            ? SESSION_EXPIRED_OR_UNAUTHORIZED
            : GENERAL_ERROR_TEXT;
        return rejectWithValue(errorMessage);
      }

      filePath = await filePathResponse.json();
      if (!filePath) {
        filePath = [];
      }
    }

    return {
      filePath,
    };
  }
);

export const updateCombineFolderStructure = createAsyncThunk(
  `${datasetSliceName}/updateCombineFolderStructure`,
  async (folderHandle, { rejectWithValue }) => {
    const folderContentResponse = await get(libraryEndpoint(folderHandle), true);
    if (!folderContentResponse || folderContentResponse.status !== StatusCodes.OK) {
      const errorMessage =
        folderContentResponse && folderContentResponse.status === StatusCodes.UNAUTHORIZED
          ? SESSION_EXPIRED_OR_UNAUTHORIZED
          : GENERAL_ERROR_TEXT;
      return rejectWithValue(errorMessage);
    }

    const folderContent = await folderContentResponse.json();

    return {
      folderHandle,
      folderContent,
    };
  }
);

export const fetchOpenedFile = createAsyncThunk(
  `${datasetSliceName}/fetchOpenedFile`,
  async (uuid, { getState, rejectWithValue }) => {
    const {
      userData: {
        user: {
          email,
          userProperties: { maxFileRows },
        },
      },
    } = getState();

    const getVersionId = () => {
      const splitPath = window.location.pathname.split('/');
      if (splitPath[splitPath.length - 1] === uuid) {
        return '';
      } else {
        return '/' + splitPath[splitPath.length - 1];
      }
    };

    let currentFile = await get(`${URL_DATASET}/${uuid}${getVersionId()}`, true);

    if (currentFile.status !== StatusCodes.OK && getVersionId()) {
      const response = await get(`${URL_DATASET}/${uuid}/views${getVersionId()}`);
      if (response.hasOwnProperty('client_state') && response.client_state) {
        currentFile = await get(`${URL_DATASET}/${uuid}/${response.client_state}`, true);
      }
    }

    const note = await get(noteEndpoint(uuid));
    const availablePermissionsJson = await get(`${URL_DATASET}/${uuid}/${PERMISSIONS}`, true).then(
      (response) => {
        return response.json();
      }
    );
    const publicFilePermissions = await get(
      `${URL_DATASET}/${uuid}/${FILE_PERMISSIONS}`,
      true
    ).then((response) => {
      return response.json();
    });

    if (currentFile.status !== StatusCodes.OK) {
      const errorText =
        currentFile.status === StatusCodes.UNAUTHORIZED ||
        currentFile.status === StatusCodes.FORBIDDEN ||
        currentFile.status === StatusCodes.INTERNAL_SERVER_ERROR
          ? currentFileErrorTexts[currentFile.status]
          : currentFileErrorTexts[StatusCodes.INTERNAL_SERVER_ERROR];
      return rejectWithValue(errorText);
    }

    const headerCommentThreads = await getHeaderCommentThreads(uuid);

    const data = await currentFile.json();

    return {
      metadata: data,
      isFileShared: data.Owner !== email,
      availablePermissions: availablePermissionsJson.permissions,
      IsWorldReadable: publicFilePermissions.permissions.length > 0,
      WithinQuota: data.WithinQuota,
      OverRowQuota: data?.FileRows >= maxFileRows,
      Note: note,
      HeaderCommentThreads: headerCommentThreads,
      ColumnInfo: data?.ColumnInfo,
    };
  }
);

export const fetchOpenedFileStats = createAsyncThunk(
  `${datasetSliceName}/fetchOpenedFileStats`,
  async (uuid) => {
    return await get(`${URL_DATASET}/stats/${uuid}`, true).then((response) => response.json());
  }
);

export const datasetSlice = createSlice({
  name: datasetSliceName,
  initialState,
  reducers: {
    setLibraryFiles(state, action) {
      state.libraryFiles = action.payload;
    },
    setIsInvalidFolderHandle(state, action) {
      state.isInvalidFolderHandle = action.payload;
    },
    setIsUserHasSharedFolderAccess(state, action) {
      state.isUserHasSharedFolderAccess = action.payload;
    },
    setFileSizeProperties(state, action) {
      const { spaceUsed, maxStorageSize } = action.payload || {};
      state.filesSizePercentage = getFilesSizeInPercentage(spaceUsed, maxStorageSize);
      state.filesSize = parseFileSize(spaceUsed);
    },
    setLibraryFolderStructureByName(state, action) {
      const { name, value } = action.payload || {};
      state[name] = value;
    },
    setFilePath(state, action) {
      state.filePath = action.payload;
    },
    setToFilePath(state, action) {
      state.filePath = [...state.filePath, action.payload];
    },
    updateFilenameInFilePath(state, action) {
      const lastIndex = state.filePath.length - 1;
      if (lastIndex >= 0) {
        state.filePath[lastIndex].FileName = action.payload;
      }
    },
    setLibrarySharedWithMe(state, action) {
      state.sharedWithMeFiles = action.payload;
    },
    setLibrarySharedWithTeam(state, action) {
      state.sharedWithMyTeamFiles = action.payload;
    },
    addFilesToTeamLibrary(state, action) {
      state.sharedWithMyTeamFiles = [...action.payload, ...state.sharedWithMyTeamFiles];
    },
    unsetLibraryFiles(state) {
      state.libraryFiles = null;
    },
    setProcessingFiles(state, action) {
      state.processingFiles = [...action.payload];
    },
    addFilesToLibraryFiles(state, action) {
      state.libraryFiles = [...action.payload, ...state.libraryFiles];
    },
    setCurrentFile(state, action) {
      state.currentFile = action.payload;
    },
    setSortingData(state, action) {
      state.sortingData = action.payload;
    },
    setIsPublicResource(state, action) {
      state.isPublicResource = action.payload.isPublicResource;
    },
    resetCombineFolderStructure(state) {
      state.combineFolderStructure = state.libraryFiles.map((e) => e.metadata);
    },

    setIsLoadingLibrary(state, action) {
      state.isLoadingLibrary = action.payload;
    },
    selectFile(state, action) {
      state.selectedFiles = [...state.selectedFiles, action.payload];
    },
    selectFiles(state, action) {
      state.selectedFiles = [...state.selectedFiles, ...action.payload];
    },
    unSelectFileById(state, action) {
      state.selectedFiles = state.selectedFiles.filter(
        (selectedFile) => selectedFile.FileUuid !== action.payload
      );
    },
    unselectFiles(state, { payload: _selectedFiles }) {
      state.selectedFiles = state.selectedFiles.filter((selectedFile) =>
        _selectedFiles.every(({ FileUuid }) => FileUuid !== selectedFile.FileUuid)
      );
    },
    selectMultipleFiles(state, action) {
      state.selectedFiles = action.payload;
    },
    unSelectAllFiles(state) {
      state.selectedFiles = [];
    },
    setCurrentDirectory(state, action) {
      state.sortingData.currentDirectory = action.payload;
      state.currentDir = action.payload;
    },
    setLibrarySearchResult(state, action) {
      state.librarySearchResult = loadLibrarySearchResults(state, action.payload);
    },
    refreshSearchResult(state) {
      state.librarySearchResult = sortColumnData(
        state.librarySearchResult,
        state.sortingData.direction,
        state.sortingData.columnName
      );
    },
    clearLibrarySearchResult(state) {
      state.librarySearchResult = null;
    },
    setIsActiveFolderTree(state, action) {
      state.isActiveFolderTree = action.payload;
    },
    setCurrentDataset(state, action) {
      state.currentDataset = action.payload;
    },
    unsetSharedWithMeFiles(state) {
      state.sharedWithMeFiles = null;
    },
    unsetSharedWithMyTeamFiles(state) {
      state.sharedWithMyTeamFiles = null;
    },
    setHeaderCommentThreads(state, { payload }) {
      if (state?.currentFile) {
        state.currentFile.HeaderCommentThreads = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFilePath.pending, (state, action) => {
      const { showLoader } = action.meta.arg;
      showLoader && (state.loading = true);
    });
    builder.addCase(updateFilePath.fulfilled, (state, action) => {
      const { filePath } = action.payload;

      state.filePath = filePath;
    });
    builder.addCase(updateFilePath.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(updateCombineFolderStructure.fulfilled, (state, action) => {
      const { folderHandle, folderContent } = action.payload;

      state.combineFolderStructure = refreshFolderStructure(
        state,
        folderHandle,
        folderContent,
        state.combineFolderStructure
      );
    });

    builder.addCase(fetchOpenedFile.fulfilled, (state, action) => {
      state.currentFile = action.payload;
    });
    builder.addCase(fetchOpenedFileStats.fulfilled, (state, action) => {
      state.stats = action.payload;
    });
  },
});

const loadLibrarySearchResults = (state, payload) => {
  const modified = payload
    .map((e) => {
      return e?.shared ? { metadata: e.metadata, shared: e.shared } : { metadata: e };
    })
    .map((e) => {
      const columnsCount = e?.shared ? getColumnsCount(e) : getColumnsCount(e.metadata);

      return {
        ...e,
        FileColumns: columnsCount,
      };
    });

  const sorted = sortColumnData(
    modified,
    state.sortingData.direction,
    state.sortingData.columnName
  );

  return sorted;
};

const refreshFolderStructure = (state, folderHandle, folderContent, _folderStructure) => {
  const folderStructure = JSON.parse(JSON.stringify(_folderStructure));
  const libraryFiles = JSON.parse(JSON.stringify(state.libraryFiles));

  function updateChildren(md, folderContent, folderHandle) {
    if (folderHandle === md?.FileUuid) {
      return folderContent.map((e) => {
        const md = e.metadata;
        md.Children = [];
        return md;
      });
    } else if (md.Children?.length > 0) {
      return md.Children.map((child) => {
        child.Children = updateChildren(child, folderContent, folderHandle);
        return child;
      });
    } else {
      return md.Children;
    }
  }

  let result = libraryFiles;
  if (folderHandle && folderHandle !== '') {
    result = folderStructure.map((e) => {
      e.Children = updateChildren(e, folderContent, folderHandle);
      return e;
    });
  }
  return result;
};

export const {
  setLibraryFiles,
  unsetLibraryFiles,
  addFilesToLibraryFiles,
  setProcessingFiles,
  addFilesToTeamLibrary,
  setCurrentFile,
  setSortingData,
  setFilePath,
  setToFilePath,
  setSharedTreeActive,
  setIsPublicResource,
  setCurrentDirectory,
  selectFile,
  selectFiles,
  unSelectFileById,
  unselectFiles,
  selectMultipleFiles,
  unSelectAllFiles,
  resetCombineFolderStructure,
  setIsLoadingLibrary,
  setLibrarySearchResult,
  refreshSearchResult,
  clearLibrarySearchResult,
  setIsActiveFolderTree,
  setCurrentDataset,
  unsetSharedWithMeFiles,
  unsetSharedWithMyTeamFiles,
  setHeaderCommentThreads,
  setLibrarySharedWithMe,
  setLibrarySharedWithTeam,
  setFileSizeProperties,
  setLibraryFolderStructureByName,
  setIsUserHasSharedFolderAccess,
  setIsInvalidFolderHandle,
  updateFilenameInFilePath,
} = datasetSlice.actions;

export default datasetSlice.reducer;
