import ShowToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import { putRaw } from '@/Utils/API';
import {
  FILE_PERMISSIONS,
  MODAL_PUBLISH_TO_WEB,
  MODAL_SHARE_FILE,
  TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_SPREADSHEET,
} from '@/Utils/constants';

import { formatUrlSafeFileName } from '@/Utils/utils';
import { userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withModal from '../withModalHOC';
import PublishToWeb from './PublishToWeb';

import { isWorldReadable } from '@/Utils/sharing';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const PublishToWebModal = ({ show }) => {
  const dispatch = useDispatch();

  const currentFile = useCurrentFile();
  const {
    user: {
      userProperties: { canUseEmbedSheet },
    },
  } = useSelector((state) => state[userDataSliceName]);
  const { metadata, IsWorldReadable } = currentFile || {};
  const { FileName, FileUuid } = metadata || {};

  const [tab, selectTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [publishSetting, setPublishSetting] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const isPublishToWebDisabled = !canUseEmbedSheet;

  useEffect(() => {
    setIsPublic(isWorldReadable(currentFile) || IsWorldReadable);
  }, [currentFile, IsWorldReadable]);

  const shareFileUrl = `${window.location.origin}/${URL_SPREADSHEET}/${formatUrlSafeFileName(
    FileName
  )}/${FileUuid}`;

  const embedOptions = [
    {
      label: 'Script',
      content: `<div class="gigasheet-embed" data-host="${
        window.location.host
      }" data-name="${formatUrlSafeFileName(
        FileName
      )}" data-handle="${FileUuid}"><script src="https://${
        window.location.host
      }/scripts/embed.min.js"></script></div>`,
    },
    {
      label: 'IFrame',
      content: `<iframe src="${shareFileUrl}" style="background: transparent; border: 1px #cccccc" scrolling="no" frameborder="0" marginheight="0px" marginwidth="0px" height="800" width="100%" allowfullscreen></iframe>`,
    },
  ];

  const handleCopy = () => navigator.clipboard.writeText(embedOptions[tab].content);

  const handlePublishSetting = async () => {
    setIsLoading(true);

    const permissions = isPublic ? [] : [0];
    let endpoint = '';
    let payload = {};
    try {
      endpoint = `${URL_DATASET}/${FileUuid}/${FILE_PERMISSIONS}`;
      payload = {
        permissions,
      };
      const filePermissions = await putRaw(endpoint, payload);

      if (!filePermissions) {
        ShowToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      } else {
        dispatch(fetchOpenedFile(FileUuid));
      }
    } catch (e) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setIsLoading(false);
      setPublishSetting(false);
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={() => dispatch(hideAllModals())}
      title='Publish to Web'
      articleID='69000837760'
      size='small'
    >
      <>
        <div className='text-sm text-ui !mt-[-18px] mb-6'>
          <div className='mb-4 text-xs' data-cy='subtitle'>
            {isPublic && !isPublishToWebDisabled ? (
              <span className='text-capri-900'>This sheet is published to the web.</span>
            ) : (
              <span className='text-ui-helper'>This sheet is not published to the web.</span>
            )}
          </div>
          Make your content visible to anyone by embedding it on your webpage.
        </div>
        <PublishToWeb
          isLoading={isLoading}
          isPublic={isPublic}
          tabs={embedOptions}
          tab={tab}
          selectTab={selectTab}
          publishSetting={publishSetting}
          setPublishSetting={setPublishSetting}
          handlePublishSetting={handlePublishSetting}
          isPublishToWebDisabled={isPublishToWebDisabled}
        />
      </>
      <hr className='bg-gray-400' />
      <div className='flex justify-between pt-4'>
        <Button onClick={() => dispatch(showModal({ name: MODAL_SHARE_FILE }))}>
          Share Settings
        </Button>
        <div className='flex gap-2'>
          <Button onClick={() => dispatch(hideAllModals())} variant='ghost'>
            Cancel
          </Button>
          {isPublic ? (
            <Button onClick={handleCopy} color='oceanBlue'>
              Copy
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_PUBLISH_TO_WEB })(PublishToWebModal);
