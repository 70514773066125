import { commonSVG } from '../Assets/icons';
import config from '../auth_config.json';

import { addSeconds, formatISO } from 'date-fns';
export const TOTAL_FILES_SIZE_LIMIT = '10GB';
export const userRolesKey = 'https://gigasheet.com/roles';
export const userMetadataKey = 'https://gigasheet.com/usermetadata';
export const userLoginMethodKey = 'https://gigasheet.com/login_method';
export const userTeamKey = 'https://gigasheet.com/org_id';
export const userTeamNameKey = 'https://gigasheet.com/org_name';
export const rolesWithAccessTo = {
  apiKey: ['giga-feature-api-key'],
};

export const GIGASHEET_SUPPORT_ROLE = 'gigasheet-support';
export const GIGASHEET_API_KEY_ROLE = 'gigasheet-feature-api-key';

export const FILE_UUID_LENGTH = 36;
export const FILE_UUID_REGEX = /^[a-f0-9]{8}_[a-f0-9]{4}_[a-f0-9]{4}_[a-f0-9]{4}_[a-f0-9]{12}$/;

/* API KEY CONSTANTS */
export const LINK_DATASETS_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/datasets`;
export const LINK_SETTINGS_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/settings`;
export const LINK_GENERATE_API_KEY_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/callback-api-key`;
export const LINK_GENERATE_API_KEY = `http://login.gigasheet.com/authorize?client_id=${
  config.clientId
}&response_type=code&redirect_uri=${encodeURIComponent(
  LINK_GENERATE_API_KEY_REDIRECT_URI
)}&scope=openid+email+offline_access&audience=https%3A%2F%2Fdev-5vgruhlg.auth0.com%2Fuserinfo`;

/* CHARGEBEE */
export const CHARGEBEE_SITE_PROD = 'gigasheet';
export const CHARGEBEE_SITE_TEST = 'gigasheet-test';

export const sortDirection = {
  ascending: 'ascending',
  descending: 'descending',
};

export const upgradeAccountTypes = {
  export: 'export',
  maxStorageSize: 'maxStorageSize',
  enterprise: 'enterprise',
  checkout: 'checkout',
  apiKeyRequest: 'apiKeyRequest',
};

export const DEFAULT_UPGRADE_SIZE = {
  maxStorageSize: '10GB',
  maxFileSize: '10GB',
  billingPeriod: 'month',
};

export const ONBOARDED = 'Onboarded';
export const MY_LIBRARY = 'Library';
export const SHARED_WITH_ME = 'Shared With Me';
export const TEAM_WORKSPACE = 'Team Workspace';
export const ALL_WORKSPACES = 'All Workspaces';

export const URL_DATASET = 'dataset';
export const URL_DATASETS = 'datasets';
export const URL_LIBRARY = 'library';
export const URL_SPREADSHEET = 'spreadsheet';
export const URL_SETTINGS = 'settings';
export const URL_AI = 'ai';

/* ROUTES */
export const ROUTE_CALLBACK = '/callback';
export const ROUTE_CALLBACK_API_KEY = '/callback-api-key';
export const ROUTE_NEXUS = '/nexus';
export const ROUTE_CONFIRMATION = '/confirmation';
export const ROUTE_DATASETS = `/${URL_DATASETS}`;
export const ROUTE_LANDING = '/';
export const ROUTE_SPREADSHEET = `${URL_SPREADSHEET}/:name/:uuid`;
export const ROUTE_IMPORTS = '/imports';

export const ROUTES_SIGN_UP = ['/sign-up', '/signup', '/signUp', '/SignUp'];
export const ROUTE_SETTINGS = '/settings';

export const AGGREGATION = 'aggregation';
export const AUTOCOMPLETE = 'autocomplete';
export const CLIENT_STATE = 'clientstate';
export const COLUMNS = 'columns';
export const COMBINE = 'combine';
export const COPY = 'copy';
export const COUNT = 'count';
export const CREATE = 'create';
export const DELETE_MULTIPLE_COLUMNS = 'delete-multiple-columns';
export const DETAILS = 'details';
export const DIRECTORY = 'directory';
export const FILE = 'file';
export const FILE_PERMISSIONS = 'file-permissions';
export const ORGANIZATION_PERMISSIONS = 'organization-permissions';
export const FILES = 'files';
export const FILTER = 'filter';
export const FILTER_TEMPLATES = 'filter-templates';
export const GENERATE_HANDLE = 'generateHandle';
export const HEADERS = 'headers';
export const NOTE = 'note';
export const ON_SHEET = 'on-sheet';
export const OPT_OUT = 'opt-out';
export const PERMISSIONS = 'permissions';
export const PIVOT = 'pivot';
export const S3 = 's3';
export const SHARE = 'share';
export const UPLOAD = 'upload';
export const USER_FILE_PERMISSIONS = 'user-file-permissions';
export const RENAME = 'rename';
export const REQUEST_ACCESS = 'request-access';
export const PREVIEW = 'preview';
export const API_KEY_REQUEST = 'request-api-access';
export const CONDITION_LABEL_COLUMN = 'conditional-label-column';
export const FIND_AND_REPLACE = 'find-and-replace';
export const FORMULA = 'formula';
export const FORMULAS = 'formulas';
export const FUNCTIONS = 'functions';
export const ITERATOR_COLUMN = 'iterator-column';
export const DOCS = 'docs';
export const DEDUPLICATE_ROW_COUNT = 'deduplicate-rows-count';
export const VALIDATE = 'validate';
export const BLANK = 'blank';
export const CHANGED_COLUMN_POSITION = 'change-column-position';
export const LIVESHARES = 'liveshares';
export const LIVESHARE = 'liveshare';
export const COMBINE_FILES_USING_COLUMN_ORDER = 'column-order';
export const COMBINE_FILES_USING_COLUMN_HEADERS = 'column-headers';

export const SIGNUP_ROUTES = ['/signup', '/sign-up', '/signUp', '/SignUp'];

/* QUERY PARAMETERS */
export const QUERY_PARAM_DIR = 'dir';
export const QUERY_PARAM_OMIT_EMPTY_VALUES = 'omitEmptyValues';
export const QUERY_PARAM_PROVIDER = 'provider';
export const QUERY_PARAM_TOKEN = 'token';
export const QUERY_PARAM_URL = 'url';
export const QUERY_PARAM_FILE_NAME = 'file_name';
export const QUERY_PARAM_CHECKOUT = 'checkout';
export const QUERY_PARAM_CHECKOUT_ID = 'id';
export const QUERY_PARAM_CHECKOUT_STATE = 'state';
export const QUERY_PARAM_CHECKOUT_PERIOD = 'checkout_period';
export const QUERY_PARAM_CHECKOUT_STORAGE = 'checkout_storage';
export const QUERY_PARAM_CHECKOUT_UPGRADING = 'upgrading';
export const QUERY_PARAM_COMMENT_THREAD = 'comment_thread';

/* LOCAL STORAGE */
export const LOCAL_STORAGE_IMPORT_PROVIDER = 'import_provider';
export const LOCAL_STORAGE_IMPORT_TOKEN = 'import_token';
export const LOCAL_STORAGE_IMPORT_URL = 'import_url';
export const LOCAL_STORAGE_IMPORT_FILE_NAME = 'import_file_name';
export const LOCAL_STORAGE_CHECKOUT = 'checkout';
export const LOCAL_STORAGE_CHECKOUT_PERIOD = 'checkout_period';
export const LOCAL_STORAGE_CHECKOUT_STORAGE = 'checkout_storage';
export const LOCAL_STORAGE_COMMENT_THREAD = 'comment_thread';

/* POPUP */
export const MODAL_CALC_LENGTH = 'calcLengthModal';
export const MODAL_CHANGE_CASE = 'changeCaseModal';
export const MODAL_CHANGE_DATA_TYPE = 'changeDataTypeModal';
export const MODAL_CLEANUP_UNIX_TIME = 'cleanupUnixTimeModal';
export const MODAL_CLEANUP_COMPANY_NAME = 'cleanupCompanyNameModal';
export const MODAL_COMBINE_FILES = 'combineModal';
export const MODAL_COMBINED_COLUMNS = 'combinedColumnsModal';
export const MODAL_CREATE_FOLDER = 'createFolderModal';
export const MODAL_CREATE_ACCOUNT = 'createAccountModal';
export const MODAL_UPGRADE_REQUIRED = 'upgradeRequiredModal';
export const MODAL_CROSS_FILE_VLOOKUP = 'crossFileVLookupModal';
export const MODAL_DELETE_DUPLICATES = 'deleteDuplicatesModal';
export const MODAL_DELETE_DUPLICATES_CONFIRM = 'deleteDuplicatesConfirmModal';
export const MODAL_DELETE_COLUMN = 'deleteColumnModal';
export const MODAL_DELETE_COLUMNS = 'deleteColumnsModal';
export const MODAL_PASTE_FROM_CONTEXT_MENU = 'pasteFromContextMenuModal';
export const MODAL_CHECKOUT = 'checkoutModal';
export const MODAL_DELETE_ROW = 'deleteRowModal';
export const MODAL_DELETE_ROWS = 'deleteRowsModal';
export const MODAL_DELETE_FILE = 'deleteFileModal';
export const MODAL_EDIT_FILE = 'editFileModal';
export const MODAL_MOVE_FILE = 'moveFileModal';
export const MODAL_REMOVE_ACCESS = 'removeAccessModal';
export const MODAL_ENRICHMENT = 'enrichmentModal';
export const MODAL_EXPLODE_DATE = 'explodeDateModal';
export const MODAL_EXPLODE_JSON = 'explodeJsonModal';
export const MODAL_EXPORT_DOWNLOAD = 'exportDownloadModal';
export const MODAL_EXPORT_SPREADSHEET = 'exportSpreadsheetModal';
export const MODAL_EXTRACT_DOMAIN_NAME = 'extractDomainNameModal';
export const MODAL_FILTER_PANEL = 'filterPanelModal';
export const MODAL_DECODE_BASE64 = 'decodeBase64Modal';
export const MODAL_GROUP_BY = 'groupByModal';
export const MODAL_MAKE_COPY = 'makeCopyModal';
export const MODAL_REQUIRE_ACCOUNT = 'makeRequireAccountModal';
export const MODAL_COMMENTS = 'commentsModal';
export const MODAL_MAKE_COPY_SUCCESS = 'makeCopySuccessModal';
export const MODAL_SHARE_FILE = 'shareFileModal';
export const MODAL_SPLIT_COLUMN = 'splitColumnModal';
export const MODAL_TRIM_WHITESPACE = 'trimWhitespaceModal';
export const MODAL_UPLOAD_FILE = 'uploadModal';
export const MODAL_UPLOAD_FILE_WARNING = 'uploadFileWarningModal';
export const MODAL_MAKE_COPY_FILE = 'makeCopyFileModal';
export const MODAL_DATA_CONNECTORS_REQUESTED = 'requestedConnectorsModal';
export const MODAL_UPGRADE_ACCOUNT = 'upgradeAccountModal';
export const MODAL_SORT_COLUMN = 'sortColumnModal';
export const MODAL_CUSTOM_ENRICHMENT = 'customEnrichmentModal';
export const MODAL_DATA_ENRICHMENT = 'dataEnrichmentModal';
export const MODAL_IP_GEOLOCATION_ENRICHMENT = 'ipGeolocationEnrichment';
export const MODAL_EMAIL_ALEXA_RANK_ENRICHMENT = 'emailAlexaRankEnrichment';
export const MODAL_IP_OSINT_ENRICHMENT = 'ipOsintEnrichment';
export const MODAL_REVENUE_BASE = 'revenueBase';
export const MODAL_IF_CONDITIONS = 'ifConditionsModal';
export const MODAL_FIND_AND_REPLACE = 'findAndReplace';
export const MODAL_FIND_AND_REPLACE_CONFIRM = 'findAndReplaceConfirm';
export const MODAL_FORMULAS = 'formulasModal';
export const MODAL_UPGRADE_TEAM_MEMBER = 'upgradeTeamMemberModal';
export const MODAL_PAPERCUT = 'papercutsModal';
export const MODAL_PUBLISH_TO_WEB = 'publishToWebModal';
export const MODAL_UPGRADE_TO_TEAM = 'upgradeToTeam';
export const MODAL_INVITE_TEAM = 'InviteTeam';
export const MODAL_SPLIT_SHEET = 'splitSheetModal';
export const MODAL_RENAME_COLUMNS = 'renameColumnModals';
export const MODAL_WARNING_MESSAGE = 'warningMessageModals';
export const MODAL_EXCEEDS_LIMITS = 'exceedsLimits';
export const MODAL_EXCEED_STORAGE_SIZE_LIMIT = 'exceedStorageSizeLimit';
export const MODAL_AUDIT_LOG = 'auditLogPopup';
export const MODAL_URL_FOR_BI_AND_SPREADSHEETS = 'urlForBiAndSpreadsheets';
export const MODAL_BUSINESS_PAYWALL = 'businessPaywall';
export const MODAL_AUTOMATION_API = 'automationAPI';
export const MODAL_CONTACT_SALES = 'contactSalesModal';
export const MODAL_ZAPIER_FULL_EXPERIENCE = 'zapierFullExperience';
export const MODAL_CONFIRM_LEAVE_TEAM = 'confirmLeaveTeamModal';
export const MODAL_IMPORT_FROM_CONNECTOR = 'importFromConnectorModal';
export const MODAL_SUGGEST_AN_INTEGRATION = 'suggestAnIntegrationModal';
export const MODAL_GENERAL_UPGRADE = 'generalUpgradeModal';
export const MODAL_ADD_VIEW = 'addView';
export const MODAL_DELETE_VIEW = 'deleteView';
export const MODAL_CURRENCY = 'currencyFormat';

/* LINKS */
export const LINK_LOGIN = `${process.env.REACT_APP_WEB_APP_BASE_HOST}/login`;
export const LINK_CREATE_ACCOUNT = `${process.env.REACT_APP_WEB_APP_BASE_HOST}/signup`;
export const LINK_PRICING = 'https://www.gigasheet.com/pricing';
export const LINK_MIGRATE_ACCOUNT =
  'https://docs.google.com/forms/d/e/1FAIpQLSdnJfrpzMvfAhyiqxnSeViXoU1W2drD-6a5PKtq5d9sQ9BLEQ/viewform';
export const LINK_UPGRADE_ACCOUNT = 'https://buy.stripe.com/fZe14J0ZK0Hle763cd?prefilled_email=';
export const LINK_UPGRADE_ACCOUNT_YEARLY =
  'https://buy.stripe.com/7sIcNr0ZK1Lp2oocMO?prefilled_email=';
export const LINK_ENTERPRISE_INFO = 'https://www.gigasheet.com/enterprise';
export const LINK_SUPPORT_DOCS = 'https://support.gigasheet.com/support/solutions';
export const LINK_TERMS_OF_USE = 'https://www.gigasheet.com/terms-of-use';
export const LINK_PRIVACY_POLICY = 'https://www.gigasheet.com/privacy';
export const LINK_HOW_WE_MAKE_MONEY = 'https://www.gigasheet.com/security#Make-Money';
export const LINK_SUPPORT_DOCS_REGEX =
  'https://support.gigasheet.com/support/solutions/articles/69000808658-find-in-file-regular-expression-regex-search';
export const LINK_SAVE_AS_NEW_SHEET =
  'https://support.gigasheet.com/support/solutions/articles/69000819880-save-as-a-new-sheet';
export const LINK_HOW_TO_ZIP_A_FILE =
  'https://support.gigasheet.com/support/solutions/articles/69000837005-how-to-zip-a-file';
export const LINK_GETTING_STARTED = 'http://gigasheet.readme.io/';
export const LINK_HOW_TO_USE_FORMULAS =
  'https://support.gigasheet.com/support/solutions/articles/69000838560-formulas';
export const LINK_API_REQUEST = 'https://gigasheet.com/api-request';
export const LINK_PRICING_PREMIUM = 'https://gigasheet.com/pricing/premium';
export const LINK_CONTACT_US = 'https://gigasheet.com/contact';
export const LINK_SHEET_ASSISTANT_SUPPORT =
  'https://gigasheet.freshdesk.com/support/solutions/articles/69000840404-sheet-assistant';
export const LINK_GIGASHEET_YOUTUBE_PLAYLIST = 'https://www.youtube.com/@gigasheet/playlists';
export const LINK_FRESHDESC_SUPPORT = 'https://gigasheet.freshdesk.com/';
export const LINK_SPREADSHEET_GOOGLE_SHEET_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850266-url-for-bi-spreadsheets-google-sheets';
export const LINK_SPREADSHEET_LOOKER_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850267-url-for-bi-spreadsheets-looker';
export const LINK_SPREADSHEET_EXEL_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850268-url-for-bi-spreadsheets-excel';
export const LINK_SPREADSHEET_POWER_BI_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850269-url-for-bi-spreadsheets-powerbi';
export const LINK_SPREADSHEET_DOMO_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850270-url-for-bi-spreadsheets-domo';
export const LINK_SPREADSHEET_TABLEAU_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000850271-url-for-bi-spreadsheets-tableau';
export const LINK_SPREADSHEET_LIVESHARING_SUPPORT =
  'https://support.gigasheet.com/support/solutions/folders/69000641682';
export const LINK_TEAMS_SUPPORT = 'https://www.gigasheet.com/teams';
export const LINK_DATA_CONNECTORS =
  'https://support.gigasheet.com/support/solutions/articles/69000853995-data-connectors';
export const GIGASHEET_API_SUPPORT =
  'https://gigasheet.freshdesk.com/support/solutions/articles/69000836344-introduction-to-the-gigasheet-api';
export const LINK_BUSINESS_CALENDLY =
  'https://calendly.com/steve-schohn/gigasheet-business?month=2024-07';
export const LINK_ENTERPRISE_CALENDLY = 'https://calendly.com/steve-schohn/gigasheet-enterprise';
export const LINK_VIEWS_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000856682-views';
export const LINK_DATA_TYPES_SUPPORT =
  'https://support.gigasheet.com/support/solutions/articles/69000776199-supported-data-types';

/* PERMISSIONS */
export const PERMISSION_NONE = -1;
export const PERMISSION_READ = 0;
export const PERMISSION_WRITE = 1;
export const PERMISSION_DENIED = 2;
export const UPLOADING_FILES_OVER_LIMIT = 3;
export const UPLOADING_OVER_LIMIT_FILE_SIZE = '150GB';

/* UPPY */
export const BROWSE_UPLOAD = 'browseUpload';
export const URL_UPLOAD = 'urlUpload';

/* SEARCH PARAMS */
export const OPEN_SHARE_POPUP_PARAM = 'open-share-popup';
export const FILL_SHARE_EMAIL_PARAM = 'fill-share-email';

/* TOAST TEXTS */
export const TOAST_TEXT_ENRICHMENT_ERROR = 'Enrichment error';
export const TOAST_TEXT_RENAME_FILE_ERROR = 'Error renaming file';
export const TOAST_TEXT_SAVE_NOTE_ERROR = 'Error updating description';
export const TOAST_TEXT_FILE_NOTE_EXCEEDS_LIMIT_ERROR = 'File description exceeds character limit';
export const TOAST_TEXT_UPLOAD_FILE_ERROR = 'File failed to upload';
export const TOAST_TEXT_UNABLE_SAVE_FILTER_ERROR = 'Unable to save filter';
export const TOAST_TEXT_INCORRECT_API_KEY_ERROR = 'Incorrect API key';
export const TOAST_TEXT_CREATING_FOLDER_ERROR = 'Error creating folder';
export const TOAST_TEXT_CHANGE_CASE_ERROR = 'Failed to change the case';
export const TOAST_TEXT_APPLY_FUNCTION_ERROR = 'Failed to apply function';
export const TOAST_TEXT_RENAME_FILE_SUCCESS = 'File renamed successfully';
export const TOAST_TEXT_SAVE_FILE_NOTE_SUCCESS = 'File description updated successfully';
export const TOAST_TEXT_ENRICHMENT_RUNNING_ERROR = 'Error running enrichment';
export const TOAST_TEXT_CHANGE_DATA_TYPE_ERROR = 'Failed to change data type';
export const TOAST_TEXT_UNABLE_TO_EXPORT_FILE_ERROR = 'Unable to export file.';
export const TOAST_TEXT_QUOTA_EXCEEDED_ERROR = 'Quota exceeded for free API key';
export const TOAST_TEXT_NETWORK_ERROR = 'We have seemed to have lost connection.';
export const TOAST_TEXT_VERIFICATION_EMAIL_SENT_INFO = 'Verification email sent.';
export const TOAST_TEXT_SHARE_FILE_PUBLICLY_ERROR = 'Failed to share file publicly';
export const TOAST_TEXT_UNABLE_DELETE_FILTER_TEMPLATE_ERROR = 'Unable to delete template.';
export const TOAST_TEXT_ADD_FILE_TO_FOLDER_ERROR = 'Error adding file into the folder';
export const TOAST_TEXT_FILE_IS_ALREADY_EXPORTED_ERROR = 'Your file is already being exported';
export const TOAST_TEXT_UNABLE_TO_RESEND_EMAIL = 'Unable to resend email. Please contact support';

export const TOAST_TEXT_BILLING_ERROR =
  'Unable to manage billing. Please contact support@gigasheet.com.';
export const TOAST_TEXT_SAVED_FILTER_COLUMNS_INCOMPATIBLE_ERROR =
  'Unable to apply saved filter. Columns may be incompatible.';
export const TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR =
  'Unable to process request. Please contact support team for more information.';
export const TOAST_TEXT_NO_DUPLICATE_VALUES_INFO = 'No duplicate values found.';
export const TOAST_TEXT_DUPLICATE_VALUES_COUNT_ERROR = 'Error finding duplicate values, try again.';
export const TOAST_TEXT_GENERAL_SPREADSHEET_ERROR =
  'Something did not work as expected. If this problem persists on a refresh, contact support@gigasheet.com';
export const TOAST_TEXT_CUSTOM_ENRICHMENT_SUCCESS =
  "This could take up to 60 minutes. We'll email you with updates.";
export const TOAST_TEXT_CUSTOM_ENRICHMENT_COMPLETE = 'Custom Enrichment Complete!';
export const TOAST_TEXT_COMBINING_FILES_FAILED =
  'Combining Files Failed. Please contact support@gigasheet.com';

/* TOAST TYPES */
export const TOAST_TYPE_INFO = 'info';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_RECONNECTION = 'reconnection';

/*  SPREADSHEET NETWORK ERROR TEXTS */
export const currentFileErrorTexts = {
  401: 'Bummer, you no longer have access to this file',
  403: 'Bummer, you no longer have access to this file',
  500: "Sorry, the file that you requested is not accessible. Make sure that you have the correct URL and the owner of the file hasn't deleted it.",
};

/* DATASET NETWORK ERROR TEXTS */
//update it if there are new error cases
export const SESSION_EXPIRED_OR_UNAUTHORIZED = 'unauthorized';
export const GENERAL_ERROR_TEXT = 'error in datasets request';

/* SHARE FILE UTILS CONSTANTS */
export const PUBLIC_LABEL = 'Public';
export const TEAM_LABEL = 'Team Workspace';
export const RESTRICTED_LABEL = 'Restricted';
export const CAN_VIEW_LABEL = 'view';
export const CAN_VIEW_AND_EDIT_LABEL = 'view and edit';
export const DELETE_LABEL = 'delete';
export const PUBLIC_OPTION_VALUE = '0';
export const TEAM_OPTION_VALUE = '1';
export const RESTRICTED_OPTION_VALUE = '2';
export const CAN_VIEW_OPTION_INDEX = '0';
export const CAN_VIEW_AND_EDIT_OPTION_INDEX = '1';
export const ACCESS_DENIED_OPTION_INDEX = '2';
export const DELETE_OPTION_INDEX = '-1';
export const FAKE_TEAM_OPTION_VALUE = 'fakeTeamOptionValue';

/* FILTER PANEL TYPES OF INPUTS FOR FILTER VALUE */
export const INPUT_FOR_FILTER_VALUE_WITH_SUGGESTIONS = 'inputForFilterValueWithSuggestions';
export const NO_INPUT_FOR_FILTER_VALUE = 'noInputForFilterValue';
export const INPUT_FOR_MULTIPLE_FILTER_VALUES = 'inputForMultipleFilterValue';
export const DEFAULT_INPUT_FOR_FILTER_VALUE = 'defaultInputForFilterValue';

/* ERRORS */
export const AGGREGATION_ERROR = 'gigasheet_aggregation_error_12345'; // this should be a unique string to minimize the likelihood of clashing with user data

/* FILTER PANEL TYPES */
export const FILTER_MODEL_CNF_ = 'filterModelCnf';
export const FILTER_MODEL_AGGREGATE_CNF_ = 'filterModelAggregationsCnf';

/* DELETE COLUMNS */
export const DELETE_HIDDEN_COLUMNS = 'delete_hidden_columns';
export const DELETE_VISIBLE_COLUMNS = 'delete_visible_columns';
export const DELETE_SELECTED_COLUMNS = 'delete_selected_columns';

/* SHEET ASSISTANT */
export const ACTION_FILTER = 'filter';
export const ACTION_GROUP = 'group';
export const ACTION_SORT = 'sort';
export const ACTION_AGGREGATE = 'aggregate';
export const ACTION_AGGREGATE_FILTER = 'aggregate-filter';
export const ACTION_SHOW_COLUMNS = 'show';

/* MOBILE DRAWER */
export const SHEET_ASSISTANT = 'sheet_assistant';
export const COLUMN_MANAGER = 'column_manager';

export const OPERATION_STATUSES = {
  DONE: 'Done',
  WORKING: 'Working',
  ERROR: 'Error',
};

/* QUERY SELECTORS */
export const QUERY_SELECTOR_FORMULA_PANEL = '.ag-root.ag-unselectable.ag-layout-normal';
export const QUERY_SELECTOR_FORMULA_PANEL_BTN =
  QUERY_SELECTOR_FORMULA_PANEL + ' > span > div > button';

export const SUPPORT_TIPS_FOR_LOADER = [
  'You can combine two or more sheets to create a single sheet.',
  'You can share your sheet with your team members? Just click the yellow share button!',
  'Duplicate values can be deleted by using the delete duplicate feature.',
  'Values can be flagged by right clicking and selecting  "Good, Suspicious, or Bad".',
  'Columns can be split into two columns by using the "Split Columns" feature under Data > Data Cleanup',
  'You can detect invalid emails by using our Email Format Check under Enrichments.',
  'You can hide and unhide columns using the column picker in the right panel.',
  'You can export group and filtered data in the premium plan.',
  'Groups will allow you to aggregate your data based on values in a column.',
  'You can filter on column values and on value aggregations.',
  'You can use the Sheet AI to write formulas.',
];

export const CONNECTORS_BUSINESS = [
  'Salesforce Report',
  'Salesforce Object',
  'Databricks Clone (Token)',
  'Databricks Delta Share',
  'Snowflake',
];
