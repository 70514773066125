import { useEffect, useMemo, useState } from 'react';

import {
  MODAL_FIND_AND_REPLACE,
  MODAL_FIND_AND_REPLACE_CONFIRM,
  MODAL_PAPERCUT,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import withModal from '../withModalHOC';

import CustomDatePicker from '@/Components/CustomDatePicker/CustomDatePicker';
import { Button } from '@/Components/UI/Button/Button';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { Select } from '@/Components/UI/Form/Select/Select';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import Modal from '@/Components/UI/Modal/Modal';
import Icon from '@/Components/UI/Icon/Icon';

import { getFilterType } from '@/Utils/getFilterType';
import parseArrayToMatrix from '@/Utils/parseArrayToMatrix';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { setReduxFilterModelCnf } from '@/redux/reducers/spreadsheetSlice';
import { parseISO, getTime, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useDispatch, useSelector } from 'react-redux';
import { filterConditionType } from './helpers';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { ROW_NUMBER_COLUMN_NAME } from '@/Utils/gridConstants';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const FindAndReplacePopup = ({ hideModal, show, getGridRequestData }) => {
  const dispatch = useDispatch();

  const { columnDefs } = useSelector((store) => store[spreadsheetSliceName]);
  const currentFile = useCurrentFile();
  const { user } = useSelector((store) => store[userDataSliceName]);
  const { useFindReplaceSettings } = user.userProperties;

  const [caseSensitive, setCaseSensitive] = useState(false);
  const [colIDs, setColIDs] = useState([]);
  const [find, setFind] = useState('');
  const [requestBody, setRequestBody] = useState({});
  const [replaceDataWith, setReplaceDataWith] = useState('');
  const [wholeCellMatch, setWholeCellMatch] = useState(false);
  const columnName = columnDefs?.find(({ colId }) => colId === colIDs[0])?.headerName || '';

  const language = user?.locale;

  const currentColumn = columnDefs?.find(({ colId }) => colId === colIDs[0]);

  const filterType = getFilterType(currentColumn?.fieldType);

  const typeOfCondition = useMemo(
    () => filterConditionType(currentColumn, wholeCellMatch),
    [currentColumn, wholeCellMatch]
  );

  const filterValue = useMemo(() => {
    switch (filterType) {
      case 'date': {
        const [dateString] = find.toString().split('GMT');
        const parsedDate = parseISO(dateString);
        const utcDate = utcToZonedTime(parsedDate, 'UTC');
        const unixTimestamp = (getTime(utcDate) / 1000).toString();
        return unixTimestamp;
      }
      case 'boolean':
        return find === '1' ? 'true' : find === '0' ? 'false' : '';
      default:
        return [find];
    }
  }, [find]);

  const filterModelCnf = {
    colId: colIDs[0],
    isCaseSensitive: caseSensitive,
    filterType,
    type: typeOfCondition,
    filter: filterValue,
  };

  const columnOptions = columnDefs
    .filter((col) => col.headerName !== ROW_NUMBER_COLUMN_NAME)
    .map(({ headerName: label, colId: value, fieldType: type }) => {
      return { value, label, type };
    });

  useEffect(() => {
    const requestBody = {
      caseSensitive,
      colIDs,
      find: filterType === 'date' && find ? format(new Date(find), 'yyyy-MM-dd HH:mm:ss') : find,
      language,
      replace:
        filterType === 'date' && replaceDataWith
          ? format(new Date(replaceDataWith), 'yyyy-MM-dd HH:mm:ss')
          : replaceDataWith,
      wholeCellMatch,
    };

    setRequestBody(requestBody);
  }, [caseSensitive, colIDs, filterType, find, language, replaceDataWith, wholeCellMatch]);

  const onFindButtonClick = () => {
    hideModal();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(setReduxFilterModelCnf(parseArrayToMatrix([filterModelCnf])));
  };

  const onReplaceAllButtonClick = () => {
    hideModal();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(
      showModal({
        name: MODAL_FIND_AND_REPLACE_CONFIRM,
        props: { filterModelCnf, requestBody, getGridRequestData, columnName },
      })
    );
  };

  const closeModal = () => {
    hideModal();
  };

  const isButtonsDisabled = !find || !colIDs.length;

  useEffect(() => {
    if (!show) {
      setCaseSensitive(false);
      setColIDs([]);
      setFind('');
      setReplaceDataWith('');
      setWholeCellMatch(false);
    }
  }, [show]);

  const onUsePapercut = (mixpanelEventName = '') => {
    dispatch(
      showModal({
        name: MODAL_PAPERCUT,
        props: {
          addMixpanelEventParam: {
            eventName: 'Find and replace paper cut',
            eventProps: {},
          },
        },
      })
    );
    if (mixpanelEventName) {
      dispatch(
        addMixpanelEvent({
          eventName: `Find and replace paywall (${mixpanelEventName})`,
          eventProps: { current_filename: currentFile?.metadata?.FileName },
          userIncrementName: '# of clicks',
        })
      );
    }
  };

  return (
    <Modal
      title='Find & Replace'
      iconName='magnifying-glass'
      articleID='69000838981'
      size='small'
      isOpen={show}
      onClose={closeModal}
    >
      <div className=''>
        <Select
          dataCy='find-replace-select-column'
          options={columnOptions}
          value={colIDs[0] || null}
          label='Select Column'
          placeholder='Column'
          onChange={(v) => setColIDs([v])}
          color='oceanBlueLight'
          buttonClassName='whitespace-pre overflow-hidden'
        />
      </div>
      <div className='mt-3'>
        {filterType === 'date' ? (
          <label>
            <span className='flex items-center h-5 mb-2 text-sm font-semibold w-fit text-ui'>
              Find
            </span>
            <CustomDatePicker
              selected={find}
              onChange={(e) => setFind(e)}
              value={find}
              className={'if-condition !w-[368px]'}
            />
          </label>
        ) : (
          <TextInput
            dataCy='find-input-text'
            label='Find'
            onChange={(e) => setFind(e.target.value)}
            placeholder=''
            size='large'
            value={find}
            className='w-full'
          />
        )}
      </div>
      <div className='flex flex-row gap-3 mt-3 text-sm'>
        <div className='flex flex-row items-center '>
          <CheckboxInput
            onChange={() =>
              !useFindReplaceSettings
                ? onUsePapercut('Case sensitive')
                : setCaseSensitive(!caseSensitive)
            }
            isChecked={caseSensitive}
            label='Case sensitive'
            disabled={!useFindReplaceSettings}
          />
          {!useFindReplaceSettings ? (
            <Button
              color='sunrise'
              variant='ghost'
              size='small'
              onClick={() => onUsePapercut('Case sensitive')}
              className='!px-0'
            >
              <Icon name='rocket-launch' size={16} color='#D9B500' className='mx-1' />
            </Button>
          ) : null}
        </div>

        <div className='flex flex-row items-center'>
          <CheckboxInput
            onChange={() =>
              !useFindReplaceSettings
                ? onUsePapercut('Match Cell Content')
                : setWholeCellMatch(!wholeCellMatch)
            }
            isChecked={wholeCellMatch}
            label='Match Cell Content'
            disabled={!useFindReplaceSettings}
          />
          {!useFindReplaceSettings ? (
            <Button
              color='sunrise'
              variant='ghost'
              size='small'
              onClick={() => onUsePapercut('Match Cell Content')}
              className='!px-0'
            >
              <Icon name='rocket-launch' size={16} color='#D9B500' className='mx-1' />
            </Button>
          ) : null}
        </div>
      </div>
      <div className='mt-3'>
        {filterType === 'date' ? (
          <label>
            <span className='flex items-center h-5 mb-2 text-sm font-semibold w-fit text-ui'>
              Replace With
            </span>
            <CustomDatePicker
              selected={replaceDataWith}
              onChange={(e) => setReplaceDataWith(e)}
              value={replaceDataWith}
              className={'if-condition !w-[368px]'}
            />
          </label>
        ) : (
          <TextInput
            dataCy='replaceWith-input'
            label='Replace With'
            onChange={(e) => setReplaceDataWith(e.target.value)}
            placeholder=''
            size='large'
            value={replaceDataWith}
            className='w-full'
          />
        )}
      </div>
      <div className='flex flex-row items-center justify-between py-4 mt-6 text-right border-t border-ui-200'>
        <Button variant='ghost' color='shadow' dataCy='cancel-btn' onClick={closeModal}>
          Cancel
        </Button>
        <div className='flex flex-row gap-4'>
          <Button
            color={'oceanBlue'}
            dataCy='replaceAll-btn'
            onClick={onReplaceAllButtonClick}
            disabled={isButtonsDisabled}
          >
            Replace All
          </Button>
          <Button
            color={'oceanBlue'}
            dataCy='find-btn'
            onClick={onFindButtonClick}
            disabled={isButtonsDisabled}
          >
            Find
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_FIND_AND_REPLACE })(FindAndReplacePopup);
