import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { post } from '@/Utils/API';
import Modal from '@/Components/UI/Modal/Modal';
import Spinner from '@/Components/UI/Spinner/Spinner';
import { Button } from '@/Components/UI/Button/Button';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import {
  MODAL_DATA_ENRICHMENT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_REVENUE_BASE,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_ENRICHMENT_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';

import withModal from '../../withModalHOC';
import Dropdown from '../DataEnrichment/Dropdown';
import { AvailableCredits } from '../DataEnrichment/AvailableCredits';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const REGEX_PATTERN = /\*\*\*\*(.*?)\*\*\*\*/;

const EnrichmentLink = ({ link, text }) => (
  <div className='overflow-hidden text-xs font-normal leading-5 font-overpass text-ocean-blue'>
    <a target='_blank' rel='noopener noreferrer' href={link}>
      {text || ''}
    </a>
  </div>
);

const RevenueBase = ({
  endpoint_description,
  endpoint_label,
  endpoint_name,
  endpoint_type,
  show,
  url_description,
  help_url,
}) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { metadata } = currentFile || {};
  const { FileUuid: fileUuid } = metadata || {};

  const {
    clientState: { filterModel },
  } = useSelector((store) => store[spreadsheetSliceName]);

  const [columnId, setColumnId] = useState('');
  const [step, setStep] = useState(1);
  const [isRevenueBaseLoading, setIsRevenueBaseLoadingLoading] = useState(false);

  const onColumnIdChange = (templateId) => {
    const match = templateId.match(REGEX_PATTERN);
    if (match) {
      const [, extractedValue] = match || [];
      return setColumnId(extractedValue);
    }
    setColumnId('');
  };
  const sendRevenueBaseRequest = async () => {
    setIsRevenueBaseLoadingLoading(true);
    dispatch(setIsInsertingNewColumn(true));
    dispatch(hideAllModals());
    let endpoint = '';
    let payload = {};
    try {
      endpoint = `enrich/on-demand/revenuebase-${endpoint_type}/${fileUuid}/${columnId}`;
      payload = {
        filterModel,
      };
      const revenueBaseResp = await post(endpoint, payload);

      if (!revenueBaseResp?.Success) {
        return ShowToast({
          type: TOAST_TYPE_ERROR,
          text: revenueBaseResp.Message || TOAST_TEXT_ENRICHMENT_ERROR,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
      await dispatch(fetchOpenedFile(fileUuid));
    } catch (error) {
      return ShowToast({
        type: TOAST_TYPE_ERROR,
        text: error.message || TOAST_TEXT_ENRICHMENT_ERROR,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setIsRevenueBaseLoadingLoading(false);
      dispatch(setIsInsertingNewColumn(false));
      setStep(1);
    }
  };

  const urlDescription = () => {
    if (url_description) {
      if (help_url) {
        return (
          <>
            <span data-cy='enrichment-description'>{url_description}</span>
            <EnrichmentLink text=' Learn more. ' link={help_url} />
          </>
        );
      } else {
        return null;
      }
    } else {
      return <span data-cy='enrichment-description'>{endpoint_description}</span>;
    }
  };

  const revenueBaseEnrich = () => {
    return (
      <>
        <div className='pl-[16px] pr-[16px]'>
          <div>
            <div
              data-cy='enrichment-name'
              className='flex items-center mb-[5px] font-extrabold  tracking-[-.02em] text-lg leading-[23px] font-overpass'
            >
              {endpoint_name}
            </div>
            <div className='text-xs font-normal leading-5 font-overpass mb-[12px] text-[#474747] overflow-hidden'>
              {urlDescription()}
            </div>
          </div>
          <div className='mb-[8px]'>
            <InputField label={endpoint_label} />
          </div>
          <div className='mb-[18px]'>
            <Dropdown value={columnId} onChange={onColumnIdChange} />
          </div>
        </div>
        <div className='p-4 mt-4 text-right border-t border-ui-200'>
          <Button
            color='shadow'
            dataCy='reset-field-btn'
            className='float-left'
            onClick={() => {
              dispatch(showModal({ name: MODAL_DATA_ENRICHMENT }));
            }}
          >
            Back
          </Button>

          <Button
            variant='ghost'
            color='shadow'
            dataCy='cancel-btn'
            className='mr-[16px]'
            onClick={() => {
              dispatch(hideAllModals());
            }}
          >
            Cancel
          </Button>

          <Button
            color={'oceanBlue'}
            dataCy='save-btn'
            onClick={() => {
              if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
                return dispatch(
                  showModal({
                    name:
                      currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
                        ? MODAL_UPGRADE_REQUIRED
                        : MODAL_EXCEEDS_LIMITS,
                  })
                );
              }
              setStep(2);
            }}
            disabled={!columnId || isRevenueBaseLoading}
          >
            {isRevenueBaseLoading && (
              <Spinner size='medium' color='shadow' className='inline-block' />
            )}
            Apply
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      title='Enrichments'
      iconName='atom'
      size='small'
      isOpen={show}
      onClose={() => dispatch(hideAllModals())}
      shouldCloseOnBackgroundClick={false}
    >
      <>
        {step === 1 && revenueBaseEnrich()}

        {step === 2 && (
          <AvailableCredits
            onBack={() => {
              setStep(1);
              setColumnId('');
            }}
            onCancel={() => dispatch(hideAllModals())}
            onRun={sendRevenueBaseRequest}
          />
        )}
      </>
    </Modal>
  );
};

export default withModal({ name: MODAL_REVENUE_BASE })(RevenueBase);
