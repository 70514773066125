import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import clsx from 'clsx';

const FilterPanelFooter = ({
  onResetFilterPanel,
  hideModal,
  onClickApply,
  isDisabledApplyButton,
  isApplyButtonShowsUpgradeModal,
  isFiltersOverLimit,
}) => {
  return (
    <>
      <div className='flex items-center justify-between px-4 pt-5 border-t'>
        <div>
          <Button
            color='shadow'
            size='large'
            dataCy='reset-filters-btn'
            onClick={onResetFilterPanel}
          >
            Reset
          </Button>
        </div>

        <div className='flex items-center gap-4'>
          <Button color='shadow' size='large' dataCy='cancel-filters-btn' onClick={hideModal}>
            Cancel
          </Button>
          <Button
            color='oceanBlue'
            size='large'
            dataCy='save-btn'
            onClick={onClickApply}
            disabled={isDisabledApplyButton}
            className={clsx(
              'flex flex-row items-center',
              isApplyButtonShowsUpgradeModal() && isFiltersOverLimit() && '!opacity-40'
            )}
          >
            <span className='flex flex-row items-center'>
              Apply
              {isApplyButtonShowsUpgradeModal() && (
                <Icon name='rocket-launch' size={20} color='#D9B500' className='ml-1' />
              )}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterPanelFooter;
