import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_RENAME_COLUMNS } from '@/Utils/constants';
import withModal from '../withModalHOC';
import RenameColumnsPopupBody from './RenameColumnsPopupBody';

const RenameColumnsPopup = ({ hideModal, show }) => {
  const closeModal = () => {
    hideModal();
  };
  return (
    <Modal
      title='Rename Columns'
      articleID='69000844208'
      iconName='textbox'
      size='medium'
      isOpen={show}
      onClose={closeModal}
    >
      {<RenameColumnsPopupBody hideModal={hideModal} />}
    </Modal>
  );
};

export default withModal({ name: MODAL_RENAME_COLUMNS })(RenameColumnsPopup);
