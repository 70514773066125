import { Button } from '@/Components/UI/Button/Button';

import './style/header.scss';

export const CustomCalendarHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
}) => {
  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  const years = range(1920, 2100, 1);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button
        variant='ghost'
        color='shadow'
        className='absolute left-5 top-5'
        onClick={decreaseMonth}
        iconName='caret-left'
        alt='left-arrow'
      />

      <select
        disabled
        className='header__month-select'
        value={months[new Date(date).getMonth()]}
        onBlur={({ target: { value } }) => changeYear(value)}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        className='header__year-select'
        value={new Date(date).getFullYear()}
        onBlur={({ target: { value } }) => changeYear(value)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <Button
        variant='ghost'
        color='shadow'
        className='absolute right-5 top-5'
        onClick={increaseMonth}
        iconName='caret-right'
        alt='right-arrow'
      />
    </div>
  );
};
