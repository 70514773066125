import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import { http_delete } from '@/Utils/API';
import { COLUMNS, FILES, MODAL_DELETE_COLUMN } from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { spreadsheetSliceName } from '@/redux/constants';
import {
  deleteColumns,
  setColumnDefs,
  setIsDeletingColumns,
} from '@/redux/reducers/spreadsheetSlice';

const DeleteColumnModal = ({ show, hideModal, columnId, columnName }) => {
  const dispatch = useDispatch();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const removeColumn = async () => {
    const uuid = getFileUuidFromPath();
    hideModal();
    try {
      dispatch(setIsDeletingColumns(true));
      dispatch(deleteColumns([columnId]));
      const data = await http_delete(`${FILES}/${uuid}/${COLUMNS}`, columnId);
      if (data.Success) {
        const newColDefs = columnDefs.filter((el) => el.colId !== columnId);
        dispatch(setColumnDefs(newColDefs));
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsDeletingColumns(false));
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title='Confirm Deletion'
      iconName='trash'
      dataCy='popup-delete'
    >
      <div className='delete-column-container'>
        <p>
          Delete <b>&quot;{columnName ? columnName : columnId}&quot;</b> forever? This will affect
          all <b>Views</b> created for this sheet.
        </p>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' dataCy='cancel-btn' size='large' onClick={hideModal}>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' dataCy='save-btn' onClick={removeColumn}>
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_COLUMN })(DeleteColumnModal);
