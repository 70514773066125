import { Helmet } from 'react-helmet';

const MetaTag = ({ documentTitle }) => {
  return (
    <Helmet>
      <meta name='title' content={`${documentTitle} - Gigasheet`} />
      <meta
        name='description'
        content={`Gigasheet application analyzing ${documentTitle} - Gigasheet`}
      />
    </Helmet>
  );
};
export default MetaTag;
