export const paginationHelper = (agGridAPI = {}, isFromCustomPagination = false) => {
  if (!agGridAPI) return;
  if (!Object.keys(agGridAPI).length) return;
  const openedToolPanel = agGridAPI.api.getOpenedToolPanel();
  const isToolPanelShowing = agGridAPI.api.isToolPanelShowing();
  const countRowPerPage = agGridAPI.api.paginationGetPageSize();
  const currentPage = agGridAPI.api.paginationGetCurrentPage() + 1;
  const lastRowIndex = agGridAPI.api.paginationGetRowCount();
  const lastIndexRowPerPage = currentPage * countRowPerPage;
  const firstIndexRowPerPage = lastIndexRowPerPage - countRowPerPage;
  const firstRowPerPage = [];
  agGridAPI.api.forEachNode((node) => {
    node.rowIndex === firstIndexRowPerPage && firstRowPerPage.push(node);
  });
  return {
    firstRowPerPage: firstRowPerPage[0] || null,
    openedToolPanel,
    isToolPanelShowing,
    firstIndexRowPerPage,
    lastIndexRowPerPage,
    lastRowIndex,
    isFromCustomPagination,
  };
};

export const rowsAmountOptions = [
  { label: '100', value: 100 },
  { label: '500', value: 500 },
  { label: '1000', value: 1000 },
];
