import withModal from '@/Components/Modals/withModalHOC';
import { Button } from '@/Components/UI/Button/Button';
import { Select } from '@/Components/UI/Form/Select/Select';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import ComboBox from '@/Components/UI/Shadcn/ComboBox';
import { MODAL_AUDIT_LOG } from '@/Utils/constants';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';
import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { useDebounce } from '@/hooks/useDebounce';

import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  actions,
  defaultFilter,
  formatButton,
  formatEmptyIndicator,
  getActivityCount,
  getActivityLog,
  getTeamEmails,
  handleExportActivityLog,
  handleFilterActivity,
  returnTableBody,
  types,
} from './auditHelper';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const AuditLogPopup = ({ show, hideModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(1);
  const [pageNumber, setPageNumber] = useState({ label: 1, value: 0 });
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [action, setAction] = useState([]);
  const [type, setType] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(defaultFilter);

  const currentFile = useCurrentFile();
  const {
    user: { email },
  } = useSelector((state) => state[userDataSliceName]);

  const fileUuid = currentFile?.metadata?.FileUuid;
  const fileName = currentFile?.metadata?.FileName;

  const filterRef = useRef(currentFilter);
  const debouncedSearchValue = useDebounce(searchValue, 200); // Use 200ms debounce

  const pageCountOptions = Array.from(Array(count)).map((num, i) => ({
    value: i?.toString(),
    label: i + 1,
  }));

  useEffect(() => {
    if (show) {
      getActivityCount(
        (count) => setCount(count),
        (totalCount) => setTotalCount(totalCount),
        fileUuid
      );
      getActivityLog((activity) => setActivity(activity), fileUuid, 0);
      getTeamEmails((teamEmails) => setUsers(teamEmails), email);
      setIsLoading(false);
    }
  }, [show]);

  useEffect(() => {
    filterRef.current = currentFilter;
  }, [currentFilter]);

  useEffect(() => {
    if (debouncedSearchValue !== filterRef.current.query) {
      setCurrentFilter((prev) => {
        const newFilter = { ...prev, query: debouncedSearchValue };
        filterRef.current = newFilter;
        handleFilterActivity(newFilter, fileUuid, setActivity);
        return newFilter;
      });
    }
  }, [debouncedSearchValue, setCurrentFilter, handleFilterActivity, fileUuid, setActivity]);

  useEffect(() => {
    setPageNumber(pageCountOptions[0]);
  }, [count]);

  const setCurrFilter = (key, value) => {
    const filter = currentFilter;
    filter[key] = value.map((i) => i.value);

    setCurrentFilter(filter);
    handleFilterActivity(filter, fileUuid, (v) => setActivity(v));
  };

  const handlePagination = (page) => {
    setPageNumber({ value: +page, label: +page });
    setCurrFilter('page', +page);
  };

  const handleExport = () => handleExportActivityLog(fileName, fileUuid);

  const handleClose = () => {
    setIsLoading(true);
    setActivity([]);
    setCount(1);
    setPageNumber({ label: 1, value: 0 });
    setSearchValue('');
    setUsers([]);
    setUser([]);
    setAction([]);
    setType([]);
    setCurrentFilter(defaultFilter);
    hideModal();
  };

  return (
    <Modal
      title='History'
      subhead='Monitor any changes made to your data.'
      iconName='clock-counter-clockwise'
      isOpen={show}
      onClose={handleClose}
      size='x-large'
      articleID=''
    >
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className='flex flex-row w-[270px]'>
            <TextInput
              value={searchValue}
              placeholder='Search All Fields'
              onChange={(e) => setSearchValue(e.target.value)}
              className='!rounded-r-none !w-64'
            />
            <Button
              className='!rounded-l-none px-[6px]'
              onClick={() => setCurrFilter('query', searchValue)}
            >
              <Icon name='magnifying-glass' />
            </Button>
          </div>
          <div className='flex flex-row gap-2 my-3'>
            <ComboBox
              value={user}
              options={users}
              placeholder='Users'
              hidePlaceholderWhenSelected={true}
              onChange={(v) => {
                setUser(v);
                setCurrFilter('users', v);
              }}
              emptyIndicator={formatEmptyIndicator()}
              className='!max-w-[284px]'
              selectionMode='multiple'
            />
            <ComboBox
              value={type}
              options={types}
              placeholder='Types'
              hidePlaceholderWhenSelected={true}
              onChange={(v) => {
                setType(v);
                setCurrFilter('actions', v);
              }}
              emptyIndicator={formatEmptyIndicator()}
              selectionMode='multiple'
              className='!max-w-[284px]'
            />
            <ComboBox
              value={action}
              options={actions}
              placeholder='Action'
              hidePlaceholderWhenSelected={true}
              onChange={(v) => {
                setAction(v);
                setCurrFilter('categories', v);
              }}
              emptyIndicator={formatEmptyIndicator()}
              selectionMode='multiple'
              className='!max-w-[284px]'
            />
          </div>
          <div>
            <div className='min-h-[350px]'>
              <table className='w-full'>
                <thead className='border-b bg-ui-secondary border-ui'>
                  <tr className='p-1 text-[13px]'>
                    <th className='w-1/12 max-w-1/12'>{formatButton('Timestamp')}</th>
                    <th className='w-3/12 max-w-3/12'>{formatButton('User')}</th>
                    <th className='w-1/12 max-w-1/12 '>{formatButton('Action')}</th>
                    <th className='w-2/12 max-w-3/12'>{formatButton('Type')}</th>
                    <th className='w-3/12 max-w-4/12'>{formatButton('Details')}</th>
                  </tr>
                </thead>
                <tbody>
                  {activity?.length !== 0 ? (
                    <>
                      {activity?.map((action, i) => (
                        <tr
                          key={i}
                          className={clsx('text-[13px] text-left h-[58px] align-top border-b')}
                        >
                          {returnTableBody(action)}
                        </tr>
                      ))}
                    </>
                  ) : null}
                </tbody>
              </table>
              <div>
                {activity?.length === 0 ? (
                  <div className='flex flex-row items-center w-[867px] h-64 text-lg font-semibold text-center align-middle text-midnight'>
                    <div className='!text-center !w-full'>No Results.</div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            data-cy='sheet-activity-pagination'
            className={clsx(
              'flex flex-row items-center justify-between align-center text-[13px] py-3',
              activity?.length === 0 && count === 0 && 'hidden'
            )}
          >
            <Button
              variant='ghost'
              className={clsx(count <= 1 && 'hidden')}
              onClick={() => handlePagination(pageNumber?.value - 1)}
              disabled={pageNumber.value === 0}
            >
              Previous
            </Button>
            <div
              className={clsx(
                'flex flex-row items-center gap-1 text-sm font-semibold',
                count <= 1 && 'hidden'
              )}
            >
              Page
              <Select
                value={pageNumber?.value.toString()}
                onChange={(v) => handlePagination(v)}
                options={pageCountOptions}
                disabled={count <= 1}
              />
              <div className='w-16'>of {count}</div>
            </div>
            <Button
              variant='ghost'
              className={clsx(count <= 1 && 'hidden')}
              onClick={() => handlePagination(pageNumber.value + 1)}
              disabled={pageNumber.value + 1 === count}
            >
              Next
            </Button>
          </div>
          <div
            className={clsx(
              'flex flex-row items-center justify-between w-full pt-3 border-t align-center',
              totalCount === 0 && '!justify-end'
            )}
          >
            <Button
              variant='outline'
              iconName='export'
              className={clsx(activity?.length === 0 && count >= 0 && 'hidden')}
              disabled={totalCount === 0}
              onClick={handleExport}
              dataCy='sheet-activity-export'
            >
              Export Data
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withModal({ name: MODAL_AUDIT_LOG })(AuditLogPopup);
