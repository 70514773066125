import { commonSVG } from '@/Assets/icons';

import './index.scss';

const EmptyFiles = () => {
  return (
    <div className='empty-files__container'>
      <img src={commonSVG.EmptyFilesIcon} alt='' />
      <h3 data-cy='drop-files-here' className='hidden md:block'>
        Drop your files here
      </h3>
      <p data-cy='use-new-button' className='hidden md:block'>
        Or use the "New" button
      </p>
    </div>
  );
};

export default EmptyFiles;
