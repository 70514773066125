import { useSelector } from 'react-redux';

import { datasetSliceName } from '@/redux/constants';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import { parseRowCount } from '@/Utils/DatasetTree/parseRowCount';
import { openSpreadsheet } from '@/Utils/utils';

const ConflictingFilesComponent = ({ conflictingFiles }) => {
  const { FileColumnsCount, FileErrors, FileRowsCount } = conflictingFiles;
  const { selectedFiles } = useSelector((state) => state[datasetSliceName]);

  const sortedSelectedFiles = selectedFiles
    .map((file) => {
      return { ...file, WithError: FileErrors[file.FileUuid] ? 1 : 0 };
    })
    .sort((a, b) => b.WithError - a.WithError);

  return (
    <div>
      <span className='flex text-base font-semibold mb-6'>
        {Object.keys(FileErrors).length} Conflicting File(s)
      </span>
      <div className=''>
        <table className='w-full rounded-t-lg overflow-hidden max-h-96 '>
          <thead className='table sticky mb-0'>
            <tr className='flex bg-shadow-100 text-ui text-[13px] font-semibold text-left'>
              <th className='py-1 px-4 w-full !p-0 !pl-4 leading-7'>FILE</th>
              <th className='flex justify-start items-center w-16 !p-0 shrink-0'>ROWS</th>
              <th className='flex justify-start items-center w-16 !p-0 shrink-0'>COLS</th>
              <th className='w-[120px] shrink-0'></th>
            </tr>
          </thead>

          <tbody className='flex flex-col max-h-[200px] overflow-y-auto'>
            {sortedSelectedFiles.map(({ FileUuid, FileName }) => {
              return (
                <tr key={FileUuid} className='flex'>
                  <td className='py-3 px-4 border-collapse border border-shadow-100 w-full'>
                    <div className='flex items-center'>
                      {FileErrors[FileUuid] ? (
                        <div className='flex justify-center items-center rounded-full bg-carrot-500 w-6 h-6 shrink-0'>
                          <Icon name='warning-circle' color='#fff' />
                        </div>
                      ) : (
                        <div className='shrink-0'>
                          <Icon size={30} name='check-circle' weight='fill' color='#A3AE28' />
                        </div>
                      )}
                      <div className='flex flex-col ml-4'>
                        <span className='text-sm font-semibold text-ui'>{FileName}</span>
                        <ul>
                          {FileErrors[FileUuid] &&
                            FileErrors[FileUuid].map((err, i) => {
                              return (
                                <li
                                  key={i}
                                  className='uppercase text-[10px] text-carrot-800 font-extrabold'
                                >
                                  {err}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td className='flex justify-center items-center p-1 border-collapse border border-shadow-100 text-sm text-ui w-16 shrink-0'>
                    {parseRowCount(FileRowsCount[FileUuid])}
                  </td>
                  <td className='flex justify-center items-center p-1 border-collapse border border-shadow-100 text-sm text-ui w-16 shrink-0'>
                    {parseRowCount(FileColumnsCount[FileUuid])}
                  </td>
                  <td className='flex justify-center items-center border-collapse border border-shadow-100 w-[120px] shrink-0'>
                    <Button
                      variant='outline'
                      color='shadow'
                      size='small'
                      onClick={() => openSpreadsheet(FileUuid, FileName)}
                    >
                      <span className='flex items-center'>
                        <span className='mr-1 !text-[13px]'>Open File</span>
                        <Icon name='arrow-square-out' />
                      </span>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConflictingFilesComponent;
