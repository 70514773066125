import { useSelector } from 'react-redux';

import FlagPanel from '../../../Components/TableGrid/rowDetail/FlagPanel';
import ViewRowData from '../../../Components/Spreadsheet/ViewRowData/ViewRowData';

import { spreadsheetSliceName } from '../../../redux/constants';

import { paginationHelper } from '../../../helpers/paginationHelper';

import './index.scss';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const PREVIOUS_PAGE = 'previous';
const NEXT_PAGE = 'next';

const RowDetailPanel = (agGrid) => {
  const { user } = useSelector((state) => state.userData);
  const currentFile = useCurrentFile();
  const { columnDefs: columnData } = useSelector((state) => state[spreadsheetSliceName]);
  const { selectedRow } = useSelector((state) => state[spreadsheetSliceName]);

  const { rowIndex = 0, rowData = null } = selectedRow || {};

  const getNodes = () => {
    const nodes = [];
    agGrid.api.forEachNode((node) => {
      nodes.push(node);
    });
    return nodes;
  };

  const ensureRowFocus = (element, index) => {
    element.setSelected(true);
    agGrid.api.ensureIndexVisible(index);
    agGrid.api.setFocusedCell(index, agGrid.columnApi.getAllDisplayedColumns()[0]);
  };

  const onButtonPaginationClick = (button) => {
    return () => {
      const { firstIndexRowPerPage, lastIndexRowPerPage, lastRowIndex } = paginationHelper(agGrid);
      const nodes = getNodes();
      switch (button) {
        case PREVIOUS_PAGE:
          onPrevButtonClick(firstIndexRowPerPage, nodes);
          break;
        case NEXT_PAGE:
          onNextButtonClick(lastIndexRowPerPage, lastRowIndex, nodes);
          break;
        default:
          break;
      }
      // }
    };
  };

  const onPrevButtonClick = (firstElementIndexPerPage, nodes) => {
    if (rowIndex === 0) return;
    if (rowIndex === firstElementIndexPerPage) {
      agGrid.api.paginationGoToPreviousPage();
      const [choosenLastRow] = nodes.filter((el) => el.rowIndex === rowIndex);
      ensureRowFocus(choosenLastRow, rowIndex);
      return;
    }
    const [choosenRow] = nodes.filter((el) => el.rowIndex === rowIndex - 1);
    ensureRowFocus(choosenRow, rowIndex - 1);
  };

  const onNextButtonClick = (lastElementIndexPerPage, lastRowIndex, nodes) => {
    if (rowIndex + 1 === lastRowIndex) return;
    if (rowIndex === lastElementIndexPerPage - 1) {
      agGrid.api.paginationGoToNextPage();
      const [chosenLastRow] = nodes.filter((el) => el.rowIndex === rowIndex);
      ensureRowFocus(chosenLastRow, rowIndex);
      return;
    }
    const [chosenRow] = nodes.filter((el) => el.rowIndex === rowIndex + 1);
    ensureRowFocus(chosenRow, rowIndex + 1);
  };

  return (
    <div className='custom-component'>
      <div className='custom-component-header h-[41px]'>Row:&nbsp;&nbsp; {rowData?.A}</div>
      <ViewRowData rowData={rowData} columnInfo={columnData} />
      {!user?.is_anonymous && !currentFile?.isFileShared && (
        <FlagPanel props={agGrid} rowData={rowData} agGrid={agGrid} />
      )}
      <div className='custom-component-footer'>
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          className='custom-component-footer-prev'
          onClick={onButtonPaginationClick(PREVIOUS_PAGE)}
        >
          {PREVIOUS_PAGE}
        </div>
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          className='custom-component-footer-next'
          onClick={onButtonPaginationClick(NEXT_PAGE)}
        >
          {NEXT_PAGE}
        </div>
      </div>
    </div>
  );
};

export default RowDetailPanel;
