import { Button } from '@/Components/UI/Button/Button';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import { userLoginMethodKey, userTeamNameKey } from '@/Utils/constants';
import { userDataSliceName } from '@/redux/constants';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const AccountSettings = () => {
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { given_name, family_name, email } = user;
  const isOAuthLogin = user[userLoginMethodKey] === 'google-oauth2';
  const teamName = user[userTeamNameKey];

  return (
    <div className='w-full h-full px-16'>
      <div className='max-w-[640px] flex flex-col w-full gap-6 p-6 pb-8 bg-white border'>
        <div className='flex flex-row items-center justify-between w-full gap-4 align-middle'>
          <div className='w-1/2'>
            <TextInput
              className='w-full text-ui-helper'
              disabled={true}
              label='First'
              value={given_name}
            />
          </div>
          <div className='w-1/2'>
            <TextInput
              className='w-full text-ui-helper'
              disabled={true}
              label='Name'
              value={family_name}
            />
          </div>
        </div>
        <div className='flex flex-row justify-between w-full gap-4 align-middle item-center'>
          <div className='w-1/2'>
            <TextInput
              className='w-full text-ui-helper'
              disabled={true}
              label='Name'
              value={email}
            />
          </div>

          {teamName ? (
            <div className='w-1/2'>
              <TextInput
                className='w-full text-ui-helper'
                disabled={true}
                label='Team'
                value={teamName}
              />
            </div>
          ) : null}
        </div>
        <div
          className={clsx(
            'flex flex-col gap-2 text-sm font-semibold text-ui',
            isOAuthLogin && 'hidden'
          )}
        >
          Password
          <Button className='font-normal w-36' onClick={() => window.fcWidget.open()}>
            Update Password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
