import { Button } from '@/Components/UI/Button/Button';
import CommentEntry from './CommentEntry';
import Icon from '@/Components/UI/Icon/Icon';

type Comments = {
  row: number;
  column: string;
  comment_id: string;
  comment: string;
  author: string;
  created_at: string;
};

type GroupedComments = {
  [key: string]: Comments[];
};

const AllCommentsEntries = ({
  comments,
  autofillOptions,
  close,
  selectCell,
}: {
  comments: Comments[];
  autofillOptions: string[];
  close: () => void;
  selectCell: ({ column, row }: { column: string; row: number }) => void;
}) => {
  const noCommentsMessage = (
    <div data-cy='add-comment-message' className='flex flex-col items-center justify-center p-10'>
      <p className='text-base font-bold text-ui'>Start a Conversation</p>
      <p className='text-sm text-ellipsis text-ui-secondary'>
        Right-click any cell to add a comment.
      </p>
    </div>
  );

  const groupCommentsByRowAndColumn = (arr: Comments[]) => {
    const groupedComments: GroupedComments = {};

    arr.forEach((comment) => {
      const key = `${comment.column}-${comment.row}`;
      if (!groupedComments[key]) {
        groupedComments[key] = [];
      }
      groupedComments[key].push(comment);
    });

    return groupedComments;
  };

  const goToComment = (value: string) => {
    const getRowAndColumnIds = value.split('-');
    const [column, row] = getRowAndColumnIds;
    close();
    selectCell({ column, row: +row });
  };

  return (
    <div>
      <div className='border-b'>
        <Button
          variant='ghost'
          color='oceanBlue'
          className='!border-ocean-blue-600 border-b-2 !rounded-none !w-24'
        >
          All
        </Button>
      </div>
      {comments.length
        ? Object.entries(groupCommentsByRowAndColumn(comments)).map((el, i) => {
            return (
              <div key={i} className='py-2 m-2 rounded hover:bg-opacity-20 hover:bg-ocean-blue-100'>
                <div className='flex justify-end w-full px-4'>
                  <Button
                    size='small'
                    variant='solid'
                    color='shadow'
                    className=' hover:!bg-sunrise-100 px-1 !h-5'
                    dataCy={`comment-identifier-${i + 1}`}
                  >
                    #{i + 1}
                  </Button>
                </div>

                {el[1].map((comment) => {
                  return (
                    <CommentEntry
                      key={comment.comment_id}
                      comment={comment}
                      column={comment.column}
                      row={comment.row}
                      isColumnComment={false}
                      getThread={() => {}}
                      index={comment.comment_id}
                      thread={comment}
                      autofillOptions={autofillOptions}
                      readOnly={true}
                    />
                  );
                })}
                <div>
                  <Button
                    variant='ghost'
                    className='ml-4 text-sm text-ui-helper hover:text-ocean-blue-500'
                    onClick={() => goToComment(el[0])}
                    dataCy={`go-to-comment-${i + 1}`}
                  >
                    <span className='flex'>
                      <Icon name='arrow-bend-up-left' size={14} className='mr-1' />
                      <span>Go To Comment</span>
                    </span>
                  </Button>
                </div>
              </div>
            );
          })
        : noCommentsMessage}
    </div>
  );
};

export default AllCommentsEntries;
