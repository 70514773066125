import { Collapsible, CollapsibleContent } from '@/Components/UI/Shadcn/Collapsible';

import DefaultEnrichments from './DefaultEnrichments';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import PasswordInput from '@/Components/UI/Form/PasswordInput/PasswordInput';

const IPEnrichments = (props) => {
  const { onSelectEnrichment, enrichments, savedApiKeys, onInputApiKey } = props;
  return (
    <div className='mb-4 enrichment-services'>
      <Collapsible open={enrichments.some((item) => item.provider === 'greynoise')}>
        <CheckboxInput label='GreyNoise' name='greynoise' onChange={onSelectEnrichment} />
        <CollapsibleContent>
          <PasswordInput
            name='greynoise'
            label='API Key'
            placeholder='Greynoise API Key'
            value={savedApiKeys['greynoise']}
            onChange={onInputApiKey}
            className='w-full mb-4'
            labelClassName='!mb-0 mt-1'
          />
        </CollapsibleContent>
      </Collapsible>

      <DefaultEnrichments {...props} />
    </div>
  );
};

export default IPEnrichments;
