import { useMemo, useRef, useState, useEffect } from 'react';
import { useTable, useAsyncDebounce } from 'react-table';

import { Button } from '@/Components/UI/Button/Button';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { getDefaultCheckedState } from '@/helpers/enrichmentHelper';

function SearchInput({ filterString, setFilterString }) {
  const [value, setValue] = useState(filterString);
  const onChange = useAsyncDebounce((value) => {
    setFilterString(value || undefined);
  }, 200);

  return (
    <TextInput
      value={value || ''}
      onChange={(event) => {
        setValue(event.target.value);
        onChange(event.target.value);
      }}
      placeholder='Search Columns'
      className='w-[414px]'
    />
  );
}

function ReactTable({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: data,
    columns,
  });

  return (
    <div className='pb-2 overflow-x-auto'>
      {!columns.length && (
        <span className='inline-block w-full mt-4 text-sm text-center text-ui-helper'>
          No search result
        </span>
      )}
      <table {...getTableProps()} className='border-collapse table-auto'>
        <thead className='sticky top-0 h-10 bg-ui-secondary'>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  key={`column-${index}`}
                  data-cy={`column-${index}`}
                  className='!border !border-ui-100 !pt-2.5 !px-4 !align-middle'
                >
                  <span className='inline-flex'>{column.render('Header')}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      key={`cell-${cellIndex}`}
                      data-cy={`cell-${rowIndex}-${cellIndex}`}
                      className='!border !border-ui-100 !border-t-0 !py-0 !px-4 !align-middle !h-8 truncate min-w-[175px]'
                    >
                      <span className='text-sm font-normal text-ui'>{cell.render('Cell')}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const FieldTable = ({ setSelectedFields, responseData, setFilteredResponseData }) => {
  const defaultNumberOfCheckedColumns = 3;

  const checkboxRefs = useRef([]);
  const [filteredData, setFilteredData] = useState();
  const [checkedState, setCheckedState] = useState(
    getDefaultCheckedState(responseData, defaultNumberOfCheckedColumns)
  );

  const [filterString, setFilterString] = useState('');

  const filterObjectByKey = (obj, searchTerm) => {
    const filteredObj = {};

    for (const key in obj) {
      if (key.toString().toLowerCase().includes(searchTerm)) {
        filteredObj[key] = obj[key];
      }
    }

    return filteredObj;
  };

  useEffect(() => {
    setCheckedState(getDefaultCheckedState(responseData, defaultNumberOfCheckedColumns));
    setSelectedFields(getDefaultCheckedState(responseData, defaultNumberOfCheckedColumns));
  }, []);

  useEffect(() => {
    if (filterString) {
      const updatedResponseData = responseData.map((row) => {
        return filterObjectByKey(row, filterString.toLowerCase());
      });
      setFilteredData(updatedResponseData);
      setFilteredResponseData(updatedResponseData);
    } else {
      setFilteredData(null);
      setFilteredResponseData(null);
    }
  }, [filterString, responseData]);

  const columns = useMemo(() => {
    const handleOnChange = (position) => {
      let updatedCheckedState = [];

      if (checkedState.includes(position)) {
        updatedCheckedState = checkedState.filter((el) => el !== position);
      } else {
        updatedCheckedState = [...checkedState, position];
      }

      setCheckedState(updatedCheckedState);
      setSelectedFields(updatedCheckedState);
    };

    return Object.entries(filteredData?.[0] || responseData[0]).map((responseItem, index) => {
      return {
        id: `field-${responseItem[0]}`,
        Header: () => {
          return (
            <>
              <CheckboxInput
                checked={checkedState.includes(responseItem[1].id)}
                ref={(ref) => (checkboxRefs.current[index] = ref)}
                onChange={() => handleOnChange(responseItem[1].id)}
              />

              <span className='font-semibold text-ui-secondary text-[13px] ml-2'>
                {responseItem[0]}
              </span>
            </>
          );
        },
        accessor: responseItem[0],
        Cell: ({ value }) => {
          if (value?.value !== null && value?.value !== undefined) {
            return value.value.toString();
          } else {
            return null;
          }
        },
      };
    }, []);
  }, [checkedState, responseData, setSelectedFields, filteredData]);

  const handleDeselectAllButtonClick = () => {
    setCheckedState([]);
    setSelectedFields([]);
  };

  const handleSelectAllButtonClick = () => {
    const newCheckedState = Object.values(responseData[0]).map((el) => el.id);

    setCheckedState(newCheckedState);
    setSelectedFields(newCheckedState);
  };

  return (
    <>
      <div className='flex items-center mb-4'>
        <>
          <SearchInput filterString={filterString} setFilterString={setFilterString} />
          <Button onClick={handleSelectAllButtonClick} className='mx-2'>
            Select All
          </Button>
          <Button onClick={handleDeselectAllButtonClick}>Deselect All</Button>
        </>
      </div>

      <ReactTable columns={columns} data={filteredData || responseData} />
    </>
  );
};

export default FieldTable;
