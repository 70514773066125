import { useState, useEffect } from 'react';
import { isValid, formatISO, fromUnixTime } from 'date-fns';

import clsx from 'clsx';

import CustomDatePicker from '@/Components/CustomDatePicker/CustomDatePicker';
import QuickDatePicker from '@/pages/SpreadsheetPage/QuickFilters/UI/QuickDatePicker';

function Calendar({
  onChange,
  defaultStartValue,
  defaultEndValue,
  id,
  modelType,
  ifCondition,
  isValidConditions = true,
  disabled,
  portalId,
  isMobile,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const focusSecondDatepicker = () => {
    if (!isOpen) document.getElementById(`${id}`).focus();
  };

  const selectStartDate = (date) => {
    if (date !== null && isValid(new Date(date))) {
      const formattedDate = formatISO(new Date(date), { representation: 'complete' });

      const startDate = (new Date(formattedDate).getTime() / 1000).toString();

      setStartDate(date);
      return onChange('filter', startDate);
    } else {
      return;
    }
  };

  const selectEndDate = (date) => {
    if (date !== null && isValid(new Date(date))) {
      const formattedDate = formatISO(new Date(date), { representation: 'complete' });

      const endDate = (new Date(formattedDate).getTime() / 1000).toString();

      setEndDate(date);
      return onChange('filterTo', endDate);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (defaultStartValue !== undefined) {
      const startValue = fromUnixTime(parseInt(defaultStartValue));

      if (isValid(startValue)) {
        setStartDate(startValue);
      }
    } else {
      setStartDate(null);
    }

    if (defaultEndValue !== undefined) {
      const endValue = fromUnixTime(parseInt(defaultEndValue));

      if (isValid(endValue)) {
        setEndDate(endValue);
        id;
      }
    } else {
      setEndDate(null);
    }
  }, [defaultEndValue, defaultStartValue]);

  if (isMobile && modelType === 'inRange') {
    return (
      <>
        <QuickDatePicker
          selected={startDate}
          onChange={(date) => selectStartDate(date)}
          value={startDate}
          isSelectStart={true}
          startDate={startDate}
          maxDate={endDate}
          endDate={endDate}
          onClose={focusSecondDatepicker}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          className={`${ifCondition && 'if-condition'} ${
            !isValidConditions &&
            !startDate &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          }`}
          disabled={disabled}
          portalId={portalId}
        />
        <QuickDatePicker
          selected={endDate}
          onChange={(date) => selectEndDate(date)}
          value={endDate}
          isSelectEnd={true}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          id={id}
          monthsShown={true}
          popperPlacement='bottom-end'
          className={`${ifCondition && 'if-condition'} ${
            !isValidConditions &&
            !endDate &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          }`}
          disabled={disabled}
          portalId='body'
        />
      </>
    );
  }
  if (isMobile && modelType !== 'inRange') {
    return (
      <QuickDatePicker
        selected={startDate}
        onChange={(date) => selectStartDate(date)}
        value={startDate}
        className={`${ifCondition && 'if-condition'} ${
          !isValidConditions &&
          !startDate &&
          '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
        }`}
        disabled={disabled}
        portalId={portalId}
      />
    );
  }

  return (
    <div className={clsx('calendar-filter__wrapper', ifCondition && 'max-w-[195px]')}>
      {modelType === 'inRange' && !isMobile ? (
        <>
          <CustomDatePicker
            selected={startDate}
            onChange={(date) => selectStartDate(date)}
            value={startDate}
            isSelectStart={true}
            startDate={startDate}
            maxDate={endDate}
            endDate={endDate}
            onClose={focusSecondDatepicker}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            className={`${ifCondition && 'if-condition'} ${
              !isValidConditions &&
              !startDate &&
              '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
            }`}
            disabled={disabled}
          />
          <CustomDatePicker
            selected={endDate}
            onChange={(date) => selectEndDate(date)}
            value={endDate}
            isSelectEnd={true}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            id={id}
            monthsShown={true}
            popperPlacement='bottom-end'
            className={`${ifCondition && 'if-condition'} ${
              !isValidConditions &&
              !endDate &&
              '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
            }`}
            disabled={disabled}
          />
        </>
      ) : (
        <CustomDatePicker
          selected={startDate}
          onChange={(date) => selectStartDate(date)}
          value={startDate}
          className={`${ifCondition && 'if-condition'} ${
            !isValidConditions &&
            !startDate &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          }`}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default Calendar;
