export const parseFileSize = (fileSize) => {
  function humanFileSize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    if (size == 0) {
      return '0 B';
    } else {
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
  }

  if (isNaN(fileSize)) {
    return fileSize;
  } else if (fileSize === '-1') {
    return '';
  } else if (fileSize === '') {
    return 0;
  } else return humanFileSize(fileSize);
};
