import Icon from '@/Components/UI/Icon/Icon';
import TextClipper from '@/Components/TextClipper/TextClipper';

import { PUBLIC_LABEL } from '@/Utils/constants';
import { isSharedByMe, isSharedWithTeam, isWorldReadable } from '@/Utils/sharing';
import { FILE_TYPE_EXPORTER, FILE_STATUS_ERROR } from '@/Utils/fileConstants';

import { functionsSVG } from '@/Assets/icons';

const FileNameCell = ({ isFolder, row, fileSize, currentFile, isSharedParentFolder }) => {
  const classNameCell = `file-title-cell ${isFolder ? 'folder-type' : 'file-type'}`;
  const getFileTypeClassName = () => {
    switch (row?.metadata?.Type) {
      case FILE_TYPE_EXPORTER:
        return 'file-title-cell__exporter';
      default:
        return 'file-title-cell__omnivore';
    }
  };
  const renderFolderTitle = () => {
    let folderClassName = 'file-title-cell__folder';
    let cypressFolderAccess = 'folder-private';
    if (isPublic) {
      folderClassName = 'file-title-cell__shared_folder';
      cypressFolderAccess = 'folder-shared';
    }
    return (
      <>
        <div data-cy={cypressFolderAccess} className={folderClassName} />
        <TextClipper
          text={row?.metadata?.FileName}
          id={row?.metadata?.FileName}
          className='pl-[33px]'
        />
      </>
    );
  };

  const isPublic =
    isSharedByMe(currentFile) ||
    isWorldReadable(row) ||
    (isSharedParentFolder && !row.metadata.Type);
  const renderFileTitle = () =>
    row?.metadata?.ParentDirectory === '' || row?.metadata?.ParentDirectory === undefined ? (
      <>
        <div
          className={
            row?.metadata?.Status === FILE_STATUS_ERROR
              ? 'file-title-cell__error'
              : getFileTypeClassName()
          }
        />
        <TextClipper
          text={row?.metadata?.FileName}
          className='pl-[33px]'
          data-cy={
            'tr_' +
            row?.metadata?.FileName +
            '_' +
            (fileSize === 'Loading' ? 'processing' : 'processed')
          }
        />
        {isSharedWithTeam(row) ? (
          <Icon dataCy='users-three' name='users-three' size={18} className='ml-2.5 mb-1 w-5' />
        ) : isPublic ? (
          isWorldReadable(row) || isSharedParentFolder === PUBLIC_LABEL ? (
            <Icon dataCy='globe' name='globe' size={18} className='ml-2.5 mb-1 min-w-[18px]' />
          ) : (
            <Icon dataCy='users' name='users' size={18} className='ml-2.5 min-w-[18px]' />
          )
        ) : null}
        {row?.metadata?.ThreatDetectionStatus?.detected_row_count > 0 && (
          <div className='ico-indicator'>
            <img
              src={
                row?.metadata?.ThreatDetectionStatus.status === 'applied'
                  ? functionsSVG.IoCGrayIcon
                  : functionsSVG.IoCIcon
              }
              alt=''
              className='ioc-icon'
            />
            <span>
              {row?.metadata?.ThreatDetectionStatus.detected_row_count} rows with known IOCs
            </span>
          </div>
        )}
      </>
    ) : (
      <>
        <div className={getFileTypeClassName()} />
        <TextClipper text={row?.metadata?.FileName} className='file-type__opened pl-[33px]' />
        {isPublic ? (
          isWorldReadable(row) || isSharedParentFolder === PUBLIC_LABEL ? (
            <Icon
              dataCy='globe'
              name='globe'
              size={18}
              className='ml-2.5 mb-1 min-w-[18px]'
              alt='public file'
            />
          ) : (
            <Icon
              dataCy='users'
              name='users'
              size={18}
              className='ml-2.5 min-w-[18px]'
              alt='public file'
            />
          )
        ) : null}
      </>
    );

  return (
    <div className={classNameCell}>
      <div className='file-title-cell__container'>
        {isFolder ? renderFolderTitle() : renderFileTitle()}
      </div>
    </div>
  );
};

export default FileNameCell;
