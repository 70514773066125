import clsx from 'clsx';

import { memo, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import { sharedSliceName, spreadsheetSliceName } from '@/redux/constants';
import {
  fetchOpenedFile,
  unsetLibraryFiles,
  unsetSharedWithMeFiles,
  unsetSharedWithMyTeamFiles,
} from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';
import {
  setIsMobileExperienceModalOpen,
  setProperMobileDrawerContent,
} from '@/redux/reducers/spreadsheetSlice';
import { store } from '@/redux/store';

import {
  MODAL_SHARE_FILE,
  MODAL_UPLOAD_FILE,
  URL_DATASET,
  URL_SPREADSHEET,
  userRolesKey,
  USER_FILE_PERMISSIONS,
  ROUTE_SPREADSHEET,
  MODAL_UPGRADE_REQUIRED,
  SHEET_ASSISTANT,
  PERMISSION_WRITE,
  MODAL_EXCEEDS_LIMITS,
  MODAL_COMMENTS,
  MODAL_REQUIRE_ACCOUNT,
  MODAL_MAKE_COPY,
  ROUTE_IMPORTS,
  ROUTE_NEXUS,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import useViewportProvider from '@/hooks/useViewportProvider';

import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import SheetLoader from '@/Components/Loader/SheetLoader';
import { Button } from '@/Components/UI/Button/Button.tsx';
import FileNameMenu from '@/Components/Navigation/FileNameMenu';
import MobileDropMenu from '@/Components/UI/MobileDropMenu/MobileDropMenu';
import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import CustomEnrichmentStatus from '@/Components/CustomEnrichmentStatus/CustomEnrichmentStatus';
import ViewOnly from '@/Components/Spreadsheet/ToolPanel/ViewOnly';

import { get } from '@/Utils/API';
import { parseFileSize } from '@/Utils/parseFileSize';
import {
  formatUrlSafeFileName,
  getAuthNonceAndSetInStorage,
  handleUnauthorizedResponseAndReload,
  postToLogoutChannel,
} from '@/Utils/utils';

import './style/index.scss';
import { getReferrerUrl } from '@/helpers/referrerHelper';
import LogoLink from './Logolink';
import Tooltip from '../UI/Tooltip/Tooltip';
import { hasNoWritePermission } from '@/helpers/spreadsheetHelper';
import Popover from '../UI/Popover/Popover';
import UserDropdownMenu from './UserDropdownMenu';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const isRanByCypress = () => {
  return store.getState()[sharedSliceName].isRanByCypress;
};

const Navigation = memo(() => {
  const { user } = useSelector((state) => state.userData);
  const currentFile = useCurrentFile();
  const {
    isInsertingNewColumn,
    isDeletingRows,
    isDeletingColumns,
    isMobileSheetAssistantOpen,
    isMobileExperienceModalOpen,
  } = useSelector((state) => state[spreadsheetSliceName]);
  const { isFileShared } = currentFile || {};

  const [shouldShowLoaderBar, setShouldShowLoaderBar] = useState(false);
  const [copyLink, setCopyLink] = useState('Link to this view');

  useEffect(() => {
    setShouldShowLoaderBar(isInsertingNewColumn || isDeletingRows || isDeletingColumns);
  }, [isInsertingNewColumn, isDeletingRows, isDeletingColumns]);

  const { navigationIconStatus } = useSelector((state) => state[sharedSliceName]);

  const [shareFileIcon, setShareFileIcon] = useState('');
  const [shareFileUrl, setShareFileUrl] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const { logout, loginWithRedirect } = useAuth0();
  const fileMatch = useMatch(ROUTE_SPREADSHEET);

  const { email, name } = user || '';
  const { isMobileScreen } = useViewportProvider();
  const isSpreadsheetPage = location.pathname.includes('spreadsheet');
  const isLibraryPage = location.pathname.includes('datasets');
  const isSettingsPage = location.pathname.includes('settings');
  const isNexusPage = location.pathname.includes(ROUTE_NEXUS);
  const isScheduledImportPage = location.pathname.includes(ROUTE_IMPORTS);

  const isMobileExperienceActive = localStorage.getItem('isMobileExperience') === 'true';
  const { FileName: fileName } = currentFile?.metadata || currentFile || {};

  const isIframe = window.location !== window.parent.location;
  const [isMobileDropMenuOpen, setIsMobileDropMenuOpen] = useState(false);
  const [isFilePropertiesModalOpen, setIsFilePropertiesModalOpen] = useState(false);
  const [fileUuid, setFileUuid] = useState('');
  const [shouldDisplayViewOnly, setShouldDisplayViewOnly] = useState(false);

  const toggleMobileExperienceModal = (modalIsOpen) => {
    dispatch(setIsMobileExperienceModalOpen(modalIsOpen));
  };

  const toggleFilePropertiesModal = (modalIsOpen) => {
    setIsFilePropertiesModalOpen(modalIsOpen);
  };

  const setMobileExperience = (status) => {
    localStorage.setItem('isMobileExperience', status);
    window.location.reload();
  };

  useEffect(() => {
    setFileUuid(fileMatch?.params?.uuid);
  }, [fileMatch]);

  useEffect(() => {
    setShouldDisplayViewOnly(
      !user?.is_anonymous &&
        isSpreadsheetPage &&
        currentFile?.isFileShared &&
        !currentFile?.availablePermissions?.includes(PERMISSION_WRITE)
    );
  }, [
    currentFile?.availablePermissions,
    currentFile?.isFileShared,
    isSpreadsheetPage,
    user?.is_anonymous,
  ]);

  useEffect(() => {
    if (isSpreadsheetPage && fileName && fileUuid) {
      setShareFileUrl(
        `${window.location.origin}/${URL_SPREADSHEET}/${formatUrlSafeFileName(
          fileName
        )}/${fileUuid}`
      );
    }
  }, [fileName, fileUuid, isSpreadsheetPage]);

  useEffect(() => {
    const checkFilePermissions = async () => {
      const response = await get(`${URL_DATASET}/${fileUuid}/${USER_FILE_PERMISSIONS}`, true);
      handleUnauthorizedResponseAndReload(response, 'user-file-permissions');
      const permissions = await response?.json();

      let icon = '';
      if (currentFile?.IsWorldReadable) {
        icon = 'globe';
      } else if (!permissions?.length) {
        icon = 'user-plus';
      } else {
        icon = 'users';
      }

      setShareFileIcon(icon);
    };

    if (fileUuid && !user?.is_anonymous) checkFilePermissions();
  }, [fileUuid, currentFile, user?.is_anonymous]);

  useEffect(() => {
    if (navigationIconStatus) {
      const icon = navigationIconStatus;
      setShareFileIcon(icon);
    }
  }, [navigationIconStatus]);

  const signOut = () => {
    if (isRanByCypress()) {
      localStorage.removeItem('cypressGigasheetAPIToken');
    } else {
      postToLogoutChannel();
    }
  };

  const navigateToHelp = () => {
    window.location.replace('https://gigasheet.freshdesk.com/');
  };

  const navigateToLibrary = () => {
    navigate('/datasets', { replace: true });
  };

  const getPageHeader = () => {
    if (!location.pathname.includes('report') && currentFile && !isLibraryPage)
      return (
        <FileNameMenu
          currentFile={currentFile}
          defaultFileName={currentFile?.metadata?.FileName || ''}
          defaultFileNote={currentFile?.Note || ''}
          fetchFileOnChange={() => dispatch(fetchOpenedFile(getFileUuidFromPath()))}
          className='navbar__rename-btn'
        />
      );
  };

  const shareButton = (
    <Button
      className='h-6 ml-3'
      iconName={shareFileIcon}
      dataCy='nav-share-btn'
      variant='outline'
      color='midnight'
      onClick={() => {
        const modal =
          !currentFile?.WithinQuota || currentFile?.OverRowQuota
            ? currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS
            : MODAL_SHARE_FILE;
        dispatch(showModal({ name: modal }));
      }}
    >
      Share
    </Button>
  );

  const handleGenerateVersion = async () => {
    const res = await get(`client-state/${getFileUuidFromPath()}/current-version`);
    if (res.version) {
      return '/' + res.version;
    } else {
      return '';
    }
  };

  const handleCopyLink = async () => {
    const version = await handleGenerateVersion();
    navigator.clipboard.writeText(
      `${window.location.origin}/${URL_SPREADSHEET}/${formatUrlSafeFileName(
        fileName
      )}/${getFileUuidFromPath()}${version}`
    );
    setCopyLink('Copied!');

    setTimeout(() => {
      setCopyLink('Link to this view');
    }, 1000);
  };

  const copyLinkButton = (
    <Tooltip text={copyLink} tooltipWidth={copyLink === 'Copied!' ? 'w-18' : 'w-32'} asChild>
      <Button
        iconName='link-simple-horizontal'
        dataCy='nav-copy-link-btn'
        variant='outline'
        color='midnight'
        className='h-6 ml-3'
        onClick={() => handleCopyLink()}
      />
    </Tooltip>
  );

  const signUpButton = (
    <Button
      className='h-6 ml-3'
      dataCy='nav-signup-btn'
      color='sunrise'
      onClick={() => {
        const authStateNonce = getAuthNonceAndSetInStorage();

        loginWithRedirect({
          screen_hint: 'signup',
          appState: {
            returnTo: location.pathname + location.search,
            referrerId: getReferrerUrl(true),
            authStateNonce,
          },
        });
      }}
    >
      Sign Up For Free
    </Button>
  );

  const getNavBarBtn = () => {
    if (!isSpreadsheetPage) return null;
    if (user?.is_anonymous) return signUpButton;
    if (isFileShared && hasNoWritePermission(currentFile)) return null;
    return shareButton;
  };

  const getFileNameComponent = () => {
    if (!isSpreadsheetPage) return null;
    if (user?.is_anonymous || hasNoWritePermission(currentFile))
      return (
        <div data-cy='file-name-menu' className='max-w-[250px] truncate'>
          {currentFile?.metadata?.FileName || ''}
        </div>
      );
    return (
      <FileNameMenu
        currentFile={currentFile}
        defaultFileName={currentFile?.metadata?.FileName || ''}
        defaultFileNote={currentFile?.Note || ''}
        fetchFileOnChange={() => dispatch(fetchOpenedFile(getFileUuidFromPath()))}
        changeUrl={true}
        className='navbar__rename-btn'
      />
    );
  };

  const getCopyLinkBtn = () => {
    if (!isSpreadsheetPage) return null;
    return copyLinkButton;
  };

  if (isSpreadsheetPage && !currentFile) return null;

  const getCommentIcon = () => {
    if (isLibraryPage || isSettingsPage || isScheduledImportPage || isNexusPage) return null;

    const handleClick = () => {
      if (user?.is_anonymous) {
        dispatch(showModal({ name: MODAL_REQUIRE_ACCOUNT }));
      } else if (!user?.is_anonymous && isFileShared && hasNoWritePermission(currentFile)) {
        dispatch(showModal({ name: MODAL_MAKE_COPY }));
      } else if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
        dispatch(
          showModal({
            name:
              currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
                ? MODAL_UPGRADE_REQUIRED
                : MODAL_EXCEEDS_LIMITS,
          })
        );
      } else {
        dispatch(
          showModal({
            name: MODAL_COMMENTS,
            props: {
              showAllComments: true,
              column: '',
              row: -1,
              isColumnComment: false,
            },
          })
        );
      }
    };

    return (
      <Tooltip
        text='Show Comment History'
        tooltipWidth='w-48'
        className='flex items-center justify-center'
        asChild
      >
        <Button
          dataCy='all-comments-icon'
          onClick={handleClick}
          variant='ghost'
          color='shadow'
          className='relative h-6'
        >
          <Icon name='chats' color='#120A6E' />
        </Button>
      </Tooltip>
    );
  };

  return (
    <div className='sticky top-0 w-full bg-white z-[3]'>
      {shouldShowLoaderBar && <SheetLoader className='top-0 left-0' />}
      <div className='relative flex items-center justify-between px-4 flex-nowrap'>
        <div className='flex items-center md:!justify-center md:w-1/2'>
          {isSpreadsheetPage && !isIframe && (
            <Button
              iconName='arrow-left'
              color='shadow'
              variant='ghost'
              className='h-6 md:hidden'
              onClick={navigateToLibrary}
              dataCy='mobile-go-to-library'
            />
          )}
          {isLibraryPage && (
            <LogoLink
              className='md:hidden'
              logoLink={isIframe ? shareFileUrl : 'https://www.gigasheet.com'}
              isIframe={isIframe}
            />
          )}
          {!isSpreadsheetPage && (
            <div data-cy='page-header-wrapper' className='navbar__page-header'>
              {getPageHeader()}
            </div>
          )}

          {isIframe ? (
            <span className='flex items-center'>
              <div data-cy='file-name-menu'>
                <a href={shareFileUrl} target='_parent'>
                  {fileName}
                </a>
              </div>
            </span>
          ) : (
            <span className={clsx('items-center hidden md:flex')}>{getFileNameComponent()}</span>
          )}

          {user?.is_anonymous && isLibraryPage ? (
            <div className='flex justify-start w-full '>
              <Button
                className='h-6 ml-0'
                dataCy='nav-signup-btn'
                onClick={() => {
                  const authStateNonce = getAuthNonceAndSetInStorage();
                  loginWithRedirect({
                    screen_hint: 'signup',
                    appState: {
                      returnTo: location.pathname + location.search,
                      referrerId: getReferrerUrl(),
                      authStateNonce,
                    },
                  });
                }}
              >
                Sign Up For Free
              </Button>
            </div>
          ) : null}
        </div>
        {!user?.is_anonymous && !isIframe ? (
          <>
            {isSpreadsheetPage && (
              <div className='fixed h-6 bg-white border rounded right-4 top-12 w-fit'>
                <CustomEnrichmentStatus fileUuid={fileUuid} />
              </div>
            )}
            <div className='flex items-center md:w-1/2 md:!justify-end'>
              <span className={clsx('items-center hidden pr-3 md:flex')}>
                {getCommentIcon()}
                {getNavBarBtn()}
                {getCopyLinkBtn()}
              </span>

              <span className='w-[1px] h-6 mr-3 bg-shadow-200 top' />

              {!isMobileScreen && user && !user[userRolesKey].includes('is-paying') && (
                <div>
                  <UpgradeButton className='!h-6' color='pear' dataCy='nav-upgrade-link' />
                </div>
              )}
              {!isMobileScreen && (
                <Popover
                  isStatic={false}
                  isButtonIcon={false}
                  isCloseButton={false}
                  buttonClassname={'text-sm !p-0 ml-2'}
                  panelClassname='!w-[250px] !px-1 !py-1 mt-1 right-0 !focus:outline-0 !focus-within:outline-none !focus-visible:outline-none !outline-0 !ring-0'
                  renderComponent={() => (
                    <UserDropdownMenu
                      isMobileExperienceActive={isMobileExperienceActive}
                      isMobileScreen={isMobileScreen}
                      signOut={signOut}
                      setMobileExperience={setMobileExperience}
                      user={user}
                    />
                  )}
                >
                  <div
                    className='flex items-center justify-center w-6 h-6 text-sm text-center text-white rounded bg-midnight'
                    data-cy='user-dropdown-menu'
                  >
                    {(name && name[0]?.toUpperCase()) || (email && email[0]?.toUpperCase())}
                  </div>
                </Popover>
              )}

              {isLibraryPage && (
                <Button
                  dataCy='mobile-upload-file-btn'
                  color='sunrise'
                  iconName='plus'
                  size='large'
                  className='ml-1 md:hidden'
                  onClick={() => dispatch(showModal({ name: MODAL_UPLOAD_FILE }))}
                />
              )}

              {isMobileExperienceActive && (
                <>
                  {isSpreadsheetPage && (
                    <>
                      <Button
                        color='oceanBlue'
                        iconName='group'
                        size='large'
                        className='md:hidden !opacity-40 ml-1'
                        onClick={() => toggleMobileExperienceModal(true)}
                      />
                      <Modal
                        title='Limited Mobile Experience'
                        isOpen={isMobileExperienceModalOpen}
                        onClose={() => toggleMobileExperienceModal(false)}
                      >
                        <p className='text-sm leading-tight text-ui-ui'>
                          To use advanced features like filters, groups, and more you’ll need to
                          switch to the desktop experience.
                        </p>
                        <div className='flex items-center justify-center pt-6 space-x-4'>
                          <Button
                            color='shadow'
                            variant='ghost'
                            onClick={() => toggleMobileExperienceModal(false)}
                          >
                            Dismiss
                          </Button>
                          <Button color='sunrise' onClick={() => setMobileExperience('false')}>
                            Switch to Desktop
                          </Button>
                        </div>
                      </Modal>
                      <Button
                        className={clsx(
                          'ml-1 md:hidden',
                          isMobileSheetAssistantOpen && '!bg-midnight-100'
                        )}
                        iconName='sparkle'
                        color='shadow'
                        variant='ghost'
                        size='large'
                        disabled={shouldDisplayViewOnly}
                        onClick={() => dispatch(setProperMobileDrawerContent(SHEET_ASSISTANT))}
                      />
                    </>
                  )}
                </>
              )}

              {isSpreadsheetPage && (
                <>
                  {!shouldDisplayViewOnly && (
                    <Button
                      className='ml-1 md:hidden'
                      iconName='user-plus'
                      color='shadow'
                      variant='ghost'
                      size='large'
                      onClick={() => dispatch(showModal({ name: MODAL_SHARE_FILE }))}
                    />
                  )}
                  <Button
                    color='shadow'
                    iconName='info'
                    variant='ghost'
                    size='large'
                    className='ml-2 md:hidden'
                    onClick={() => toggleFilePropertiesModal(true)}
                  />
                  {isMobileScreen && <ViewOnly buttonSize='large' buttonVariant='ghost' />}
                  <Modal
                    title='File Properties'
                    iconName='pencil-simple'
                    isOpen={isFilePropertiesModalOpen}
                    onClose={() => toggleFilePropertiesModal(false)}
                  >
                    {currentFile && (
                      <>
                        <div className='inline-flex flex-col justify-start w-full pb-3'>
                          <p className='pb-2 text-base font-semibold leading-tight uppercase text-ui'>
                            File Name
                          </p>
                          <div className='inline-flex items-center w-full h-8 pl-3 pr-6 border rounded bg-ui-secondary border-ui-100'>
                            <p className='text-base leading-tight text-ui-helper'>
                              {currentFile?.metadata?.FileName}
                            </p>
                          </div>
                        </div>
                        <div className='flex flex-col justify-start'>
                          <div className='inline-flex'>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='text-base font-semibold text-center text-ui-helper'>
                                Size
                              </p>
                            </div>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='text-base'>
                                {parseFileSize(currentFile?.metadata?.FileSize)}
                              </p>
                            </div>
                          </div>
                          <div className='inline-flex'>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='font-semibold text-center text-bas text-ui-helper'>
                                Rows
                              </p>
                            </div>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='text-base'>{currentFile?.metadata?.FileRows}</p>
                            </div>
                          </div>
                          <div className='inline-flex'>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='text-base font-semibold text-center text-ui-helper'>
                                Columns
                              </p>
                            </div>
                            <div className='flex items-center flex-1'>
                              <p className='text-base'>{currentFile?.metadata?.Headers?.length}</p>
                            </div>
                          </div>
                          <div className='inline-flex'>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <p className='text-base font-semibold text-center text-ui-helper'>
                                Sharing Visibility
                              </p>
                            </div>
                            <div className='flex space-x-1.5 items-center flex-1'>
                              <Icon name={currentFile.IsWorldReadable ? 'globe' : 'lock'} />
                              <p className='text-base'>
                                {currentFile.IsWorldReadable ? 'Public' : 'Private'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Modal>
                </>
              )}
              <Button
                dataCy='mobile-nav-bar-dropdown-open-btn'
                color='shadow'
                iconName='dots-three-vertical'
                size='large'
                variant='ghost'
                className='ml-1 md:hidden'
                onClick={() => setIsMobileDropMenuOpen(true)}
              />

              <MobileDropMenu
                isOpen={isMobileDropMenuOpen}
                onClose={() => setIsMobileDropMenuOpen(false)}
              >
                <p
                  data-cy='mobile-active-dropdown-email'
                  className='px-3 py-2 font-semibold border-b'
                >
                  {email}
                </p>
                {isMobileScreen && !isMobileExperienceActive && (
                  <Button
                    dataCy='mobile-active-dropdown-sign-out-btn'
                    className={'w-full !justify-start !text-ui'}
                    variant='ghost'
                    onClick={() => setMobileExperience('true')}
                    iconName='sign-out'
                    size='large'
                  >
                    Switch to Mobile View
                  </Button>
                )}
                <Button
                  dataCy='mobile-active-dropdown-help-btn'
                  color='shadow'
                  className={'w-full !justify-start !text-ui'}
                  variant='ghost'
                  onClick={navigateToHelp}
                  iconName='question'
                  size='large'
                >
                  Help
                </Button>
                <Button
                  dataCy='mobile-active-dropdown-sign-out-btn'
                  className={'w-full !justify-start !text-ui'}
                  variant='ghost'
                  onClick={signOut}
                  iconName='sign-out'
                  size='large'
                >
                  Sign Out
                </Button>
              </MobileDropMenu>
            </div>
          </>
        ) : (
          <div className='flex justify-end'>
            {!isIframe && (
              <span className={clsx('items-center hidden pr-3 md:flex')}>
                {getNavBarBtn()}
                {getCopyLinkBtn()}
              </span>
            )}
            <LogoLink
              logoLink={isIframe ? shareFileUrl : 'https://www.gigasheet.com'}
              isIframe={isIframe}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default Navigation;
