import clsx from 'clsx';
import HelperText from '../../../../Components/UI/HelperText/HelperText';
import Label from '../../../../Components/UI/Form/Label/Label';

type Props = {
  label?: string | React.ReactNode;
  color?: 'shadow' | 'oceanBlue' | 'violetWeb' | 'pear' | 'capri';
  disabled?: boolean;
  helperText?: string;
  helperTextPosition?: 'top' | 'bottom';
  helperTextLowercase?: boolean;
  children: React.ReactNode;
  className?: string;
  labelClassName?: string;
  dataCy?: string;
};

const InputField = ({
  label,
  color = 'shadow',
  disabled,
  helperText,
  helperTextPosition = 'bottom',
  helperTextLowercase = false,
  children,
  className,
  labelClassName,
  dataCy,
}: Props) => {
  return (
    <div className={clsx(className, 'w-full')}>
      {label && (
        <Label
          dataCy={dataCy}
          text={label}
          className={clsx(
            helperText && helperTextPosition === 'top' && 'mb-1',
            !helperText && 'mb-2',
            labelClassName
          )}
        />
      )}
      {helperText && helperTextPosition === 'top' && (
        <HelperText
          dataCy={dataCy}
          className='mb-2'
          text={helperText}
          color={disabled || color === 'oceanBlue' ? 'shadow' : color}
          lowercase={helperTextLowercase}
        />
      )}
      {children}
      {helperText && helperTextPosition === 'bottom' && (
        <HelperText
          dataCy={dataCy}
          className='mt-2 break-words'
          text={helperText}
          color={disabled || color === 'oceanBlue' ? 'shadow' : color}
          lowercase={helperTextLowercase}
        />
      )}
    </div>
  );
};

export default InputField;
