import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';
import ChangeDataType from './ChangeDataType';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import {
  changeIndividualColumnState,
  setAlwaysConfirm,
  setIsInsertingNewColumn,
} from '@/redux/reducers/spreadsheetSlice';

import {
  MODAL_CHANGE_DATA_TYPE,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_CHANGE_DATA_TYPE_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { post } from '@/Utils/API';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ChangeDataTypeModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const {
    columnDefs,
    clientState: { alwaysConfirm },
  } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const { options, targetColumn, setTargetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
  });

  const [changeDataTypeData, setChangeDataTypeData] = useState(null);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const currentFile = useCurrentFile();

  const changeDataType = async () => {
    setIsConfirmStep(false);
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      hideModal();
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      setIsSaveBtnDisabled(true);

      const columnId = changeDataTypeData.sourceColumn.value;
      const uuid = getFileUuidFromPath();
      const payload = {
        cast_type: changeDataTypeData.castType,
        cast_format: changeDataTypeData.format,
      };

      const endpoint = `cast-column/${uuid}/${columnId}`;
      const castResponse = await post(endpoint, payload);

      if (!castResponse?.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_CHANGE_DATA_TYPE_ERROR,
          errorContext: castResponse,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }

      // set alwaysConfirm flag
      if (changeDataTypeData.isConfirmDialogChecked) {
        dispatch(setAlwaysConfirm(false));
      }

      dispatch(changeIndividualColumnState({ colId: targetColumn.value, hide: true }));
      dispatch(fetchOpenedFile(uuid));

      setIsSaveBtnDisabled(false);

      dispatch(
        addMixpanelEvent({
          eventName: 'Change Data Type',
          eventProps: {
            'Source Type': changeDataTypeData.srcType,
            'Source Type Technical': changeDataTypeData.technicalSrcType,
            'Destination Type': changeDataTypeData.castType,
            'Destination Format': changeDataTypeData.format,
          },
          userIncrementName: '# of changed data types',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const save = () => {
    if (!alwaysConfirm || isConfirmStep) {
      changeDataType();
    } else setIsConfirmStep(true);
  };

  const cancel = () => {
    if (isConfirmStep) setIsConfirmStep(false);
    else {
      setTargetColumn(null);
      hideModal();
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={cancel}
      titleSize='large'
      size='small'
      dataCy={isConfirmStep ? 'change-data-type-confirm-step' : ''}
      title={isConfirmStep ? 'Are you sure you want to convert this?' : 'Change Data Type'}
      iconName={isConfirmStep ? '' : 'arrows-left-right'}
      articleID='69000811418'
    >
      <ChangeDataType
        options={options}
        targetColumn={targetColumn}
        isConfirmStep={isConfirmStep}
        setTargetColumn={setTargetColumn}
        setSaveBtnDisabled={setIsSaveBtnDisabled}
        updateChangeDataTypeState={(data) => setChangeDataTypeData(data)}
      />

      <hr className='mt-10 mb-5 solid' />
      <div className='flex items-center justify-end gap-4 px-4'>
        <Button variant='ghost' color='shadow' size='large' dataCy='cancel-btn' onClick={cancel}>
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          dataCy='save-btn'
          onClick={save}
          disabled={isSaveBtnDisabled}
        >
          {isConfirmStep ? 'Proceed' : 'Apply'}
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CHANGE_DATA_TYPE })(ChangeDataTypeModal);
