import { useState, useEffect } from 'react';

import { put } from '../../../Utils/API';
import Icon from '../../../Components/UI/Icon/Icon';
import {
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  URL_DATASET,
} from '../../../Utils/constants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSliceName } from '../../../redux/constants';
import { showModal } from '../../../redux/reducers/modalsSlice';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const FlagPanel = ({ rowData, agGrid }) => {
  const [rowFlag, setRowFlag] = useState(-1);
  const [showFlaggingCard, setShowFlaggingCard] = useState(false);
  const currentFile = useCurrentFile();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const dispatch = useDispatch();

  const flagRows = async (flag, rowData) => {
    if (rowData && rowData.A) {
      const uuid = getFileUuidFromPath();

      const payload = { flags: {} };
      payload.flags[rowData.A] = flag;
      await put(`${URL_DATASET}/${uuid}/flag`, payload);
      setRowFlag(flag);
      agGrid.api.refreshServerSide();
    }
  };

  const CurrentFlagItem = ({ id, flag, title, icon, color }) => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <li
        className='px-4 py-2.5 cursor-pointer hover:bg-capri-100'
        id={id}
        onClick={() => flagRows(flag, rowData)}
      >
        <span className='flex items-center h-[20px]'>
          {icon && (
            <Icon name={icon} className='ml-1 custom-component-flagging-flag-icon' color={color} />
          )}
          {title}
          <span className='ml-auto'>
            {flag === Number(rowFlag) && <Icon name='check' size={20} />}
          </span>
        </span>
      </li>
    );
  };

  const getCurrentFlagItem = (flag) => {
    if (showFlaggingCard) {
      switch (flag) {
        case 1: {
          return (
            <CurrentFlagItem
              id={'flag-good'}
              flag={flag}
              title={'Good'}
              icon='smiley'
              color='#589F23'
            />
          );
        }
        case 2: {
          return (
            <CurrentFlagItem
              id={'flag-suspicious'}
              flag={flag}
              title={'Suspicious'}
              icon='smiley-meh'
              color='#C0660C'
            />
          );
        }
        case 3: {
          return (
            <CurrentFlagItem
              id={'flag-bad'}
              flag={flag}
              title={'Bad'}
              icon='smiley-x-eyes'
              color='#AA10C1'
            />
          );
        }
        default: {
          return <CurrentFlagItem id={'flag-none'} flag={flag} title={'None'} />;
        }
      }
    }
  };

  const toggleFlagging = () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    } else {
      setShowFlaggingCard(!showFlaggingCard);
    }
  };

  const generateSelectedRowClassName = (flag) => {
    switch (flag) {
      case '1':
        return 'row-selected-good';
      case '2':
        return 'row-selected-suspicious';
      case '3':
        return 'row-selected-bad';
      default:
        return 'row-selected-default';
    }
  };

  useEffect(() => {
    if (rowData) {
      setRowFlag(rowData.metadata_tag);
    }
  }, [rowData]);

  useEffect(() => {
    const selectedRowNodes = document.getElementsByClassName('ag-row-focus');
    for (const node of selectedRowNodes) {
      node.classList.add(generateSelectedRowClassName(rowData?.metadata_tag));
    }
  }, [rowData, rowFlag]);

  return (
    <div
      className={
        showFlaggingCard ? 'custom-component-flagging-unfolded' : 'custom-component-flagging-folded'
      }
    >
      <div className='w-full overflow-hidden bg-white rounded-sm shadow-sm'>
        <button
          className='w-full overflow-hidden bg-white border rounded-sm border-ui-200'
          onClick={() => toggleFlagging()}
        >
          <div className='flex items-center justify-between w-full px-4 py-2.5 bg-ui-secondary'>
            <span className='flex items-center' data-cy='flag-panel-header'>
              <Icon name='flag-banner' size={12} />
              <span className='ml-2'>Flag As</span>
            </span>
            <Icon name={!showFlaggingCard ? 'caret-right' : 'caret-down'} size={20} weight='fill' />
          </div>
        </button>
        <ul className='border-b divide-y border-ui-200 divide-ui-200'>
          {getCurrentFlagItem(0)}
          {getCurrentFlagItem(1)}
          {getCurrentFlagItem(2)}
          {getCurrentFlagItem(3)}
        </ul>
      </div>
    </div>
  );
};

export default FlagPanel;
