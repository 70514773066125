import { createSlice } from '@reduxjs/toolkit';

const buildSlice = createSlice({
  name: 'build',
  initialState: {
    commit_id: null,
  },
  reducers: {
    setCommitId: (state, action) => {
      state.commit_id = action.payload;
    },
  },
});

export const { setCommitId } = buildSlice.actions;
export default buildSlice.reducer;
