import { get, post } from '@/Utils/API';
import {
  TOAST_TEXT_FILE_IS_ALREADY_EXPORTED_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_DATASETS,
} from '@/Utils/constants';
import {
  FILE_STATUS_LOADING,
  FILE_STATUS_PROCESSING,
  FILE_TYPE_EXPORTER,
} from '@/Utils/fileConstants';

import showToast from '@/Components/Toast/showToastTemplate';
import { getExportFileStatus } from '@/Utils/getExportFileStatus';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import { store } from '@/redux/store';

export const exportAllRows = async (getGridRequestData, file, shouldDisableActions, dispatch) => {
  const { FileName, FileUuid } = file;
  const customExportCSVData = getGridRequestData;

  try {
    dispatch(setIsInsertingNewColumn(true));
    let simultaneousExport = false;
    const files = await get(URL_DATASETS);
    files !== null &&
      files.forEach((file) => {
        if (
          file.Source === FileUuid &&
          file.Type === FILE_TYPE_EXPORTER &&
          (file.Status === FILE_STATUS_PROCESSING || file.Status === FILE_STATUS_LOADING)
        ) {
          simultaneousExport = true;
          showToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_FILE_IS_ALREADY_EXPORTED_ERROR,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
          return;
        }
      });
    if (simultaneousExport) {
      return;
    }

    if (shouldDisableActions) {
      return;
    }
    const {
      totalRowCount,
      rowGroupCols,
      valueCols,
      pivotCols,
      pivotMode,
      groupKeys,
      filterModel,
      sortModel,
    } = customExportCSVData;

    const state = store.getState();
    const { columnState } = state.spreadsheet.clientState;
    const updatedRowGroupCols = rowGroupCols.map((col) => {
      const column = columnState.find((e) => e.colId === col.id);
      return {
        ...col,
        groupFunc: column?.groupFunc ?? '',
      };
    });

    const gridState = {
      startRow: 0,
      endRow: totalRowCount,
      rowGroupCols: updatedRowGroupCols,
      valueCols,
      pivotCols,
      pivotMode,
      groupKeys,
      filterModel,
      sortModel,
    };
    const data = {
      gridState: gridState,
      filename: `${FileName}_exported.csv`,
    };
    await post(`${URL_DATASET}/${FileUuid}/export`, data, true);

    await getExportFileStatus(() => '', dispatch);

    dispatch(
      addMixpanelEvent({
        eventName: 'File Export',
        eventProps: { 'Export Type': 'All Rows' },
        userIncrementName: '# of exports',
      })
    );
  } catch (error) {
    throw new Error(error);
  } finally {
    dispatch(setIsInsertingNewColumn(false));
  }
};
