import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '@/Components/UI/Spinner/Spinner';
import { Button } from '@/Components/UI/Button/Button';
import ShowToast from '@/Components/Toast/showToastTemplate';

import { sharedSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { setNewEnrichmentStarted } from '@/redux/reducers/sharedSlice';

import { get, post } from '@/Utils/API';

import {
  CHANGED_COLUMN_POSITION,
  TOAST_TEXT_CUSTOM_ENRICHMENT_COMPLETE,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_SUCCESS,
  URL_DATASET,
} from '@/Utils/constants';

const ALTERNATING_TIME = 10000;

const CustomEnrichmentStatus = ({ fileUuid }) => {
  const [task, setTask] = useState(null);
  const [activeTaskId, setActiveTaskId] = useState(null);
  const [tasksToPoll, setTasksToPoll] = useState([]);
  const [isCancelling, setIsCancelling] = useState(false);
  const dispatch = useDispatch();

  const { newEnrichmentStarted } = useSelector((state) => state[sharedSliceName]);

  const getActiveEnrichmentTasks = async () => {
    const response = await get(`enrich/user-defined-http/tasks?sheet=${fileUuid}`);
    const notCompletedTasks = response.filter(
      (task) => task.Status !== 'Done' && task.Status !== 'Cancelled' && task.Status !== 'Error'
    );
    setTasksToPoll(notCompletedTasks);
  };

  const getEnrichmentTaskStatus = async (taskId) => {
    if (!taskId) return;
    const endpoint = `enrich/user-defined-http/${taskId}`;
    const response = await get(endpoint);

    if (response.Status === 'Error') {
      dispatch(fetchOpenedFile(fileUuid));
      setTasksToPoll(tasksToPoll.filter((taskToPoll) => taskToPoll.TaskId !== taskId));
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: response.LastMessage,
        errorContext: response,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } else if (response.Status === 'Done' && response.PercentComplete === 100) {
      dispatch(fetchOpenedFile(fileUuid));
      setTasksToPoll(tasksToPoll.filter((taskToPoll) => taskToPoll.TaskId !== taskId));
      ShowToast({ type: TOAST_TYPE_SUCCESS, text: TOAST_TEXT_CUSTOM_ENRICHMENT_COMPLETE });
    } else if (response.Status === 'Cancelled') {
      dispatch(fetchOpenedFile(fileUuid));
      setTasksToPoll(tasksToPoll.filter((taskToPoll) => taskToPoll.TaskId !== taskId));
      setIsCancelling(false);
    } else {
      setTask(response);
      setActiveTaskId(response.TaskId !== '' ? response.TaskId : activeTaskId);
      dispatch(fetchOpenedFile(fileUuid));
    }
  };

  const cancelEnrichmentTask = async (taskId) => {
    if (!taskId) return;
    const response = await post(`enrich/user-defined-http/${taskId}/cancel`);

    if (response.Success) {
      setIsCancelling(true);
    }
  };

  useEffect(() => {
    if (newEnrichmentStarted) dispatch(setNewEnrichmentStarted(false));
    if (fileUuid) getActiveEnrichmentTasks(fileUuid);
  }, [fileUuid, newEnrichmentStarted]);

  useEffect(() => {
    if (tasksToPoll.length > 0) {
      setTask(tasksToPoll[0]);
      setActiveTaskId(tasksToPoll[0].TaskId);
    } else {
      setTask(null);
      setActiveTaskId(null);
    }
  }, [tasksToPoll]);

  useEffect(() => {
    if (activeTaskId) {
      const interval = setInterval(() => {
        getEnrichmentTaskStatus(activeTaskId);
      }, ALTERNATING_TIME);
      return () => clearInterval(interval);
    }
  }, [activeTaskId]);

  return (
    <span className={clsx(!task && 'hidden')}>
      <span className='text-midnight'>
        <Spinner size='medium' color='midnight' className={'inline-block'} />
        {isCancelling ? (
          <p className='inline-block ml-2 text-xs'>Cancelling Custom Enrichment</p>
        ) : (
          <>
            <p className='inline-block ml-2 text-xs'>
              {task?.Status === 'NotStarted' && 'Custom Enrichment Queued'}
              {task?.Status === 'Started' && 'Preparing Custom Enrichment'}
              {task?.Status === 'Working' && task?.PercentComplete !== undefined
                ? `Obtaining Custom Enrichment Results... ${task?.PercentComplete}% complete`
                : ''}
            </p>
            <Button
              color='shadow'
              size='small'
              className='ml-2'
              variant='ghost'
              onClick={() => cancelEnrichmentTask(activeTaskId)}
            >
              Cancel
            </Button>
          </>
        )}
      </span>
    </span>
  );
};

export default CustomEnrichmentStatus;
