import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useOnClickOutsideHook from '@/hooks/useOnClickOutside';
import { Mixpanel } from '@/Mixpanel';
import { datasetSliceName, spreadsheetSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { prepareClientState } from '@/redux/reducers/spreadsheetSlice';
import { post } from '@/Utils/API';
import {
  COPY,
  FILE,
  MODAL_MAKE_COPY_SUCCESS,
  MODAL_UPGRADE_ACCOUNT,
  PERMISSION_WRITE,
  upgradeAccountTypes,
} from '@/Utils/constants';
import { checkFileExceedsQuota, openInNewTab } from '@/Utils/utils';
import useViewportProvider from '@/hooks/useViewportProvider';

import { Button } from '@/Components/UI/Button/Button';
import './styles.scss';
import clsx from 'clsx';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';

const ViewOnly = ({ buttonSize = 'small', buttonVariant = 'solid', otherClassNames = '' }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const { currentFile, filesSize, currentDataset, currentDir } = useSelector(
    (state) => state[datasetSliceName]
  );
  const { sendMessage } = useWebSocketContext();
  const clientState = useSelector((state) => state[spreadsheetSliceName].clientState);
  const { FileUuid: uuid } = currentFile?.metadata || {};
  const refDropdown = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const supportLink =
    'https://gigasheet.freshdesk.com/support/solutions/articles/69000818491-view-only-mode';
  const isSpreadsheetPage = window.location.pathname.includes('spreadsheet');

  const exceedsQuota = checkFileExceedsQuota(filesSize, currentFile, user);
  const { isMobileScreen } = useViewportProvider();

  const _setIsDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const openSupportLink = () => {
    openInNewTab(supportLink);
  };

  const createCopy = async () => {
    _setIsDropdownOpen();
    if (exceedsQuota) {
      dispatch(
        showModal({
          name: MODAL_UPGRADE_ACCOUNT,
          props: { upgradeType: upgradeAccountTypes.maxStorageSize },
        })
      );
    } else {
      if (uuid != null) {
        const bodyData = {
          state: prepareClientState(clientState),
        };
        const result = await post(`${FILE}/${uuid}/${COPY}`, bodyData);
        Mixpanel.track('User Saves A Shared File to My Files', {
          file_uuid: uuid,
        });

        if (result.Success) {
          dispatch(
            showModal({
              name: MODAL_MAKE_COPY_SUCCESS,
              props: {
                uuid: result.NewHandle,
              },
            })
          );
          sendMessage({
            location: `${currentDir}`,
            category: getWebSocketCategory(currentDataset),
          });
        }
      }
    }
  };

  useOnClickOutsideHook(isDropdownOpen, refDropdown, _setIsDropdownOpen);

  const shouldDisplayViewOnly = () => {
    return (
      !user?.is_anonymous &&
      isSpreadsheetPage &&
      currentFile?.isFileShared &&
      !currentFile?.availablePermissions?.includes(PERMISSION_WRITE)
    );
  };

  return (
    <div>
      {shouldDisplayViewOnly() && (
        <Button
          iconName='pencil-simple-slash'
          color='violetWeb'
          size={buttonSize}
          variant={buttonVariant}
          onClick={_setIsDropdownOpen}
          className={clsx('mx-1', otherClassNames)}
        >
          {!isMobileScreen && 'View Only'}
        </Button>
      )}
      {isDropdownOpen && (
        <div ref={refDropdown} className={clsx('view-only-popout', isMobileScreen && 'right-4')}>
          <div
            role='button'
            tabIndex={0}
            onKeyDown={() => null}
            onClick={openSupportLink}
            className='view-only-popout-question-mark'
          />
          <div
            role='button'
            tabIndex={0}
            onKeyDown={() => null}
            onClick={_setIsDropdownOpen}
            className='btn-close'
          />
          <div className='view-only-popout-header'>View Only</div>
          <p>
            You can only <strong>view</strong> and{' '}
            <strong>apply temporary filters or groups</strong> to this file. To make changes save a
            copy to your account.
          </p>
          <div className='view-only-popout-actions'>
            <Button color='oceanBlue' data-cy='save-btn' onClick={createCopy}>
              Make a Copy
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewOnly;
