import { useState, useEffect, useRef } from 'react';
import { useHover } from '@/hooks/useHoverHook';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import clsx from 'clsx';

const clipTextClassNames = 'overflow-hidden whitespace-nowrap text-ellipsis font-medium mb-0';

const TextClipper = ({ text, className, ...rest }) => {
  const [hoverRef, isHovered] = useHover();
  const [showTooltip, setShowTooltip] = useState(false);

  const hoverStateRef = useRef(isHovered);

  useEffect(() => {
    if (hoverStateRef.current === isHovered) return;
    hoverStateRef.current = isHovered;

    const checkTextClipping = () => {
      const { current } = hoverRef;
      const isClipped = current && current.offsetWidth < current.scrollWidth;
      setShowTooltip(isClipped);
    };

    checkTextClipping();

    window.addEventListener('resize', checkTextClipping);
    return () => window.removeEventListener('resize', checkTextClipping);
  }, [hoverRef, isHovered]);

  return showTooltip ? (
    <Tooltip text={text} side='bottom' asChild className='w-48 break-words'>
      <p ref={hoverRef} className={clsx(clipTextClassNames, className)} {...rest}>
        {text}
      </p>
    </Tooltip>
  ) : (
    <p ref={hoverRef} className={clsx(clipTextClassNames, className)} {...rest}>
      {text}
    </p>
  );
};

export default TextClipper;
