import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { CustomCalendarHeader } from '@/Components/CustomDatePicker/CalendarHeader';

import './quickPickerStyles.scss';

const QuickDatePicker = ({
  selected,
  onChange,
  onYearChange = () => {},
  id,
  value,
  isSelectStart,
  isSelectEnd,
  startDate,
  endDate,
  onFocus,
  onBlur,
  onClose,
  monthsShown,
  popperPlacement,
  className,
  disabled,
  portalId,
}) => {
  const calendarHeader = ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth }) => (
    <CustomCalendarHeader
      date={date}
      changeYear={changeYear}
      changeMonth={changeMonth}
      decreaseMonth={decreaseMonth}
      increaseMonth={increaseMonth}
    />
  );

  return (
    <div className='calendar-filter__wrapper mobile-date-picker'>
      <DatePicker
        className={className}
        id={id}
        selected={selected}
        onChange={onChange}
        value={value}
        selectsStart={isSelectStart}
        selectsEnd={isSelectEnd}
        startDate={startDate}
        endDate={endDate}
        onYearChange={onYearChange}
        onCalendarClose={onClose}
        placeholderText='YYYY.MM.DD, HH:MM:SS'
        dateFormat='yyyy.MM.dd, HH:mm:ss'
        timeInputLabel='Set Hours:'
        monthsShown={monthsShown}
        shouldCloseOnSelect={false}
        popperPlacement={popperPlacement}
        disabledKeyboardNavigation
        showTimeInput
        renderCustomHeader={calendarHeader}
        onFocus={onFocus}
        onBlur={onBlur}
        portalId={portalId}
        disabled={disabled}
      />
    </div>
  );
};

export default QuickDatePicker;
