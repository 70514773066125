import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

function LoadingOverlayReact() {
  return (
    <LoadingOverlay
      active={true}
      spinner={<BounceLoader />}
      styles={{
        overlay: (base) => ({
          ...base,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
      }}
    />
  );
}

LoadingOverlay.propTypes = undefined;

export default LoadingOverlayReact;
