import Icon from '../../../Components/UI/Icon/Icon';

import useViewportProvider from '@/hooks/useViewportProvider';
import { userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThreeDotsLoader from '../../../Components/Loader/ThreeDotsLoader/ThreeDotsLoader';
import { Button, Link } from '../../../Components/UI/Button/Button';
import { get, headers, postRaw } from '../../../Utils/API';
import {
  ACTION_FILTER,
  ACTION_GROUP,
  ACTION_SORT,
  LINK_SHEET_ASSISTANT_SUPPORT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_FILTER_PANEL,
  MODAL_GROUP_BY,
  MODAL_MAKE_COPY_FILE,
  MODAL_SORT_COLUMN,
  MODAL_UPGRADE_REQUIRED,
  PERMISSION_WRITE,
  URL_AI,
  URL_DATASET,
} from '../../../Utils/constants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';
import { hideAllModals, showModal } from '../../../redux/reducers/modalsSlice';
import {
  restoreGridState,
  setIsInsertingNewColumn,
} from '../../../redux/reducers/spreadsheetSlice';
import SheetAssistantDialogSection from './SheetAssistantDialogSection';
import SheetAssistantTipsSection from './SheetAssistantTipsSection';
import SheetAssistantSettings from './SheetAssistantSettings';
import SheetAssistantChatMessage from './SheetAssistantChatMessage';
import { getWebsocketHostname } from '@/Utils/utils';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import useCanExportOrSave from '@/hooks/useCanExportOrSave';

const SheetAssistant = (agGrid) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [disableSheetAssistant, setDisableSheetAssistant] = useState(null);
  const [assistantResponseIsLoading, setAssistantResponseIsLoading] = useState(false);
  const [assistantResponse, setAssistantResponse] = useState([]);

  const chatEndRef = useRef(null);
  const chatRef = useRef(null);
  const messageRefs = useRef([]);

  const dispatch = useDispatch();
  const handle = getFileUuidFromPath();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();
  const { isCanExportFilteredGroupedRolePaywall } = useCanExportOrSave();
  const assistantResponseTimeouts = useRef([]);

  const { isMobileScreen } = useViewportProvider();
  const isMobileExperienceActive = localStorage.getItem('isMobileExperience') === 'true';

  const viewOnlyMode =
    currentFile?.isFileShared && !currentFile?.availablePermissions?.includes(PERMISSION_WRITE);

  const scrollToBottom = useMemo(() => {
    return () => {
      chatEndRef.current?.scrollIntoView();
    };
  }, [chatEndRef]);

  const scrollDownALittle = useCallback(() => {
    if (chatRef.current) {
      chatRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    }
  }, [chatRef]);

  const resetAssistantResponse = useCallback(() => {
    setAssistantResponse([]);
  }, [setAssistantResponse]);

  useEffect(() => {
    if (assistantResponse?.length > 0) {
      if (!isInitialLoad) {
        scrollDownALittle();
      }
    }
  }, [isInitialLoad, assistantResponse, scrollDownALittle]);

  useEffect(() => {
    let websocket;
    let reconnectionAttempts = 0;
    const maxReconnectionAttempts = 5;
    const reconnectionDelay = 2000; // 2 seconds
    const maxReconnectionDelay = 30000; // 30 seconds

    const connectWebSocket = () => {
      websocket = new WebSocket(`${getWebsocketHostname()}/${URL_AI}/${handle}/logs/ws`);

      websocket.onopen = () => {
        reconnectionAttempts = 0; // Reset reconnection attempts on successful connection
        const requestHeaders = headers();
        websocket.send(JSON.stringify(requestHeaders));
      };

      websocket.onmessage = (event) => {
        try {
          const history = JSON.parse(event.data);
          if (history?.length > 0) {
            const formattedHistory = history
              .reverse()
              .filter((message) => message.MessageType !== 'assistant-action-placeholder');

            if (JSON.stringify(formattedHistory) !== JSON.stringify(assistantResponse)) {
              if (isInitialLoad) {
                setAssistantResponse(formattedHistory);
                setIsInitialLoad(false);
                setTimeout(() => {
                  scrollToBottom();
                }, 100);
              } else {
                assistantResponseTimeouts.current.forEach(clearTimeout);
                assistantResponseTimeouts.current = [];

                const newMessages = formattedHistory.filter(
                  (msg) =>
                    !assistantResponse?.some(
                      (existingMsg) => JSON.stringify(existingMsg) === JSON.stringify(msg)
                    )
                );

                const artificialDelay = 2000;
                newMessages.forEach((message, index) => {
                  const timeoutId = setTimeout(() => {
                    setAssistantResponse((prevMessages) => [...(prevMessages ?? []), message]);
                  }, artificialDelay * index);
                  assistantResponseTimeouts.current.push(timeoutId);
                });
              }
            }
          } else {
            if (isInitialLoad) {
              setIsInitialLoad(false);
            }
          }
        } catch (e) {
          console.error('Error parsing JSON:', e);
        }
      };

      websocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      websocket.onclose = (event) => {
        if (!event.wasClean && reconnectionAttempts < maxReconnectionAttempts) {
          setTimeout(() => {
            reconnectionAttempts++;
            connectWebSocket();
          }, Math.min(reconnectionDelay * 2 ** reconnectionAttempts, maxReconnectionDelay));
        }
      };
    };

    connectWebSocket();

    return () => {
      websocket.close();
    };
  }, []);

  useEffect(() => {
    return () => {
      assistantResponseTimeouts.current.forEach(clearTimeout);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, assistantResponseIsLoading]);

  const sendRequest = async (requestMessage) => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }

    setTimeout(() => {
      setAssistantResponseIsLoading(true);
    }, 1000);

    dispatch(hideAllModals());
    dispatch(setIsInsertingNewColumn(true));

    try {
      const res = await postRaw(`${URL_AI}/${handle}/chat`, { Query: requestMessage });

      if (res?.ResponseType !== 1) {
        const currentFileResponse = await get(`${URL_DATASET}/${handle}`, true);
        const currentFile = await currentFileResponse.json();
        dispatch(fetchOpenedFile(handle));
        dispatch(restoreGridState(currentFile?.ClientState));
      } else {
        setAssistantResponse(null);
      }
    } catch (e) {
      setAssistantResponse(null);

      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));

      setTimeout(() => {
        setAssistantResponseIsLoading(false);
      }, 5000);
    }
  };

  const applyExample = (example) => {
    if (example?.length > 0) {
      sendRequest(example);

      dispatch(
        addMixpanelEvent({
          eventName: 'Apply Sheet Assistant Example',
          eventProps: {
            examplePrompt: example,
          },
        })
      );
    }
  };

  useEffect(() => {
    const userHasWriteAccess = currentFile?.availablePermissions?.includes(PERMISSION_WRITE);
    const sheetOwnedByUser = currentFile?.metadata?.Owner === user.email;
    setDisableSheetAssistant(!sheetOwnedByUser && !userHasWriteAccess);
  }, [currentFile, user.email]);

  const renderStep = (step, index) => {
    const clickableClassName = 'hover:cursor-pointer text-ocean-blue-600 hover:underline';
    let onClick = null;
    let className = '';

    switch (step.Action) {
      case ACTION_GROUP:
        className = clickableClassName;
        onClick = function () {
          dispatch(showModal({ name: MODAL_GROUP_BY }));
        };
        break;
      case ACTION_FILTER:
        className = clickableClassName;
        onClick = function () {
          dispatch(showModal({ name: MODAL_FILTER_PANEL }));
        };
        break;
      case ACTION_SORT:
        className = clickableClassName;
        onClick = function () {
          dispatch(showModal({ name: MODAL_SORT_COLUMN }));
        };
        break;
    }
    return (
      // eslint-disable-next-line
      <li key={index} className={className} onClick={onClick}>
        {step?.Description}
      </li>
    );
  };

  const renderMessage = (message, index, className) => {
    const isLatestMessage = index === assistantResponse.length - 1;

    const animationClass = isLatestMessage
      ? message?.Role === 'user'
        ? 'slide-in-from-right'
        : 'slide-in-from-left'
      : '';

    if (!messageRefs.current[index]) {
      messageRefs.current[index] = createRef();
    }

    if (message.MessageType === 'assistant-action') {
      try {
        const messageContent = JSON.parse(message.Content);
        if (Array.isArray(messageContent)) {
          return (
            <SheetAssistantChatMessage
              key={index}
              messageContent={
                <ul className='mt-1 list-none'>
                  {messageContent.map((step, index) => {
                    return (
                      <>
                        {renderStep(step, index)}

                        {index !== messageContent.length - 1 && <hr className='my-1' />}
                      </>
                    );
                  })}
                </ul>
              }
              messageRole='assistant'
              ref={messageRefs.current[index]}
              className={className}
              animationClass={animationClass}
              shouldSanitize={false}
              isMobileExperienceActive={isMobileExperienceActive && isMobileScreen}
            />
          );
        }
      } catch {
        // throw new Error('Invalid JSON');
      }
    } else {
      return (
        <SheetAssistantChatMessage
          key={index}
          messageContent={message?.Content}
          messageRole={message?.Role}
          ref={messageRefs.current[index]}
          className={className}
          animationClass={animationClass}
          isMobileExperienceActive={isMobileExperienceActive && isMobileScreen}
        />
      );
    }
  };

  const renderFeedbackFooter = () => {
    return (
      <div className='flex items-start w-full h-4 align-top'>
        <div className='h-4 w-full tracking-wide flex flex-row justify-center text-[10px] text-ui-helper align-middle items-center'>
          BETA Share your
          <Button
            onClick={() => {
              if (window.fcWidget) window.fcWidget.open();
            }}
            className='!text-ui-helper !tracking-wide !pl-[3px] !pr-1 text-[10px] underline active:!bg-transparent hover:!bg-transparent'
            variant='ghost'
            color='shadow'
          >
            feedback
          </Button>{' '}
          or connect with{' '}
          <Button
            onClick={() => {
              if (window.fcWidget) window.fcWidget.open();
            }}
            className='!text-ui-helper !tracking-wide !pl-[3px] !pr-1 text-[10px] underline active:!bg-transparent hover:!bg-transparent'
            variant='ghost'
            color='shadow'
          >
            support
          </Button>{' '}
          <Icon name='hand-heart' size={12} color='#463AD4' weight='duotone' />
        </div>
      </div>
    );
  };

  const LinkButton = ({ onClick, children }) => (
    <button className='text-blue-500 underline' onClick={onClick}>
      {children}
    </button>
  );

  const openModal = (modalName, props) => {
    dispatch(showModal({ name: modalName, props: props }));
  };

  const getViewOnlySheetAssistantChatMessage = () => {
    return (
      <div
        data-cy='view-only-sheet-assistant-chat-message'
        className='flex flex-col h-full overflow-y-auto select-text'
      >
        <SheetAssistantChatMessage
          key={0}
          messageContent={
            <ul className='mt-1 list-none'>
              Sheet Assistant is only available with Edit access. To use Sheet Assistant, ask the
              owner of this file for Edit access, get your own complete copy with{' '}
              <LinkButton
                onClick={() =>
                  openModal(MODAL_MAKE_COPY_FILE, {
                    getGridRequestData: agGrid?.api?.rowModel?.datasource?.state,
                    isCanExportFilteredGroupedRolePaywall,
                    isSaveResultsView: false,
                  })
                }
              >
                Make a Copy
              </LinkButton>{' '}
              in the File menu, or copy a subset of the data by{' '}
              <LinkButton onClick={() => openModal(MODAL_FILTER_PANEL)}>Filtering</LinkButton> and
              then using{' '}
              <LinkButton
                onClick={() =>
                  openModal(MODAL_MAKE_COPY_FILE, {
                    getGridRequestData: agGrid?.api?.rowModel?.datasource?.state,
                    isCanExportFilteredGroupedRolePaywall,
                    isSaveResultsView: true,
                  })
                }
              >
                Save Results
              </LinkButton>{' '}
              in the File menu.
            </ul>
          }
          messageRole='assistant'
          className='mb-2'
          shouldSanitize={false}
          isMobileExperienceActive={isMobileExperienceActive && isMobileScreen}
        />
      </div>
    );
  };

  return (
    <div className='flex flex-col h-full bg-gray-50'>
      <div className='flex flex-col flex-1 overflow-x-auto'>
        <div
          className={clsx(
            'flex flex-row gap-1 sticky top-0 w-full h-[41px] p-2 text-[15px] justify-between font-semibold items-center border-t border-b border-ui-200 bg-white ',
            isMobileScreen && isMobileExperienceActive && 'hidden'
          )}
        >
          Sheet Assistant
          <span className='flex items-center'>
            <SheetAssistantSettings
              isAnonymous={user?.is_anonymous}
              handle={handle}
              resetAssistantResponse={resetAssistantResponse}
            />

            <Link to={LINK_SHEET_ASSISTANT_SUPPORT} target='_blank' size='medium' className='ml-1'>
              <Icon color='#707070' name='question' size={16} />
            </Link>
          </span>
        </div>

        <div className='flex-1 mb-6 h-5/6'>
          {viewOnlyMode ? (
            getViewOnlySheetAssistantChatMessage()
          ) : isInitialLoad ? (
            <ThreeDotsLoader text='' className='mt-14' />
          ) : assistantResponse?.length ? (
            <div className='flex flex-col h-full overflow-y-auto select-text' ref={chatRef}>
              {assistantResponse.map((message, index) => {
                return renderMessage(message, index, 'mb-2');
              })}

              {assistantResponseIsLoading && (
                <SheetAssistantChatMessage
                  assistantResponseIsLoading={assistantResponseIsLoading}
                  className='self-start mb-2'
                  animationClass='slide-in-from-left'
                  isMobileExperienceActive={isMobileExperienceActive && isMobileScreen}
                />
              )}
              <div ref={chatEndRef} className='h-1' />
            </div>
          ) : (
            <div className='flex flex-col justify-start h-full gap-8 p-3 md:justify-end'>
              <SheetAssistantTipsSection
                disableSheetAssistant={disableSheetAssistant}
                applyExample={applyExample}
              />
              <div hidden={!disableSheetAssistant}>
                {user?.is_anonymous
                  ? 'Login or create a free account to use the AI Sheet Assistant.'
                  : 'Sheet Assistant is only available with Edit access. To use Sheet Assistant, ask the owner of this file for Edit access, get your own complete copy with Make a Copy in the File menu, or copy a subset of the data by Filtering and then using Save Results in the File menu.'}
              </div>
            </div>
          )}
        </div>
      </div>

      <div hidden={disableSheetAssistant} className='bottom-0 p-3 border-t flex-0'>
        <SheetAssistantDialogSection
          assistantResponseIsLoading={assistantResponseIsLoading}
          sendRequest={sendRequest}
          botResponse={assistantResponse}
          renderFeedbackFooter={renderFeedbackFooter}
        />
      </div>
    </div>
  );
};

export default SheetAssistant;
