import { get } from './API';
import { PERMISSION_READ, PERMISSION_WRITE, URL_DATASETS, URL_SPREADSHEET } from './constants';
import { convertToBytes } from './convertToBytes';
import getFileSize from './getFileSize';
import { getFileUuidFromPath, getFolderHandleFromPath } from './getFileUuidFromPath';
import { parseFileSize } from './parseFileSize';

export function bytesToReadable(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray, chunkSize) {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunkSize) {
    const chunk = myArray.slice(index, index + chunkSize);
    // Do something if you want with the group
    tempArray.push(chunk);
  }

  return tempArray;
}
const ERR_TEXT_WEB_SERVICE_BASE_URL = 'environment variable REACT_APP_WEB_APP_BASE_HOST is empty';
const ERR_TEXT_API_SERVICE_BASE_URL = 'environment variable ERR_TEXT_API_SERVICE_BASE_URL is empty';
const ERR_TEXT_COMPANION_SERVICE_BASE_URL =
  'environment variable COMPANION_SERVICE_BASE_URL is empty';

// return the proper base url for methods
export function getBaseUrl(component) {
  const WEB_SERVICE_BASE_URL = process.env.REACT_APP_WEB_APP_BASE_HOST;
  const API_SERVICE_BASE_URL = process.env.REACT_APP_API_SERVICE_BASE_HOST;
  const COMPANION_SERVICE_BASE_URL = process.env.REACT_APP_COMPANION_SERVICE_BASE_HOST;

  if (isEmpty(WEB_SERVICE_BASE_URL)) {
    console.error(ERR_TEXT_WEB_SERVICE_BASE_URL);
    throw new Error(ERR_TEXT_WEB_SERVICE_BASE_URL);
  }

  if (isEmpty(API_SERVICE_BASE_URL)) {
    console.error(ERR_TEXT_API_SERVICE_BASE_URL);
    throw new Error(ERR_TEXT_API_SERVICE_BASE_URL);
  }

  if (isEmpty(COMPANION_SERVICE_BASE_URL)) {
    console.error(ERR_TEXT_COMPANION_SERVICE_BASE_URL);
    throw new Error(ERR_TEXT_COMPANION_SERVICE_BASE_URL);
  }

  switch (component) {
    case 'api':
      return API_SERVICE_BASE_URL;
    case 'webapp':
      return WEB_SERVICE_BASE_URL;
    case 'companion':
      return COMPANION_SERVICE_BASE_URL;
    default:
      return API_SERVICE_BASE_URL;
  }
}

function isEmpty(str) {
  return !str || 0 === str.length;
}

export const openInNewTab = (link) => window.open(link, '_blank', 'noopener', 'noreferrer');

export const openSpreadsheet = (uuid, spreadsheetName) => {
  localStorage.setItem('fileName', spreadsheetName);
  localStorage.setItem('uuid', uuid);
  const linkTo = `/${URL_SPREADSHEET}/${formatUrlSafeFileName(spreadsheetName)}/${uuid}`;
  openInNewTab(linkTo);
};

export const getFilesSizeInPercentage = (size, maxStorageSize) => {
  let currentSizeInBytes = size;
  if (isNaN(size)) {
    currentSizeInBytes = convertToBytes(size) || 0;
  }
  const maxSizeInBytes = convertToBytes(maxStorageSize);

  return +Number((currentSizeInBytes / maxSizeInBytes) * 100).toFixed(2);
};

export const getAllFilesSize = (data) => {
  const allSize = data?.reduce((total, item) => total + getIndividualFileSize(item.FileSize), 0);

  if (!allSize || isNaN(allSize)) {
    return 0;
  }

  return parseFileSize(allSize);
};

export const getIndividualFileSize = (fileSize) => {
  if (isNaN(fileSize) && fileSize !== '') {
    return Number(fileSize.split(' ')[0]);
  } else if (fileSize === '') {
    return 0;
  } else {
    return Number(fileSize);
  }
};

export const getUniqueArrayOfObjectsByProperty = (arr, property) => [
  ...new Map(arr.map((item) => [item[property], item])).values(),
];

export const getSelectionRange = (start, end) => {
  if (start < end) return [start, end + 1];
  if (start > end) return [end, start + 1];
  return [start, end];
};

const hasReadOrWriteAccess = (permissions) => {
  return permissions?.includes(PERMISSION_READ) || permissions?.includes(PERMISSION_WRITE);
};

function convertToPermissionArray(permissions) {
  return permissions.map((permissionObject) => parseInt(permissionObject.permission_id, 10));
}

export const getReadOrWriteAccessForAllPermissionTypes = async ({
  fileUUID,
  userFilePermissionsQueryParam = '',
  isAnonymous,
}) => {
  try {
    const [filePermissionsRes, orgPermissionsRes, userFilePermissionsRes] = await Promise.all(
      isAnonymous
        ? [get(`dataset/${fileUUID}/file-permissions`, true)]
        : [
            get(`dataset/${fileUUID}/file-permissions`, true),
            get(`dataset/${fileUUID}/organization-permissions`, true),
            get(`dataset/${fileUUID}/user-file-permissions${userFilePermissionsQueryParam}`, true),
          ]
    );

    const filePermissions = await handleResponse(filePermissionsRes);
    const orgPermissions = !isAnonymous && (await handleResponse(orgPermissionsRes));
    const userFilePermissions = !isAnonymous && (await handleResponse(userFilePermissionsRes));

    const fileAccess = filePermissions ? hasReadOrWriteAccess(filePermissions.permissions) : false;
    const orgAccess = orgPermissions ? hasReadOrWriteAccess(orgPermissions.permissions) : false;

    // userFilePermissionsJson is returning..
    // {user_id: 'abc@gmail.com', file_id: 'abc123', permission_id: '0'}
    // {user_id: 'abc@gmail.com', file_id: 'abc123', permission_id: '1'}
    // Data needs manipulating to confirm to other two above.
    const convertedPermissions = userFilePermissions
      ? convertToPermissionArray(userFilePermissions)
      : [];
    const userAccess = convertedPermissions.length
      ? hasReadOrWriteAccess(convertedPermissions)
      : false;

    return [fileAccess, orgAccess, userAccess];
  } catch (error) {
    console.error('Error fetching permissions:', error);
    return [false, false, false];
  }
};

const handleResponse = async (response) => {
  if (!response.ok) {
    return false;
  }
  return await response.json();
};

export const getIsResourcePublic = (isAnonymous = false) => {
  let fileUUID = '';
  if (location.pathname.includes(`/${URL_DATASETS}`)) {
    fileUUID = getFolderHandleFromPath();
  } else {
    fileUUID = getFileUuidFromPath();
  }
  if (fileUUID?.length > 0) {
    return getReadOrWriteAccessForAllPermissionTypes({ fileUUID, isAnonymous }).then((values) => {
      const [hasFileReadOrWriteAccess, hasOrgReadOrWriteAccess, hasUserFileReadOrWriteAccess] =
        values;
      return hasFileReadOrWriteAccess && !hasOrgReadOrWriteAccess && !hasUserFileReadOrWriteAccess;
    });
  } else {
    return Promise.resolve(false);
  }
};

export const checkFileExceedsQuota = (filesSize, file, user) => {
  const maxStorageSize = user?.userProperties.maxStorageSize;
  return (
    convertToBytes(filesSize) + getFileSize(file?.metadata?.FileSize || '') >
    convertToBytes(maxStorageSize)
  );
};

export const formatUrlSafeFileName = (fileName = '') => {
  return fileName.replace(/\W/g, '-');
};

export const getNumberValueSeparatedByCommas = (value) => {
  if (!value) return 0;
  if (isNaN(Number(value))) return value;
  return Number(value).toLocaleString('en-US');
};

export const postToLogoutChannel = () => {
  const broadcastChannel = new BroadcastChannel('logoutChannel');
  broadcastChannel.postMessage({ type: 'logout' });
};

export const getWebsocketHostname = () => {
  const baseHost = process.env.REACT_APP_API_SERVICE_BASE_HOST;
  return baseHost.replace('http', 'ws');
};

export const handleUnauthorizedResponseAndReload = (response, id) => {
  if (response?.status === 401) {
    const reloadCount = localStorage.getItem('`${id}-reloadCount`');
    if (reloadCount < 3) {
      localStorage.setItem(`${id}-reloadCount`, reloadCount + 1);
      window.location.reload();
    }
  } else {
    localStorage.removeItem('`${id}-reloadCount`');
  }
};

const generateAuthStateNonce = () => {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(16);
};
export const getAuthNonceAndSetInStorage = () => {
  const authStateNonce = generateAuthStateNonce();
  localStorage.setItem('authStateNonce', authStateNonce);
  return authStateNonce;
};

export const camelCaseToNormal = (camelCase) => {
  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .trim();
};
