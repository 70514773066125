import {
  dynamicUserRoles,
  initialUserProperties,
  userPriorities,
  userPropertiesMap,
} from './userProperties';

export const sortUserProperties = (userRoles) => {
  return userRoles
    .map((userRole = '') => {
      const dRole = dynamicUserRoles.find((dRole) => userRole.includes(dRole.staticPart));
      if (dRole) {
        return { role: dRole.staticPart, value: userRole.split(dRole.staticPart)[1] };
      }
      return { role: userRole, value: '' };
    })
    .sort((a, b) => {
      if (userPriorities.includes(a.role)) {
        return -1;
      }
      if (userPriorities.includes(b.role)) {
        return 1;
      }
      return a - b;
    })
    .sort((a, b) => {
      if (a.role === b.role) {
        return parseInt(a.value) - parseInt(b.value);
      }
    });
};

export const mapUserRoles = (userRoles) => {
  const copyOfUserRoles = userRoles.reduce((acc, userRole) => {
    if (typeof userPropertiesMap[userRole.role] === 'function') {
      userPropertiesMap[userRole.role](acc, userRole);
    }
    return acc;
  }, initialUserProperties);

  const fullUserRoles = { ...copyOfUserRoles };

  if (!userRoles.find((e) => e.role === 'is-paying')) {
    fullUserRoles.isPaying = false;
  }
  return fullUserRoles;
};
