import Icon from '@/Components/UI/Icon/Icon';
import { StackedList } from '@/Components/UI/StackedList/StackedList';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { useEffect, useState } from 'react';

const defaultExamples = [
  'Show me rows where Company Name contains LLC, Corp, or Inc',
  'What has the highest cost?',
  'Which product is most popular?',
  'What city has the highest income per capita?',
];

const SheetAssistantTipsSection = ({ disableSheetAssistant, applyExample }) => {
  const [examples, setExamples] = useState(defaultExamples);
  const currentFile = useCurrentFile();

  useEffect(() => {
    const exampleAnalyses = currentFile?.metadata?.ExampleAnalyses;
    if (exampleAnalyses && Array.isArray(exampleAnalyses) && exampleAnalyses.length > 0) {
      setExamples(exampleAnalyses);
    }
  }, [currentFile]);

  const isDefaultExample = (example) => !!defaultExamples.filter((i) => i === example).length;
  const handleApplyExample = (example) => {
    if (!isDefaultExample(example)) {
      applyExample(example);
    } else {
      return;
    }
  };
  const optionsFormat = examples.map((example) => {
    return {
      value: example,
      label: example,
      disabled: isDefaultExample(example),
      onClick: () => handleApplyExample(example),
    };
  });

  return (
    <div hidden={disableSheetAssistant} className='select-text text-ui-helper w-fit'>
      <div className='flex flex-row items-center gap-1 pb-1 text-sm italic font-semibold'>
        <Icon name='books' size={14} />
        Examples
      </div>
      <div className='w-full'>
        <StackedList options={optionsFormat} />
      </div>
    </div>
  );
};

export default SheetAssistantTipsSection;
