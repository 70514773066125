import { Button, Link } from '@/Components/UI/Button/Button';
import Spinner from '@/Components/UI/Spinner/Spinner';
import { get } from '@/Utils/API';
import { userRolesKey } from '@/Utils/constants';
import {
  enrichmentTemplatesSliceName,
  spreadsheetSliceName,
  userDataSliceName,
} from '@/redux/constants';
import { setUniqueColumns } from '@/redux/reducers/enrichmentTemplates';
import { setEnrichmentCredits } from '@/redux/reducers/spreadsheetSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DEFAULT_ENRICH_CREDITS = { Limit: 0, Used: 0 };
const commonDescription = () => {
  return (
    <p className='pr-8 mb-4 ml-8 -mt-6 text-base font-semibold text-ui-secondary'>
      Gigasheet will make multiple API requests, using the values from each row in your sheet; up to
      your credit limit.{' '}
      <Link
        to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
        target='_blank'
        className='inline-block'
        size='medium'
      >
        Contact support
      </Link>{' '}
      for full access.
    </p>
  );
};

export const AvailableCredits = ({ onCancel, onRun, onBack }) => {
  const dispatch = useDispatch();
  const { uniqueColumns } = useSelector((state) => state[enrichmentTemplatesSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { filteredNumberOfRows, enrichmentCredits } = useSelector(
    (store) => store[spreadsheetSliceName]
  );
  const [isLoadingEnrichCredits, setIsLoadingEnrichCredits] = useState(false);
  const isRunDisabled = enrichmentCredits.Used + uniqueColumns >= enrichmentCredits.Limit;

  const fetchEnrichmentCredits = async () => {
    setIsLoadingEnrichCredits(true);
    try {
      const response = await get('user/enrichment-credits');
      if (!response?.Limit) {
        dispatch(setEnrichmentCredits(DEFAULT_ENRICH_CREDITS));
      }
      dispatch(setEnrichmentCredits(response));
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoadingEnrichCredits(false);
    }
  };

  const fetchUniqueColumnValues = async () => {
    try {
      const uniqueValues = filteredNumberOfRows || 0;
      dispatch(
        setUniqueColumns(
          user[userRolesKey].includes('giga-feature-customenrichfull')
            ? uniqueValues
            : uniqueValues > 1000
            ? 1000
            : uniqueValues
        )
      );
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    fetchEnrichmentCredits(), fetchUniqueColumnValues();
  }, []);

  return (
    <>
      {commonDescription()}
      <div className='text-left flex items-start justify-center min-h-[14rem] flex-col gap-y-2'>
        {isLoadingEnrichCredits ? (
          <div className='flex items-center justify-center w-full'>
            <Spinner size='medium' color='shadow' className={'inline-block'} />
          </div>
        ) : (
          <>
            {enrichmentCredits.Used + uniqueColumns <= enrichmentCredits.Limit ? (
              <>
                <p className='text-base font-semibold text-midnight'>Are you sure?</p>
                <p className='mb-4 text-sm font-normal text-ui'>
                  {`${uniqueColumns} requests will be run. You have used ${enrichmentCredits.Used} of ${enrichmentCredits.Limit} enrichment credits. Depending on the third-party service used this could incur
      additional charges.`}
                </p>
                <p className='text-sm font-semibold text-ui'>
                  Are you sure you&apos;d like to proceed?
                </p>
              </>
            ) : (
              <p className='w-full mb-4 text-sm font-normal text-center text-ui'>
                <p className='p-1 text-base font-semibold text-midnight'>
                  Unable to run enrichment.
                </p>
                {`This request exceeds remaining ${Math.max(
                  0,
                  enrichmentCredits.Limit - enrichmentCredits.Used
                )} of ${enrichmentCredits.Limit} enrichment credits. `}
                <Link
                  to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
                  target='_blank'
                  className='inline-block'
                  size='medium'
                >
                  Contact support
                </Link>{' '}
                for more information.
              </p>
            )}
          </>
        )}
      </div>

      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button color='shadow' dataCy='reset-field-btn' className='float-left' onClick={onBack}>
          Back
        </Button>

        <Button className='mr-[16px]' variant='ghost' color='shadow' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={isLoadingEnrichCredits || isRunDisabled}
          variant='solid'
          dataCy='run-btn'
          color='sunrise'
          onClick={onRun}
        >
          Run
        </Button>
      </div>
    </>
  );
};
