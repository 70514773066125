import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Mixpanel } from '@/Mixpanel';
import { combineFilesSliceName, datasetSliceName, userDataSliceName } from '@/redux/constants';
import { resetCombineFolderStructure, updateFilePath } from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';

import CombineFile from './CombineFile';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import Spinner from '@/Components/UI/Spinner/Spinner';
import ConflictingFilesComponent from './ConflictingFilesComponent';

import { post } from '@/Utils/API';
import {
  COMBINE,
  COMBINE_FILES_USING_COLUMN_HEADERS,
  FILES,
  MODAL_COMBINE_FILES,
  MODAL_EXCEED_STORAGE_SIZE_LIMIT,
  MODAL_PAPERCUT,
  TOAST_TEXT_COMBINING_FILES_FAILED,
  TOAST_TYPE_ERROR,
  VALIDATE,
} from '@/Utils/constants';
import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';
import { useOverMaxCombineFiles } from '@/hooks/useOverMaxCombineFiles';
import getCurrentDateTimeWithTimezone from '@/Utils/getCurrentDateTimeWithTimezone';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import './style/index.scss';
import { convertToBytes } from '@/Utils/convertToBytes';
import {
  setAddUnmatchedColumns,
  setCaseSensitive,
  setNewSheetName,
  setTrimColumnNames,
} from '@/redux/reducers/combineFilesSlice';

const CombineFileModal = ({ show, hideModal }) => {
  const dispatch = useDispatch();

  const { selectedFiles, combineFolderStructure, filesSize } = useSelector(
    (state) => state[datasetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { newSheetName, combineMethod, addUnmatchedColumns, trimColumnNames, caseSensitive } =
    useSelector((state) => state[combineFilesSliceName]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCombineComplete, setIsCombineComplete] = useState(false);
  const [conflictingFiles, setConflictingFiles] = useState(null);

  const isDisabledSaveBtn = isLoading || selectedFiles.length < 2 || !newSheetName.trim();
  const { isOverMaxCombineFiles, maxCombineFiles } = useOverMaxCombineFiles();

  const resetInnerState = () => {
    dispatch(setNewSheetName(`Combined file ${getCurrentDateTimeWithTimezone()}`));
    dispatch(setAddUnmatchedColumns(true));
    dispatch(setTrimColumnNames(true));
    dispatch(setCaseSensitive(false));
    setConflictingFiles(null);
    setIsCombineComplete(false);
    dispatch(resetCombineFolderStructure());
  };

  const getFilesToCombine = () => {
    return selectedFiles
      .filter(
        ({ IsDirectory, Status, Type }) =>
          !IsDirectory && Status !== FILE_STATUS_ERROR && Type !== FILE_TYPE_EXPORTER
      )
      .map(({ FileUuid }) => FileUuid);
  };

  const combineFiles = async (method) => {
    setIsLoading(true);

    let combineData = {
      FolderHandle: selectedFiles[0].ParentDirectory,
      fileName: newSheetName,
      handles: getFilesToCombine(),
    };
    let combineFilesEndpoint = `${FILES}/${COMBINE}`;
    let combineFilesValidationEndpoint = `${FILES}/${COMBINE}/${VALIDATE}`;

    if (method === COMBINE_FILES_USING_COLUMN_HEADERS) {
      combineData = {
        handles: getFilesToCombine(),
        new_sheet_name: newSheetName,
        add_unmatched_columns: addUnmatchedColumns,
        case_insensitive: !caseSensitive,
        trim_column_names: trimColumnNames,
      };
      combineFilesEndpoint = `${FILES}/${COMBINE}/by-name`;
      combineFilesValidationEndpoint = `${FILES}/${COMBINE}/by-name/${VALIDATE}`;
    }

    try {
      const validation = await post(combineFilesValidationEndpoint, combineData);

      if (validation.Success && !Object.keys(validation.FileErrors).length) {
        setTimeout(() => {
          hideModal();
        }, 500);
        setIsCombineComplete(true);
        Mixpanel.track('Combine Files Completed');

        dispatch(updateFilePath({ showLoader: false }));

        const response = await post(combineFilesEndpoint, combineData);
        if (!response.Success) {
          const { Message: errorMsg1, message: errorMsg2 } = response;
          const Message = errorMsg1 || errorMsg2;
          const message = () => {
            if (Message.includes('Not a valid')) {
              return Message.replace(' ID', '');
            } else if (Message.includes('ID')) {
              return `${Message.slice(0, Message.lastIndexOf(' ID'))}: '${
                combineFolderStructure.find(
                  (i) =>
                    i.FileUuid === Message.slice(Message.indexOf('[') + 1, Message.indexOf(']'))
                ).FileName || 'FileName'
              }'`;
            } else if (Message.includes('Internal Server Error')) {
              return TOAST_TEXT_COMBINING_FILES_FAILED;
            } else {
              return Message;
            }
          };
          showToast({
            type: TOAST_TYPE_ERROR,
            text: message(),
            errorContext: response,
            endpoint: combineFilesEndpoint,
            payload: combineData,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      }

      if (Object.keys(validation.FileErrors).length) {
        setConflictingFiles(validation);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const title = (
    <span className='flex flex-col' data-cy='combined-file-title'>
      Combine Files
      <span className='!text-[13px] leading-4 !text-ui-secondary'>
        Combining files requires the same columns, column order, and column data types between the
        selected files.
      </span>
    </span>
  );

  const onApplyOrCombineButtonClick = () => {
    const { maxStorageSize } = user?.userProperties || {};
    const isStorageOverLimit = convertToBytes(filesSize) >= convertToBytes(maxStorageSize);

    return isOverMaxCombineFiles
      ? () => {
          dispatch(showModal({ name: MODAL_PAPERCUT }));
          dispatch(
            addMixpanelEvent({
              eventName: 'Combine files paywall',
              eventProps: {
                FunctionName: 'Combine files',
                maxCombineFiles,
                filesToCombine: selectedFiles?.length,
              },
              userIncrementName: '# of clicks',
            })
          );
        }
      : isStorageOverLimit
      ? () => {
          hideModal();
          dispatch(showModal({ name: MODAL_EXCEED_STORAGE_SIZE_LIMIT }));
          dispatch(
            addMixpanelEvent({
              eventName: 'Combine Files (exceed storage size limit)',
              eventProps: { maxStorageSize: user?.userProperties?.maxStorageSize, filesSize },
              userIncrementName: '# modal displayed',
            })
          );
        }
      : () => combineFiles(combineMethod);
  };

  const modalButtons = !isLoading && (
    <div className='flex items-center justify-between py-4 mt-2 border-t border-ui-200'>
      <div>
        {conflictingFiles && (
          <Button
            variant='solid'
            color='shadow'
            dataCy='cancel-btn'
            onClick={() => setConflictingFiles(null)}
            className='mr-1'
            disabled={isLoading}
          >
            Back
          </Button>
        )}
      </div>
      <div>
        <Button
          variant='ghost'
          color='shadow'
          dataCy='cancel-btn'
          onClick={hideModal}
          className='mr-1'
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color={conflictingFiles ? 'oceanBlue' : 'sunrise'}
          dataCy='save-btn'
          onClick={onApplyOrCombineButtonClick()}
          disabled={isDisabledSaveBtn || conflictingFiles || isCombineComplete}
        >
          {conflictingFiles ? 'Apply' : 'Combine'}
        </Button>
      </div>
    </div>
  );

  const modalSpinner = isLoading && (
    <div className='flex flex-col items-center justify-center h-64'>
      <Spinner size='medium' />
      <span className='text-lg text-midnight-700'>Building New File...</span>
    </div>
  );

  useEffect(() => {
    if (show) {
      resetInnerState();
    }
  }, [show]);

  return (
    <>
      <Modal
        isOpen={show}
        title={title}
        size={'x-large'}
        onClose={hideModal}
        articleID={69000668567}
        modalClassName='flex flex-col h-[70vh]'
        childrenCss='flex-grow'
      >
        <div className='h-full'>
          {modalSpinner}
          {conflictingFiles && <ConflictingFilesComponent conflictingFiles={conflictingFiles} />}
          <div className='flex flex-col h-full'>
            {!isLoading && !conflictingFiles && <CombineFile />}
            {modalButtons}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withModal({ name: MODAL_COMBINE_FILES })(CombineFileModal);
