import { GENERATE_HANDLE } from '@/Utils/constants';
import BasePlugin from '@uppy/core/lib/BasePlugin.js';
import { get } from '@/Utils/API';

export default class GigasheetUppyIngestion extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = 'GigasheetIngestor';
    this.type = 'modifier';

    this.defaultLocale = {
      strings: {
        ingestingFile: 'Ingesting file...',
      },
    };

    this.prepareUpload = this.prepareUpload.bind(this);
  }

  prepareUpload = (fileIDs) => {
    const promises = fileIDs.map((id) => {
      const file = this.uppy.getFile(id);
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: 'preparing file',
      });

      if (file.isRemote) {
        return get(GENERATE_HANDLE).then((res) => this.uppy.setFileMeta(id, { name: res.Message }));
      }
    });

    const emitPreprocessComplete = () => {
      fileIDs.forEach((id) => {
        const file = this.uppy.getFile(id);
        this.uppy.emit('preprocess-complete', file);
      });
    };

    return Promise.all(promises).then(emitPreprocessComplete);
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}
