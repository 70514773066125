import { Mixpanel } from '@/Mixpanel';
const addClickbyPluginToMixpanel = (plugin) => {
  switch (plugin) {
    case 'OneDrive':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'OneDrive',
      });
      break;
    case 'Google Drive':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'Google Drive',
      });
      break;
    case 'Dropbox':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'Dropbox',
      });
      break;
    case 'Box':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'Box',
      });
      break;
    case 'Link':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'Link',
      });
      break;
    case 'SFTP':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'SFTP',
      });
      break;
    case 'S3':
      Mixpanel.track('clicked by plugin', {
        'plugin name': 'S3',
      });
      break;
    default:
      return;
  }
};
export default addClickbyPluginToMixpanel;
