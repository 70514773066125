import withModal from '@/Components/Modals/withModalHOC';

import { Button, Link } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_MAKE_COPY_SUCCESS, URL_SPREADSHEET } from '@/Utils/constants';
import { getBaseUrl } from '@/Utils/utils';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const url = getBaseUrl('webapp');

const SuccessCopyPopup = ({ show, hideModal, uuid }) => {
  const currentFile = useCurrentFile();
  const { FileName: filename } = currentFile?.metadata || {};

  const handleOpenCopy = () => {
    hideModal();

    window.open(`${url}/${URL_SPREADSHEET}/${filename}/${uuid}`);
  };

  return (
    <Modal size='xs' isOpen={show} onClose={hideModal}>
      <div className='flex flex-col mt-[-40px] mb-2 gap-4 items-center text-center'>
        <div className='flex flex-col items-center'>
          <Icon name='seal-check' size={60} color='#1A145C' />
          <div className='typography-h1 !text-ocean-blue-900'>Success</div>
        </div>

        <div>
          A copy has been saved to{' '}
          <Link
            color=''
            size='large'
            className='font-semibold'
            to={`${url}/${URL_SPREADSHEET}/${filename}/${uuid}`}
          >
            Your files
          </Link>
          .
        </div>

        <Button onClick={handleOpenCopy} color='pear' size='large' className='w-36'>
          Open it now?
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_MAKE_COPY_SUCCESS })(SuccessCopyPopup);
