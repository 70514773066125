import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { Collapsible, CollapsibleContent } from '@/Components/UI/Shadcn/Collapsible';
import PasswordInput from '@/Components/UI/Form/PasswordInput/PasswordInput';

const DefaultEnrichments = ({ onSelectEnrichment, enrichments, savedApiKeys, onInputApiKey }) => {
  return (
    <>
      <Collapsible open={enrichments.some((item) => item.provider === 'recordedfuture')}>
        <CheckboxInput
          label='Recorded Future'
          name='recordedfuture'
          onChange={onSelectEnrichment}
        />
        <CollapsibleContent>
          <PasswordInput
            name='recordedfuture'
            label='API Key'
            placeholder='Recorded Future API Key'
            value={savedApiKeys['recordedfuture']}
            onChange={onInputApiKey}
            className='w-full mb-4'
            labelClassName='!mb-0 mt-1'
          />
        </CollapsibleContent>
      </Collapsible>

      <Collapsible open={enrichments.some((item) => item.provider === 'virustotal')}>
        <CheckboxInput name='virustotal' label='VirusTotal' onChange={onSelectEnrichment} />
        <CollapsibleContent>
          <PasswordInput
            name='virustotal'
            label='API Key'
            placeholder='VirusTotal API Key'
            value={savedApiKeys['virustotal']}
            onChange={onInputApiKey}
            className='w-full mb-4'
            labelClassName='!mb-0 mt-1'
          />
        </CollapsibleContent>
      </Collapsible>
    </>
  );
};

export default DefaultEnrichments;
