import { useEffect, useMemo, useState } from 'react';

// TODO: extract types to their corresponding folders

type ColumnDefType = {
  field: string;
  headerName: string;
  fieldType: string;
};

type OptionType = {
  value: ColumnDefType['field'];
  type?: ColumnDefType['fieldType'];
  label: ColumnDefType['headerName'];
} | null;

type FunctionModalPropsType = {
  targetColumnId: string;
  columnDefs: ColumnDefType[];
  predicate: (value: OptionType) => boolean;
};

type FunctionModalReturnType = {
  options: any[];
  targetColumn: null | OptionType;
  setTargetColumn: (value: OptionType) => void;
};

export const useFunctionModal = ({
  targetColumnId,
  columnDefs,
  predicate = () => true,
}: FunctionModalPropsType): FunctionModalReturnType => {
  const [targetColumn, setTargetColumn] = useState<OptionType>(null);

  const options = useMemo(() => {
    return columnDefs
      .map((colDef) => ({ value: colDef.field, label: colDef.headerName, type: colDef.fieldType }))
      .filter(predicate);
  }, [columnDefs]);

  useEffect(() => {
    const _targetColumn = options.find((option) => option.value === targetColumnId) || null;
    setTargetColumn(_targetColumn);
  }, [targetColumnId, options]);

  return { options, targetColumn, setTargetColumn };
};
