import Modal from '@/Components/UI/Modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import withModal from '../withModalHOC';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import { Button } from '@/Components/UI/Button/Button';
import {
  MODAL_ADD_VIEW,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { get, post } from '@/Utils/API';
import { openSheetViewPopover } from '@/helpers/spreadsheetHelper';

type AddView = {
  hideModal: () => void;
  show: boolean;
};

const AddView = ({ hideModal, show }: AddView) => {
  const [newSheetViewName, setNewSheetViewName] = useState('');
  const [fetching, setFetching] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const getClientStateVersion = async () => {
    const response = await get(`client-state/${getFileUuidFromPath()}/current-version`);
    if (response?.version) {
      return response.version;
    } else {
      throw new Error();
    }
  };

  const addSheetView = async () => {
    setFetching(true);

    let endpoint = '';
    let payload = {};
    try {
      const clientStateId = await getClientStateVersion();

      payload = {
        client_state_id: clientStateId,
        name: newSheetViewName || 'Sheet View',
      };

      endpoint = `${URL_DATASET}/${getFileUuidFromPath()}/views`;
      const response = await post(endpoint, payload);

      if (response.hasOwnProperty('Success') && !response.Success) {
        const errorWithMessage = new Error();
        errorWithMessage.message = response?.Message || '';
        throw errorWithMessage;
      }
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: (error as Error)?.message || TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      hideModal();
      setFetching(false);
      openSheetViewPopover();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && newSheetViewName && !fetching) {
      addSheetView();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      size='x-small'
      titleSize='small'
      title='Add View'
      withoutCloseButton={true}
      dataCy='add-view-modal'
    >
      <div className=''>
        <TextInput
          value={newSheetViewName}
          label='Name'
          onChange={(e) => setNewSheetViewName(e.target.value)}
          className='!w-full'
          ref={inputRef}
          placeholder='Sheet View 1'
          dataCy='add-view-modal-input'
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className='flex justify-end gap-4 mt-6'>
        <Button
          color='shadow'
          variant='ghost'
          onClick={hideModal}
          dataCy='add-view-modal-cancel-button'
        >
          Cancel
        </Button>
        <Button
          dataCy='add-view-modal-create-button'
          color='oceanBlue'
          onClick={addSheetView}
          disabled={!newSheetViewName || fetching}
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_ADD_VIEW })(AddView);
