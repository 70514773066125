import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { showModal } from '@/redux/reducers/modalsSlice';

import { get, put } from '@/Utils/API';
import {
  ALL_WORKSPACES,
  MODAL_CREATE_FOLDER,
  MY_LIBRARY,
  SHARED_WITH_ME,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  userTeamNameKey,
} from '@/Utils/constants';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import showToast from '@/Components/Toast/showToastTemplate';
import {
  fetchOpenedFile,
  setCurrentDirectory,
  setLibraryFiles,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';

import {
  filePathEndpoint,
  libraryEndpoint,
  moveToDirEndpoint,
  sharedWithMeEndpoint,
  sharedWithMyTeamEndpoint,
} from '@/Utils/apiEndpoints';
import { getSortedElementByLastUpdated } from '@/Utils/getSortedElement';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import { userDataSliceName } from '@/redux/constants';
import MoveFileRow from './MoveFileRow';
import MoveFilePath from './MoveFilePath';
import { getDefaultAllWorkspacesFolderContent } from './helpers';
import { useWebSocketContext } from '../WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '../WebSocketLibrary/WebSocketContainer';

const MoveFile = ({
  hideModal = () => {},
  defaultFolder: { folderName, folderId },
  isMovingFromSpreadsheet = false,
  isMovingSelected = false,
}) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const [selectedInitialFileWorkspace, setSelectedInitialFileWorkspace] = useState('');
  const [selectedFileWorkspace, setSelectedFileWorkspace] = useState('');
  const [selected, setSelected] = useState(folderName || selectedFileWorkspace);
  const [loading, setLoading] = useState(true);
  const [folderContentCount, setFolderContentCount] = useState(0);
  const [folderContent, setFolderContent] = useState([]);
  const [folderContentDirsSorted, setFolderContentDirsSorted] = useState([]);
  const [moveIsForbidden, setMoveIsForbidden] = useState(true);
  const [selectedFileDirectory, setSelectedFileDirectory] = useState(folderId);
  const [softSelectedFileDirectory, setSoftSelectedFileDirectory] = useState(folderId);
  const [selectedFileDirectoryMetadata, setSelectedFileDirectoryMetadata] = useState(null);
  const [folderPath, setFolderPath] = useState([]);

  const { libraryFiles, selectedFiles, sortingData, currentFile, currentDataset, currentDir } =
    useSelector((state) => state.dataset, shallowEqual);
  const { user } = useSelector((state) => state[userDataSliceName], shallowEqual);
  const _selectedFiles = isMovingSelected ? selectedFiles : [currentFile?.metadata];

  const teamLabel = getTeamNameLabel(user[userTeamNameKey]);
  const libraryLabel = MY_LIBRARY;
  const allWorkspacesLabel = ALL_WORKSPACES;
  const sharedWithMeLabel = SHARED_WITH_ME;
  const libraryRootPath = '/gigasheet-library-root-path';
  const teamRootPath = '/gigasheet-shared-with-my-team-root-path';

  const defaultAllWorkspacesFolderContent = [
    { sectionName: libraryLabel, folderIcon: 'folder', hidden: false },
    { sectionName: teamLabel, folderIcon: 'users-three', hidden: !user?.org_id },
    { sectionName: sharedWithMeLabel, folderIcon: 'folder-user', hidden: true },
  ];

  const isAllWorkspace = selectedFileWorkspace === allWorkspacesLabel;

  const getSelectedFileWorkspace = async () => {
    if (currentFile?.shared) {
      (currentFile?.shared.organization.length && setSelectedFileWorkspace(teamLabel)) ||
        setSelectedFileWorkspace(libraryLabel);
      !selectedInitialFileWorkspace && setSelectedInitialFileWorkspace(libraryLabel);
    } else {
      try {
        const fileUUID = selectedFiles[0]?.FileUuid || currentFile?.metadata?.FileUuid;
        const response = await get(`dataset/${fileUUID}/organization-permissions`, true);
        if (response.status === 200) {
          const { permissions } = await response.json();
          const workspaceName = permissions.length ? teamLabel : libraryLabel;
          setSelectedFileWorkspace(workspaceName);
          !selectedInitialFileWorkspace && setSelectedInitialFileWorkspace(workspaceName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  useEffect(() => {
    getSelectedFileWorkspace();
  }, []);

  const isSelected = (FileUuid) => {
    return softSelectedFileDirectory === FileUuid;
  };

  const moveFile = (fileDirectory) => {
    !isMovingFromSpreadsheet &&
      dispatch(
        setLibraryFiles(
          libraryFiles.filter((file) => !_selectedFiles.find((el) => el.FileUuid === file.FileUuid))
        )
      );

    const getDestinationDirectoryHandle = (handle) => {
      if (handle === '' && selectedFileWorkspace === libraryLabel) {
        return libraryRootPath;
      }
      if (handle === '' && selectedFileWorkspace === teamLabel) {
        return teamRootPath;
      }
      return handle;
    };

    _selectedFiles.forEach(async (file) => {
      let endpoint = '';
      let payload = {};
      try {
        const fileUuid = file.FileUuid || file.metadata.FileUuid;
        endpoint = moveToDirEndpoint(fileUuid);
        payload = {
          dest_dir_handle: getDestinationDirectoryHandle(`${fileDirectory}`),
        };
        await put(endpoint, payload);

        setCurrentDirectory(fileDirectory);
        if (isMovingFromSpreadsheet) dispatch(fetchOpenedFile(fileUuid));
        if (!isMovingFromSpreadsheet) {
          sendMessage({
            category: getWebSocketCategory(currentDataset),
            location: currentDir,
          });
        }
      } catch (e) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: `Unable to move ${file.FileName}.`,
          errorContext: e,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
    });

    !isMovingFromSpreadsheet && dispatch(unSelectAllFiles());
    setSelected(libraryLabel);
    hideModal();
  };

  const cancelMove = () => {
    hideModal();
    if (_selectedFiles.map((i) => i.FileUuid).includes(sortingData.currentDirectory)) {
      dispatch(unSelectAllFiles());
    }
  };

  const checkAndSetMovePermission = (fileDirectory) => {
    if (fileDirectory) {
      get(filePathEndpoint(fileDirectory), true).then((response) => {
        response.json().then((filePath) => {
          const folderPath = filePath.filter(({ FileUuid }) => FileUuid !== fileDirectory);
          setFolderPath(folderPath);
          setMoveIsForbidden(isMoveForbidden(fileDirectory, filePath));
        });
      });
    } else {
      setFolderPath([]);
      setMoveIsForbidden(isMoveForbidden(fileDirectory, []));
    }
  };

  useEffect(() => {
    if (selectedFileWorkspace && selectedFileWorkspace !== allWorkspacesLabel) {
      checkAndSetMovePermission(selectedFileDirectory);
      updateFolderContent(true);
    }
  }, [selectedFileDirectory, selectedFileWorkspace]);

  useEffect(() => {
    if (softSelectedFileDirectory && selectedFileWorkspace) {
      checkAndSetMovePermission(softSelectedFileDirectory);
      fetchAndSetFileDirectoryMetadata(softSelectedFileDirectory);
    }
  }, [softSelectedFileDirectory, selectedFileWorkspace]);

  const getProperEndpoint = (selectedFileDirectory) => {
    if (selectedFileWorkspace === teamLabel) return sharedWithMyTeamEndpoint(selectedFileDirectory);
    if (selectedFileWorkspace === sharedWithMeLabel)
      return sharedWithMeEndpoint(selectedFileDirectory);
    return libraryEndpoint(selectedFileDirectory);
  };

  const fetchAndSetFolderContent = (showLoading = false) => {
    if (showLoading) setLoading(true);
    get(getProperEndpoint(selectedFileDirectory)).then((response) => {
      setFolderContentCount(response.length);
      setFolderContent(response);
      if (showLoading) setLoading(false);
    });
  };

  const fetchAndSetFileDirectoryMetadata = (fileDirectory, showLoading = false) => {
    if (showLoading) setLoading(true);
    if (fileDirectory) {
      get(`${URL_DATASET}/${fileDirectory}`).then((response) => {
        if (response?.ParentDirectory?.length > 0) {
          get(`${URL_DATASET}/${response.ParentDirectory}`).then(() => {
            setLoading(false);
          });
        } else {
          setLoading(false);
        }
        setSelectedFileDirectoryMetadata(response);
        setLoading(false);
      });
    }
  };

  const updateFolderContent = (showLoading = false) => {
    fetchAndSetFolderContent(showLoading);
    fetchAndSetFileDirectoryMetadata(selectedFileDirectory, showLoading);
  };

  useEffect(() => {
    const folderContentDirs = folderContent.filter((e) => {
      return e.metadata.IsDirectory;
    });

    const folderContentDirsSorted = folderContentDirs.sort((a, b) => {
      return getSortedElementByLastUpdated(a.metadata, b.metadata);
    });

    setFolderContentDirsSorted(folderContentDirsSorted);
  }, [folderContent]);

  const isMoveForbidden = (fileDirectory, destFilePath) => {
    const moveToSameDir = _selectedFiles.some((file) => {
      const fileUuid = file.FileUuid || file.metadata.FileUuid;
      return (
        (fileUuid === fileDirectory || file.ParentDirectory === fileDirectory) &&
        selectedInitialFileWorkspace === selectedFileWorkspace
      );
    });
    const moveToChildLevel = _selectedFiles.some((file) => {
      const fileUuid = file.FileUuid || file.metadata.FileUuid;
      return (
        file.IsDirectory &&
        destFilePath?.length > 1 &&
        destFilePath?.some((path) => path.FileUuid === fileUuid)
      );
    });

    return moveToSameDir || moveToChildLevel || loading || isAllWorkspace;
  };

  const navigateCurrPath = (fileName, fileUuid) => {
    setSelected(fileName);
    setSelectedFileDirectory(fileUuid);
    setSoftSelectedFileDirectory(null);
  };

  const goToAllWorkspaces = () => {
    const defaultFolderContent = getDefaultAllWorkspacesFolderContent(
      defaultAllWorkspacesFolderContent
    );
    setSelectedFileWorkspace(allWorkspacesLabel);
    navigateCurrPath('', '');
    setFolderContent(defaultFolderContent);
    setFolderContentCount(defaultFolderContent.length);
    setFolderPath([]);
  };

  const onAllWorkspaceRowClick = (workspaceName) => {
    setSelectedFileWorkspace(workspaceName);
  };

  const isAddFolderButtonDisabled =
    (selectedFileWorkspace === sharedWithMeLabel && !selectedFileDirectory) ||
    isAllWorkspace ||
    loading;

  const isAllWorkspaceItemVisible = !!user?.org_id;

  return (
    <>
      <div className='flex items-center justify-between border-b border-ui'>
        <div className='flex text-ellipsis whitespace-nowrap max-w-[80%]'>
          {selectedFileDirectory ? (
            <div className='flex'>
              <MoveFilePath
                filePath={folderPath}
                currentDir={selectedFileDirectory}
                navigateCurrPath={navigateCurrPath}
                selectedFileWorkspace={selectedFileWorkspace}
                selectedInitialFileWorkspace={selectedInitialFileWorkspace}
                goToAllWorkspaces={goToAllWorkspaces}
                disabled={loading}
                isAllWorkspaceItemVisible={isAllWorkspaceItemVisible}
              />
              <span data-cy='move-dropdown-folder-name' className='typography-body'>
                {selected || selectedFileDirectoryMetadata?.FileName || ''}
              </span>
            </div>
          ) : (
            <div data-cy='move-dropdown-folder-name' className='mb-1 typography-body'>
              {selectedFileWorkspace !== allWorkspacesLabel && (
                <Button
                  className='!p-2'
                  color='transparent'
                  onClick={goToAllWorkspaces}
                  disabled={loading}
                >
                  <Icon name='arrow-left' size={14} className='inline-block' />
                </Button>
              )}
              {selected || selectedFileWorkspace}
            </div>
          )}
        </div>
      </div>
      <div className='text-ellipsis overflow-auto whitespace-nowrap h-[152px]'>
        {folderContentDirsSorted?.length && !loading ? (
          folderContentDirsSorted.map(({ metadata }, i) => {
            const { FileUuid, FileName, folderIcon } = metadata || {};
            return (
              <MoveFileRow
                key={i}
                fileName={FileName}
                fileUuid={FileUuid}
                isSelected={isSelected}
                setSelected={isAllWorkspace ? onAllWorkspaceRowClick : setSelected}
                setSelectedFileDirectory={setSelectedFileDirectory}
                setSoftSelectedFileDirectory={
                  isAllWorkspace ? () => {} : setSoftSelectedFileDirectory
                }
                folderIcon={folderIcon}
              />
            );
          })
        ) : (
          <div className='flex items-center justify-center h-full text-shadow-600'>
            {loading ? (
              <span data-cy='move-file-dropdown-loader'>loading...</span>
            ) : folderContentCount ? (
              <span data-cy='move-file-dropdown-content-count'>
                This folder contains {folderContentCount} files.
              </span>
            ) : (
              'No additional folders'
            )}
          </div>
        )}
      </div>

      <div className='flex items-center justify-between pt-4 border-t border-t-ui-200'>
        <Button
          onClick={() => {
            dispatch(
              showModal({
                name: MODAL_CREATE_FOLDER,
                props: {
                  directory: selectedFileDirectory,
                  isTeamWorkspace: selectedFileWorkspace === teamLabel,
                },
              })
            );
          }}
          color='shadow'
          iconName='folder-plus'
          className='!content-start'
          disabled={isAddFolderButtonDisabled}
        >
          <span className='hidden md:block'>Add Folder</span>
        </Button>
        <span className='flex items-center'>
          <Button variant='ghost' className='mr-3' onClick={cancelMove}>
            Cancel
          </Button>

          <Button
            disabled={moveIsForbidden || isAllWorkspace}
            onClick={() => {
              moveFile(softSelectedFileDirectory || selectedFileDirectory);
            }}
            dataCy='move-here-btn'
            color='sunrise'
            className='!content-end'
          >
            Move Here
          </Button>
        </span>
      </div>
    </>
  );
};

export default MoveFile;
