import { IllustrationSVG } from '@/Assets/icons';
import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import withModal from '@/Components/Modals/withModalHOC';
import { MODAL_UPGRADE_TO_TEAM } from '@/Utils/constants';

import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import './style/index.scss';

const UpgradeToTeamsModal = ({ show, hideModal }) => {
  const whatsIncluded = [
    'Get your first 5 users for the price of 2',
    'Premium features for your entire team',
    'Share to your everyone on team with one click',
  ];
  const bodyComponent = (
    <>
      <div className='flex flex-row'>
        <div className='flex flex-col w-6/12 gap-6 text-sm'>
          <div className=''>
            Unlock Gigasheet Teams to scale your operations and streamline collaboration.
          </div>

          <div className='flex flex-col gap-2 '>
            <div className='pb-1 text-sm font-semibold'>Here&apos;s what&apos;s included:</div>
            <div className='pl-2'>
              {whatsIncluded.map((option) => {
                return (
                  <li className='text-sm' key={option}>
                    {option}
                  </li>
                );
              })}
            </div>
          </div>

          <div className='flex flex-col items-center justify-center gap-2 pb-2 align-center'>
            <UpgradeButton />
            <Button onClick={hideModal} variant='ghost'>
              Maybe Later
            </Button>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center absolute top-0 right-0 h-full rounded-r-md bg-pear-100 md:w-[300px] w-[180px]'>
          <img className='w-52' src={IllustrationSVG.Teams} alt='no-results' />
        </div>
      </div>
    </>
  );

  return (
    <Modal
      title='Upgrade to Teams'
      size='medium'
      isOpen={show}
      onClose={hideModal}
      withoutCloseButton
    >
      {bodyComponent}
    </Modal>
  );
};

export default withModal({ name: MODAL_UPGRADE_TO_TEAM })(UpgradeToTeamsModal);
