const sortColumnData = (files, sortDirection, columnName) => {
  if (sortDirection !== 1 && sortDirection !== -1) {
    sortDirection = -1;
  }

  const stringType = columnName === 'FileName' || columnName === 'Owner';

  return files?.sort((x1, x2) => {
    let x1Col = Number(x1.metadata[columnName]);
    let x2Col = Number(x2.metadata[columnName]);
    if (!columnName || columnName === 'LastUpdated') {
      x1Col = new Date(x1.metadata.LastUpdated);
      x2Col = new Date(x2.metadata.LastUpdated);
    } else if (columnName === 'FileColumns') {
      x1Col = Number(x1[columnName]);
      x2Col = Number(x2[columnName]);
    } else if (stringType) {
      x1Col = String(x1.metadata[columnName]).toLowerCase();
      x2Col = String(x2.metadata[columnName]).toLowerCase();
    }

    if (x2Col === x1Col) {
      x1Col = new Date(x1.metadata.LastUpdated);
      x2Col = new Date(x2.metadata.LastUpdated);
    }

    return x1Col < x2Col ? -1 * sortDirection : sortDirection;
  });
};
export default sortColumnData;
