import { Button } from '@/Components/UI/Button/Button';
import MobileDropMenu from '@/Components/UI/MobileDropMenu/MobileDropMenu';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux/es/exports';

import {
  getFileSize,
  getTheLastUploadedFileId,
  onOpenFile,
  onShareFile,
  onEditFile,
  onEditFolder,
  onDeleteFile,
  onExport,
  onMoveFile,
} from '@/helpers/datasetsHelper';

import { Mixpanel } from '@/Mixpanel';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import {
  clearLibrarySearchResult,
  setCurrentDirectory,
  setCurrentFile,
  setIsLoadingLibrary,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';

import {
  MODAL_EDIT_FILE,
  MODAL_SHARE_FILE,
  ROUTE_DATASETS,
  MODAL_UPGRADE_ACCOUNT,
  MODAL_DELETE_FILE,
  MODAL_EXPORT_DOWNLOAD,
  MODAL_MOVE_FILE,
  SHARED_WITH_ME,
} from '@/Utils/constants';
import { parseRowCount } from '@/Utils/DatasetTree/parseRowCount';
import {
  FILE_STATUS_ERROR,
  FILE_TYPE_EXPORTER,
  FILE_TYPE_EXTERNAL,
  FILE_TYPE_OMNIVORE,
  FILE_STATUS_PROCESSED,
} from '@/Utils/fileConstants';
import { getFileTypeByName } from '@/Utils/getFileTypeByName';
import rowClassNameGenerator from '@/Utils/rowClassNames';
import { formatUrlSafeFileName, openInNewTab, openSpreadsheet } from '@/Utils/utils';

import MobileFileNameDetail from '@/pages/DatasetPage/MobileFileNameDetail';
import { useNavigate } from 'react-router-dom';
import { getWebSocketCategory } from './WebSocketLibrary/WebSocketContainer';
import { useWebSocketContext } from './WebSocketLibrary/WebSocketContext';

export const MobileRow = ({ row, isAnonymous, isSharedParentFolder }) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const [isMobileDropMenuOpen, setIsMobileDropMenuOpen] = useState(false);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { libraryFiles, filesSizePercentage, processingFiles, currentDataset, currentFile } =
    useSelector((state) => state[datasetSliceName]);
  const isSharedWithMeTabActive = currentDataset === SHARED_WITH_ME;

  const { metadata, FileColumns } = row;
  const fileSize = getFileSize(metadata);
  const isFolder = metadata.IsDirectory;
  const columns = isFolder ? '' : FileColumns;
  const rowCount = metadata.FileRows === '-1' ? '' : parseRowCount(metadata.FileRows);
  const rowClass = rowClassNameGenerator(row, [], [], false);
  const navigate = useNavigate();
  const isOverFileRowsLimit = metadata?.FileRows > user?.userProperties?.maxFileRows;

  // If this file is included in the processingFiles queue, and it has successfully processed, then refresh the dataset
  // This will trigger the file to open in a new tab
  useEffect(() => {
    if (
      processingFiles.find(({ FileUuid }) => metadata.FileUuid === FileUuid) &&
      metadata.Status === FILE_STATUS_PROCESSED
    ) {
      sendMessage({
        location: `${metadata.FileUuid}`,
        category: getWebSocketCategory(currentDataset),
      });
    }
  }, [metadata.Status]);

  const openFile = onOpenFile(() =>
    openInNewTab(`spreadsheet/${formatUrlSafeFileName(metadata.FileName)}/${metadata.FileUuid}`)
  );
  const editFolderPopup = onEditFolder(() => dispatch(showModal({ name: MODAL_EDIT_FILE })));
  const editFileModal = onEditFile(() => dispatch(showModal({ name: MODAL_EDIT_FILE })));
  const moveFilePopup = onMoveFile(() => {
    const folderId = row.ParentDirectory || row.metadata.ParentDirectory;
    dispatch(
      showModal({
        name: MODAL_MOVE_FILE,
        props: { file: currentFile, folderName: '', folderId, size: 'small' },
      })
    );
  });
  const shareFileModal = onShareFile(() => dispatch(showModal({ name: MODAL_SHARE_FILE })));
  const deleteFileModal = onDeleteFile(() =>
    dispatch(
      showModal({
        name: MODAL_DELETE_FILE,
        props: {
          filesToDelete: [currentFile?.metadata],
        },
      })
    )
  );
  const exportFilePopup = onExport(() => dispatch(showModal({ name: MODAL_EXPORT_DOWNLOAD })));

  const _setCurrentFile = (file) => {
    const isFileShared = user.email !== file.metadata.Owner;
    dispatch(setCurrentFile({ ...file, isFileShared }));
  };

  const shouldShowFileActions = () => {
    return (
      !isSharedWithMeTabActive ||
      (!isAnonymous && !(row?.IsDirectory && row?.permissions?.length === 0))
    );
  };

  const onFolderClick = () => {
    if (isAnonymous) {
      window.open(
        `${ROUTE_DATASETS}?folder=${metadata.FileUuid}`,
        '_self',
        'noopener',
        'noreferrer'
      );
    } else {
      dispatch(setCurrentFile(currentFile));
      dispatch(setIsLoadingLibrary(true));
      dispatch(clearLibrarySearchResult());
      dispatch(setCurrentDirectory(metadata.FileUuid));
      navigate(`/datasets/${metadata.FileUuid}`);
      dispatch(unSelectAllFiles());
      sendMessage({
        location: `${metadata.FileUuid}`,
        category: getWebSocketCategory(currentDataset),
      });
    }
  };

  const onFileClick = (e) => {
    e.stopPropagation();
    if (metadata.Type === FILE_TYPE_EXPORTER) {
      // onExportClick(row);
      return null;
    } else {
      if (
        (metadata.Type === FILE_TYPE_OMNIVORE || metadata.Type === FILE_TYPE_EXTERNAL) &&
        metadata.Status === FILE_STATUS_PROCESSED
      ) {
        openSpreadsheet(metadata.FileUuid, metadata.FileName);
      }
    }
  };

  const onFileOrFolderClick = (event, row, isToolTip) => {
    _setCurrentFile(row);
    if (filesSizePercentage > 100) {
      const theLastUploadedFileID = getTheLastUploadedFileId(libraryFiles);

      if (theLastUploadedFileID === metadata.FileUuid && metadata.Type !== FILE_TYPE_EXPORTER) {
        return dispatch(
          showModal({ name: MODAL_UPGRADE_ACCOUNT, props: { upgradeType: 'maxStorageSize' } })
        );
      }
    }
    if (shouldShowFileActions()) {
      if (isFolder) {
        if (isToolTip) {
          event.stopPropagation();
          return setIsMobileDropMenuOpen(true);
        }
        return onFolderClick(row);
      } else {
        return setIsMobileDropMenuOpen(true);
      }
    } else {
      if (isFolder) return onFolderClick(row);
      return onFileClick(event);
    }
  };

  const getDropdownItems = () => {
    if (metadata.Status === FILE_STATUS_ERROR) {
      return [deleteFileModal];
    } else if (metadata.Type === FILE_TYPE_EXPORTER) {
      return [exportFilePopup, deleteFileModal];
    } else if (isFolder) {
      if (currentFile?.isFileShared) {
        return [];
      } else {
        return [editFolderPopup, moveFilePopup, shareFileModal];
      }
    } else {
      if (currentFile?.isFileShared) {
        return [openFile];
      } else {
        if (row.within_quota && !isOverFileRowsLimit) {
          return [openFile, shareFileModal, editFileModal, moveFilePopup, deleteFileModal];
        }
        return [openFile, editFileModal, moveFilePopup, deleteFileModal];
      }
    }
  };

  useEffect(() => {
    if (metadata.Status === FILE_STATUS_ERROR) {
      const { FileName, DetailedStatus, FileRows } = row.metadata || {};
      const fileType = getFileTypeByName(FileName);
      Mixpanel.track('File Error', {
        'File Type': fileType,
        'Error Message': DetailedStatus,
        'Row Count': FileRows,
      });
    }
  }, [metadata.Status]);

  return (
    <>
      <div
        onKeyDown={() => null}
        id={'tr_' + metadata.FileName.replaceAll('.csv', '')}
        data-cy={
          'tr_' + metadata.FileName + '_' + (fileSize === 'Loading' ? 'processing' : 'processed')
        }
        key={metadata.fileName}
        className={rowClass}
        tabIndex='0'
        role='link'
        onClick={(event) => onFileOrFolderClick(event, row)}
      >
        <div className='flex flex-row items-center pl-3 h-14'>
          <div className='grow'>
            <MobileFileNameDetail
              isFolder={isFolder}
              row={row}
              fileSize={fileSize}
              columns={columns}
              rowCount={rowCount}
              currentFile={currentFile}
              isSharedParentFolder={isSharedParentFolder}
            />
          </div>

          {!isAnonymous ? (
            <Button
              dataCy={
                'tr_row_actions_' +
                metadata.FileName +
                '_' +
                (fileSize === 'Loading' ? 'processing' : 'processed')
              }
              color='shadow'
              iconName='dots-three-vertical'
              size='large'
              variant='ghost'
              className='md:hidden'
              onClick={(event) => onFileOrFolderClick(event, row, true)}
            />
          ) : null}
        </div>
      </div>
      <MobileDropMenu isOpen={isMobileDropMenuOpen} onClose={() => setIsMobileDropMenuOpen(false)}>
        <p
          data-cy={`mobile-file-actions-list-header-${metadata.FileName}`}
          className='px-3 py-2 font-semibold border-b'
        >
          {metadata.FileName}
        </p>
        {getDropdownItems().map(({ title, dataCy, icon, callback }, index) => (
          <Button
            key={index}
            className={'w-full !justify-start !text-ui'}
            variant='ghost'
            dataCy={dataCy}
            onClick={callback}
            iconName={icon}
            size='large'
          >
            {title}
          </Button>
        ))}
      </MobileDropMenu>
    </>
  );
};
