import withModal from '@/Components/Modals/withModalHOC';
import {
  MODAL_COMMENTS,
  MODAL_MAKE_COPY,
  PERMISSION_WRITE,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import Modal from '@/Components/UI/Modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import CommentInput from '@/Components/Modals/Comments/CommentInput';
import CommentThread from '@/Components/Modals/Comments/CommentThread';
import {
  addCellComment,
  addColumnComment,
  getAllComments,
  getAutofillEmails,
  getCellComments,
  getColumnComments,
} from '@/Utils/CommentUtils';
import showToast from '@/Components/Toast/showToastTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { datasetSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import CommentResetSheet from '@/Components/Modals/Comments/CommentResetSheet';
import { setRefreshCommentThreads, setCommentCell } from '@/redux/reducers/spreadsheetSlice';
import AllCommentsEntries from './AllCommentsEntries';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const Comments = ({
  show,
  hideModal,
  column,
  row,
  showAllComments,
  isColumnComment = false,
  askToResetSheet = false,
}) => {
  const [thread, setThread] = useState([]);
  const [requestIsSending, setRequestIsSending] = useState(false);
  const [autofillOptions, setAutofillOptions] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(null);

  const currentFile = useCurrentFile();
  const { org_id } = useSelector((state) => state.userData.user);
  const { availablePermissions } = currentFile || {};
  const handle = getFileUuidFromPath();
  const textareaRef = useRef();
  const refreshThreadsTimeout = 5000;
  const dispatch = useDispatch();

  useEffect(() => {
    getAutofillOptions();
    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    if ((column && row > 0) || showAllComments || isColumnComment) {
      getThread();
      const interval = setInterval(() => getThread(), refreshThreadsTimeout);
      setRefreshInterval(interval);
      return () => clearInterval(interval);
    }
  }, [column, row, showAllComments, isColumnComment]);

  const close = () => {
    clearInterval(refreshInterval);
    setThread([]);
    setRequestIsSending(false);
    setRefreshInterval(null);
    hideModal();
  };

  const getThread = () => {
    if (isColumnComment) getColumnThread();
    if (row > 0 && column) getCellThread();
    if (showAllComments) getSpreadsheetThread();
  };

  const getSpreadsheetThread = async () => {
    const response = await getAllComments(handle);
    setThread(response);
  };

  const getColumnThread = () => {
    if (handle && column) {
      getColumnComments(handle, column).then((columnThread) => {
        setThread((prev) => (prev.length === columnThread.length ? prev : columnThread));
      });
    }
  };

  const getCellThread = () => {
    if (handle && column && row > 0) {
      getCellComments(handle, column, row).then((cellThread) => {
        setThread((prev) => (prev.length === cellThread.length ? prev : cellThread));
      });
    }
  };

  const getAutofillOptions = () => {
    const includeShareRecipients = true;
    let includeTeamMembers = false;
    if (org_id?.length > 0) {
      includeTeamMembers = true;
    }
    getAutofillEmails(includeTeamMembers, includeShareRecipients).then((emails) => {
      setAutofillOptions(emails);
    });
  };

  const sendCreateCommentRequest = async (commentText, taggedEmails) => {
    setRequestIsSending(true);
    try {
      if (!availablePermissions?.includes(PERMISSION_WRITE)) {
        dispatch(showModal({ name: MODAL_MAKE_COPY }));
      } else {
        if (isColumnComment) {
          await addColumnComment(handle, column, commentText, taggedEmails);
        } else {
          await addCellComment(handle, column, row, commentText, taggedEmails);
        }
      }
    } catch (e) {
      console.error(e);
      showToast({
        type: TOAST_TYPE_ERROR,
        text: 'Failed to add comment. Please try again later or contact support if the issue persists.',
        errorContext: e,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setRequestIsSending(false);
      if (thread?.length === 0) {
        dispatch(setRefreshCommentThreads(true));
      }
      getThread();
      focusTextArea();
    }
  };

  const focusTextArea = () => {
    textareaRef.current?.focus();
  };

  const selectCell = ({ row, column }) => {
    dispatch(setCommentCell({ row, column, isColumnComment }));
  };

  return (
    <Modal
      title='Comments'
      titleCss='!text-[21px] !text-ui-secondary !font-medium !relative !-top-4'
      isOpen={show}
      onClose={close}
      childrenCss='p-0'
      modalClassName='!absolute !top-12 !right-20'
      shouldCloseOnEscapeKeyDown={false}
    >
      <div>
        {showAllComments ? (
          <AllCommentsEntries
            autofillOptions={autofillOptions}
            comments={thread}
            close={close}
            selectCell={selectCell}
          />
        ) : askToResetSheet ? (
          <CommentResetSheet column={column} row={row} close={close} />
        ) : thread?.length > 0 ? (
          <CommentThread
            thread={thread}
            column={column}
            row={row}
            getThread={getThread}
            isColumnComment={isColumnComment}
            autofillOptions={autofillOptions}
            sendCreateCommentRequest={sendCreateCommentRequest}
            requestIsSending={requestIsSending}
          />
        ) : (
          <CommentInput
            placeholder='Comment or notify others with @'
            sendCreateCommentRequest={sendCreateCommentRequest}
            requestIsSending={requestIsSending}
            autofillOptions={autofillOptions}
            ref={textareaRef}
          />
        )}
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_COMMENTS })(Comments);
