import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';

import {
  setCurrentDirectory,
  setLibraryFiles,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';

import { http_delete } from '@/Utils/API';
import { MODAL_DELETE_FILE, TOAST_TYPE_ERROR, URL_DATASETS } from '@/Utils/constants';

import { useNavigate } from 'react-router-dom';
import { datasetSliceName } from '@/redux/constants';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';

const DeleteFileModal = ({ show, hideModal, filesToDelete = [], resetLibrary = false }) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();

  const { libraryFiles, filePath, currentDataset } = useSelector(
    (state) => state[datasetSliceName]
  );

  const navigate = useNavigate();
  const deleteFiles = async () => {
    const topLevelParentDirectory = filesToDelete[0].ParentDirectory;
    const topLevelFilesToDelete = filesToDelete.filter(
      ({ ParentDirectory }) => ParentDirectory === topLevelParentDirectory
    );

    dispatch(
      setLibraryFiles(
        libraryFiles.filter(
          (file) => !topLevelFilesToDelete.find((el) => el.FileUuid === file.metadata.FileUuid)
        )
      )
    );

    const parentFolder = filePath[filePath?.length - 2];

    try {
      hideModal();

      if (resetLibrary) {
        dispatch(setCurrentDirectory(parentFolder?.FileUuid || ''));
        navigate(`/${URL_DATASETS}/${parentFolder?.FileUuid || ''}`);

        sendMessage({
          category: getWebSocketCategory(currentDataset),
          location: parentFolder?.FileUuid || '',
        });
      }

      // This endpoint will now delete an entire folder including all nested children
      const deletePromises = topLevelFilesToDelete.map((file) =>
        http_delete('delete', file.FileUuid + '?recursive=true')
      );
      const responses = await Promise.all(deletePromises);
      responses.forEach((response, idx) => {
        if (!response?.Success) {
          const { IsDirectory: isDirectory } = topLevelFilesToDelete[idx];

          const errorMessage = isDirectory
            ? `Error deleting folder: ${response?.Message}`
            : `Error deleting file: ${response?.Message}`;
          showToast({
            type: TOAST_TYPE_ERROR,
            text: errorMessage,
            errorContext: response,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
          return;
        }
      });
      dispatch(unSelectAllFiles());
      sendMessage({
        category: getWebSocketCategory(currentDataset),
        location: topLevelParentDirectory || '',
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <Modal isOpen={show} onClose={hideModal} title='Confirm Deletion' iconName='trash'>
      <div data-cy='delete-row-text' className='text-sm'>
        Are you sure you want to delete the {filesToDelete.length > 1 ? filesToDelete.length : ''}{' '}
        selected{' '}
        {filesToDelete.some((e) => e.IsDirectory)
          ? 'and any nested items'
          : filesToDelete.length > 1
          ? 'items'
          : 'item'}{' '}
        <span className='font-bold underline'>forever?</span>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' onClick={deleteFiles} dataCy='save-btn'>
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_FILE })(DeleteFileModal);
