import React, { useMemo } from 'react';
import { Select } from '../Form/Select/Select';
import { getTimezones, TimezoneOption } from './getTimezones';

interface TimezonePickerProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  onClick?: () => void;
}

const TimezonePicker: React.FC<TimezonePickerProps> = ({
  label,
  value = 'Etc/UTC',
  onChange,
  onClick,
}) => {
  const options = useMemo<TimezoneOption[]>(() => getTimezones(), []);

  return (
    <Select label={label} options={options} value={value} onChange={onChange} onClick={onClick} />
  );
};

export default TimezonePicker;
