import React, { useState } from 'react';
import withModal from '@/Components/Modals/withModalHOC';
import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_SUGGEST_AN_INTEGRATION } from '@/Utils/constants';
import { Button } from '@/Components/UI/Button/Button';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import { toast } from 'react-toastify';
import Toast from '@/Components/Toast/Toast';
import { post } from '@/Utils/API';
import { StatusCodes } from 'http-status-codes';

const SuggestIntegrationModal = ({ show, hideModal }) => {
  const [input, setInput] = useState('');

  const MAX_LENGTH = 100;

  const showToast = (type, message) => {
    toast(<Toast type={type}>{message}</Toast>, {
      autoClose: true,
      closeButton: false,
      closeOnClick: true,
      hideProgressBar: true,
    });
  };

  const submitSuggestion = async () => {
    if (input.length > MAX_LENGTH) {
      showToast('error', `Input exceeds the maximum length of ${MAX_LENGTH} characters.`);
      return;
    }

    try {
      const response = await post('request-data-integration', { integration_name: input });
      if (response.status !== StatusCodes.OK) {
        showToast('error', 'Something went wrong. Please try again later.');
        console.error('Error submitting input:', response);
      } else {
        showToast('info', 'Thank you for your suggestion!');
      }
    } catch (e) {
      showToast('error', 'Something went wrong. Please try again later.');
      console.error('Error submitting input:', e);
    } finally {
      closeModal();
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[\n\r]/g, '');
    if (value.length <= MAX_LENGTH) setInput(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && input.length <= MAX_LENGTH) {
      submitSuggestion();
    }
  };

  const closeModal = () => {
    hideModal();
    setInput('');
  };

  return (
    <Modal
      title='Suggest an Integration'
      size='small'
      isOpen={show}
      onClose={closeModal}
      childrenCss='m-0 p-0'
      titleSize='large'
    >
      <div className='mt-4 mb-6 px-4'>
        <div className='mb-3 text-sm font-semibold'>What do you suggest?</div>
        <Textarea
          placeholder="I'd like to see..."
          maxLength={MAX_LENGTH}
          className='!text-ui-secondary w-full h-[80px] placeholder:italic resize-none pr-9 focus:!border-ocean-blue focus:!ring-[2px] focus:!ring-ocean-blue-100'
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={input}
        />
      </div>
      <hr />
      <div className='flex justify-end px-5 py-4'>
        <Button onClick={closeModal} dataCy='dismiss-btn' color='white' className='mr-4'>
          Dismiss
        </Button>
        <Button onClick={submitSuggestion} dataCy='submit-btn' color='sunrise'>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_SUGGEST_AN_INTEGRATION })(SuggestIntegrationModal);
