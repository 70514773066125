import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { flushSync } from 'react-dom';

import useOnClickOutsideHook from '@/hooks/useOnClickOutside';

import { spreadsheetSliceName } from '@/redux/constants';
import { setIsOpenFileNameMenu } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  RENAME,
  TOAST_TEXT_RENAME_FILE_ERROR,
  TOAST_TYPE_ERROR,
  URL_SPREADSHEET,
} from '@/Utils/constants';
import { formatUrlSafeFileName } from '@/Utils/utils';

import Icon from '@/Components/UI/Icon/Icon';
import EditFile from '@/Components/Modals/EditFile/EditFile';
import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button.tsx';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

const FileNameMenu = ({
  currentFile = {},
  defaultFileName = '',
  changeUrl = false,
  fetchFileOnChange = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refDropdown = useRef();
  const popoverButtonRef = useRef();

  const { isOpenFileNameMenu } = useSelector((state) => state[spreadsheetSliceName]);
  const toggleDropdown = () => {
    flushSync(() => dispatch(setIsOpenFileNameMenu()));
  };

  const [fileName, setFileName] = useState('');

  const { FileUuid, IsDirectory, Headers } = currentFile?.metadata || {};

  const onChangeFileName = async () => {
    const payload = { uuid: FileUuid, filename: fileName };
    const endpoint = `${RENAME}/${FileUuid}`;
    const response = await post(endpoint, payload);

    if (response?.Success) {
      if (changeUrl) {
        navigate(`/${URL_SPREADSHEET}/${formatUrlSafeFileName(fileName)}/${FileUuid}`, {
          replace: true,
        });
      }
      setFileName(fileName);
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_RENAME_FILE_ERROR,
        errorContext: response,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const handleFileEdit = () => {
    if (fileName !== defaultFileName) onChangeFileName();
    fetchFileOnChange();
    toggleDropdown();
  };

  useOnClickOutsideHook(isOpenFileNameMenu, refDropdown, handleFileEdit);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFileEdit();
      event.preventDefault();
    }
  };

  const onFileNameBlur = async () => {
    if (fileName !== defaultFileName) {
      await onChangeFileName();
      fetchFileOnChange();
    }
  };

  const handleChange = (newValue) => setFileName(newValue);

  useEffect(() => {
    document.title = fileName + ' - Gigasheet';
  }, [fileName]);

  useEffect(() => {
    if (isOpenFileNameMenu) {
      popoverButtonRef.current.click();
    }
  }, [isOpenFileNameMenu]);

  useEffect(() => {
    setFileName(defaultFileName);
  }, [defaultFileName]);

  return (
    <div data-cy='file-name-menu'>
      <Popover as='div' className='relative inline-block'>
        {({ open, close }) => (
          <>
            {defaultFileName?.length > 30 && defaultFileName && !open ? (
              <Tooltip text={defaultFileName} side='bottom'>
                <Popover.Button
                  className='inline-flex items-center justify-center h-6 px-3 text-sm font-semibold rounded text-ui hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-shadow-200 focus-visible:ring focus-visible:ring-ocean-blue-200 focus:outline-none disabled:opacity-40 max-w-[365px]'
                  ref={popoverButtonRef}
                  as='span'
                >
                  <span
                    data-cy='file-name-menu-name'
                    className='overflow-hidden overflow-ellipsis whitespace-nowrap'
                  >
                    {defaultFileName}
                  </span>
                  <span className='w-6'>
                    <Icon name='info' size={18} color='#151515' className='inline-block ml-2' />
                  </span>
                </Popover.Button>
              </Tooltip>
            ) : (
              <Popover.Button
                className='inline-flex items-center justify-center h-6 px-3 text-sm font-semibold rounded text-ui hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-shadow-200 focus-visible:ring focus-visible:ring-ocean-blue-200 focus:outline-none disabled:opacity-40 max-w-[365px]'
                ref={popoverButtonRef}
              >
                <span
                  data-cy='file-name-menu-name'
                  className='overflow-hidden overflow-ellipsis whitespace-nowrap'
                >
                  {defaultFileName}
                </span>
                <span className='w-6'>
                  <Icon name='info' size={18} color='#151515' className='inline-block ml-2' />
                </span>
              </Popover.Button>
            )}

            <Transition
              as={React.Fragment}
              enter='transition ease-out duration-100'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Popover.Panel
                focus
                static
                className='absolute z-10 w-[25rem] border rounded px-3 py-2 transform bg-white shadow-lg'
              >
                <Button
                  onClick={() => close()}
                  dataCy='close_rename_sheet_dropdown'
                  iconName='x'
                  color='shadow'
                  variant='ghost'
                  className='float-right'
                />
                <div data-cy='rename-dropdown' ref={refDropdown}>
                  <div className='file-details' data-cy='file-details'>
                    <EditFile
                      fileInfo={currentFile}
                      filename={fileName}
                      handleChange={handleChange}
                      isDirectory={IsDirectory}
                      columns={Headers?.length}
                      onKeyDown={handleKeyDown}
                      onFileNameBlur={onFileNameBlur}
                    />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default FileNameMenu;
