import { useMemo, useRef } from 'react';

import './style/index.scss';

const CustomToggleSwitch = ({
  isChecked,
  onChange,
  switcher,
  isDisabled,
  cypressId,
  wrapperClassName,
}) => {
  const checkboxRref = useRef(null);

  const generateSwitcher = useMemo(() => {
    switch (switcher) {
      case 'AndOrSwitcher':
        return { text: 'AND', className: 'AndOr-filter' };
      case 'PrivatePublicSwitcher':
        return { text: 'PRIVATE', className: 'PrivatePublic-filter' };
      case 'YearlyMonthlySwitcher':
        return { text: 'YEARLY', className: 'YearlyMonthly-filter' };
      case 'SimpleSwitcher':
        return { text: '', className: 'Simple-checkbox' };
      default:
        return { text: 'OFF' };
    }
  }, [switcher]);

  return (
    <div
      className={`toggle-button-wrapper ${generateSwitcher.className} ${wrapperClassName || ''}`}
    >
      <div className='button-cover'>
        <div className={`toggle-button ${generateSwitcher.className}`} ref={checkboxRref}>
          <input
            type='checkbox'
            className='toggle-checkbox'
            onChange={onChange}
            checked={isChecked}
            disabled={isDisabled}
            onFocus={() => checkboxRref.current?.classList.add('focused')}
            onBlur={() => checkboxRref.current?.classList.remove('focused')}
            data-cy={cypressId}
          />
          <div className={`knobs ${generateSwitcher.className}`}>
            <span>{generateSwitcher.text}</span>
          </div>
          <div className='layer' />
        </div>
      </div>
    </div>
  );
};

export default CustomToggleSwitch;
