import { Mixpanel } from '@/Mixpanel';

const mixpanelUppyEventsTracking = (uppy) => {
  uppy?.on('file-added', (file) => {
    Mixpanel.track('file-added', {
      'File Type': file.extension,
      'File Name': file.name,
      'File Size': file.size,
      'File ID': file.id,
    });
  });
  uppy?.on('files-added', (files) => {
    const types = files.map((file) => file.extension).join();
    const names = files.map((file) => file.name).join();
    const sizes = files.map((file) => file.size).join();
    const IDs = files.map((file) => file.id).join();
    Mixpanel.track('files-added', {
      'Files Count': files.length,
      'Files Types': types,
      'Files Names': names,
      'Files Sizes': sizes,
      'Files IDs': IDs,
    });
  });
  uppy?.on('file-removed', (file) => {
    Mixpanel.track('file-removed', {
      'File Type': file.extension,
      'File Name': file.name,
      'File Size': file.size,
      'File ID': file.id,
    });
  });
  uppy?.on('upload', (data) => {
    const filesIDs = data.fileIDs.join();
    Mixpanel.track('upload', {
      'Files Count': data.fileIDs.length,
      'Files IDs': filesIDs,
      'Upload ID': data.id,
    });
  });
  uppy?.on('upload-success', (file) => {
    Mixpanel.track('upload-success', {
      'File Type': file.extension,
      'File Name': file.name,
      'File Size': file.size,
      'File ID': file.id,
    });
  });
  uppy?.on('upload-error', (file, error) => {
    Mixpanel.track('upload-error', {
      'File Type': file.extension,
      'File Name': file.name,
      'File Size': file.size,
      'File ID': file.id,
      'Upload Error Name': error.name,
      'Upload Error Message': error.message,
      'Upload Error Stack': error.stack,
    });
  });
  uppy?.on('upload-retry', (fileID) => {
    Mixpanel.track('upload-retry', {
      'File Retry': fileID,
    });
  });
  uppy?.on('error', (error) => {
    Mixpanel.track('uppy-error', {
      'Uppy Error Name': error.name,
      'Uppy Error Message': error.message,
      'Uppy Error Stack': error.stack,
    });
  });
  uppy?.on('cancel-all', () => {
    Mixpanel.track('cancel-all', {
      'Canceled Upload': 'Canceled',
    });
  });
  uppy?.on('restriction-failed', (file, error) => {
    Mixpanel.track('restriction-failed', {
      'File Type': file.extension,
      'File Name': file.name,
      'File Size': file.size,
      'File ID': file.id,
      'Upload Error Name': error.name,
      'Upload Error Message': error.message,
      'Upload Error Stack': error.stack,
    });
  });
  uppy?.on('reset-progress', () => {
    Mixpanel.track('reset-progress', {
      'Reset Progress': 'Reseted',
    });
  });
};

export default mixpanelUppyEventsTracking;
