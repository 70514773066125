import { createSlice } from '@reduxjs/toolkit';
import { enrichmentTemplatesSliceName } from '../constants';

const initialState = {
  chosenEnrichmentTemplate: null,
  defaultFieldValues: null,
  userInputValues: null,
  enrichmentId: '',
  enrichmentOutputPathTable: [],
  selectedOutputPathBool: [],
  outputPathFilteredData: [],
  outputPathMapForApplyRequest: [],
  uniqueColumns: null,
  enrichmentResponseType: '',
  persistedValues: {},
  enrichmentCategory: 'Explore',
};

const enrichmentTemplatesSlice = createSlice({
  name: enrichmentTemplatesSliceName,
  initialState,
  reducers: {
    resetEnrichment(state) {
      state.chosenEnrichmentTemplate = null;
      state.defaultFieldValues = null;
      state.userInputValues = null;
      state.enrichmentId = '';
      state.enrichmentOutputPathTable = [];
      state.selectedOutputPathBool = [];
      state.outputPathFilteredData = [];
      state.outputPathMapForApplyRequest = [];
      state.enrichmentResponseType = '';
      // step 4
      state.uniqueColumns = null;
    },
    setEnrichmentId(state, action) {
      state.enrichmentId = action.payload;
    },
    setEnrichmentCategory(state, action) {
      state.enrichmentCategory = action.payload;
    },
    setUniqueColumns(state, action) {
      state.uniqueColumns = action.payload;
    },
    setOutputPathMapForApplyRequest(state, action) {
      state.outputPathMapForApplyRequest = action.payload;
    },
    setOutputPathFilteredData(state, action) {
      state.outputPathFilteredData = action.payload;
    },
    setSelectedOutputPathBool(state, action) {
      state.selectedOutputPathBool = action.payload;
    },
    setEnrichmentOutputPathTable(state, action) {
      state.enrichmentOutputPathTable = action.payload;
    },
    setEnrichmentResponseType(state, action) {
      state.enrichmentResponseType = action.payload;
    },
    setChosenEnrichmentTemplate(state, action) {
      state.chosenEnrichmentTemplate = action.payload;
      state.defaultFieldValues = action.payload.fields;
      if (state.userInputValues === null) {
        state.userInputValues = action.payload.fields.map((field) => ({
          ...field,
          template_value_if_set: '',
        }));
      }
    },

    updateUserInputValueById: (state, action) => {
      const { id, template_value_if_set } = action.payload;
      function replaceObjectById(array, id, value) {
        const updatedArray = array.map((object) => {
          if (object.id === id) {
            return { ...object, template_value_if_set: value };
          }
          return object;
        });

        return updatedArray;
      }
      state.userInputValues = replaceObjectById(state.userInputValues, id, template_value_if_set);
    },

    updatePersistedValues(state, action) {
      const { key, value } = action.payload;
      state.persistedValues[key] = value;
    },
  },
});

export const {
  setChosenEnrichmentTemplate,
  setEnrichmentOutputPathTable,
  setEnrichmentResponseType,
  updateUserInputValueById,
  setEnrichmentId,
  setSelectedOutputPathBool,
  setOutputPathFilteredData,
  setOutputPathMapForApplyRequest,
  setUniqueColumns,
  resetEnrichment,
  updatePersistedValues,
  setEnrichmentCategory,
} = enrichmentTemplatesSlice.actions;

export default enrichmentTemplatesSlice.reducer;
