import { useSelector, useDispatch } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

import { datasetSliceName } from '@/redux/constants';
import { unSelectAllFiles } from '@/redux/reducers/datasetSlice';

import { put, putRaw } from '@/Utils/API';
import { FILE, MODAL_REMOVE_ACCESS, OPT_OUT, SHARE } from '@/Utils/constants';

import withModal from '@/Components/Modals/withModalHOC';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';

const RemoveAccessModal = ({ show, hideModal, isMultiple = false }) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const { currentFile, selectedFiles, currentDir, currentDataset } = useSelector(
    (state) => state[datasetSliceName]
  );

  const removeAccess = async () => {
    if (isMultiple) {
      await removeAccessForAllSelected();
    } else {
      await put(`${FILE}/${currentFile.metadata.FileUuid}/${SHARE}/${OPT_OUT}`, null);
      sendMessage({
        category: getWebSocketCategory(currentDataset),
        location: currentDir,
      });
    }
    dispatch(unSelectAllFiles());
    hideModal();
  };

  const removeAccessForAllSelected = async () => {
    await Promise.allSettled(
      selectedFiles.map((selectedFile) =>
        putRaw(`${FILE}/${selectedFile.FileUuid}/${SHARE}/${OPT_OUT}`, null)
      )
    );
    sendMessage({
      category: getWebSocketCategory(currentDataset),
      location: currentDir,
    });
  };

  return (
    <Modal isOpen={show} onClose={hideModal} title='Remove Access' iconName='warning'>
      Your access will be removed and this action cannot be undone. Are you sure?
      <hr className='mt-10 mb-5 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' onClick={removeAccess} dataCy='save-btn'>
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_REMOVE_ACCESS })(RemoveAccessModal);
