import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';

import { Button } from '@/Components/UI/Button/Button';
import { Select } from '@/Components/UI/Form/Select/Select';
import '../style/index.scss';
import HelperText from '@/Components/UI/HelperText/HelperText';

const ChangeCase = ({
  options,
  isConfirmStep,
  typeOptions,
  selectedCaseValue,
  setSelectedCaseValue,
  setSelectedColumnValue,
  selectedColumnValue,
  isConfirmDialogChecked,
  setIsConfirmDialogChecked,
  isSaveBtnDisabled,
  cancel,
  save,
}) => {
  return (
    <div>
      <div className='flex flex-col gap-4 pb-4 border-b border-ui' hidden={isConfirmStep}>
        <Select
          disabled={options.length === 0}
          label='Original Column'
          onChange={setSelectedColumnValue}
          options={options}
          value={selectedColumnValue}
        />

        <Select
          label='New Column Case'
          onChange={setSelectedCaseValue}
          options={typeOptions}
          value={selectedCaseValue}
        />
      </div>
      <div className='flex flex-col gap-2 py-4 border-b border-ui' hidden={!isConfirmStep}>
        <div className='text-sm text-ui'>
          You may lose some data. The original column will be hidden. You can toggle its visibility
          in the columns panel.
        </div>
        <CheckboxInput
          onChange={() => setIsConfirmDialogChecked(!isConfirmDialogChecked)}
          label='Don’t show this message again'
          checked={isConfirmDialogChecked}
        />
      </div>

      <div className='flex flex-row justify-end gap-4 mt-4'>
        <Button onClick={cancel}>Cancel</Button>
        <Button
          disabled={!selectedColumnValue?.length || isSaveBtnDisabled}
          onClick={save}
          color='oceanBlue'
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ChangeCase;
