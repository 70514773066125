import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { headers } from '@/Utils/API';
import { getWebsocketHostname } from '@/Utils/utils';
import { useSelector } from 'react-redux';
import { sharedSliceName } from '@/redux/constants';

const WebSocketContext = createContext();

const SOCKETS_CONFIG = {
  reconnectAttempts: 10,
  shouldReconnect: (closeEvent) => true,
  reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  share: true,
};

const libraryFilesSocketUrl = `${getWebsocketHostname()}/library/ws`;
const spacedUsedSocketUrl = `${getWebsocketHostname()}/user/space-used/ws`;

export const useWebSocketContext = () => useContext(WebSocketContext);
export const WEBSOCKET_CATEGORY_LIBRARY = 'library';
export const WEBSOCKET_CATEGORY_SHARED_WITH_ME = 'shared';
export const WEBSOCKET_CATEGORY_SHARED_WITH_TEAM = 'team';

export const WebSocketProvider = ({ children }) => {
  const { isRanByCypress } = useSelector((state) => state[sharedSliceName]);
  const requestHeaders = headers();

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const [retryAttempts, setRetryAttempts] = useState(0);

  const handleOpen = useCallback(
    (sendJsonMessage) => {
      if (!isRanByCypress && requestHeaders.Authorization?.includes('undefined')) return;
      sendJsonMessage(requestHeaders);
    },
    [isRanByCypress, requestHeaders]
  );

  const handleClose = () => {
    if (retryAttempts >= SOCKETS_CONFIG.reconnectAttempts) {
      setShouldReconnect(false);
    } else {
      setRetryAttempts((prev) => prev + 1);
    }
  };

  const handleError = (event) => {
    console.error('WebSocket error observed:', event);
  };

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(libraryFilesSocketUrl, {
    ...SOCKETS_CONFIG,
    onOpen: () => handleOpen(sendJsonMessage),
    onClose: handleClose,
    onError: handleError,
    shouldReconnect: (closeEvent) =>
      shouldReconnect && retryAttempts < SOCKETS_CONFIG.reconnectAttempts,
  });

  const {
    sendJsonMessage: spaceUsedSendMessage,
    lastMessage: spaceUsedLastMessage,
    readyState: spaceUsedReadyState,
  } = useWebSocket(spacedUsedSocketUrl, {
    ...SOCKETS_CONFIG,
    onOpen: () => handleOpen(spaceUsedSendMessage),
    onClose: handleClose,
    onError: handleError,
    shouldReconnect: (closeEvent) =>
      shouldReconnect && retryAttempts < SOCKETS_CONFIG.reconnectAttempts,
  });

  useEffect(() => {
    if (!isRanByCypress && requestHeaders.Authorization?.includes('undefined')) return;
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage(requestHeaders);
    }
    if (spaceUsedReadyState === ReadyState.OPEN) {
      spaceUsedSendMessage(requestHeaders);
    }
  }, [requestHeaders?.Authorization, sendJsonMessage, spaceUsedSendMessage]);

  return (
    <WebSocketContext.Provider
      value={{ sendMessage: sendJsonMessage, lastMessage, spaceUsedLastMessage }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
