export function generateArrayFromRowsIndex(inputArray) {
  const result = [];
  for (const range of inputArray) {
    let start = range.startRow.rowIndex;
    const end = range.endRow.rowIndex;
    const step = start <= end ? 1 : -1;

    while (start !== end) {
      result.push(start);
      start += step;
    }

    result.push(end);
  }

  return result;
}
