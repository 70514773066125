import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Mixpanel } from '@/Mixpanel';

import { datasetSliceName, sharedSliceName, spreadsheetSliceName } from '@/redux/constants';

import { get, post } from '@/Utils/API';
import {
  MODAL_MAKE_COPY_FILE,
  TOAST_TYPE_ERROR,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  URL_DATASETS,
  FILE,
  URL_DATASET,
  URL_SPREADSHEET,
  COPY,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';

import Toast from '@/Components/Toast/Toast';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';

import MakeCopyFile from './MakeCopyFile';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { formatUrlSafeFileName } from '@/Utils/utils';
import { prepareClientState } from '@/redux/reducers/spreadsheetSlice';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { FILE_STATUS_PROCESSED } from '@/Utils/fileConstants';
import { showModal } from '@/redux/reducers/modalsSlice';

const DEFAULT_FOLDER_HANDLE = 'Library';

const MakeCopyFileModal = ({
  show,
  hideModal,
  getGridRequestData,
  isCanExportFilteredGroupedRolePaywall = false,
  isSaveResultsView,
}) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const [isKeepShareSettings, setKeepShareSettings] = useState(false);
  const [currentView, flattenCurrentView] = useState(false);
  const [folder, setFolder] = useState('');
  const [isFolderLoading, setIsFolderLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [options, setOptions] = useState([]);

  const { currentFile, currentDataset, currentDir } = useSelector(
    (state) => state[datasetSliceName]
  );
  const isProgressBarActive = useSelector((state) => state[sharedSliceName].isProgressBarActive);
  const { clientState } = useSelector((state) => state[spreadsheetSliceName]);
  const { FileUuid, FileName, ParentDirectory } = currentFile?.metadata || {};

  const { isPivotEnabled, columnState } = clientState;

  const getOptions = async () => {
    setIsFolderLoading(true);
    try {
      const files = await get(URL_DATASETS);

      if (!files || files === 'null') {
        setOptions([{ value: DEFAULT_FOLDER_HANDLE, label: 'Library' }]);
        setFolder(DEFAULT_FOLDER_HANDLE);
      } else {
        const [defaultFolder] = files.filter((el) => el.FileUuid === ParentDirectory);
        const options = files
          .filter(({ IsDirectory }) => IsDirectory)
          .map((file) => ({ value: file.FileUuid, label: file.FileName }));
        setOptions([{ value: DEFAULT_FOLDER_HANDLE, label: 'Library' }, ...options]);
        setFolder(!defaultFolder ? DEFAULT_FOLDER_HANDLE : defaultFolder.FileUuid);
      }
    } catch (error) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
        errorContext: error,
        endpoint: URL_DATASETS,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setIsFolderLoading(false);
    }
  };

  const getFolder = () => {
    if (folder === DEFAULT_FOLDER_HANDLE) return '';
    return folder;
  };

  const makeCopyFile = async () => {
    hideModal();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    let endpoint = '';
    let payload = {};
    try {
      const {
        rowGroupCols,
        valueCols,
        pivotCols,
        groupKeys,
        totalRowCount,
        filterModel,
        sortModel,
      } = getGridRequestData;

      payload = currentView
        ? {
            gridState: {
              startRow: 0,
              endRow: totalRowCount,
              rowGroupCols: rowGroupCols,
              valueCols: valueCols,
              pivotCols: pivotCols,
              pivotMode: isPivotEnabled,
              groupKeys: groupKeys,
              filterModel: filterModel || {},
              sortModel: sortModel || [],
            },
            fileName: fileName,
            folderHandle: getFolder(),
            KeepShareSettings: isKeepShareSettings,
          }
        : {
            FileName: fileName,
            ParentDirectory: getFolder(),
            KeepShareSettings: isKeepShareSettings,
            filterModel: filterModel || {},
            sortModel: sortModel,
            columnState: columnState || [],
          };

      const copyRequestBody = {
        folderHandle: getFolder(),
        state: prepareClientState(clientState),
      };

      endpoint = isSaveResultsView
        ? `${currentView ? FILE : URL_DATASET}/${FileUuid}/${
            currentView ? 'save-exported' : 'save-current-view'
          }`
        : `${FILE}/${FileUuid}/${COPY}`;
      payload = isSaveResultsView ? payload : copyRequestBody;
      const response = await post(endpoint, payload, true);

      const newFileId = !isSaveResultsView
        ? response.NewHandle
        : currentView
        ? response.Handle
        : response.Message;

      const checkFileReady = (newFileId, intervalId, timeoutId) => {
        isNewFileReady(newFileId).then((newFileIsReady) => {
          if (newFileIsReady) {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            toast(
              ({ closeToast }) => (
                <Toast closeToast={closeToast} type='info'>
                  <MakeCopyFileFileToastBody
                    closeToast={closeToast}
                    newFileName={fileName}
                    newFileId={newFileId}
                  />
                </Toast>
              ),
              { autoClose: false, closeButton: false, closeOnClick: false }
            );
          }
        });
      };

      if (isSaveResultsView) {
        const intervalId = setInterval(
          () => checkFileReady(newFileId, intervalId, timeoutId),
          5000
        );
        const timeoutId = setTimeout(() => clearInterval(intervalId), 5 * 60 * 1000); // 5 minutes timeout

        toast(
          ({ closeToast }) => (
            <Toast closeToast={closeToast} type='info'>
              <div className='px-3'>Saving results, this can take a few seconds…</div>
            </Toast>
          ),
          { autoClose: 10000, hideProgressBar: true, closeButton: false, closeOnClick: true }
        );
      } else {
        toast(
          ({ closeToast }) => (
            <Toast closeToast={closeToast} type='info'>
              <MakeCopyFileFileToastBody
                closeToast={closeToast}
                newFileName={fileName}
                newFileId={newFileId}
              />
            </Toast>
          ),
          { autoClose: false, closeButton: false, closeOnClick: false }
        );
      }

      Mixpanel.track('File Save As', {
        file_uuid: FileUuid,
        save_type: currentView ? 'Save current view' : 'Flatten file',
      });
      sendMessage({
        location: `${currentDir}`,
        category: getWebSocketCategory(currentDataset),
      });
    } catch (error) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: error,
        errorContext: error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const isNewFileReady = async (newFileId) => {
    try {
      const response = await get(`dataset/${newFileId}`, true);
      const metadata = await response?.json();
      return metadata?.Status === FILE_STATUS_PROCESSED;
    } catch (error) {
      return false;
    }
  };

  const onPopupOpen = () => {
    getOptions();
    const defaultFileName = isSaveResultsView ? `Copy of ${FileName}` : `${FileName} Copy`;
    setFileName(defaultFileName);
  };

  useEffect(() => {
    if (show) {
      onPopupOpen();
    }
  }, [show]);

  useEffect(() => {
    if (show && isCanExportFilteredGroupedRolePaywall) {
      dispatch(
        addMixpanelEvent({
          eventName: 'Make a Copy (upgrade to make a copy with filters and groups)',
          eventProps: { 'Export Type': 'All Rows' },
          userIncrementName: '# of exports',
        })
      );
    }
  }, [show, isCanExportFilteredGroupedRolePaywall]);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title={isSaveResultsView ? 'Save Results' : 'Copy Sheet'}
      subheader={isSaveResultsView ? 'Current Result Set' : ''}
      iconName='floppy-disk'
      hasQuestionMark={true}
      articleID={69000819880}
    >
      <MakeCopyFile
        setName={setFileName}
        setFolder={setFolder}
        folder={folder}
        fileName={fileName}
        isFolderLoading={isFolderLoading}
        isKeepShareSettings={isKeepShareSettings}
        setKeepShareSettings={setKeepShareSettings}
        currentView={currentView}
        flattenCurrentView={flattenCurrentView}
        options={options}
        isFileShared={currentFile?.isFileShared}
        isCanExportFilteredGroupedRolePaywall={isCanExportFilteredGroupedRolePaywall}
        isSaveResultsView={isSaveResultsView}
      />
      <hr className='my-4 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          onClick={makeCopyFile}
          disabled={
            isProgressBarActive ||
            fileName.trim() === '' ||
            folder.trim() === '' ||
            isCanExportFilteredGroupedRolePaywall
          }
          dataCy='save-btn'
        >
          {isSaveResultsView ? 'Save Results' : 'Make a Copy'}
        </Button>
      </div>
    </Modal>
  );
};

const MakeCopyFileFileToastBody = ({ closeToast, newFileName, newFileId }) => (
  <a
    href={`/${URL_SPREADSHEET}/${formatUrlSafeFileName(newFileName)}/${newFileId}`}
    onClick={closeToast}
    target='_blank'
    rel='noreferrer'
    style={{ marginLeft: '3px' }}
  >
    Sheet saved! Open it now
  </a>
);

export default withModal({ name: MODAL_MAKE_COPY_FILE })(MakeCopyFileModal);
