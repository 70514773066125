import clsx from 'clsx';
import { ChangeEvent, forwardRef, useState } from 'react';
import InputField from '../../../../Components/UI/Form/InputField/InputField';

type Props = {
  label?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onScroll?: (event: React.UIEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  color?: 'shadow' | 'oceanBlue' | 'violetWeb' | 'pear';
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  helperText?: string;
  helperTextPosition?: 'top' | 'bottom';
  dataCy?: string;
  className?: string;
};

const characterLimitColors = {
  shadow: 'text-ui-helper bg-none !shadow-none ',
  oceanBlue: 'text-capri-900 bg-capri-100',
  violetWeb: 'text-violet-web-900 bg-violet-web-100',
  pear: 'text-pear-900 bg-pear-100',
};

const textAreaColors = {
  shadow: 'border-shadow-200 text-secondary !shadow-none placeholder-helper font-normal',
  oceanBlue: 'border-ocean-blue-500 shadow-ocean-blue-100 text-ui placeholder-ui font-normal',
  violetWeb: 'border-violet-web-700 shadow-violet-web-100 text-ui placeholder-ui font-normal',
  pear: 'border--shadow-200 shadow-pear-100 text-ui placeholder-ui font-normal',
};

const accessibilityColors = 'focus:outline-none';

const Textarea = forwardRef(
  (
    {
      label,
      value,
      onChange,
      onMouseUp,
      onKeyUp,
      onKeyDown,
      onFocus,
      onBlur,
      onScroll,
      placeholder,
      color = 'shadow',
      disabled,
      readonly,
      maxLength,
      helperText,
      helperTextPosition = 'bottom',
      dataCy,
      className,
    }: Props,
    ref?: React.Ref<HTMLTextAreaElement>
  ) => {
    const [charCount, setCharCount] = useState(value?.length);

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event);
      setCharCount(event.target.value.length);
    };

    return (
      <div>
        <InputField
          dataCy={dataCy}
          label={label}
          helperText={helperText}
          helperTextPosition={helperTextPosition}
          color={color}
          disabled={disabled}
        >
          <textarea
            value={value}
            ref={ref}
            readOnly={readonly}
            data-hj-allow
            onChange={handleInputChange}
            placeholder={placeholder}
            data-cy={dataCy}
            disabled={disabled}
            onMouseUp={(e) => {
              if (onMouseUp) onMouseUp(e);
            }}
            onKeyUp={(e) => {
              if (onKeyUp) onKeyUp(e);
            }}
            onKeyDown={(e) => {
              if (onKeyDown) onKeyDown(e);
            }}
            onFocus={(e) => {
              if (onFocus) onFocus(e);
            }}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
            }}
            onScroll={(e) => {
              if (onScroll) onScroll(e);
            }}
            className={clsx(
              'peer shadow-spread rounded w-full p-2 border block disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200 bg-white text-sm',
              textAreaColors[disabled ? 'shadow' : color],
              accessibilityColors,
              className
            )}
          />
          {maxLength && (
            <p
              className={clsx(
                'peer-focus:visible invisible uppercase tracking-wider items-center text-[10px] px-1 w-fit rounded-sm font-extrabold float-right mt-2',
                color && characterLimitColors[disabled ? 'shadow' : color]
              )}
            >
              {charCount}/{maxLength}
            </p>
          )}
        </InputField>
      </div>
    );
  }
);

export default Textarea;
