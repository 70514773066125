import InputField from '@/Components/UI/Form/InputField/InputField';
import clsx from 'clsx';
import React, { ChangeEvent } from 'react';

type Option = {
  label: string;
  labelCss?: string;
  dataCy?: string;
  value: string;
  disabled?: boolean;
  children?: React.ReactElement;
};

type Props = {
  label?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  color?: 'shadow' | 'capri';
  disabled?: boolean;
  options: Option[];
  helperText?: string;
  className?: string;
  horizontalLayout?: boolean;
};

const RadioInput = ({
  label,
  value,
  onChange,
  options,
  color = 'shadow',
  disabled,
  helperText,
  horizontalLayout = true,
  className = '',
}: Props) => {
  const handleInputClick = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  return (
    <div>
      <InputField
        label={label}
        helperText={helperText}
        helperTextPosition='top'
        color={color}
        disabled={disabled}
      >
        <div className={clsx('gap-4 mt-2 w-fit', { 'grid grid-cols-2': horizontalLayout })}>
          {options.map((option) => (
            <label
              key={option.value}
              className={clsx('flex items-center px-1', { 'mb-2': !horizontalLayout }, className)}
            >
              <input
                type='radio'
                value={option.value}
                data-cy={option.dataCy}
                checked={value === option.value}
                onChange={handleInputClick}
                disabled={disabled}
                className={clsx('h-4 w-4 accent-midnight')}
              />
              <span className={clsx('ml-2 text-sm font-normal text-ui', option.labelCss)}>
                {option.label}
              </span>
              {option.children}
            </label>
          ))}
        </div>
      </InputField>
    </div>
  );
};

export default RadioInput;
