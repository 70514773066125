import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';

import {
  FILE_PERMISSIONS,
  MODAL_CREATE_FOLDER,
  MODAL_DELETE_FILE,
  MODAL_EDIT_FILE,
  MODAL_MOVE_FILE,
  MODAL_SHARE_FILE,
  PERMISSIONS,
  SHARED_WITH_ME,
  URL_DATASET,
  userTeamNameKey,
} from '@/Utils/constants';

import useOnClickOutsideHook from '@/hooks/useOnClickOutside';
import {
  selectFile,
  setCurrentDataset,
  setCurrentDirectory,
  setCurrentFile,
  setFilePath,
  setIsInvalidFolderHandle,
  setIsLoadingLibrary,
  setIsUserHasSharedFolderAccess,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';

import { fileSVG } from '@/Assets/icons';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Spinner from '@/Components/UI/Spinner/Spinner';
import { get } from '@/Utils/API';
import { filePathEndpoint, noteEndpoint } from '@/Utils/apiEndpoints';
import getColumnsCount from '@/Utils/getColumnsCount';
import useViewportProvider from '@/hooks/useViewportProvider';
import { useNavigate, useParams } from 'react-router-dom';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import {
  WEBSOCKET_CATEGORY_LIBRARY,
  useWebSocketContext,
} from '../WebSocketLibrary/WebSocketContext';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import { IsFileSharedWithMe, IsFileSharedWithTeam } from '@/Utils/sharing';
import { StatusCodes } from 'http-status-codes';
import { getWebSocketCategory } from '../WebSocketLibrary/WebSocketContainer';

const NavigateFilePath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuidFile = useParams().fileUuid;
  const { sendMessage } = useWebSocketContext();
  const { filePath, sortingData, librarySearchResult, currentDataset } = useSelector(
    (state) => state[datasetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { email } = user;
  const isSharedWithMeTabActive = currentDataset === SHARED_WITH_ME;
  const isSharedWithMyTeamTabActive = currentDataset === getTeamNameLabel(user[userTeamNameKey]);

  const actionsMenuRef = useRef();
  const [menuIsOpen, toggleActionsMenu] = useState(false);
  const [isActualDataLoading, setIsActualDataLoading] = useState(false);

  const { isMobile } = useViewportProvider();

  useOnClickOutsideHook(menuIsOpen, actionsMenuRef, () => toggleActionsMenu(false));
  const resetLibrary = async () => {
    dispatch(setIsLoadingLibrary(true));
    dispatch(setFilePath([]));
    try {
      sendMessage({
        location: '',
        category: getWebSocketCategory(currentDataset),
      });
      toggleActionsMenu(() => false);
      dispatch(unSelectAllFiles());
      dispatch(setCurrentDirectory(''));
      navigate('/datasets', { replace: true });
    } catch (error) {
      console.error('Error resetting library:', error);
    } finally {
      dispatch(setIsLoadingLibrary(false));
    }
  };
  const getFullActualFileInformation = async (FileUuid) => {
    try {
      setIsActualDataLoading(true);

      const [currentFile, note, availablePermissionsJson, publicFilePermissions] =
        await Promise.all([
          get(`${URL_DATASET}/${FileUuid}`, true).then((response) => response.json()),
          get(noteEndpoint(FileUuid)),
          get(`${URL_DATASET}/${FileUuid}/${PERMISSIONS}`, true).then((response) =>
            response.json()
          ),
          get(`${URL_DATASET}/${FileUuid}/${FILE_PERMISSIONS}`, true).then((response) =>
            response.json()
          ),
        ]);

      return {
        metadata: { ...currentFile, Note: note },
        isFileShared: currentFile.Owner !== email,
        availablePermissions: availablePermissionsJson.permissions,
        IsWorldReadable: publicFilePermissions.permissions.length > 0,
        WithinQuota: currentFile.WithinQuota,
        FileColumns: getColumnsCount(currentFile),
      };
    } catch (error) {
      console.error('Error fetching file information:', error);
      throw new Error('Failed to retrieve file information.');
    } finally {
      setIsActualDataLoading(false);
    }
  };

  const handleUuidParams = async (uuidFile) => {
    if (uuidFile) {
      dispatch(setCurrentDirectory(uuidFile));
      dispatch(unSelectAllFiles());
      try {
        const metadataResponse = await get(`${URL_DATASET}/${uuidFile}`, true);
        if (metadataResponse.status === StatusCodes.OK) {
          const metadata = await metadataResponse.json();
          const isSharedWithTeam = await IsFileSharedWithTeam(uuidFile);
          const isSharedWithMe = await IsFileSharedWithMe(user, uuidFile, true);
          const isOwner = metadata?.Owner === user.email;

          if (isSharedWithMe) {
            sendMessage({
              location: uuidFile,
              category: 'shared',
            });
            dispatch(setCurrentDataset(SHARED_WITH_ME));
          }
          if (isSharedWithTeam) {
            sendMessage({
              location: uuidFile,
              category: 'team',
            });
            dispatch(setCurrentDataset(getTeamNameLabel(user[userTeamNameKey])));
          }

          if (!isOwner && !isSharedWithMeTabActive && !isSharedWithMyTeamTabActive) {
            dispatch(setIsInvalidFolderHandle(false));
            return;
          }
          dispatch(setIsInvalidFolderHandle(false));
          sendMessage({
            location: uuidFile,
            category: WEBSOCKET_CATEGORY_LIBRARY,
          });
        }
        if (metadataResponse.status === StatusCodes.BAD_REQUEST) {
          dispatch(setIsInvalidFolderHandle(true));
        }
        if (metadataResponse.status === StatusCodes.UNAUTHORIZED) {
          dispatch(setIsUserHasSharedFolderAccess(false));
        }
      } catch (error) {
        throw new Error(error);
      }
    } else {
      dispatch(setCurrentDirectory(''));
    }
  };

  useEffect(() => {
    dispatch(setIsInvalidFolderHandle(false));
    if (uuidFile) {
      handleUuidParams(uuidFile).then(() => {
        get(filePathEndpoint(uuidFile)).then((data) => {
          if (!data.Success && data?.Message === 'sheet does not exist') {
            dispatch(setIsInvalidFolderHandle(true));
            return;
          }
          dispatch(setFilePath(data));
        });
      });
    }
  }, [uuidFile]);

  const navigateCurrPath = async (e) => {
    const { FileUuid } = e;

    if (FileUuid === sortingData.currentDirectory) {
      const actualFileInfo = await getFullActualFileInformation(FileUuid);
      dispatch(setCurrentFile(actualFileInfo));
      toggleActionsMenu(() => true);
    } else {
      sendMessage({
        location: `${FileUuid}`,
        category: WEBSOCKET_CATEGORY_LIBRARY,
      });
      const actions = [
        dispatch(setIsLoadingLibrary(true)),
        dispatch(unSelectAllFiles()),
        dispatch(setCurrentDirectory(FileUuid)),
      ];

      await Promise.all(actions);

      toggleActionsMenu(() => false);
      navigate(`/datasets/${FileUuid}`, { replace: true });
    }
  };

  const dropdownActions = [
    {
      name: 'Rename',
      icon: 'pencil-simple',
      action: { name: MODAL_EDIT_FILE, props: { refreshHeader: true } },
      popup: true,
    },
    {
      name: 'New Folder',
      icon: 'folder-plus',
      action: { name: MODAL_CREATE_FOLDER, props: { directory: sortingData.currentDirectory } },
      popup: true,
    },
    {
      name: 'Move',
      icon: 'arrows-out-cardinal',
      action: null,
      popup: false,
    },
    {
      name: 'Share',
      icon: 'user-plus',
      action: { name: MODAL_SHARE_FILE, props: {} },
      popup: true,
    },
    {
      name: 'Delete',
      icon: 'trash',
      action: {
        name: MODAL_DELETE_FILE,
        props: { filesToDelete: [filePath[filePath.length - 1]], resetLibrary: true },
      },
      popup: true,
    },
  ];

  const handleMoveFolder = () => {
    dispatch(unSelectAllFiles());
    dispatch(selectFile(filePath[filePath?.length - 1]));
    dispatch(showModal({ name: MODAL_MOVE_FILE, props: { isMovingSelected: true } }));
  };

  const isMobileExperienceActive = localStorage.getItem('isMobileExperience') === 'true';

  return (
    <div className='flex items-center justify-center md:justify-between '>
      <div className='flex flex-wrap start nowrap'>
        {(filePath?.length === 0 || librarySearchResult) && !isMobile && (
          <p
            data-cy='top-library-level'
            className='items-center justify-center hidden h-8 px-3 text-base md:inline-flex'
          >
            {librarySearchResult ? 'Search Results' : currentDataset}
          </p>
        )}
        {filePath?.length > 0 && !librarySearchResult && !isMobile && (
          <Button
            disabled={!sortingData.currentDirectory}
            onClick={resetLibrary}
            color='shadow'
            variant='ghost'
            dataCy='file-path__library'
            className='!text-ui !text-base'
          >
            {librarySearchResult ? 'Search Results' : currentDataset}
          </Button>
        )}
        {!librarySearchResult &&
          !isMobile &&
          filePath?.map((e, i) => (
            <div className='pb-2.5' hidden={isSharedWithMeTabActive} key={i}>
              <Icon name='caret-right' className='inline-block' />
              <Tooltip text={e.FileName.length > 14 ? e.FileName : null} side='bottom' asChild>
                <Button
                  color='shadow'
                  onClick={() => navigateCurrPath(e)}
                  dataTestId={`path-dropdown-${e.FileName}`}
                  dataCy={`file-path__${
                    i + 1 === filePath.length ? 'current-directory' : 'parent-directory'
                  }`}
                  className={clsx(
                    `file-path-btn${i + 1 === filePath.length ? '__dropdown' : ''}`,
                    'ml-2',
                    isMobileExperienceActive &&
                      i + 1 === filePath.length &&
                      'pointer-events-none md:pointer-events-auto opacity-40 md:opacity-100'
                  )}
                  id={e.FileUuid}
                >
                  <span className='flex items-center file-path-btn__folder-name'>
                    <span className='truncate max-w-[150px]'>{e.FileName}</span>
                    <span className='ml-1'>
                      {isActualDataLoading && filePath.length - 1 === i && <Spinner />}
                    </span>
                    <span className='ml-1'>
                      {i + 1 === filePath.length && (
                        <Icon name='caret-down' className='inline-block' />
                      )}
                    </span>
                  </span>
                </Button>
              </Tooltip>
              {i + 1 === filePath.length && (e.IsWorldReadable || e.IsSharedByMe) ? (
                <img
                  src={fileSVG.PublicFileIcon}
                  className='inline-block ml-2.5'
                  alt='public file'
                />
              ) : null}

              {menuIsOpen && i + 1 === filePath.length ? (
                <div className='file-path-container'>
                  <div
                    ref={actionsMenuRef}
                    data-cy='file-path-dropdown'
                    className='ml-2 file-path-dropdown'
                  >
                    {dropdownActions.map(({ name, icon, action, popup }, i) => (
                      <Button
                        key={i}
                        onClick={() => {
                          if (name === 'Delete') {
                            toggleActionsMenu(false);
                          }
                          return popup ? dispatch(showModal(action)) : handleMoveFolder();
                        }}
                        color='shadow'
                        variant='ghost'
                        className='!w-full !justify-start'
                        dataCy={`file-path-dropdown__action-${name}`}
                        iconName={icon}
                      >
                        {name}
                      </Button>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
};

export default NavigateFilePath;
