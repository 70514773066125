// Lookup icons here: https://phosphoricons.com/ and only add what is needed.
import {
  Archive,
  ArrowClockwise,
  ArrowCounterClockwise,
  ArrowDown,
  ArrowLeft,
  ArrowLineLeft,
  ArrowLineRight,
  ArrowRight,
  ArrowSquareDown,
  ArrowSquareOut,
  ArrowUp,
  ArrowsDownUp,
  ArrowsInSimple,
  ArrowsOutSimple,
  ArrowsInLineHorizontal,
  ArrowsInLineVertical,
  ArrowsLeftRight,
  ArrowsMerge,
  ArrowsOutCardinal,
  ArrowsOutLineHorizontal,
  ArrowsOutLineVertical,
  ArrowsSplit,
  Atom,
  Books,
  BracketsCurly,
  Broom,
  Calculator,
  Calendar,
  CalendarBlank,
  CaretCircleLeft,
  CaretCircleRight,
  CaretDoubleDown,
  CaretDoubleUp,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartBar,
  ChatDots,
  ChatText,
  Check,
  CheckCircle,
  ClipboardText,
  Clock,
  Cloud,
  Code,
  Command,
  Columns,
  Copy,
  Database,
  Divide,
  DotsSix,
  DotsSixVertical,
  DotsThree,
  DotsThreeVertical,
  Download,
  DownloadSimple,
  EnvelopeSimple,
  Eye,
  EyeSlash,
  Eraser,
  File,
  FileSearch,
  FileText,
  FlagBanner,
  FloppyDisk,
  FolderOpen,
  FolderPlus,
  FolderSimple,
  FolderSimpleLock,
  FolderSimpleUser,
  Function,
  Funnel,
  Gear,
  GitMerge,
  Globe,
  Graph,
  HandHeart,
  Hash,
  HashStraight,
  IconWeight,
  Info,
  Key,
  Laptop,
  Lightbulb,
  Link,
  LinkSimpleHorizontal,
  ListChecks,
  ListNumbers,
  Lock,
  LockKey,
  LockSimple,
  LockSimpleOpen,
  MagnifyingGlass,
  MapPinLine,
  Minus,
  Note,
  NumberSquareTwo,
  PaperPlaneRight,
  PaperPlaneTilt,
  Pause,
  PencilSimple,
  PencilSimpleSlash,
  Play,
  Plus,
  PlusCircle,
  Prohibit,
  Question,
  Robot,
  RocketLaunch,
  Rows,
  Scissors,
  SealCheck,
  SignIn,
  SignOut,
  SlidersHorizontal,
  Smiley,
  SmileyMeh,
  SmileyXEyes,
  SortAscending,
  SortDescending,
  Sparkle,
  SpinnerGap,
  SquareHalf,
  SquaresFour,
  Swap,
  Table,
  TextAa,
  TextT,
  Trash,
  UploadSimple,
  User,
  UserPlus,
  Users,
  UsersThree,
  Warning,
  WarningCircle,
  X,
  XCircle,
  TreeStructure,
  Textbox,
  List,
  ChatTeardropText,
  Video,
  Gift,
  Infinity,
  LockKeyOpen,
  Buildings,
  MapPin,
  ShieldStar,
  Sliders,
  MagicWand,
  Translate,
  Coins,
  ArrowUDownLeft,
  ArrowsHorizontal,
  ArrowLineDown,
  ArrowLineUp,
  DeviceMobile,
  ClockCounterClockwise,
  Chats,
  ArrowBendUpLeft,
  WebhooksLogo,
  PaintBrushHousehold,
  GridFour,
  Pencil,
  ClockClockwise,
  LinkSimple,
  CurrencyDollarSimple,
  SmileySad,
} from '@phosphor-icons/react';

interface Props {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  weight?: IconWeight;
  dataCy?: string;
}

const Icon = ({
  name,
  size = 18,
  color = 'currentColor',
  className,
  weight = 'regular',
  dataCy = '',
}: Props) => {
  switch (name) {
    case 'arrows-horizontal':
      return (
        <ArrowsHorizontal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-line-down':
      return (
        <ArrowLineDown
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-line-up':
      return (
        <ArrowLineUp
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'archive':
      return (
        <Archive size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'arrow-clockwise':
      return (
        <ArrowClockwise
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'infinity':
      return (
        <Infinity
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'coins':
      return (
        <Coins size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'key':
      return (
        <Key size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'lock-key-open':
      return (
        <LockKeyOpen
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'magic-wand':
      return (
        <MagicWand
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'buildings':
      return (
        <Buildings
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'shield-star':
      return (
        <ShieldStar
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'map-pin':
      return (
        <MapPin size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'translate':
      return (
        <Translate
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'sliders':
      return (
        <Sliders size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'arrow-counter-clockwise':
      return (
        <ArrowCounterClockwise
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-down':
      return (
        <ArrowDown
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-down-up':
      return (
        <ArrowsDownUp
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-in-line-horizontal':
      return (
        <ArrowsInLineHorizontal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-in-line-vertical':
      return (
        <ArrowsInLineVertical
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-left':
      return (
        <ArrowLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-right':
      return (
        <ArrowRight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-left-right':
      return (
        <ArrowsLeftRight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-line-left':
      return (
        <ArrowLineLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-line-right':
      return (
        <ArrowLineRight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-in-simple':
      return (
        <ArrowsInSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-out-cardinal':
      return (
        <ArrowsOutCardinal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-out-simple':
      return (
        <ArrowsOutSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-outline-horizontal':
      return (
        <ArrowsOutLineHorizontal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-outline-vertical':
      return (
        <ArrowsOutLineVertical
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-merge':
      return (
        <ArrowsMerge
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrows-split':
      return (
        <ArrowsSplit
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-square-down':
      return (
        <ArrowSquareDown
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-square-out':
      return (
        <ArrowSquareOut
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'arrow-up':
      return (
        <ArrowUp size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'atom':
      return (
        <Atom size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'books':
      return (
        <Books size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'brackets-curly':
      return <BracketsCurly size={size} color={color} weight={weight} className={className} />;
    case 'broom':
      return (
        <Broom size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'cloud':
      return (
        <Cloud size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'copy':
      return (
        <Copy size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'code':
      return (
        <Code size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'calculator':
      return (
        <Calculator
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'calendar':
      return (
        <Calendar
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'calendar-blank':
      return (
        <CalendarBlank
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-down':
      return (
        <CaretDown
          size={size}
          color={color}
          weight={weight || 'fill'}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-down-regular':
      return (
        <CaretDown
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-double-down':
      return (
        <CaretDoubleDown
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-double-up':
      return (
        <CaretDoubleUp
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-circle-left':
      return (
        <CaretCircleLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-circle-right':
      return (
        <CaretCircleRight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'check-circle':
      return (
        <CheckCircle
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-left':
      return (
        <CaretLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-right':
      return (
        <CaretRight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'caret-up':
      return (
        <CaretUp size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'clipboard-text':
      return (
        <ClipboardText
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'chart-bar':
      return (
        <ChartBar
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'chat-dots':
      return (
        <ChatDots
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'chat-text':
      return (
        <ChatText
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );

    case 'check':
      return (
        <Check size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'clock':
      return (
        <Clock size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'clock-counter-clockwise':
      return (
        <ClockCounterClockwise
          size={size}
          color={color}
          weight='regular'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'command':
      return (
        <Command
          size={size}
          color={color}
          weight='regular'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'database':
      return (
        <Database
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'divide':
      return (
        <Divide size={size} color={color} weight='regular' className={className} data-cy={dataCy} />
      );
    case 'eraser':
      return (
        <Eraser size={size} color={color} weight='regular' className={className} data-cy={dataCy} />
      );
    case 'export':
      return (
        <DownloadSimple
          size={size}
          color={color}
          weight='regular'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'eye':
      return (
        <Eye size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'download-filled':
      return (
        <Download size={size} color={color} weight='fill' className={className} data-cy={dataCy} />
      );
    case 'dots-six':
      return (
        <DotsSix size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'dots-six-vertical':
      return (
        <DotsSixVertical
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'dots-three':
      return (
        <DotsThree
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'dots-three-vertical':
      return (
        <DotsThreeVertical
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'envelope-simple':
      return (
        <EnvelopeSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'file':
      return (
        <File size={size} color={color} weight='fill' className={className} data-cy={dataCy} />
      );
    case 'file-simple':
      return (
        <File size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'file-search':
      return (
        <FileSearch
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'file-text':
      return (
        <FileText
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'flag-banner':
      return (
        <FlagBanner
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'floppy-disk':
      return (
        <FloppyDisk
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder':
      return (
        <FolderSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder-filled':
      return (
        <FolderSimple
          size={size}
          color={color}
          weight='fill'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder-open':
      return (
        <FolderOpen
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder-plus':
      return (
        <FolderPlus
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder-simple-locked':
      return (
        <FolderSimpleLock
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'folder-user':
      return (
        <FolderSimpleUser
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'filter':
      return (
        <Funnel size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'gear':
      return (
        <Gear size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'git-merge':
      return (
        <GitMerge
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'globe':
      return (
        <Globe size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'graph':
      return (
        <Graph size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'grid-four':
      return (
        <GridFour
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'group':
      return (
        <SquaresFour
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'hand-heart':
      return (
        <HandHeart
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'hash':
      return (
        <Hash size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'hash-straight':
      return (
        <HashStraight
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'info':
      return (
        <Info size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'laptop':
      return (
        <Laptop size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'link':
      return (
        <Link size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'link-simple-horizontal':
      return (
        <LinkSimpleHorizontal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'list-ordered':
      return (
        <ListNumbers
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'lightbulb':
      return (
        <Lightbulb
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'lock':
      return (
        <Lock size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'lock-key':
      return (
        <LockKey size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'lock-simple':
      return (
        <LockSimple
          size={size}
          color={color}
          weight='fill'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'lock-simple-open':
      return (
        <LockSimpleOpen
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'magnifying-glass':
      return (
        <MagnifyingGlass
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'minus':
      return (
        <Minus size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'note':
      return (
        <Note size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'number-square-two':
      return (
        <NumberSquareTwo
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'paint-brush-household':
      return (
        <PaintBrushHousehold
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'paper-plane-right':
      return (
        <PaperPlaneRight
          size={size}
          color={color}
          weight='fill'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'paper-plane-tilt':
      return (
        <PaperPlaneTilt
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'pause':
      return (
        <Pause size={size} color={color} weight='fill' className={className} data-cy={dataCy} />
      );
    case 'pencil-simple':
      return (
        <PencilSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'pencil-simple-slash':
      return (
        <PencilSimpleSlash
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'play':
      return (
        <Play size={size} color={color} weight='fill' className={className} data-cy={dataCy} />
      );
    case 'plus':
      return (
        <Plus size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'plus-circle':
      return (
        <PlusCircle
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'prohibit':
      return (
        <Prohibit
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'question':
      return (
        <Question
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'robot':
      return (
        <Robot size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'rows':
      return (
        <Rows size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'scissors':
      return (
        <Scissors
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'seal-check':
      return (
        <SealCheck
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'settings':
      return (
        <SlidersHorizontal
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'sign-in':
      return (
        <SignIn size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'sign-out':
      return (
        <SignOut size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'sort-ascending':
      return (
        <SortAscending
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'sort-descending':
      return (
        <SortDescending
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'square-half':
      return (
        <SquareHalf
          size={size}
          color={color}
          weight='duotone'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'swap':
      return (
        <Swap size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'table':
      return (
        <Table size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'text-aa':
      return (
        <TextAa size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'text-t':
      return (
        <TextT size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'trash':
      return (
        <Trash size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'upload-simple':
      return (
        <UploadSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'user':
      return (
        <User size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'user-plus':
      return (
        <UserPlus
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'users':
      return (
        <Users size={size} color={color} weight='fill' className={className} data-cy={dataCy} />
      );
    case 'users-simple':
      return (
        <Users size={size} color={color} weight='regular' className={className} data-cy={dataCy} />
      );
    case 'users-three':
      return (
        <UsersThree
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'warning':
      return (
        <Warning size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'warning-circle':
      return (
        <WarningCircle
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'x':
      return <X size={size} color={color} weight={weight} className={className} data-cy={dataCy} />;
    case 'x-circle':
      return (
        <XCircle size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'smiley':
      return (
        <Smiley size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'smiley-x-eyes':
      return (
        <SmileyXEyes
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'smiley-meh':
      return (
        <SmileyMeh
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'smiley-sad':
      return (
        <SmileySad
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'columns':
      return (
        <Columns size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'sparkle':
      return (
        <Sparkle
          size={size}
          color={color}
          weight='regular'
          className={className}
          data-cy={dataCy}
        />
      );
    case 'list-checks':
      return (
        <ListChecks
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'map-pin-line':
      return (
        <MapPinLine
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'function':
      return (
        <Function
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'spinner-gap':
      return (
        <SpinnerGap
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'eye-slash':
      return (
        <EyeSlash
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'rocket-launch':
      return (
        <RocketLaunch
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'tree-structure':
      return (
        <TreeStructure
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'textbox':
      return (
        <Textbox size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'list':
      return (
        <List size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'chat-teardrop-text':
      return (
        <ChatTeardropText
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'video':
      return (
        <Video size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'gift':
      return (
        <Gift size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'arrow-u-down-left':
      return (
        <ArrowUDownLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'device-mobile':
      return (
        <DeviceMobile
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'chats':
      return (
        <Chats size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'arrow-bend-up-left':
      return (
        <ArrowBendUpLeft
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'webhooks-logo':
      return (
        <WebhooksLogo
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'pencil':
      return (
        <Pencil size={size} color={color} weight={weight} className={className} data-cy={dataCy} />
      );
    case 'clock-clockwise':
      return (
        <ClockClockwise
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'link-simple':
      return (
        <LinkSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );
    case 'currency-dollar-simple':
      return (
        <CurrencyDollarSimple
          size={size}
          color={color}
          weight={weight}
          className={className}
          data-cy={dataCy}
        />
      );

    default:
      return null;
  }
};

export default Icon;
