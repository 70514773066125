import withModal from '@/Components/Modals/withModalHOC';
import {
  MODAL_DELETE_COLUMNS,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import { handleDeleteColumns } from '@/helpers/spreadsheetHelper';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '@/redux/reducers/modalsSlice';
import { userDataSliceName } from '@/redux/constants';

const DeleteColumns = ({ show, hideModal, columnsToDelete = '', columnsToDeleteList }) => {
  const currentFile = useCurrentFile();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const dispatch = useDispatch();
  const deleteColumns = () => {
    handleDeleteColumns(columnsToDelete, columnsToDeleteList);
  };

  const save = () => {
    hideModal();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    deleteColumns();
  };

  return (
    <Modal title='Are you sure?' isOpen={show} onClose={hideModal}>
      <div className='mb-4'>
        This will affect all <b>Views</b> created for this sheet. This action is permanent and
        cannot be undone.{' '}
      </div>
      <div className='flex justify-end gap-4'>
        <Button color='shadow' variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button dataCy='save-btn' color='oceanBlue' onClick={save}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_COLUMNS })(DeleteColumns);
