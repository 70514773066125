import Icon from '../../../Components/UI/Icon/Icon';
import { useEffect, useState } from 'react';
import { convertStringToMarkdownList } from '../../../Utils/convertStringToMarkdownList';
import { getTypeIconPath } from '../../../Utils/getTypeIconPath';

import SplitView from '../../../Components/SplitView/SplitView';
import ItemValue from '../../../Components/Spreadsheet/ViewRowData/ItemValue';
import TextClipper from '@/Components/TextClipper/TextClipper';

const ItemIcon = ({ column }) => {
  return (
    <div className='view-row-data-item-icon'>
      <Icon name={getTypeIconPath(column.fieldType)} size={18} />
      <TextClipper
        text={column.headerName || column.colId}
        className='ml-2 uppercase text-ui-helper'
      />
    </div>
  );
};

const ViewRowData = ({ rowData, columnInfo }) => {
  const [detailPanelWidth, setDetailPanelWidth] = useState();

  const getCellValue = (column) => {
    if (!rowData || !column) return '';
    const cellValue = rowData[column.colId] === 'null' ? '' : rowData[column.colId];

    if (column.fieldType === 'MarkdownString') {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: convertStringToMarkdownList(cellValue),
          }}
        />
      );
    }

    return cellValue;
  };

  useEffect(() => {
    const detailsWrapper = document.querySelector('.ag-tool-panel-wrapper:not(.ag-hidden)');
    if (!detailsWrapper) return;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const { target, attributeName } = mutation;
        if (target === detailsWrapper && attributeName === 'style') {
          setDetailPanelWidth(target.clientWidth);
        }
      });
    });

    observer.observe(detailsWrapper, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return () => observer.disconnect();
  });

  return (
    <div className='view-row-data-container'>
      {columnInfo?.map((column) => {
        return (
          <div className='view-row-data-item' key={column.colId}>
            <SplitView
              leftPane={<ItemIcon column={column} />}
              rightPane={<ItemValue value={getCellValue(column)} />}
              containerWidth={detailPanelWidth}
              additionalClassNames={{ rightPane: 'row-details-panel-right-pane' }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ViewRowData;
