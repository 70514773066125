// The order of the connectors in this array will determine the order in which they are displayed in the UI. They are ordered by popularity with the most popular connectors at the top.

export const cloudConnectors = [
  {
    id: 'google-drive',
    name: 'Google Drive',
    iconName: 'Logo_GoogleDrive',
    uppyConnectorName: 'GoogleDrive',
  },
  { id: 'dropbox', name: 'DropBox', iconName: 'Logo_Dropbox', uppyConnectorName: 'Dropbox' },
  { id: 'onedrive', name: 'OneDrive', iconName: 'Logo_OneDrive', uppyConnectorName: 'OneDrive' },
  { id: 'aws-s3', name: 'AWS S3', iconName: 'AmazonS3Logo', uppyConnectorName: 'AWS-S3' },
  { id: 'box', name: 'Box', iconName: 'Logo_Box', uppyConnectorName: 'Box' },
];
