import clsx from 'clsx';

type Props = {
  text: string | React.ReactNode;
  className?: string;
  dataCy?: string;
};

const Label = ({ text, className, dataCy }: Props) => {
  return (
    <label
      data-cy={dataCy ? `label-${dataCy}` : ''}
      className={clsx('h-5 items-center text-sm w-fit flex text-ui font-semibold', className)}
    >
      {text}
    </label>
  );
};

export default Label;
