import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import showToast from '@/Components/Toast/showToastTemplate';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { formatEnrichmentElements } from '@/helpers/enrichmentHelper';

import { post } from '@/Utils/API';
import {
  MODAL_CUSTOM_ENRICHMENT,
  MODAL_DATA_ENRICHMENT,
  MODAL_ENRICHMENT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_ENRICHMENT_ERROR,
  TOAST_TEXT_ENRICHMENT_RUNNING_ERROR,
  TOAST_TEXT_INCORRECT_API_KEY_ERROR,
  TOAST_TEXT_QUOTA_EXCEEDED_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import IPEnrichments from './IPEnrichments';
import Modal from '@/Components/UI/Modal/Modal';
import EmailEnrichments from './EmailEnrichments';
import Label from '@/Components/UI/Form/Label/Label';
import withModal from '@/Components/Modals/withModalHOC';
import { Button, Link } from '@/Components/UI/Button/Button';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import RadioInput from '@/Components/UI/Form/RadioInput/RadioInput';

import './style/index.scss';
import { StatusCodes } from 'http-status-codes';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const EnrichmentModal = ({ show: isOpen, hideModal: closeModal, targetColumnId, enrichType }) => {
  const dispatch = useDispatch();
  const {
    columnDefs,
    clientState: { filterModel, sortModel },
  } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();

  const options = useMemo(() => {
    return columnDefs
      .map((colDef) => ({ label: colDef.headerName, value: colDef.field, type: colDef.fieldType }))
      .filter(({ type }) => type === 'IPv4' || type === 'String' || type === 'EmailAddress');
  }, [columnDefs]);

  const [targetColumn, setTargetColumn] = useState(null);
  const [enrichments, setEnrichments] = useState([]);

  const [savedApiKeys, setSavedApiKeys] = useState({
    greynoise: '',
    recordedfuture: '',
    virustotal: '',
    emailabstractapi: '',
  });
  const [targetOptions, setTargetOptions] = useState([]);
  const [enrichmentType, setEnrichmentType] = useState('EMAIL');

  const handleEnrichmentTypeChange = (event) => {
    setEnrichmentType(event.target.value);
  };

  useEffect(() => {
    if (enrichType) {
      return setEnrichmentType(enrichType);
    } else {
      setEnrichmentType('EMAIL');
    }
  }, [enrichType]);

  const onSelectEnrichment = (event) => {
    const enrichmentServiceName = event.target.value;
    const indexOfEnrichmentService = enrichments.findIndex(
      (enrichment) => enrichment.provider === enrichmentServiceName
    );

    if (indexOfEnrichmentService === -1) {
      return setEnrichments([
        ...enrichments,
        {
          provider: enrichmentServiceName,
          type: enrichmentType,
        },
      ]);
    }
    enrichments.splice(indexOfEnrichmentService, 1);
    setEnrichments([...enrichments]);
  };

  const onChangeTargetColumn = (newTargetColumn) => {
    setTargetColumn(newTargetColumn);
  };

  const onInputApiKey = (event) => {
    const apiKey = event.target.value;
    const inputName = event.target.name;

    setSavedApiKeys((state) => ({ ...state, [inputName]: apiKey }));
  };

  const onChangeEnrichmentType = (newEnrichment) => {
    setEnrichmentType(newEnrichment);
    setEnrichments([]);
    setTargetColumn(null);
    setSavedApiKeys({
      greynoise: '',
      recordedfuture: '',
      virustotal: '',
      emailabstractapi: '',
    });
  };

  const enrich = async () => {
    closeModal();
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    const uuid = getFileUuidFromPath();
    const _enrichments = enrichments.map((enrichment) => {
      const mapKey = enrichment.provider;

      return {
        ...enrichment,
        key: savedApiKeys[mapKey] || null,
      };
    });

    const payload = {
      filterModel: { filterModel: filterModel || null },
      enrichments: _enrichments,
    };

    const enrichmentTypes = enrichments?.reduce((acc, item) => acc + item.provider + ' ', '');

    if (enrichmentTypes) {
      dispatch(
        addMixpanelEvent({
          eventName: 'Enrichment Used',
          eventProps: { 'Enrichment Types': enrichmentTypes },
          userIncrementName: '# of enrichment use',
        })
      );
    }

    let endpoint = '';
    try {
      endpoint = `enrichments/${uuid}/${targetColumn?.value}`;
      const enrichmentResult = await post(endpoint, payload);
      dispatch(fetchOpenedFile(uuid));
      if (!enrichmentResult.Success) {
        if (enrichmentResult.Message.includes(StatusCodes.FORBIDDEN)) {
          showToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_INCORRECT_API_KEY_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        } else if (enrichmentResult.Message.includes('429')) {
          showToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_QUOTA_EXCEEDED_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        } else {
          showToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_ENRICHMENT_RUNNING_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
        throw new Error(enrichmentResult.Error);
      }
    } catch (err) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_ENRICHMENT_ERROR,
        errorContext: err,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      throw new Error(err);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
      closeModalAndResetType();
    }
  };

  const closeModalAndResetType = () => {
    onChangeEnrichmentType('EMAIL');
    dispatch(hideAllModals());
  };

  const isSaveBtnDisabled =
    !targetColumn ||
    !enrichments.length ||
    !!savedApiKeys.emailabstractapi?.match(/\W/g) ||
    (enrichments?.map((i) => i.provider)?.includes('email-abstract-api') &&
      savedApiKeys.emailabstractapi?.length !== 32);

  useEffect(() => {
    const newTargetOptions = options.filter(({ type }) =>
      enrichmentType === 'IP' ? type === 'IPv4' : type !== 'IPv4'
    );

    setTargetOptions(newTargetOptions);
  }, [options, enrichmentType]);

  useEffect(() => {
    if (enrichmentType === 'CUSTOM') {
      dispatch(hideAllModals());
      onChangeEnrichmentType('EMAIL');
      dispatch(showModal({ name: MODAL_CUSTOM_ENRICHMENT, props: { filterModel, sortModel } }));
    }
  }, [dispatch, enrichmentType]);

  useEffect(() => {
    if (isOpen) {
      const _targetColumn = targetOptions.find((option) => option.value === targetColumnId) || null;
      onChangeTargetColumn(_targetColumn);
    }
  }, [isOpen, targetColumnId, targetOptions]);

  const noStringColumns =
    options.filter(({ type }) => type === 'String' || type === 'EmailAddress').length === 0;

  const propsToChildrenEnrichments = {
    onSelectEnrichment: onSelectEnrichment,
    enrichments: enrichments,
    savedApiKeys: savedApiKeys,
    onInputApiKey: onInputApiKey,
  };
  const propsToEmailEnrichment = { ...propsToChildrenEnrichments, noStringColumns };

  return (
    <Modal
      title='Data Enrichments'
      iconName='atom'
      size='small'
      isOpen={isOpen}
      onClose={closeModalAndResetType}
      isSaveBtnDisabled={isSaveBtnDisabled}
      articleID={69000774345}
      shouldCloseOnBackgroundClick={false}
    >
      <>
        <div className='enrichment-container'>
          <>
            {targetColumnId && (
              <RadioInput
                value={enrichmentType}
                label='Enrichment Type'
                helperText='Only applies to current filtered rows'
                color='capri'
                onChange={handleEnrichmentTypeChange}
                options={formatEnrichmentElements(enrichmentType, noStringColumns, options)}
              />
            )}

            <Link
              to='mailto:support@gigasheet.com?subject=How to I run more than 100 rows?'
              iconName='info'
              target='_blank'
              className='mb-4 ml-6'
              size='micro'
            >
              1000 row limit. Contact support for full access.
            </Link>
          </>

          <ToastContainer />

          <>
            <Label text='Choose enrichment column' />
            <SearchSelect
              options={targetOptions}
              onChange={onChangeTargetColumn}
              isSmall={true}
              additionalClasses='mb-4'
              defaultValue={targetColumn}
            />

            <Label text='Service Options' />
          </>

          {enrichmentType === 'EMAIL' && <EmailEnrichments {...propsToEmailEnrichment} />}
          {enrichmentType === 'IP' && <IPEnrichments {...propsToChildrenEnrichments} />}
        </div>

        <div className='p-4 text-right border-t border-ui-200'>
          <Button
            color='shadow'
            dataCy='reset-field-btn'
            className='float-left'
            onClick={() => {
              closeModal();
              dispatch(showModal({ name: MODAL_DATA_ENRICHMENT }));
            }}
          >
            Back
          </Button>

          <Button
            variant='ghost'
            color='shadow'
            dataCy='cancel-btn'
            className='mr-[16px]'
            onClick={closeModalAndResetType}
          >
            Cancel
          </Button>

          <Button
            color={'oceanBlue'}
            dataCy='save-btn'
            onClick={enrich}
            disabled={isSaveBtnDisabled}
          >
            Apply
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default withModal({ name: MODAL_ENRICHMENT })(EnrichmentModal);
