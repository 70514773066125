import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import PasswordInput from '@/Components/UI/Form/PasswordInput/PasswordInput';
import { Collapsible, CollapsibleContent } from '@/Components/UI/Shadcn/Collapsible';
import Icon from '@/Components/UI/Icon/Icon';

const EmailEnrichments = ({
  onSelectEnrichment,
  noStringColumns,
  enrichments,
  savedApiKeys,
  onInputApiKey,
}) => {
  return (
    <div className='enrichment-services'>
      <div className='enrichment-service-row'>
        <CheckboxInput
          name='email-format-check'
          label='Email Format Check'
          data-cy='email-format-check'
          onChange={onSelectEnrichment}
          disabled={noStringColumns}
        />
      </div>
      <div className='enrichment-service-row'>
        <Collapsible open={enrichments.some((item) => item.provider === 'emailabstractapi')}>
          <CheckboxInput
            name='email-abstract-api'
            label='Abstract API Email Verification'
            onChange={onSelectEnrichment}
            disabled={noStringColumns}
          />
          <CollapsibleContent>
            <PasswordInput
              type='password'
              name='emailabstractapi'
              placeholder='Abstract API Key'
              value={savedApiKeys['emailabstractapi']}
              onChange={onInputApiKey}
            />
            <span>
              <a
                href='https://www.abstractapi.com/api/email-verification-validation-api#pricing'
                target='_blank'
                rel='noopener noreferrer'
              >
                How do I get an Abstract API key?
                <Icon name='info' size={14} alt='info'></Icon>
              </a>
            </span>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  );
};

export default EmailEnrichments;
