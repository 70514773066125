import { Button } from '@/Components/UI/Button/Button';
import SpinnerSolid from '@/Components/UI/Spinner/SpinnerSolid';
import { FILE_STATUS_LOADING, FILE_STATUS_UPLOADING } from '@/Utils/fileConstants';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

const FileStatus = ({ row = {}, cancelUpload = () => {} }) => {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [stopOnHover, setStopOnHover] = useState(false);

  const { StatusDetails, FileName, Status } = row;
  const { message: providedMessage } = StatusDetails || {};

  let formattedStatus;
  switch (Status) {
    case FILE_STATUS_LOADING:
      formattedStatus = 'Queued for processing...';
      break;
    case FILE_STATUS_UPLOADING:
      formattedStatus = 'Importing...'; // should only show during Connector imports
      break;
    default:
      formattedStatus = 'Processing...';
  }

  const messageOptions = [FileName, providedMessage ? providedMessage : formattedStatus];

  useEffect(() => {
    const interval = setInterval(() => {
      if (stopOnHover) {
        return;
      }

      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % messageOptions.length);
        setFade(true);
      }, 400);
    }, 4000);

    return () => clearInterval(interval);
  }, [stopOnHover]);

  return (
    <div className='flex flex-row items-center gap-2 ml-[73px] text-sm align-middle file-status-bar border-ui-200'>
      <SpinnerSolid size='medium' />
      <div
        className={clsx(
          'flex flex-row font-medium animate-file-loading-message transition-opacity duration-500',
          fade ? 'opacity-100' : 'opacity-0',
          index
        )}
        onMouseOver={() => setStopOnHover(true)}
        onFocus={() => setStopOnHover(true)}
        onMouseLeave={() => setStopOnHover(false)}
      >
        {messageOptions[index]}
      </div>

      <div className='absolute font-medium right-[10px]'>
        <Button
          onClick={() => cancelUpload(row)}
          iconName='x'
          variant='ghost'
          size='small'
          className='p-5 !rounded-full'
        />
      </div>
    </div>
  );
};

export default FileStatus;
