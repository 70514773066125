import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { CustomCalendarHeader } from './CalendarHeader';

import './style/index.scss';

const CustomDatePicker = ({
  selected,
  onChange,
  onYearChange = () => {},
  id,
  value,
  isSelectStart,
  isSelectEnd,
  startDate,
  endDate,
  onFocus,
  onBlur,
  onClose,
  monthsShown,
  popperPlacement,
  className,
  disabled,
  portalId,
}) => {
  const calendarHeader = ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth }) => (
    <CustomCalendarHeader
      date={date}
      changeYear={changeYear}
      changeMonth={changeMonth}
      decreaseMonth={decreaseMonth}
      increaseMonth={increaseMonth}
    />
  );

  return (
    <DatePicker
      className={className}
      id={id}
      selected={selected}
      onChange={onChange}
      value={value}
      selectsStart={isSelectStart}
      selectsEnd={isSelectEnd}
      startDate={startDate}
      endDate={endDate}
      onYearChange={onYearChange}
      onCalendarClose={onClose}
      placeholderText='yyyy.mm.dd, hh:mm:ss'
      dateFormat='yyyy.MM.dd, HH:mm:ss'
      timeInputLabel='Set Hours:'
      monthsShown={monthsShown}
      shouldCloseOnSelect={false}
      popperPlacement={popperPlacement}
      disabledKeyboardNavigation
      showTimeInput
      renderCustomHeader={calendarHeader}
      onFocus={onFocus}
      onBlur={onBlur}
      portalId={portalId}
      disabled={disabled}
    />
  );
};

export default CustomDatePicker;
