export const formatEnrichmentElements = (enrichment, noStringColumns, options) => {
  return [
    {
      value: 'EMAIL',
      dataCy: 'email',
      label: 'Email',
      disabled: noStringColumns,
    },
    {
      value: 'IP',
      dataCy: 'ip',
      label: 'IP Address',
      disabled: options.filter((option) => option.type === 'IPv4').length === 0,
    },
    {
      value: 'CUSTOM',
      dataCy: 'custom',
      label: 'Custom Enrichment (cURL)',
      disabled: noStringColumns,
    },
  ];
};

export const stripQuotes = (string) => {
  return string.replace(/(^['"]+)|(['"]+$)/g, '');
};

export const parseCurlCommandToJSON = (curlCommand) => {
  const json = {};

  const methodMatch = curlCommand.match(/(?:-X\s+([A-Z]+)|-X([A-Z]+)|--request\s+([A-Z]+))/);
  const hasGetMethod = curlCommand.match(/(?:-G\s|--get\s)/);
  const data = /(?:--data-?[\w]*|-d|-binary) (?:\$)?(?:'|"|`)?([^\\]*)(?:'|"|`)?/g.exec(
    curlCommand.replaceAll("'\\''", "'''").replaceAll('\\"', '"')
  );

  const dataItems =
    data &&
    data[0]
      .split(/(?=-d )/)
      .map((item) => item.trim())
      .filter(Boolean);
  if (methodMatch) {
    json.method = methodMatch[1] || methodMatch[2] || methodMatch[3] || undefined;
  } else if (hasGetMethod) {
    json.method = 'GET';
  } else if (data && data[0]) {
    json.method = 'POST';
  } else {
    json.method = 'GET';
  }

  const url = curlCommand
    .replaceAll(/[<>]/g, '')
    .match(
      /(?:--url\s)?(?:'|"|`)?(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=\[\],]*))(?:'|"|`)?/
    );
  if (url) {
    const [, objUrl] = url;
    json.url = objUrl;
  }

  const headers = curlCommand.match(/(-H|--header) (?:'|"|`)([^'"]+)(?:'|"|`)/g);
  const basicAuthenticationMatch = /-u ['|"|`]?([^'"]+)['|"|`]?/g.exec(curlCommand);
  if (headers) {
    json.headers = headers.map((header) => {
      const matches = header.match(/(?:-H|--header) (?:'|"|`)([\w\/\s-]*):\s*(.+)(?:'|"|`)/);
      if (matches) {
        return { name: stripQuotes(matches[1]), value: stripQuotes(matches[2]) };
      }
    });
  }

  if (basicAuthenticationMatch && basicAuthenticationMatch[1]) {
    const basicAuthentication = {
      name: 'Authorization',
      value: 'Basic ' + window.btoa(basicAuthenticationMatch[1]),
    };
    if (json.headers?.length) {
      json.headers.push(basicAuthentication);
    } else {
      json.headers = [basicAuthentication];
    }
  }

  if (data && data[1]) {
    if (dataItems.length === 1) {
      json.data = data[1].trim().replace(/(['"`])$/, '');
    } else {
      if (json.method === 'GET') {
        json.url += `?${dataItems
          .map((dataItem) => {
            const match =
              /(?:-d|-binary|--data-?[\w]*)\s+(?:\$)?(?:'|"|`)?([^"]+)(?:'|"|`)?=(?:'|"|`)?([^"]+)(?:'|"|`)?/g
                .exec(dataItem)
                .slice(1);
            return `${match[0]}=${match[1]}`;
          })
          .join('&')}`;
      }
    }
  }

  return json;
};

export const formatCurlJSONtoString = (curlJSON) => {
  let formattedJSON = '';
  formattedJSON += 'Method: ' + curlJSON.method + '\n';
  formattedJSON += 'Base URL: ' + curlJSON.url + '\n';

  if (curlJSON.headers) {
    formattedJSON += 'Headers: \n';
    curlJSON.headers.forEach((header) => {
      formattedJSON += "        '" + header.name + ': ' + header.value + "'\n";
    });
  }

  if (curlJSON.data) {
    formattedJSON += 'Body:\n        "' + curlJSON.data + '"\n';
  }
  return formattedJSON;
};

export const parseFormattedCurlToJSON = (formattedCurl) => {
  const requestSplitIntoLines = formattedCurl
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line !== '');
  let method,
    url,
    data = '';

  const dataIndex = requestSplitIntoLines.findIndex((line) => line === 'Body:');

  if (requestSplitIntoLines[0].startsWith('Method')) {
    const [, destructedMethod] = requestSplitIntoLines[0].split(': ');
    method = destructedMethod;
  } else {
    throw new Error('Invalid formatted cURL: No Method specified');
  }

  if (requestSplitIntoLines[1].startsWith('Base URL')) {
    const [, destructedUrl] = requestSplitIntoLines[1].split(': ');
    url = destructedUrl;
  } else {
    throw new Error('Invalid formatted cURL: No Base URL specified');
  }

  const headers = {};
  if (requestSplitIntoLines[2] === 'Headers:') {
    let headersArray = [];
    if (dataIndex > -1) {
      headersArray = requestSplitIntoLines.slice(3, dataIndex);
    } else {
      headersArray = requestSplitIntoLines.slice(3, requestSplitIntoLines.length);
    }
    headersArray.forEach((headerLine) => {
      const [name, value] = stripQuotes(headerLine).split(': ');
      if (headers[name]) {
        headers[name].push(value);
      } else {
        headers[name] = [value];
      }
    });
  }

  if (dataIndex > -1) {
    data = stripQuotes(
      requestSplitIntoLines.slice(dataIndex + 1, requestSplitIntoLines.length).join('')
    );
  }

  return {
    method,
    url_template: url,
    headers,
    body_template: data,
  };
};

export const transformResponseDataToTableFormat = (responseDataLists) => {
  return responseDataLists.map((responseDataList) => {
    const responseDataItem = {};
    responseDataList.forEach((item, index) => {
      responseDataItem[item.path.join('/')] = {
        value: item.value,
        fieldNamePath: item.path,
        id: index,
      };
    });

    return responseDataItem;
  });
};

export const getSelectedFieldNames = (selectedIndexes, data) => {
  const selectedFieldNames = [];
  Object.entries(selectedIndexes).forEach(([index, selected]) => {
    if (selected) {
      selectedFieldNames.push(data[parseInt(index, 10)]);
    }
  });

  return selectedFieldNames;
};

export const getSelectedFieldNamesFromIndex = (selectedIndexes, data) => {
  const selectedFieldNames = [];

  selectedIndexes.forEach((index) => {
    const targetElement = data.find((el) => index === el[1].id);
    selectedFieldNames.push(targetElement);
  });

  return selectedFieldNames;
};

export const swapLabelForValue = (curlRequestText, targetColumn) => {
  return curlRequestText.replace(`****${targetColumn.label}****`, `****${targetColumn.value}****`);
};

export const getDefaultCheckedState = (responseData, defaultNumberOfCheckedColumns) => {
  return !responseData || !responseData.length
    ? []
    : Object.entries(responseData[0]).reduce((acc, el) => {
        if (el[1].id < defaultNumberOfCheckedColumns) {
          return [...acc, el[1].id];
        }
        return acc;
      }, []);
};
