const shieldSymbols = (stringValue) => {
  const backslash = 92;
  const singleQuote = 39;
  const shieldedStringValue = stringValue
    .split('')
    .map((symbol, index) => {
      if (stringValue.charCodeAt(index) === backslash) {
        return symbol;
      }
      if (stringValue.charCodeAt(index) === singleQuote) {
        return `\\${symbol}`;
      }
      return symbol;
    })
    .join('');

  return shieldedStringValue;
};

export default shieldSymbols;
