import { SUPPORT_TIPS_FOR_LOADER } from '@/Utils/constants';
import { useEffect, useState } from 'react';
import SpinnerSolid from '../UI/Spinner/SpinnerSolid';

const LoadingOverlay = () => {
  const [index, setIndex] = useState(() =>
    Math.floor(Math.random() * SUPPORT_TIPS_FOR_LOADER.length)
  );
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % SUPPORT_TIPS_FOR_LOADER.length);
        setFade(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex items-center justify-center min-h-screen p-5 bg-white min-w-screen'>
      <div className='flex flex-col justify-center gap-3 text-center'>
        <div className='mb-6 h-9 content-logoSimple' />
        <div className='text-sm text-ui-helper'>Gigasheet 101</div>
        <div
          className={`h-16 text-xl font-bold leading-tight text-center w-[500px] text-ui-secondary transition-opacity duration-500 ${
            fade ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {SUPPORT_TIPS_FOR_LOADER[index]}
        </div>
        <div className='flex justify-center w-full'>
          <SpinnerSolid size='large' />
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
