import withModal from '@/Components/Modals/withModalHOC';

import { IllustrationSVG } from '@/Assets/icons';

import Icon from '@/Components/UI/Icon/Icon';

import { LINK_CREATE_ACCOUNT, LINK_PRICING, MODAL_CREATE_ACCOUNT } from '@/Utils/constants';

import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

const navigateToCreateAccount = () => {
  window.open(LINK_CREATE_ACCOUNT, '_blank');
};

const CreateAccountModal = ({ show, hideModal }) => {
  const bodyComponent = (
    <>
      <ul className='p-0 m-0 text-sm leading-9 list-none text-shadow-700'>
        <li className='flex items-center gap-4'>
          <Icon name='check-circle' color='#463AD4' size={24} /> Share and collaborate
        </li>
        <li className='flex items-center gap-4'>
          <Icon name='check-circle' color='#463AD4' size={24} />
          No credit card
        </li>
        <li className='flex items-center gap-4'>
          <Icon name='check-circle' color='#463AD4' size={24} />
          Export Sheets
        </li>
        <li className='flex items-center gap-4'>
          <Icon name='check-circle' color='#463AD4' size={24} />
          1GB Storage
        </li>
      </ul>
      <div>
        <div className='flex justify-center m-2 align-middle'>
          <Button color='sunrise' dataCy='bar-create-account' onClick={navigateToCreateAccount}>
            Sign Up
          </Button>
        </div>
        <span className='flex justify-center gap-1 pt-2 text-xs'>
          Looking for more?
          <a
            href={LINK_PRICING}
            className='!text-midnight-500 !no-underline'
            target='_blank'
            rel='noreferrer'
          >
            Check out our plans.
          </a>
        </span>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={show}
      onClose={() => hideModal()}
      titleSize='large'
      size='x-small'
      title='Sign up for free'
      titlePosition='center'
      dataCy='popup-create-account'
    >
      <div className='popup-create-account'>
        <img
          src={IllustrationSVG.CreateAccountIllustration}
          alt='create-account'
          className='mb-4'
        />
        {bodyComponent}
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CREATE_ACCOUNT })(CreateAccountModal);
