import { toastSVG } from '../../Assets/icons';

import './index.scss';

const Toast = ({ closeToast, type, children }) => {
  const getToastAppearance = () => {
    switch (type) {
      case 'info':
        return { icon: toastSVG.ToastInfo, alt: 'info', color: 'blue' };
      case 'success':
        return { icon: toastSVG.ToastSuccess, alt: 'success', color: 'green' };
      case 'error':
        return { icon: toastSVG.ToastError, alt: 'error', color: 'violet' };
      case 'reconnection':
        return { icon: toastSVG.ToastReconnection, alt: 'reconnection', color: 'orange' };
      default:
        return { icon: toastSVG.ToastInfo, alt: 'info', color: 'blue' };
    }
  };
  return (
    <div className={`toast-container toast-container-${getToastAppearance().color}`}>
      <img src={getToastAppearance().icon} alt={getToastAppearance().icon} />
      {children}
      <button onClick={closeToast} className='toast-container-close'>
        <img src={toastSVG.ToastClose} alt='close' />
      </button>
    </div>
  );
};

export default Toast;
