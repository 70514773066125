import { connectorsSVG } from '@/Assets/HotGlue_Icons_SVG';
import withModal from '@/Components/Modals/withModalHOC';
import withUploadModal from '@/Components/Modals/withUploadModal';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { Button, Link } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import { get } from '@/Utils/API';
import {
  CONNECTORS_BUSINESS,
  LINK_BUSINESS_CALENDLY,
  LINK_ENTERPRISE_CALENDLY,
  MODAL_GENERAL_UPGRADE,
  MODAL_IMPORT_FROM_CONNECTOR,
  MODAL_SUGGEST_AN_INTEGRATION,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { setShowLoadingState } from '@/redux/reducers/sharedSlice';
import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ConnectorCard = ({
  dataCy,
  connectorName,
  iconName,
  onConnectorSelect,
  isConnectorEnabled,
  disableAllNexusConnectors,
}) => {
  const cardClassName = `
    flex flex-col h-[72px] justify-center rounded-xl border border-gray-300 px-4 my-2 mx-1
    flex-grow-0 flex-shrink-0 basis-[calc(50%-0.5rem)]`;
  const iconSrc = connectorsSVG[iconName] || connectorsSVG.NoIconConnector;

  return (
    <div
      data-cy={dataCy}
      className={clsx(
        cardClassName,
        disableAllNexusConnectors && 'border-ui-200 text-ui-helper bg-ui-secondary'
      )}
      role='button'
      tabIndex='0'
      onClick={onConnectorSelect}
      onKeyDown={(e) => e.key === 'Enter' && onConnectorSelect()}
    >
      {iconName && (
        <div className='flex items-center'>
          <img src={iconSrc} alt={`${connectorName} logo`} width={40} height={40} />
          <div className='w-[136px] mx-4 text-sm font-semibold'>{connectorName}</div>

          {!isConnectorEnabled(connectorName) && !disableAllNexusConnectors ? (
            <div className='flex items-center h-4 px-2 uppercase rounded-full w-fit bg-sunrise-600 text-[10px] font-extrabold mb-10'>
              Enterprise
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const ImportFromConnector = ({
  title,
  show,
  hideModal,
  openUploadModal,
  connectors: connectors = [],
}) => {
  const [connectorSubItems, setConnectorSubItems] = useState([]);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const navigate = useNavigate();

  const {
    userProperties: { canAccessBusinessConnectors, canAccessEnterpriseConnectors },
  } = user;

  const disableAllNexusConnectors =
    !canAccessBusinessConnectors &&
    !canAccessEnterpriseConnectors &&
    connectors.find((i) => i?.isNexusConnector);

  const isConnectorEnabled = (connector) => {
    let isEnabled = false;

    if (!connectors.find((c) => c.isNexusConnector)) {
      // Enable all non-nexus connectors
      return true;
    }

    if (canAccessEnterpriseConnectors) {
      // Enable all nexus connectors for enterprise tier
      isEnabled = true;
    } else if (canAccessBusinessConnectors && CONNECTORS_BUSINESS.includes(connector)) {
      // Disable some nexus connectors for business tier
      isEnabled = true;
    } else if (!canAccessBusinessConnectors && !canAccessEnterpriseConnectors) {
      // Disable all nexus connectors for premium and below
      isEnabled = false;
    }

    return isEnabled;
  };

  const triggerConnector = (connector) => {
    if (
      connector.hasOwnProperty('subItems') &&
      Array.isArray(connector.subItems) &&
      connector.subItems.length
    ) {
      setConnectorSubItems(connector.subItems);
      return;
    }

    if (connector.isNexusConnector) {
      if (!isConnectorEnabled(connector.name)) {
        dispatch(showModal({ name: MODAL_GENERAL_UPGRADE }));
      } else {
        triggerNexusConnector(connector.nexusConnectorName);
      }
    } else {
      triggerUppyConnector(connector.uppyConnectorName);
    }
  };

  const getConnectorName = (name) => {
    if (name === 'databricksDirect') return 'databricks';
    return name;
  };

  const triggerNexusConnector = async (connectorName) => {
    dispatch(setShowLoadingState(true));
    let endpoint = '';
    try {
      endpoint = `connectors/sources/params/${getConnectorName(connectorName)}`;
      const response = await get(endpoint);

      if (Object.prototype.hasOwnProperty.call(response, 'Success') && !response.Success) {
        ShowToast({
          type: TOAST_TYPE_ERROR,
          text: response?.Message || TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
          endpoint: endpoint,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
        return dispatch(setShowLoadingState(false));
      }

      if (Object.prototype.hasOwnProperty.call(response, 'ConsentURL') && response.ConsentURL) {
        window.location.href = response.ConsentURL;
      } else {
        dispatch(hideAllModals());
        navigate(`/nexus?c=${connectorName}`);
      }
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      dispatch(setShowLoadingState(false));
    }
  };

  const getUppyConnectorElementByName = (connectorName) => {
    if (!connectorName) return;
    return document.querySelector(`[data-uppy-acquirer-id="${connectorName}"] > button`);
  };

  const triggerUppyConnector = (uppyConnectorName) => {
    openUploadModal();
    getUppyConnectorElementByName(uppyConnectorName).click();
  };

  const onClickSuggestAnIntegrationBtn = () => {
    dispatch(showModal({ name: MODAL_SUGGEST_AN_INTEGRATION }));
  };

  return (
    <Modal
      title={title}
      size='medium'
      isOpen={show}
      onClose={hideModal}
      childrenCss='m-0 p-0'
      titleSize='small'
    >
      {disableAllNexusConnectors ? (
        <div className='w-full rounded bg-capri-100 border border-capri-900 bg-opacity-50 flex flex-row gap-1 text-sm px-1 py-2'>
          <Icon name='info' color='#0079B6' size={18} className='w-10' />
          <div className='flex flex-col gap-1'>
            <div className='font-semibold' data-cy='connectors-paywall-banner'>
              Import from Platforms with a Business Plan
            </div>
            <div>
              <Link
                className='!text-ui underline'
                size='medium'
                to={LINK_BUSINESS_CALENDLY}
                target='__blank'
              >
                Upgrade to Business
              </Link>{' '}
              to import from select SaaS platforms and databases.{' '}
              <Link
                className='!text-ui underline'
                size='medium'
                to={LINK_ENTERPRISE_CALENDLY}
                target='__blank'
              >
                Contact sales about Enterprise
              </Link>{' '}
              for access to Salesforce, Databricks and Snowflake.
            </div>
          </div>
        </div>
      ) : null}
      <div className='flex flex-wrap justify-start content-start	mb-3 min-h-[352px]'>
        {(connectorSubItems.length ? connectorSubItems : connectors).map((connector) => (
          <ConnectorCard
            key={connector.id}
            dataCy={`${connector.id}-connector-card`}
            connectorName={connector.name}
            iconName={connector.iconName}
            onConnectorSelect={() => triggerConnector(connector)}
            isConnectorEnabled={(connector) => isConnectorEnabled(connector)}
            disableAllNexusConnectors={disableAllNexusConnectors}
          />
        ))}
      </div>
      <hr />
      <div className='flex justify-between py-4'>
        {connectorSubItems.length ? (
          <Button
            onClick={() => setConnectorSubItems([])}
            dataCy='close-btn'
            color='white'
            className='mr-4'
            iconName='arrow-left'
          >
            Back
          </Button>
        ) : (
          <Button
            onClick={() => onClickSuggestAnIntegrationBtn()}
            dataCy='suggest-an-integration-btn'
            color='white'
            className='border'
          >
            Suggest an Integration
          </Button>
        )}
        <Button onClick={() => hideModal()} dataCy='close-btn' color='white' className='mr-4'>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_IMPORT_FROM_CONNECTOR })(
  withUploadModal(ImportFromConnector)
);
