import { useState } from 'react';
import { useDispatch } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';

import {
  setAlwaysConfirmDeleteRow,
  setGenerateDeletingRows,
} from '@/redux/reducers/spreadsheetSlice';

import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { MODAL_DELETE_ROWS } from '@/Utils/constants';
import { handleDeleteRows } from '@/helpers/spreadsheetHelper';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

const DeleteRowsModal = ({ show, hideModal, deletingRows }) => {
  const dispatch = useDispatch();
  const [confirmStep, showConfirmStep] = useState(false);
  const deleteRows = (deletingRows) => {
    handleDeleteRows(deletingRows);
    hideModal();
  };

  const save = () => {
    dispatch(setAlwaysConfirmDeleteRow(confirmStep));
    deleteRows(deletingRows);
    hideModal();
  };

  const cancel = () => {
    dispatch(setGenerateDeletingRows(false));
    hideModal();
  };

  return (
    <Modal
      iconName='trash'
      className={'popup popup-delete-row'}
      isOpen={show}
      onClose={cancel}
      title={'Are you sure?'}
      size='small'
    >
      <div>
        <div>
          This will affect all <b>Views</b> created for this sheet. This action is permanent and
          cannot be undone.
        </div>
        <div className='mt-4 mb-8'>
          <CheckboxInput
            checked={confirmStep === false}
            onChange={() => showConfirmStep(!confirmStep)}
            label='Don’t show this message again'
          />
        </div>
        <div className='flex justify-end mt-[4px]'>
          <Button dataCy='reset-btn' onClick={cancel}>
            {'Cancel'}
          </Button>
          <Button className='ml-[8px]' color='oceanBlue' dataCy='save-btn' onClick={save}>
            {'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_ROWS })(DeleteRowsModal);
