export const parseRowCount = (rows = 0) => {
  if (rows < 1000) {
    return rows.toString();
  } else {
    const suffix = ['', 'K', 'M', 'B'];
    const a = rows.toPrecision(2).split('e'), // get power
      b = a.length === 1 ? 0 : Math.floor(Math.min(a[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = b < 1 ? rows.toFixed(1) : (rows / Math.pow(10, b * 3)).toFixed(1), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      parsedRowCount = d + suffix[b]; // append power
    return parsedRowCount;
  }
};
