import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomToggleSwitch from '@/Components/CustomToggleSwitch/CustomToggleSwitch';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { Button, Link } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import withModal from '../withModalHOC';

import { userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';

import { get, http_delete, post } from '@/Utils/API';
import {
  LINK_SPREADSHEET_DOMO_SUPPORT,
  LINK_SPREADSHEET_EXEL_SUPPORT,
  LINK_SPREADSHEET_GOOGLE_SHEET_SUPPORT,
  LINK_SPREADSHEET_LIVESHARING_SUPPORT,
  LINK_SPREADSHEET_LOOKER_SUPPORT,
  LINK_SPREADSHEET_POWER_BI_SUPPORT,
  LINK_SPREADSHEET_TABLEAU_SUPPORT,
  LINK_SUPPORT_DOCS,
  LIVESHARE,
  LIVESHARES,
  MODAL_CONTACT_SALES,
  MODAL_MAKE_COPY,
  MODAL_PAPERCUT,
  MODAL_REQUIRE_ACCOUNT,
  MODAL_URL_FOR_BI_AND_SPREADSHEETS,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { getBaseUrl } from '@/Utils/utils';

import domoIcon from '@/Assets/icons/Spreadsheets_Icons/domo.svg';
import exelIcon from '@/Assets/icons/Spreadsheets_Icons/exel.svg';
import googleSheetsIcon from '@/Assets/icons/Spreadsheets_Icons/google_sheets.svg';
import lookerIcon from '@/Assets/icons/Spreadsheets_Icons/looker.svg';
import powerBiIcon from '@/Assets/icons/Spreadsheets_Icons/power_bi.svg';
import tableauIcon from '@/Assets/icons/Spreadsheets_Icons/tableau.svg';
import { hasNoWritePermission } from '@/helpers/spreadsheetHelper';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const defaultApps = [
  { name: 'Google Sheets', img: googleSheetsIcon, link: LINK_SPREADSHEET_GOOGLE_SHEET_SUPPORT },
  { name: 'Looker', img: lookerIcon, link: LINK_SPREADSHEET_LOOKER_SUPPORT },
  { name: 'Excel', img: exelIcon, link: LINK_SPREADSHEET_EXEL_SUPPORT },
  { name: 'Power BI', img: powerBiIcon, link: LINK_SPREADSHEET_POWER_BI_SUPPORT },
  { name: 'Domo', img: domoIcon, link: LINK_SPREADSHEET_DOMO_SUPPORT },
  { name: 'Tableau', img: tableauIcon, link: LINK_SPREADSHEET_TABLEAU_SUPPORT },
];

const UrlForBiAndSpreadsheets = ({ show, hideModal }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();

  const [isFetching, setIsFetching] = useState(false);
  const [shareId, setShareId] = useState('');
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);
  const [liveSharesEntries, setLiveSharesEntries] = useState([]);

  const { user } = useSelector((state) => state[userDataSliceName]);
  const { isFileShared } = currentFile || {};
  const { canLivesharing } = user?.userProperties || {};

  const uuid = getFileUuidFromPath();
  const inputValue = `${getBaseUrl('api')}/${URL_DATASET}/${uuid}/${LIVESHARE}/${shareId}`;

  const createShareId = async () => {
    setIsFetching(true);

    let endpoint = '';
    let payload = {};
    try {
      endpoint = `${URL_DATASET}/${uuid}/${LIVESHARE}`;
      payload = {
        endRow: 0,
        startRow: 0,
      };
      const response = await post(endpoint, payload);
      const { share_id: shareId } = response || {};
      if (!shareId) {
        ShowToast({
          type: TOAST_TYPE_ERROR,
          text: response.Message.toUpperCase() || TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
      setShareId(shareId);
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        errorContext: error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const removeShareId = async () => {
    setIsFetching(true);
    let endpoint = '';
    try {
      endpoint = `${URL_DATASET}/${LIVESHARE}/${shareId}`;
      const arrayToDelete = liveSharesEntries?.length ? liveSharesEntries : [{ ShareId: shareId }];
      const deletePromises = arrayToDelete.map(async ({ ShareId: shareId }) => {
        await http_delete(endpoint);
      });

      await Promise.all(deletePromises);

      setShareId('');
      setLiveSharesEntries([]);
    } catch (error) {
      hideModal();
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const onToggleButtonChange = () => {
    if (user?.is_anonymous) {
      dispatch(showModal({ name: MODAL_REQUIRE_ACCOUNT }));
    } else if (!user?.is_anonymous && isFileShared && hasNoWritePermission(currentFile)) {
      dispatch(showModal({ name: MODAL_MAKE_COPY }));
    } else {
      if (!canLivesharing) {
        dispatch(showModal({ name: MODAL_PAPERCUT }));
        return;
      }

      if (shareId) {
        removeShareId();
      } else {
        createShareId();
      }
    }
  };

  const getLiveshares = async () => {
    const { live_share_entries: liveSharesEntries } = await get(
      `${URL_DATASET}/${uuid}/${LIVESHARES}`
    );
    return liveSharesEntries;
  };

  const getInitialData = async () => {
    setIsFetching(true);
    try {
      const liveshares = await getLiveshares();

      setLiveSharesEntries(liveshares);

      const getShareId = (arr) => {
        if (arr.length) {
          const filteredArr = arr.filter(({ Handle: handle }) => uuid === handle);
          filteredArr.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
          return arr[0]?.ShareId;
        } else {
          return '';
        }
      };

      setShareId(getShareId(liveshares));
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      hideModal();
    } finally {
      setIsFetching(false);
    }
  };

  const onLearnMoreButtonClick = () => {
    window.open(LINK_SPREADSHEET_LIVESHARING_SUPPORT, '_blank', 'noopener,noreferrer');
  };

  const onCopyButtonClick = () => {
    navigator.clipboard.writeText(inputValue);
    setIsToolTipVisible(true);

    setTimeout(() => {
      setIsToolTipVisible(false);
    }, 2000);
  };

  const title = (
    <div className='flex items-center'>
      <span className='pt-1'>URL for BI & Spreadsheets</span>
      {!canLivesharing && (
        <span className='ml-2'>
          <Icon size={24} name='rocket-launch' color='#D9B500' />
        </span>
      )}
    </div>
  );

  useEffect(() => {
    canLivesharing && getInitialData();
  }, [canLivesharing]);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='large'
      size='medium'
      title={title}
      iconName='link-simple-horizontal'
      onQuestionMarkButtonClick={onLearnMoreButtonClick}
      isQuestionMarkButton
    >
      <div className='mb-6 text-sm text-ui'>
        <p className=''>
          Make your content and live changes visible to Business Intelligence (BI) applications and
          spreadsheets by exposing a unique, unlisted public URL.
        </p>
      </div>
      <div>
        <div className='flex mb-6 text-sm text-ui'>
          <div className='flex pt-1 -ml-2'>
            <CustomToggleSwitch
              onChange={onToggleButtonChange}
              isChecked={shareId}
              switcher='SimpleSwitcher'
              wrapperClassName='scale-75'
              isDisabled={isFetching}
            />
          </div>
          <div className='flex flex-col'>
            <span className='text-sm font-semibold text-ui'>Enable URL</span>
            <span className='text-[10px] text-ui-helper uppercase font-extrabold'>
              Anyone with the link may be able to access the underlying data for the sheet.
            </span>
          </div>
        </div>
        {shareId && (
          <div className='flex mb-4'>
            <input
              readOnly
              value={inputValue}
              type='text'
              className='w-full h-8 px-3 border rounded-l outline-none border-ui-200 focus:ring-1 focus:ring-midnight-100'
            />
            <div className='relative'>
              <Button
                iconName='copy'
                color='midnight'
                className='!rounded-l-none'
                onClick={onCopyButtonClick}
                disabled={isToolTipVisible}
              />
              {isToolTipVisible && (
                <span className='absolute p-2 text-xs text-white -translate-x-1/2 rounded w-fit bg-midnight-900 left-1/2 -bottom-full '>
                  copied!
                </span>
              )}
            </div>
          </div>
        )}
        <hr className='my-1 bg-gray' />
      </div>
      <div className='flex items-center py-4'>
        <div className='text-base font-semibold grow'>Connect to:</div>
        <Button variant='solid' onClick={onLearnMoreButtonClick}>
          Learn More
        </Button>
      </div>
      <div className='mb-7'>
        <ul className='flex justify-between'>
          {defaultApps.map(({ name, img, link }, i) => {
            return (
              <li key={i}>
                <a
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex flex-col w-20 px-2 py-3 border border-white cursor-pointer h-28 rounded-xl hover:border-ocean-blue-500'
                >
                  <div className='flex items-start justify-center h-1/2'>
                    <img src={img} alt={name} />
                  </div>
                  <div className='flex items-center justify-center text-sm font-semibold text-center text-ui h-1/2'>
                    {name}
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className='flex flex-row justify-start p-2 mb-3 text-sm border-[1.5px] rounded bg-capri-100 border-capri-900 bg-opacity-40'>
        <div className='pr-2'>
          <Icon name='info' color='#0079B6' />
        </div>
        <div className=''>
          <div className='font-extrabold'>Limited to 100k rows and/or 100 columns</div>
          <div className='text-ui-secondary'>
            For more information or service specific limitations{' '}
            <Link to={LINK_SUPPORT_DOCS} className='underline' size='medium'>
              read our support docs
            </Link>
            .
          </div>
          <div className='text-ui-secondary'>
            For higher usage limits{' '}
            <Button
              className='!p-0 !m-0 underline text-capri-900 hover:bg-transparent active:bg-transparent h-2'
              variant='ghost'
              onClick={() => dispatch(showModal({ name: MODAL_CONTACT_SALES }))}
            >
              contact sales
            </Button>
            .
          </div>
        </div>
      </div>
      <hr className='my-1 bg-gray' />
      <div className='flex justify-end mt-4'>
        <Button onClick={hideModal} color='none'>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_URL_FOR_BI_AND_SPREADSHEETS, destroyOnHide: true })(
  UrlForBiAndSpreadsheets
);
