import clsx from 'clsx';

type Props = {
  size: 'small' | 'medium';
  color: 'pear' | 'midnight';
  className?: string;
};

export default function SpinnerSolid({ size = 'small', color = 'pear', className }: Props) {
  const sizes = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    large: 'w-8 h-8',
  };

  const colors = {
    pear: 'text-pear-900',
    midnight: 'text-midnight-500',
  };

  return (
    <span data-cy='animated-spinner' className='rounded-xl'>
      <svg
        className={clsx('animate-spin', sizes[size], colors[color], className)}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_31_1403)'>
          <path
            d='M21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75C9.41414 21.75 6.93419 20.7228 5.10571 18.8943C3.27723 17.0658 2.25 14.5859 2.25 12C2.25 8.15625 4.48219 4.65375 7.93688 3.06844C8.02638 3.02732 8.12311 3.00423 8.22153 3.00049C8.31996 2.99675 8.41816 3.01244 8.51052 3.04665C8.60289 3.08086 8.68761 3.13293 8.75985 3.19989C8.8321 3.26684 8.89044 3.34737 8.93156 3.43688C8.97268 3.52638 8.99577 3.62311 8.99951 3.72153C9.00325 3.81996 8.98756 3.91816 8.95335 4.01053C8.91914 4.10289 8.86707 4.18761 8.80011 4.25985C8.73316 4.3321 8.65263 4.39044 8.56312 4.43156C5.63906 5.77406 3.75 8.74406 3.75 12C3.75 14.188 4.61919 16.2865 6.16637 17.8336C7.71354 19.3808 9.81196 20.25 12 20.25C14.188 20.25 16.2865 19.3808 17.8336 17.8336C19.3808 16.2865 20.25 14.188 20.25 12C20.25 8.74406 18.3609 5.77406 15.4369 4.43156C15.2561 4.34852 15.1157 4.19707 15.0466 4.01053C14.9776 3.82399 14.9854 3.61764 15.0684 3.43688C15.1515 3.25611 15.3029 3.11575 15.4895 3.04665C15.676 2.97755 15.8824 2.98539 16.0631 3.06844C19.5178 4.65375 21.75 8.15625 21.75 12Z'
            fill='url(#paint0_linear_31_1403)'
          />
        </g>
        <defs>
          <linearGradient
            id='paint0_linear_31_1403'
            x1='12'
            y1='2.99995'
            x2='12'
            y2='21.75'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#ADE4FF' />
            <stop offset='0.48245' stopColor='#33BBFF' />
            <stop offset='1' stopColor='#008CD2' />
          </linearGradient>
          <clipPath id='clip0_31_1403'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}
