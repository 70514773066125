const getFileSize = (fileSize) => {
  if (isNaN(fileSize) && fileSize !== '') {
    return Number(fileSize.split(' ')[0]);
  } else if (fileSize === '') {
    return 0;
  } else {
    return Number(fileSize);
  }
};
export default getFileSize;
