import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

import {
  MODAL_DELETE_DUPLICATES_CONFIRM,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import { handleDeleteDuplicates } from '@/helpers/spreadsheetHelper';
import withModal from '@/Components/Modals/withModalHOC';
import { showModal } from '@/redux/reducers/modalsSlice';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSliceName } from '@/redux/constants';

const DeleteDuplicatesConfirmModal = ({
  show,
  hideModal,
  deduplicateColumns = [],
  duplicatesCount = 0,
  remainingRowsCount = 0,
  failedToCountDuplicates = false,
}) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const save = () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      hideModal();
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    handleDeleteDuplicates(deduplicateColumns);
    hideModal();
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title={failedToCountDuplicates ? 'Remove duplicates' : 'Duplicate Values Found'}
      iconName='eraser'
      size='medium'
    >
      <div>
        {failedToCountDuplicates ? (
          <div>
            <strong>Unable to display duplicate count. Remove duplicates anyway?</strong>
          </div>
        ) : (
          <div>
            <strong>
              <span data-cy='duplicates-value-count'>{duplicatesCount.toLocaleString()}</span>{' '}
              duplicate values can be removed
            </strong>
            .{' '}
            <span>
              <span data-cy='values-will-remain'>{remainingRowsCount.toLocaleString()}</span> values
              will remain. Would you like to proceed?
            </span>
          </div>
        )}
        <div className='mt-5'>
          <p>
            <i data-cy='warning-message'>This action is permanent and cannot be undone.</i>
          </p>
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' onClick={save} dataCy='save-btn'>
          Proceed
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_DUPLICATES_CONFIRM })(DeleteDuplicatesConfirmModal);
