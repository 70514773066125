export const filterConditionType = (col, wholeCellMatch) => {
  switch (col?.fieldType) {
    case 'Int64':
    case 'UInt64':
    case 'Float64':
      return 'equalsAny';
    case 'IPv4':
      return wholeCellMatch ? 'IPv4-Equals-Any' : 'IPv4-Contains-Any';
    case 'DateTime64':
    case 'DateTime':
    case 'Boolean':
      return 'equals';
    default:
      return wholeCellMatch ? 'equalsAny' : 'containsAny';
  }
};
