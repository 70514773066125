import CombineFilesInfoPanel from './CombineFilesInfoPanel';
import CombineDatasets from './CombineDatasets/CombineDatasets';

const Combine = ({ combinedName }) => {
  return (
    <div className='combine-container flex h-full mb-2'>
      <CombineDatasets />
      <CombineFilesInfoPanel combinedName={combinedName} />
    </div>
  );
};

export default Combine;
