import { createContext, useEffect, useState } from 'react';
import useThrottle from '@/hooks/useThrottle';

const defaultValue = { width: 0 };
export const ViewportContext = createContext(defaultValue);

const ViewportProvider = ({ children }) => {
  const [screenWidth, setWidth] = useState(window.innerWidth);
  const [screenHeight, setHeight] = useState(window.innerHeight);

  const onResize = useThrottle(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <ViewportContext.Provider value={{ screenWidth, screenHeight }}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
