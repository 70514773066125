import { useCallback, useRef } from 'react';

const useThrottle = (fn, wait) => {
  const timerId = useRef(); // track the timer
  const lastArgs = useRef(); // track the args

  // create a memoized debounce
  const throttle = useCallback(
    function (...args) {
      // function for delayed call
      const waitFunc = () => {
        if (lastArgs.current) {
          fn.apply(this, lastArgs.current);
          lastArgs.current = null;
          timerId.current = setTimeout(waitFunc, wait);
        } else {
          timerId.current = null;
        }
      };

      if (!timerId.current) {
        fn.apply(this, args);
        timerId.current = setTimeout(waitFunc, wait);
      } else {
        lastArgs.current = args;
      }
    },
    [fn, wait]
  );

  return throttle;
};
export default useThrottle;
