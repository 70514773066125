const NoSearchResults = () => {
  return (
    <div className='flex flex-col items-center justify-center md:h-full h-3/4'>
      <p
        data-cy='use-new-button'
        className='mt-6 text-base font-extrabold md:text-xl text-midnight-500 '
      >
        No files or folders matched this search.
      </p>
    </div>
  );
};

export default NoSearchResults;
