const getCurrentDateTimeWithTimezone = () => {
  const now = new Date();

  // Get the timezone offset in minutes and convert it to milliseconds
  const timezoneOffsetInMilliseconds = now.getTimezoneOffset() * 60000;

  // Add the timezone offset to the current date
  const userDateTime = new Date(now.getTime() - timezoneOffsetInMilliseconds);

  // Format the date and time as "yyyy-MM-dd HH:mm:ss"
  const formattedDateTime = userDateTime.toISOString().slice(0, 19).replace('T', ' ');

  return formattedDateTime;
};

export default getCurrentDateTimeWithTimezone;
