import clsx from 'clsx';

type Props = {
  size?: 'small' | 'medium';
  color?: 'pear' | 'midnight';
  className?: string;
};

export default function Spinner({ size = 'small', color = 'pear', className = '' }: Props) {
  const sizes = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
  };

  const colors = {
    pear: 'text-pear-900',
    midnight: 'text-midnight-500',
  };

  return (
    <span data-cy='animated-spinner' className='rounded-xl'>
      <svg
        className={clsx('animate-spin', sizes[size], colors[color], className)}
        viewBox='0 0 24 24'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 4.75V6.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M17.1266 6.87347L16.0659 7.93413'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M19.25 12L17.75 12'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M17.1266 17.1265L16.0659 16.0659'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M12 17.75V19.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M7.9342 16.0659L6.87354 17.1265'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M6.25 12L4.75 12'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
      </svg>
    </span>
  );
}
