import clsx from 'clsx';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';

import { Listbox } from '@headlessui/react';
import './style/index.scss';
import { DEFAULT_FILTER_VALUE } from '@/Utils/saveFilter';

const SelectSavedFilter = ({
  onOpen,
  onFilterChange,
  savedFilters,
  currentAppliedFilter,
  togglePopup,
  exceededSavedFilters,
}) => {
  return (
    <div className='mx-2'>
      <Listbox
        value={currentAppliedFilter ? currentAppliedFilter : 'Load Filters'}
        defaultValue={currentAppliedFilter ? currentAppliedFilter : DEFAULT_FILTER_VALUE}
        onChange={(value) => onFilterChange(value)}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              onClick={() => {
                if (!open) onOpen();
              }}
              className='w-40 border appearance-none flex items-center bg-white px-3 rounded disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200 relative h-8 text-sm border-shadow-200 text-ui-secondary !shadow-none placeholder-helper font-normal focus:outline-none overflow-hidden'
              data-cy='load-filters'
            >
              <div className='truncate'>
                {currentAppliedFilter ? currentAppliedFilter.label : 'Load Filters'}
              </div>
              <Icon
                className='absolute ml-2 right-2'
                name={open ? 'caret-up' : 'caret-down-regular'}
                size={12}
                color='#151515'
              />
            </Listbox.Button>

            <Listbox.Options
              className={clsx(
                'w-64 border bg-white rounded absolute z-50 mt-1 max-h-60 overflow-auto p-1 text-sm border-shadow-200 text-ui-secondary !shadow-none placeholder-helper font-normal focus:outline-none',
                exceededSavedFilters && 'w-72'
              )}
            >
              {savedFilters.length > 0 ? (
                savedFilters.map((option, i) => (
                  <Listbox.Option
                    data-cy={`select-option-${i + 1}`}
                    id={option.label + i}
                    className={({ active }) =>
                      clsx(
                        'relative leading-5 flex items-center cursor-pointer select-none p-2 whitespace-pre overflow-hidden h-8 text-sm border-shadow-200 text-ui-secondary !shadow-none placeholder-helper font-normal focus:outline-none justify-between',
                        active && 'text-ui'
                      )
                    }
                    key={option.label + i}
                    value={option.value}
                  >
                    <div className='truncate w-52'>{option.label}</div>

                    {option.isShared ? (
                      <Icon name='users-three' size={14} className='mx-1' />
                    ) : (
                      <Button
                        size='small'
                        variant='ghost'
                        iconName='trash'
                        dataCy='remove-search-select-option'
                        onClick={(e) => {
                          togglePopup(option.value);
                          e.stopPropagation();
                        }}
                      />
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <Listbox.Option
                  value='No Options'
                  disabled
                  className='flex items-center justify-center h-8 p-2 overflow-hidden text-xs leading-5 text-center rounded cursor-not-allowed select-none align-center bg-midnight-100 !bg-opacity-40 text-midnight'
                >
                  You don't have any saved filters.
                </Listbox.Option>
              )}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SelectSavedFilter;
