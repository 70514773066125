import { useEffect, useState, useMemo } from 'react';

import 'react-multi-email/style.css';
import { useDispatch, useSelector } from 'react-redux';

import '@/pages/DatasetPage/style/index.scss';
import InvitePeopleBody from './InvitePeopleBody';
import ShareFileFooter from './ShareFileFooter';

import showToast from '@/Components/Toast/showToastTemplate';
import { Mixpanel } from '@/Mixpanel';
import { datasetSliceName } from '@/redux/constants';

import { get, putRaw } from '@/Utils/API';
import {
  PUBLIC_LABEL,
  RESTRICTED_LABEL,
  RESTRICTED_OPTION_VALUE,
  CAN_VIEW_AND_EDIT_LABEL,
  PUBLIC_OPTION_VALUE,
  CAN_VIEW_LABEL,
  DELETE_OPTION_INDEX,
  DELETE_LABEL,
  PERMISSION_READ,
  PERMISSION_WRITE,
  TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
  TOAST_TYPE_ERROR,
  URL_SPREADSHEET,
  URL_DATASETS,
  URL_DATASET,
  FILE_PERMISSIONS,
  USER_FILE_PERMISSIONS,
  FILE,
  SHARE,
  TEAM_LABEL,
  TEAM_OPTION_VALUE,
  ORGANIZATION_PERMISSIONS,
  CAN_VIEW_OPTION_INDEX,
  CAN_VIEW_AND_EDIT_OPTION_INDEX,
  FAKE_TEAM_OPTION_VALUE,
  MODAL_UPGRADE_TO_TEAM,
  MODAL_PUBLISH_TO_WEB,
  userTeamNameKey,
  TOAST_TYPE_INFO,
  TOAST_TYPE_SUCCESS,
} from '@/Utils/constants';
import { formatUrlSafeFileName } from '@/Utils/utils';
import Icon from '@/Components/UI/Icon/Icon';
import { showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import { getAutofillEmails } from '@/Utils/CommentUtils';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';

const DefaultAccessOptions = [
  { value: PUBLIC_OPTION_VALUE, label: PUBLIC_LABEL },
  { value: RESTRICTED_OPTION_VALUE, label: RESTRICTED_LABEL },
  {
    value: FAKE_TEAM_OPTION_VALUE,
    label: (
      <div className='flex'>
        <span className='text-gray-400'>{TEAM_LABEL}</span>
        <span className='ml-3'>
          <Icon name='rocket-launch' color='#D9B500' />
        </span>
      </div>
    ),
  },
];

const ShareFile = ({ closePopup, accessRequestUserEmail }) => {
  const { sendMessage } = useWebSocketContext();
  const [allUserFilePermissions, setAllUserFilePermissions] = useState([]);
  const [publicFilePermissions, setPublicFilePermissions] = useState([]);
  const [organizationFilePermissions, setOrganizationFilePermissions] = useState([]);
  const [loadingTextBtn, setLoadingTextBtn] = useState('');
  const [activeAccessOption, setActiveAccessOption] = useState(null);
  const [accessOptions, setAccessOptions] = useState(DefaultAccessOptions);
  const [emailTags, setEmailTags] = useState(
    accessRequestUserEmail ? [accessRequestUserEmail] : []
  );
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [initialUserPermission, setInitialUserPermission] = useState(PUBLIC_OPTION_VALUE);
  const [initialOrganizationPermission, setInitialOrganizationPermission] = useState('');
  const [emailAutocompleteList, setEmailAutocompleteList] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const { currentFile, currentDir, currentDataset } = useSelector(
    (state) => state[datasetSliceName]
  );
  const {
    FileName: fileName,
    Owner: fileOwner,
    FileUuid: uuid,
    IsDirectory: isDirectory,
  } = currentFile?.metadata || currentFile || {};
  const { email: currentUserEmail, org_id } = user;

  const isSpreadsheetPage = location.pathname.includes('spreadsheet');

  const teamLabel = getTeamNameLabel(user[userTeamNameKey]);

  const TeamAccessOption = {
    value: TEAM_OPTION_VALUE,
    label: teamLabel,
  };

  const restrictedPermissionsOptions = useMemo(
    () => [
      { value: CAN_VIEW_OPTION_INDEX, label: CAN_VIEW_LABEL },
      { value: CAN_VIEW_AND_EDIT_OPTION_INDEX, label: CAN_VIEW_AND_EDIT_LABEL },
      { value: DELETE_OPTION_INDEX, label: DELETE_LABEL },
    ],
    []
  );

  const shareFileUrl = (versionId) =>
    `${window.location.origin}/${URL_SPREADSHEET}/${formatUrlSafeFileName(
      fileName
    )}/${uuid}${versionId}`;

  const shareFolderUrl = `${window.location.origin}/${URL_DATASETS}/${uuid}`;

  const showLabelTimeout = 3000;
  let timerId;

  const refreshPageIfFileIsShared = (userId) => {
    if (currentUserEmail === userId) {
      closePopup();
      if (isSpreadsheetPage) {
        window.location.reload();
      } else {
        sendMessage({
          location: `${currentDir}`,
          category: getWebSocketCategory(currentDataset),
        });
      }

      return false;
    }
    return true;
  };

  const setIndividualUserFilePermissions = (userId, permissions) => {
    const endpoint = `${URL_DATASET}/${uuid}/${USER_FILE_PERMISSIONS}`;
    const payload = {
      user_id: userId,
      permissions,
    };
    putRaw(endpoint, payload).then((response) => {
      if (response.status === 200) {
        refreshPageIfFileIsShared(userId) && refreshUserFilePermissions();
      } else {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
    });
  };

  const refreshUserFilePermissions = () => {
    setIsLoading(true);
    get(`${URL_DATASET}/${uuid}/${USER_FILE_PERMISSIONS}?includeInherited=true`).then(
      (response) => {
        setAllUserFilePermissions(response);
        setIsLoading(false);
      }
    );
  };

  const refreshPublicFilePermissions = () => {
    setIsLoading(true);
    get(`${URL_DATASET}/${uuid}/${FILE_PERMISSIONS}`).then((response) => {
      setPublicFilePermissions(response.permissions);
      setIsLoading(false);
    });
  };

  const refreshOrganizationFilePermissions = () => {
    setIsLoading(true);
    get(`${URL_DATASET}/${uuid}/${ORGANIZATION_PERMISSIONS}`).then((response) => {
      setOrganizationFilePermissions(response.permissions);
      setIsLoading(false);
    });
  };

  const copyFileLink = async () => {
    const getVersionId = async () => await get(`client-state/${uuid}/current-version`);
    if (typeof ClipboardItem && navigator.clipboard.write) {
      const clipboardItemUrl = new ClipboardItem({
        'text/plain': getVersionId()
          .then((data) => {
            let versionId = '';
            if (data.version) {
              versionId = '/' + data.version;
            }
            return isDirectory ? shareFolderUrl : shareFileUrl(versionId);
          })
          .then((el) => {
            return new Blob([el], { type: 'text/plain' });
          }),
      });
      navigator.clipboard.write([clipboardItemUrl]);
    } else {
      let versionId = '';

      getVersionId().then((res) => {
        if (res.version) {
          versionId = '/' + res.version;
        } else {
          versionId = '';
        }

        navigator.clipboard.writeText(isDirectory ? shareFolderUrl : shareFileUrl(versionId));
      });
    }

    showToast({
      type: TOAST_TYPE_SUCCESS,
      text: 'Link copied to clipboard',
    });
  };

  const embedCodeBtn = () => dispatch(showModal({ name: MODAL_PUBLISH_TO_WEB }));

  const sendInvite = async () => {
    const permissions =
      initialUserPermission === PUBLIC_OPTION_VALUE
        ? [PERMISSION_READ]
        : [PERMISSION_READ, PERMISSION_WRITE];

    setLoadingTextBtn('Sending');
    const endpoint = `${FILE}/${uuid}/${SHARE}/${FILE}`;
    const payload = {
      emails: emailTags,
      permissions: permissions,
      message: message,
    };
    const response = await putRaw(endpoint, payload);

    setLoadingTextBtn('');
    if (response.status === 200) {
      setEmailTags([]);
      refreshUserFilePermissions();
      mixPanelTrackLinkShare();
    } else {
      const responseBody = await response.json();

      if (responseBody.hasOwnProperty('Success') && !responseBody.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: responseBody?.Message || TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      } else {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
    }
  };

  const mixPanelTrackLinkShare = () => {
    const [, fileType] = fileName.split('.');
    Mixpanel.identify(currentUserEmail);
    Mixpanel.track('Link Share', {
      'File Type': fileType,
      file_uuid: uuid,
    });
    Mixpanel.people.increment('# of link shares', 1);
  };

  const onCancelBtnClick = () => setEmailTags([]);

  const onAccessSelectChange = (accessOption) => {
    if (accessOption.value === FAKE_TEAM_OPTION_VALUE) {
      setActiveAccessOption(accessOptions[getAccessOptionIndex(FAKE_TEAM_OPTION_VALUE)]);
      dispatch(showModal({ name: MODAL_UPGRADE_TO_TEAM }));
      dispatch(
        addMixpanelEvent({
          eventName: 'Team access paywall',
          eventProps: { current_filename: currentFile?.metadata?.FileName },
          userIncrementName: '# of clicks',
        })
      );
      return;
    }
    let newPublicFilePermissions = [PERMISSION_READ];
    let newOrganizationFilePermissions = [PERMISSION_READ];
    if (accessOption.value !== PUBLIC_OPTION_VALUE) {
      newPublicFilePermissions = [];
    }
    if (accessOption.value !== TEAM_OPTION_VALUE) {
      newOrganizationFilePermissions = [];
    }
    setLoadingTextBtn('Saving');

    const setPublicFilePermissionsRequest = putRaw(`${URL_DATASET}/${uuid}/${FILE_PERMISSIONS}`, {
      permissions: newPublicFilePermissions,
    });
    const setOrganizationFilePermissionsRequest = putRaw(
      `${URL_DATASET}/${uuid}/${ORGANIZATION_PERMISSIONS}`,
      { permissions: newOrganizationFilePermissions }
    );

    Promise.allSettled([
      setPublicFilePermissionsRequest,
      setOrganizationFilePermissionsRequest,
    ]).then((results) => {
      if (results.some((e) => e.status !== 'fulfilled')) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_UNABLE_PROCESS_REQUEST_ERROR,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      } else {
        refreshUserFilePermissions();
        refreshPublicFilePermissions();
        refreshOrganizationFilePermissions();

        sendMessage({
          location: `${currentDir}`,
          category: getWebSocketCategory(currentDataset),
        });
      }
      setLoadingTextBtn('');
    });
  };

  const onEmailsChange = (emails) => {
    setEmailTags(emails);
  };

  const getAccessOptionIndex = (value) => {
    return accessOptions.findIndex((e) => e.value === value);
  };

  const onOrganizationPermissionChange = async (value) => {
    const newOrganizationFilePermissions = () => {
      if (value === CAN_VIEW_AND_EDIT_OPTION_INDEX) return [0, 1];
      if (value === CAN_VIEW_OPTION_INDEX) return [0];
      return [];
    };

    await putRaw(`${URL_DATASET}/${uuid}/${ORGANIZATION_PERMISSIONS}`, {
      permissions: newOrganizationFilePermissions(),
    });
    refreshOrganizationFilePermissions();
  };

  const getProperOrganizationValue = () => {
    if (organizationFilePermissions.length === 2) return CAN_VIEW_AND_EDIT_OPTION_INDEX;
    if (organizationFilePermissions.length === 1) return CAN_VIEW_OPTION_INDEX;
    return '';
  };

  const getAutofillOptions = () => {
    const includeShareRecipients = true;
    let includeTeamMembers = false;
    if (org_id?.length > 0) {
      includeTeamMembers = true;
    }
    getAutofillEmails(includeTeamMembers, includeShareRecipients).then((emails) => {
      setEmailAutocompleteList(emails);
    });
  };

  useEffect(() => {
    refreshUserFilePermissions();
    refreshPublicFilePermissions();
    refreshOrganizationFilePermissions();
    getAutofillOptions();
  }, []);

  useEffect(() => {
    if (organizationFilePermissions?.length > 0) {
      setActiveAccessOption(accessOptions[getAccessOptionIndex(TEAM_OPTION_VALUE)]);
    } else if (publicFilePermissions?.length > 0) {
      setActiveAccessOption(accessOptions[getAccessOptionIndex(PUBLIC_OPTION_VALUE)]);
      setInitialUserPermission(PUBLIC_OPTION_VALUE);
    } else {
      setActiveAccessOption(accessOptions[getAccessOptionIndex(RESTRICTED_OPTION_VALUE)]);
      setInitialUserPermission('1');
    }
  }, [allUserFilePermissions, publicFilePermissions, organizationFilePermissions]);

  const isDoneButtonDisabled = activeAccessOption?.value === FAKE_TEAM_OPTION_VALUE;
  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  useEffect(() => {
    if (org_id?.length > 0) {
      setAccessOptions([DefaultAccessOptions[0], TeamAccessOption, DefaultAccessOptions[1]]);
    } else {
      setAccessOptions(DefaultAccessOptions);
    }
  }, [org_id]);

  useEffect(() => {
    setInitialOrganizationPermission(getProperOrganizationValue(organizationFilePermissions));
  }, [organizationFilePermissions]);

  return (
    <div className='sharing-container'>
      <InvitePeopleBody
        owner={fileOwner}
        currentUser={currentUserEmail}
        fileUuid={uuid}
        userFilePermissions={allUserFilePermissions}
        activeAccessOption={activeAccessOption}
        accessOptions={accessOptions}
        restrictedPermissionsOptions={restrictedPermissionsOptions}
        emailTags={emailTags}
        isLoading={isLoading}
        message={message}
        setMessage={setMessage}
        currentUserEmail={currentUserEmail}
        setIndividualUserFilePermissions={setIndividualUserFilePermissions}
        onSelectChange={onAccessSelectChange}
        onEmailsChange={onEmailsChange}
        initialUserPermission={initialUserPermission}
        setInitialUserPermission={setInitialUserPermission}
        initialOrganizationPermission={initialOrganizationPermission}
        onOrganizationPermissionChange={onOrganizationPermissionChange}
        autocompleteList={emailAutocompleteList}
      />

      <ShareFileFooter
        showDoneBtn={emailTags.length === 0}
        onDoneBtnClick={closePopup}
        sendInvite={sendInvite}
        onCancelBtnClick={onCancelBtnClick}
        onCopyBtnClick={copyFileLink}
        onEmbedCodeBtn={embedCodeBtn}
        loadingTextBtn={loadingTextBtn}
        isDoneButtonDisabled={isDoneButtonDisabled}
      />

      <div data-cy='shared-folder-url' hidden={true} shared-folder-url={shareFolderUrl}>
        {shareFolderUrl}
      </div>
    </div>
  );
};

export default ShareFile;
