import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Mixpanel } from '../../Mixpanel';
import { getFileTypeByName } from '../../Utils/getFileTypeByName';
import { getBaseUrl } from '../../Utils/utils';
import { buildSliceName, datasetSliceName, sharedSliceName, userDataSliceName } from '../constants';

const initialState = {
  isOnboardingOpened: false,
  navigationIconStatus: null,
  newEnrichmentStarted: false,
  showLoadingState: false,
  isRanByCypress:
    localStorage.getItem('cypressGigasheetAPIToken') !== null &&
    getBaseUrl('webapp') === 'http://localhost:3000',
};

export const addMixpanelEvent = createAsyncThunk(
  `${sharedSliceName}/addMixpanelEvent`,
  (args, api) => {
    const { getState } = api;
    const state = getState();
    const { user } = state[userDataSliceName];
    const { currentFile } = state[datasetSliceName];
    const { commit_id } = state[buildSliceName];
    const { FileName, FileUuid } = currentFile.metadata || {};
    const { eventName, eventProps, userIncrementName } = args;
    const fileType = getFileTypeByName(FileName);

    Mixpanel.identify(user.email);
    Mixpanel.track(eventName, {
      ...eventProps,
      'File Type': fileType,
      file_uuid: FileUuid,
      commit_id: commit_id,
      currentFile: currentFile,
    });
    Mixpanel.people.increment(userIncrementName, 1);
  }
);

const sharedSlice = createSlice({
  name: sharedSliceName,
  initialState,
  reducers: {
    setIsOnboardingOpened(state, action) {
      state.isOnboardingOpened = action.payload;
    },
    setNavigationIconStatus(state, action) {
      state.navigationIconStatus = action.payload;
    },
    setNewEnrichmentStarted(state, action) {
      state.newEnrichmentStarted = action.payload;
    },
    setShowLoadingState(state, action) {
      state.showLoadingState = action.payload;
    },
  },
});

export const {
  setIsOnboardingOpened,
  setNavigationIconStatus,
  setNewEnrichmentStarted,
  setShowLoadingState,
} = sharedSlice.actions;

export default sharedSlice.reducer;
