export const getDefaultAllWorkspacesFolderContent = (arr) => {
  const filteredArr = arr.filter(({ hidden }) => !hidden);
  return filteredArr.map(({ sectionName, folderIcon }, i) => {
    return {
      metadata: {
        id: i,
        folderIcon,
        FileName: sectionName,
        FileUuid: '',
        IsDirectory: true,
        ParentDirectory: '',
      },
      shared: {
        restricted: [],
        organization: [],
        public: [0],
      },
    };
  });
};
