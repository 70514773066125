import Modal from '@/Components/UI/Modal/Modal';
import React, { useState } from 'react';
import withModal from '../withModalHOC';
import { Button } from '@/Components/UI/Button/Button';
import {
  MODAL_DELETE_VIEW,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_SPREADSHEET,
} from '@/Utils/constants';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { http_delete } from '@/Utils/API';
import { openSheetViewPopover } from '@/helpers/spreadsheetHelper';
import { useNavigate } from 'react-router-dom';

type DeleteView = {
  hideModal: () => void;
  show: boolean;
  view: {
    name: string;
    file: string;
    id: string;
    client_state: string;
  };
  params?: { name: string | undefined; uuid: string | undefined; stateVersion: string | undefined };
};

const DeleteView = ({ hideModal, show, view, params }: DeleteView) => {
  const [fetching, setFetching] = useState(false);

  const navigate = useNavigate();

  const handleDeleteView = async () => {
    setFetching(true);
    let endpoint = '';
    try {
      endpoint = `${URL_DATASET}/${view.file}/views/${view.id}`;
      const response = await http_delete(endpoint);

      if (response.hasOwnProperty('Success') && !response.Success) {
        throw new Error();
      }
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      if (params?.stateVersion === view.client_state) {
        navigate(`/${URL_SPREADSHEET}/${params?.name}/${params?.uuid}`);
      }
      setFetching(false);
      hideModal();
      openSheetViewPopover();
    }
  };

  const cutFileName = (name: string) => {
    if (name.length > 25) {
      return `${name.slice(0, 25)}...`;
    } else {
      return name;
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      size='small'
      titleSize='small'
      title='Confirm Deletion'
      withoutCloseButton={true}
      dataCy='delete-view-modal'
    >
      <div data-cy='delete-view-text'>
        Delete <strong>&ldquo;{cutFileName(view.name)}&rdquo;</strong> forever? This will affect all{' '}
        <strong>Views</strong> created for this sheet.
      </div>
      <div className='flex justify-end gap-4 mt-6'>
        <Button
          color='shadow'
          variant='ghost'
          onClick={hideModal}
          dataCy='delete-view-modal-cancel-button'
        >
          Cancel
        </Button>
        <Button
          dataCy='delete-view-modal-confirm-button'
          color='oceanBlue'
          onClick={handleDeleteView}
          disabled={fetching}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_VIEW })(DeleteView);
