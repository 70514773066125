import { Button, Link } from '@/Components/UI/Button/Button';
import {
  LINK_HOW_WE_MAKE_MONEY,
  LINK_PRIVACY_POLICY,
  LINK_SUPPORT_DOCS,
  LINK_TERMS_OF_USE,
} from '@/Utils/constants';
import { postToLogoutChannel } from '@/Utils/utils';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';

const onboardingStyles = {
  header: 'before:content-logo',
  title: 'text-4xl font-extrabold',
  footer: 'flex items-end h-16 text-sm',
};

export const VerifyEmail = ({ user, resendEmailVerification }) => {
  return (
    <div className={clsx('md:w-[450px] w-80', onboardingStyles.header)}>
      <div className='h-[60px] pt-3'>
        <h3 className={onboardingStyles.title}>Verify Your Email</h3>
      </div>

      <div className='flex flex-col items-start justify-between h-40 text-sm leading-6'>
        <div>
          To complete registration an email has been sent to {user.email} with a link to verify your
          account. If you have not received the email after a few minutes, please check your spam
          folder.
        </div>
        <Button color='sunrise' size='large' onClick={() => resendEmailVerification()}>
          Resend Email
        </Button>
      </div>

      <div className={onboardingStyles.footer}>
        <div>
          Need help?&nbsp;
          <Link
            to={LINK_SUPPORT_DOCS}
            target='_blank'
            rel='noopener noreferrer'
            size='medium'
            color='oceanBlue'
          >
            Contact support
          </Link>
          &nbsp;or&nbsp;
          <span
            role='button'
            tabIndex={0}
            onKeyUp={() => {}}
            onClick={() => {
              postToLogoutChannel();
            }}
            className='text-sm !text-midnight-300 !no-underline cursor-pointer hover:text-midnight-600 hover:no-underline'
          >
            try another email address
          </span>
          .
        </div>
      </div>
    </div>
  );
};

export const TermsAndConditions = ({ isChecked, toggleIsChecked, setOnboardingStep }) => {
  return (
    <div className={onboardingStyles.header}>
      <div className='flex flex-col justify-around h-[170px]'>
        <div>
          <div className='py-2 text-lg font-semibold'>
            Gigasheet does not sell or share your data.
          </div>

          <div>
            <Link
              to={LINK_HOW_WE_MAKE_MONEY}
              size='medium'
              color='oceanBlue'
              target='_blank'
              rel='noopener noreferrer'
            >
              How do we make money?
            </Link>
          </div>
        </div>

        <div>
          <div className='py-2 text-lg font-semibold'>Terms & Conditions</div>

          <div className='flex flex-row !items-center gap-3 text-sm items-row'>
            <input
              value={isChecked}
              onClick={() => toggleIsChecked()}
              type='checkbox'
              className='h-[15px] w-[15px]'
            />
            <div>
              I agree with Gigasheet's{' '}
              <Link
                color='oceanBlue'
                size='medium'
                to={LINK_TERMS_OF_USE}
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of Use
              </Link>{' '}
              and{' '}
              <Link
                color='oceanBlue'
                size='medium'
                to={LINK_PRIVACY_POLICY}
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </Link>
              .
            </div>
          </div>
        </div>
      </div>

      <div className={onboardingStyles.footer}>
        <Button
          color='sunrise'
          size='large'
          disabled={!isChecked}
          onClick={() => setOnboardingStep(2)}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const InviteUsers = ({
  invitedUsers,
  handleInviteUsers,
  validateInviteEmail,
  addInput,
  removeInput,
  inviteUsers,
  enableSendInvites,
  responseMessage,
  isInviteSent,
  setIsOnboarded,
  withoutHeader,
}) => {
  return (
    <div
      className={clsx(
        'overflow-auto max-w-[540px] min-h-[300px] max-h-[550px]',
        !withoutHeader && onboardingStyles.header
      )}
    >
      <div className='flex flex-col justify-center h-32 mb-1'>
        <h3 className={onboardingStyles.title}>Invite Your Team</h3>
        <p className='text-sm'>
          The big data party has started and everyone's invited. Let's light this fire.
        </p>
      </div>

      <div className='py-2'>
        {invitedUsers?.map(({ id, valid }, i) => {
          return (
            <div className='flex flex-col gap-1 py-1' key={id}>
              <div className='flex items-center gap-1'>
                <input
                  className={clsx(
                    '!text-sm border border-shadow-700 rounded h-[36px] w-80 p-1 ease-in-out transition-shadow duration-200',
                    !valid && '!border !border-violet-web-600 shadow-md !shadow-violet-web-100'
                  )}
                  disabled={isInviteSent}
                  placeholder={'Enter email address'}
                  onChange={(e) => handleInviteUsers(e, i)}
                  onBlur={validateInviteEmail}
                  id={id}
                  type='text'
                />
                {!isInviteSent ? (
                  <Button
                    iconName='x'
                    variant='ghost'
                    color='shadow'
                    disabled={invitedUsers.length === 1}
                    onClick={() => removeInput(id)}
                  />
                ) : null}
              </div>

              <div className='w-20 px-1 text-[10px] font-semibold uppercase rounded-sm bg-violet-web-100 text-violet-web-900'>
                {!valid ? 'Invalid Email' : ''}
              </div>
            </div>
          );
        })}

        <div
          className={clsx(
            'flex flex-col pt-1 max-w-96',
            isInviteSent ? 'justify-start' : 'justify-center'
          )}
        >
          <div
            className={clsx(
              'px-1 text-xs font-semibold uppercase rounded-sm mb-2',
              responseMessage.includes('sent')
                ? 'bg-capri-100 text-capri-900'
                : 'bg-violet-web-100 text-violet-web-900'
            )}
          >
            {responseMessage}
          </div>
          {!isInviteSent ? (
            <div className='flex justify-center'>
              <Button
                color='oceanBlue'
                variant='outline'
                iconName='plus-circle'
                className='w-fit'
                onClick={addInput}
              >
                Add Another Email
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <div className={clsx('gap-4', onboardingStyles.footer)}>
        {isInviteSent ? (
          <>
            <Button color='sunrise' onClick={() => setIsOnboarded()}>
              Continue to Gigasheet
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={enableSendInvites}
              color='sunrise'
              iconName='envelope-simple'
              onClick={inviteUsers}
            >
              Send Invite
            </Button>
            <Button color='shadow' variant='ghost' onClick={() => setIsOnboarded()}>
              Skip for Now
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
