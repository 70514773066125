import { Button } from '@/Components/UI/Button/Button';
import { MODAL_CONFIRM_LEAVE_TEAM } from '@/Utils/constants';
import { InvitePeopleButton } from '@/helpers/settingsHelper';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';
import { settingsSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { useDispatch, useSelector } from 'react-redux';
import TeamGraphic from './../../../Components/UI/Graphics/team.svg';

const TeamSettings = () => {
  const dispatch = useDispatch();
  const { teamName, teamMembers, isSettingsPageLoading } = useSelector(
    (state) => state[settingsSliceName]
  );
  return (
    <div className='px-16 pb-28'>
      {isSettingsPageLoading ? (
        <SkeletonLoader header={false} />
      ) : (
        <div className='flex flex-col w-full'>
          {!teamMembers.length ? (
            <div className='flex flex-col items-center justify-center w-full'>
              <div>
                <img src={TeamGraphic} alt='team' className='w-[370px] h-[370px]' />
              </div>

              <div className='flex flex-col gap-3 mt-6 text-center'>
                <div className='text-lg font-semibold'>Start Collaborating Today</div>
                <div className='text-base'>
                  Big data is hard to share. Make your life easier and start collaborating today.
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='w-[800px] border bg-white flex flex-col gap-4 p-8'>
                <div className='flex flex-row pb-4 mb-2 text-left border-b'>
                  <div className='flex flex-col justify-center w-1/2 gap-4 border-r h-28'>
                    <div className='text-lg font-extrabold'>Name</div>
                    <div>{teamName}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div className='mb-6 text-lg font-extrabold'>Members</div>
                    <div className='flex flex-row justify-between p-2 border-b h-9'>
                      <div className='text-sm font-semibold'>Name</div>
                      <div className='text-sm font-semibold'>Role</div>
                    </div>

                    <div className='max-h-[480px] overflow-scroll mb-6'>
                      {teamMembers?.map((teamMember, i) => {
                        return (
                          <div
                            className='flex flex-row items-center justify-between w-full p-2 text-sm border-b'
                            key={teamMember + i}
                          >
                            <div className='flex flex-row items-center h-12 gap-4 '>
                              <div className='flex items-center justify-center w-6 h-6 pt-1 text-xs font-bold text-white uppercase rounded text-items-center bg-ocean-blue-700 align-center'>
                                {teamMember[0]}
                              </div>
                              <div className='text-ui-secondary'>{teamMember}</div>
                            </div>
                            <div className=''>Member</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='flex flex-row justify-between w-full'>
                    <InvitePeopleButton
                      styles={{ color: 'midnight', iconName: 'plus-circle', variant: 'ghost' }}
                    />
                    <Button
                      variant='ghost'
                      onClick={() =>
                        dispatch(
                          showModal({
                            name: MODAL_CONFIRM_LEAVE_TEAM,
                          })
                        )
                      }
                    >
                      Leave Team
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TeamSettings;
