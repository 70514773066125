const COMMON_CONDITIONS = ['equals', 'notEqual', 'isBlank', 'isNotBlank'];
const MULTIVALUE_CONDITIONS = ['equalsAny', 'notEqualsAny', 'isBlank', 'isNotBlank'];
export const STRING_CONDITIONS = [
  ...MULTIVALUE_CONDITIONS,
  'containsAny',
  'notContainsAny',
  'startsWithAny',
  'notStartsWithAny',
  'endsWithAny',
  'notEndsWithAny',
  'wildcardMatch',
  'regularExpression',
];
export const NUMBER_CONDITIONS = [
  ...MULTIVALUE_CONDITIONS,
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
];
export const IPV4_CONDITIONS = [
  'IPv4-Contains-Any',
  'IPv4-Not-Contains-Any',
  'IPv4-Equals-Any',
  'IPv4-Not-Equals-Any',
  'IPv4-Starts-With-Any',
  'IPv4-Ends-With-Any',
  'IPv4-IsBlank',
  'IPv4-IsNotBlank',
  'IPv4-Internal',
  'IPv4-External',
  'regularExpression',
];
export const DATE_CONDITIONS = [
  ...COMMON_CONDITIONS,
  'lessThanOrEqual',
  'greaterThanOrEqual',
  'inRange',
];
export const BOOLEAN_CONDITIONS = [...COMMON_CONDITIONS];
export const FLAG_CONDITIONS = ['equals', 'notEqual'];
export const prettyPrintCondition = (condition) => {
  switch (condition) {
    case 'equals':
      return 'Equals';
    case 'notEqual':
      return 'Does not equal';
    case 'equalsAny':
      return 'Equals';
    case 'notEqualsAny':
      return 'Does not equal';
    case 'contains':
      return 'Contains';
    case 'containsAny':
      return 'Contains';
    case 'notContainsAny':
      return 'Does not contain';
    case 'notContains':
      return 'Does not contain';
    case 'startsWithAny':
      return 'Starts with';
    case 'startsWith':
      return 'Starts with';
    case 'notStartsWithAny':
      return 'Does not start with';
    case 'notStartsWith':
      return 'Does not start with';
    case 'endsWithAny':
      return 'Ends with';
    case 'endsWith':
      return 'Ends with';
    case 'notEndsWithAny':
      return 'Does not end with';
    case 'notEndsWith':
      return 'Does not end with';
    case 'isBlank':
      return 'Is empty';
    case 'isNotBlank':
      return 'Is not empty';
    case 'lessThan':
      return 'Less than';
    case 'lessThanOrEqual':
      return 'Less than or equal to';
    case 'greaterThan':
      return 'Greater than';
    case 'greaterThanOrEqual':
      return 'Greater than or equal to';
    case 'inRange':
      return 'In range';
    case 'wildcardMatch':
      return 'Wildcard Match';
    case 'IPv4-Contains-Any':
      return 'IP contains';
    case 'IPv4-Contains':
      return 'IP contains';
    case 'IPv4-Not-Contains-Any':
      return 'IP does not contain';
    case 'IPv4-Not-Contains':
      return 'IP does not contain';
    case 'IPv4-Equals-Any':
      return 'IP equals';
    case 'IPv4-Equals':
      return 'IP equals';
    case 'IPv4-Not-Equals-Any':
      return 'IP does not equal';
    case 'IPv4-Not-Equals':
      return 'IP does not equal';
    case 'IPv4-Starts-With-Any':
      return 'IP starts with';
    case 'IPv4-Starts-With':
      return 'IP starts with';
    case 'IPv4-Ends-With-Any':
      return 'IP ends with';
    case 'IPv4-Ends-With':
      return 'IP ends with';
    case 'IPv4-IsBlank':
      return 'IP is empty';
    case 'IPv4-IsNotBlank':
      return 'IP is not empty';
    case 'IPv4-Internal':
      return 'IP is internal';
    case 'IPv4-External':
      return 'IP is external';
    case 'regularExpression':
      return 'Regex Match';
    default:
      return condition.charAt(0).toUpperCase() + condition.slice(1);
  }
};

export const prettyPrintDateCondition = (condition) => {
  switch (condition) {
    case 'notEqual':
      return 'Does not equal';
    case 'lessThanOrEqual':
      return 'Before';
    case 'greaterThanOrEqual':
      return 'After';
    case 'equals':
      return 'Exactly';
    case 'inRange':
      return 'In range';
    case 'isBlank':
      return 'Is empty';
    case 'isNotBlank':
      return 'Is not empty';
    default:
      return condition.charAt(0).toUpperCase() + condition.slice(1);
  }
};

export const prettyPrintFlagLabel = (flag) => {
  switch (flag) {
    case '0':
      return 'None';
    case '1':
      return 'Good';
    case '2':
      return 'Suspicious';
    case '3':
      return 'Bad';
    default:
      return '';
  }
};
