import { useEffect, useRef } from 'react';
import {
  setFileSizeProperties,
  setLibraryFiles,
  setLibraryFolderStructureByName,
  setLibrarySharedWithMe,
  setLibrarySharedWithTeam,
  setProcessingFiles,
} from '../../../redux/reducers/datasetSlice';
import getColumnsCount from '../../../Utils/getColumnsCount';
import sortColumnData from '../../../Utils/sortColumnData';
import { useDispatch, useSelector } from 'react-redux';
import {
  authenticationSliceName,
  datasetSliceName,
  sharedSliceName,
  userDataSliceName,
} from '../../../redux/constants';
import {
  WEBSOCKET_CATEGORY_LIBRARY,
  WEBSOCKET_CATEGORY_SHARED_WITH_ME,
  WEBSOCKET_CATEGORY_SHARED_WITH_TEAM,
  useWebSocketContext,
} from './WebSocketContext';
import { MY_LIBRARY, SHARED_WITH_ME, userTeamNameKey } from '@/Utils/constants';
import { getTeamNameLabel, openProcessedFile } from '@/helpers/datasetsHelper';

const refreshLibraryFolderStructure = (
  currentDirHandle,
  currentDatasetFiles,
  currentDatasetFolderStructure,
  topLevelParentDirectory
) => {
  const folderStructure = JSON.parse(JSON.stringify(currentDatasetFolderStructure));

  function updateChildren(metadata) {
    if (currentDirHandle === metadata?.FileUuid) {
      return currentDatasetFiles?.map((e) => ({
        ...e.metadata,
        Children: [],
      }));
    } else if (metadata.Children?.length > 0) {
      return metadata.Children.map((child) => ({
        ...child,
        Children: updateChildren(child),
      }));
    } else {
      return metadata.Children;
    }
  }

  if (!currentDirHandle || currentDirHandle === '') {
    return [
      {
        FileName: topLevelParentDirectory,
        FileUuid: 0,
        Children: currentDatasetFiles?.map((e) => ({
          ...e.metadata,
          Children: [],
        })),
      },
    ];
  } else {
    return folderStructure.map((e) => {
      const updatedNode = { ...e, Children: updateChildren(e) };
      return updatedNode;
    });
  }
};

const getLoadedStructure = (sortData, libraryStructure = []) => {
  try {
    const modifiedSharedWithMeFiles = libraryStructure.map((e) => ({
      ...e,
      FileColumns: getColumnsCount(e.metadata),
    }));

    return sortColumnData(modifiedSharedWithMeFiles, sortData.direction, sortData.columnName);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getWebSocketCategory = (currentDataset) => {
  if (currentDataset === MY_LIBRARY) return WEBSOCKET_CATEGORY_LIBRARY;
  if (currentDataset === SHARED_WITH_ME) return WEBSOCKET_CATEGORY_SHARED_WITH_ME;
  if (currentDataset.includes('Workspace')) return WEBSOCKET_CATEGORY_SHARED_WITH_TEAM;
  throw new Error(`Category Doesn't Exist: ${currentDataset}`);
};

export const WebSocketContainer = () => {
  const dispatch = useDispatch();
  const { lastMessage, spaceUsedLastMessage } = useWebSocketContext();
  const spaceUsed = useRef();
  const {
    sortingData,
    currentDir,
    folderStructure,
    sharedWithMeFolderStructure,
    sharedWithMyTeamFolderStructure,
    processingFiles,
  } = useSelector((state) => state[datasetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { isRanByCypress } = useSelector((state) => state[sharedSliceName]);
  const { auth0IdToken } = useSelector((state) => state[authenticationSliceName]);
  const { maxStorageSize } = user?.userProperties || {};

  useEffect(() => {
    if ((lastMessage !== null && auth0IdToken) || (isRanByCypress && lastMessage !== null)) {
      try {
        const files = JSON.parse(lastMessage?.data);

        switch (files.category) {
          case WEBSOCKET_CATEGORY_LIBRARY: {
            if (spaceUsed.current?.space_used) {
              dispatch(
                setFileSizeProperties({ spaceUsed: spaceUsed.current.space_used, maxStorageSize })
              );
            }

            const loadedStructure = getLoadedStructure(sortingData, files.files);
            dispatch(setLibraryFiles(loadedStructure));
            dispatch(
              setLibraryFolderStructureByName({
                name: 'folderStructure',
                value: refreshLibraryFolderStructure(
                  currentDir,
                  loadedStructure,
                  folderStructure,
                  MY_LIBRARY
                ),
              })
            );

            const res = openProcessedFile(processingFiles, loadedStructure);
            dispatch(setProcessingFiles(res));

            break;
          }
          case WEBSOCKET_CATEGORY_SHARED_WITH_ME: {
            const loadedStructure = getLoadedStructure(sortingData, files.shared_files);
            dispatch(setLibrarySharedWithMe(loadedStructure));

            dispatch(
              setLibraryFolderStructureByName({
                name: 'sharedWithMeFolderStructure',
                value: refreshLibraryFolderStructure(
                  currentDir,
                  loadedStructure,
                  sharedWithMeFolderStructure,
                  SHARED_WITH_ME
                ),
              })
            );

            break;
          }
          case WEBSOCKET_CATEGORY_SHARED_WITH_TEAM: {
            const loadedStructure = getLoadedStructure(sortingData, files.shared_files);
            dispatch(setLibrarySharedWithTeam(loadedStructure));

            dispatch(
              setLibraryFolderStructureByName({
                name: 'sharedWithMyTeamFolderStructure',
                value: refreshLibraryFolderStructure(
                  currentDir,
                  loadedStructure,
                  sharedWithMyTeamFolderStructure,
                  getTeamNameLabel(user[userTeamNameKey])
                ),
              })
            );

            break;
          }
          default:
            console.error('library websocket: missing "category"');
        }
      } catch (error) {
        console.error('WebSocket message parsing error:', error);
      }
    }
  }, [lastMessage, auth0IdToken, isRanByCypress]);

  useEffect(() => {
    if (
      (spaceUsedLastMessage !== null && auth0IdToken) ||
      (isRanByCypress && spaceUsedLastMessage !== null)
    ) {
      spaceUsed.current = JSON.parse(spaceUsedLastMessage?.data);
    }
  }, [spaceUsedLastMessage, auth0IdToken]);

  return null;
};
