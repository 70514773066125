import { Tab } from '@headlessui/react';
import clsx from 'clsx';

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface Props {
  tabs: TabItem[];
  selectedIndex: number;
  onTabChange: () => void;
}

export const Tabs = ({ tabs, selectedIndex, onTabChange = () => {} }: Props) => {
  // Can't dynamically set the tailwind flex-basis of the tabs, add more logic here if you need more than 2 tabs
  const flexBasis = tabs.length === 2 && 'basis-1/2';
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <Tab.List className='flex flex-row h-8 border-b border-gray-200 bg-ui'>
        {tabs.map((tab, index) => (
          <Tab
            data-cy={`tab-list-${tab.label}`}
            key={index}
            className={({ selected }) =>
              clsx(
                'whitespace-nowrap py-1 font-normal text-sm bg-transparent outline-none flex flex-row items-center px-2 gap-1 justify-center',
                flexBasis,
                selected && 'border-b-ocean-blue-600 border-b-2 text-ocean-blue-600',
                !selected && 'text-ui-secondary border-0'
              )
            }
          >
            {tab.label}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map((tab, index) => (
          <Tab.Panel key={index} className='pt-4 pb-12 overflow-scroll h-[85vh]'>
            {tab.content}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
