export const EXPAND_ALL = 'Expand All';
export const COLLAPSE_ALL = 'Collapse All';

export const orEndSelectorOptions = [
  {
    label: 'or',
    value: true,
  },
  {
    label: 'and',
    value: false,
  },
];

export const defaultFilterElement = { colId: undefined, isCaseSensitive: false, isChecked: false };
