import React, { useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { get, post } from '@/Utils/API.js';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import Spinner from '@/Components/UI/Spinner/Spinner';
import { Select } from '@/Components/UI/Form/Select/Select';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { Button, Link } from '@/Components/UI/Button/Button';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath.js';
import { setNewEnrichmentStarted } from '@/redux/reducers/sharedSlice.js';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice.js';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import {
  MODAL_CUSTOM_ENRICHMENT,
  MODAL_DATA_ENRICHMENT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_CUSTOM_ENRICHMENT_SUCCESS,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  userRolesKey,
} from '@/Utils/constants.js';
import {
  formatCurlJSONtoString,
  getSelectedFieldNamesFromIndex,
  parseCurlCommandToJSON,
  parseFormattedCurlToJSON,
  swapLabelForValue,
  transformResponseDataToTableFormat,
} from '@/helpers/enrichmentHelper.js';

import FieldTable from './FieldTable.jsx';
import withModal from '../withModalHOC.jsx';
import { setEnrichmentCredits } from '@/redux/reducers/spreadsheetSlice.js';
import { useCurrentFile } from '@/hooks/useCurrentFile.js';

const fileId = getFileUuidFromPath();

const CustomEnrichmentModal = ({ show: isOpen, hideModal: closeModal, filterModel, sortModel }) => {
  const limitOfSelectedColumn = 100;
  const { user } = useSelector((state) => state[userDataSliceName]);
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const textareaRef = useRef();
  const { columnDefs, filteredNumberOfRows, enrichmentCredits } = useSelector(
    (state) => state[spreadsheetSliceName]
  );

  const options = useMemo(() => {
    return columnDefs.map((colDef) => ({
      label: colDef.headerName,
      value: colDef.field,
      type: colDef.fieldType,
    }));
  }, [columnDefs]);

  const [curlRequestText, setCurlRequestText] = useState('');
  const [formattedCurlText, setFormattedCurlText] = useState('');
  const [originalFormattedCurlText, setOriginalFormattedCurlText] = useState('');
  const [isInsertAndFormatComplete, setIsInsertAndFormatComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [formattedResponseData, setFormattedResponseData] = useState([]);
  const [selectedFields, setSelectedFields] = useState(null);
  const [outputPathMap, setOutputPathMap] = useState([]);
  const [, setSelection] = useState('');
  const [selectedStep, setSelectedStep] = useState(1);
  const [uniqueColumnValues, setUniqueColumnValues] = useState(0);
  const [targetColumn, setTargetColumn] = useState(null);
  const [usedColumns, setUsedColumns] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const [responseType, setResponseType] = useState('');

  useEffect(() => {
    setTargetColumn(options[0]);
  }, [options]);

  const focusTexarea = () => {
    textareaRef.current?.focus();
  };

  useEffect(() => {
    if (formattedResponseData && selectedFields) {
      const fieldsToApply = getSelectedFieldNamesFromIndex(
        selectedFields,
        Object.entries(formattedResponseData[0])
      );

      if (fieldsToApply && fieldsToApply.length > 0) {
        setOutputPathMap(
          fieldsToApply.map((field) => {
            return { path: field[1].fieldNamePath, name: field[0] };
          })
        );
      } else {
        setOutputPathMap([]);
      }
    }
  }, [selectedFields, formattedResponseData, filteredData]);

  const onChangeTargetColumn = (newTargetColumnValue) => {
    options.map((option) => {
      if (option.value === newTargetColumnValue) {
        setTargetColumn(option);
      }
    });
  };

  useEffect(() => {
    let interval;
    if (targetColumn) {
      interval = setTimeout(() => {
        focusTexarea();
      }, 50);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [targetColumn]);

  const handleSelect = () => {
    const textSelection = window.getSelection();
    if (textSelection && textSelection.rangeCount > 0) {
      const { activeElement } = document;
      if (activeElement && activeElement.value) {
        setSelection(
          activeElement.value.substring(activeElement.selectionStart, activeElement.selectionEnd)
        );
      }
    }
  };

  const handleCurlRequestChange = (event) => {
    const { value } = event.target;
    setCurlRequestText(value);
  };

  const handleFormattedCurlTextChange = (event) => {
    const { value } = event.target;
    setFormattedCurlText(value);
  };

  const returnToEnrichments = () => {
    dispatch(hideAllModals());
    dispatch(showModal({ name: MODAL_DATA_ENRICHMENT }));
  };

  const goBackAStep = () => {
    if (errorMessage && selectedStep === 2) {
      setErrorMessage('');
    } else {
      if (selectedStep === 2) {
        setUsedColumns([]);
      } else if (selectedStep === 4) {
        setOutputPathMap([]);
      }
      setSelectedStep(selectedStep - 1);
    }
  };

  const formatField = () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    const curlJSON = parseCurlCommandToJSON(curlRequestText);
    const prettyPrintedCurl = formatCurlJSONtoString(curlJSON);
    setFormattedCurlText(prettyPrintedCurl);
    setOriginalFormattedCurlText(prettyPrintedCurl);
    setSelectedStep(2);
  };

  const fetchUniqueColumnValues = async () => {
    try {
      const uniqueValues = filteredNumberOfRows || 0;
      setUniqueColumnValues(
        user[userRolesKey].includes('giga-feature-customenrichfull')
          ? uniqueValues
          : uniqueValues > 1000
          ? 1000
          : uniqueValues
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchEnrichmentCredits = async () => {
    try {
      const response = await get('user/enrichment-credits');
      if (!response?.Limit) {
        dispatch(setEnrichmentCredits({ Limit: 0, Used: 0 }));
      }
      dispatch(setEnrichmentCredits(response));
    } catch (error) {
      throw new Error(error);
    }
  };

  const onApply = async () => {
    setIsFetching(true);

    if (outputPathMap.length > limitOfSelectedColumn) {
      return ShowToast({
        type: TOAST_TYPE_ERROR,
        text: `You have reached the maximum size of ${limitOfSelectedColumn} selected columns. Please adjust your selection to continue`,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
    setSelectedStep(4);
    await fetchEnrichmentCredits();
    await fetchUniqueColumnValues();

    setIsFetching(false);
  };

  const insertColumnReference = () => {
    const insertValue = `****${targetColumn.label}****`;
    const { selectionStart, selectionEnd } = textareaRef.current;
    const formattedAndInsertedText =
      formattedCurlText.slice(0, selectionStart) +
      insertValue +
      formattedCurlText.slice(selectionEnd);
    setFormattedCurlText(formattedAndInsertedText);
    setIsInsertAndFormatComplete(true);
    setUsedColumns([...usedColumns, targetColumn]);
  };

  const resetSteps = () => {
    setErrorMessage('');
    setIsFetching(false);
    setIsInsertAndFormatComplete(false);
    setFormattedCurlText(originalFormattedCurlText);
    setFormattedResponseData(null);
    setSelectedStep(1);
    setUsedColumns([]);
    setResponseType('');
  };

  const clearSteps = () => {
    setErrorMessage('');
    setIsFetching(false);
    setIsInsertAndFormatComplete(false);
    setCurlRequestText('');
    setFormattedCurlText('');
    setFormattedResponseData(null);
    setSelectedStep(1);
    setOutputPathMap([]);
    setUsedColumns([]);
    setResponseType('');
  };

  const onCancel = () => {
    resetSteps();
    closeModal();
  };

  const swapAllLabelsForValues = (text) => {
    let swappedText = text;
    usedColumns.map((column) => {
      swappedText = swapLabelForValue(swappedText, column);
    });
    return swappedText;
  };

  const getFieldsToSend = async () => {
    setIsFetching(true);
    const swappedRequestText = swapAllLabelsForValues(formattedCurlText);
    if (!swappedRequestText) {
      setErrorMessage('Please insert a column reference in the request body');
    }

    const curlJSON = parseFormattedCurlToJSON(swappedRequestText);
    if (!curlJSON) {
      setErrorMessage('Parse to Json Failed');
    }

    curlJSON['filter_model'] = { ...filterModel };
    curlJSON['sort_model'] = sortModel;

    const response = await post(`enrich/user-defined-http/${fileId}/preview`, {
      ...curlJSON,
    });
    if (response?.response_data?.length <= 0 && response?.response_failures?.length <= 0) {
      setErrorMessage('Results not found');
      setIsFetching(false);
      return;
    }

    if (response.response_data) {
      setFormattedResponseData(
        transformResponseDataToTableFormat(
          response.response_data.length > 0 ? response?.response_data : response?.response_failures
        )
      );
      if (response?.response_type) {
        setResponseType(response?.response_type);
      }
      setSelectedStep(3);
    } else {
      setErrorMessage(response.Message || response.message || '');
    }
    setIsFetching(false);

    return response;
  };

  const applyEnrichments = async () => {
    const swappedRequestText = swapAllLabelsForValues(formattedCurlText);
    const curlJSON = parseFormattedCurlToJSON(swappedRequestText);
    curlJSON['output_path_map'] = outputPathMap;
    curlJSON['email_on_complete'] = user.email;
    curlJSON['filter_model'] = { ...filterModel };
    curlJSON['sort_model'] = sortModel;
    if (responseType) {
      curlJSON['response_type'] = responseType;
    }

    const endpoint = `enrich/user-defined-http/${fileId}/apply`;
    const payload = {
      ...curlJSON,
    };
    const { success } = await post(endpoint, payload);

    if (success) {
      ShowToast({ type: TOAST_TYPE_INFO, text: TOAST_TEXT_CUSTOM_ENRICHMENT_SUCCESS });
      dispatch(setNewEnrichmentStarted(true));
      clearSteps();
      onCancel();
      dispatch(hideAllModals());
    }
  };

  const isReferenceInText = () => {
    if (!usedColumns.length) return false;

    return usedColumns?.every(({ label }) => {
      return formattedCurlText.includes(`****${label}****`);
    });
  };

  const stepSeparator = <span className='border border-shadow-400 w-[78px]'></span>;

  const stepTracker = (
    <>
      <div className='flex items-center justify-center w-full my-4 gap-x-4'>
        {[1, 2, 3, 4].map((step) => (
          <React.Fragment key={step}>
            <div className='flex flex-col items-center justify-center gap-y-1'>
              <div
                data-cy={step === 4 ? `${step}-last` : `${step}`}
                className={clsx(
                  'w-8 h-8 rounded-full flex items-center justify-center text-xs font-bold text-white',
                  errorMessage && selectedStep === step && selectedStep === 2
                    ? 'bg-violet-web-700'
                    : selectedStep === step || selectedStep > step
                    ? 'bg-pear-700'
                    : 'bg-shadow-500'
                )}
              >
                {errorMessage && selectedStep === step && selectedStep === 2 ? (
                  <Icon name='info' />
                ) : selectedStep > step ? (
                  <Icon name='check' />
                ) : (
                  step
                )}
              </div>
              <p className='uppercase text-[10px] text-ui-helper font-extrabold'>
                {step === 1 && 'Curl Request'}
                {step === 2 && 'Add Sheet Values'}
                {step === 3 && 'Select Response Fields'}
                {step === 4 && 'Run'}
              </p>
            </div>
            {step !== 4 && stepSeparator}
          </React.Fragment>
        ))}
      </div>
    </>
  );

  const stepOne = (
    <>
      <div className='min-h-[14rem]'>
        <Textarea
          dataCy='enter-curl'
          label='Enter cURL Request Below'
          placeholder="curl 'https://api.ip2location.io/?key=012346789ABCDEF&ip=8.8.8.8'"
          value={curlRequestText}
          helperText='Before moving to the next step include any necessary tokens or  fixed parameters within the curl request.'
          helperTextPosition='top'
          onChange={handleCurlRequestChange}
          className={clsx('h-48')}
        />
      </div>

      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button
          color='shadow'
          dataCy='return-to-enrichments-btn'
          className='float-left'
          onClick={returnToEnrichments}
        >
          Return to Enrichments
        </Button>

        <Button
          className='mr-[16px]'
          variant='ghost'
          color='shadow'
          dataCy='cancel-btn'
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          color={'oceanBlue'}
          dataCy='save-btn'
          onClick={formatField}
          disabled={curlRequestText === ''}
        >
          Next
        </Button>
      </div>
    </>
  );

  const stepTwo = (
    <>
      {errorMessage ? (
        <div className='text-center flex items-center justify-center min-h-[14rem] flex-col gap-y-2'>
          <p className='text-base font-semibold text-midnight'>Error Running Request</p>
          <p>{errorMessage}</p>
        </div>
      ) : isFetching ? (
        <div className='text-center flex items-center justify-center min-h-[14rem]'>
          <Spinner size='medium' color='shadow' className={'inline-block'} />
          <p className='inline-block ml-2'>Determining Fields..</p>
        </div>
      ) : (
        <>
          <div className='flex flex-row gap-x-4 min-h-[14rem]'>
            <div className='basis-7/12'>
              <Textarea
                dataCy='add-sheet-values'
                label={'Add Sheet Values'}
                placeholder=''
                value={formattedCurlText}
                helperText='first, Select the text or place your cursor where column value should be inserted.'
                helperTextPosition='top'
                onChange={handleFormattedCurlTextChange}
                className={clsx('h-48 selection:bg-sunrise')}
                onMouseUp={handleSelect}
                onKeyUp={handleSelect}
                ref={textareaRef}
              />
            </div>
            <div className='basis-5/12'>
              <InputField
                dataCy='select-column-reference'
                label='Select Column Reference(s)'
                helperText='Next, select column to insert. Repeat previous instructions for each reference.'
                helperTextPosition='top'
              />

              {targetColumn && (
                <Select
                  dataCy='targetColumnOptions'
                  options={options}
                  onChange={onChangeTargetColumn}
                  value={targetColumn.value}
                  className='h-8 w-60'
                />
              )}

              <Button
                dataCy='insert-column-reference'
                color='midnight'
                variant='outline'
                size='small'
                iconName='plus-circle'
                className='mt-2'
                disabled={!targetColumn}
                onClick={insertColumnReference}
              >
                Insert Column Reference
              </Button>
            </div>
          </div>
        </>
      )}
      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button
          color='shadow'
          dataCy='reset-field-btn'
          className='float-left'
          onClick={goBackAStep}
        >
          Back
        </Button>

        <Button
          className='mr-[16px]'
          variant='ghost'
          color='shadow'
          dataCy='cancel-btn'
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          color={'oceanBlue'}
          dataCy='save-btn'
          onClick={getFieldsToSend}
          disabled={!isInsertAndFormatComplete || !usedColumns.length || !isReferenceInText()}
        >
          Test
        </Button>
      </div>
    </>
  );

  const stepThree = (
    <>
      <div className={clsx('min-h-[14rem]', isFetching && 'flex items-center justify-center')}>
        {formattedResponseData && (
          <>
            <InputField
              dataCy='select-import-fields'
              label='Select Fields to Import'
              helperText='HTTP Response data preview is limited to 3 records. Selected fields will be imported as new columns.'
              helperTextPosition='top'
            />
            <FieldTable
              setSelectedFields={setSelectedFields}
              responseData={formattedResponseData}
              setFilteredResponseData={setFilteredData}
            />
          </>
        )}
      </div>
      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button
          color='shadow'
          dataCy='reset-field-btn'
          className='float-left'
          onClick={goBackAStep}
        >
          Back
        </Button>

        <Button
          className='mr-[16px]'
          variant='ghost'
          color='shadow'
          dataCy='cancel-btn'
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          color={'oceanBlue'}
          dataCy='save-btn'
          onClick={onApply}
          disabled={outputPathMap.length === 0}
        >
          Apply
        </Button>
      </div>
    </>
  );

  const stepFour = (
    <>
      <div className='text-left flex items-start justify-center min-h-[14rem] flex-col gap-y-2'>
        {isFetching ? (
          <div className='flex items-center justify-center w-full'>
            <Spinner size='medium' color='shadow' className={'inline-block'} />
          </div>
        ) : (
          <>
            {enrichmentCredits.Used + uniqueColumnValues <= enrichmentCredits.Limit ? (
              <>
                <p className='text-base font-semibold text-midnight'>Are you sure?</p>
                <p className='mb-4 text-sm font-normal text-ui'>
                  {`${uniqueColumnValues} requests will be run. You have used ${enrichmentCredits.Used} of ${enrichmentCredits.Limit} enrichment credits. Depending on the third-party service used this could incur
      additional charges.`}
                </p>
                <p className='text-sm font-semibold text-ui'>
                  Are you sure you&apos;d like to proceed?
                </p>
              </>
            ) : (
              <p className='w-full mb-4 text-sm font-normal text-center text-ui'>
                <p className='p-1 text-base font-semibold text-midnight'>
                  Unable to run enrichment.
                </p>
                {`This request exceeds remaining ${
                  enrichmentCredits.Limit - enrichmentCredits.Used
                } of ${enrichmentCredits.Limit} enrichment credits. `}
                <Link
                  to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
                  target='_blank'
                  className='inline-block'
                  size='medium'
                >
                  Contact support
                </Link>{' '}
                for more information.
              </p>
            )}
          </>
        )}
      </div>

      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button
          color='shadow'
          dataCy='reset-field-btn'
          className='float-left'
          onClick={goBackAStep}
        >
          Back
        </Button>

        <Button className='mr-[16px]' variant='ghost' color='shadow' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          dataCy='save-btn'
          variant='solid'
          color='sunrise'
          onClick={applyEnrichments}
          disabled={enrichmentCredits.Used + uniqueColumnValues >= enrichmentCredits.Limit}
        >
          Run
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      title='Custom Enrichment (cURL)'
      iconName='atom'
      size='large'
      isOpen={isOpen}
      onClose={onCancel}
      shouldCloseOnBackgroundClick={false}
      articleID={69000835545}
    >
      <p className='pr-8 mb-4 ml-8 -mt-6 text-base font-semibold text-ui-secondary'>
        Gigasheet will make multiple API requests, using the values from each row in your sheet; up
        to 10,000 rows.{' '}
        <Link
          to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
          target='_blank'
          className='inline-block'
          size='medium'
        >
          Contact support
        </Link>{' '}
        for full access.
      </p>

      {stepTracker}

      <div>
        {selectedStep === 1 && stepOne}
        {selectedStep === 2 && stepTwo}
        {selectedStep === 3 && stepThree}
        {selectedStep === 4 && stepFour}
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CUSTOM_ENRICHMENT })(CustomEnrichmentModal);
