import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import { setEnrichmentId } from '@/redux/reducers/enrichmentTemplates';
import { showModal } from '@/redux/reducers/modalsSlice';
import {
  MODAL_CUSTOM_ENRICHMENT,
  MODAL_EMAIL_ALEXA_RANK_ENRICHMENT,
  MODAL_ENRICHMENT,
  MODAL_IP_GEOLOCATION_ENRICHMENT,
  MODAL_IP_OSINT_ENRICHMENT,
  MODAL_REVENUE_BASE,
} from '@/Utils/constants';

import * as enrichmentLogos from './assets/index';
import { truncateText } from '../../../../Utils/enrichmentUtils/EnrichmentUtils';

const BUILD_YOUR_OWN = 'Build Your Own';
const EMAIL_ALEXA_RANK = 'Email Format and Alexa Rank';
const THREAD_DATA = 'Threat Data';
const REVENUE_BASE_PERSON_LINKEDIN = 'Enrich Person from LinkedIn';
const REVENUE_BASE_PERSON_EMAIL = 'Enrich Person from Email';
const REVENUE_BASE_COMPANY_LINKEDIN = 'Enrich Company from LinkedIn';
const REVENUE_BASE_COMPANY_DOMAIN = 'Enrich Company from URL';

export const EnrichmentListItem = ({ recipe, handleSetStep }) => {
  const dispatch = useDispatch();
  const {
    endpoint_name,
    endpoint_description,
    endpoint_logo,
    vendor_name,
    id,
    url_description,
    help_url,
  } = recipe;

  const vendorActionsMap = {
    [BUILD_YOUR_OWN]: () => dispatch(showModal({ name: MODAL_CUSTOM_ENRICHMENT })),
    [EMAIL_ALEXA_RANK]: () => dispatch(showModal({ name: MODAL_EMAIL_ALEXA_RANK_ENRICHMENT })),
    [THREAD_DATA]: () =>
      dispatch(showModal({ name: MODAL_ENRICHMENT, props: { enrichType: 'IP' } })),
    Geolocation: () => dispatch(showModal({ name: MODAL_IP_GEOLOCATION_ENRICHMENT })),
    OSINT: () =>
      dispatch(showModal({ name: MODAL_IP_OSINT_ENRICHMENT, props: { endpoint_description } })),
    [REVENUE_BASE_PERSON_LINKEDIN]: () =>
      dispatch(
        showModal({
          name: MODAL_REVENUE_BASE,
          props: {
            endpoint_description,
            url_description,
            help_url,
            endpoint_name,
            endpoint_type: 'linkedin',
            endpoint_label: 'LinkedIn URL Column',
          },
        })
      ),
    [REVENUE_BASE_COMPANY_LINKEDIN]: () =>
      dispatch(
        showModal({
          name: MODAL_REVENUE_BASE,
          props: {
            endpoint_description,
            url_description,
            help_url,
            endpoint_name,
            endpoint_type: 'org-linkedin',
            endpoint_label: 'LinkedIn Company URL Column',
          },
        })
      ),
    [REVENUE_BASE_PERSON_EMAIL]: () =>
      dispatch(
        showModal({
          name: MODAL_REVENUE_BASE,
          props: {
            endpoint_description,
            url_description,
            help_url,
            endpoint_name,
            endpoint_type: 'email',
            endpoint_label: 'Email Column',
          },
        })
      ),
    [REVENUE_BASE_COMPANY_DOMAIN]: () =>
      dispatch(
        showModal({
          name: MODAL_REVENUE_BASE,
          props: {
            endpoint_description,
            url_description,
            help_url,
            endpoint_name,
            endpoint_type: 'company-domain',
            endpoint_label: 'Company Domain Column',
          },
        })
      ),
  };

  const excludedEndpointNames = [
    BUILD_YOUR_OWN,
    THREAD_DATA,
    'Geolocation',
    'OSINT',
    EMAIL_ALEXA_RANK,
    REVENUE_BASE_PERSON_LINKEDIN,
    REVENUE_BASE_PERSON_EMAIL,
    REVENUE_BASE_COMPANY_LINKEDIN,
    REVENUE_BASE_COMPANY_DOMAIN,
  ];

  const action =
    vendorActionsMap[endpoint_name] ||
    (() => {
      dispatch(setEnrichmentId(id));
      handleSetStep(2);
    });

  const endpointName = excludedEndpointNames
    .map((name) => name.toLowerCase())
    .includes(vendor_name.toLowerCase())
    ? vendor_name
    : `${endpoint_name}`;

  return (
    <motion.div
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.5 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.4 }}
      layoutId={id}
      data-cy={`enrichment-${endpoint_name}`}
      role='button'
      tabIndex={0}
      onKeyUp={() => {}}
      onClick={action}
      className='cursor-pointer p-[16px] h-[80px]  flex items-center bg-fbfbfb border border-d6d6d6 shadow-xs rounded-xl m-[6px] relative hover:border-ocean-blue-500 overflow-hidden'
    >
      <div className='w-[40px] h-[40px] overflow-hidden'>
        <img
          className='w-full h-full'
          src={enrichmentLogos[endpoint_logo]}
          alt={`${endpoint_name}`}
        />
      </div>
      <div className='w-full max-w-[246px] pl-[16px]  overflow-hidden'>
        <Tooltip text={endpointName.length >= 34 && endpointName} side='bottom'>
          <p className='truncate max-w-[260px] flex items-center h-5 mb-1 text-sm font-semibold w-fit text-ui'>
            {truncateText(endpointName, 34)}
          </p>
        </Tooltip>

        <p className='text-[13px] text-ui-helper leading-normal'>
          {truncateText(endpoint_description)}
        </p>
      </div>
    </motion.div>
  );
};
