import { Button } from '@/Components/UI/Button/Button';

const ShareFileFooter = ({
  showDoneBtn,
  onDoneBtnClick,
  sendInvite,
  onCancelBtnClick,
  onCopyBtnClick,
  onEmbedCodeBtn,
  loadingTextBtn,
  isDoneButtonDisabled,
}) => {
  return (
    <div className='share-file-footer'>
      <div>
        <Button
          color='oceanBlue'
          dataCy='sharing-copy-link'
          disabled={!showDoneBtn}
          variant='ghost'
          onClick={onCopyBtnClick}
          iconName='link-simple'
        >
          Copy Link
        </Button>
        <Button
          color='oceanBlue'
          dataCy='sharing-embed-code'
          variant='ghost'
          onClick={onEmbedCodeBtn}
          iconName='code'
        >
          Embed
        </Button>
      </div>

      {showDoneBtn ? (
        <Button
          onClick={onDoneBtnClick}
          color='midnight'
          dataCy='sharing-done-btn'
          disabled={loadingTextBtn || isDoneButtonDisabled}
        >
          {loadingTextBtn ? loadingTextBtn : 'Done'}
        </Button>
      ) : (
        <div>
          <Button
            color='midnight'
            variant='ghost'
            onClick={onCancelBtnClick}
            dataCy='sharing-cancel-btn'
          >
            Cancel
          </Button>
          <Button
            color='midnight'
            onClick={sendInvite}
            dataCy='sharing-invite-btn'
            className='ml-4'
            disabled={loadingTextBtn}
          >
            {loadingTextBtn ? loadingTextBtn : 'Invite'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShareFileFooter;
