import * as React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Shadcn/Popover';

type PopoverProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
};

const PopoverV2 = ({ content, children, open, onOpenChange, disabled = false }: PopoverProps) => {
  const [internalOpen, setInternalOpen] = React.useState(false);
  const isControlled = open !== undefined;

  const handleOpenChange = (newOpen: boolean) => {
    if (!isControlled) {
      setInternalOpen(newOpen);
    }
    if (onOpenChange) {
      onOpenChange(newOpen);
    }
  };

  return disabled ? (
    <>{children}</>
  ) : (
    <Popover open={isControlled ? open : internalOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild disabled={disabled}>
        {children}
      </PopoverTrigger>
      <PopoverContent align='end' className='bg-white' usePortal>
        {content}
      </PopoverContent>
    </Popover>
  );
};

export default PopoverV2;
