import { useContext } from 'react';
import { ViewportContext } from '@/Components/ViewportProvider/ViewportProvider';

const useViewportProvider = () => {
  const { screenWidth, screenHeight } = useContext(ViewportContext);
  return {
    screenWidth,
    screenHeight,
    isNarrowScreen: screenHeight < 768,
    isMobileScreen: screenWidth < 768 || navigator.userAgent.indexOf('Mobi') > -1,
    isMobile: screenWidth < 768,
  };
};

export default useViewportProvider;
