const toNonTechnicalType = (technicalType) => {
  switch (technicalType) {
    case 'DateTime':
    case 'DateTime64': {
      return 'Date';
    }
    case 'String': {
      return 'Plain Text';
    }
    case 'EmailAddress': {
      return 'Email Address';
    }
    case 'Int64':
    case 'UInt64':
    case 'Float64': {
      return 'Number';
    }
    case 'Boolean': {
      return 'Boolean';
    }
    case 'IPv4':
    case 'IPv6': {
      return 'IP Address';
    }
    case 'URL': {
      return 'URL';
    }
    default: {
      return technicalType;
    }
  }
};
export default toNonTechnicalType;
