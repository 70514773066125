import React, { useEffect, useRef } from 'react';
import CommentEntry from '@/Components/Modals/Comments/CommentEntry';
import CommentInput from '@/Components/Modals/Comments/CommentInput';

const CommentThread = ({
  thread,
  column,
  row,
  getThread,
  isColumnComment,
  autofillOptions,
  sendCreateCommentRequest,
  requestIsSending,
}) => {
  const textareaRef = useRef();

  useEffect(() => {
    focusTextArea();
  }, [thread]);

  const focusTextArea = () => {
    textareaRef.current?.focus();
  };

  return (
    <div>
      <div>
        {thread.map((comment, index) => {
          return (
            <CommentEntry
              key={index}
              comment={comment}
              column={column}
              row={row}
              isColumnComment={isColumnComment}
              getThread={getThread}
              index={index}
              thread={thread}
              autofillOptions={autofillOptions}
            />
          );
        })}
      </div>
      <CommentInput
        placeholder='Reply or notify others with @'
        sendCreateCommentRequest={sendCreateCommentRequest}
        requestIsSending={requestIsSending}
        autofillOptions={autofillOptions}
        ref={textareaRef}
      />
    </div>
  );
};

export default CommentThread;
