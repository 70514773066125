import { type KeyboardEvent, forwardRef, type ReactNode } from 'react';

import { Button } from '../UI/Button/Button';
import clsx from 'clsx';

export type AutocompleteItem = {
  value: string;
};

type Autocomplete = {
  autocompleteList: AutocompleteItem[];
  inputValue: string;
  onAutocompleteValueClick: (v: string) => void;
  children: ReactNode;
  className?: string;
  showMatchAfter?: number;
  disabled?: boolean;
  autocompleteListLimit?: number;
};

const AutocompleteWrapper = forwardRef<HTMLInputElement, Autocomplete>(
  (
    {
      autocompleteList,
      inputValue,
      onAutocompleteValueClick,
      showMatchAfter = 1,
      className = '',
      children,
      disabled = false,
      autocompleteListLimit = 100,
    },
    ref
  ) => {
    const autocompleteCondition =
      showMatchAfter === 0
        ? !disabled && autocompleteList?.length
        : !disabled && autocompleteList?.length && inputValue?.length > showMatchAfter;

    const _autocompleteList = autocompleteCondition
      ? autocompleteList.filter(
          ({ value }, i) =>
            value.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()) &&
            value.toLocaleLowerCase() !== inputValue.toLocaleLowerCase() &&
            i < autocompleteListLimit
        )
      : [];

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>, value: string) => {
      if (e.key === 'Enter') {
        onAutocompleteValueClick(value);
      }
    };

    return (
      <div className='relative'>
        <div>{children}</div>
        {_autocompleteList.length ? (
          <div
            ref={ref}
            className={clsx(
              'absolute mt-1 z-10 p-1 w-full bg-white text-ui border-ui-secondary border-2 max-h-36 !overflow-y-auto rounded-b shadow-pear-100',
              className
            )}
            data-cy='autocomplete-body'
          >
            <ul>
              {_autocompleteList.map(({ value }, i) => {
                return (
                  <li
                    key={i}
                    className='leading-8 hover:bg-ocean-blue-100 hover:text-ui hover:rounded focus-within:bg-ocean-blue-100 focus-within:text-ui focus-within:rounded'
                  >
                    <Button
                      className='!w-full !justify-start'
                      onClick={() => onAutocompleteValueClick(value)}
                      variant='ghost'
                      onKeyDown={(e) => handleKeyDown(e, value)}
                      dataCy={`autocomplete-option-${value}`}
                    >
                      {value}
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
);

export default AutocompleteWrapper;
