import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { spreadsheetSliceName } from '@/redux/constants';

import { Button } from '@/Components/UI/Button/Button';
import { Select } from '@/Components/UI/Form/Select/Select';
import Icon from '@/Components/UI/Icon/Icon';

const SortColumnsRow = ({ rowIndex, sortedColumn, sortData, setSortData }) => {
  const [unsortedColumns, setUnsortedColumns] = useState([]);

  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const allOptions = useMemo(
    () =>
      columnDefs.map((colDef) => ({
        value: colDef.field,
        label: colDef.headerName,
        type: colDef.fieldType,
        sortDirection: 'asc',
      })),
    [columnDefs]
  );
  const getSortingOrderOptions = (type) => [
    { value: 'asc', label: type !== 'String' && type !== 'EmailAddress' ? '1 to 9' : 'A to Z' },
    { value: 'desc', label: type !== 'String' && type !== 'EmailAddress' ? '9 to 1' : 'Z to A' },
  ];

  const getDefaultSortDirectionValue = (sortedColumn) => ({
    value: sortedColumn.sortDirection,
    label: getSortingOrderOptions(sortedColumn.type).find(
      ({ value }) => value === sortedColumn.sortDirection
    ).label,
  });

  const removeColumnToSort = () => {
    setSortData(sortData.filter((_, index) => index !== rowIndex));
  };

  const changeColumnToSort = ({ value, label, type }) => {
    const sortDataCopy = [...sortData];
    sortDataCopy[rowIndex] = { ...sortDataCopy[rowIndex], value, label, type };
    setSortData(sortDataCopy);
  };

  const changeSortDirection = (value) => {
    const sortDataCopy = [...sortData];
    sortDataCopy[rowIndex] = { ...sortDataCopy[rowIndex], sortDirection: value };
    setSortData(sortDataCopy);
  };

  useEffect(() => {
    const _unsortedColumns = columnDefs.reduce((acc, colDef) => {
      const correspondingColumnState = sortData.find(({ value }) => value === colDef.field);
      const columnData = {
        value: colDef.field,
        label: colDef.headerName,
        type: colDef.fieldType,
        sortDirection: 'asc',
      };

      if (!correspondingColumnState) {
        acc.push({
          ...columnData,
        });
      }

      return acc;
    }, []);

    setUnsortedColumns(_unsortedColumns);
  }, [columnDefs, sortData]);

  return (
    <div className='flex items-center gap-4 sort-column-row h-11'>
      <div className='w-52'>
        <Select
          value={sortedColumn?.value}
          options={unsortedColumns}
          placeholder='Column'
          excludedOptions={allOptions}
          onChange={(option) => changeColumnToSort(allOptions.find((i) => i.value === option))}
          dataCy='select-sort-column'
        />
      </div>
      <div className='w-20'>
        <Select
          value={getDefaultSortDirectionValue(sortedColumn).value}
          options={getSortingOrderOptions(sortedColumn.type)}
          onChange={(option) => changeSortDirection(option)}
          dataCy='select-sort-column-by'
        />
      </div>

      {sortData.length > 1 && (
        <div className='flex flex-row items-center'>
          <Icon size='22' name='dots-six-vertical' color='#474747' />
          <Button onClick={removeColumnToSort} variant='ghost' data-cy='remove-btn' iconName='x' />
        </div>
      )}
    </div>
  );
};

export default SortColumnsRow;
