import { StatusCodes } from 'http-status-codes';
import { authenticationSliceName, sharedSliceName } from '../redux/constants';
import { store } from '../redux/store';
import { operationStatusEndpoint } from './apiEndpoints';
import { OPERATION_STATUSES } from './constants';
import { logConsoleIfAllowed } from './debugging';
import { getFileUuidFromPath } from './getFileUuidFromPath';

import { getBaseUrl, handleUnauthorizedResponseAndReload } from './utils';

const url = getBaseUrl('api');

const isRanByCypress = () => {
  return store.getState()[sharedSliceName].isRanByCypress;
};

export const headers = () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (isRanByCypress()) {
    headers['X-GIGASHEET-TOKEN'] = localStorage.getItem('cypressGigasheetAPIToken');
    headers['WithC'] = 'true';
  } else {
    // Need token to be undefined for backend
    let token = store.getState()[authenticationSliceName].auth0IdToken;
    if (token === null) {
      token = undefined;
    }
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};

export async function get(route, returnRaw = false) {
  let response = null;
  try {
    const requestOptions = {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
    };
    if (isRanByCypress()) {
      requestOptions.headers['X-GIGASHEET-TOKEN'] = localStorage.getItem(
        'cypressGigasheetAPIToken'
      );
      requestOptions.headers['WithC'] = true;
    } else {
      // Need token to be undefined for backend
      let token = store.getState()[authenticationSliceName].auth0IdToken;
      if (token === null) {
        token = undefined;
      }
      requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }
    response = await fetch(`${url}/${route}`, requestOptions);

    if (response.status === StatusCodes.UNAUTHORIZED)
      handleUnauthorizedResponseAndReload(response, 'api-get');
    if (returnRaw) return response;

    return response.json();
  } catch (err) {
    if (returnRaw) return response;
    throw new Error(`GET error: ${err}`);
  }
}

export async function post(route, data) {
  let timeoutId;

  try {
    const response = await postRaw(route, data);
    if (response.status === StatusCodes.UNAUTHORIZED)
      handleUnauthorizedResponseAndReload(response, 'api-post');
    const uuid = getFileUuidFromPath();
    if (response.status === 202) {
      const pollInterval = 5000;

      return new Promise((resolve, reject) => {
        const pollStatusCode = async () => {
          try {
            const operationStatusResponse = await get(operationStatusEndpoint(uuid));
            if (operationStatusResponse.Status === OPERATION_STATUSES.DONE) {
              logConsoleIfAllowed('Success! Status code Done received.');
              const json = await response.json();
              resolve({ ...json, Success: true, status: response.status });
              clearTimeout(timeoutId);
            }
            if (operationStatusResponse.Status === OPERATION_STATUSES.ERROR) {
              logConsoleIfAllowed('Error response received:', operationStatusResponse.Status);

              resolve({ operationStatusResponse, Success: false, status: response.status });
              clearTimeout(timeoutId);
            }
            if (operationStatusResponse.Status === OPERATION_STATUSES.WORKING) {
              logConsoleIfAllowed('Continue polling...');
              timeoutId = setTimeout(pollStatusCode, pollInterval);
            }
            if (!operationStatusResponse.Status) {
              reject({ operationStatusResponse, Success: false, status: response.status });
            }
          } catch (error) {
            logConsoleIfAllowed('Error during polling:', error.message);
            reject(new Error(`Polling error: ${error.message}`));
            clearTimeout(timeoutId);
          }
        };
        uuid !== 'datasets' && pollStatusCode();
      });
    } else {
      clearTimeout(timeoutId);
      const json = await response.json();
      return { ...json, status: response.status };
    }
  } catch (err) {
    clearTimeout(timeoutId);
    logConsoleIfAllowed('POST error:', err.message);
    throw new Error(`POST error: ${err.message}`);
  }
}

export function postRaw(route, data) {
  const requestOptions = {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (isRanByCypress()) {
    requestOptions.headers['X-GIGASHEET-TOKEN'] = localStorage.getItem('cypressGigasheetAPIToken');
    requestOptions.headers['WithC'] = true;
  } else {
    // Need token to be undefined for backend
    let token = store.getState()[authenticationSliceName].auth0IdToken;
    if (token === null) {
      token = undefined;
    }
    requestOptions.headers['Authorization'] = `Bearer ${token}`;
  }
  return fetch(`${url}/${route}`, requestOptions);
}

export async function http_delete(route, identifier) {
  try {
    const requestOptions = {
      method: 'DELETE',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (isRanByCypress()) {
      requestOptions.headers['X-GIGASHEET-TOKEN'] = localStorage.getItem(
        'cypressGigasheetAPIToken'
      );
      requestOptions.headers['WithC'] = true;
    } else {
      // Need token to be undefined for backend
      let token = store.getState()[authenticationSliceName].auth0IdToken;
      if (token === null) {
        token = undefined;
      }
      requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }

    const urlPath = identifier ? `${url}/${route}/${identifier}` : `${url}/${route}`;

    const response = await fetch(urlPath, requestOptions);
    return await response.json();
  } catch (err) {
    throw new Error(`POST error: ${err}`);
  }
}

export async function httpDeleteWithBody(route, identifier, body) {
  let timeoutId;

  try {
    const requestOptions = {
      method: 'DELETE',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    if (isRanByCypress()) {
      requestOptions.headers['X-GIGASHEET-TOKEN'] = localStorage.getItem(
        'cypressGigasheetAPIToken'
      );
      requestOptions.headers['WithC'] = true;
    } else {
      // Need token to be undefined for backend
      let token = store.getState()[authenticationSliceName].auth0IdToken;
      if (token === null) {
        token = undefined;
      }
      requestOptions.headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await fetch(`${url}/${route}/${identifier}`, requestOptions);
    const uuid = getFileUuidFromPath();

    if (response.status === 202) {
      const pollInterval = 5000;

      return new Promise((resolve, reject) => {
        const pollStatusCode = async () => {
          try {
            const operationStatusResponse = await get(operationStatusEndpoint(uuid));
            if (operationStatusResponse.Status === OPERATION_STATUSES.DONE) {
              logConsoleIfAllowed('Success! Status code Done received.');
              const json = await response.json();
              resolve({ ...json, Success: true });
              clearTimeout(timeoutId);
            }
            if (operationStatusResponse.Status === OPERATION_STATUSES.ERROR) {
              logConsoleIfAllowed('Error response received:', operationStatusResponse.Status);

              resolve({ operationStatusResponse, Success: false });
              clearTimeout(timeoutId);
            }
            if (operationStatusResponse.Status === OPERATION_STATUSES.WORKING) {
              logConsoleIfAllowed('Continue polling...');
              timeoutId = setTimeout(pollStatusCode, pollInterval);
            }
            if (!operationStatusResponse.Status) {
              reject({ operationStatusResponse, Success: false });
            }
          } catch (error) {
            logConsoleIfAllowed('Error during polling:', error.message);
            reject(new Error(`Polling error: ${error.message}`));
            clearTimeout(timeoutId);
          }
        };
        uuid !== 'datasets' && pollStatusCode();
      });
    } else {
      logConsoleIfAllowed('Directly returning JSON response...');
      clearTimeout(timeoutId);
      const json = await response.json();
      return json;
    }
  } catch (err) {
    clearTimeout(timeoutId);
    logConsoleIfAllowed('POST error:', err.message);
    throw new Error(`POST error: ${err}`);
  }
}

export async function put(route, data, opts) {
  try {
    const response = await putRaw(route, data, opts);
    const json = await response.json();
    return json;
  } catch (err) {
    throw new Error(`PUT error: ${err}`);
  }
}

export async function patch(route, data, opts) {
  try {
    const response = await patchRaw(route, data, opts);
    const json = await response.json();
    return json;
  } catch (err) {
    throw new Error(`PATCH error: ${err}`);
  }
}

export async function putRaw(route, data, opts = {}) {
  return putOrPatchRaw('PUT', route, data, opts);
}

export async function patchRaw(route, data, opts = {}) {
  return putOrPatchRaw('PATCH', route, data, opts);
}

export async function putOrPatchRaw(method, route, data, opts = {}) {
  const requestOptions = {
    method: method,
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ...opts,
  };
  if (isRanByCypress()) {
    requestOptions.headers['X-GIGASHEET-TOKEN'] = localStorage.getItem('cypressGigasheetAPIToken');
    requestOptions.headers['WithC'] = true;
  } else {
    // Need token to be undefined for backend
    let token = store.getState()[authenticationSliceName].auth0IdToken;
    if (token === null) {
      token = undefined;
    }
    requestOptions.headers['Authorization'] = `Bearer ${token}`;
  }
  return fetch(`${url}/${route}`, requestOptions);
}
