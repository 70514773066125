import { lazy, Suspense, useEffect, useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { get } from '@/Utils/API';
import { Mixpanel } from '@/Mixpanel';
import { store } from '@/redux/store';
import Datasets from '@/pages/DatasetPage/Datasets';
import { showModal } from '@/redux/reducers/modalsSlice';
import ExternalScripts from '@/Components/ExternalScripts';
import Navigation from '@/Components/Navigation/Navigation';
import showToast from '@/Components/Toast/showToastTemplate';
import LeftSidebar from '@/Components/LeftSidebar/LeftSidebar';
import InitiateModals from '@/Components/Modals/InitiateModals';
import ErrorBoundary from '@/pages/ErrorBoundaryPage/ErrorBoundary';
import { setIsOnboardingOpened } from '@/redux/reducers/sharedSlice';
import LoadingOverlay from '@/Components/LoadingOverlay/LoadingOverlay';
import {
  getIsResourcePublic,
  postToLogoutChannel,
  getAuthNonceAndSetInStorage,
} from '@/Utils/utils';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { authenticationSliceName, sharedSliceName, userDataSliceName } from '@/redux/constants';
import { refreshSubscriptions, setUser } from '@/redux/reducers/userDataSlice';
import { setAuth0IdToken, unsetAuth0IdToken } from '@/redux/reducers/authenticationSlice';
import {
  setIsPublicResource,
  unsetLibraryFiles,
  unsetSharedWithMeFiles,
  unsetSharedWithMyTeamFiles,
} from '@/redux/reducers/datasetSlice';
import {
  LOCAL_STORAGE_CHECKOUT_PERIOD,
  LOCAL_STORAGE_CHECKOUT_STORAGE,
  LOCAL_STORAGE_IMPORT_FILE_NAME,
  LOCAL_STORAGE_IMPORT_PROVIDER,
  LOCAL_STORAGE_IMPORT_TOKEN,
  LOCAL_STORAGE_IMPORT_URL,
  MODAL_UPLOAD_FILE,
  ONBOARDED,
  QUERY_PARAM_CHECKOUT_PERIOD,
  QUERY_PARAM_CHECKOUT_STORAGE,
  QUERY_PARAM_CHECKOUT_UPGRADING,
  QUERY_PARAM_FILE_NAME,
  QUERY_PARAM_PROVIDER,
  QUERY_PARAM_TOKEN,
  QUERY_PARAM_URL,
  ROUTE_CALLBACK_API_KEY,
  ROUTE_CONFIRMATION,
  ROUTE_DATASETS,
  ROUTE_LANDING,
  ROUTE_NEXUS,
  ROUTE_SPREADSHEET,
  SIGNUP_ROUTES,
  TOAST_TEXT_NETWORK_ERROR,
  TOAST_TYPE_RECONNECTION,
  URL_DATASET,
  URL_DATASETS,
  URL_SPREADSHEET,
  userLoginMethodKey,
  userMetadataKey,
  userRolesKey,
  userTeamKey,
  userTeamNameKey,
  ROUTE_SETTINGS,
  QUERY_PARAM_COMMENT_THREAD,
  LOCAL_STORAGE_COMMENT_THREAD,
  ROUTE_IMPORTS,
} from '@/Utils/constants';

import { getReferrerUrl } from './helpers/referrerHelper';
import useViewportProvider from './hooks/useViewportProvider';
import {
  getFileNameFromPath,
  getFileUuidFromPath,
  getFolderHandleFromPath,
} from '@/Utils/getFileUuidFromPath';
import { WebSocketProvider } from './pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { WebSocketContainer } from './pages/DatasetPage/WebSocketLibrary/WebSocketContainer';
import useAuth0SilentRefresh from '@/hooks/useAuth0SilentRefresh';

const LazyConfirmation = lazy(() => import('@/Components/ConfirmationPage'));
const LazyPublicFolder = lazy(() => import('@/pages/DatasetPage/PublicFolder'));
const LazyOnboarding = lazy(() => import('@/pages/OnboardingPage/Onboarding'));
const LazyGenerateApiKey = lazy(() => import('@/pages/GenerateApiKeyPage/GenerateApiKey'));
const LazyLandingPage = lazy(() => import('@/Components/LandingPage'));
const LazySignUpPage = lazy(() => import('@/Components/SignUpPage'));
const LazyNexusPage = lazy(() => import('@/Components/Nexus/NexusPage'));
const LazySpreadsheetPage = lazy(() => import('@/pages/SpreadsheetPage/SpreadsheetPage'));
const LazySettingsPage = lazy(() => import('@/pages/SettingsPage/SettingsPage'));
const LazyScheduledPage = lazy(() => import('@/pages/ScheduleImportPage/ScheduleImportPage'));

const isRanByCypress = () => {
  return store.getState()[sharedSliceName].isRanByCypress;
};

const sendMixpanelSignupReferrer = (userEmail) => {
  const signupReferrer = localStorage.getItem('signup_referrer');
  if (signupReferrer) {
    Mixpanel.identify(userEmail);
    Mixpanel.track('Signup Referrer (Auth0)', {
      'Signup Referrer': signupReferrer,
    });
    localStorage.removeItem('signup_referrer');
  }
};

const ProtectedRoute = ({ component, ...args }) => {
  let Component = withAuthenticationRequired(component, args);
  if (isRanByCypress()) Component = component;
  return <Component {...args} />;
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    isLoading,
    error,
    user: auth0User,
    logout,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();
  const isIframe = window.location !== window.parent.location;
  const isLibraryPage = location.pathname.includes('datasets');
  const isSettingsPage = location.pathname.includes('settings');
  const isScheduledImportPage = location.pathname.includes(ROUTE_IMPORTS);
  const isNexusPage = location.pathname.includes(ROUTE_NEXUS);

  const { isMobile } = useViewportProvider();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { auth0IdToken } = useSelector((state) => state[authenticationSliceName]);
  const { isOnboardingOpened, showLoadingState } = useSelector((state) => state[sharedSliceName]);
  const { isAuthenticated } = useAuth0();
  const { silentRefresh } = useAuth0SilentRefresh();

  const [shouldShowLayoutComponents, setShouldShowLayoutComponents] = useState(false);
  const [isResourcePublicFolder, setIsResourcePublicFolder] = useState(false);
  const [displayLoadingOverlay, setDisplayLoadingOverlay] = useState(true);
  const [orgMetadata, setOrgMetadata] = useState({});
  const refreshAuth0TokenInterval = 5 * 60 * 1000; // 5 minutes in milliseconds

  const isAnonymousUserAndLoaded = !isLoading && user && user.is_anonymous;

  const broadcastChannel = new BroadcastChannel('logoutChannel');
  broadcastChannel.addEventListener('message', (event) => {
    if (event.data.type === 'logout') {
      logout({ returnTo: 'https://gigasheet.com/' });
      dispatch(unsetAuth0IdToken());
      dispatch(unsetLibraryFiles());
      dispatch(unsetSharedWithMeFiles());
      dispatch(unsetSharedWithMyTeamFiles());
    }
  });

  // Periodically attempt to refresh Auth0 token to avoid it becoming expired and cause issues and error toasts popping up if a user leaves a browser tab open for more than 2 hours
  useEffect(() => {
    if (isAuthenticated) {
      // Set up interval for periodic refresh
      const intervalId = setInterval(() => {
        silentRefresh();
      }, refreshAuth0TokenInterval);

      // Visibility change event listener (computer wake from sleep or tab change)
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          silentRefresh();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Cleanup on component unmount or dependency change
      return () => {
        clearInterval(intervalId);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [isAuthenticated, silentRefresh, refreshAuth0TokenInterval]);

  useEffect(() => {
    if (auth0IdToken) {
      getIsResourcePublic().then((isResourcePublic) => {
        dispatch(setIsPublicResource({ isPublicResource: isResourcePublic }));
      });
    }
    if (!isRanByCypress()) {
      if (!isLoading) {
        if (auth0User) {
          const getToken = async () => {
            await getIdTokenClaims().then((authResult) => {
              normalizeOrgIdClaim(auth0User);
              normalizeOrgIdClaim(authResult);
              checkOrganization(authResult);
              dispatch(setUser({ ...auth0User, is_anonymous: false }));
              dispatch(setAuth0IdToken(authResult.__raw));
              dispatch(refreshSubscriptions());

              if (!auth0User[userMetadataKey]?.onboarding?.actions?.includes(ONBOARDED)) {
                sendMixpanelSignupReferrer(auth0User.email);
              }
            });
          };

          getToken();
        } else if (
          location.pathname.includes(`/${URL_SPREADSHEET}`) ||
          location.pathname.includes(`/${URL_DATASETS}`)
        ) {
          getIsResourcePublic(true).then((isResourcePublic) => {
            if (isResourcePublic || isIframe) {
              const anonymousUser = {
                [userRolesKey]: [],
                [userMetadataKey]: {
                  onboarding: { actions: [ONBOARDED] },
                },
                is_anonymous: true,
              };
              dispatch(setUser(anonymousUser));
            } else {
              const authStateNonce = getAuthNonceAndSetInStorage();

              loginWithRedirect({
                appState: {
                  returnTo: location.pathname + location.search,
                  referrerId: getReferrerUrl(),
                  authStateNonce,
                },
              });
            }
          });
        } else if (location.pathname.startsWith('/nexus')) {
          const authStateNonce = getAuthNonceAndSetInStorage();

          loginWithRedirect({
            appState: {
              returnTo: location.pathname + location.search,
              referrerId: getReferrerUrl(),
              authStateNonce,
            },
          });
        } else {
          if (location.pathname !== '/signup') {
            const authStateNonce = getAuthNonceAndSetInStorage();

            loginWithRedirect({ appState: { authStateNonce } });
          }
        }
      }
    }
  }, [isLoading, auth0User, loginWithRedirect, location.pathname, getIdTokenClaims, dispatch]);

  useEffect(() => {
    const folderHandle = getFolderHandleFromPath();
    if (!isLoading && folderHandle?.length > 0) {
      get(`${URL_DATASET}/${folderHandle}`, true).then((response) => {
        if (response.status === 200) {
          response.json().then((folderMetadata) => {
            if (folderMetadata.IsDirectory) {
              getIsResourcePublic().then((isResPublic) => {
                if (isResPublic) {
                  setIsResourcePublicFolder(folderMetadata.IsDirectory);
                  return;
                } else {
                  setIsResourcePublicFolder(false);
                }
              });
            } else {
              setIsResourcePublicFolder(false);
            }
          });
        } else {
          setIsResourcePublicFolder(false);
        }
      });
    } else {
      setIsResourcePublicFolder(false);
    }
  }, [isLoading, location]);

  useEffect(() => {
    const checkoutRequested = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_PERIOD);
    storeQueryParameters();
    setCheckoutQueryParameter();
    if (searchParams.get('termsaccept') === 'true') {
      searchParams.delete('termsaccept');
      setSearchParams(searchParams);
      if (location.pathname.includes(`/${URL_DATASETS}`) && !checkoutRequested) {
        dispatch(showModal({ name: MODAL_UPLOAD_FILE }));
      }
    }
  }, []);

  useEffect(() => {
    setShouldShowLayoutComponents((!isOnboardingOpened && user) || isRanByCypress());
  }, [isOnboardingOpened, user]);

  useEffect(() => {
    if (user) {
      const user_metadata = user[userMetadataKey];
      if (!user_metadata.onboarding) dispatch(setIsOnboardingOpened(true));
    }
  }, [dispatch, user]);

  useEffect(() => {
    setDisplayLoadingOverlay(
      isLoading || (orgMetadata.orgFromMetadata && !orgMetadata.orgFromClaims) || showLoadingState
    );
  }, [isLoading, orgMetadata.orgFromMetadata, orgMetadata.orgFromClaims, showLoadingState]);

  useEffect(() => {
    let toastId = null;

    const showNetworkErrorToast = () =>
      (toastId = showToast({
        type: TOAST_TYPE_RECONNECTION,
        text: TOAST_TEXT_NETWORK_ERROR,
      }));
    const hideNetworkErrorToast = () => toast.dismiss(toastId);

    const onAppMount = async () => {
      window.addEventListener('online', hideNetworkErrorToast);
      window.addEventListener('offline', showNetworkErrorToast);
      window.FreshworksWidget('hide', 'launcher');

      if (location.pathname === '/callback-api-key' || location.pathname === '/nexus') return;

      try {
        if (isRanByCypress()) {
          const cypressFreeUser = {
            email: 'cypress@gigasheet.com',
            email_verified: true,
            [userLoginMethodKey]: 'Username-Password-Authentication',
            [userMetadataKey]: {
              onboarding: { actions: [ONBOARDED] },
            },
            'https://gigasheet.com/roles': [
              'free',
              'can-aggregate-filter',
              'giga-feature-connector-schedule',
            ],
            roles: ['free', 'can-aggregate-filter', 'giga-feature-connector-schedule'],
            rolesCategories: { paid: false },
          };
          const cypressPaidUser = {
            email: 'cypress+tier-paid-basic@gigasheet.com',
            email_verified: true,
            [userLoginMethodKey]: 'Username-Password-Authentication',
            [userMetadataKey]: {
              onboarding: { actions: [ONBOARDED] },
              roles: [
                'is-paying',
                'giga-feature-api-key',
                'giga-feature-connector-schedule',
                'max-file-size-gb-10',
                'max-total-storage-gb-50',
                'can-export',
              ],
            },
            [userTeamKey]: 'org_gigasheet_cypress',
            [userTeamNameKey]: 'Gigasheet Cypress',
            org_id: 'org_gigasheet_cypress',
            org_name: 'Gigasheet Cypress',
            'https://gigasheet.com/roles': [
              'is-paying',
              'giga-feature-api-key',
              'giga-feature-connector-schedule',
              'max-file-size-gb-10',
              'max-total-storage-gb-50',
              'can-export',
              'giga-feature-embed-sheet',
            ],
            roles: [
              'is-paying',
              'giga-feature-api-key',
              'giga-feature-connector-schedule',
              'max-file-size-gb-10',
              'max-total-storage-gb-50',
              'can-export',
              'giga-feature-embed-sheet',
            ],
            rolesCategories: { paid: true },
          };
          const cypressTeamMemberUser = {
            email: 'cypress+team-member@gigasheet.com',
            email_verified: true,
            [userLoginMethodKey]: 'Username-Password-Authentication',
            [userMetadataKey]: {
              onboarding: { actions: [ONBOARDED] },
              roles: [
                'is-paying',
                'max-file-size-gb-10',
                'max-total-storage-gb-50',
                'can-export',
                'giga-feature-connector-schedule',
              ],
            },
            [userTeamKey]: 'org_gigasheet_cypress',
            [userTeamNameKey]: 'Gigasheet Cypress',
            org_id: 'org_gigasheet_cypress',
            org_name: 'Gigasheet Cypress',
            'https://gigasheet.com/roles': [
              'is-paying',
              'max-file-size-gb-10',
              'max-total-storage-gb-50',
              'giga-feature-connector-schedule',
              'can-export',
            ],
            roles: [
              'is-paying',
              'max-file-size-gb-10',
              'max-total-storage-gb-50',
              'can-export',
              'giga-feature-connector-schedule',
            ],
            rolesCategories: { paid: true },
          };
          const isFreeUser = localStorage.getItem('isPaidCypressUser') === 'true' ? false : true;
          const isTeamMemberUser =
            localStorage.getItem('isTeamMemberCypressUser') === 'true' ? true : false;
          const currentUser = isTeamMemberUser
            ? cypressTeamMemberUser
            : isFreeUser
            ? cypressFreeUser
            : cypressPaidUser;
          dispatch(setUser(currentUser));
        }

        window.fcWidget?.on('message:sent', () => {
          Mixpanel.track('Chat Used');
          Mixpanel.people.increment('# of chat use', 1);
        });
      } catch (err) {
        if (err.error === 'login_required') return;
        console.error(`Silent Authentication failure: ${err.error}`);
      }
    };

    onAppMount();

    return () => {
      window.removeEventListener('online', showNetworkErrorToast);
      window.removeEventListener('offline', hideNetworkErrorToast);
    };
  }, []);

  // This block does some weird stuff related to Teams/Organizations.
  // It exists because we are working around the Organizations feature in Auth0 that is significantly more expensive.
  // Scenarios:
  // - user has an org_id in their claims: this means they are authenticated, either properly by Auth0 Organizations or using our Auth0 app metadata workaround (see normalizeOrgIdClaim), and we don't need to do anything
  // - user does not have an org_id in their claims but they do have one in usermetadata: this means we need to re-run login prompting them for organization login
  const checkOrganization = (authResult) => {
    const orgFromMetadata = auth0User['https://gigasheet.com/usermetadata'].organization;
    const orgFromClaims = authResult.org_id;
    const orgMetadata = {
      orgFromMetadata: orgFromMetadata,
      orgFromClaims: orgFromClaims,
    };
    setOrgMetadata(orgMetadata);
    if (orgFromMetadata && !orgFromClaims) {
      const authStateNonce = getAuthNonceAndSetInStorage();

      loginWithRedirect({
        appState: {
          returnTo: location.pathname + location.search,
          referrerId: getReferrerUrl(),
          authStateNonce,
        },
        organization: orgFromMetadata,
      });
    }
  };

  // This mutates the authResult or auth0User object to normalize the key of the org_id in the response.
  // The result is that even if org_id is present as the custom claim prefixed with our domain, it ends up set at the org_id key as we would expect.
  // See checkOrganization for more.
  const normalizeOrgIdClaim = (authData) => {
    const metadataClaimKey = 'https://gigasheet.com/org_id';
    if (!authData.org_id && authData[metadataClaimKey]) {
      authData.org_id = authData[metadataClaimKey];
    }
  };

  const storeQueryParameters = () => {
    try {
      // import parameters
      if (searchParams.get(QUERY_PARAM_PROVIDER)) {
        localStorage.setItem(LOCAL_STORAGE_IMPORT_PROVIDER, searchParams.get(QUERY_PARAM_PROVIDER));
      }
      if (searchParams.get(QUERY_PARAM_TOKEN)) {
        localStorage.setItem(LOCAL_STORAGE_IMPORT_TOKEN, searchParams.get(QUERY_PARAM_TOKEN));
      }
      if (searchParams.get(QUERY_PARAM_URL)) {
        localStorage.setItem(LOCAL_STORAGE_IMPORT_URL, searchParams.get(QUERY_PARAM_URL));
      }
      if (searchParams.get(QUERY_PARAM_FILE_NAME)) {
        localStorage.setItem(
          LOCAL_STORAGE_IMPORT_FILE_NAME,
          searchParams.get(QUERY_PARAM_FILE_NAME)
        );
      }

      // comments parameters
      const handle = getFileUuidFromPath();
      if (searchParams.get(QUERY_PARAM_COMMENT_THREAD) && handle?.length > 0) {
        localStorage.setItem(
          LOCAL_STORAGE_COMMENT_THREAD,
          JSON.stringify({
            handle: handle,
            fileName: getFileNameFromPath(),
            commentThread: searchParams.get(QUERY_PARAM_COMMENT_THREAD),
          })
        );
      }

      // checkout parameters
      if (searchParams.get(QUERY_PARAM_CHECKOUT_PERIOD)) {
        localStorage.setItem(
          LOCAL_STORAGE_CHECKOUT_PERIOD,
          searchParams.get(QUERY_PARAM_CHECKOUT_PERIOD)
        );
        searchParams.delete(QUERY_PARAM_CHECKOUT_PERIOD);
        setSearchParams(searchParams);
      }
      if (searchParams.get(QUERY_PARAM_CHECKOUT_STORAGE)) {
        localStorage.setItem(
          LOCAL_STORAGE_CHECKOUT_STORAGE,
          searchParams.get(QUERY_PARAM_CHECKOUT_STORAGE)
        );
        searchParams.delete(QUERY_PARAM_CHECKOUT_STORAGE);
        setSearchParams(searchParams);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setCheckoutQueryParameter = () => {
    if (
      localStorage.getItem(LOCAL_STORAGE_CHECKOUT_PERIOD) &&
      !searchParams.has(QUERY_PARAM_CHECKOUT_UPGRADING)
    ) {
      searchParams.append(QUERY_PARAM_CHECKOUT_UPGRADING, 'true');
      setSearchParams(searchParams);
    }
  };

  if (displayLoadingOverlay) {
    return <LoadingOverlay extendClassName='bg-ocean-blue-500 bg-opacity-90' />;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const DatasetsRouteElement = () => {
    if (isOnboardingOpened) {
      return <LazyOnboarding />;
    } else if (isResourcePublicFolder) {
      return <LazyPublicFolder />;
    } else if (!user?.is_anonymous) {
      return <ProtectedRoute component={Datasets} user={auth0User} />;
    }
  };

  const mobileExperienceStatus = localStorage.getItem('isMobileExperience');
  if (mobileExperienceStatus === null) {
    localStorage.setItem('isMobileExperience', true);
  }
  const isStaticPage = window.location.pathname.startsWith('/nexus');

  const navigationProps = {
    showNavigation: shouldShowLayoutComponents && !isIframe,
  };

  const isMobileExperienceActive = localStorage.getItem('isMobileExperience') === 'true';

  return (
    <ErrorBoundary
      user={auth0User}
      logout={() => {
        postToLogoutChannel();
      }}
    >
      <WebSocketProvider>
        <div className='app' id='container'>
          <div className='app-wrapper'>
            {(user || isRanByCypress()) && <ExternalScripts />}
            {shouldShowLayoutComponents && !user?.is_anonymous && !isIframe && <LeftSidebar />}
            <div
              className={clsx(
                'main-content',
                'pl-0 md:pl-[46px]',
                isOnboardingOpened && '!pl-0',
                (isAnonymousUserAndLoaded || isIframe) && '!pl-[0px]'
              )}
            >
              {shouldShowLayoutComponents &&
                !isIframe &&
                (isLibraryPage ||
                  isSettingsPage ||
                  isScheduledImportPage ||
                  isNexusPage ||
                  (isMobileExperienceActive && isMobile)) && (
                  <div
                    className={clsx(
                      ' bg-white border-b border-ui-200',
                      isMobileExperienceActive && isMobile ? 'py-[2px]' : 'py-2'
                    )}
                  >
                    <Navigation />
                  </div>
                )}
              <WebSocketContainer />
              <ToastContainer />
              <InitiateModals />
              <Routes>
                {SIGNUP_ROUTES.map((path) => (
                  <Route path={path} element={<LazySignUpPage />} key={path} />
                ))}
                <Route path={ROUTE_CONFIRMATION} element={<LazyConfirmation />} />
              </Routes>

              {(user || isRanByCypress()) && (
                <Suspense fallback={null}>
                  <Routes>
                    <Route path={ROUTE_LANDING} element={<LazyLandingPage />} />
                    <Route path={ROUTE_DATASETS} element={DatasetsRouteElement()} />
                    <Route path={`${ROUTE_DATASETS}/:fileUuid`} element={DatasetsRouteElement()} />
                    <Route path={ROUTE_SETTINGS} element={<LazySettingsPage />} />
                    <Route
                      path={ROUTE_SPREADSHEET}
                      element={
                        isOnboardingOpened ? (
                          <LazyOnboarding />
                        ) : (
                          <LazySpreadsheetPage navigationProps={navigationProps} />
                        )
                      }
                    />
                    <Route
                      path={`${ROUTE_SPREADSHEET}/:fileUuid`}
                      element={
                        isOnboardingOpened ? (
                          <LazyOnboarding />
                        ) : (
                          <LazySpreadsheetPage navigationProps={navigationProps} />
                        )
                      }
                    />
                    <Route path={ROUTE_CALLBACK_API_KEY} element={<LazyGenerateApiKey />} />
                    <Route path={ROUTE_IMPORTS} element={<LazyScheduledPage />} />
                    <Route path={ROUTE_NEXUS} element={<LazyNexusPage />} />
                    {!isLoading && (
                      <Route path='*' element={<Navigate to={`/${URL_DATASETS}`} />} />
                    )}
                  </Routes>
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </WebSocketProvider>
    </ErrorBoundary>
  );
};

export default App;
