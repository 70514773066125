import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Box from '@uppy/box';
import Uppy from '@uppy/core';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import { DashboardModal } from '@uppy/react';
import Url from '@uppy/url';
import { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/url/dist/style.css';
import { Mixpanel } from '@/Mixpanel';
import { datasetSliceName } from '@/redux/constants';
import {
  addFilesToLibraryFiles,
  addFilesToTeamLibrary,
  setProcessingFiles,
} from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';
import { get, post } from '@/Utils/API';
import {
  CREATE,
  FILES,
  MODAL_UPGRADE_ACCOUNT,
  MODAL_UPLOAD_FILE,
  MODAL_UPLOAD_FILE_WARNING,
  S3,
  TOAST_TEXT_UPLOAD_FILE_ERROR,
  TOAST_TYPE_ERROR,
  UPLOAD,
  upgradeAccountTypes,
  userRolesKey,
  UPLOADING_FILES_OVER_LIMIT,
  UPLOADING_OVER_LIMIT_FILE_SIZE,
  MY_LIBRARY,
  SHARED_WITH_ME,
  userTeamNameKey,
  BROWSE_UPLOAD,
  URL_UPLOAD,
} from '../../Utils/constants';
import { convertToBytes } from '../../Utils/convertToBytes';
import { parseFileSize } from '../../Utils/parseFileSize';
import { getBaseUrl } from '../../Utils/utils';
import ZipWarningPopup from '../Modals/UploadWarning/ZipWarningPopup';
import withModal from '../Modals/withModalHOC';
import Portal from '../Portal/Portal';
import showToast from '../Toast/showToastTemplate';

import addClickbyPluginToMixpanel from './addClickbyPluginToMixpanel';
import mixpanelUppyEventsTracking from './mixpanelUppyEventsTracking';
import GigasheetUppyIngestion from './plugins/GigasheetUppyIngestion';
import './style/index.scss';
import { mapUserRoles } from '@/Utils/userConversion/userConversion';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';

const UppyUploaderMulti = ({ hideModal, show, openUploadType }) => {
  const dispatch = useDispatch();
  const [uploadComplete, setUploadComplete] = useState(false);
  const companionUrl = getBaseUrl('companion');
  const { user } = useSelector((state) => state.userData, shallowEqual);
  const { filePath, currentDataset } = useSelector((state) => state[datasetSliceName]);
  const sortingData = useSelector((state) => state[datasetSliceName].sortingData, shallowEqual);
  const { filesSize, currentDir } = useSelector((state) => state.dataset, shallowEqual);
  const { maxStorageSize } = user?.userProperties || {};
  const [owner, setOwner] = useState(null);
  const { email } = user;
  const filesParentFolderRef = useRef({});
  const ownerRef = useRef();
  const sortingDataRef = useRef();
  const filesSizeRef = useRef();
  const currentFolderRef = useRef();
  const currentDatasetRef = useRef();
  const uppyRef = useRef();

  useEffect(() => {
    ownerRef.current = owner;
    sortingDataRef.current = sortingData;
    filesSizeRef.current = filesSize;
    currentFolderRef.current = currentDir;
    currentDatasetRef.current = currentDataset;
  }, [owner, sortingData, filesSize, currentDir, currentDataset]);

  useEffect(() => {
    // if the file is being created within a folder that has been shared with me then the owner should be set to the owner
    // of that shared folder
    const parentDirectorySharedWithMe = filePath?.find(
      (fileSharedWithMe) => fileSharedWithMe.FileUuid === sortingData.currentDirectory
    );
    const newOwner = parentDirectorySharedWithMe ? parentDirectorySharedWithMe.Owner : email;
    if (newOwner !== owner) {
      setOwner(newOwner);
    }
  }, [show]);

  const compressedFileExtensions = [
    'compressed',
    'zip',
    'zipx',
    'tar',
    'rar',
    'gz',
    'z',
    'cab',
    'bz2',
    'Izh',
    '7z',
  ];

  useEffect(() => {
    const onMount = () => {
      if (user?.[userRolesKey]?.length > 0) {
        window.userGuiding?.identify(user.email, {
          email: user.email,
          ...mapUserRoles(user[userRolesKey]),
        });
      }
      window.userGuiding?.previewGuide(68627, {
        checkHistory: true,
      });

      if (openUploadType === BROWSE_UPLOAD) {
        const browseElement = document.querySelector('.uppy-Dashboard-input');
        if (browseElement) {
          browseElement.click();
        }
      }
      if (openUploadType === URL_UPLOAD) {
        const urlElement = document.querySelector(
          '.uppy-DashboardTab[data-uppy-acquirer-id="URL"] > button'
        );
        if (urlElement) {
          urlElement.click();
        }
      }
    };
    if (show) onMount();
  }, [show, user]);

  const setFakeFiles = (result) => {
    if (!result.successful) return;
    const filesFakeEntries = result.successful.map((file) => {
      const itemKey = file.s3Multipart?.key || file.meta.name;
      const { name, size } = file;
      const lastUpdated = new Date(file.progress.uploadStarted);

      return {
        metadata: {
          FileName: name,
          FileColumns: 0,
          FileSize: size,
          FileRows: 0,
          ParentDirectory: sortingDataRef.current?.currentDirectory,
          LastUpdated: lastUpdated.toISOString(),
          Status: 'loading',
          FileUuid: itemKey,
          Deleting: 0,
          DetailedStatus: '',
          FieldsTypes: [],
          Headers: [],
          Public: false,
          isNew: true,
        },
      };
    });

    currentDataset !== SHARED_WITH_ME && currentDataset !== MY_LIBRARY
      ? dispatch(addFilesToTeamLibrary(filesFakeEntries))
      : dispatch(addFilesToLibraryFiles(filesFakeEntries));

    // If a user uploads a single file, add it to the queue of processing files
    // Once it has been uploaded successfully, it will open in a new tab.
    if (filesFakeEntries.length === 1) dispatch(setProcessingFiles(filesFakeEntries));
  };

  const isUploadCompleted = (uppy) => {
    return (
      uppy &&
      Object.values(uppy?.getState()?.files)?.every(({ progress }) => progress.uploadComplete)
    );
  };

  const uppyResetWhenUploadComplete = () => {
    if (uploadComplete && isUploadCompleted(uppyRef.current)) {
      uppyRef.current.reset();
      setUploadComplete(false);
    }
  };

  async function triggerS3Download(res) {
    if (res.failed.length !== 0) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UPLOAD_FILE_ERROR,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      return false;
    }

    const files = res.successful.map((el) => ({
      filename: el.name,
      handle: el.s3Multipart?.key || el.meta.name,
      folderHandle: filesParentFolderRef?.current[el?.id]?.folder || '',
      uploadToTeam: filesParentFolderRef?.current[el?.id]?.uploadToTeam,
    }));

    for (const key of files) {
      await post(`${FILES}/${CREATE}`, {
        handle: key.handle,
        filename: key.filename,
        owner: ownerRef.current,
        folderHandle: key.folderHandle,
        uploadToTeam: key.uploadToTeam,
      });
    }

    res.successful.map((el) => delete filesParentFolderRef.current[el.id]);
  }

  const getMaxFileSize = () => user?.userProperties.maxFileSize;

  // upload all files or exclude unzipped files over 500 mb from being uploaded
  const continueUpload = (willZip) => {
    if (willZip) {
      uppyRef.current?.getFiles().forEach((file) => {
        if (
          file.size > 500000000 &&
          !file.type.endsWith('compressed') &&
          !file.type.endsWith('zip')
        )
          uppyRef.current?.removeFile(file.id);
      });
      uppyRef.current?.info('Removed unzipped files over 500mb');
    }
    uppyRef.current?.upload();
  };

  useEffect(() => {
    const uppy_ = new Uppy({
      id: 'uppy',
      autoProceed: false,
      restrictions: {
        maxFileSize: 1099511627776,
        maxNumberOfFiles: 100,
      },
      locale: {
        strings: {
          browseFiles: 'browse',
          dropPasteImportFiles: 'Drop files here or %{browse}',
          uploadingXFiles: {
            0: 'Uploading %{smart_count} file - Uploading continues in background',
            1: 'Uploading %{smart_count} files - Uploading continues in background',
          },
          poweredBy2: '',
        },
      },
    });

    uppy_.on('files-added', (files) => {
      // check if there are any unzipped files over 500MB
      let canUpload = true;
      const isLargeUnzippedFiles = (file) => {
        return (
          file.size > 500000000 &&
          !compressedFileExtensions.includes(file.type) &&
          !compressedFileExtensions.includes(file.extension)
        );
      };

      const isUppyCanBeCloseAfterCheck = files.every((file) => isLargeUnzippedFiles(file));

      files.every((file) => {
        if (isLargeUnzippedFiles(file)) {
          canUpload = false;
          dispatch(
            showModal({
              name: MODAL_UPLOAD_FILE_WARNING,
              props: { fileSize: parseFileSize(file.size), isUppyCanBeCloseAfterCheck },
            })
          );
          return false;
        }
        return true;
      });

      const currentUserMaxFileSize = convertToBytes(getMaxFileSize());
      const currentUserStorageSize = convertToBytes(filesSizeRef.current);
      const allowedMaxFileSize = convertToBytes(UPLOADING_OVER_LIMIT_FILE_SIZE);
      const allowedStorageSize = convertToBytes(maxStorageSize);

      const allowedMaxFileSizeForUser =
        currentUserMaxFileSize > allowedMaxFileSize ? currentUserMaxFileSize : allowedMaxFileSize;

      // check if files added will pass storage limit and remove all of them if they will
      const removeFilesOverQuota = (list = []) => {
        const { filesForDeleting } = list.reduce(
          (acc, el) => {
            if (
              el.size > allowedMaxFileSizeForUser ||
              acc.overQuotaFilesNumber >= UPLOADING_FILES_OVER_LIMIT
            ) {
              return { ...acc, filesForDeleting: [...acc.filesForDeleting, el] };
            }
            acc.overQuotaFilesNumber += 1;
            return acc;
          },
          {
            overQuotaFilesNumber: 0,
            filesForDeleting: [],
          }
        );

        if (filesForDeleting.length) {
          filesForDeleting.forEach((file) => {
            dispatch(
              addMixpanelEvent({
                eventName: '"Upgrade max file size" modal window',
                eventProps: { fileName: file?.meta?.name, fileSize: file?.size },
                userIncrementName: '# of modal windows displayed',
              })
            );
            uppy_.removeFile(file.id);
          });

          dispatch(
            showModal({
              name: MODAL_UPGRADE_ACCOUNT,
              props: { upgradeType: upgradeAccountTypes.maxStorageSize },
            })
          );

          showToast({
            type: TOAST_TYPE_ERROR,
            text: `You have reached limit of files in preview mode. ${filesForDeleting.length} files will not be uploaded`,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      };

      const filesToBeUploadedSize = Object.values(files).reduce(
        (totalSize, file) => totalSize + file.size,
        0
      );

      if (currentUserStorageSize + filesToBeUploadedSize >= allowedStorageSize) {
        const overQuotaFiles = files.reduce(
          (acc, file) => {
            if (acc.updatedStorageSize + file.size > allowedStorageSize) {
              acc.list.push(file);
            }
            acc.updatedStorageSize += file.size;
            return acc;
          },
          { updatedStorageSize: currentUserStorageSize, list: [] }
        );

        removeFilesOverQuota(overQuotaFiles.list);
      }

      files.forEach(({ id }) => {
        filesParentFolderRef.current[id] = {
          folder: currentFolderRef.current,
          uploadToTeam: currentDatasetRef.current === getTeamNameLabel(user[userTeamNameKey]),
        };
      });

      if (canUpload) uppy_.upload(files);
    });

    uppy_.use(GigasheetUppyIngestion, {});

    uppy_.use(GoogleDrive, {
      companionUrl: companionUrl,
    });

    uppy_.use(Dropbox, {
      companionUrl: companionUrl,
    });

    uppy_.use(Url, {
      id: 'AWS-S3',
      companionUrl: companionUrl,
      title: 'AWS S3',
      locale: {
        strings: {
          enterUrlToImport: 'Enter S3 presigned URL to import',
        },
      },
    });

    uppy_.use(Url, {
      id: 'URL',
      companionUrl: companionUrl,
    });

    uppy_.use(OneDrive, {
      companionUrl: companionUrl,
    });

    uppy_.use(Box, {
      companionUrl: companionUrl,
    });

    uppy_.use(AwsS3Multipart, {
      limit: 10,
      companionUrl: companionUrl,
      async createMultipartUpload() {
        const response = await get(`${UPLOAD}/${S3}/createMultipartUpload`);
        return { uploadId: response.UploadId, key: response.Key };
      },
      async listParts(file, { uploadId, key }) {
        const response = await get(`${UPLOAD}/${S3}/listParts/${key}/${uploadId}`);
        const parts = response.Parts == null ? [] : response.Parts;
        return parts;
      },
      async prepareUploadParts(file, partData) {
        const response = await get(
          `${UPLOAD}/${S3}/prepareUploadParts/${partData.key}/${partData.uploadId}/${partData.partNumbers}`
        );
        return response;
      },
      async abortMultipartUpload(file, { uploadId, key }) {
        await get(`${UPLOAD}/${S3}/abortMultipartUpload/${key}/${uploadId}`);
        return {};
      },
      async completeMultipartUpload(file, { uploadId, key, parts }) {
        await post(`${UPLOAD}/${S3}/completeMultipartUpload/${key}/${uploadId}`, {
          parts: parts,
        });
        return {};
      },
      getChunkSize() {
        return 1024 * 1024 * 5;
      },
    });

    uppy_.on('progress', (progress) => {
      setUploadComplete(false);
      if (progress === 100) {
        hideModal();
        setUploadComplete(true);
      }
    });

    uppy_.on('complete', (result) => {
      setFakeFiles(result);
      triggerS3Download(result);

      //Mixpanel Upload File Event
      if (result?.successful?.length) {
        for (let i = 0; i < result.successful.length; i++) {
          const uploadSource =
            result.successful[i].source === 'react:DashboardModal'
              ? 'local computer'
              : result.successful[i].source;

          Mixpanel.identify(email);
          Mixpanel.track('Upload File', {
            'File Type': result.successful[i].extension,
            'Upload Method': uploadSource,
            'File Name': result.successful[i].name,
            'File Size': parseFileSize(result.successful[i].size),
            'Upload URL': result.successful[i].uploadURL || '',
          });
          Mixpanel.people.increment('# of uploads', 1);
        }
      }
    });

    uppy_.on('upload-error', (file, error) => {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: `Error uploading ${file.name}: ${error}`,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    });

    mixpanelUppyEventsTracking(uppy_);
    uppyRef.current = uppy_;

    return () => {
      uppyRef.current?.close();
    };
  }, []);

  const onUppyClose = () => {
    hideModal();
  };

  useEffect(() => {
    uppyRef.current?.on('complete', () => {
      uppyResetWhenUploadComplete();
    });
  }, [uploadComplete, show, uppyRef.current]);

  useEffect(() => {
    let element;
    let link;
    if (
      uppyRef.current?.store?.state?.plugins['react:DashboardModal']?.activePickerPanel.id ===
      'AWS-S3'
    ) {
      element = document.querySelector('.uppy-Url');
      link = document.createElement('a');
      link.classList.add('content-link');
      link.href =
        'https://docs.aws.amazon.com/AmazonS3/latest/userguide/ShareObjectPreSignedURL.html';
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.innerText = 'How to create an S3 presigned URL?';
      element?.appendChild(link);
    }
    return () => element?.removeChild(link);
  }, [uppyRef.current?.store?.state?.plugins['react:DashboardModal']?.activePickerPanel]);

  if (uppyRef.current) {
    return (
      <Portal>
        <div className={'uppyUploadContainer'}>
          <DashboardModal
            uppy={uppyRef.current}
            closeModalOnClickOutside
            onRequestClose={onUppyClose}
            open={show}
            onClick={(event) => addClickbyPluginToMixpanel(event.target.textContent)}
            plugins={['GoogleDrive', 'Dropbox', 'OneDrive', 'Box', 'AWS-S3', 'URL']}
          />
          <ZipWarningPopup continueUpload={continueUpload} />
        </div>
      </Portal>
    );
  } else {
    return null;
  }
};

export default withModal({ name: MODAL_UPLOAD_FILE, destroyOnHide: false })(UppyUploaderMulti);
