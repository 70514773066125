import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Mixpanel } from '@/Mixpanel';

import withModal from '@/Components/Modals/withModalHOC';
import { Button } from '@/Components/UI/Button/Button';
import ShowToast from '@/Components/Toast/showToastTemplate';
import ThreeDotsLoader from '@/Components/Loader/ThreeDotsLoader/ThreeDotsLoader';

import { showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { spreadsheetSliceName } from '@/redux/constants';
import { prepareClientState } from '@/redux/reducers/spreadsheetSlice';

import {
  COPY,
  FILE,
  MODAL_EXCEEDS_LIMITS,
  MODAL_MAKE_COPY_SUCCESS,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { parseFileSize } from '@/Utils/parseFileSize';
import { post } from '@/Utils/API';
import Modal from '@/Components/UI/Modal/Modal';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ExceedsLimits = ({ show, hideModal }) => {
  const currentFile = useCurrentFile();
  const { clientState } = useSelector((state) => state[spreadsheetSliceName]);

  const uuid = parseFileSize(currentFile?.metadata?.FileUuid);

  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState('');

  const onSaveCopyButtonClick = async () => {
    try {
      if (uuid !== null) {
        setIsFetching(true);
        const payload = {
          state: prepareClientState(clientState),
        };
        const endpoint = `${FILE}/${uuid}/${COPY}`;
        const result = await post(endpoint, payload);
        Mixpanel.track('User Saves A Shared File (Exceeds storage limits of the file owner)', {
          file_uuid: uuid,
        });

        if (result.Success) {
          dispatch(
            showModal({
              name: MODAL_MAKE_COPY_SUCCESS,
              props: {
                uuid: result.NewHandle,
              },
            })
          );
        } else {
          ShowToast({
            type: TOAST_TYPE_ERROR,
            text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
            errorContext: result,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      }
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      throw new Error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const titleComponent = (
    <div className='flex flex-col mb-6 '>
      <span className='text-2xl text-midnight-600'>Disrupted Access</span>
      <span className='text-lg text-ui-helper'>Limited Preview</span>
    </div>
  );

  const sendMixpanelEvent = () => {
    dispatch(
      addMixpanelEvent({
        eventName: 'Preview Mode (Exceeds storage limits of the file owner)',
        eventProps: { file_uuid: uuid, current_filename: currentFile?.metadata?.FileName },
        userIncrementName: '# of preview mode displayed',
      })
    );
  };

  useEffect(() => {
    show && sendMixpanelEvent();
  }, [show]);

  return (
    <Modal isOpen={show} onClose={hideModal} title={titleComponent}>
      {isFetching ? (
        <ThreeDotsLoader text='' />
      ) : (
        <>
          <p className='mb-4 text-sm font-bold'>Exceeds storage limits of the file owner</p>
          <div className='flex justify-end mt-8'>
            <Button onClick={hideModal} color='none' disabled={isFetching}>
              Preview Sheet
            </Button>
            <Button
              onClick={onSaveCopyButtonClick}
              color='sunrise'
              className='flex flex-row'
              disabled={isFetching}
            >
              Save a Copy
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withModal({ name: MODAL_EXCEEDS_LIMITS })(ExceedsLimits);
