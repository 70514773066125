export const throttle = (func, delay) => {
  let canRun = true;
  return (...args) => {
    if (canRun) {
      func(...args);
      canRun = false;
      setTimeout(() => (canRun = true), delay);
    }
  };
};
