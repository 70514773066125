import { useEffect, useMemo, useRef, useState } from 'react';

import { throttle } from '../../Utils/throttle';

import './styles.scss';

const MIN_WIDTH = 50;
const DEFAULT_WIDTH = '50%';

const SplitView = ({
  leftPane,
  rightPane,
  minWidth = MIN_WIDTH,
  containerWidth,
  _rightPanelWidth,
  isDraggingDisabled,
  rightPaneMinWidth,
  additionalClassNames = { splitter: '', rightPane: '', leftPane: '' },
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [rightPaneWidth, setRightPaneWidth] = useState();

  const containerRef = useRef(null);
  const separatorRef = useRef(null);

  const throttledOnMouseMove = useMemo(() => {
    const onMouseMove = (e) => {
      e.persist();
      if (!separatorRef.current || !isDragging || isDraggingDisabled) return;
      const containerRect = e.currentTarget.getBoundingClientRect();
      const mousePosition = e.clientX - containerRect.left;
      const rightPaneWidth = containerRect.width - mousePosition;
      const leftPaneFlexBasis = containerRect.width - rightPaneWidth;

      if (rightPaneWidth < rightPaneMinWidth || leftPaneFlexBasis < minWidth) return;

      separatorRef.current.style.left = mousePosition + separatorRef.current.offsetWidth + 'px';
      setRightPaneWidth(rightPaneWidth);
    };

    return throttle(onMouseMove, 1);
  }, [separatorRef, isDragging, minWidth, isDraggingDisabled, rightPaneMinWidth]);

  const onMouseDown = () => {
    setIsDragging(true);
  };

  const onStopResize = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (!containerWidth) return;
    const timeout = setTimeout(() => {
      setRightPaneWidth(containerRef.current.clientWidth / 2);
      separatorRef.current.style.left = `calc(50% + ${separatorRef.current.offsetWidth * 1.5}px)`;
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [containerWidth]);

  const leftPaneStyle = {
    width: rightPaneWidth ? containerRef.current.clientWidth - rightPaneWidth : DEFAULT_WIDTH,
  };

  const rightPaneStyle = {
    width: rightPaneWidth ? rightPaneWidth : DEFAULT_WIDTH,
  };

  useEffect(() => {
    if (_rightPanelWidth) {
      setRightPaneWidth(_rightPanelWidth);
      separatorRef.current.style.left =
        containerRef.current.offsetWidth - _rightPanelWidth + 20 + 'px';
    }
  }, [_rightPanelWidth, separatorRef, containerRef]);

  return (
    <div
      role='button'
      tabIndex={0}
      onKeyUp={() => {}}
      ref={containerRef}
      onMouseUp={onStopResize}
      onMouseLeave={onStopResize}
      onMouseMove={throttledOnMouseMove}
      className='splitter'
    >
      <div style={leftPaneStyle} className={`leftPane pane ${isDragging ? 'dragging' : ''}`}>
        {leftPane}
      </div>
      <div
        role='button'
        tabIndex={0}
        onKeyUp={() => {}}
        onMouseDown={onMouseDown}
        ref={separatorRef}
        className='separator'
      />
      <div
        style={rightPaneStyle}
        className={`rightPane pane ${isDragging ? 'dragging' : ''} ${
          additionalClassNames.rightPane
        }`}
      >
        {rightPane}
      </div>
    </div>
  );
};

export default SplitView;
