import React from 'react';
import {
  changeIndividualColumnState,
  clearClientState,
  initialState,
  restoreGridState,
} from '@/redux/reducers/spreadsheetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { spreadsheetSliceName } from '@/redux/constants';
import { Button } from '@/Components/UI/Button/Button';
import { useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_COMMENT_THREAD } from '@/Utils/constants';

const CommentResetSheet = ({ column, row, close }) => {
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetSheet = () => {
    try {
      close();
      dispatch(restoreGridState(initialState.clientState));
      columnDefs.forEach((e) => {
        if (e.hide && !e.rowGroup) return;
        dispatch(
          changeIndividualColumnState({
            colId: e.colId,
            hide: false,
            rowGroup: false,
            rowGroupIndex: null,
          })
        );
      });
      clearClientState();
      searchParams.append(QUERY_PARAM_COMMENT_THREAD, `${column}_${row}`);
      setSearchParams(searchParams);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const cancel = () => {
    try {
      searchParams.delete(QUERY_PARAM_COMMENT_THREAD);
      setSearchParams(searchParams);
      close();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className='px-4 pb-4'>
      <div data-cy='comment-reset-sheet-div' className='text-sm'>
        To open the comment you need to reset the sheet.
      </div>
      <div>
        <div className='flex flex-row justify-end gap-4 mt-4'>
          <Button dataCy='comment-cancel-btn' onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={resetSheet} dataCy='comment-reset-sheet-btn' color='oceanBlue'>
            Reset Sheet
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommentResetSheet;
