import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import Label from '@/Components/UI/Form/Label/Label';
3;
import SearchSelect from '@/Components/SearchSelect/SearchSelect';

import withModal from '@/Components/Modals/withModalHOC';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';
import { spreadsheetSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import {
  setIsInsertingNewColumn,
  changeIndividualColumnState,
} from '@/redux/reducers/spreadsheetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import { post } from '@/Utils/API';
import { MODAL_TRIM_WHITESPACE } from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import '../style/index.scss';

const TrimWhitespaceModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { options, setTargetColumn, targetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: ({ type }) => type === 'String',
  });

  const closeTrimWhitespacePopup = () => {
    hideModal();
    setTargetColumn(null);
  };

  const trimWhitespace = async () => {
    dispatch(setIsInsertingNewColumn(true));
    try {
      const uuid = getFileUuidFromPath();
      await post(`trim-whitespace/${uuid}/${targetColumn.value}`, {}, true);
      await dispatch(fetchOpenedFile(uuid));
      dispatch(changeIndividualColumnState({ colId: targetColumn.value, hide: true }));

      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Trim Whitespace' },
          userIncrementName: '# of function use',
        })
      );
      setTargetColumn(null);
      hideModal();
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeTrimWhitespacePopup}
      title='Trim Whitespace'
      articleID='69000842012'
      iconName='scissors'
    >
      <div className='trim-whitespace-container'>
        <div className='mb-4'>
          <Label text='Choose column' />
          <SearchSelect options={options} onChange={setTargetColumn} value={targetColumn} />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          onClick={closeTrimWhitespacePopup}
          dataCy='cancel-btn'
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={trimWhitespace}
          disabled={!targetColumn}
          dataCy='save-btn'
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_TRIM_WHITESPACE })(TrimWhitespaceModal);
