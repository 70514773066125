export const convertToBytes = (size) => {
  if (!size) {
    return;
  }

  const sizeLetter = size.toLowerCase().match(/[a-z]/)['0'];
  const parsedSize = parseFloat(size);
  switch (sizeLetter) {
    case 'k':
      return parsedSize * 1024;
    case 'm':
      return parsedSize * Math.pow(1024, 2);
    case 'g':
      return parsedSize * Math.pow(1024, 3);
    case 't':
      return parsedSize * Math.pow(1024, 4);
    default:
      return parsedSize;
  }
};
