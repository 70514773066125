import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import showToast from '../../Toast/showToastTemplate';
import withModal from '../withModalHOC';

import { useFunctionModal } from '../../../hooks/useFunctionModal';

import { spreadsheetSliceName } from '../../../redux/constants';
import { fetchOpenedFile } from '../../../redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '../../../redux/reducers/modalsSlice';
import { setIsInsertingNewColumn } from '../../../redux/reducers/spreadsheetSlice';

import { Button } from '@/Components/UI/Button/Button';
import { Select } from '@/Components/UI/Form/Select/Select';
import Modal from '@/Components/UI/Modal/Modal';
import { post } from '../../../Utils/API';
import {
  MODAL_EXTRACT_DOMAIN_NAME,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  TOAST_TYPE_ERROR,
} from '../../../Utils/constants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ExtractDomainNamePopup = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const { options, setTargetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: ({ type }) => type === 'String' || type === 'URL',
  });

  const [selectedColumnValue, setSelectedColumnValue] = useState(options[0]?.value);
  const [, setSelectedColumn] = useState(options[0]);

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  useEffect(() => {
    const column = options?.find((option) => option?.value === selectedColumnValue);
    setSelectedColumn(column);
  }, [selectedColumnValue]);

  const changeCase = async () => {
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }

    dispatch(setIsInsertingNewColumn(true));

    try {
      setIsSaveBtnDisabled(true);
      const endpoint = `extract-domain/${getFileUuidFromPath()}/${selectedColumnValue}`;
      const castResponse = await post(endpoint);

      if (!castResponse?.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
          errorContext: castResponse,
          endpoint: endpoint,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }

      dispatch(fetchOpenedFile(getFileUuidFromPath()));

      setIsSaveBtnDisabled(false);
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const save = () => changeCase();

  const cancel = () => {
    setTargetColumn(null);
    hideModal();
  };

  return (
    <Modal
      iconName='link'
      isOpen={show}
      title='Extract Domain Name'
      articleID='69000853205'
      onClose={cancel}
      size='small'
    >
      <>
        <div className='flex flex-col gap-4 pb-8 border-b border-ui'>
          <Select
            disabled={options.length === 0}
            label='Column'
            onChange={setSelectedColumnValue}
            options={options}
            value={selectedColumnValue}
          />
        </div>

        <div className='flex flex-row justify-end gap-4 mt-4'>
          <Button onClick={cancel}>Cancel</Button>
          <Button
            disabled={!selectedColumnValue?.length || isSaveBtnDisabled}
            onClick={save}
            color='oceanBlue'
          >
            Apply
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default withModal({ name: MODAL_EXTRACT_DOMAIN_NAME })(ExtractDomainNamePopup);
