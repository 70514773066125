import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { mapUserRoles, sortUserProperties } from '../../Utils/userConversion/userConversion';
import { userMetadataKey, userRolesKey } from '../../Utils/constants';
import { userDataSliceName } from '../constants';
import { get } from '@/Utils/API';

const initialState = {
  user: null,
  subscriptions: null,
};

export const refreshSubscriptions = createAsyncThunk(
  `${userDataSliceName}/refreshSubscriptions`,
  async () => {
    const subscriptions = await get('billing/active-subscriptions/20').then((subscriptions) => {
      return subscriptions;
    });

    return {
      subscriptions,
    };
  }
);

export const userDataSlice = createSlice({
  name: userDataSliceName,
  initialState,
  reducers: {
    setUser: {
      prepare(user) {
        const userRoles = sortUserProperties(user[userRolesKey]);
        const userProperties = mapUserRoles(userRoles);

        const hasBillingId =
          user[userMetadataKey]?.stripe_id || user[userMetadataKey]?.chargebee_id ? true : false;
        userProperties.hasBillingId = hasBillingId;

        return { payload: { ...user, userProperties } };
      },
      reducer(state, action) {
        state.user = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshSubscriptions.fulfilled, (state, action) => {
      const { subscriptions } = action.payload;
      state.subscriptions = subscriptions;
    });
    builder.addCase(refreshSubscriptions.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const { setUser } = userDataSlice.actions;

export default userDataSlice.reducer;
