import {
  FILE_STATUS_ENRICHING,
  FILE_STATUS_LOADING,
  FILE_STATUS_PROCESSING,
  FILE_STATUS_PROCESSED,
  FILE_STATUS_ERROR,
} from './fileConstants';
const rowClassNameGenerator = (row, deleting, selectedFilesData, isDraggingRow) => {
  let rowClass;

  if (!row.IsDirectory) rowClass = 'dataset-file';

  switch (row.Status) {
    case FILE_STATUS_PROCESSED:
      rowClass = 'dataset';
      break;
    case FILE_STATUS_ERROR:
      rowClass = 'dataset-error';
      break;
    case FILE_STATUS_PROCESSING:
      rowClass = 'dataset-processing';
      break;
    case FILE_STATUS_ENRICHING:
      rowClass = 'dataset-enriching';
      break;
    case FILE_STATUS_LOADING:
      rowClass = 'dataset-loading';
      break;
    default:
      rowClass = 'dataset';
      break;
  }

  if (!row.IsDirectory && deleting.includes(row.FileUuid)) {
    rowClass = 'dataset-deleted';
  }
  if (row.IsDirectory) rowClass = 'dataset-folder';

  if (row.IsDirectory && deleting.includes(row.FileUuid)) {
    rowClass = 'dataset-folder-deleted';
  }
  const selectedFilesId = selectedFilesData.map((e) => e.FileUuid);
  if (selectedFilesId.includes(row.FileUuid) && isDraggingRow) {
    rowClass = 'dataset-dragging-file';
  }

  return rowClass;
};

export default rowClassNameGenerator;
