import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import { Button } from '@/Components/UI/Button/Button';

import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import { MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { setUserClosedUpgradeRequiredModal } from '@/redux/reducers/spreadsheetSlice';
import { parseFileSize } from '@/Utils/parseFileSize';
import { convertToBytes } from '@/Utils/convertToBytes';
import { parseRowCount } from '@/Utils/DatasetTree/parseRowCount';
import UpgradeButton from '../UpgradeAccount/UpgradeButton';
import Modal from '@/Components/UI/Modal/Modal';

const DefaultModalTexts = {
  header: 'This file exceeds account storage or file size limits.',
  body: 'For full sheet control upgrade your account or delete some files from your Library.',
};

const UpgradeRequiredModal = ({ show, hideModal }) => {
  const { currentFile, filesSize } = useSelector((state) => state[datasetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const currentFileSize = parseFileSize(currentFile?.metadata?.FileSize);
  const maxStorageSize = user?.userProperties?.maxStorageSize;
  const maxFileSize = user?.userProperties?.maxFileSize;
  const isOverQuota = !currentFile?.WithinQuota;
  const currentFileRows = currentFile?.metadata?.FileRows;
  const maxFileRows = user?.userProperties?.maxFileRows;
  const isFileRowOverQuota = currentFile?.OverRowQuota;

  const dispatch = useDispatch();
  const [modalTexts, setModalTexts] = useState(DefaultModalTexts);

  const createAccountActions = {
    cancel: () => {
      dispatch(setUserClosedUpgradeRequiredModal(true));
      hideModal();
    },
  };

  const sendMixpanelEvent = useCallback(() => {
    if (show) {
      dispatch(
        addMixpanelEvent({
          eventName: `Preview Mode (${
            (isOverQuota && 'quota') || (isFileRowOverQuota && 'max file rows quota')
          })`,
          eventProps: {
            filesSize,
            current_filename: currentFile?.metadata?.FileName,
            rowsNumber: currentFile?.metadata?.FileRows,
          },
          userIncrementName: '# of preview mode displayed',
        })
      );
    }
  }, [dispatch, isFileRowOverQuota, isOverQuota, show]);

  useEffect(() => {
    if (!isOverQuota && !isFileRowOverQuota) return;

    const fileSizeInBytes = convertToBytes(currentFileSize);
    const maxSizeInBytes = convertToBytes(maxFileSize);
    const filesSizeInBytes = convertToBytes(filesSize);
    const maxStorageInBytes = convertToBytes(maxStorageSize);
    const isFileSizeOverQuota =
      fileSizeInBytes >= maxSizeInBytes && filesSizeInBytes < maxStorageInBytes;
    const currentFileRowsFormatted = parseRowCount(currentFileRows);
    const maxFileRowsFormatted = parseRowCount(Number(maxFileRows));

    let reasonOfOverQuota = '';
    let amountOfQuotaOverrun = '';
    let reasonValue = '';
    let bodyText = '';

    if (isOverQuota) {
      reasonOfOverQuota = isFileSizeOverQuota ? 'max file size limit' : 'storage limit';
      amountOfQuotaOverrun = isFileSizeOverQuota ? maxFileSize : maxStorageSize;
      reasonValue = currentFileSize;
      bodyText = `For full sheet control upgrade to an account with ${reasonOfOverQuota} or delete some files from your Library.`;
    } else if (isFileRowOverQuota) {
      reasonOfOverQuota = 'row limit';
      amountOfQuotaOverrun = `${maxFileRowsFormatted} rows`;
      reasonValue = `${currentFileRowsFormatted} rows`;
      bodyText = 'For full sheet control upgrade to Gigasheet Premium.';
    }

    setModalTexts({
      header: `This file is ${reasonValue} and exceeds account ${reasonOfOverQuota} of ${amountOfQuotaOverrun}.`,
      body: bodyText,
    });
  }, [
    isOverQuota,
    isFileRowOverQuota,
    sendMixpanelEvent,
    currentFileSize,
    maxFileSize,
    filesSize,
    maxStorageSize,
    currentFileRows,
    maxFileRows,
  ]);

  useEffect(() => {
    if (show && (isOverQuota || isFileRowOverQuota)) sendMixpanelEvent();
  }, [isFileRowOverQuota, isOverQuota, sendMixpanelEvent, show]);

  const mixpanelEventParam = () => ({
    eventName: `opened Upgrade Account modal from preview mode (${
      (isOverQuota && 'quota') || (isFileRowOverQuota && 'max file rows quota')
    })`,
    eventProps: {},
    userIncrementName: '# of Upgrade Account modal displayed',
  });

  const bodyComponent = (
    <div className=''>
      <p className='mb-4 text-sm font-bold'>{modalTexts.header}</p>
      <p className='text-sm'>{modalTexts.body}</p>
      <div className='flex justify-end mt-14'>
        <Button onClick={createAccountActions.cancel} color='none'>
          Preview your sheet
        </Button>
        <UpgradeButton
          text='Upgrade Account'
          color='pear'
          addMixpanelEventParam={mixpanelEventParam}
        />
      </div>
    </div>
  );
  return (
    <Modal
      isOpen={show}
      onClose={() => {
        dispatch(setUserClosedUpgradeRequiredModal(true));
        hideModal();
      }}
      title='Upgrade Required'
      subhead='Limited Preview'
      iconName='rocket-launch'
    >
      {bodyComponent}
    </Modal>
  );
};

export default withModal({ name: MODAL_UPGRADE_REQUIRED })(UpgradeRequiredModal);
