import { ReactComponent as SuccessPartyIllustration } from '@/Assets/icons/Illustrations/success_party.svg';
import LoadingOverlayReact from '@/pages/DatasetPage/LoadingOverlay';
import { formatAsCurrency } from '@/Components/Modals/Checkout/CheckoutHelper';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import { get, postRaw } from '@/Utils/API';
import {
  CHARGEBEE_SITE_PROD,
  CHARGEBEE_SITE_TEST,
  LOCAL_STORAGE_CHECKOUT_PERIOD,
  LOCAL_STORAGE_CHECKOUT_STORAGE,
  MODAL_CHECKOUT,
  QUERY_PARAM_CHECKOUT,
  QUERY_PARAM_CHECKOUT_ID,
  QUERY_PARAM_CHECKOUT_STATE,
  QUERY_PARAM_CHECKOUT_UPGRADING,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getBaseUrl } from '@/Utils/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// period enums
const PERIOD_MONTHLY = 1;
const PERIOD_YEARLY = 2;

// storage enums
const STORAGE_25GB = 25;
const STORAGE_50GB = 50;
const STORAGE_75GB = 75;

const storagePrices = {
  [STORAGE_25GB]: 95,
  [STORAGE_50GB]: 165,
  [STORAGE_75GB]: 250,
};

export const clearCheckoutLocaleStorage = () => {
  [LOCAL_STORAGE_CHECKOUT_PERIOD, LOCAL_STORAGE_CHECKOUT_STORAGE].forEach((key) =>
    localStorage.removeItem(key)
  );
};

const getStorageFromQueryParameters = () => {
  const fallbackStorage = STORAGE_25GB;
  try {
    if (localStorage.getItem(LOCAL_STORAGE_CHECKOUT_STORAGE)) {
      const storageAsNumber = Number(localStorage.getItem(LOCAL_STORAGE_CHECKOUT_STORAGE));
      if (storageAsNumber in storagePrices) {
        return storageAsNumber;
      } else {
        return fallbackStorage;
      }
    } else {
      return fallbackStorage;
    }
  } catch (e) {
    console.error(e);
    return fallbackStorage;
  }
};

const Checkout = ({ show, hideModal }) => {
  const [period, setPeriod] = useState(
    localStorage.getItem(LOCAL_STORAGE_CHECKOUT_PERIOD) === 'yearly'
      ? PERIOD_YEARLY
      : PERIOD_MONTHLY
  );
  const [storage, setStorage] = useState(getStorageFromQueryParameters());
  const [success, setSuccess] = useState(false);
  const [price, setPrice] = useState(storagePrices[`${getStorageFromQueryParameters()}`]);
  const [zapFinished, setZapFinished] = useState(false);
  const [waitForZapTimedOut, setWaitForZapTimedOut] = useState(false);
  const [reloadCountDown, setReloadCountDown] = useState(30);
  const [searchParams, setSearchParams] = useSearchParams();
  const waitForZapInterval = 5 * 1000; // 5 seconds
  const waitForZapTimeout = 5 * 60 * 1000; // 5 minutes

  const { user } = useSelector((state) => state.userData);

  useEffect(() => {
    switch (period) {
      case PERIOD_MONTHLY:
        setPrice(Math.trunc(storagePrices[`${storage}`] * 12));
        break;
      case PERIOD_YEARLY:
        if (storage === STORAGE_25GB) {
          setPrice(295);
        } else {
          setPrice(Math.trunc(storagePrices[`${storage}`] * 10));
        }
        break;
    }
  }, [period, storage]);

  useEffect(() => {
    const queryParamCheckout = searchParams.get('checkout');
    const queryParamState = searchParams.get('state');

    if (queryParamCheckout === 'redirect' && queryParamState === 'succeeded') {
      onSuccess();
    }
  }, [searchParams]);

  useEffect(() => {
    if (zapFinished === true) {
      startReloadCountDown();
    }
  }, [zapFinished]);

  const onSuccess = () => {
    setSuccess(true);

    window.dataLayer?.push({
      event: 'purchase',
      enhanced_conversion_data: {
        email: user?.email,
      },
    });

    waitForZap();
  };

  const reloadPage = () => {
    close();
    window.location.reload();
  };

  const startReloadCountDown = () => {
    const intervalId = setInterval(() => {
      setReloadCountDown((prevReloadCountDown) => {
        if (prevReloadCountDown > 0) {
          return prevReloadCountDown - 1;
        }
        if (prevReloadCountDown === 0) {
          clearInterval(intervalId);
          reloadPage();
          return 0;
        }
      });
    }, 1000);
  };

  const waitForZap = () => {
    const intervalId = setInterval(() => {
      try {
        getUserMetadata().then((userMetadata) => {
          if (userMetadata?.chargebee_id?.length > 0) {
            clearInterval(intervalId);
            setZapFinished(true);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }, waitForZapInterval);

    setTimeout(() => {
      clearInterval(intervalId);
      setWaitForZapTimedOut(true);
    }, waitForZapTimeout);
  };

  const getUserMetadata = async () => {
    return await get('user/metadata').then((userMetadata) => {
      return userMetadata;
    });
  };

  const deleteQueryParams = () => {
    searchParams.delete(QUERY_PARAM_CHECKOUT);
    searchParams.delete(QUERY_PARAM_CHECKOUT_ID);
    searchParams.delete(QUERY_PARAM_CHECKOUT_STATE);
    searchParams.delete(QUERY_PARAM_CHECKOUT_UPGRADING);
    setSearchParams(searchParams);
  };

  const close = () => {
    clearCheckoutLocaleStorage();
    deleteQueryParams();
    hideModal();
    hideModal();
  };

  const getChargebeeSite = () => {
    if (
      ['dev.gigasheet.com', 'localhost', 'dev.supergiga.co'].some((hostname) =>
        getBaseUrl('api').includes(hostname)
      )
    ) {
      return CHARGEBEE_SITE_TEST;
    } else {
      return CHARGEBEE_SITE_PROD;
    }
  };

  const hasActiveSubscriptions = async () => {
    try {
      return await get('billing/active-subscriptions/20').then((subscriptions) => {
        return subscriptions.length > 0;
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getItemPriceIds = (storage, period) => {
    const periodString = period === PERIOD_MONTHLY ? 'Monthly' : 'Yearly';
    return [
      `Gigasheet-Premium-2023-10-USD-${periodString}`,
      `${storage}-Gigabyte-GB-Storage-USD-${periodString}`,
    ];
  };

  const onClickContinue = async () => {
    const _hasActiveSubscriptions = await hasActiveSubscriptions();
    if (_hasActiveSubscriptions) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: 'Account already has an active subscription',
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      close();
    } else {
      const cbInstance = window.Chargebee.init({
        site: getChargebeeSite(),
        isItemsModel: true,
      });

      cbInstance.openCheckout({
        layout: 'in_app',
        hostedPage: () => {
          const payload = {
            item_price_ids: getItemPriceIds(storage, period),
            redirect_url: `${getBaseUrl('webapp')}/datasets?checkout=redirect`,
          };

          return postRaw('billing/checkout-hosted-page', payload).then((response) => {
            return response.json();
          });
        },
        success() {},
        loaded() {},
        error(err) {
          console.error(err);
        },
        close: () => {},
        step() {},
      });
    }
  };

  const getPeriodBtn = (_period, text) => {
    const baseClassName =
      'pl-0 ml-0 rounded flex-grow w-[146px] h-[87px] mb-2 text-center text-sm font-extrabold';
    const nonActiveClassName = baseClassName + ' border-[#EBEBEB] border';
    const activeClassName = baseClassName + ' border-[#463AD4] border-2';

    return (
      <button
        className={period === _period ? activeClassName : nonActiveClassName}
        data-cy={
          period === _period
            ? 'btn-active-period'
            : `btn-period-${_period === PERIOD_MONTHLY ? 'monthly' : 'yearly'}`
        }
        onClick={() => setPeriod(_period)}
      >
        <div className='p-2'>
          {period === _period ? (
            <Icon
              className={`relative left-[114px] ${
                _period === PERIOD_YEARLY ? 'bottom-[11px]' : 'bottom-[20px]'
              }`}
              size={16}
              name='check-circle'
              weight='duotone'
              color='#463AD4'
            />
          ) : null}
          {_period === PERIOD_MONTHLY ? (
            <>
              <div className={period === _period ? 'relative bottom-[8px]' : ''}>{text}</div>
            </>
          ) : (
            <>
              <div className={period !== _period ? 'relative bottom-[-8px]' : ''}>{text}</div>
              <div
                hidden={_period !== PERIOD_YEARLY}
                className={`text-[9px] px-1 rounded bg-sunrise ${
                  period !== _period ? 'relative bottom-[-8px]' : ''
                }`}
              >
                {storage === STORAGE_25GB ? 'SAVE 74%' : '2 MONTHS FREE'}
              </div>
            </>
          )}
        </div>
      </button>
    );
  };

  const getMoreBtn = () => {
    return getStorageBtn(0, true);
  };

  const getStorageBtn = (_storage, isMoreBtn = false) => {
    const baseClassName =
      'pl-0 ml-0 rounded flex-grow w-[70px] h-[72px] mb-2 text-center text-sm font-extrabold';
    const nonActiveClassName = baseClassName + ' border-[#EBEBEB] border';
    const activeClassName = baseClassName + ' border-[#463AD4] border-2';

    return (
      <>
        {isMoreBtn ? (
          <button
            className={nonActiveClassName}
            data-cy='btn-storage-more'
            onClick={() => handleOpenMoreLink()}
          >
            <div>More</div>
          </button>
        ) : (
          <button
            className={storage === _storage ? activeClassName : nonActiveClassName}
            data-cy={storage === _storage ? 'btn-active-storage' : `btn-storage-${_storage}`}
            onClick={() => setStorage(_storage)}
          >
            {storage === _storage ? (
              <Icon
                className='relative -top-3 left-12'
                size={16}
                name='check-circle'
                weight='duotone'
                color='#463AD4'
              />
            ) : null}
            <div className={storage === _storage ? 'relative bottom-[8px]' : ''}>{_storage}GB</div>
          </button>
        )}
      </>
    );
  };

  const handleOpenMoreLink = () => {
    window.open('https://calendly.com/steve-schohn/jumbo-storage', '_blank', 'noopener,noreferrer');
  };

  const premiumAdvantages = [
    '1 Billion Rows/Sheet',
    'Export Filtered & Grouped Sheets',
    '10,000 Enrichment Credits/Month',
    'Combine 100 Files',
    '250 API Request/Month',
    'URL for BI & Spreadsheets',
  ];

  return (
    <Modal
      title={success ? '' : 'Choose Your Plan'}
      isOpen={show}
      size='medium'
      onClose={close}
      titleSize='large'
      shouldCloseOnBackgroundClick={!success}
      withoutCloseButton={success}
    >
      <div className={clsx('flex', success && 'hidden')}>
        <div>
          <div className='p-3 border rounded-xl'>
            <div className='mb-3 text-sm font-semibold'>Gigasheet Premium</div>
            <div className='text-ui-helper text-[13px] font-normal leading-6'>
              {premiumAdvantages.map((advantage) => {
                return (
                  <div key={advantage} className='flex items-center self-stretch gap-2'>
                    <Icon
                      className='relative top-[-1px]'
                      size={16}
                      name='check-circle'
                      weight='duotone'
                      color='#463AD4'
                    />
                    {advantage}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div className='ml-7 w-[320px]'>
            <div className='text-sm font-semibold'>Storage</div>
            <div className='h-20'>
              <div className='flex items-center justify-between'>
                <div className='flex-grow'>{getStorageBtn(STORAGE_25GB)}</div>
                <div className='flex-grow'>{getStorageBtn(STORAGE_50GB)}</div>
                <div className='flex-grow'>{getStorageBtn(STORAGE_75GB)}</div>
                <div className='flex-grow'>{getMoreBtn()}</div>
              </div>
            </div>
          </div>
          <div className=' ml-7 w-[320px]'>
            <div className='mt-6 text-sm font-semibold'>Billing Cycle</div>
            <div className='flex justify-between'>
              <div>{getPeriodBtn(PERIOD_MONTHLY, 'Monthly')}</div>
              <div>{getPeriodBtn(PERIOD_YEARLY, 'Yearly')}</div>
            </div>
          </div>
          <div className='flex h-20 place-content-end'>
            <div>
              <div className='flex mt-4 text-midnight place-content-end'>
                <div data-cy='price-monthly' className='text-3xl font-semibold'>
                  ${formatAsCurrency(Math.ceil(price / 12))} USD
                </div>
                <div className='relative pl-2 mt-1 text-sm top-1'>/month</div>
              </div>
              <div
                data-cy='price-yearly'
                className='flex pb-2 text-sm font-light text-shadow-600 place-content-end'
              >
                {period === PERIOD_MONTHLY
                  ? ''
                  : `Billed yearly at $${formatAsCurrency(price)} USD`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('relative -top-6', !success && 'hidden')}>
        {zapFinished ? (
          <div className='text-2xl font-semibold text-center text-midnight'>Success!</div>
        ) : (
          <div className='text-2xl font-semibold text-center text-midnight'>
            Please wait while we upgrade your account
          </div>
        )}
        <div className='pt-4 text-sm font-normal text-center'>
          {zapFinished ? (
            <div>Your account has been upgraded!</div>
          ) : waitForZapTimedOut ? (
            <div>
              <div className='mb-4'>
                It took a bit longer than expected to get your account upgraded.
              </div>
              <div>
                Try{' '}
                <a
                  role='button'
                  tabIndex={0}
                  onKeyDown={() => null}
                  className='text-capri-900 hover:cursor-pointer hover:underline'
                  onClick={reloadPage}
                >
                  reload
                </a>{' '}
                the page or contact support.
              </div>
            </div>
          ) : (
            <div>
              <LoadingOverlayReact />
            </div>
          )}
        </div>
        <div className={clsx(!zapFinished && 'hidden')}>
          <div className='flex justify-center'>
            <SuccessPartyIllustration />
          </div>
          <div className='pt-4 text-sm font-normal text-center text-capri-900'>
            Page will reload automatically in <b>{reloadCountDown}</b> seconds
          </div>
        </div>
      </div>
      <div>
        <hr className={clsx('solid', (waitForZapTimedOut || !zapFinished) && 'hidden')}></hr>
        <div>
          <div className={clsx('flex flex-row justify-end gap-4 mt-4', success && 'hidden')}>
            <Button dataCy='btn-cancel' onClick={close}>
              Cancel
            </Button>
            <Button dataCy='btn-continue' onClick={onClickContinue} color='oceanBlue'>
              Add Billing Details
            </Button>
          </div>
          <div
            className={clsx(
              'pt-4 text-sm font-normal text-center w-full',
              !zapFinished && 'hidden'
            )}
          >
            <button onClick={() => reloadPage()}>Or click here to reload immediately</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CHECKOUT })(Checkout);
