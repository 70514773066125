import React, { Component } from 'react';
import { URL_DATASETS } from '@/Utils/constants';
import ExternalScripts from '@/Components/ExternalScripts';
import { Button } from '@/Components/UI/Button/Button';
import { Mixpanel } from '@/Mixpanel';
import './index.scss';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);

    const formattedErrorDetails = {
      errorMessage: error?.message,
      errorStack: errorInfo,
    };

    this.setState({ hasError: true });

    Mixpanel.identify(this.props.user?.email);
    Mixpanel.track('Caught on error page', {
      errorDetails: formattedErrorDetails,
    });
    Mixpanel.people.increment('# of times caught error page', 1);
  }

  handleGoToLibrary = () => {
    this.setState({ hasError: false }, () => {
      window.location.replace(`${window.location.origin}/${URL_DATASETS}`);
    });
  };

  render() {
    const { logout } = this.props;
    if (this.state.hasError) {
      return (
        <div className='app'>
          <div className='app-wrapper'>
            <div className='main-content'>
              <div className='crash-page'>
                <div className='crash-page-content'>
                  <ExternalScripts />
                  <h1 className='!leading-tight'>
                    You could use <br /> a refresh.
                  </h1>
                  <p className='mb-8'>Refresh your browser or return to Gigasheet.</p>
                  <div className='flex gap-2'>
                    <Button size='large' color='violetWeb' onClick={this.handleGoToLibrary}>
                      Go to Library
                    </Button>
                    <Button onClick={logout} variant='ghost' size='large' color='shadow'>
                      Logout of Gigasheet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
