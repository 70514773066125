import { createSlice } from '@reduxjs/toolkit';
import { combineFilesSliceName } from '../constants';
import { COMBINE_FILES_USING_COLUMN_ORDER } from '@/Utils/constants';

const initialState = {
  newSheetName: '',
  combineMethod: COMBINE_FILES_USING_COLUMN_ORDER,
  addUnmatchedColumns: true,
  trimColumnNames: true,
  caseSensitive: false,
};

export const combineFilesSlice = createSlice({
  name: combineFilesSliceName,
  initialState,
  reducers: {
    setNewSheetName(state, action) {
      state.newSheetName = action.payload;
    },
    setCombineMethod(state, action) {
      state.combineMethod = action.payload;
    },
    setAddUnmatchedColumns(state, action) {
      state.addUnmatchedColumns = action.payload;
    },
    setTrimColumnNames(state, action) {
      state.trimColumnNames = action.payload;
    },
    setCaseSensitive(state, action) {
      state.caseSensitive = action.payload;
    },
  },
});

export const {
  setNewSheetName,
  setCombineMethod,
  setAddUnmatchedColumns,
  setTrimColumnNames,
  setCaseSensitive,
} = combineFilesSlice.actions;

export default combineFilesSlice.reducer;
