// The order of the connectors in this array will determine the order in which they are displayed in the UI. They are ordered by popularity with the most popular connectors at the top.

export const platformConnectors = [
  {
    id: 'salesforce',
    name: 'Salesforce',
    iconName: 'salesforce',
    isNexusConnector: true,
    subItems: [
      {
        id: 'salesforce-report',
        name: 'Salesforce Report',
        iconName: 'salesforce',
        isNexusConnector: true,
        nexusConnectorName: 'salesforceReport',
      },
      {
        id: 'salesforce-object',
        name: 'Salesforce Object',
        iconName: 'salesforce',
        isNexusConnector: true,
        nexusConnectorName: 'salesforce',
      },
    ],
  },

  {
    id: 'databricks',
    name: 'Databricks',
    iconName: 'databricksvectorlogo',
    isNexusConnector: true,
    subItems: [
      {
        id: 'databricks-direct',
        name: 'Databricks Direct (Token)',
        iconName: 'databricksvectorlogo',
        isNexusConnector: true,
        nexusConnectorName: 'databricksDirect',
      },
      {
        id: 'databricks-clone',
        name: 'Databricks Clone (Token)',
        iconName: 'databricksvectorlogo',
        isNexusConnector: true,
        nexusConnectorName: 'databricks',
      },
      {
        id: 'databricks-delta-share',
        name: 'Databricks Delta Share',
        iconName: 'databricksvectorlogo',
        isNexusConnector: true,
        nexusConnectorName: 'databricksDeltaShare',
      },
    ],
  },
  {
    id: 'hubspot',
    name: 'Hubspot',
    iconName: 'hubspot',
    isNexusConnector: true,
    nexusConnectorName: 'hubspot',
  },
  {
    id: 'snowflake',
    name: 'Snowflake',
    iconName: 'snowflake',
    isNexusConnector: true,
    nexusConnectorName: 'snowflake',
  },
  {
    id: 'mysql',
    name: 'MySQL',
    iconName: 'mysql',
    isNexusConnector: true,
    nexusConnectorName: 'mysql',
  },
  {
    id: 'postgresql',
    name: 'PostgreSQL',
    iconName: 'postgres',
    isNexusConnector: true,
    nexusConnectorName: 'postgres',
  },
  {
    id: 'google-analytics',
    name: 'Google Analytics',
    iconName: 'Google_Analytics',
    isNexusConnector: true,
    nexusConnectorName: 'google-analytics-data-api',
  },

  {
    id: 'freshsales',
    name: 'Freshsales',
    iconName: 'freshsalesvectorlogo',
    isNexusConnector: true,
    nexusConnectorName: 'freshsales',
  },
];
