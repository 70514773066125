import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_CONTACT_SALES } from '@/Utils/constants';
import { InlineWidget } from 'react-calendly';
import withModal from '../withModalHOC';

const ContactSales = ({ show, hideModal }) => {
  return (
    <Modal size='medium' isOpen={show} onClose={hideModal} withoutTitle={true}>
      <div className='relative' data-cy='business-paywall-widget'>
        <InlineWidget url='https://calendly.com/steve-schohn/gigasheet-business' />
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CONTACT_SALES, destroyOnHide: true })(ContactSales);
