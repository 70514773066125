import { get } from './API';
import { PUBLIC_LABEL, RESTRICTED_LABEL, URL_LIBRARY } from './constants';

export const getFolderStatus = async (currentDir, filePath, currentFile) => {
  if (!currentDir || !filePath.length) return false;

  let isShared = [];

  if (
    currentDir === currentFile?.metadata?.FileUuid &&
    (currentFile?.shared?.restricted?.length || currentFile?.shared?.public?.length)
  ) {
    isShared = [currentFile];
  } else {
    const response = await Promise.allSettled(
      filePath?.map(async ({ ParentDirectory }) => {
        return await get(`${URL_LIBRARY}/${ParentDirectory}`);
      })
    );

    const allFilesInParentPath = response?.reduce((acc, { status, value }) => {
      if (status === 'fulfilled') {
        return [...acc, ...value];
      }
      return acc;
    }, []);

    isShared = allFilesInParentPath.filter(({ metadata: { FileUuid: fileId }, shared }) => {
      return filePath?.some(({ FileUuid: id }) => {
        return id === fileId && (shared?.public?.length || shared?.restricted?.length);
      });
    });
  }

  if (!isShared?.length) return false;
  if (isShared[0]?.shared?.public?.length) return PUBLIC_LABEL;
  if (isShared[0]?.shared?.restricted?.length) return RESTRICTED_LABEL;

  return false;
};
