import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';

import DotsLoader from '@/Components/Loader/DotsLoader';

import { get } from '@/Utils/API';
import { parseFileSize } from '@/Utils/parseFileSize';
import { URL_DATASETS, sortDirection } from '@/Utils/constants';
import { FILE_STATUS_PROCESSED } from '@/Utils/fileConstants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { format } from 'date-fns';

const LookupChooseFile = ({ setSelectedFile, closeModal }) => {
  const [initialData, setInitialData] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const getData = useCallback(async () => {
    try {
      setSearchResultLoading(true);
      const files = await get(URL_DATASETS);
      const uuidOfFileToExclude = getFileUuidFromPath();
      const filterFiles = files.filter(
        (el) =>
          el.Status === FILE_STATUS_PROCESSED &&
          el.FileRows &&
          el.Deleting === 0 &&
          el.FileUuid !== uuidOfFileToExclude
      );
      setInitialData(filterFiles);
      setSearchResults(filterFiles);
    } catch (error) {
      throw new Error(error);
    } finally {
      setSearchResultLoading(false);
    }
  }, []);

  const data = useMemo(() => searchResults || [], [searchResults]);
  const columns = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'FileName',
      },
      {
        Header: 'Rows',
        accessor: 'FileRows',
      },
      {
        Header: 'File Size',
        accessor: 'FileSize',
        Cell: ({ cell }) => parseFileSize(cell.value),
      },
      {
        Header: 'Last Modified',
        accessor: 'LastUpdated',
        Cell: ({ row }) => {
          return format(new Date(row.values.LastUpdated), 'MMM d, yyyy - h:mm:ss a');
        },
      },
    ],
    []
  );

  const defaultSortBy = useMemo(
    () => [
      {
        id: 'LastUpdated',
        desc: true,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortBy,
      },
    },
    useSortBy
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const results = initialData?.filter((el) =>
      el.FileName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [initialData, searchTerm]);

  const onSelectFile = (file) => {
    setSelectedFile(file);
    closeModal();
  };
  return (
    <div className='lookup-choose-file-container'>
      <fieldset>
        <input
          type='text'
          placeholder='Search...'
          className='search-file-input'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span className='search-icon' />
      </fieldset>
      <div className='table-wrapper'>
        {searchResultLoading ? (
          <div className='lookupLoader'>
            <DotsLoader />
          </div>
        ) : (
          <table {...getTableProps()} className='table w-full'>
            <thead>
              {headerGroups.map((headerGroup, idx) => (
                <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, idx) => (
                    <th
                      key={idx}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`table-header ${
                        column.isSorted
                          ? column.isSortedDesc
                            ? sortDirection.descending
                            : sortDirection.ascending
                          : ''
                      }`}
                    >
                      {column.render('Header')}
                      <span>
                        <div className={'arrow-up'} />
                        <div className={'arrow-down'} />
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr key={idx} {...row.getRowProps()} onClick={() => onSelectFile(row.original)}>
                    {row.cells.map((cell, idx) => {
                      return (
                        <td
                          key={idx}
                          className='overflow-hidden whitespace-nowrap text-ellipsis font-medium mb-0 pl-[33px]'
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default LookupChooseFile;
