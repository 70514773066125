import { useSelector } from 'react-redux';

import GroupByPopup from '@/Components/Modals/GroupBy/GroupByModal';
import CombineFileModal from '@/Components/Modals/Combine/CombineFileModal';
import MakeCopyPopup from '@/Components/Modals/MakeCopy/MakeCopyPopup';
import EditFileModal from '@/Components/Modals/EditFile/EditFileModal';
import FilterPanel from '@/Components/Modals/FilterPanel/FilterPanelCnf';
import DeleteRowModal from '@/Components/Modals/DeleteRow/DeleteRowModal';
import ShareFileModal from '@/Components/Modals/ShareFile/ShareFileModal';
import SortPanelPopup from '@/Components/Modals/SortPanel/SortPanelPopup';
import DeleteRowsModal from '@/Components/Modals/DeleteRow/DeleteRowsModal';
import IfCondition from '@/Components/Modals/IfCondition/IfConditionsModal';
import MakeCopyFileModal from '@/Components/Modals/SaveAs/MakeCopyFileModal';
import ExportFileModal from '@/Components/Modals/ExportFile/ExportFileModal';
import SuccessCopyPopup from '@/Components/Modals/MakeCopy/SuccessCopyPopup';
import EnrichmentModal from '@/Components/Modals/Enrichment/EnrichmentModal';
import DeleteFileModal from '@/Components/Modals/DeleteFile/DeleteFileModal';
import CalcLengthModal from '@/Components/Modals/CalcLength/CalcLengthModal';
import PapercutModal from '@/Components/Modals/UpgradeAccount/PapercutModal';
import ExplodeDateModal from '@/Components/Modals/ExplodeDate/ExplodeDateModal';
import ExplodeJsonPopup from '@/Components/Modals/ExplodeJson/ExplodeJsonModal';
import SplitColumnModal from '@/Components/Modals/SplitColumn/SplitColumnModal';
import UppyUploaderMulti from '@/Components/UppyUploaderMulti/UppyUploaderMulti';
import RemoveAccessModal from '@/Components/Modals/RemoveAccess/RemoveAccessModal';
import CustomEnrichmentModal from '@/Components/Modals/Enrichment/CustomEnrichmentModal';
import UpgradeTeamMemberPopup from '@/Components/Modals/UpgradeTeamMember/UpgradeTeamMemberPopup';

import MoveFileModal from './MoveFileModal';
import AuditLogPopup from './AuditLog/AuditLogPopup';
import ContactSales from './ContactSales/ContactSales';
import ExceedsLimits from './ExceedsLimits/ExceedsLimits';
import ChangeCasePopup from './ChangeCase/ChangeCasePopup';
import InviteTeamPopup from './InviteTeam/InviteTeamPopup';
import SplitSheetPopup from './SplitSheet/SplitSheetPopup';
import IPOsintModal from './Enrichment/DataEnrichment/IPOsint';
import RevenueBase from './Enrichment/RevenueBase/RevenueBase';
import BusinessPaywall from './BusinessPaywall/BusinessPaywall';
import AutomationAPI from './AutomationAPI/AutomationAPI';
import CreateFolderModal from './CreateFolder/CreateFolderModal';
import DecodeBase64Modal from './DecodeBase64/DecodeBase64Modal';
import DeleteColumnModal from './DeleteColumn/DeleteColumnModal';
import PublishToWebModal from './PublishToWeb/PublishToWebModal';
import CreateAccountModal from './CreateAccount/CreateAccountModal';
import RenameColumnsPopup from './RenameColumns/RenameColumnsPopup';
import UpgradeRequiredModal from './UpgradeRequired/UpgradeRequired';
import CheckoutModal from '../../Components/Modals/Checkout/Checkout';
import CommentsModal from '../../Components/Modals/Comments/Comments';
import ChangeDataTypeModal from './ChangeDataType/ChangeDataTypeModal';
import ExportDownloadModal from './ExportDownload/ExportDownloadModal';
import RequireAccountModal from './RequireAccount/RequireAccountModal';
import TrimWhitespaceModal from './TrimWhitespace/TrimWhitespaceModal';
import UpgradeAccountModal from './UpgradeAccount/UpgradeAccountModal';
import UpgradeToTeamsModal from './UpgradeAccount/UpgradeToTeamsModal';
import WarningMessageModal from './WarningMessage/WarningMessageModal';
import RequestSentBanner from './DataConnectorsModal/RequestSentBanner';
import DataEnrichment from './Enrichment/DataEnrichment/DataEnrichment';
import CleanupCompanyName from './CleanupCompanyName/CleanupCompanyName';
import CleanupUnixTimeModal from './CleanupUnixTime/CleanupUnixTimeModal';
import CombinedColumnsModal from './CombinedColumns/CombinedColumnsModal';
import IPGeolocationModal from './Enrichment/DataEnrichment/IPGeolocation';
import FindAndReplacePopup from './FindAndReplacePopup/FindAndReplacePopup';
import DeleteDuplicatesModal from './DeleteDuplicates/DeleteDuplicatesModal';
import DeleteColumns from '../../Components/Modals/DeleteColumns/DeleteColumns';
import ExtractDomainNamePopup from './ExtractDomainName/ExtractDomainNamePopup';
import EmailAlexaRankModal from './Enrichment/DataEnrichment/EmailAlexaRankModal';
import CrossFileVLookupModal from './CrossFileVLookup/CrossFileVLookupModal';
import ExceedStorageSizeLimit from './ExceedStorageSizeLimit/ExceedStorageSizeLimit';
import UrlForBiAndSpreadsheets from './UrlForBiAndSpreadsheets/UrlForBiAndSpreadsheets';
import FindAndReplaceConfirmPopup from './FindAndReplacePopup/FindAndReplaceConfirmPopup';
import DeleteDuplicatesConfirmModal from './DeleteDuplicates/DeleteDuplicatesConfirmModal';
import PasteFromContextMenu from '@/Components/Modals/PasteFromContextMenu/PasteFromContextMenu';
import ConfirmLeaveTeamModal from '@/pages/SettingsPage/SettingsPages/ConfirmLeaveTeamModal';
import ZapierFullExperience from './ZapierFullExperience/ZapierFullExperience';
import ImportFromConnectorModal from '@/Components/Modals/ImportFromConnector/ImportFromConnector';
import SuggestIntegrationModal from '@/Components/Modals/SuggestIntegration/SuggestIntegrationModal';
import GeneralUpgradeModal from './GeneralUpgrade/GeneralUpgrade';
import AddView from './SheetViews/AddView';
import DeleteView from './SheetViews/DeleteView';
import CurrencyFormat from './CurrencyFormat/CurrencyFormat';

const InitiateModals = () => {
  const { user } = useSelector((state) => state.userData);
  if (!user) return null;
  return (
    <>
      <AuditLogPopup />
      <AutomationAPI />
      <BusinessPaywall />
      <CalcLengthModal />
      <ChangeCasePopup />
      <ChangeDataTypeModal />
      <CheckoutModal />
      <CleanupCompanyName />
      <CleanupUnixTimeModal />
      <CombinedColumnsModal />
      <CombineFileModal />
      <CommentsModal />
      <ContactSales />
      <CreateAccountModal />
      <CreateFolderModal />
      <CrossFileVLookupModal />
      <CustomEnrichmentModal />
      <DataEnrichment />
      <DecodeBase64Modal />
      <DeleteColumnModal />
      <DeleteColumns />
      <DeleteDuplicatesConfirmModal />
      <DeleteDuplicatesModal />
      <DeleteFileModal />
      <DeleteRowModal />
      <DeleteRowsModal />
      <EditFileModal />
      <EmailAlexaRankModal />
      <EnrichmentModal />
      <ExceedsLimits />
      <ExceedStorageSizeLimit />
      <ExplodeDateModal />
      <ExplodeJsonPopup />
      <ExportDownloadModal />
      <ExportFileModal />
      <ExtractDomainNamePopup />
      <FilterPanel />
      <FindAndReplaceConfirmPopup />
      <FindAndReplacePopup />
      <GroupByPopup />
      <IfCondition />
      <InviteTeamPopup />
      <IPGeolocationModal />
      <IPOsintModal />
      <MakeCopyFileModal />
      <MakeCopyPopup />
      <MoveFileModal />
      <PapercutModal />
      <PasteFromContextMenu />
      <PublishToWebModal />
      <RemoveAccessModal />
      <RenameColumnsPopup />
      <RequestSentBanner />
      <RequireAccountModal />
      <RevenueBase />
      <ShareFileModal />
      <SortPanelPopup />
      <SplitColumnModal />
      <SplitSheetPopup />
      <SuccessCopyPopup />
      <TrimWhitespaceModal />
      <UpgradeAccountModal />
      <UpgradeRequiredModal />
      <UpgradeTeamMemberPopup />
      <UpgradeToTeamsModal />
      <UppyUploaderMulti />
      <UrlForBiAndSpreadsheets />
      <WarningMessageModal />
      <ZapierFullExperience />
      <ConfirmLeaveTeamModal />
      <ImportFromConnectorModal />
      <SuggestIntegrationModal />
      <GeneralUpgradeModal />
      <AddView />
      <DeleteView />
      <CurrencyFormat />
    </>
  );
};

export default InitiateModals;
