export const noteEndpoint = (uuid) => {
  return `dataset/${uuid}/note`;
};

export const libraryEndpoint = (dirHandle) => {
  dirHandle = dirHandle?.length > 0 ? dirHandle : '';
  return `library/${dirHandle}`;
};

export const filePathEndpoint = (fileHandle) => {
  return `library/path/${fileHandle}`;
};

export const sharedWithMeEndpoint = (dirHandle) => {
  dirHandle = dirHandle?.length > 0 ? dirHandle : '';
  return `library/shared-with-me/${dirHandle}`;
};

export const sharedWithMyTeamEndpoint = (dirHandle) => {
  dirHandle = dirHandle?.length > 0 ? dirHandle : '';
  return `library/shared-with-my-team/${dirHandle}`;
};

export const operationStatusEndpoint = (uuid) => {
  return `dataset/${uuid}/operation-status`;
};

export const moveToDirEndpoint = (uuid) => {
  return `file/${uuid}/directory`;
};
