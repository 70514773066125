import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useEffect } from 'react';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';

declare global {
  interface Window {
    FreshworksWidget?: any;
  }
}

type ModalProps = {
  isOpen: boolean;
  title?: string | React.ReactNode;
  subhead?: string;
  iconName?: string;
  modalClassName?: string;
  modalTitleClasses?: string;
  size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  titleSize: 'small' | 'large';
  titlePosition?: 'center' | 'left';
  titleCss?: string;
  onClose: () => void;
  children: React.ReactNode;
  articleID?: string;
  shouldCloseOnBackgroundClick?: boolean;
  shouldCloseOnEscapeKeyDown?: boolean;
  withoutCloseButton?: boolean;
  childrenCss?: string;
  onQuestionMarkButtonClick?: () => void;
  isQuestionMarkButton?: boolean;
  withoutTitle?: boolean;
  dataCy?: string;
};

export default function Modal({
  isOpen = false,
  title,
  subhead,
  iconName,
  modalClassName = '',
  modalTitleClasses,
  size = 'small',
  titleSize = 'large',
  titlePosition = 'left',
  titleCss = '',
  onClose = () => {},
  children,
  articleID,
  shouldCloseOnBackgroundClick = true,
  shouldCloseOnEscapeKeyDown = true,
  withoutCloseButton = false,
  childrenCss = '',
  onQuestionMarkButtonClick = () => {},
  isQuestionMarkButton = false,
  withoutTitle = false,
  dataCy,
}: ModalProps) {
  const openWidgetArticle = () => {
    window.FreshworksWidget('open', 'article', { id: `${articleID}` });
  };

  const modalSize = () => {
    switch (size) {
      case 'x-small':
        return 'max-w-[280px]';
      case 'small':
        return 'max-w-[400px]';
      case 'medium':
        return 'max-w-[640px]';
      case 'large':
        return 'max-w-[800px]';
      case 'x-large':
        return 'max-w-[900px]';
      default:
        return 'max-w-[400px]';
    }
  };

  useEffect(() => {
    addEventListener('keydown', handleKey, false);
    return () => {
      removeEventListener('keydown', handleKey, false);
    };
  }, [isOpen]);

  const handleKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpen) {
      event.preventDefault();
      if (shouldCloseOnEscapeKeyDown) {
        onClose();
      }
    }
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={() => {}} as='div' className='fixed inset-0 z-20 overflow-y-auto'>
        <div className='flex items-center justify-center min-h-screen p-8'>
          <Dialog.Overlay
            onClick={shouldCloseOnBackgroundClick ? onClose : () => {}}
            className='fixed inset-0 bg-black opacity-50'
          />

          <div
            className={clsx(
              'relative w-full mx-auto bg-white rounded-lg my-8',
              modalClassName,
              modalSize()
            )}
            data-cy={dataCy}
          >
            <div
              className={clsx(
                'flex items-center px-4 pt-4 pb-2',
                titlePosition === 'left' ? 'justify-start' : 'justify-center'
              )}
            >
              {withoutTitle ? null : (
                <div className={`pt-1 mt-4 ${modalTitleClasses}`} data-cy='modal-title'>
                  <div
                    className={clsx(
                      'inline-flex items-center text-midnight-600 overflow-hidden break-all',
                      titleSize === 'small' && 'typography-h3',
                      titleSize === 'large' && 'typography-h1',
                      titleCss
                    )}
                  >
                    {iconName && (
                      <Icon
                        name={iconName}
                        size={24}
                        color='#0F085C'
                        className='inline-block mx-1 mb-1'
                      />
                    )}
                    <span>{title}</span>
                  </div>
                  {subhead && (
                    <div className='ml-8 mt-[-5px] typography-subtitle !text-ui-secondary'>
                      {subhead}
                    </div>
                  )}
                </div>
              )}
              <span className='absolute z-10 space-x-1 top-2 right-2'>
                {articleID || isQuestionMarkButton ? (
                  <Button
                    onClick={!isQuestionMarkButton ? openWidgetArticle : onQuestionMarkButtonClick}
                    color='shadow'
                    variant='ghost'
                    iconName='question'
                  />
                ) : null}
                {!withoutCloseButton && (
                  <Button
                    dataCy='modal-x-close-btn'
                    onClick={onClose}
                    color='shadow'
                    variant='ghost'
                    iconName='x'
                  />
                )}
              </span>
            </div>

            <div className={'p-4 ' + childrenCss}>{children}</div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
