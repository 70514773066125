const dateTimeOptions = [
  { label: 'None', value: 'none' },
  { label: 'Row Count', value: 'row count' },
  { label: 'Empty', value: 'empty' },
  { label: 'Filled', value: 'filled' },
  { label: 'Unique', value: 'unique' },
  { label: 'Percent Empty', value: 'percent-empty' },
  { label: 'Percent Filled', value: 'percent-filled' },
  { label: 'Percent Unique', value: 'percent-unique' },
  { label: 'Earliest Date', value: 'min' },
  { label: 'Latest Date', value: 'max' },
  { label: 'Range (Days)', value: 'range' },
  { label: 'Range (Months)', value: 'date-range-months' },
  { label: 'First Value', value: 'first' },
  { label: 'Last Value', value: 'last' },
  { label: 'Mode', value: 'mode' },
];
const numberOptions = [
  { label: 'None', value: 'none' },
  { label: 'Row Count', value: 'row count' },
  { label: 'Sum', value: 'sum' },
  { label: 'Average', value: 'avg' },
  { label: 'Median', value: 'median' },
  { label: 'Min', value: 'min' },
  { label: 'Max', value: 'max' },
  { label: 'Range', value: 'range' },
  { label: 'Empty', value: 'empty' },
  { label: 'Filled', value: 'filled' },
  { label: 'Unique', value: 'unique' },
  { label: 'Percent Empty', value: 'percent-empty' },
  { label: 'Percent Filled', value: 'percent-filled' },
  { label: 'Percent Unique', value: 'percent-unique' },
  { label: 'First Value', value: 'first' },
  { label: 'Last Value', value: 'last' },
  { label: 'Mode', value: 'mode' },
];
const stringOptions = [
  { label: 'None', value: 'none' },
  { label: 'Row Count', value: 'row count' },
  { label: 'Empty', value: 'empty' },
  { label: 'Filled', value: 'filled' },
  { label: 'Unique', value: 'unique' },
  { label: 'Percent Empty', value: 'percent-empty' },
  { label: 'Percent Filled', value: 'percent-filled' },
  { label: 'Percent Unique', value: 'percent-unique' },
  { label: 'First Value', value: 'first' },
  { label: 'Last Value', value: 'last' },
  { label: 'Mode', value: 'mode' },
];

export const getAggregationOptionsByFieldType = (type) => {
  switch (type) {
    case 'Int64':
    case 'UInt64':
    case 'Float64':
      return numberOptions;
    case 'DateTime':
    case 'DateTime64':
      return dateTimeOptions;
    default:
      return stringOptions;
  }
};
