export const getReferrerUrl = (isPublic) => {
  return (
    window.location.protocol +
    '//' +
    (window.location.host.indexOf('www.') !== -1
      ? window.location.host
      : 'www.' + window.location.host) +
    window.location.pathname +
    (isPublic ? '?public=true' : '')
  );
};
