import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import withUploadModal from '@/Components/Modals/withUploadModal';
import { Mixpanel } from '@/Mixpanel';
import { datasetSliceName } from '@/redux/constants';
import { unSelectAllFiles } from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';
import {
  MODAL_COMBINE_FILES,
  MODAL_DELETE_FILE,
  MODAL_MOVE_FILE,
  MODAL_REMOVE_ACCESS,
  userTeamNameKey,
} from '@/Utils/constants';
import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';

import NavigateFilePath from '@/pages/DatasetPage/HeaderControlGroup/NavigateFilePath';

import { Button } from '@/Components/UI/Button/Button';
import { selectNestedFiles } from '@/Utils/selectionOfNestedFiles';
import './index.scss';
import LibraryAutomation from '@/pages/DatasetPage/HeaderControlGroup/LibraryAutomation';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';

const HeaderControlGroup = ({ libraryContent, openUploadModal }) => {
  const dispatch = useDispatch();
  const {
    selectedFiles,
    libraryFiles,
    sharedWithMeFiles,
    currentDataset,
    filePath,
    librarySearchResult,
  } = useSelector((state) => state[datasetSliceName]);
  const { user } = useSelector((state) => state.userData);

  const isSharedWithMeTabActive = currentDataset === 'Shared With Me';
  const isSharedWithMyTeamTabActive = currentDataset === getTeamNameLabel(user[userTeamNameKey]);

  const showMoveFileModal = () => {
    dispatch(
      showModal({
        name: MODAL_MOVE_FILE,
        props: {
          file: selectedFiles?.[0],
          folderName: filePath[filePath?.length - 1]?.FileName || '',
          folderId: filePath[filePath?.length - 1]?.FileUuid || '',
          isMovingSelected: true,
        },
      })
    );
  };

  const disabledMoveFolder = !selectedFiles || !selectedFiles.length;
  const disabledDeleteItem = !selectedFiles || !selectedFiles.length;
  const disabledCombineButton = selectedFiles.some(
    (file) => file.Status === FILE_STATUS_ERROR || file.Type === FILE_TYPE_EXPORTER
  );

  const onClickCombineFilesBtn = () => {
    dispatch(showModal({ name: MODAL_COMBINE_FILES }));
    Mixpanel.track('Combine Files Clicked');
  };

  const accessCanBeRemoved = (row) => {
    return !user?.is_anonymous && row?.permissions?.length > 0;
  };

  const onDeleteButtonClick = () => {
    // if we are deleting files shared with me, just opt-out on all checked files
    if (isSharedWithMeTabActive) {
      return dispatch(showModal({ name: MODAL_REMOVE_ACCESS, props: { isMultiple: true } }));
    }

    dispatch(
      showModal({
        name: MODAL_DELETE_FILE,
        props: {
          filesToDelete: selectedFiles,
        },
      })
    );
  };

  const selectAllChange = () => {
    if (selectedFiles.length) {
      return dispatch(unSelectAllFiles());
    }
    (isSharedWithMeTabActive ? sharedWithMeFiles : libraryFiles).forEach((file) =>
      selectNestedFiles(file.metadata)
    );
  };

  return (
    <div className={'datasets-header__control !static !md:sticky'}>
      <div className='w-full'>
        <div className='my-2.5'>
          <NavigateFilePath />
          {!isSharedWithMeTabActive && !isSharedWithMyTeamTabActive && (
            <LibraryAutomation openUploadModal={openUploadModal} />
          )}
        </div>
      </div>

      {libraryContent?.length && !isSharedWithMyTeamTabActive && !librarySearchResult ? (
        <div className='items-center hidden w-full px-4 py-2 border rounded-t md:flex bg-ui-secondary border-ui-200'>
          <div className='datasets-header__input-container'>
            <input
              data-cy='generalCheckbox'
              type='checkbox'
              className='ml-1 file-checkbox'
              disabled={
                isSharedWithMeTabActive &&
                sharedWithMeFiles.filter((f) => accessCanBeRemoved(f))?.length === 0
              }
              onChange={selectAllChange}
              ref={(checkbox) => {
                if (!checkbox) return;
                checkbox.indeterminate =
                  selectedFiles.length &&
                  checkbox &&
                  selectedFiles.length <
                    (isSharedWithMeTabActive ? sharedWithMeFiles : libraryFiles).length;
              }}
              checked={selectedFiles.length}
            />
            {selectedFiles.length > 0 && (
              <p className='text-ocean-blue ml-2.5 text-xs'>
                <span data-cy='library-selected-item-count'>{selectedFiles.length}</span> item(s)
                selected
              </p>
            )}
          </div>

          {!isSharedWithMeTabActive && (
            <Button
              dataCy='combine-btn'
              className={clsx(disabledCombineButton ? 'pointer-events-none' : '')}
              disabled={disabledCombineButton}
              onClick={onClickCombineFilesBtn}
              iconName='arrows-in-line-horizontal'
              color='shadow'
              variant='ghost'
            >
              Combine
            </Button>
          )}

          {!isSharedWithMeTabActive && (
            <Button
              dataCy='move-btn'
              disabled={disabledMoveFolder}
              className={clsx('ml-2', disabledMoveFolder ? 'pointer-events-none' : '')}
              onClick={showMoveFileModal}
              color='shadow'
              variant='ghost'
              iconName='arrows-out-cardinal'
            >
              Move File
            </Button>
          )}

          <Button
            dataCy='deleteItemBtn'
            className={clsx('ml-2', disabledDeleteItem ? 'pointer-events-none' : '')}
            disabled={disabledDeleteItem}
            onClick={onDeleteButtonClick}
            iconName='trash'
            color='shadow'
            variant='ghost'
          >
            {isSharedWithMeTabActive ? 'Remove Access' : 'Delete'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default withUploadModal(HeaderControlGroup);
