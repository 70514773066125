import withModal from '../withModalHOC';
import {
  GIGASHEET_API_SUPPORT,
  LINK_GENERATE_API_KEY,
  MODAL_AUTOMATION_API,
  MODAL_ZAPIER_FULL_EXPERIENCE,
  userRolesKey,
} from '@/Utils/constants';
import Modal from '@/Components/UI/Modal/Modal';
import { Button, Link } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import { ReactComponent as ZapierIcon } from './zapier.svg';
import { ReactComponent as GigasheetLogo } from '@/Assets/icons/logo.svg';
import AlertMessage from '@/Components/UI/AlertMessage/AlertMesage';
import UpgradeButton from '../UpgradeAccount/UpgradeButton';
import { useSelector, useDispatch } from 'react-redux';
import { userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';

const onQuestionMarkButtonClick = () => {
  window.open(GIGASHEET_API_SUPPORT, '_blank', 'noopener,noreferrer');
};

const onGenerateAPIKeyButtonClick = () => {
  window.open(LINK_GENERATE_API_KEY, '_self', 'noopener,noreferrer');
};

type AutomationAPIProps = { show: boolean; hideModal: () => void };

const AutomationAPI = ({ show, hideModal }: AutomationAPIProps) => {
  const { user } = useSelector((state: any) => state[userDataSliceName]);
  const dispatch = useDispatch();

  const onZapierButtonClick = () => {
    dispatch(showModal({ name: MODAL_ZAPIER_FULL_EXPERIENCE }));
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='large'
      titlePosition='center'
      size='small'
      title='Automation API'
      onQuestionMarkButtonClick={onQuestionMarkButtonClick}
      isQuestionMarkButton
    >
      <div data-cy='automation-api-modal'>
        <p className='mb-6 typography-body'>
          Automate anything in Gigasheet. Check out our{' '}
          <Link
            to='https://gigasheet.readme.io/reference/intro/getting-started'
            color='capri'
            size='medium'
            target='_blank'
            dataCy='api-docs-link'
          >
            API docs
          </Link>{' '}
          or for no-code automation use API keys with{' '}
          <Link
            to='https://zapier.com/apps/gigasheet/integrations'
            color='capri'
            size='medium'
            target='_blank'
            dataCy='zapier-link'
          >
            Zapier
          </Link>
          .
        </p>

        {!user[userRolesKey].includes('giga-feature-api-key') ? (
          <UpgradeButton text='Generate API Key' className='w-full mb-6' dataCy='upgrade-button' />
        ) : (
          <Button
            color='sunrise'
            className='w-full mb-6'
            onClick={onGenerateAPIKeyButtonClick}
            dataCy='generate-api-token-button'
          >
            Generate API Key
          </Button>
        )}

        <div className='flex flex-row items-center justify-center mb-6 space-x-4'>
          <div className='flex items-center justify-center rounded-xl border border-ui-200 h-[72px] w-[72px]'>
            <GigasheetLogo height={40} width={40} />
          </div>

          <div className='flex items-center justify-center'>
            <Icon name='arrow-right' size={24} color='#151515' />
          </div>

          <div className='flex items-center justify-center rounded-xl border border-ui-200 h-[72px] w-[72px]'>
            <ZapierIcon height={40} width={40} />
          </div>
        </div>
        <Button
          color='shadow'
          variant='outline'
          className='w-full mb-6'
          dataCy='set-up-your-zap-button'
          onClick={onZapierButtonClick}
        >
          Set Up Your Zap
        </Button>
        <AlertMessage iconName='info'>
          <div className='text-[14px] font-normal'>
            Unsure of how to get started?{' '}
            <Link
              to='https://calendly.com/steve-schohn/gigasheet-automation'
              color='capri'
              size='medium'
              target='_blank'
              dataCy='book-a-time-link'
            >
              Book a time
            </Link>{' '}
            and let us configure your automation for you.
          </div>
        </AlertMessage>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_AUTOMATION_API, destroyOnHide: true })(AutomationAPI);
