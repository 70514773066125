import withModal from '@/Components/Modals/withModalHOC';
import { MODAL_PASTE_FROM_CONTEXT_MENU } from '@/Utils/constants';
import Modal from '@/Components/UI/Modal/Modal';
import Icon from '@/Components/UI/Icon/Icon';
import React from 'react';

const PasteFromContextMenu = ({ show, hideModal }) => {
  const close = () => {
    hideModal();
  };

  const isMacOs = navigator.platform.toLowerCase().includes('mac');

  return (
    <Modal
      title='Pasting in Gigasheet'
      isOpen={show}
      size='medium'
      onClose={close}
      titleSize='small'
    >
      <div className='mb-8'>
        This action is unavailable using the context menu, but you can still use:
      </div>
      <div className='flex justify-center text-7xl leading-[107px]'>
        {isMacOs && <Icon name='command' size='91' />}
        <div>{isMacOs ? 'V' : 'Ctrl+V'}</div>
      </div>
      <div className='flex justify-center mb-8'>for paste</div>
    </Modal>
  );
};

export default withModal({ name: MODAL_PASTE_FROM_CONTEXT_MENU })(PasteFromContextMenu);
