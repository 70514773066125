import FunctionSelect from '../../../Components/Spreadsheet/AggregationFunctions/FunctionSelect';

import './style/index.scss';
import { ROW_NUMBER_COLUMN_ID, ROW_NUMBER_COLUMN_NAME } from '@/Utils/gridConstants';

const AggregationFunctions = ({ agGrid, column }) => {
  const isFirstColumn =
    column.colDef.field === ROW_NUMBER_COLUMN_ID &&
    column.colDef.headerName === ROW_NUMBER_COLUMN_NAME;
  return (
    <>
      {!isFirstColumn && (
        <>
          <FunctionSelect column={column} agGrid={agGrid} />
        </>
      )}
    </>
  );
};

export default AggregationFunctions;
