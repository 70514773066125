import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '@/Components/UI/Modal/Modal';
import IfConditionsBody from './IfConditionsBody';
import { Button } from '@/Components/UI/Button/Button';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';

import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  CONDITION_LABEL_COLUMN,
  MODAL_IF_CONDITIONS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const IfConditionsModal = ({ hideModal, show }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();

  const [conditionalLabelColumn, setConditionalLabelColumn] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isValidConditionForInserting, setIsValidConditionForInserting] = useState(null);

  const insertNewColumn = async () => {
    const uuid = getFileUuidFromPath();
    dispatch(hideAllModals());
    dispatch(setIsInsertingNewColumn(true));

    try {
      const endpoint = `${URL_DATASET}/${uuid}/${CONDITION_LABEL_COLUMN}`;
      const result = await post(endpoint, conditionalLabelColumn, true);
      if (!result.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
          errorContext: result,
          endpoint: endpoint,
          payload: conditionalLabelColumn,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
      await dispatch(fetchOpenedFile(uuid));
      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'If function' },
          userIncrementName: '# of function use',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isValidConditionForInserting) {
      insertNewColumn();
      setIsValidConditionForInserting(null);
    } else {
      setIsFetching(false);
    }
  }, [isValidConditionForInserting, isFetching]);

  const onInsertButtonClick = () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      hideModal();
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    setIsFetching(true);
  };

  const closeModal = () => {
    hideModal();
  };

  return (
    <Modal
      title='If'
      iconName='list-checks'
      size='x-large'
      isOpen={show}
      onClose={closeModal}
      articleID={69000836451}
    >
      <>
        <div className='relative h-8 mt-1 ml-8 text-base font-semibold leading-5 text-ui-secondary'>
          <span className='absolute top-[-30px]'>
            Values matching the conditions will be inserted as a new column.
          </span>
        </div>
        <IfConditionsBody
          setConditionalLabelColumn={setConditionalLabelColumn}
          setIsValidConditionForInserting={setIsValidConditionForInserting}
          isFetching={isFetching}
        />
      </>
      <div className='p-4 mt-6 text-right border-t border-ui-200'>
        <Button variant='ghost' color='shadow' dataCy='cancel-btn' onClick={closeModal}>
          Cancel
        </Button>
        <Button
          color={'oceanBlue'}
          dataCy='save-btn'
          onClick={onInsertButtonClick}
          disabled={isFetching}
        >
          Insert
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_IF_CONDITIONS })(IfConditionsModal);
