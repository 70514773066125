import mixpanel from 'mixpanel-browser';

import { userDataSliceName } from './redux/constants';
import { store } from './redux/store';

// Exposing window.mixpanel for UG to use.
window.mixpanel = mixpanel;

mixpanel.init('ada79c86ae2d13a966ae9b16289c6810', {
  api_host: 'https://api.gigasheet.com/mixpanel',
  api_payload_format: 'json',
});

const url_check = window.location.host === 'app.gigasheet.com';

const actions = {
  identify: (id) => {
    if (url_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (url_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (url_check) {
      const { user } = store.getState()[userDataSliceName];
      const deviceInfo = navigator.platform;
      mixpanel.track(name, {
        ...props,
        'Device Info': deviceInfo,
        user_name: user.email,
        distinct_id: user.email,
      });
    }
  },
  register: (props) => {
    if (url_check) mixpanel.register(props);
  },
  people: {
    set: (props) => {
      if (url_check) mixpanel.people.set(props);
    },
    increment: (property, amount) => {
      if (url_check) mixpanel.people.increment(property, amount);
    },
  },
};

export const Mixpanel = actions;
