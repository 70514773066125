import { useSelector } from 'react-redux';

import withModal from '../withModalHOC';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

import { MODAL_EXCEED_STORAGE_SIZE_LIMIT } from '@/Utils/constants';
import { userDataSliceName } from '@/redux/constants';
import UpgradeButton from '../UpgradeAccount/UpgradeButton';

const ExceedStorageSizeLimit = ({ show, hideModal }) => {
  const { user } = useSelector((state) => state[userDataSliceName]);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='small'
      size='small'
      title='Upgrade Required'
      titleCss='!text-2xl'
    >
      <div className='text-sm'>
        <p className='mb-4 font-bold text-black'>
          {`Files cannot be combined as your account’s storage limits of ${user?.userProperties?.maxStorageSize} have been exceeded.`}
        </p>
        <p className=''>
          To combine files upgrade to an account with more storage or delete some files from your
          Library.
        </p>
      </div>
      <div className='flex justify-end mt-14'>
        <Button onClick={hideModal} color='none'>
          Close
        </Button>
        <UpgradeButton text='Upgrade Account' color='pear' />
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_EXCEED_STORAGE_SIZE_LIMIT, destroyOnHide: true })(
  ExceedStorageSizeLimit
);
