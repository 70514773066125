import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import { Button } from '@/Components/UI/Button/Button';
import { CHARGEBEE_SITE_PROD, CHARGEBEE_SITE_TEST, MODAL_INVITE_TEAM } from '@/Utils/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import UserSettings from '../pages/SettingsPage/SettingsPages/AccountSettings';
import SubscriptionAndUsageSettings from '../pages/SettingsPage/SettingsPages/SubscriptionAndUsageSettings';
import TeamSettings from '../pages/SettingsPage/SettingsPages/TeamSettings';
import LinkToClickhouse from '@/pages/SettingsPage/SettingsPages/LinkToClickhouse';
import { getBaseUrl } from '@/Utils/utils';

export const pageByIndex = (index) => {
  switch (index) {
    case 0:
      return <UserSettings />;
    case 1:
      return <TeamSettings />;
    case 2:
      return <SubscriptionAndUsageSettings />;
    case 3:
      return <LinkToClickhouse />;
    default:
      return <UserSettings />;
  }
};

export const InvitePeopleButton = ({ styles }) => {
  const dispatch = useDispatch();
  return (
    <Button onClick={() => dispatch(showModal({ name: MODAL_INVITE_TEAM }))} {...styles}>
      Invite People
    </Button>
  );
};

export const navByIndex = {
  0: {
    title: 'Account',
    actionName: '',
    button: null,
  },
  1: {
    title: 'Team',
    actionName: 'Invite your team',
    button: <InvitePeopleButton styles={{ color: 'sunrise' }} />,
  },
  2: {
    title: 'Subscription & Usage',
    actionName: 'Upgrade',
    action: () => {},
    button: <UpgradeButton />,
  },
  3: {
    title: 'Link to Clickhouse',
    actionName: '',
    action: () => {},
    button: null,
  },
};

export const settingsHeader = (header) => {
  const { title, button } = header;
  return (
    <div className='w-full max-w-6xl px-16'>
      <div className='flex flex-row justify-between w-full h-20 py-6 my-12 border-b border-shadow-200'>
        <div className='text-xl font-extrabold text-ui-secondary text-[21px]'>{title}</div>
        {button}
      </div>
    </div>
  );
};

const getChargebeeSite = () => {
  const baseUrl = getBaseUrl('api');
  if (
    ['dev.gigasheet.com', 'localhost', 'dev.supergiga.co'].some((hostname) =>
      baseUrl.includes(hostname)
    )
  ) {
    return CHARGEBEE_SITE_TEST;
  } else {
    return CHARGEBEE_SITE_PROD;
  }
};

export const manageBilling = () => {
  const cbInstance = window.Chargebee?.init({
    site: getChargebeeSite(),
    isItemsModel: true,
  });
  const cbPortal = cbInstance.createChargebeePortal();
  cbPortal.open();
};

export const subscriptionPrices = {
  '0.5GB': 0,
  '1GB': 0,
  '10GB': 95,
  '25GB': 165,
  '50GB': 325,
  '100GB': 495,
  '150GB': 750,
  '200GB': 950,
};

export const StoragePercentageBar = ({
  usagePercentage,
  label,
  limitLabel = '',
  usedLabel = '',
  used = 0,
  limit = 0,
}) => {
  const storageUsageColor = () => {
    if (usagePercentage < 50) {
      return 'bg-capri-500';
    } else if (usagePercentage >= 50 && usagePercentage < 80) {
      return 'bg-carrot-500';
    } else {
      return 'bg-violet-web-500';
    }
  };

  const roundToTwoDecimals = (num) => {
    try {
      return parseFloat(num.toFixed(2));
    } catch (error) {
      return 0;
    }
  };

  return (
    <>
      <div className='flex flex-row justify-between text-sm font-semibold'>
        <div>{label}</div>
        <div className='text-helper'>{usagePercentage}%</div>
      </div>
      <div className='w-full rounded-full h-2.5 bg-shadow-100'>
        <div
          className={clsx('h-2.5 rounded-full', storageUsageColor())}
          style={{ width: `${usagePercentage}%`, maxWidth: '100%', minWidth: '2%' }}
        ></div>
      </div>

      <div className='text-[13px]'>
        {roundToTwoDecimals(used).toLocaleString()}
        {usedLabel !== '' && <span className='px-1'>{usedLabel}</span>}
        <span className='px-1'>/</span>
        <span className='text-ui-helper'>{roundToTwoDecimals(limit).toLocaleString()}</span>
        {limitLabel !== '' && <span className='text-ui-helper px-1'>{limitLabel}</span>}
      </div>
    </>
  );
};
