export const userDataSliceName = 'userData';
export const datasetSliceName = 'dataset';
export const modalsSliceName = 'modal';
export const sharedSliceName = 'shared';
export const spreadsheetSliceName = 'spreadsheet';
export const authenticationSliceName = 'authentication';
export const enrichmentTemplatesSliceName = 'enrichmentTemplates';
export const settingsSliceName = 'settings';
export const combineFilesSliceName = 'combineFiles';
export const buildSliceName = 'build';
