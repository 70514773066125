import clsx from 'clsx';
import React, { ChangeEvent, forwardRef } from 'react';

type Props = {
  label?: string;
  name?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  dataCy?: string;
  className?: string;
  disabled?: boolean;
  labelClassName?: string;
};

const CheckboxInput = forwardRef(
  (
    { label, checked, name, onChange, dataCy, className, disabled, labelClassName = '' }: Props,
    ref?: React.Ref<HTMLInputElement>
  ) => {
    return (
      <>
        <div className={clsx(label && 'flex items-start', !label && 'inline-flex')}>
          <div className='flex items-center h-5'>
            <label className={clsx('flex items-center text-sm', label && 'px-1', labelClassName)}>
              <input
                type='checkbox'
                data-cy={dataCy}
                className={clsx(
                  'w-4 h-4 rounded border-ui-300 accent-midnight focus:ring-midnight',
                  className
                )}
                name={name}
                checked={checked}
                onChange={onChange}
                ref={ref}
                disabled={disabled}
              />
              {label && (
                <span
                  data-cy={`${dataCy}-label-text`}
                  className='ml-2 font-normal text-inherit text-ui'
                >
                  {label}
                </span>
              )}
            </label>
          </div>
        </div>
      </>
    );
  }
);

export default CheckboxInput;
