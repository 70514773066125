import clsx from 'clsx';
import { ChangeEvent, forwardRef } from 'react';
import InputField from '../InputField/InputField';

type Props = {
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  max?: string;
  min?: string;
  placeholder?: string;
  helperText?: string;
  color?: 'shadow' | 'oceanBlue' | 'violetWeb' | 'pear';
  size?: 'small' | 'large';
  disabled?: boolean;
  dataCy?: string;
  className?: string;
  helperTextLowercase?: boolean;
};

const colors = {
  shadow: 'border-shadow-200 text-secondary !shadow-none placeholder-helper font-normal',
  oceanBlue: 'border-ocean-blue-500 shadow-ocean-blue-100 text-ui placeholder-ui font-normal',
  violetWeb: 'border-violet-web-500 shadow-violet-web-100 text-ui placeholder-ui font-normal',
  pear: 'border-pear-500 shadow-pear-100 text-ui placeholder-ui font-normal',
};

const accessibilityColors = 'focus:outline-none';

const TextInput = forwardRef(
  (
    {
      label,
      value,
      onChange,
      name,
      max = '',
      min = '',
      placeholder,
      helperText,
      color = 'shadow',
      size = 'large',
      disabled = false,
      dataCy,
      className,
      helperTextLowercase,
    }: Props,
    ref?: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div>
        <InputField
          label={label}
          helperText={helperText}
          helperTextPosition='bottom'
          helperTextLowercase={helperTextLowercase}
          color={color}
          disabled={disabled}
        >
          <input
            ref={ref}
            type='number'
            max={max}
            min={min}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            data-cy={dataCy}
            className={clsx(
              'border flex items-center bg-white pr-6 pl-3 rounded disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200',
              size === 'large' ? 'shadow-spread h-8 text-sm' : 'h-6 text-[13px] leading-[18px]',
              accessibilityColors,
              colors[disabled ? 'shadow' : color],
              className
            )}
          />
        </InputField>
      </div>
    );
  }
);

export default TextInput;
