import { Suspense, lazy, useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import Icon from '../../../Components/UI/Icon/Icon';
import { Select } from '../../../Components/UI/Form/Select/Select';
import ShowToast from '../../../Components/Toast/showToastTemplate';
import Textarea from '../../../Components/UI/Form/Textarea/Textarea';
import HelperText from '../../../Components/UI/HelperText/HelperText';

import { put } from '../../../Utils/API';
import { getTypeIconPath } from '../../../Utils/getTypeIconPath';
import { parseRowCount } from '../../../Utils/DatasetTree/parseRowCount';
import {
  TOAST_TEXT_SAVE_NOTE_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '../../../Utils/constants';
import { FILE_NOTE_CHARACTER_LIMIT, FILE_NOTE_EXCEEDS_LIMIT } from '../../../Utils/fileConstants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';

import { fetchOpenedFile } from '../../../redux/reducers/datasetSlice';

const Bar = lazy(() =>
  import('react-chartjs-2').then((module) => ({
    default: module.Bar,
  }))
);

const registerChartJS = async () => {
  const { CategoryScale, LinearScale, BarElement, Chart: ChartJS } = await import('chart.js');
  ChartJS.register(CategoryScale, LinearScale, BarElement);
};

const SheetSummary = ({
  fileName = '',
  fileDescription = '',
  isReadOnly,
  fileSize,
  fileRows,
  columnCount,
  columnsByType,
  columnSummary,
  viewTypeOptions = [],
  getSummaryLabel = () => {},
  getTableLabel = () => {},
  isEstimate = true,
}) => {
  const dispatch = useDispatch();
  const formmatedViewTypeOptions = viewTypeOptions.map((option) => {
    return { label: option.label, value: option.id + '' };
  });
  const [viewType, setViewType] = useState(formmatedViewTypeOptions[0]);
  const [viewTypeValue, setViewTypeValue] = useState(formmatedViewTypeOptions[0].value);

  const [editedFileDescription, setEditedFileDescription] = useState(fileDescription);

  const updateDescription = async () => {
    const endpoint = `${URL_DATASET}/${getFileUuidFromPath()}/note`;
    const payload = {
      note: editedFileDescription,
    };
    const res = await put(endpoint, payload);

    if (res?.Success) {
      dispatch(fetchOpenedFile(getFileUuidFromPath()));
    } else {
      setEditedFileDescription(fileDescription);
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_SAVE_NOTE_ERROR,
        errorContext: res,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const handleDescriptionChange = () => {
    if (fileDescription !== editedFileDescription) updateDescription();
  };

  useEffect(() => {
    const viewType = formmatedViewTypeOptions.find((option) => option.value === viewTypeValue);
    setViewType(viewType);
  }, [viewTypeValue]);

  useEffect(() => {
    registerChartJS();
  }, []);

  const renderChart = () => {
    return (
      <Suspense fallback={<div>Loading Chart...</div>}>
        <div data-cy='sheet-stats-chart'>
          <Bar
            options={{
              indexAxis: 'y',
              elements: {
                bar: {
                  borderRadius: 4,
                },
              },
              plugins: {
                legend: {
                  position: 'right',
                },
              },
            }}
            data={{
              labels: Object.entries(columnsByType)?.map(([key]) => key),
              datasets: [
                {
                  data: Object.entries(columnsByType)?.map(([, value]) => value),
                  backgroundColor: '#C400E1',
                },
              ],
            }}
          />
        </div>
      </Suspense>
    );
  };
  const renderTable = () => {
    return (
      <div data-cy='sheet-stats-table'>
        {Object.entries(columnsByType).map(([key = '', value], i) => (
          <div
            className={clsx(
              'flex flex-row justify-between text-[13px] leading-7',
              i !== Object.entries(columnsByType).length - 1 && 'border-b'
            )}
            key={key}
          >
            <div className='font-semibold text-ui-secondary'>{getSummaryLabel(key)}</div>
            <div className='text-ui-helper'>{JSON.stringify(value)}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='flex flex-col gap-6 m-4'>
      <div data-cy='description-container' className='w-full'>
        <div className='px-3 py-2 text-[13px] leading-5 !border-b-0 border rounded-t-md border-shadow-200 bg-ui-secondary'>
          <div data-cy='file-name' className='font-semibold truncate text-ui max-w-[275px]'>
            {fileName}
          </div>
          <div className='text-ui-secondary'>
            <span data-cy='file-rows'>{fileRows} </span>rows,{' '}
            <span data-cy='file-columns'>{columnCount}</span> cols,{' '}
            <span data-cy='file-size'>{fileSize}</span>
          </div>
          <div data-cy='desc-header-text' className='pt-4 text-[13px] font-semibold'>
            Description
          </div>
        </div>

        <div onBlur={handleDescriptionChange}>
          <Textarea
            className='!text-ui-secondary text-[13px] w-full min-h-[100px] !max-h-[175px] border !rounded-t-none rounded-b-md !focus:border-none px-3 py-2 '
            color='shadow'
            value={editedFileDescription}
            maxLength={500}
            helperText={
              editedFileDescription?.length > FILE_NOTE_CHARACTER_LIMIT && FILE_NOTE_EXCEEDS_LIMIT
            }
            dataCy='file-note-input'
            onChange={(e) => setEditedFileDescription(e.target.value)}
            readonly={isReadOnly}
            disabled={editedFileDescription?.length > FILE_NOTE_CHARACTER_LIMIT}
            placeholder={'Description...'}
          />
        </div>
      </div>

      <div className='border rounded-md border-shadow-200 bg-ui border-md'>
        <div className='px-3 py-2 min-h-[200px] flex flex-col justify-between'>
          <div className='flex flex-row items-center justify-between w-full'>
            <div data-cy='data-by-type' className='text-[13px] font-semibold'>
              Data By Type
            </div>

            <div className='border rounded border-ui w-[78px]'>
              <Select
                dataCy='view-type'
                size='small'
                onChange={setViewTypeValue}
                value={viewTypeValue}
                options={formmatedViewTypeOptions}
              />
            </div>
          </div>

          <div className='w-full'>{viewType.label === 'Chart' ? renderChart() : renderTable()}</div>
        </div>
      </div>

      <div className='overflow-scroll'>
        <div className={clsx(columnCount === 0 && 'hidden')}>
          <div data-cy='column-summary-header' className='text-base font-semibold text-ui'>
            Column Summary
          </div>
          {columnCount > 20 ? (
            <div className='text-[13px] text-ui-secondary'>
              Basic statistics limited to first 20 columns
            </div>
          ) : null}
        </div>

        {columnSummary.map(({ columnName = '', stats = {} }, i) => {
          return (
            <div
              data-cy='all-headers'
              className='my-2 border rounded-md border-ui border-sm'
              key={columnName + i}
            >
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      data-cy={`open-summary-by-name-${columnName}`}
                      className='flex items-center justify-between w-full p-2 font-semibold rounded-md bg-ui'
                    >
                      <div className='flex gap-2'>
                        <Icon name={getTypeIconPath()} size='18' />
                        <div className='max-w-[250px] truncate'>{columnName}</div>
                      </div>

                      <Icon
                        className=''
                        name={open ? 'caret-up' : 'caret-down-regular'}
                        size={12}
                      />
                    </Disclosure.Button>

                    <Disclosure.Panel className='px-3 py-2 text-gray-500 border-t '>
                      {Object.entries(stats)
                        ?.filter(([key]) => key !== 'ColumnHeader')
                        .filter(([key]) =>
                          isEstimate ? key !== 'UniqueCount' : key !== 'EstimatedUniqueCount'
                        )
                        .filter(([key]) => getSummaryLabel(key) !== '')
                        .map(([key, value]) => (
                          <div
                            className='flex flex-row justify-between text-[13px] leading-7'
                            key={key}
                          >
                            <div
                              data-cy={`summary-label-${columnName}`}
                              className='font-semibold text-ui-secondary'
                            >
                              {getSummaryLabel(key)}
                            </div>
                            <div data-cy={`summary-value-${columnName}`} className='text-ui-helper'>
                              {key === 'ColumnType'
                                ? getTableLabel(value)
                                : key === 'ColumnHeader'
                                ? value
                                : parseRowCount(value)}
                            </div>
                          </div>
                        ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          );
        })}
      </div>
      <div>
        {isEstimate && columnCount !== 0 ? (
          <div className='flex pt-2'>
            <Icon name='info' size={14} color='#0079B6' />
            <HelperText
              className='font-semibold leading-3 uppercase text-capri-900'
              text='Some responses may be estimated due to the size of the sheet.'
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SheetSummary;
