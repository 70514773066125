import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getIndexForExpandedNode } from '../../helpers/spreadsheetHelper';
import { put } from '../../Utils/API';
import { getFileUuidFromPath } from '../../Utils/getFileUuidFromPath';
import { datasetSliceName, spreadsheetSliceName, userDataSliceName } from '../constants';

import { fetchOpenedFile } from './datasetSlice';

import { CLIENT_STATE, URL_DATASET } from '../../Utils/constants';
import { isReadOnly } from '../../Utils/sharing';
import { ROW_NUMBER_COLUMN_NAME } from '@/Utils/gridConstants';

export const initialState = {
  clientState: {
    sortModel: [],
    columnState: [],
    filterModel: null,
    alwaysConfirm: true,
    alwaysConfirmChangeCase: true,
    alwaysConfirmDeleteRow: true,
    isPivotEnabled: false,
    expandedNodes: [],
    groupedNodeState: {},
    reduxSavedFilterHandle: '',
    columnHighlights: null,
  },
  selectedRow: null,
  columnDefs: [],
  pageNumber: 0,
  totalNumberOfRows: 0,
  filteredNumberOfRows: 0,
  isRequestingRows: null,
  isDeletingRows: false,
  isDeletingColumns: false,
  userClosedUpgradeRequiredModal: false,
  isInsertingNewColumn: false,
  toasts: [],
  isOpenFileNameMenu: false,
  defaultColDef: {
    minWidth: 140,
    sortable: true,
    resizable: true,
    filter: true,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    lockPinned: true,
    wrapText: false,
    autoHeight: false,
  },
  isGridStateRestored: false,
  errorInfo: {
    error: false,
    errorMessage: '',
  },
  autocompleteColumns: {},
  paginationPageSize: 100,
  newColumnMode: 'formula',
  formulaToEdit: null,
  newColumnName: '',
  gridToolPanelName: '',
  gridToolPanelNameToggle: false,
  isMobileSheetAssistantOpen: false,
  runDeletingRows: false,
  mobileDrawerContent: '',
  isMobileExperienceModalOpen: false,
  refreshCommentThreads: false,
  aggregationEndpointValues: [],
  isAggregationEndpointValuesLoading: false,
  fileStats: null,
  insertNewColumnNextTo: null,
  resetFormulaForm: false,
  commentCell: null,
  isCustomColumnHeaderContextMenuOpen: false,
  customColumnHeaderContextMenuPosition: null,
  customContextMenuColumn: null,
  isFiltersRuleORSet: false,
  isFiltersMultipleRulesSet: false,
  appliedFiltersMap: {},
  columnHighlights: {},
  enrichmentCredits: { Limit: 0, Used: 0 },
  sheetViews: [],
  renamePopoverCoordinates: { top: 0, left: 0 },
  isRenamePopoverOpen: false,
  renameColumnData: null,
};

export const prepareClientState = (rawClientState) => {
  // basically replace all undefined props with initial one's
  const clientState = Object.keys(rawClientState).reduce((acc, key) => {
    if (rawClientState[key] === undefined) {
      acc[key] = initialState.clientState[key];
      return acc;
    }
    acc[key] = rawClientState[key];
    return acc;
  }, {});
  return clientState;
};

const restoreRules = {
  filterModel: ({ filterModel }) => {
    if (!filterModel || Object.keys(filterModel).length === 0)
      return initialState.clientState.filterModel;
    return filterModel;
  },
  sortModel: ({ sortModel }) => {
    if (!sortModel) return initialState.clientState.sortModel;
    return sortModel.map((singleSortModel) => {
      if (typeof singleSortModel !== 'string') return singleSortModel;
      return {
        colId: singleSortModel.split('-')[0],
        sort: singleSortModel.split('-')[1],
      };
    });
  },
};

let previousClientStateString = {};

export const saveClientState = createAsyncThunk(
  `${spreadsheetSliceName}/saveClientState`,
  async (clientStatePayload, thunkApi) => {
    const state = thunkApi.getState();

    const { user } = state[userDataSliceName];
    if (user?.is_anonymous) return;

    const { clientState } = state[spreadsheetSliceName];
    const { currentFile } = state[datasetSliceName];
    if (!currentFile) return;

    const clientStateToSave = prepareClientState({ ...clientState, ...clientStatePayload });

    if (isReadOnly(currentFile)) return;

    if (JSON.stringify(clientStateToSave) !== JSON.stringify(previousClientStateString)) {
      previousClientStateString = clientStateToSave;
      put(`${URL_DATASET}/${currentFile.metadata.FileUuid}/${CLIENT_STATE}`, {
        state: clientStateToSave,
      });
    }
  }
);

export const clearClientState = () =>
  put(`${URL_DATASET}/${getFileUuidFromPath()}/${CLIENT_STATE}`, {
    state: initialState.clientState,
  });

export const getAggFunctionsByColumnType = (colType) => {
  const defaultFunc = [
    'row count',
    'empty',
    'filled',
    'unique',
    'percent-empty',
    'percent-filled',
    'percent-unique',
    'mode',
  ];
  switch (colType) {
    case 'Int64':
    case 'UInt64':
    case 'Float64':
      return [...defaultFunc, 'min', 'max', 'sum', 'avg', 'median', 'range'];
    case 'DateTime':
    case 'DateTime64':
      return [...defaultFunc, 'min', 'max', 'range', 'date-range-months'];
    default:
      return defaultFunc;
  }
};

const spreadsheetSlice = createSlice({
  name: spreadsheetSliceName,
  initialState,
  reducers: {
    setColumnDefs(state, action) {
      state.columnDefs = action.payload;
    },
    setRenamePopoverCoordinates(state, action) {
      state.renamePopoverCoordinates = action.payload;
    },
    setIsRenamePopoverOpen(state, action) {
      state.isRenamePopoverOpen = action.payload;
    },
    setRenameColumnData(state, action) {
      state.renameColumnData = action.payload;
    },
    setAppliedFiltersMap(state, action) {
      state.appliedFiltersMap = action.payload;
    },
    setIsCustomColumnHeaderContextMenuOpen(state, action) {
      state.isCustomColumnHeaderContextMenuOpen = action.payload;
    },
    setCustomColumnHeaderContextMenuPosition(state, action) {
      state.customColumnHeaderContextMenuPosition = action.payload;
    },
    setCustomContextMenuColumn(state, action) {
      state.customContextMenuColumn = action.payload;
    },
    setIsFiltersRuleORSet(state, action) {
      state.isFiltersRuleORSet = action.payload;
    },
    setIsFiltersMultipleRulesSet(state, action) {
      state.isFiltersMultipleRulesSet = action.payload;
    },
    setFileStats(state, action) {
      state.fileStats = { ...action.payload };
    },
    setAggregationEndpointValues(state, action) {
      state.aggregationEndpointValues = action.payload;
    },
    setIsAggregationEndpointValuesLoading(state, action) {
      state.isAggregationEndpointValuesLoading = action.payload;
    },
    deleteColumns(state, { payload: colIdsToDelete }) {
      colIdsToDelete.forEach((colFieldToDelete) => {
        const deleteColumnPredicate = ({ colId }) => colFieldToDelete !== colId;
        state.clientState.sortModel = state.clientState.sortModel.filter(deleteColumnPredicate);
        state.clientState.columnState = state.clientState.columnState.filter(deleteColumnPredicate);
        if (Array.isArray(state.clientState.filterModel?._cnf_?.[0])) {
          state.clientState.filterModel._cnf_ = state.clientState.filterModel?._cnf_?.reduce(
            (acc, singleFilterModel) => {
              const arrayOfFilters = singleFilterModel.filter(
                ({ colId }) => colId !== colFieldToDelete
              );
              if (arrayOfFilters.length === 0) return acc;
              acc.push(arrayOfFilters);
              return acc;
            },
            []
          );
        } else {
          if (state.clientState.filterModel?._cnf_?.length) {
            state.clientState.filterModel._cnf_ =
              state.clientState.filterModel?._cnf_?.filter(deleteColumnPredicate);
          }
        }
      });
    },
    renameColumn(state, { payload }) {
      const { colId, newColumnName } = payload;
      state.columnDefs = state.columnDefs.map((colDef) => {
        if (colId === colDef.field) {
          return { ...colDef, headerName: newColumnName };
        }
        return colDef;
      });
    },
    restoreGridState(state, { payload: _clientState }) {
      state.isGridStateRestored = true;
      if (!_clientState) {
        state.isGridStateRestored = true;
        return;
      }
      state.clientState = Object.keys(state.clientState).reduce((acc, key) => {
        if (restoreRules[key]) {
          const rule = restoreRules[key];
          acc[key] = rule(_clientState);
          return acc;
        }
        if (_clientState[key] === undefined) {
          acc[key] = initialState.clientState[key];
        } else {
          acc[key] = _clientState[key];
        }
        return acc;
      }, {});
    },
    changeIndividualColumnState(state, action) {
      const { payload: columnStatePayload } = action;
      const currentState = state.clientState.columnState.find(
        (state) => state.colId === columnStatePayload.colId
      );
      if (columnStatePayload.rowGroupIndex === undefined && columnStatePayload.rowGroup) {
        const groupedColumnsNumber = state.clientState.columnState.filter(
          (columnState) => columnState.rowGroup
        ).length;
        if (groupedColumnsNumber === 0) columnStatePayload.rowGroupIndex = 0;
        else columnStatePayload.rowGroupIndex = groupedColumnsNumber;
      }

      if (!currentState) {
        state.clientState.columnState = [...state.clientState.columnState, columnStatePayload];
      } else {
        let shouldUpdateCurrentState = false;
        Object.keys(columnStatePayload).forEach((key) => {
          if (currentState[key] !== columnStatePayload) {
            currentState[key] = columnStatePayload[key];
            shouldUpdateCurrentState = true;
          }
        });

        if (shouldUpdateCurrentState) {
          state.clientState.columnState = [...state.clientState.columnState];
        }
      }
    },
    setGroupedNodeState(state, { payload }) {
      state.clientState.groupedNodeState = payload;
    },
    updateGroupedNodeState(state, { payload: { field, update } }) {
      if (!state.clientState.groupedNodeState) {
        state.clientState.groupedNodeState = {};
      }
      state.clientState.groupedNodeState[field] = {
        ...state.clientState.groupedNodeState[field],
        ...update,
      };
    },
    removeGroupedNodeState: (state, { payload: { field } }) => {
      delete state.clientState.groupedNodeState[field];
    },
    addCollapsedNode(state, { payload: { field, path } }) {
      if (!state.clientState.groupedNodeState) {
        state.clientState.groupedNodeState = {};
      }

      if (!state.clientState.groupedNodeState[field]) {
        state.clientState.groupedNodeState[field] = {
          defaultExpanded: false,
          expandedNodes: [],
          collapsedNodes: [],
        };
      }
      const nodeState = state.clientState.groupedNodeState[field];
      if (nodeState.defaultExpanded) {
        if (!nodeState.collapsedNodes.some((p) => JSON.stringify(p) === JSON.stringify(path))) {
          nodeState.collapsedNodes.push(path);
        }
      }
    },
    removeCollapsedNode(state, { payload: { field, path } }) {
      if (!state.clientState.groupedNodeState) {
        state.clientState.groupedNodeState = {};
      }

      if (!state.clientState.groupedNodeState[field]) return;
      const nodeState = state.clientState.groupedNodeState[field];
      if (nodeState.defaultExpanded) {
        const index = nodeState.collapsedNodes.findIndex(
          (p) => JSON.stringify(p) === JSON.stringify(path)
        );
        if (index !== -1) nodeState.collapsedNodes.splice(index, 1);
      }
    },
    addExpandedNode(state, { payload: { field, path } }) {
      if (!state.clientState.groupedNodeState) {
        state.clientState.groupedNodeState = {};
      }

      if (!state.clientState.groupedNodeState[field]) {
        state.clientState.groupedNodeState[field] = {
          defaultExpanded: false,
          expandedNodes: [],
          collapsedNodes: [],
        };
      }
      const nodeState = state.clientState.groupedNodeState[field];
      if (!nodeState.defaultExpanded) {
        if (!nodeState.expandedNodes.some((p) => JSON.stringify(p) === JSON.stringify(path))) {
          nodeState.expandedNodes.push(path);
        }
      }
    },
    removeExpandedNode(state, { payload: { field, path } }) {
      if (!state.clientState.groupedNodeState) {
        state.clientState.groupedNodeState = {};
      }

      if (!state.clientState.groupedNodeState[field]) return;
      const nodeState = state.clientState.groupedNodeState[field];
      if (!nodeState.defaultExpanded) {
        const index = nodeState.expandedNodes.findIndex(
          (p) => JSON.stringify(p) === JSON.stringify(path)
        );
        if (index !== -1) nodeState.expandedNodes.splice(index, 1);
      }
    },
    setColumnsState(state, action) {
      const { payload: columnStatePayload } = action;
      // bad
      if (!columnStatePayload || !columnStatePayload.length) {
        state.clientState.columnState = [];
      } else {
        state.clientState.columnState = columnStatePayload.map((columnState) => {
          const prevState = state.clientState.columnState.find(
            (column) => column.colId === columnState.colId
          );
          return { ...prevState, ...columnState };
        });
      }
    },
    setPageNumber(state, { payload: _pageNumber }) {
      state.pageNumber = _pageNumber;
    },
    setTotalNumberOfRows(state, { payload: _totalNumberOfRows }) {
      state.totalNumberOfRows = _totalNumberOfRows;
    },
    setFilteredNumberOfRows(state, { payload: _filteredNumberOfRows }) {
      state.filteredNumberOfRows = _filteredNumberOfRows;
    },
    setRowsNumber(state, { payload }) {
      state.filteredNumberOfRows = payload.filteredNumberOfRows;
      state.totalNumberOfRows = payload.totalNumberOfRows;
    },
    setReduxFilterModelCnf(state, { payload: _filterModel }) {
      if (!state.clientState.filterModel) {
        state.clientState.filterModel = { _cnf_: _filterModel };
      } else {
        state.clientState.filterModel._cnf_ = _filterModel;
      }
    },
    setReduxFilterModelAggregationsCnf(state, { payload: _filterModel }) {
      if (!state.clientState.filterModel) {
        state.clientState.filterModel = { _aggregate_cnf_: _filterModel };
      } else {
        state.clientState.filterModel._aggregate_cnf_ = _filterModel;
      }
    },
    removeAggregationFromFilterModel(state, { payload: aggregatedColIds }) {
      if (state.clientState.filterModel?._aggregate_cnf_) {
        const removeAggregationPredicate = ({ colId }) => aggregatedColIds.includes(colId);
        state.clientState.filterModel._aggregate_cnf_.map(removeAggregationPredicate);

        if (Array.isArray(state.clientState.filterModel?._aggregate_cnf_[0])) {
          state.clientState.filterModel._aggregate_cnf_ =
            state.clientState.filterModel?._aggregate_cnf_?.reduce((acc, singleFilterModel) => {
              const arrayOfFilters = singleFilterModel.filter(({ colId }) =>
                aggregatedColIds.includes(colId)
              );
              if (arrayOfFilters.length === 0) return acc;
              acc.push(arrayOfFilters);
              return acc;
            }, []);
        } else {
          if (state.clientState.filterModel?._aggregate_cnf_?.length) {
            state.clientState.filterModel._aggregate_cnf_ =
              state.clientState.filterModel?._aggregate_cnf_?.filter(removeAggregationPredicate);
          }
        }
      }
    },
    setSelectedRow(state, { payload: _selectedRow }) {
      state.selectedRow = _selectedRow;
    },
    setReduxSavedFilterHandle(state, { payload: _reduxSavedFilterHandle }) {
      state.clientState.reduxSavedFilterHandle = _reduxSavedFilterHandle;
    },
    updateSortModel(state, { payload: _sortModel }) {
      state.clientState.sortModel = [..._sortModel, ...state.clientState.sortModel].reduce(
        (acc, sort) => {
          const indexOfCorrespondingSortModel = acc.findIndex((el) => el.colId === sort.colId);
          if (indexOfCorrespondingSortModel === -1) {
            acc.push(sort);
          }
          return acc;
        },
        []
      );
    },
    setSortModel(state, { payload: _sortModel }) {
      state.clientState.sortModel = _sortModel;
    },
    setAlwaysConfirm(state, { payload: _alwaysConfirm }) {
      state.clientState.alwaysConfirm = _alwaysConfirm;
    },
    setAlwaysConfirmDeleteRow(state, { payload: _alwaysConfirmDeleteRow }) {
      state.clientState.alwaysConfirmDeleteRow = _alwaysConfirmDeleteRow;
    },
    setAlwaysConfirmChangeCase(state, { payload: _alwaysConfirmChangeCase }) {
      state.clientState.alwaysConfirmChangeCase = _alwaysConfirmChangeCase;
    },
    setClientStateColumnHighlights(state, { payload: _columnHighlights }) {
      state.clientState.columnHighlights = _columnHighlights;
    },
    setIsInsertingNewColumn(state, { payload: _isInsertingNewColumn }) {
      state.isInsertingNewColumn = _isInsertingNewColumn;
    },
    setIsRequestingRows(state, { payload: _isRequestingRows }) {
      state.isRequestingRows = _isRequestingRows;
    },
    setIsDeletingRows(state, { payload: _isDeletingRows }) {
      state.isDeletingRows = _isDeletingRows;
    },
    setIsDeletingColumns(state, { payload: _isDeletingColumns }) {
      state.isDeletingColumns = _isDeletingColumns;
    },
    changeColumnDef(state, { payload: _colDef }) {
      state.columnDefs = state.columnDefs.map((colDef) => {
        if (_colDef.field === colDef.field) {
          return { ...colDef, ..._colDef };
        }
        return colDef;
      });
    },
    addToast(state, { payload: _toast }) {
      state.toasts.push(_toast);
    },
    removeToast(state, { payload: _toastId }) {
      const toastIndex = state.toasts.findIndex((toast) => toast.id === _toastId);
      state.toasts.splice(toastIndex, 1);
    },
    setShownToasts(state, { payload: _shownToasts }) {
      state.toasts = state.toasts.map((toast) => {
        const wasToastShown = _shownToasts.some((shownToast) => shownToast.id === toast.id);
        if (wasToastShown) {
          return { ...toast, wasShown: true };
        }
        return toast;
      });
    },
    setIsOpenFileNameMenu(state) {
      state.isOpenFileNameMenu = !state.isOpenFileNameMenu;
    },
    setIsPivotEnabled(state, { payload: _isPivotEnabled }) {
      state.clientState.isPivotEnabled = _isPivotEnabled;
    },
    pushExpandedNode(state, { payload: _expandedNode }) {
      state.clientState.expandedNodes.push(_expandedNode);
    },
    dropExpandedNode(state, { payload: _expandedNodeId }) {
      const index = getIndexForExpandedNode(_expandedNodeId, state.clientState.expandedNodes);
      if (index > -1) {
        state.clientState.expandedNodes.splice(index, 1);
      }
    },
    setExpandedNodes(state, { payload: _expandedNodes }) {
      state.clientState.expandedNodes = _expandedNodes;
    },
    changeDefaultColDef(state, { payload: _defaultColDef }) {
      state.defaultColDef = { ...state.defaultColDef, ..._defaultColDef };
    },
    setIsGridStateRestored(state, { payload: _isGridStateRestored }) {
      state.isGridStateRestored = _isGridStateRestored;
    },
    setAutocompleteColumns(state, { payload: { colId, values } }) {
      state.autocompleteColumns[colId] = values;
    },
    setPaginationPageSize(state, { payload: rowsAmount }) {
      state.paginationPageSize = rowsAmount;
    },
    setUserClosedUpgradeRequiredModal(state, { payload: closed }) {
      state.userClosedUpgradeRequiredModal = closed;
    },
    setNewColumnMode(state, { payload: newColumnMode }) {
      state.newColumnMode = newColumnMode;
    },
    setFormulaToEdit(state, { payload: _formulaToEdit }) {
      state.formulaToEdit = _formulaToEdit;
    },
    openGridToolPanelByName(state, { payload }) {
      state.gridToolPanelName = payload;
      state.gridToolPanelNameToggle = !state.gridToolPanelNameToggle;
    },
    setNewColumnName(state, { payload: columnName }) {
      state.newColumnName = columnName;
    },
    setIsMobileSheetAssistantOpen(state, action) {
      state.isMobileSheetAssistantOpen = action.payload;
    },
    setGenerateDeletingRows(state, action) {
      state.runDeletingRows = action.payload;
    },
    setProperMobileDrawerContent(state, { payload }) {
      state.mobileDrawerContent = payload;
    },
    setIsMobileExperienceModalOpen(state, { payload }) {
      state.isMobileExperienceModalOpen = payload;
    },
    setRefreshCommentThreads(state, { payload }) {
      state.refreshCommentThreads = payload;
    },
    setInsertNewColumnNextTo(state, { payload: columnId }) {
      state.insertNewColumnNextTo = columnId;
    },
    setResetFormulaForm(state, { payload }) {
      state.resetFormulaForm = payload;
    },
    setCommentCell(state, { payload }) {
      state.commentCell = payload;
    },
    setColumnHighlights(state, { payload }) {
      state.columnHighlights = payload;
    },
    setEnrichmentCredits(state, { payload }) {
      state.enrichmentCredits = payload;
    },
    setSheetViews(state, { payload }) {
      state.sheetViews = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchOpenedFile.fulfilled, (state, action) => {
      const currentFile = action.payload;
      state.isRequestingRows = true;
      state.columnDefs = currentFile.metadata.FieldsTypes.map((_, i) => {
        const minimalSetOfProperties = {
          field: currentFile.metadata.HeaderToColumnMapping[i] || `field-${i}`,
          colId: currentFile.metadata.HeaderToColumnMapping[i] || `field-${i}`,
          headerName: currentFile.metadata.Headers[i],
          fieldType: currentFile.metadata.FieldsTypes[i],
          allowedAggFuncs: getAggFunctionsByColumnType(currentFile.metadata.FieldsTypes[i]),
          suppressMenu: false,
          maxWidth: 700,
          filter: null,
        };
        if (i === 0 && currentFile?.metadata?.Headers[i] === ROW_NUMBER_COLUMN_NAME) {
          const hideIDColumn = currentFile?.metadata?.Headers.length > 2;
          return {
            ...minimalSetOfProperties,
            pinned: 'left',
            width: 74,
            minWidth: 74,
            maxWidth: 74,
            lockPosition: 'left',
            hide: hideIDColumn,
          };
        }

        return minimalSetOfProperties;
      });
    });

    builder.addCase(fetchOpenedFile.rejected, (state, action) => {
      state.errorInfo = {
        error: true,
        errorMessage: action.payload,
      };
    });
  },
});

export const {
  addToast,
  removeToast,
  deleteColumns,
  renameColumn,
  setSortModel,
  updateSortModel,
  setPageNumber,
  setColumnDefs,
  setRowsNumber,
  setSelectedRow,
  setReduxFilterModelCnf,
  setReduxFilterModelAggregationsCnf,
  removeAggregationFromFilterModel,
  setShownToasts,
  setColumnsState,
  changeColumnDef,
  restoreGridState,
  pushExpandedNode,
  setExpandedNodes,
  dropExpandedNode,
  setAlwaysConfirm,
  setIsPivotEnabled,
  setIsDeletingRows,
  setIsDeletingColumns,
  setIsRequestingRows,
  changeDefaultColDef,
  setTotalNumberOfRows,
  setIsOpenFileNameMenu,
  setIsGridStateRestored,
  setFilteredNumberOfRows,
  setIsInsertingNewColumn,
  changeIndividualColumnState,
  setGroupedNodeState,
  updateGroupedNodeState,
  removeGroupedNodeState,
  addCollapsedNode,
  removeCollapsedNode,
  addExpandedNode,
  removeExpandedNode,
  setAlwaysConfirmDeleteRow,
  setAlwaysConfirmChangeCase,
  setAutocompleteColumns,
  setReduxSavedFilterHandle,
  setPaginationPageSize,
  setNewColumnMode,
  setFormulaToEdit,
  openGridToolPanelByName,
  setUserClosedUpgradeRequiredModal,
  setNewColumnName,
  setIsMobileSheetAssistantOpen,
  setGenerateDeletingRows,
  setProperMobileDrawerContent,
  setIsMobileExperienceModalOpen,
  setRefreshCommentThreads,
  setAggregationEndpointValues,
  setIsAggregationEndpointValuesLoading,
  setFileStats,
  setInsertNewColumnNextTo,
  setResetFormulaForm,
  setCommentCell,
  setIsCustomColumnHeaderContextMenuOpen,
  setCustomColumnHeaderContextMenuPosition,
  setCustomContextMenuColumn,
  setIsFiltersRuleORSet,
  setIsFiltersMultipleRulesSet,
  setAppliedFiltersMap,
  setColumnHighlights,
  setClientStateColumnHighlights,
  setEnrichmentCredits,
  setSheetViews,
  setRenamePopoverCoordinates,
  setIsRenamePopoverOpen,
  setRenameColumnData,
} = spreadsheetSlice.actions;

export default spreadsheetSlice.reducer;
