import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  MODAL_CLEANUP_UNIX_TIME,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import TimezonePicker from '@/Components/UI/TimezonePicker/TimezonePicker';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const CleanupUnixTimeModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const [, setIsMenuOpen] = useState(false);
  const [timezoneValue, setTimezoneValue] = useState({ timezone: 'Etc/UTC' });

  const { options, setTargetColumn, targetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: (option) =>
      option.type === 'Int64' || option.type === 'UInt64' || option.type === 'Float64',
  });

  const cleanupUnixTime = async () => {
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      const { offset, timezone } = timezoneValue;
      const uuid = getFileUuidFromPath();

      await post(
        `cleanup-unix-time/${uuid}`,
        {
          column: targetColumn.value,
          format: 'default',
          offset: offset,
          timezoneValue: timezone,
        },
        true
      );

      await dispatch(fetchOpenedFile(uuid));

      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Cleanup Unix Time' },
          userIncrementName: '# of function use',
        })
      );

      setTargetColumn(null);
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const closeCleanupUnixTimeModal = () => {
    hideModal();
    setTargetColumn(null);
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeCleanupUnixTimeModal}
      titleSize='large'
      size='small'
      title='Cleanup Unix time'
      articleID='69000777837'
      iconName={'clock'}
    >
      <div className='cleanup-unix-time-container'>
        <div className='mb-4'>
          <InputField label='Choose Unix timestamp column'>
            <SearchSelect
              options={options}
              onChange={(_targetColumn) => setTargetColumn(_targetColumn)}
              setMenuIsOpen={setIsMenuOpen}
              defaultValue={targetColumn}
            />
          </InputField>
        </div>
        <div className='mb-4'>
          <TimezonePicker
            label='Choose time zone offset'
            value={timezoneValue?.timezone}
            onChange={(timezone, label) => {
              setTimezoneValue({
                timezone,
                offset: label && +label.match(/[-+]\d\d/)[0].replace(/\d/, ''),
              });
            }}
          />
        </div>
      </div>

      <hr className='mt-10 mb-5 solid' />
      <div className='flex items-center justify-end gap-4 px-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          dataCy='cancel-btn'
          onClick={closeCleanupUnixTimeModal}
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          dataCy='save-btn'
          onClick={cleanupUnixTime}
          disabled={!targetColumn}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CLEANUP_UNIX_TIME })(CleanupUnixTimeModal);
