import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { useSelector } from 'react-redux';

export const useOverMaxCombineFiles = () => {
  const {
    user: { 'https://gigasheet.com/roles': userRoles },
  } = useSelector((state) => state[userDataSliceName]);
  const { selectedFiles } = useSelector((state) => state[datasetSliceName]);

  const maxCombineFilesRole = userRoles.find((role) => role.includes('max-combine-files-'));
  const maxCombineFilesByRole =
    maxCombineFilesRole && maxCombineFilesRole.split('max-combine-files-')[1];

  const maxCombineFiles = maxCombineFilesByRole || 5;
  const isOverMaxCombineFiles = selectedFiles.length > maxCombineFiles;
  return { isOverMaxCombineFiles, maxCombineFiles };
};
