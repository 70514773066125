import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsRequestingRows } from '../../redux/reducers/spreadsheetSlice';

const DotsLoader = ({ dataCy }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setTimeout(() => {
        dispatch(setIsRequestingRows(false));
      }, 50);
    };
  }, [dispatch]);

  return (
    <div data-cy={dataCy} className='search-loader'>
      <div />
      <div />
      <div />
    </div>
  );
};

export default DotsLoader;
