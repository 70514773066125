import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import {
  setCurrentDirectory,
  setIsLoadingLibrary,
  unSelectAllFiles,
  clearLibrarySearchResult,
  setIsActiveFolderTree,
  setCurrentDataset,
  setFilePath,
} from '@/redux/reducers/datasetSlice';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import useViewportProvider from '@/hooks/useViewportProvider';

import Icon from '@/Components/UI/Icon/Icon';
import { getSortedElementByLastUpdated } from '@/Utils/getSortedElement';
import './styles/index.scss';
import { useNavigate } from 'react-router-dom';
import {
  MY_LIBRARY,
  ORGANIZATION_PERMISSIONS,
  SHARED_WITH_ME,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_DATASETS,
  userTeamNameKey,
} from '@/Utils/constants';
import { putRaw } from '@/Utils/API';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { moveToDirEndpoint } from '@/Utils/apiEndpoints';
import {
  WEBSOCKET_CATEGORY_LIBRARY,
  WEBSOCKET_CATEGORY_SHARED_WITH_ME,
  useWebSocketContext,
} from '../WebSocketLibrary/WebSocketContext';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';

const DatasetTree = ({
  folders,
  w = 230,
  isSharedWithMe,
  icon,
  isExpandedPanel,
  setIsExpandedPanel,
  rootFolderName,
  teamLabel,
}) => {
  const sortedFolder = folders.sort((a, b) => getSortedElementByLastUpdated(a, b));

  return (
    <div className='file-tree'>
      <div className='file-tree__container'>
        {sortedFolder.map((folder, i) => (
          <TreeNode
            w={w}
            key={i}
            id={i}
            folder={folder}
            isSharedWithMe={isSharedWithMe}
            icon={icon}
            isExpandedPanel={isExpandedPanel}
            setIsExpandedPanel={setIsExpandedPanel}
            rootFolderName={rootFolderName || folder.FileName}
            teamLabel={teamLabel}
          />
        ))}
      </div>
    </div>
  );
};

const TreeNode = ({
  folder,
  id,
  w,
  isSharedWithMe,
  icon,
  isExpandedPanel,
  setIsExpandedPanel,
  rootFolderName,
  teamLabel,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { FileUuid, FileName, Children } = folder;
  const { filePath, isActiveFolderTree, currentDataset, selectedFiles } = useSelector(
    (state) => state[datasetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);

  const { sendMessage } = useWebSocketContext();

  const [childVisible, showChildren] = useState(false);

  const { isMobileScreen } = useViewportProvider();
  const hasChild =
    Children?.length && Children?.filter(({ IsDirectory }) => IsDirectory).length ? true : false;

  useEffect(() => {
    if (
      filePath
        ?.slice(0, -1)
        .map((i) => i.FileUuid)
        .includes(FileUuid)
    ) {
      showChildren(true);
    }
  }, [filePath]);

  const handleShowChildren = () => {
    if (FileUuid === 0) {
      if (isActiveFolderTree.includes(FileName)) {
        const newActiveFolderTree = isActiveFolderTree.filter((name) => name !== FileName);
        return dispatch(setIsActiveFolderTree(newActiveFolderTree));
      }
      dispatch(setIsActiveFolderTree([...new Set([...isActiveFolderTree, FileName])]));
    }
  };

  const handleClick = (event) => {
    dispatch(clearLibrarySearchResult());
    dispatch(setIsLoadingLibrary(true));

    event.stopPropagation();
    if (isMobileScreen && !isExpandedPanel) {
      setIsExpandedPanel(true);
    }
    let category = WEBSOCKET_CATEGORY_LIBRARY;
    if (icon === 'folder') {
      category = WEBSOCKET_CATEGORY_LIBRARY;
      dispatch(setCurrentDataset(MY_LIBRARY));
    } else if (icon === 'users-three') {
      category = WEBSOCKET_CATEGORY_SHARED_WITH_ME;
      dispatch(setCurrentDataset(getTeamNameLabel(user[userTeamNameKey])));
    } else {
      category = WEBSOCKET_CATEGORY_SHARED_WITH_ME;
      dispatch(setCurrentDataset(SHARED_WITH_ME));
    }

    if (!FileUuid && !childVisible) {
      sendMessage({ location: '', category });
      dispatch(setFilePath([]));
      dispatch(setCurrentDirectory(''));

      dispatch(setCurrentDirectory(''));
      navigate(`/${URL_DATASETS}`);
    } else if (!FileUuid) {
      sendMessage({ location: '', category });
      dispatch(setFilePath([]));
      dispatch(setCurrentDirectory(''));
      navigate(`/${URL_DATASETS}`);
    } else {
      sendMessage({ location: `${FileUuid}`, category });
      dispatch(setCurrentDirectory(FileUuid));
      navigate(`/${URL_DATASETS}/${FileUuid}`);
    }
  };

  const isTreeMainItemActive = () => {
    if (FileUuid === 0 && FileName === currentDataset && location.pathname.includes('datasets')) {
      return true;
    }
  };

  const setShowChildren = (FileName) => {
    showChildren((v) => !v);
    handleShowChildren(FileName);
  };

  const handleOnDragOver = (e) => {
    rootFolderName === teamLabel && currentDataset === MY_LIBRARY && e.preventDefault();
  };

  const handleOnDrop = async (e, folder) => {
    const dataTransfer = e.dataTransfer.getData('file');

    const moveFile = async (file, folder) => {
      let endpoint = '';
      let payload = {};
      try {
        const fileUuid = file.FileUuid || file.metadata.FileUuid;
        if (folder.FileUuid) {
          endpoint = moveToDirEndpoint(fileUuid);
          payload = {
            dest_dir_handle: `${folder.FileUuid}`,
          };
        } else {
          endpoint = `${URL_DATASET}/${fileUuid}/${ORGANIZATION_PERMISSIONS}`;
          payload = {
            permissions: [0],
          };
        }
        const response = await putRaw(endpoint, payload);
        const { Success } = await response.json();

        if (!Success) {
          ShowToast({
            type: TOAST_TYPE_ERROR,
            text: `Unable to move ${file.FileName}.`,
            errorContext: e,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      } catch (e) {
        ShowToast({
          type: TOAST_TYPE_ERROR,
          text: `Unable to move ${file.FileName}.`,
          errorContext: e,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
    };

    const moveFilePromises = selectedFiles.map((file) => moveFile(file, folder));

    dataTransfer && (await Promise.allSettled(moveFilePromises));

    dispatch(unSelectAllFiles());
  };

  useEffect(() => {
    showChildren(isActiveFolderTree.includes(FileName));
  }, []);

  return (
    <div id={id} className='file-tree__main'>
      <div
        onDrop={(e) => handleOnDrop(e, folder)}
        onDragOver={handleOnDragOver}
        data-cy={`file-tree__main-item-${FileName}`}
        className={`file-tree__main-item
        ${isTreeMainItemActive() ? 'active' : ''}
        ${!hasChild && FileUuid !== 0 ? 'no-toggle' : 'toggle'}
        `}
      >
        {hasChild || FileUuid === 0 ? (
          <div
            data-cy={`file-tree__toggler-${FileName}`}
            role='button'
            tabIndex={0}
            onKeyUp={() => {}}
            onClick={() => setShowChildren(FileName)}
            className='flex justify-center w-4 shrink-0'
          >
            <Icon
              size={10}
              name={childVisible ? 'caret-down-regular' : 'caret-right'}
              weight='fill'
            />
          </div>
        ) : null}

        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          disabled={true}
          onClick={handleClick}
          value={FileName}
          className={`file-tree__main-item-${
            FileUuid === 0 ? 'library text-sm' : 'folder text-[13px]'
          } h-8 flex flex-row items-center gap-2`}
        >
          <div className='pl-2'>
            <Icon name={icon} weight={isTreeMainItemActive() ? 'fill' : 'regular'} />
          </div>
          <label className='file-tree-label' style={{ maxWidth: `${w}` }}>
            {(isExpandedPanel || !isMobileScreen) && FileName}
          </label>
        </div>
      </div>

      {hasChild && childVisible && (
        <div className='file-tree__content'>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
            <DatasetTree
              w={w - 10}
              folders={Children.filter(({ IsDirectory }) => IsDirectory)}
              isSharedWithMe={isSharedWithMe}
              icon={icon}
              isExpandedPanel={isExpandedPanel}
              setIsExpandedPanel={setIsExpandedPanel}
              rootFolderName={rootFolderName}
              teamLabel={teamLabel}
            />
          </Scrollbars>
        </div>
      )}

      {!hasChild && FileUuid === 0 && childVisible && (
        <div
          style={{
            width: 210,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            height: '36px',
            margin: '8px 0px',
            opacity: 1,
          }}
        >
          <p>No folders yet.</p>
        </div>
      )}
    </div>
  );
};

export default DatasetTree;
