import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { useSelector } from 'react-redux';

const useCanExportOrSave = () => {
  const {
    clientState: { columnState, filterModel },
  } = useSelector((state) => state[spreadsheetSliceName]);

  const {
    user: {
      userProperties: { canExportFilteredGrouped },
    },
  } = useSelector((state) => state[userDataSliceName]);

  const isFilterApplied = filterModel && Object.values(filterModel)?.some((el) => el?.length);
  const isGroupApplied = columnState?.length && columnState?.some(({ rowGroup }) => rowGroup);

  const isCanExportFilteredGroupedRolePaywall =
    !canExportFilteredGrouped && (isFilterApplied || isGroupApplied);

  return { isFilterApplied, isGroupApplied, isCanExportFilteredGroupedRolePaywall };
};

export default useCanExportOrSave;
