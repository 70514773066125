import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';

import { spreadsheetSliceName } from '@/redux/constants';
import { setAutocompleteColumns } from '@/redux/reducers/spreadsheetSlice';

import useClickOutside from '@/hooks/useClickOutside';
import { useDebounce } from '@/hooks/useDebounce';

import { get } from '@/Utils/API';
import { getFilterType } from '@/Utils/getFilterType';
import { getTypeIconPath } from '@/Utils/getTypeIconPath';
import { AUTOCOMPLETE, QUERY_PARAM_OMIT_EMPTY_VALUES } from '@/Utils/constants';

import Icon from '@/Components/UI/Icon/Icon';
import DotsLoader from '@/Components/Loader/DotsLoader';
import { Button } from '@/Components/UI/Button/Button';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

import './style/index.scss';
import TextClipper from '@/Components/TextClipper/TextClipper';
import { isBlankOptionVisible } from '@/helpers/spreadsheetHelper';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const FilterPanelMultiValueSetFilter = ({
  cypressTextFieldName,
  defaultValue,
  onChange,
  disabled,
  currFieldType,
  colId,
  modalBodyScrollTop,
  ifCondition,
  isValidConditions = true,
  operatorType = '',
}) => {
  const btnDropDownRef = useRef();
  const dropdownMenuRef = useRef();
  const [menuIsOpen, toggleActionsMenu] = useState(false);

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [isFilterListLoading, setIsFilterListLoading] = useState(false);
  const [isLowCardinality, setIsLowCardinality] = useState(false);
  const [isMaxValueModalShowing, setIsMaxValueModalShowing] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { autocompleteColumns } = useSelector((state) => state[spreadsheetSliceName]);

  const handleSelectValue = (v) => {
    const newValue =
      v?.map((el) => {
        if (el === '') {
          return 'Blanks';
        }
        return el;
      }) || [];

    setValue(newValue);
  };

  const optionsLimit = 10000;
  const filterListHeight =
    !isLowCardinality && filteredOptions && filteredOptions.length >= optionsLimit
      ? searchValue.length > 0
        ? 108
        : 146
      : 177;

  const toggleActionsMenuAndGetColumnValues = async () => {
    toggleActionsMenu(!menuIsOpen);
    setSearchValue('');
    setIsAddDisabled(true);
    handleSelectValue(selectedOptions);
    onChange(selectedOptions);
    setIsMaxValueModalShowing(false);

    setIsFilterListLoading(true);

    const response = await get(
      `${AUTOCOMPLETE}/${
        currentFile.metadata.FileUuid
      }/${colId}?${QUERY_PARAM_OMIT_EMPTY_VALUES}=${isBlankOptionVisible(
        currFieldType,
        operatorType
      )}`
    );

    if (response.Success) {
      if (response.Values) {
        setOptions(response.Values);
        dispatch(
          setAutocompleteColumns({
            colId: colId,
            values: response.Values,
          })
        );
      } else {
        setOptions([]);
        dispatch(
          setAutocompleteColumns({
            colId: colId,
            values: [],
          })
        );
      }
      setIsLowCardinality(response.isLowCardinality);
    } else {
      setOptions([]);
      setIsLowCardinality(true);
    }
    setIsFilterListLoading(false);
  };

  const selectAllToggle = (event) => {
    if (event.target.checked) {
      if (!searchValue.includes(',')) return setSelectedOptions(filteredOptions);
      const searches = searchValue.split(',');
      const filteredSearch = [];
      searches.forEach(
        (value) =>
          value.trim().length > 0 &&
          !filteredSearch.includes(value.trim()) &&
          filteredSearch.push(value.trim())
      );
      return setSelectedOptions([...filteredSearch, ...selectedOptions]);
    }
    setSelectedOptions([]);
  };

  const onCheckboxToggle = (event, option) => {
    if (event.target.checked) return setSelectedOptions([...selectedOptions, option]);
    const newSelected = selectedOptions.filter((op) => op !== option);
    setSelectedOptions(newSelected);
  };

  const handleResetOptions = () => {
    setIsAddDisabled(true);
    setValue([]);
    onChange([]);
    setSearchValue('');
    setSelectedOptions([]);
  };

  const handleAddOptions = () => {
    setIsAddDisabled(true);
    handleSelectValue(selectedOptions);
    onChange(selectedOptions);
    setSearchValue('');
    toggleActionsMenu();
  };

  const getSearchOptions = () => {
    const searches = searchValue.split(',');
    const filteredSearch = [];
    searches.forEach(
      (value) =>
        value.trim().length > 0 &&
        !filteredSearch.includes(value.trim()) &&
        filteredSearch.push(value.trim())
    );
    return (
      <FixedSizeList itemCount={filteredSearch.length} itemSize={37} height={178} width={255}>
        {(props) =>
          SetFilterOption({
            ...props,
            filteredOptions: filteredSearch,
            onCheckboxToggle,
            selectedOptions,
            currFieldType,
          })
        }
      </FixedSizeList>
    );
  };

  useEffect(() => {
    if (defaultValue !== undefined) {
      const filterValueToArray = typeof defaultValue === 'object' ? defaultValue : [defaultValue];

      handleSelectValue(filterValueToArray);
      setSelectedOptions(filterValueToArray);
    } else {
      setValue([]);
      setSelectedOptions([]);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (searchValue.length > 0 && getFilterType(currFieldType) === 'number') {
      // check if there are multiple inputs
      if (searchValue.includes(',')) {
        const searches = searchValue.split(',');
        return setIsValidNumber(!searches.some((value) => isNaN(value.trim())));
      }
      setIsValidNumber(!isNaN(searchValue));
    } else setIsValidNumber(true);
  }, [currFieldType, searchValue]);

  // update options to show values selected and applied when not searching
  useEffect(() => {
    if (!searchValue.length && selectedOptions.length) {
      selectedOptions.forEach(
        (op) =>
          filteredOptions &&
          !filteredOptions.includes(op) &&
          setFilteredOptions([op, ...filteredOptions])
      );
    }
    setIsAddDisabled(selectedOptions.length < 1);
  }, [selectedOptions, searchValue, filteredOptions]);

  // get options that match with the search
  useEffect(() => {
    const getMatchingValues = async () => {
      if (searchValue == null || searchValue.length < 1 || !isValidNumber)
        return setFilteredOptions(autocompleteColumns[colId]);
      setIsFilterListLoading(true);
      setIsLowCardinality(true);
      const response = await get(
        `${AUTOCOMPLETE}/${currentFile.metadata.FileUuid}/${colId}/${searchValue}`
      );
      if (!response.Success) {
        setFilteredOptions(autocompleteColumns[colId]);
      }
      if (response.Values) {
        setFilteredOptions(response.Values);
        setIsLowCardinality(response.isLowCardinality);
      }
      setIsFilterListLoading(false);
    };
    getMatchingValues();
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (searchValue.length < 1) {
      return setFilteredOptions(options);
    }
    // check if there are multiple inputs and search exactly
    if (searchValue.includes(',')) {
      const searches = searchValue.split(',');
      if (getFilterType(currFieldType) === 'text') {
        setFilteredOptions(
          options.filter((option) =>
            searches.some((value) => option.toLowerCase() === value.trim().toLowerCase())
          )
        );
      } else {
        setFilteredOptions(
          options.filter((option) => searches.some((value) => option === value.trim()))
        );
      }
    } else {
      if (getFilterType(currFieldType) === 'text') {
        setFilteredOptions(
          options.filter((option) => option.toLowerCase().includes(searchValue.toLowerCase()))
        );
      } else {
        setFilteredOptions(options.filter((option) => option.includes(searchValue)));
      }
    }
  }, [currFieldType, searchValue, options]);

  useClickOutside(dropdownMenuRef, toggleActionsMenuAndGetColumnValues);

  return (
    <div className='set-filter__container'>
      {value.length > 2 ? (
        <Tooltip side='right' text={JSON.stringify(value.slice(2))}>
          <input
            ref={btnDropDownRef}
            data-cy='set-filter-btn'
            type='text'
            className={clsx(
              'set-filter__values pl-2.5',
              menuIsOpen && 'set-filter__values-focused',
              ifCondition && '!h-8 !w-40 !static',
              !isValidConditions &&
                !value.length &&
                '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
            )}
            placeholder='Value1, Value2, ...'
            value={
              value.length > 2 ? `${value[0]}, ${value[1]}, or ${value.length - 2} more` : value
            }
            onClick={toggleActionsMenuAndGetColumnValues}
            readOnly
            data-hj-allow
          />
        </Tooltip>
      ) : (
        <input
          ref={btnDropDownRef}
          data-cy='set-filter-btn'
          type='text'
          className={clsx(
            'set-filter__values pl-2.5',
            menuIsOpen && 'set-filter__values-focused',
            ifCondition && '!h-8 !w-40 !static',
            !isValidConditions &&
              !value.length &&
              '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          )}
          placeholder='Value1, Value2, ...'
          value={value.length > 2 ? `${value[0]}, ${value[1]}, or ${value.length - 2} more` : value}
          onClick={toggleActionsMenuAndGetColumnValues}
          readOnly
          data-hj-allow
        />
      )}

      {!disabled && menuIsOpen && (
        <div
          ref={dropdownMenuRef}
          style={{
            top:
              btnDropDownRef.current.offsetTop +
              btnDropDownRef.current.offsetHeight -
              modalBodyScrollTop,
          }}
          className='set-filter__dropdown'
          id='set-filter-dropdown'
        >
          <div className={`set-filter__search ${!isValidNumber && 'set-filter__search-invalid'}`}>
            <input
              data-cy={cypressTextFieldName}
              className='pl-2.5'
              value={searchValue}
              placeholder='Search'
              onChange={(event) => setSearchValue(event.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  const dataToRequest = e.target.value;
                  if (dataToRequest.includes(',')) {
                    setSelectedOptions([...selectedOptions, ...dataToRequest.split(',')]);
                    handleSelectValue([...value, ...dataToRequest.split(',')]);
                    toggleActionsMenu();
                    onChange([...selectedOptions, ...dataToRequest.split(',')]);
                    return;
                  }
                  setSelectedOptions([...selectedOptions, dataToRequest]);
                  handleSelectValue([...value, dataToRequest]);
                  toggleActionsMenu();
                  onChange([...selectedOptions, dataToRequest]);
                }
              }}
              autoFocus
              data-hj-allow
            />
            {!isValidNumber && (
              <div className='validation-block set-filter__validation'>Must be valid number</div>
            )}
            <Icon name='magnifying-glass' size={18} />
          </div>
          <div className='set-filter__menu'>
            <div className='set-filter__menu-select-all'>
              <input
                className='file-checkbox pl-2.5'
                type='checkbox'
                id='selectAllOptions'
                onChange={selectAllToggle}
                ref={(checkbox) => {
                  if (!checkbox) return;
                  checkbox.indeterminate =
                    checkbox &&
                    selectedOptions?.length &&
                    filteredOptions?.length &&
                    selectedOptions.length < filteredOptions.length;
                }}
                checked={
                  selectedOptions?.length &&
                  filteredOptions?.length &&
                  selectedOptions.length >= filteredOptions.length
                }
                data-hj-allow
              />
              <label htmlFor='selectAllOptions'>Select/Deselect All</label>
            </div>
            {(value?.length > 0 || (searchValue.length > 0 && !value?.includes(searchValue))) &&
              isValidNumber &&
              searchValue.length > 0 && (
                <div className='set-filter__menu-freesolo'>
                  {/* show option that is being searched */}
                  {searchValue.includes(',')
                    ? getSearchOptions()
                    : !value.includes(searchValue) && (
                        <SetFilterOption
                          option={searchValue}
                          cypressName='set-filter-search-option'
                          onCheckboxToggle={onCheckboxToggle}
                          selectedOptions={selectedOptions}
                          currFieldType={currFieldType}
                          isCurrFieldTypeIcon={false}
                        />
                      )}
                </div>
              )}
            {isFilterListLoading ? (
              <div className='mx-auto my-5'>
                <DotsLoader />
              </div>
            ) : (
              <div className='set-filter__menu-items'>
                <FixedSizeList
                  itemCount={filteredOptions?.length}
                  itemSize={37}
                  height={filterListHeight}
                  width={255}
                >
                  {(props) =>
                    SetFilterOption({
                      ...props,
                      filteredOptions,
                      onCheckboxToggle,
                      selectedOptions,
                      currFieldType,
                      isCurrFieldTypeIcon: false,
                    })
                  }
                </FixedSizeList>
              </div>
            )}
            {!isLowCardinality &&
              !isFilterListLoading &&
              isValidNumber &&
              filteredOptions?.length >= optionsLimit && (
                <div
                  role='button'
                  tabIndex={0}
                  onKeyUp={() => {}}
                  className='flex flex-row items-center gap-2 set-filter__menu-low-cardinality'
                  onClick={() => setIsMaxValueModalShowing(!isMaxValueModalShowing)}
                >
                  <Icon name='warning' color='#D9B500' size={20} alt='Not all values showing' />
                  <span>Not all values showing</span>
                </div>
              )}
            <div
              className={`set-filter__menu-low-cardinality-modal${
                isMaxValueModalShowing ? '' : '-hide'
              }`}
            >
              <div className='set-filter__menu-low-cardinality-modal-header'>
                <div className='inline-flex items-center justify-center set-filter__menu-low-cardinality-modal-header-circle'>
                  <Icon name='warning' color='#F2CA00' size={40} alt='Not all values showing' />
                </div>
              </div>
              <p className='mx-5 mb-5'>
                Only the first 10,000 unique values are displayed. This column exceeds 10,000 unique
                values.
              </p>
              <div className='set-filter__menu-low-cardinality-modal-footer'>
                <Button
                  color='shadow'
                  onClick={() => setIsMaxValueModalShowing(!isMaxValueModalShowing)}
                >
                  Okay
                </Button>
              </div>
            </div>
          </div>
          <div className='set-filter__footer'>
            <Button color='shadow' onClick={handleResetOptions}>
              Reset
            </Button>
            <Button
              dataCy='set-filter-add'
              color='oceanBlue'
              onClick={handleAddOptions}
              disabled={isAddDisabled}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const SetFilterOption = ({
  index,
  style,
  filteredOptions = [],
  option = null,
  cypressName = 'set-filter-option',
  onCheckboxToggle,
  selectedOptions,
  currFieldType,
  isCurrFieldTypeIcon = true,
}) => {
  const currentOption = option ? option : filteredOptions[index];

  return (
    <div className='set-filter__menu-item' key={currentOption} style={style}>
      <input
        data-cy={cypressName}
        className='file-checkbox set-filter__menu-item-check pl-2.5'
        type='checkbox'
        id={`${currentOption}-check`}
        onChange={(event) => onCheckboxToggle(event, currentOption)}
        checked={selectedOptions.includes(currentOption)}
      />
      <label htmlFor={`${currentOption}-check`}>
        {isCurrFieldTypeIcon && <Icon name={getTypeIconPath(currFieldType)} size={18} />}
        <TextClipper text={currentOption === '' ? '(Blanks)' : currentOption} />
      </label>
    </div>
  );
};

export default FilterPanelMultiValueSetFilter;
