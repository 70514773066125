import { useDispatch, useSelector } from 'react-redux';

import { showModal } from '@/redux/reducers/modalsSlice';

import { convertToBytes } from '@/Utils/convertToBytes';
import { MODAL_UPGRADE_ACCOUNT, MODAL_UPLOAD_FILE, upgradeAccountTypes } from '@/Utils/constants';

const withUploadModal = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const { filesSize } = useSelector((state) => state.dataset);
  const { user } = useSelector((state) => state.userData);
  const { maxStorageSize } = user?.userProperties || {};

  // here we need to check if user did not exceeded fileStorageSize
  const openUploadModal = (props = {}) => {
    if (convertToBytes(filesSize) >= convertToBytes(maxStorageSize)) {
      return dispatch(
        showModal({
          name: MODAL_UPGRADE_ACCOUNT,
          props: { upgradeType: upgradeAccountTypes.maxStorageSize },
        })
      );
    }
    dispatch(showModal({ name: MODAL_UPLOAD_FILE, props: { ...props } }));
  };

  return <WrappedComponent {...props} openUploadModal={openUploadModal} />;
};

export default withUploadModal;
