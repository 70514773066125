import { get, put, http_delete } from './API';
import { FILTER_TEMPLATES, GENERATE_HANDLE, ON_SHEET } from './constants';
import { getFileUuidFromPath } from './getFileUuidFromPath';
import parseArrayToMatrix from './parseArrayToMatrix';

const uuid = getFileUuidFromPath();

export const DEFAULT_FILTER_VALUE = {
  value: 'Load Filters',
  label: 'Load Filters',
  filterHandle: '',
};

export const LOADING_FILTER_VALUE = {
  value: 'Loading...',
  label: 'Loading...',
  filterHandle: '',
};

// Generate unique handle for filter template
export const generateFilterHandle = () => get(GENERATE_HANDLE);

// Save filter
export const saveFilterTemplate = (filterHandle, filterModel, name) => {
  const data = {
    filterHandle: filterHandle,
    sheet: uuid,
    filterModel: { _cnf_: parseArrayToMatrix(filterModel) },
    name: name,
  };
  const response = put(`${FILTER_TEMPLATES}/${filterHandle}`, data);
  return response;
};

// Get a template by handle
export const getFilterTemplate = (filterHandle) => get(`${FILTER_TEMPLATES}/${filterHandle}`);

// Get all templates
export const getAllFilterTemplates = () => get(FILTER_TEMPLATES);

// Delete template by filter handle
export const deleteFilterTemplate = (filterHandle) => http_delete(FILTER_TEMPLATES, filterHandle);

// Get templates applied on sheet
export const getFilterTemplateOnSheet = (filterHandle) =>
  get(`${FILTER_TEMPLATES}/${filterHandle}/${ON_SHEET}/${uuid}`);
