import clsx from 'clsx';
import React, { useRef, useState, useEffect } from 'react';

interface MobileDropMenuProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

function MobileDropMenu({ isOpen, onClose, children }: MobileDropMenuProps) {
  const sheetRef = useRef(null);
  const [sheetHeight, setSheetHeight] = useState(0);

  useEffect(() => {
    if (sheetRef.current) {
      setSheetHeight(sheetRef.current['offsetHeight']);
    }
  }, [sheetRef]);

  const sheetStyles = {
    transform: isOpen ? 'translateY(0)' : `translateY(${sheetHeight}px)`,
  };

  return (
    <>
      <div
        data-cy={isOpen ? 'mobile-close-active-dropdown-menu' : ''}
        onKeyUp={() => {}}
        tabIndex={0}
        role='button'
        className={clsx(
          'fixed top-0 left-0 w-full h-full bg-black opacity-50 z-[4]',
          isOpen ? 'block' : 'hidden'
        )}
        onClick={onClose}
      />
      <div
        data-cy='mobile-active-dropdown-menu-wrapper'
        className={clsx(
          'fixed bottom-0 left-0 z-10 w-full transition-transform duration-300 ease-out bg-white shadow-md',
          isOpen ? 'opacity-1' : 'opacity-0'
        )}
        style={sheetStyles}
        ref={sheetRef}
      >
        <div data-cy='mobile-active-dropdown-menu-children' className='mb-3'>
          {children}
        </div>
      </div>
    </>
  );
}

export default MobileDropMenu;
