const ToastBody = ({ type, text }) => {
  const getContentHeader = () => {
    switch (type) {
      case 'info':
        return 'Processing...';
      case 'success':
        return 'It worked!';
      case 'error':
        return 'You’ve hit an error...';
      case 'reconnection':
        return 'Uh oh';
      default:
        return 'Processing';
    }
  };

  return (
    <div className='!leading-normal toast-content'>
      <span>{getContentHeader()}</span>
      <p>{text}</p>
    </div>
  );
};
export default ToastBody;
