import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import showToast from '../../Toast/showToastTemplate';
import withModal from '../withModalHOC';
import ChangeCase from './ChangeCase';

import { useFunctionModal } from '../../../hooks/useFunctionModal.ts';

import { spreadsheetSliceName } from '../../../redux/constants';
import { fetchOpenedFile } from '../../../redux/reducers/datasetSlice';
import { hideAllModals } from '../../../redux/reducers/modalsSlice';
import {
  changeIndividualColumnState,
  setAlwaysConfirmChangeCase,
  setIsInsertingNewColumn,
} from '../../../redux/reducers/spreadsheetSlice';

import Modal from '@/Components/UI/Modal/Modal';
import { post } from '../../../Utils/API';
import {
  MODAL_CHANGE_CASE,
  TOAST_TEXT_CHANGE_CASE_ERROR,
  TOAST_TYPE_ERROR,
} from '../../../Utils/constants';
import { getFileUuidFromPath } from '../../../Utils/getFileUuidFromPath';

const ChangeCasePopup = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const {
    columnDefs,
    clientState: { alwaysConfirmChangeCase },
  } = useSelector((state) => state[spreadsheetSliceName]);

  const { options, setTargetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: ({ type }) => type === 'String' || type === 'EmailAddress',
  });

  const typeOptions = [
    { label: 'UPPER', value: 'Uppercase' },
    { label: 'lower', value: 'Lowercase' },
    { label: 'Capitalize first', value: 'Capitalize' },
    { label: 'Proper Case', value: 'Proper' },
  ];

  const [selectedCaseValue, setSelectedCaseValue] = useState(typeOptions[0].value);
  const [, setSelectedCase] = useState(typeOptions[0]);

  const [selectedColumnValue, setSelectedColumnValue] = useState(options[0]?.value);
  const [, setSelectedColumn] = useState(options[0]);

  const [isConfirmDialogChecked, setIsConfirmDialogChecked] = useState(false);

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const [isConfirmStep, setIsConfirmStep] = useState(false);

  useEffect(() => {
    const option = typeOptions.find((option) => option.value === selectedCaseValue);
    setSelectedCase(option);
  }, [selectedCaseValue]);

  useEffect(() => {
    const column = options?.find((option) => option?.value === selectedColumnValue);
    setSelectedColumn(column);
  }, [selectedColumnValue]);

  const changeCase = async () => {
    setIsConfirmStep(false);
    dispatch(hideAllModals());
    dispatch(setIsInsertingNewColumn(true));

    try {
      setIsSaveBtnDisabled(true);
      const endpoint = `change-case/${getFileUuidFromPath()}/${selectedColumnValue}`;
      const payload = { case: selectedCaseValue };
      const castResponse = await post(endpoint, payload);

      if (!castResponse?.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_CHANGE_CASE_ERROR,
          errorContext: castResponse,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }

      dispatch(setAlwaysConfirmChangeCase(!isConfirmDialogChecked));
      dispatch(changeIndividualColumnState({ colId: selectedColumnValue, hide: true }));
      dispatch(fetchOpenedFile(getFileUuidFromPath()));

      setIsSaveBtnDisabled(false);
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const save = () => {
    if (!alwaysConfirmChangeCase || isConfirmStep) {
      changeCase();
    } else setIsConfirmStep(true);
  };

  const cancel = () => {
    if (isConfirmStep) setIsConfirmStep(false);
    else {
      setTargetColumn(null);
      hideModal();
    }
  };

  return (
    <Modal
      iconName='swap'
      isOpen={show}
      title={isConfirmStep ? 'Are you sure you want to convert this?' : 'Change Case'}
      articleID='69000841264'
      onClose={cancel}
      size='small'
    >
      <ChangeCase
        options={options}
        isConfirmStep={isConfirmStep}
        typeOptions={typeOptions}
        setSelectedCaseValue={setSelectedCaseValue}
        selectedCaseValue={selectedCaseValue}
        setSelectedColumnValue={setSelectedColumnValue}
        selectedColumnValue={selectedColumnValue}
        setIsConfirmDialogChecked={setIsConfirmDialogChecked}
        isConfirmDialogChecked={isConfirmDialogChecked}
        isSaveBtnDisabled={isSaveBtnDisabled}
        cancel={cancel}
        save={save}
      />
    </Modal>
  );
};

export default withModal({ name: MODAL_CHANGE_CASE })(ChangeCasePopup);
