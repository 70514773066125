import { FILE_UUID_LENGTH, FILE_UUID_REGEX } from './constants';

const PATH_INFO_FILE_HANDLE = 1;
const PATH_INFO_FILE_NAME = 2;

export const getFileUuidFromPath = () => {
  return getFromPath(PATH_INFO_FILE_HANDLE);
};

export const getFileNameFromPath = () => {
  return getFromPath(PATH_INFO_FILE_NAME);
};

const getFromPath = (pathInfo) => {
  try {
    const splitPath = window.location.pathname.split('/');
    const spreadsheetIndex = splitPath.indexOf('spreadsheet');
    if (spreadsheetIndex === -1 || spreadsheetIndex === splitPath.length - 1) {
      // 'spreadsheet' not found in the path, or no segments after 'spreadsheet'
      return null;
    }

    const fileNameSegment = splitPath[spreadsheetIndex + 1]; // File name is one segment after 'spreadsheet'
    const fileHandleSegment = splitPath[spreadsheetIndex + 2]; // File handle is two segments after 'spreadsheet'
    const hasValidHandle =
      fileHandleSegment &&
      fileHandleSegment.length === FILE_UUID_LENGTH &&
      fileHandleSegment.match(FILE_UUID_REGEX);

    switch (pathInfo) {
      case PATH_INFO_FILE_HANDLE:
        if (hasValidHandle) {
          return fileHandleSegment;
        }
        break;
      case PATH_INFO_FILE_NAME:
        if (hasValidHandle && fileNameSegment.length > 0) {
          return fileNameSegment;
        }
        break;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getFolderHandleFromPath = () => {
  try {
    const splitPath = window.location.pathname.split('/');
    const datasetsIndex = splitPath.indexOf('datasets');
    if (datasetsIndex === -1 || datasetsIndex === splitPath.length - 1) {
      // 'datasets' not found in the path, or no segments after 'datasets'
      return null;
    }

    const folderHandleSegment = splitPath[datasetsIndex + 1]; // UUID is one segment after 'datasets'

    if (
      folderHandleSegment &&
      folderHandleSegment.length === FILE_UUID_LENGTH &&
      folderHandleSegment.match(FILE_UUID_REGEX)
    ) {
      return folderHandleSegment;
    }

    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
