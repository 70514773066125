import InputField from '@/Components/UI/Form/InputField/InputField';
import PasswordInput from '@/Components/UI/Form/PasswordInput/PasswordInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../FilterPanel/style/index.scss';
import Dropdown from './Dropdown';
import { updateUserInputValueById } from '@/redux/reducers/enrichmentTemplates';
import { enrichmentTemplatesSliceName } from '@/redux/constants';
import { ColumnReferenceTextField } from '@/Components/Modals/Enrichment/DataEnrichment/fields/ColumnReferenceTextField';
import { SliderField } from '@/Components/Modals/Enrichment/DataEnrichment/fields/SliderField';
import { getPersistedValuesKey } from '@/Utils/enrichmentUtils/EnrichmentUtils';

export const EnrichmentField = ({ field }) => {
  const { chosenEnrichmentTemplate, persistedValues } = useSelector(
    (state) => state[enrichmentTemplatesSliceName]
  );
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState('');

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const dataToRedux = (value) => {
    return {
      ...field,
      template_value_if_set: value,
    };
  };

  const onHandleChangePassword = (e) => {
    setValue(e.target.value);
    dispatch(updateUserInputValueById(dataToRedux(e.target.value)));
  };

  const onHandleChangeTextInput = (text) => {
    const textAsString = '' + text;
    setValue(textAsString);
    dispatch(updateUserInputValueById(dataToRedux(textAsString)));
  };

  useEffect(() => {
    const isSliderChecked = field?.type === 'slider' && !!field?.template_value_if_set;
    isSliderChecked && setIsChecked(isSliderChecked);
  }, []);

  useEffect(() => {
    if (isChecked) {
      const chosenEnrichmentTemplateField = chosenEnrichmentTemplate.fields.find(
        (prop) => prop.id === field.id
      );
      let newUserInputValue = chosenEnrichmentTemplateField.template_value_if_unset;
      try {
        const persistedValue =
          persistedValues[
            getPersistedValuesKey(
              chosenEnrichmentTemplate.id,
              field.id,
              chosenEnrichmentTemplateField.persisted_values_key
            )
          ];
        const isCheckedInitialValue = chosenEnrichmentTemplateField.is_checked_initial_value;

        if (persistedValue?.length > 0) {
          newUserInputValue = persistedValue;
        } else if (isCheckedInitialValue?.length > 0) {
          newUserInputValue = isCheckedInitialValue;
        }
      } catch (e) {
        console.error(e);
      }
      const newUserInputValueAsString = '' + newUserInputValue;
      dispatch(updateUserInputValueById(dataToRedux(newUserInputValueAsString)));
    } else {
      if (field?.type === 'slider') {
        dispatch(updateUserInputValueById(dataToRedux('')));
      }
    }
  }, [isChecked]);

  const renderField = () => {
    if (field.type === 'column_reference') {
      return <ColumnReferenceTextField field={field} onChange={onHandleChangeTextInput} />;
    } else if (field.type === 'slider') {
      return <SliderField field={field} onChange={onHandleChangeTextInput} />;
    } else {
      return field.is_password ? (
        <div className='mb-[18px]'>
          <PasswordInput value={value} onChange={onHandleChangePassword} className='w-full' />
        </div>
      ) : (
        <div className='mb-[18px]'>
          <Dropdown value={value} onChange={onHandleChangeTextInput} />
          <InputField helperText={field.description ? field.description : ''} color='capri' />
        </div>
      );
    }
  };

  return (
    <div>
      <div className='flex justify-between'>
        <div className='mb-[8px]'>
          <InputField label={field.name} />
        </div>

        <div>
          {!field.required && (
            <label className='relative inline-flex items-center cursor-pointer'>
              <input
                type='checkbox'
                id='checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
                data-cy='required-field-checkbox'
                className='sr-only peer'
              />
              <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          )}
        </div>
      </div>
      {(field.required || isChecked) && renderField()}
    </div>
  );
};
