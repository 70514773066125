import React, { useState } from 'react';
import withModal from '../withModalHOC';
import { MODAL_BUSINESS_PAYWALL } from '@/Utils/constants';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import { InlineWidget } from 'react-calendly';

type BusinessPaywallProps = { show: boolean; hideModal: () => void };

const BusinessPaywall = ({ show, hideModal }: BusinessPaywallProps) => {
  const [showDemo, setShowDemo] = useState<boolean>(false);

  const onRequestDemoButtonClick = () => {
    setShowDemo(true);
  };

  const title = showDemo ? (
    ''
  ) : (
    <span className='absolute left-0 w-full text-center'>Gigasheet for Business </span>
  );

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='large'
      titlePosition='center'
      size='small'
      title={title}
      withoutCloseButton
      withoutTitle={showDemo}
    >
      {showDemo ? (
        <div className='relative' data-cy='business-paywall-widget'>
          <InlineWidget url='https://calendly.com/steve-schohn/gigasheet-business' />
        </div>
      ) : (
        <div data-cy='business-paywall-modal'>
          <table className='mb-5' data-cy='business-paywall-modal-table'>
            <thead className='text-lg font-semibold border-b border-shadow-100 text-ui'>
              <tr>
                <th className='w-[40%]'></th>
                <th className='w-[30%] text-center'>Premium</th>
                <th className='w-[30%] text-center'>Business</th>
              </tr>
            </thead>
            <tbody>
              <tr className='border-b border-shadow-100'>
                <td className='p-2 text-[13px] font-semibold text-ui-secondary'>Users</td>
                <td className='p-2 text-[13px] font-normal text-ui-secondary text-center'>
                  Pay Per User
                </td>
                <td className='p-2 text-[13px] font-normal text-ui-secondary text-center'>
                  Unlimited
                </td>
              </tr>
              {['Team Workspace & Storage', 'Automate Workflows', ' Audit File Changes'].map(
                (el) => (
                  <tr key={el} className='border-b border-shadow-100'>
                    <td className='p-2 text-[13px] font-semibold text-ui-secondary'>{el}</td>
                    <td className='p-2 text-[13px] font-normal text-ui-secondary text-center'>
                      <span className='flex justify-center'>
                        <Icon size={16} name='x-circle' weight='fill' color='#C400E1' />
                      </span>
                    </td>
                    <td className='p-2 text-[13px] font-normal text-ui-secondary text-center'>
                      <span className='flex justify-center'>
                        <Icon size={16} name='check-circle' weight='fill' color='#A3AE28' />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div className='flex flex-col items-center'>
            <Button
              onClick={onRequestDemoButtonClick}
              className='!w-fit !mb-3'
              color='sunrise'
              dataCy='business-paywall-modal-button'
            >
              Request Demo
            </Button>
            <a
              data-cy='business-paywall-modal-link'
              href='https://gigasheet.com/business'
              target='_blank'
              className='text-sm text-ui-helper'
              rel='noopener noreferrer'
            >
              Learn more
            </a>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withModal({ name: MODAL_BUSINESS_PAYWALL, destroyOnHide: true })(BusinessPaywall);
