import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '@/redux/reducers/modalsSlice';

import { Button } from '@/Components/UI/Button/Button';
import { MODAL_CUSTOM_ENRICHMENT } from '@/Utils/constants';

import Telescope from './assets/telescope.svg';
import { RequestedConnectorsSVG } from '@/Assets/icons';

export const NewEnrichmentRequest = ({ setIsNewEnrichmentPopoverShow }) => {
  return (
    <div className='absolute transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md shadow-[0_0px_20px_-4px_rgba(6,3,77,0.16)] top-[42%] left-1/2'>
      <div className='w-[500px] h-[350px] p-6 flex items-center justify-center flex-col'>
        <img src={RequestedConnectorsSVG.RequestedConnectors} alt='convert' />
        <p className='mt-6 text-2xl font-semibold leading-8 text-indigo-800'>Suggestion Sent!</p>
        <p className='mt-4 mb-4 sent-request-container-text'>'Thank you for suggesting.'</p>
        <Button
          className='font-medium tracking-wide uppercase'
          color='shadow'
          onClick={() => setIsNewEnrichmentPopoverShow(false)}
        >
          Dismiss
        </Button>
      </div>
    </div>
  );
};

export const EnrichmentNoResults = () => {
  const dispatch = useDispatch();

  const [isNewEnrichmentPopoverShow, setIsNewEnrichmentPopoverShow] = useState(false);

  const sendBuildItPleaseRequest = () => {
    setIsNewEnrichmentPopoverShow(true);
    window.location.href = 'mailto:info@gigasheet.com?subject=Enrichment Request';
  };

  return (
    <div className='relative flex flex-col items-center w-full h-full'>
      <div className='mt-14'>
        <img alt='enrichment no result' src={Telescope} />
      </div>
      <div className='mt-[48px] text-lg font-extrabold'>
        Hmm... we can’t seem to find what you’re searching for
      </div>
      <div className='my-[24px] text-sm font-normal leading-[145%] text-center pl-[104px] pr-[88px]'>
        Check out our{' '}
        <a
          href='https://support.gigasheet.com/support/solutions/articles/69000835545-custom-enrichments'
          target='_blank'
          className='text-ocean-blue-500'
          rel='noopener noreferrer'
        >
          support docs
        </a>{' '}
        and{' '}
        <span
          role='button'
          onKeyUp={() => {}}
          tabIndex='0'
          onClick={() => dispatch(showModal({ name: MODAL_CUSTOM_ENRICHMENT }))}
          className='text-ocean-blue-500'
        >
          Build Your Own enrichment
        </span>
        . Or let us know what enrichment we should add.
      </div>
      <Button color='oceanBlue' onClick={sendBuildItPleaseRequest}>
        Build it Please
      </Button>

      {isNewEnrichmentPopoverShow && (
        <NewEnrichmentRequest setIsNewEnrichmentPopoverShow={setIsNewEnrichmentPopoverShow} />
      )}
    </div>
  );
};
