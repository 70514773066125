import { URL_DATASETS } from '@/Utils/constants';
import { Link } from 'react-router-dom';

const ExportFileToastBody = ({ closeToast }) => (
  <span>
    Your export is building. You can monitor progress in the{' '}
    <Link to={`/${URL_DATASETS}`} onClick={closeToast} target='_blank'>
      Library
    </Link>
  </span>
);

export default ExportFileToastBody;
