import { sha1 } from 'object-hash';
import SheetAssistant from '..//Components/Spreadsheet/SheetAssistant/SheetAssistant';
import SheetStats from '..//Components/Spreadsheet/SheetStats/SheetStatsPanel';
import {
  COUNT,
  DELETE_HIDDEN_COLUMNS,
  DELETE_MULTIPLE_COLUMNS,
  DELETE_SELECTED_COLUMNS,
  DELETE_VISIBLE_COLUMNS,
  FILE,
  FILES,
  FILTER,
  MODAL_DELETE_ROW,
  MODAL_MAKE_COPY,
  MODAL_UPGRADE_REQUIRED,
  PERMISSION_WRITE,
  PIVOT,
  QUERY_SELECTOR_FORMULA_PANEL_BTN,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  URL_DATASET,
} from '..//Utils/constants';
import { getFileUuidFromPath } from '..//Utils/getFileUuidFromPath';
import { getFilterType } from '..//Utils/getFilterType';
import DotsLoader from '../Components/Loader/DotsLoader';
import AggregationFunctions from '../Components/Spreadsheet/AggregationFunctions/AggregationFunctions';
import FunctionSelect from '../Components/Spreadsheet/AggregationFunctions/FunctionSelect';
import CustomAutoGroupValue from '../Components/TableGrid/CustomAutoGroupValue';
import CustomColumnHeader from '../Components/TableGrid/CustomColumnHeader';
import CustomNoRowsComponent from '../Components/TableGrid/CustomNoRowsComponent';
import RowDetailPanel from '../Components/TableGrid/rowDetail/rowDetailPanel';
import { get, httpDeleteWithBody, post, postRaw, put } from '../Utils/API';
import { fetchOpenedFile } from '../redux/reducers/datasetSlice';
import { showModal } from '../redux/reducers/modalsSlice';
import { addMixpanelEvent } from '../redux/reducers/sharedSlice';
import {
  addToast,
  deleteColumns,
  restoreGridState,
  setReduxFilterModelCnf,
  setGenerateDeletingRows,
  setIsDeletingRows,
  setIsInsertingNewColumn,
  setNewColumnMode,
  setRowsNumber,
  setInsertNewColumnNextTo,
  setResetFormulaForm,
  setAppliedFiltersMap,
  setFormulaToEdit,
  setColumnHighlights,
} from '../redux/reducers/spreadsheetSlice';
import { store } from '../redux/store';
import { generateArrayFromRowsIndex } from '@/Utils/spreadsheetGenerateArrayFromRowsIndex';
import getTopRangeRowsIndexAndRowCount from '@/Utils/getTopRangeRowsIndexAndRowCount';
import { AG_GRID_AUTO_COLUMN, ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import getCurrentAppliedFilters from '@/Utils/getCurrentAppliedFilters';
import { authenticationSliceName } from '@/redux/constants';
import { jwtDecode } from 'jwt-decode';
import { StatusCodes } from 'http-status-codes';

export const getRowClassRules = () => ({
  'flag-none': (params) => {
    const flag = params.data?.metadata_tag ? +params.data.metadata_tag : 0;
    return flag === 0;
  },
  'flag-good': (params) => {
    const flag = params.data?.metadata_tag ? +params.data.metadata_tag : 0;
    return flag === 1;
  },
  'flag-suspicious': (params) => {
    const flag = params.data?.metadata_tag ? +params.data.metadata_tag : 0;
    return flag === 2;
  },
  'flag-bad': (params) => {
    const flag = params.data?.metadata_tag ? +params.data.metadata_tag : 0;
    return flag === 3;
  },
});

export const icons = {
  rows: () => {
    return `<div class="sidebar-icon" data-cy='row-details-icon'>
        <span class="sidebar-tooltip">Rows</span>
        <div class="row_detail"/>
      </div>`;
  },
  columnsIcon: () => {
    return `<div class="sidebar-icon">
        <span class="sidebar-tooltip">Columns</span>
        <div class="columns_details"/>
      </div>`;
  },
  statsIcon: () => {
    return `<div class="sidebar-icon">
        <span class="sidebar-tooltip">Sheet Stats</span>
        <div class="stats_detail"/>
      </div>`;
  },
  sheetAssistantIcon: () => {
    return `<div class="sidebar-icon">
        <span class="sidebar-tooltip">Sheet Assistant</span>
        <div class="sheet-assistant"/>
      </div>`;
  },
};

export const getRowId = (params) =>
  sha1({
    data: params.data,
    parentKeys: params.parentKeys,
  });

export const getDataSource = (agGrid, silentRefresh) => {
  return {
    colDefHash: null,
    state: null,
    getRows: async function (params) {
      const state = store.getState();

      const { filterModel, sortModel, columnHighlights, columnState } =
        state.spreadsheet.clientState;
      const { paginationPageSize } = state.spreadsheet;

      params.request.filterModel = filterModel;
      params.request.sortModel = sortModel;
      params.request.endRow = params.request.startRow + paginationPageSize;

      if (!params.request.pivotMode) {
        params.request.pivotCols = [];
      }
      params.request.groupKeys = params.request.groupKeys?.map((row) => {
        return (row === 'true' || row === 'false') &&
          params.parentNode?.rowGroupColumn?.colDef?.fieldType === 'Boolean'
          ? JSON.parse(row.toLowerCase())
          : row;
      });
      this.state = params.request;

      const currentAppliedFilters = getCurrentAppliedFilters(filterModel?._cnf_) || [];
      const appliedFiltersMap = {};
      currentAppliedFilters.forEach((filter) => (appliedFiltersMap[filter.colId] = true));
      store.dispatch(setAppliedFiltersMap(appliedFiltersMap));

      const fileId = getFileUuidFromPath();
      const rowsCountResponse = await get(`${FILE}/${COUNT}/${fileId}`);
      let fileRowsResponse = null;

      if (params.request.pivotMode && params.request.pivotCols.length) {
        const endpoint = `${FILE}/${fileId}/${PIVOT}`;
        fileRowsResponse = await post(endpoint, params.request);

        if (fileRowsResponse.Success === false) {
          store.dispatch(
            addToast({
              id: Date.now(),
              message: fileRowsResponse.Message,
              type: 'error',
              wasShown: false,
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
              endpoint: endpoint,
              payload: params.request,
            })
          );
        }
        this.setSecondaryColsIntoGrid(fileRowsResponse.pivotFields || []);
      } else {
        const { request } = params;

        const requestCopy = { ...request };
        requestCopy.rowGroupCols = requestCopy.rowGroupCols.map((col) => {
          const column = columnState.find((e) => e.colId === col.id);
          return {
            ...col,
            groupFunc: column?.groupFunc ?? '',
          };
        });

        const endpoint = `${FILE}/${fileId}/${FILTER}`;
        const payload = {
          ...requestCopy,
          columnHighlights: columnHighlights || {},
        };

        let tokenExpirationDetails = {};
        try {
          const token = store.getState()[authenticationSliceName].auth0IdToken;
          const decodedToken = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);
          const tenMinutesInSeconds = 10 * 60;
          const tokenExpireTime = decodedToken.exp;
          const tokenTimeRemainingUntilExpiration = tokenExpireTime - currentTime;
          const willExpireSoon = tokenTimeRemainingUntilExpiration < tenMinutesInSeconds;

          tokenExpirationDetails = {
            tokenExpireTime: tokenExpireTime,
            tokenTimeRemainingUntilExpiration: tokenTimeRemainingUntilExpiration,
            willExpireSoon: willExpireSoon,
          };

          if (willExpireSoon) {
            await silentRefresh(true);
          }
        } catch (e) {
          store.dispatch(
            addMixpanelEvent({
              eventName: 'Failed to silent refresh auth0 token',
              eventProps: {
                errorMessage: e?.message,
                errorStack: e?.stack ? e?.stack.substring(0, 500) : 'No stack trace', // Truncate for brevity
                responseStatus: fileRowsResponse?.status,
                fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
                endpoint: endpoint,
                payload: payload,
              },
            })
          );
          console.error(e);
        }
        fileRowsResponse = await post(endpoint, payload);
        if (fileRowsResponse?.status === StatusCodes.UNAUTHORIZED) {
          await silentRefresh(true);
          store.dispatch(
            addMixpanelEvent({
              eventName: 'Retry silent auth0 token refresh',
              eventProps: {
                responseStatus: fileRowsResponse?.status,
                fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
                endpoint: endpoint,
                payload: payload,
              },
            })
          );
          fileRowsResponse = await post(endpoint, payload);
        }

        store.dispatch(setColumnHighlights(fileRowsResponse?.cellHighlights || {}));

        if (!fileRowsResponse.rows && fileRowsResponse?.status !== StatusCodes.NOT_FOUND) {
          store.dispatch(
            addToast({
              id: Date.now(),
              message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
              type: 'error',
              wasShown: false,
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
              endpoint: endpoint,
              payload: payload,
              responseStatus: fileRowsResponse?.status,
              other: tokenExpirationDetails,
            })
          );
          return params.fail();
        }
        this.colDefHash = null;
        if (agGrid.columnApi.getSecondaryColumns()) {
          this.setSecondaryColsIntoGrid([]);
        }

        if (
          fileRowsResponse.message === 'Bad Request' &&
          fileRowsResponse?.status !== StatusCodes.NOT_FOUND
        ) {
          store.dispatch(
            addToast({
              id: Date.now(),
              message: 'Unable to apply filter',
              type: 'error',
              wasShown: false,
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
              endpoint: endpoint,
              payload: payload,
              responseStatus: fileRowsResponse?.status,
              other: tokenExpirationDetails,
            })
          );

          fileRowsResponse = {
            lastRow: 0,
            rows: [],
          };
        }
      }

      store.dispatch(
        setRowsNumber({
          filteredNumberOfRows: fileRowsResponse.lastRow,
          totalNumberOfRows: rowsCountResponse,
        })
      );

      const rowData =
        fileRowsResponse.rows?.map((row) =>
          Object.keys(row).reduce((acc, key) => {
            if (row[key] === true) {
              acc[key] = 'true';
            } else if (row[key] === false) {
              acc[key] = 'false';
            } else acc[key] = row[key];
            return acc;
          }, {})
        ) || [];

      params.success({
        rowData,
        rowCount: fileRowsResponse.lastRow,
      });
    },
    setSecondaryColsIntoGrid: function (secondaryColDefs) {
      const colDefHash = createColsHash(secondaryColDefs);
      if (this.colDefHash !== colDefHash) {
        agGrid.columnApi.setSecondaryColumns(secondaryColDefs);
        this.colDefHash = colDefHash;
      }
    },
  };
};

const createColsHash = (colDefs) => {
  if (!colDefs) {
    return null;
  }
  const parts = [];
  colDefs.forEach((colDef) => {
    if (colDef.children) {
      parts.push(colDef.groupId);
      parts.push('[' + createColsHash(colDef.children) + ']');
    } else {
      parts.push(colDef.colId);
      // headerName can change if the aggFunc was changed in a value col. if we didn't
      // do this, then the grid would not pick up on new header names as we move from
      // eg min to max.
      if (colDef.headerName) {
        parts.push(colDef.headerName);
      }
    }
  });
  return parts.join(',');
};

export const getMainMenuItems = () => {};

export const sidebar = {
  toolPanels: [
    {
      id: 'sheetAssistant',
      labelDefault: '',
      labelKey: 'sheetAssistant',
      iconKey: 'sheetAssistantIcon',
      toolPanel: SheetAssistant,
    },
    {
      id: 'columns',
      labelDefault: '',
      labelKey: 'columns',
      iconKey: 'columnsIcon',
      toolPanel: 'agColumnsToolPanel',
    },
    {
      id: 'rows',
      labelDefault: '',
      labelKey: 'rows',
      iconKey: 'rows',
      toolPanel: RowDetailPanel,
    },
    {
      id: 'stats',
      labelDefault: '',
      labelKey: 'stats',
      iconKey: 'statsIcon',
      toolPanel: SheetStats,
    },
  ],
  position: 'right',
  maxWidth: 600,
};

export const frameworkComponents = {
  agColumnHeader: CustomColumnHeader,
  customLoadingCellRenderer: DotsLoader,
  customNoRowsOverlay: CustomNoRowsComponent,
  customPinnedRowRenderer: (props) => <AggregationFunctions agGrid={props} column={props.column} />,
  aggFunctionSelector: (props) => (
    <FunctionSelect
      agGrid={props}
      column={props.column}
      aggCellValue={props.value}
      isCellAggSelector={true}
    />
  ),
};

export const modifyRowDataAppearance = (rowData) => {
  const modifiedRowData = [];
  if (rowData) {
    Object.keys(rowData).forEach((key) => {
      modifiedRowData[key] = (rowData[key] + '').replaceAll('\\r\\n', '\n').replaceAll('\\t', '\t');
    });
  }
  return modifiedRowData;
};

export const filterContextMenuAction = (params, initialType) => {
  let type = initialType;
  const fieldType =
    params.column.colId === AG_GRID_AUTO_COLUMN
      ? params.node.rowGroupColumn.colDef.fieldType
      : params.column.colDef.fieldType;
  const filterType = getFilterType(fieldType);
  let filterValue = params.value;
  const columnId =
    params.column.colId === AG_GRID_AUTO_COLUMN ? params.node.field : params.column.colId;

  // have to check because ag-grid returns it as boolean type(ag-grid bug)
  if (params.value === true) {
    filterValue = 'true';
  }
  if (filterType === 'number') {
    filterValue = String(params.value);
  }
  if (fieldType === 'DateTime' || fieldType === 'DateTime64') {
    filterValue = Math.floor(new Date(params.value + 'Z').getTime() / 1000).toString();
  }

  if (fieldType === 'IPv4') {
    type = initialType === 'equalsAny' ? 'IPv4-Equals-Any' : 'IPv4-Not-Equals-Any';
  }

  if (fieldType === 'DateTime' || fieldType === 'DateTime64' || fieldType === 'Boolean') {
    type = initialType === 'equalsAny' ? 'equals' : 'notEqual';
  }

  if (!params.value) {
    type = initialType === 'equalsAny' ? 'isBlank' : 'isNotBlank';
  }

  return [
    {
      colId: columnId,
      type,
      filter: type.includes('Any') ? [filterValue] : filterValue,
      filterType,
    },
  ];
};

export const autoGroupColumnDef = {
  minWidth: 246,
  pinned: 'left',
  lockPosition: true,
  filter: true,
  cellRendererParams: {
    filter: true,
    suppressCount: true,
    innerRenderer: CustomAutoGroupValue,
  },
  cellRendererSelector: (params) => {
    if (params.api.columnModel.columnDefs.length === 1) {
      return { component: CustomAutoGroupValue };
    }
    return { component: 'agGroupCellRenderer' };
  },
};

export const calcNestedNodeId = (node, id) => {
  if (node.level > 0) {
    return calcNestedNodeId(node.parent, id + '_' + node.parent.key);
  } else {
    return id;
  }
};

export const getIndexForExpandedNode = (nestedNodeId, expandedNodes) => {
  for (let i = 0; i < expandedNodes.length; i += 1) {
    if (expandedNodes[i]['nestedNodeID'] === nestedNodeId) {
      return i;
    }
  }
  return -1;
};

export const expandedNodesHasId = (nestedNodeId, expandedNodes) => {
  return getIndexForExpandedNode(nestedNodeId, expandedNodes) > -1;
};

export const trackMixpanelColumnVisibility = () => {
  const { dispatch } = store;
  dispatch(
    addMixpanelEvent({
      eventName: 'Hide/Show Column',
      eventProps: {},
      userIncrementName: '# of Hide/Show Columns',
    })
  );
};

export const getGridRequestData = (agGrid) => {
  if (!agGrid) return;
  return agGrid.api.rowModel.datasource.state;
};
export const flagRows = async (params, flag, agGrid) => {
  const uuid = getFileUuidFromPath();

  if (params?.node?.group) {
    const agGridRequest = agGrid.api.rowModel.datasource.state;
    agGridRequest.groupKeys = [params.node.key];
    agGridRequest.rowGroupCols = agGridRequest.rowGroupCols.filter(
      (e) => e.id === params.node.field
    );
    const payload = {
      ag_grid_request: agGridRequest,
      flag: flag,
    };
    await put(`${URL_DATASET}/${uuid}/flag/query`, payload);
  } else {
    const payload = { flags: {} };
    payload.flags[params.node.data.A] = flag;
    await put(`${URL_DATASET}/${uuid}/flag`, payload);
  }

  agGrid.api.closeToolPanel();
  agGrid.api.deselectAll();
  agGrid.api.refreshServerSide();
};

const changeHeadersAndFirstRow = async (
  callback,
  mixpanelMessage,
  dispatch,
  agGrid,
  currentFile,
  setIsInsertingNewColumn
) => {
  const { isFileShared } = currentFile;
  const uuid = getFileUuidFromPath();

  if (!agGrid) return;
  if (isFileShared) {
    dispatch(showModal({ name: MODAL_MAKE_COPY }));
  }
  try {
    dispatch(setIsInsertingNewColumn(true));
    const result = await callback(uuid);

    if (!result.Success) {
      return store.dispatch(
        addToast({
          id: Date.now(),
          message: `${result.Message}`,
          type: 'error',
          wasShown: false,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        })
      );
    }

    await dispatch(fetchOpenedFile(uuid));
    dispatch(addMixpanelEvent({ eventName: `Set ${mixpanelMessage}` }));
    agGrid.api.refreshServerSide();
  } catch (error) {
    throw new Error(error);
  } finally {
    dispatch(setIsInsertingNewColumn(false));
  }
};

export const headerAsFirstRow = (dispatch, agGrid, currentFile) => {
  changeHeadersAndFirstRow(
    (uuid) => post(`${URL_DATASET}/${uuid}/headersasfirstrow`, {}, true),
    'header as first row',
    dispatch,
    agGrid,
    currentFile,
    setIsInsertingNewColumn
  );
};

export const firstRowAsHeader = (dispatch, agGrid, currentFile) => {
  changeHeadersAndFirstRow(
    (uuid) => put(`${URL_DATASET}/${uuid}/firstrowisheader`, {}, true),
    'first row as header',
    dispatch,
    agGrid,
    currentFile,
    setIsInsertingNewColumn
  );
};

export const handleDeleteDuplicates = async (columns) => {
  const uuid = getFileUuidFromPath();
  const { dispatch } = store;
  const state = store.getState();
  const {
    clientState: { sortModel },
  } = state.spreadsheet;

  try {
    dispatch(setIsInsertingNewColumn(true));

    const endpoint = `${URL_DATASET}/${uuid}`;
    const payload = {
      sortModel,
      columns,
    };
    const deleteDuplicates = await httpDeleteWithBody(endpoint, 'deduplicate-rows', payload);

    deleteDuplicates.Success
      ? dispatch(setIsDeletingRows(true))
      : store.dispatch(
          addToast({
            id: Date.now(),
            message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
            type: 'error',
            wasShown: false,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
            endpoint: endpoint,
            payload: payload,
          })
        );
  } catch (e) {
    throw new Error(e);
  } finally {
    dispatch(setIsInsertingNewColumn(false));
    dispatch(setIsDeletingRows(false));
  }
};

export const chartData = (agGrid) => {
  const cells = agGrid.api?.getCellRanges();

  const { endRow, startRow, columns } = cells?.[0] ?? {};

  agGrid.api.createRangeChart({
    chartType: 'stackedColumn',
    cellRange: {
      rowStartIndex: startRow?.rowIndex,
      rowEndIndex: endRow?.rowIndex,
      columns: columns?.map((i) => i.colId),
    },
    chartThemeOverrides: {
      common: {
        title: {
          enabled: true,
          text: 'Chart Editor',
        },
      },
    },
  });
};

export const addRow = async (rowCorrectingIndex = 0, agGrid) => {
  const cellRanges = agGrid?.api?.getCellRanges();
  const { topRowIndex, rowCount } = getTopRangeRowsIndexAndRowCount(cellRanges);

  const { dispatch } = store;
  const uuid = getFileUuidFromPath();

  dispatch(setIsInsertingNewColumn(true));

  const payload = {
    index: topRowIndex + 1 + rowCorrectingIndex,
    num_rows: rowCount,
  };

  try {
    const endpoint = `datasets/${uuid}/insert-blank-row`;
    const res = await post(`datasets/${uuid}/insert-blank-row`, payload);

    if (!res.Success) {
      return dispatch(
        addToast({
          id: Date.now(),
          message: `${res.Message}`,
          type: 'error',
          wasShown: false,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          endpoint: endpoint,
          payload: payload,
        })
      );
    }

    dispatch(
      addMixpanelEvent({
        eventName: 'Insert Blank Row',
        eventProps: { Location: 'Right click on row' },
        userIncrementName: '# of group by use',
      })
    );

    await dispatch(fetchOpenedFile(uuid));
  } catch (error) {
    throw new Error(error);
  } finally {
    agGrid.api.refreshServerSide();
    dispatch(setIsInsertingNewColumn(false));
  }
};

export const handleDeleteRow = async (rowsToDelete = '', requestBody = {}) => {
  const { dispatch } = store;
  const state = store.getState();
  const uuid = getFileUuidFromPath();
  const { selectedRow } = state.spreadsheet;
  const { currentFile } = state.dataset;
  if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
    return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
  }
  dispatch(setIsDeletingRows(true));

  const endpoint = `${URL_DATASET}/${uuid}`;
  const payload = rowsToDelete ? requestBody : { rows: [Number(selectedRow?.rowData?.A)] };
  const response = await httpDeleteWithBody(endpoint, `delete-rows${rowsToDelete}`, payload);

  if (response.Success) {
    // Remove filter after rows filtered/unfiltered rows are deleted
    if (rowsToDelete) {
      dispatch(setReduxFilterModelCnf([]));
    }
  } else {
    store.dispatch(
      addToast({
        id: Date.now(),
        message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        type: 'error',
        wasShown: false,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        endpoint: endpoint,
        payload: payload,
      })
    );
  }
  dispatch(setIsDeletingRows(false));
};

export const handleDeleteColumns = async (columnsToDelete = '', columnsToDeleteList = []) => {
  const { dispatch } = store;
  const {
    columnDefs,
    clientState: { columnState },
  } = store.getState().spreadsheet;
  const uuid = getFileUuidFromPath();

  if (
    columnsToDelete?.length > 0 &&
    [DELETE_VISIBLE_COLUMNS, DELETE_HIDDEN_COLUMNS, DELETE_SELECTED_COLUMNS].includes(
      columnsToDelete
    )
  ) {
    dispatch(setIsInsertingNewColumn(true));
    const hiddenColIds = columnState.filter((e) => e.hide).map((e) => e.colId);
    let visibleColIds = columnState.filter((e) => !e.hide).map((e) => e.colId);
    const selectedColId = columnState
      .filter((e) => columnsToDeleteList.some((el) => e.colId === el.colId))
      .map((e) => e.colId);

    // new or unmodified files have an empty column state, so we need to instead use the columnDefs
    if (!(columnState?.length > 0)) {
      visibleColIds = columnDefs.map((e) => e.colId);
    }

    const colIdsToDelete =
      columnsToDelete === DELETE_HIDDEN_COLUMNS
        ? hiddenColIds
        : columnsToDelete === DELETE_SELECTED_COLUMNS
        ? selectedColId
        : visibleColIds;

    // if exists, remove column A from colIdsToDelete
    const index = colIdsToDelete.indexOf(ROW_NUMBER_COLUMN_ID);
    if (index > -1) {
      colIdsToDelete.splice(index, 1);
    }

    if (colIdsToDelete.length > 0) {
      let endpoint = '';
      let payload = {};
      try {
        endpoint = `${FILES}/${uuid}/${DELETE_MULTIPLE_COLUMNS}`;
        payload = {
          columnsToDelete: colIdsToDelete,
        };
        const response = await postRaw(endpoint, payload);
        if (response.status === 200) {
          await dispatch(fetchOpenedFile(uuid));
          dispatch(deleteColumns(colIdsToDelete));
          dispatch(setReduxFilterModelCnf([]));
          dispatch(restoreGridState());
        } else {
          store.dispatch(
            addToast({
              id: Date.now(),
              message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
              type: 'error',
              wasShown: false,
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
              endpoint: endpoint,
              payload: payload,
            })
          );
        }
      } catch (e) {
        store.dispatch(
          addToast({
            id: Date.now(),
            message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
            type: 'error',
            wasShown: false,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
            endpoint: endpoint,
            payload: payload,
          })
        );
      }
    }
    dispatch(setIsInsertingNewColumn(false));
  }
};

export const deleteRow = (rowsToDelete, requestBody) => {
  const state = store.getState();
  const { dispatch } = store;
  const {
    clientState: { alwaysConfirmDeleteRow },
  } = state.spreadsheet;

  alwaysConfirmDeleteRow
    ? dispatch(showModal({ name: MODAL_DELETE_ROW, props: { rowsToDelete, requestBody } }))
    : handleDeleteRow(rowsToDelete, requestBody);
};

export const openFormulaPopover = ({ resetForm, mode, columnId, formulaToEdit }) => {
  const buttonElement = document.querySelector(QUERY_SELECTOR_FORMULA_PANEL_BTN);

  if (buttonElement) {
    store.dispatch(setNewColumnMode(mode || 'blank'));
    store.dispatch(setInsertNewColumnNextTo(columnId || null));
    store.dispatch(setResetFormulaForm(!!resetForm));

    if (formulaToEdit) {
      formulaToEdit['columnId'] = columnId;
      store.dispatch(setFormulaToEdit(formulaToEdit));
    }

    buttonElement.click();
  }
};

export const openHideColumnsPopover = () => {
  const buttonElement = document.querySelector('[data-cy="toolpanel-manage-columns"]');
  if (buttonElement) {
    buttonElement.click();
  }
};

export const generateDeletingRowsArray = async (params) => {
  const cellRanges = params.api.getCellRanges();
  const cellRange = {
    columns: [ROW_NUMBER_COLUMN_ID],
  };
  let rowData = [];
  params.api.forEachNode((node) => {
    if (node.group && node.expanded) {
      const groupNode = params.api.getRowNode(node.id);
      if (groupNode.childrenAfterFilter && Array.isArray(groupNode.childrenAfterFilter)) {
        groupNode.childrenAfterFilter.forEach((childNode) => {
          const rowDataItem = {};
          cellRange.columns.forEach((column) => {
            rowDataItem[column] = childNode.data[column];
            rowDataItem['rowIndex'] = childNode.rowIndex;
          });
          rowData.push(rowDataItem);
        });
      }
    } else if (!node.group && params.columnApi.getRowGroupColumns().length) {
      const rowDataItem = {};
      cellRange.columns.forEach((column) => {
        rowDataItem[column] = node.data[column];
        rowDataItem['rowIndex'] = node.rowIndex;
      });
      rowData.push(rowDataItem);
    }
  });

  if (!params.columnApi.getRowGroupColumns().length) {
    const selectedRanges = params.api.getCellRanges();
    const dataToDelete = [];
    selectedRanges.forEach((cellRange) => {
      const { startRow, endRow } = cellRange;
      const fromRowIndex = Math.min(startRow.rowIndex, endRow.rowIndex);
      const toRowIndex = Math.max(startRow.rowIndex, endRow.rowIndex);

      for (let rowIndex = fromRowIndex; rowIndex <= toRowIndex; rowIndex++) {
        const rowNode = params.api.getDisplayedRowAtIndex(rowIndex);
        if (rowNode) {
          dataToDelete.push(Number(rowNode.data.A));
        }
      }
    });
    rowData = dataToDelete;
  }
  if (Array.isArray(rowData) && rowData.length > 0 && typeof rowData[0] === 'object') {
    return rowData
      .filter((item) => generateArrayFromRowsIndex(cellRanges).includes(item.rowIndex))
      .map((el) => Number(el.A));
  } else {
    return rowData;
  }
};

export const handleDeleteRows = async (rows) => {
  const { dispatch } = store;
  dispatch(setIsInsertingNewColumn(true));
  const uuid = getFileUuidFromPath();

  dispatch(setIsDeletingRows(true));

  try {
    const endpoint = `${URL_DATASET}/${uuid}`;
    const payload = {
      rows,
    };
    const response = await httpDeleteWithBody(endpoint, 'delete-rows', payload);
    if (response.Success) {
      dispatch(setIsInsertingNewColumn(false));
    } else {
      store.dispatch(
        addToast({
          id: Date.now(),
          message: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
          type: 'error',
          wasShown: false,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          endpoint: endpoint,
          payload: payload,
        })
      );
      dispatch(setIsInsertingNewColumn(false));
    }
  } catch (e) {
    dispatch(setIsInsertingNewColumn(false));
  } finally {
    dispatch(setGenerateDeletingRows(false));

    dispatch(setIsDeletingRows(false));
  }
};

export const hasNoWritePermission = (currentFile) => {
  return !currentFile?.availablePermissions?.includes(PERMISSION_WRITE);
};

export const isBlankOptionVisible = (type, operator) => {
  return !(
    (type === 'Int64' ||
      type === 'UInt64' ||
      type === 'Float64' ||
      type === 'String' ||
      type === 'ZipCode' ||
      type === 'EmailAddress') &&
    (operator === 'equalsAny' || operator === 'notEqualsAny')
  );
};

export const generatePathForNode = (node) => {
  const path = [];
  while (node && node.key !== null) {
    path.unshift(node.key);
    node = node.parent;
  }
  return path;
};
const groupNodeFromNestedNodeID = (nestedNodeID) => {
  const stringPath = nestedNodeID.split('_');
  return stringPath.reverse();
};
export const convertExpandedNodesToGroupedNodeState = (clientState) => {
  if (!clientState.expandedNodes || clientState.expandedNodes.length === 0) {
    return {};
  }

  const groupedNodeState = {};
  const nodeDepths = {};

  if (clientState.columnState && clientState.columnState.length > 0) {
    clientState.columnState.forEach((col) => {
      if (col.rowGroup) {
        nodeDepths[col.colId] = col.rowGroupIndex;
      }
    });
  }

  clientState.expandedNodes.forEach((ex) => {
    const colId = ex.field;

    if (colId === undefined) {
      console.warn('Undefined colId encountered:', ex);
      return;
    }

    let convertedNode = null;
    if (nodeDepths[colId] !== undefined && nodeDepths[colId] === 0) {
      convertedNode = [ex.nestedNodeID];
    } else {
      convertedNode = groupNodeFromNestedNodeID(ex.nestedNodeID);
    }

    if (groupedNodeState[colId]) {
      groupedNodeState[colId].expandedNodes.push(convertedNode);
    } else {
      groupedNodeState[colId] = {
        defaultExpanded: false,
        expandedNodes: [convertedNode],
        collapsedNodes: [],
      };
    }
  });

  return groupedNodeState;
};

export const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const openSheetViewPopover = () => {
  setTimeout(() => {
    const element = document.getElementById('view-button');
    const button = element.querySelector('button');
    button.click();
  }, 500);
};
