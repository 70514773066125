import { useRef } from 'react';

import './style/index.scss';

const PlainToggleSwitch = ({
  isChecked,
  onChange,
  isDisabled,
  label,
  isHidden,
  cypressId,
  extraStyles,
  labelSize,
}) => {
  const checkboxRref = useRef(null);
  return (
    <div
      className={
        extraStyles ? `plain-toggle-button-frame ${extraStyles}` : 'plain-toggle-button-frame'
      }
      hidden={isHidden}
    >
      <div className='plain-toggle-button-switch' ref={checkboxRref}>
        <input
          type='checkbox'
          tabIndex='0'
          value={label}
          className='plain-toggle-button-checkbox'
          onChange={onChange}
          checked={isChecked}
          disabled={isDisabled}
          onFocus={() => checkboxRref.current?.classList.add('focused')}
          onBlur={() => checkboxRref.current?.classList.remove('focused')}
          data-cy={cypressId}
        />
        <div className='plain-toggle-button-layer'>
          <div className='plain-toggle-button-knob' />
          <div className={`plain-toggle-button-label ${labelSize}`}>{label}</div>
        </div>
      </div>
    </div>
  );
};

export default PlainToggleSwitch;
