import { Button } from '@/Components/UI/Button/Button';
import withModal from '@/Components/Modals/withModalHOC';

import { MODAL_DATA_CONNECTORS_REQUESTED } from '@/Utils/constants';

import { RequestedConnectorsSVG } from '@/Assets/icons';

const RequestSentBanner = ({ hideModal, body }) => {
  return (
    <div className='connectors-modal'>
      <div className='sent-request-container'>
        <img
          data-cy='send-request-img'
          src={RequestedConnectorsSVG.RequestedConnectors}
          alt='convert'
        />
        <p data-cy='send-request-title' className='sent-request-container-header'>
          We’ll be in touch!
        </p>
        <p data-cy='send-request-descr' className='mb-3 sent-request-container-text'>
          {body ||
            'Thank you for expressing your interest in our Enterprise features. We’ll reach out shortly with additional details.'}
        </p>
        <Button dataCy='send-request-dismiss' color='shadow' onClick={hideModal}>
          Dismiss
        </Button>
      </div>
    </div>
  );
};
export default withModal({ name: MODAL_DATA_CONNECTORS_REQUESTED, destroyOnHide: true })(
  RequestSentBanner
);
