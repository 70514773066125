export const formatIPElements = (format) => {
  return [
    {
      radioFormat: 'ipv4',
      checked: 'ipv4' === format,
      text: 'IPv4',
    },
    {
      radioFormat: 'ipv6',
      checked: 'ipv6' === format,
      text: 'IPv6',
    },
  ];
};
export const formatNumberElements = (format) => {
  return [
    {
      radioFormat: 'decimal',
      checked: 'decimal' === format,
      text: 'Decimal (1.1)',
    },
    {
      radioFormat: 'integer',
      checked: 'integer' === format,
      text: 'Integer (2)',
    },
  ];
};
