export const getTypeIconPath = (columnType) => {
  switch (columnType) {
    case 'DateTime':
    case 'DateTime64': {
      return 'calendar-blank';
    }
    case 'String': {
      return 'text-t';
    }
    case 'ZipCode': {
      return 'map-pin-line';
    }
    case 'EmailAddress': {
      return 'envelope-simple';
    }
    case 'Int64':
    case 'UInt64':
    case 'Float64':
    case 'Boolean': {
      return 'hash-straight';
    }
    case 'IPv4': {
      return 'laptop';
    }
    case 'URL': {
      return 'link-simple-horizontal';
    }
    case 'metadata_tag': {
      return 'flag-banner';
    }
    case 'Add': {
      return 'plus';
    }
    case 'Subtract': {
      return 'minus';
    }
    case 'Divide': {
      return 'divide';
    }
    case 'Multiply': {
      return 'x';
    }
    case 'PowerOf': {
      return 'number-square-two'; // placeholder
    }
    case 'Folder': {
      return 'folder';
    }
    case 'Function': {
      return 'function';
    }
    case 'Iterator': {
      return 'list-ordered';
    }
    case 'Group': {
      return 'group';
    }
    default: {
      return 'magnifying-glass';
    }
  }
};
