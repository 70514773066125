import Icon from '@/Components/UI/Icon/Icon';
import React, { useState } from 'react';
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

interface Props {
  data: any;
}

const CopyableJsonView = ({ data }: Props) => {
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data, null, 2))
      .then(() => {
        setShowCopiedMessage(true);
        setTimeout(() => setShowCopiedMessage(false), 2000); // Hide the message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err));
  };

  return (
    <div
      className='overflow-scroll min-h-[250px] max-h-[420px] relative'
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
    >
      {showCopyIcon && (
        <button
          onClick={handleCopy}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
          }}
          title='Copy to clipboard'
        >
          {showCopiedMessage ? 'Copied' : <Icon name='copy' size={20} color='#151515' />}
        </button>
      )}
      <JsonView data={data} shouldExpandNode={allExpanded} style={defaultStyles} />
    </div>
  );
};

export default CopyableJsonView;
