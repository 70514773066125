/**
 * This module provides a custom hook `useAuth0SilentRefresh` for handling the silent refresh of Auth0 ID tokens.
 *
 * The hook leverages the Auth0 React SDK to periodically check the expiration of the ID token and refresh it
 * if it is about to expire in less than 10 minutes. It updates the Redux store with the new token.
 *
 * The `silentRefresh` function:
 * - Retrieves the current ID token using `getIdTokenClaims`.
 * - Decodes the token using `jwt-decode` to inspect its `exp` (expiration) field.
 * - Checks the current time against the token's expiration time.
 * - If the token is set to expire in less than 10 minutes, it forces a refresh of the token by calling `getAccessTokenSilently` and `getIdTokenClaims` with `ignoreCache: true`.
 * - Dispatches the new token to the Redux store by calling setAuth0IdToken.
 * - Waits for the Redux state to update with the new token before resolving.
 */

import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setAuth0IdToken } from '@/redux/reducers/authenticationSlice';
import { jwtDecode } from 'jwt-decode';
import { store } from '@/redux/store';
import { authenticationSliceName } from '@/redux/constants';

const useAuth0SilentRefresh = () => {
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const waitForStateUpdate = (selector, value) => {
    return new Promise((resolve) => {
      const unsubscribe = store.subscribe(() => {
        if (selector(store.getState()) === value) {
          unsubscribe();
          resolve();
        }
      });
    });
  };

  const silentRefresh = async (force = false) => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const token = idTokenClaims.__raw;
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const tenMinutesInSeconds = 10 * 60;

      if (force || decodedToken.exp - currentTime < tenMinutesInSeconds) {
        // Refresh the token if it's about to expire in less than 10 minutes
        await getAccessTokenSilently({ ignoreCache: true });
        const newIdTokenClaims = await getIdTokenClaims({ ignoreCache: true });
        const newAuth0IdToken = newIdTokenClaims.__raw;

        // Dispatch the new token and wait for state update
        dispatch(setAuth0IdToken(newAuth0IdToken));
        await waitForStateUpdate(
          (state) => state[authenticationSliceName].auth0IdToken,
          newAuth0IdToken
        );
      }
    } catch (error) {
      console.error('Silent token refresh failed:', error);
    }
  };

  return { silentRefresh };
};

export default useAuth0SilentRefresh;
