import {
  FILE,
  FILTER,
  FIND_AND_REPLACE,
  MODAL_FIND_AND_REPLACE_CONFIRM,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '@/Utils/constants';
import withModal from '../withModalHOC';

import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { useDispatch } from 'react-redux';
import { setReduxFilterModelCnf, setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import parseArrayToMatrix from '@/Utils/parseArrayToMatrix';
import { post } from '@/Utils/API';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { hideAllModals } from '@/redux/reducers/modalsSlice';
import showToast from '@/Components/Toast/showToastTemplate';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { useState, useEffect } from 'react';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';

const FindAndReplaceConfirmPopup = ({
  hideModal,
  show,
  filterModelCnf,
  requestBody,
  getGridRequestData,
  columnName,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfMatches, setNumberOfMatches] = useState(0);
  const uuid = getFileUuidFromPath();

  useEffect(() => {
    const getFilteredRows = async () => {
      setIsLoading(true);
      let endpoint = '';
      let payload = {};
      try {
        endpoint = `${FILE}/${uuid}/${FILTER}`;
        payload = {
          ...getGridRequestData,
          filterModel: { ...getGridRequestData.filterModel, _cnf_: [[filterModelCnf]] },
        };
        const { lastRow } = await post(endpoint, payload, true);
        setNumberOfMatches(lastRow);
      } catch (error) {
        hideModal();
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
          errorContext: error,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
        throw new Error();
      } finally {
        setIsLoading(false);
      }
    };

    if (show) {
      getFilteredRows();
    }
  }, [show]);

  const onReviewMatchesButtonClick = () => {
    dispatch(setReduxFilterModelCnf(parseArrayToMatrix([filterModelCnf])));
  };

  const onReplaceAllButtonClick = async () => {
    dispatch(hideAllModals());
    dispatch(setIsInsertingNewColumn(true));

    try {
      const endpoint = `${URL_DATASET}/${uuid}/${FIND_AND_REPLACE}`;
      const result = await post(endpoint, requestBody, true);
      if (!result.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
          errorContext: result,
          endpoint: endpoint,
          payload: requestBody,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }
      await dispatch(fetchOpenedFile(uuid));
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const closeModal = () => {
    dispatch(setReduxFilterModelCnf(parseArrayToMatrix([])));
    hideModal();
  };

  return (
    <Modal
      title='Replace Matches?'
      size='small'
      isOpen={show}
      onClose={closeModal}
      transparentOverlay
    >
      {isLoading ? (
        <SkeletonLoader header={false} loadingBars={2} />
      ) : (
        <>
          <div className='text-sm'>
            This will replace all
            <span
              data-cy='count-matches'
              className='font-semibold'
            >{` ${numberOfMatches} matches `}</span>
            found in
            <span data-cy='column-name' className='font-semibold'>{` Column ${columnName} `}</span>
            and cannot be reversed. Are you sure?
          </div>
          <div className='flex flex-row justify-end gap-4 py-4 mt-6 text-right border-t border-ui-200 '>
            <Button variant='ghost' color='shadow' dataCy='cancel-btn' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color='shadow'
              dataCy='review-btn'
              onClick={onReviewMatchesButtonClick}
              disabled={false}
            >
              Review Matches
            </Button>
            <Button
              color='oceanBlue'
              dataCy='save-btn'
              onClick={onReplaceAllButtonClick}
              disabled={false}
            >
              Replace All
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default withModal({ name: MODAL_FIND_AND_REPLACE_CONFIRM })(FindAndReplaceConfirmPopup);
