import { useEffect, useState } from 'react';

import {
  flagOptions,
  getColumnsMap,
  getFilterConditions,
  onColumnFilterTypeChange,
  onColumnIdChange,
} from '@/helpers/filterPanelHelper';
import {
  prettyPrintCondition,
  prettyPrintDateCondition,
  prettyPrintFlagLabel,
} from '@/Utils/filterConditions';
import { getFilterType } from '@/Utils/getFilterType';

import Calendar from './FilterPanelCalendar';
import withFilterPanel from './withFilterPanelHOC';
import { Button } from '@/Components/UI/Button/Button';
import FilterPanelMultiValue from './FilterPanelMultiValue';
import { Select } from '@/Components/UI/Form/Select/Select';
import { orEndSelectorOptions } from '../IfCondition/constants';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import FilterPanelMultiValueSetFilter from './FilterPanelMultiValueSetFilter';
import PlainToggleSwitch from '@/Components/PlainToggleSwitch/PlainToggleSwitch';
import CustomToggleSwitch from '@/Components/CustomToggleSwitch/CustomToggleSwitch';
import InRange from '@/Components/Modals/FilterPanel/FilterPanelInRange';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';

const FilterPanelOrClause = ({
  filterModelElement,
  index,
  onFilterChange,
  onChangeFilterType,
  setIsDisabledApplyButton,
  modalBodyScrollTop,
  setAnyFilterData,
  isMultiValueEnabled,
  onRemove,
  blankConditions,
  filterOptions,
  ifCondition,
  ifGroupIndex,
  filterModel,
  searchSelectPopupIcon,
  isValidConditions,
}) => {
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const columns = getColumnsMap(columnDefs);
  const [isWrongRegexValue, showRegexValidation] = useState(false);
  const [isInputWithSuggestions, setIsInputWithSuggestions] = useState(false);

  const { maxFilters } = user.userProperties;

  const { colId, isChecked, type, filter, filterTo, isCaseSensitive } = filterModelElement;
  const currFieldType = columns[colId]?.fieldType;
  const filterValueSelector = filter || '';

  const selectedColumn = columns[colId] || null;
  const isFirstFilter = index === 0;
  const conditions = getFilterConditions(colId, currFieldType).map((el) => ({
    value: el,
    label:
      selectedColumn?.fieldType === 'DateTime' || selectedColumn?.fieldType === 'DateTime64'
        ? prettyPrintDateCondition(el)
        : prettyPrintCondition(el),
  }));

  const columnsOptions = Object.values(columns).map((el) => ({
    ...el,
    value: el.colId,
    label: el.name || el.colId,
    type: el.fieldType,
  }));

  const onChangeModelKeyValue = (key, modelValue) => {
    let _filterModelElement = { ...filterModelElement };
    if (key === 'colId') {
      const filterType = getFilterType(columns[modelValue].fieldType);
      _filterModelElement = { ...filterModelElement, filterType };

      onColumnIdChange(
        key,
        index,
        colId,
        currFieldType,
        filterType,
        columns,
        modelValue,
        _filterModelElement,
        showRegexValidation,
        onFilterChange,
        ifGroupIndex
      );
    } else if (key === 'type') {
      const filterType = getFilterType(columns[colId].fieldType);
      _filterModelElement = { ...filterModelElement, filterType };
      onColumnFilterTypeChange(
        key,
        index,
        modelValue,
        _filterModelElement,
        onFilterChange,
        ifGroupIndex
      );
    } else {
      onFilterChange(
        index,
        {
          ..._filterModelElement,
          [key]: modelValue,
        },
        ifGroupIndex
      );
    }
  };

  const onCaseSensitiveChangeEvent = (event) => {
    onFilterChange(
      index,
      {
        ...filterModelElement,
        isCaseSensitive: event.target.checked,
      },
      ifGroupIndex
    );
  };
  const getFilterColumnLabel = (currentColumn, columns) => {
    if (Object.values(columns).every((column) => !column.name)) {
      return currentColumn.colId;
    }
    return currentColumn.name && currentColumn.name.length > 0
      ? currentColumn.name
      : `${currentColumn.colId} (no header)`;
  };

  const getSelectValue = (isChecked) => {
    return orEndSelectorOptions.find((el) => el.value === isChecked).value;
  };

  const isComponentDisabled = maxFilters <= index;

  useEffect(() => {
    if (!type) return;

    setIsInputWithSuggestions(type.includes('Any') && colId !== '*');
  }, [type, colId]);

  useEffect(() => {
    if (type === 'regularExpression') {
      try {
        new RegExp(filter);
        showRegexValidation(false);
        onFilterChange(
          index,
          {
            ...filterModelElement,
            isValidRegex: true,
          },
          ifGroupIndex
        );
      } catch (e) {
        showRegexValidation(true);
        onFilterChange(
          index,
          {
            ...filterModelElement,
            isValidRegex: false,
          },
          ifGroupIndex
        );
      }
    }
  }, [filter, type]);

  return (
    <div
      className={clsx(
        'filter-row',
        isChecked && !isFirstFilter && !ifCondition && 'filter-row-checked',
        ifCondition && '!justify-start !mb-3',
        isComponentDisabled && 'relative'
      )}
    >
      <span
        className={clsx(isComponentDisabled && '!cursor-not-allowed absolute w-full h-full z-10')}
      />
      {isFirstFilter ? (
        <h4 className={ifCondition && '!w-16 !m-0 text-center'}>{ifCondition || 'Where'}</h4>
      ) : ifCondition ? (
        <div className={clsx('w-16')}>
          <Select
            onChange={(e) => onChangeFilterType(index, ifGroupIndex, ifCondition && e)}
            options={orEndSelectorOptions}
            value={getSelectValue(isChecked)}
            disabled={isComponentDisabled}
          />
        </div>
      ) : (
        <CustomToggleSwitch
          onChange={() => onChangeFilterType(index)}
          isChecked={isChecked}
          switcher='AndOrSwitcher'
          isDisabled={isComponentDisabled}
        />
      )}
      <div className={clsx(ifCondition ? 'select-filter_wrapper !ml-4' : 'select-filter_wrapper')}>
        <SearchSelect
          defaultValue={selectedColumn}
          options={columnsOptions}
          onChange={(newValue) => {
            onChangeModelKeyValue('colId', newValue.value);
          }}
          filterOptions={filterOptions}
          getOptionLabel={(option) => getFilterColumnLabel(option, columns)}
          maxWidth={160}
          ifCondition={ifCondition}
          popupIcon={searchSelectPopupIcon}
          isValidConditions={isValidConditions}
          isDisabled={isComponentDisabled}
          className='!w-[29%]'
        />
        <SearchSelect
          placeholder='Operator'
          isDisabled={!colId || isComponentDisabled}
          defaultValue={colId ? type : ''}
          options={conditions}
          onChange={(newValue) => onChangeModelKeyValue('type', newValue.value)}
          getOptionLabel={(option) =>
            option.label ||
            (currFieldType === 'DateTime' || currFieldType === 'DateTime64'
              ? prettyPrintDateCondition(option)
              : prettyPrintCondition(option))
          }
          ifCondition={ifCondition}
          popupIcon={searchSelectPopupIcon}
          isValidConditions={isValidConditions}
          className='!w-[29%]'
        />
        {currFieldType !== 'Boolean' &&
          currFieldType !== 'DateTime' &&
          currFieldType !== 'DateTime64' &&
          currFieldType !== 'metadata_tag' &&
          type !== 'inRange' &&
          !blankConditions && (
            <div className={ifCondition ? 'w-40 static' : 'w-56'}>
              {isInputWithSuggestions ? (
                <FilterPanelMultiValueSetFilter
                  cypressTextFieldName={'filter-panel-input'}
                  defaultValue={colId ? filter : ''}
                  onChange={(newValue) => onChangeModelKeyValue('filter', newValue)}
                  disabled={!type || isComponentDisabled}
                  currFieldType={currFieldType}
                  colId={colId}
                  modalBodyScrollTop={modalBodyScrollTop}
                  ifCondition={ifCondition}
                  isValidConditions={isValidConditions}
                  operatorType={type}
                />
              ) : (
                <FilterPanelMultiValue
                  type={type}
                  cypressTextFieldName={'filter-panel-input'}
                  showRegexInvalidBlock={isWrongRegexValue}
                  setIsDisabledApplyButton={setIsDisabledApplyButton}
                  isMultiple={isMultiValueEnabled}
                  disabled={!type || isComponentDisabled}
                  defaultValue={colId ? filter : ''}
                  currFieldType={currFieldType}
                  onChange={(newValue) => onChangeModelKeyValue('filter', newValue)}
                  indexInFilterModel={index}
                  setAnyFilterData={setAnyFilterData}
                  ifCondition={ifCondition}
                  isValidConditions={isValidConditions}
                  isBlurAction={ifCondition}
                />
              )}
            </div>
          )}
        {currFieldType === 'Boolean' && !blankConditions && (
          <SearchSelect
            placeholder='Value'
            cypressTextFieldName={'filter-panel-input'}
            isDisabled={!colId || isComponentDisabled}
            options={[
              { value: 'true', label: 'true' },
              { value: 'false', label: 'false' },
            ]}
            defaultValue={colId ? { value: filterValueSelector } : ''}
            filterOptions={filterOptions}
            onChange={(newValue) => onChangeModelKeyValue('filter', newValue.value)}
            getOptionLabel={(option) => option.value}
            boolFilterPanelStyles={true}
            ifCondition={ifCondition}
            popupIcon={searchSelectPopupIcon}
            isValidConditions={isValidConditions}
          />
        )}
        {currFieldType === 'metadata_tag' && (
          <SearchSelect
            placeholder='Value'
            cypressTextFieldName={'filter-panel-input'}
            isDisabled={!colId || isComponentDisabled}
            options={flagOptions}
            defaultValue={colId ? filterValueSelector : ''}
            onChange={(newValue) => onChangeModelKeyValue('filter', newValue.value)}
            getOptionLabel={(option) => option.label || prettyPrintFlagLabel(option)}
            ifCondition={ifCondition}
            popupIcon={searchSelectPopupIcon}
            isValidConditions={isValidConditions}
          />
        )}
        {(currFieldType === 'DateTime' || currFieldType === 'DateTime64') && !blankConditions && (
          <Calendar
            onChange={onChangeModelKeyValue}
            defaultStartValue={filter}
            defaultEndValue={filterTo}
            id={index}
            modelType={type}
            ifCondition={ifCondition}
            isValidConditions={isValidConditions}
            disabled={isComponentDisabled}
          />
        )}
        {type === 'inRange' &&
          currFieldType !== 'DateTime' &&
          currFieldType !== 'DateTime64' &&
          !blankConditions && (
            <InRange
              onChange={onChangeModelKeyValue}
              defaultStartValue={filter}
              defaultEndValue={filterTo}
              id={index}
              modelType={type}
              indexInFilterModel={index}
              ifCondition={ifCondition}
              isValidConditions={isValidConditions}
              type={type}
              cypressTextFieldName={'filter-panel-input'}
              isWrongRegexValue={isWrongRegexValue}
              setIsDisabledApplyButton={setIsDisabledApplyButton}
              isMultiValueEnabled={isMultiValueEnabled}
              disabled={!type || isComponentDisabled}
              defaultValue={colId ? filter : ''}
              currFieldType={currFieldType}
              setAnyFilterData={setAnyFilterData}
              colId={colId}
              filter={filter}
              filterTo={filterTo}
            />
          )}
        {!blankConditions && isInputWithSuggestions && (
          <PlainToggleSwitch
            onChange={onCaseSensitiveChangeEvent}
            isChecked={isCaseSensitive || false}
            label='Match Case'
            isHidden={currFieldType !== 'String'}
            ifCondition={ifCondition}
            extraStyles='!m-0 !p-0 !w-8 !text-xs'
            labelSize={ifCondition ? '!text-[13px] !leading-none pt-2' : ''}
            isDisabled={isComponentDisabled}
          />
        )}
      </div>

      {filterModel.length === 1 ? null : (
        <Button
          onClick={onRemove}
          variant='ghost'
          size={ifCondition ? 'medium' : 'large'}
          dataCy='remove-btn'
          iconName='trash'
          color='violetWeb'
          className='z-20'
        />
      )}
    </div>
  );
};

export default withFilterPanel(FilterPanelOrClause);
