import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { combineFilesSliceName, datasetSliceName } from '@/redux/constants';
import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import {
  COMBINE_FILES_USING_COLUMN_HEADERS,
  COMBINE_FILES_USING_COLUMN_ORDER,
} from '@/Utils/constants';
import {
  setNewSheetName as reduxSetNewSheetName,
  setCombineMethod as reduxSetCombineMethod,
  setAddUnmatchedColumns,
  setTrimColumnNames,
  setCaseSensitive,
} from '@/redux/reducers/combineFilesSlice';
import Icon from '@/Components/UI/Icon/Icon';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import RadioInput from '@/Components/UI/Form/RadioInput/RadioInput';
import ToggleSwitchInput from '@/Components/UI/Form/ToggleSwitchInput/ToggleSwitchInput';

const Label = ({ children, className = '' }) => (
  <span className={`text-[#151515] font-semibold text-[14px] inline-block mb-[10px] ${className}`}>
    {children}
  </span>
);

const CombineFilesInfoPanel = () => {
  const dispatch = useDispatch();
  const { selectedFiles } = useSelector((state) => state[datasetSliceName]);
  const {
    newSheetName: reduxNewSheetName,
    addUnmatchedColumns,
    trimColumnNames,
    caseSensitive,
  } = useSelector((state) => state[combineFilesSliceName]);
  const [combineMethod, setCombineMethod] = useState(COMBINE_FILES_USING_COLUMN_ORDER);
  const [newSheetName, setNewSheetName] = useState(reduxNewSheetName);
  const [checkboxStates, setCheckboxStates] = useState({
    addUnmatchedColumns: addUnmatchedColumns,
    trimColumnNames: trimColumnNames,
    caseSensitive: caseSensitive,
  });

  const fileNameInputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => fileNameInputRef.current.focus(), 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    dispatch(reduxSetNewSheetName(newSheetName));
  }, [newSheetName]);

  useEffect(() => {
    dispatch(setAddUnmatchedColumns(checkboxStates.addUnmatchedColumns));
    dispatch(setTrimColumnNames(checkboxStates.trimColumnNames));
    dispatch(setCaseSensitive(checkboxStates.caseSensitive));
  }, [checkboxStates]);

  useEffect(() => {
    dispatch(reduxSetCombineMethod(combineMethod));
  }, [combineMethod]);

  useEffect(() => {
    setNewSheetName(reduxNewSheetName);
  }, [reduxNewSheetName]);

  const validFiles = selectedFiles.filter(
    ({ IsDirectory, Status, Type }) =>
      !IsDirectory && Status !== FILE_STATUS_ERROR && Type !== FILE_TYPE_EXPORTER
  );

  const handleCheckboxChange = (checkboxId) => {
    setCheckboxStates((prevStates) => ({ ...prevStates, [checkboxId]: !prevStates[checkboxId] }));
  };

  const toggleOptions = [
    {
      label: 'Add Unmatched Columns',
      tooltip: 'Add any unmatched source sheet columns as new columns in the combined sheet.',
      id: 'addUnmatchedColumns',
      value: () => checkboxStates.addUnmatchedColumns,
    },
    {
      label: 'Trim Column Names',
      tooltip: 'Ignore leading and trailing spaces in column names.',
      id: 'trimColumnNames',
      value: () => checkboxStates.trimColumnNames,
    },
    {
      label: 'Case Sensitive',
      tooltip: 'Ignore letter case in column names.',
      id: 'caseSensitive',
      value: () => checkboxStates.caseSensitive,
    },
  ];

  const getRadioOptionTooltip = (text) => {
    return (
      <Tooltip text={text} side='right'>
        <Icon name='info' color='shadow' variant='ghost' size={20} className='ml-3' />
      </Tooltip>
    );
  };

  const radioOptionLabelCss = 'text-[14px] dashed-underscore cursor-pointer';

  const radioOptions = [
    {
      label: 'Column Order',
      labelCss: radioOptionLabelCss,
      value: COMBINE_FILES_USING_COLUMN_ORDER,
      dataCy: 'radio-column-order',
      children: getRadioOptionTooltip('Columns combined based on matching order'),
    },
    {
      label: 'Column Headers',
      labelCss: radioOptionLabelCss,
      value: COMBINE_FILES_USING_COLUMN_HEADERS,
      dataCy: 'radio-column-headers',
      children: getRadioOptionTooltip('Combined based on matching column names'),
    },
  ];

  return (
    <div className='files-info-panel'>
      <TextInput
        label='New Sheet Name'
        dataCy='combined-file-name'
        ref={fileNameInputRef}
        value={newSheetName}
        onChange={(event) => {
          setNewSheetName(event.target.value);
        }}
        className='border rounded w-full h-[30px] inline-block mb-[30px] pr-2.5 border-ui pl-2.5 outline-none !text-sm'
      />

      <div className='mb-2'>
        <RadioInput
          label='Combine Files Using'
          options={radioOptions}
          value={combineMethod}
          horizontalLayout={false}
          onChange={(event) => setCombineMethod(event.target.value)}
        />
      </div>

      {combineMethod === COMBINE_FILES_USING_COLUMN_HEADERS && (
        <div className='checkboxes'>
          <div className='flex flex-col space-y-2 mt-3 ml-1'>
            {toggleOptions.map((option) => (
              <ToggleSwitchInput
                key={option.id}
                value={option.value()}
                name={option.id}
                dataCy={option.id}
                onChange={() => handleCheckboxChange(option.id)}
                label={option.label}
                labelCss='ml-3 text-sm dashed-underscore cursor-pointer'
                labelOnLeftSide={false}
                size='small'
                additionalStyles='cursor-pointer'
              >
                <Tooltip text={option.tooltip} className='w-64' side='right'>
                  <Icon name='info' color='shadow' variant='ghost' size={20} className='ml-3' />
                </Tooltip>
              </ToggleSwitchInput>
            ))}
          </div>
        </div>
      )}

      <div className='files-info-panel-selected-files-panel'>
        <Label className='uppercase mt-4'>Selected Files</Label>
        <div className='files-info-panel-selected-files'>
          {validFiles.map(({ FileName, FileUuid }) => (
            <div key={FileUuid} className='files-info-panel-file'>
              {FileName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CombineFilesInfoPanel;
