import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Mixpanel } from '@/Mixpanel';
import { showModal } from '@/redux/reducers/modalsSlice';

import { post } from '@/Utils/API';
import { COPY, FILE, MODAL_MAKE_COPY, MODAL_MAKE_COPY_SUCCESS } from '@/Utils/constants';

import withModal from '@/Components/Modals/withModalHOC';

import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import './style/index.scss';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const MakeCopyPopup = ({ show, hideModal }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();

  const [isLoading, setIsLoading] = useState(false);

  const { FileUuid: uuid } = currentFile?.metadata || {};

  const createCopy = async () => {
    if (uuid !== null) {
      setIsLoading(true);
      const result = await post(`${FILE}/${uuid}/${COPY}`);
      hideModal();
      setIsLoading(false);
      Mixpanel.track('User Saves A Shared File to My Files', {
        file_uuid: uuid,
      });

      if (result.Success) {
        dispatch(
          showModal({
            name: MODAL_MAKE_COPY_SUCCESS,
            props: {
              uuid: result.NewHandle,
            },
          })
        );
      }
    }
  };

  return (
    <Modal title='View Only' isOpen={show} onClose={hideModal} iconName='pencil-simple-slash'>
      <p className='mt-[-10px]' data-cy='make-a-copy-modal'>
        You can only view and filter this sheet. To make changes save a copy to your account.
      </p>
      <div className='flex items-end justify-end w-full gap-2 mt-4'>
        <Button variant='ghost' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' onClick={createCopy} disabled={isLoading}>
          {isLoading ? 'Saving' : 'Save a Copy'}
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_MAKE_COPY })(MakeCopyPopup);
