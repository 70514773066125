import { Button, Link } from '@/Components/UI/Button/Button';
import { GIGASHEET_API_SUPPORT, LINK_ENTERPRISE_CALENDLY } from '@/Utils/constants';
import { manageBilling } from '@/helpers/settingsHelper';

export const teamMemberUpgrade = () => {
  return (
    <div className='h-10 px-6 text-sm text-ui-secondary'>
      Contact your team’s adminstrator to request an upgrade for access.
    </div>
  );
};

export const premiumFeatureUpgradeStorage = (usedStorage, maxStorageSize) => {
  return (
    <div className='h-16 px-6 text-sm text-ui-secondary'>
      <span className='italic font-bold'>
        You’re using {usedStorage} of storage which exceeds your account storage limit of{' '}
        {maxStorageSize}.
      </span>{' '}
      Upgrade to an account with more storage or delete some files from your Library to restore full
      functionality.
    </div>
  );
};

export const premiumFeatureCombineFiles = () => {
  return (
    <div className='h-16 px-6 text-sm text-ui-secondary'>
      <span className='italic font-bold'>
        Files cannot be combined as your account’s storage limits have been exceeded.
      </span>{' '}
      Upgrade to an account with more storage or delete some files from your Library to restore full
      functionality.
    </div>
  );
};

export const premiumFeatureSheetPreview = (currentFileSize, maxStorageSize) => {
  return (
    <div className='h-16 px-6 text-sm text-ui-secondary'>
      <span className='italic font-bold'>
        This file is {currentFileSize} and is restricted to a limited preview as it exceeds your
        account storage limit of {maxStorageSize}.
      </span>{' '}
      For full sheet control upgrade to an account with storage limit or delete some files from your
      Library.
    </div>
  );
};

export const premiumFeatureFooter = (onClose) => {
  return (
    <div className='flex justify-end w-full h-12 px-7'>
      <Button onClick={onClose} variant='ghost'>
        Dismiss
      </Button>
    </div>
  );
};

export const generalUpgradeFooter = (hasBillingId, manageBillingModal, requestTrial) => {
  return (
    <>
      {hasBillingId ? (
        <div className='flex flex-row justify-end'>
          <div className='h-16 w-[700px] flex justify-center gap-48'>
            <Button onClick={manageBilling} className='w-40' color='midnight'>
              Manage Subscription
            </Button>
            <Link
              to={LINK_ENTERPRISE_CALENDLY}
              target='__blank'
              size='large'
              onClick={requestTrial}
              className='flex items-center justify-center w-32 h-8 px-3 text-sm rounded !text-ui bg-sunrise-500 hover:bg-sunrise-600 active:bg-sunrise-700'
            >
              Request Trial
            </Link>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center'>
          <div className='flex flex-row justify-center w-full gap-56 h-14'>
            <div className='font-semibold text-center w-28 text-midnight-500'>Your Plan</div>
            <Button onClick={manageBillingModal} className='w-28' color='sunrise'>
              Upgrade Now
            </Button>
            <Link
              to={LINK_ENTERPRISE_CALENDLY}
              target='__blank'
              size='large'
              onClick={requestTrial}
              className='flex items-center justify-center w-28 h-8 px-3 text-sm rounded !text-ui bg-sunrise-500 hover:bg-sunrise-600 active:bg-sunrise-700'
            >
              Request Trial
            </Link>
          </div>
          <div className='h-10'>
            <Link
              to={GIGASHEET_API_SUPPORT}
              size='medium'
              target='__blank'
              className='font-semibold underline'
            >
              Need to generate an API key?
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export const GENERAL_UPGRADE_TITLE = 'You could use an upgrade';
export const TEAM_MEMBER_UPGRADE_TITLE = 'Account Upgrade Required';
export const TEAM_WORKSPACE_UPGRADE_TITLE = 'Add a Team Workspace on the Business Plan';

export const upgradeHandlerByType = (
  hasBillingId = false,
  usedStorage = '',
  currentFileSize = '',
  maxStorageSize = '',
  manageBillingModal = () => {},
  requestTrial = () => {},
  onClose = () => {}
) => {
  return {
    general: {
      title: GENERAL_UPGRADE_TITLE,
      align: 'center',
      subhead: null,
      footer: generalUpgradeFooter(hasBillingId, manageBillingModal, requestTrial),
    },
    teamMember: {
      title: TEAM_MEMBER_UPGRADE_TITLE,
      align: 'left',
      subhead: teamMemberUpgrade(),
      footer: premiumFeatureFooter(onClose),
    },
    teamWorkspace: {
      title: TEAM_WORKSPACE_UPGRADE_TITLE,
      align: 'left',
      subhead: null,
      footer: generalUpgradeFooter(hasBillingId, manageBillingModal, requestTrial),
    },
    combineFiles: {
      title: GENERAL_UPGRADE_TITLE,
      align: 'left',
      subhead: premiumFeatureCombineFiles(),
      footer: premiumFeatureFooter(onClose),
    },
    storageSize: {
      title: GENERAL_UPGRADE_TITLE,
      align: 'left',
      subhead: premiumFeatureUpgradeStorage(usedStorage, maxStorageSize),
      footer: premiumFeatureFooter(onClose),
    },
    fileSize: {
      title: GENERAL_UPGRADE_TITLE,
      align: 'left',
      subhead: premiumFeatureSheetPreview(currentFileSize, maxStorageSize),
      footer: premiumFeatureFooter(onClose),
    },
  };
};
