import { forwardRef, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';

import { stylesSearchSelect, stylesSearchSelectNewDesign } from '@/helpers/filterPanelHelper';

import { getTypeIconPath } from '@/Utils/getTypeIconPath';

import Icon from '@/Components/UI/Icon/Icon';

import './style/index.scss';

const SearchSelect = forwardRef(
  (
    {
      closeOnScroll,
      scrollTarget,
      freeSolo,
      classes,
      options,
      allOptionsList,
      setMenuIsOpen,
      onChange,
      defaultValue,
      defaultMultiValues,
      placeholder = 'Column',
      isDisabled,
      filterOptions,
      getOptionLabel,
      boolFilterPanelStyles,
      error,
      additionalClasses,
      className,
      helperText,
      isMultiple,
      blurOnSelect = false,
      cypressTextFieldName = '',
      noOptionsText,
      canDelete,
      handleDelete,
      disableCloseOnSelect = false,
      maxWidth = 243,
      popupIcon,
      isSmall = false,
      onBlur,
      onClose = () => {},
      ifCondition,
      isValidConditions = true,
      openOnFocus = false,
    },
    searchSelectForwardedRef
  ) => {
    const [value, setValue] = useState(defaultValue || null);
    const [multiValues, setMultiValues] = useState(defaultMultiValues || []);
    const [isOpen, setIsOpen] = useState(false);
    const [disableHover, setDisableHover] = useState(false);
    // eslint-disable-next-line
    const searchSelectRef = searchSelectForwardedRef ? searchSelectForwardedRef : useRef(null);
    const _classes = ifCondition ? stylesSearchSelectNewDesign() : classes;

    const handleOverflow = (text) => {
      const element = document.getElementById(text);
      if (element.scrollWidth !== 0) {
        setDisableHover(element.scrollWidth < maxWidth);
      } else {
        setDisableHover(element.offsetWidth < maxWidth);
      }
    };

    const onSelectChange = (newValue) => {
      if (isMultiple) {
        setMultiValues(newValue);
      } else {
        setValue(newValue);
      }
      onChange(newValue);
    };

    const getOptionFromInputValue = (value) =>
      (allOptionsList || options)?.find((option) => option.type && option.label === value);

    const getInputIconPath = (inputValue) => {
      const selectedOption = getOptionFromInputValue(inputValue);
      const optionsWithoutIcon = selectedOption.colId === '*';
      return selectedOption && !optionsWithoutIcon ? getTypeIconPath(selectedOption.type) : '';
    };

    // if the SearchSelect is the "boolean" select box in the filters panel, apply slightly different style
    if (boolFilterPanelStyles !== null && boolFilterPanelStyles === true) {
      _classes.endAdornment = null;
    }

    const onFocus = () => {
      searchSelectRef.current.classList.add('focused');

      if (isOpen && searchSelectRef.current) {
        searchSelectRef.current.classList.remove('focused');
        return searchSelectRef.current?.classList.add('focus-hidden');
      } else {
        searchSelectRef.current.classList.remove('focus-hidden');
        return searchSelectRef.current?.classList.add('focus-hidden');
      }
    };

    useEffect(() => {
      if (defaultValue !== undefined) setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      if (!closeOnScroll) return;
      const onScroll = (e) => {
        const { scrollName } = e.detail;
        if (scrollName !== scrollTarget) return;
        setIsOpen(false);
      };
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }, [closeOnScroll, scrollTarget]);

    return (
      <Autocomplete
        blurOnSelect={blurOnSelect}
        freeSolo={freeSolo}
        open={isOpen}
        openOnFocus={openOnFocus}
        noOptionsText={noOptionsText}
        multiple={isMultiple}
        className={clsx(
          'search-select',
          isSmall && 'search-select--small',
          isDisabled && 'disabled-search-select',
          ifCondition && '!w-40 if-condition !static',
          className
        )}
        value={isMultiple ? multiValues : value}
        options={options}
        getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.label}
        autoComplete
        disableClearable
        disabled={isDisabled}
        disableCloseOnSelect={disableCloseOnSelect}
        filterOptions={filterOptions}
        popupIcon={
          popupIcon ? (
            popupIcon
          ) : (
            <Icon
              name='caret-down-regular'
              alt='caret-down-regular'
              weight='fill'
              color='#858585'
              className='mt-1'
            />
          )
        }
        onChange={(event, newValue) => onSelectChange(newValue)}
        onOpen={() => {
          searchSelectRef.current.classList.remove('focused');
          searchSelectRef.current.classList.add('focus-hidden');
          setMenuIsOpen && setMenuIsOpen(true);
          setIsOpen && setIsOpen(true);
        }}
        onClose={(event) => {
          searchSelectRef.current.classList.remove('focus-hidden');
          event.key === 'Enter' && searchSelectRef.current.classList.add('focused');
          setMenuIsOpen && setMenuIsOpen(false);
          setIsOpen && setIsOpen(false);
          onClose();
        }}
        getOptionDisabled={(option) => option.label === 'Loading...'}
        classes={{
          paper: `${_classes.paper} ${ifCondition ? 'ifFunction' : ''}`,
          listbox: `${_classes.listbox} ${ifCondition ? 'ifFunction' : ''}`,
          option: _classes.option,
          popupIndicator: _classes.popupIndicator,
          noOptions: _classes.noOptions,
          input: _classes.input,
          inputRoot: `${_classes.inputRoot} ${isOpen && 'opened-menu'} ${
            !isValidConditions &&
            (!value || !multiValues) &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          } ${ifCondition ? 'ifFunction' : ''}`,
          endAdornment: `${_classes.endAdornment} ${ifCondition ? 'ifFunction' : ''}`,
        }}
        getOptionSelected={(option, v) => option.value === v.value}
        renderOption={(option) => {
          return (
            <Tooltip
              title={option.label}
              placement='bottom-start'
              disableHoverListener={disableHover}
              arrow
            >
              <div
                className={`search-select__option
        ${canDelete ? 'delete-option-icon' : ''}
        ${option.pinned ? 'pinned-option' : ''}`}
                onMouseEnter={() => handleOverflow(option.label)}
              >
                {option.type && (
                  <Icon name={getTypeIconPath(option.type)} size={18} alt='option-type' />
                )}
                <span id={option.label}>{option.label}</span>
                {canDelete && option.label !== 'Loading...' && (
                  <div
                    role='button'
                    tabIndex={0}
                    onKeyUp={() => {}}
                    onClick={(e) => {
                      handleDelete(option);
                      e.stopPropagation();
                    }}
                    data-cy='remove-search-select-option'
                    alt={''}
                  >
                    <Icon name='trash' size={18} />
                  </div>
                )}
              </div>
            </Tooltip>
          );
        }}
        renderInput={(params) => {
          const { value } = params.inputProps;
          const isOptionHasType = getOptionFromInputValue(value);
          return (
            <div
              className={clsx(
                'search-select__input',
                !isOptionHasType ? 'search-select__input_text' : '',
                ifCondition ? 'if-condition' : '',
                additionalClasses
              )}
            >
              {isOptionHasType && (
                <div
                  style={error ? { top: '12px' } : {}}
                  alt={value}
                  className='search-select__input_image'
                >
                  <Icon name={getInputIconPath(value)} size={18} />
                </div>
              )}
              <TextField
                data-cy={cypressTextFieldName}
                ref={searchSelectRef}
                onFocus={onFocus}
                onBlur={() => {
                  searchSelectRef.current?.classList.remove('focused');
                  onBlur && onBlur();
                }}
                {...params}
                placeholder={placeholder}
                error={error}
                helperText={helperText}
              />
            </div>
          );
        }}
      />
    );
  }
);

export default withStyles(stylesSearchSelect)(SearchSelect);
