/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
'use client';

import { Command as CommandPrimitive } from 'cmdk';
import {
  useEffect,
  useState,
  useImperativeHandle,
  ReactNode,
  ComponentPropsWithoutRef,
  forwardRef,
  useRef,
  Ref,
  useCallback,
  KeyboardEvent,
} from 'react';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from './Command';
import { cn } from './lib/utils';
import Icon from '@/Components/UI/Icon/Icon';
import PopoverV2 from '../Popover/PopoverV2';
import { getTypeIconPath } from '@/Utils/getTypeIconPath';
import clsx from 'clsx';

export interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  icon?: string;
  /** fixed option that can't be removed. */
  fixed?: boolean;
  /** Group the options by providing key. */
  [key: string]: string | boolean | undefined;
}
interface GroupOption {
  [key: string]: Option[];
}

interface MultipleSelectorProps {
  value?: Option[];
  defaultOptions?: Option[];
  /** manually controlled options */
  options?: Option[];
  placeholder?: string;
  /** Empty component. */
  emptyIndicator?: ReactNode;
  onChange?: (options: Option[], name?: string) => void;
  /** Limit the maximum number of selected options. */
  maxSelected?: number;
  /** When the number of selected options exceeds the limit, the onMaxSelected will be called. */
  onMaxSelected?: (maxLimit: number) => void;
  /** Hide the placeholder when there are options selected. */
  name?: string;
  disabled?: boolean;
  /** Group the options base on provided key. */
  groupBy?: string;
  className?: string;
  /**
   * First item selected is a default behavior by cmdk. That is why the default is true.
   * This is a workaround solution by add a dummy item.
   *
   * @reference: https://github.com/pacocoursey/cmdk/issues/171
   */
  selectFirstItem?: boolean;
  /** Props of `Command` */
  commandProps?: ComponentPropsWithoutRef<typeof Command>;
  /** Props of `CommandInput` */
  inputProps?: Omit<
    ComponentPropsWithoutRef<typeof CommandPrimitive.Input>,
    'value' | 'placeholder' | 'disabled'
  >;
  selectionMode?: 'single' | 'multiple';
  dataCy?: string;
  isOpenbyDefault?: boolean;
  placeholderClassName?: string;
}

export interface MultipleSelectorRef {
  selectedValue: Option[];
  input: HTMLInputElement;
}

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function reorderOptions(selectedOptions: Option[], options: GroupOption): GroupOption {
  const allOptions = Object.entries(options);
  const reorderedOptions: GroupOption = {};
  allOptions.forEach(([key, dropdowns]) => {
    const selectedItems = dropdowns.filter((option) =>
      selectedOptions.some((selectedOption) => selectedOption.value === option.value)
    );
    const unselectedItems = dropdowns.filter(
      (option) => !selectedOptions.some((selectedOption) => selectedOption.value === option.value)
    );
    // Concatenate selected items with unselected items, ensuring selected ones are on top
    reorderedOptions[key] = [...selectedItems, ...unselectedItems];
  });
  return reorderedOptions;
}

function groupOptionsByProperty(options: Option[], propertyKey?: string): GroupOption {
  if (options.length === 0) {
    return {};
  }

  if (!propertyKey) {
    return { '': options };
  }

  const groupedOptions: GroupOption = {};
  options.forEach((option) => {
    const key = (option[propertyKey] as string) || '';
    groupedOptions[key] = groupedOptions[key] || [];
    groupedOptions[key].push(option);
  });

  return groupedOptions;
}

const ComboBox = forwardRef<MultipleSelectorRef, MultipleSelectorProps>(
  (
    {
      value,
      onChange,
      placeholder = 'Search or type',
      defaultOptions = [],
      options: arrayOptions,
      emptyIndicator,
      maxSelected = Number.MAX_SAFE_INTEGER,
      onMaxSelected,
      name,
      disabled,
      groupBy,
      className,
      selectFirstItem = true,
      commandProps,
      inputProps,
      selectionMode = 'single',
      dataCy,
      isOpenbyDefault,
      placeholderClassName = '',
    }: MultipleSelectorProps,
    ref: Ref<MultipleSelectorRef>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(isOpenbyDefault || false);

    const [selected, setSelected] = useState<Option[]>(value || []);
    const [options, setOptions] = useState<GroupOption>(
      groupOptionsByProperty(defaultOptions, groupBy)
    );
    const [inputValue, setInputValue] = useState('');
    const [selectedValuesString, setSelectedValuesString] = useState('');

    const onSelectSingle = (option: Option) => {
      setInputValue('');
      setSelected([option]);
      setIsPopoverOpen(false);
      onChange?.([option], name);
    };

    const onSelectMultiple = (option: Option) => {
      if (selected.map((v) => v.value)?.includes(option.value)) {
        handleUnselect(option);
        return;
      }
      if (selected.length >= maxSelected) {
        onMaxSelected?.(maxSelected);
        return;
      }
      setInputValue('');
      const newOptions = [...selected, option];
      setSelected(newOptions);
      onChange?.(newOptions, name);
    };

    useImperativeHandle(
      ref,
      () => ({
        selectedValue: [...selected],
        input: inputRef.current as HTMLInputElement,
      }),
      [selected]
    );

    const handleUnselect = useCallback(
      (option: Option) => {
        const newOptions = selected.filter((s) => s.value !== option.value);
        setSelected(newOptions);
        onChange?.(newOptions, name);
      },
      [selected]
    );

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (input) {
          if (e.key === 'Delete' || e.key === 'Backspace') {
            if (input.value === '' && selected.length > 0) {
              handleUnselect(selected[selected.length - 1]);
            }
          }
          // This is not a default behaviour of the <input /> field
          if (e.key === 'Escape') {
            input.blur();
          }
        }
      },
      [selected]
    );

    useEffect(() => {
      if (value?.length) {
        setSelected(value);
      }
    }, [value]);

    useEffect(() => {
      if (!arrayOptions) {
        return;
      }
      const newOption = groupOptionsByProperty(arrayOptions || [], groupBy);
      if (JSON.stringify(newOption) !== JSON.stringify(options)) {
        setOptions(newOption);
      }
    }, []);

    useEffect(() => {
      const handleOutsideInteraction = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
          if (!inputRef.current.contains(event.target as Node)) {
            setIsPopoverOpen(false);
          }
        }
      };

      document.addEventListener('mousedown', handleOutsideInteraction);

      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction);
      };
    }, [inputRef]);

    const EmptyItem = () => {
      if (!emptyIndicator) return undefined;

      return <CommandEmpty>{emptyIndicator}</CommandEmpty>;
    };

    const getSelectedLabels = () => {
      return selected.map((option) => option.label).join(', ');
    };

    useEffect(() => {
      setSelectedValuesString(selected?.map((option) => option.value).join(','));
    }, [selected]);

    return (
      <>
        <input type='hidden' name={name} value={selectedValuesString} />
        <Command
          {...commandProps}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
            handleKeyDown(e);
            commandProps?.onKeyDown?.(e);
          }}
          className={cn(
            'overflow-visible',
            commandProps?.className,
            disabled ? 'bg-ui-secondary placeholder-ui-secondary' : 'bg-white placeholder-ui'
          )}
        >
          <PopoverV2
            content={
              <div className='relative z-100'>
                <CommandPrimitive.Input
                  {...inputProps}
                  ref={inputRef}
                  defaultValue={inputValue}
                  disabled={disabled}
                  onValueChange={(value) => {
                    setInputValue(value);
                    inputProps?.onValueChange?.(value);
                  }}
                  onBlur={(event) => {
                    setIsPopoverOpen(false);
                    inputProps?.onBlur?.(event);
                  }}
                  onFocus={(event) => {
                    setOptions(
                      reorderOptions(selected, groupOptionsByProperty(arrayOptions || [], groupBy))
                    );
                    setIsPopoverOpen(true);
                    inputProps?.onFocus?.(event);
                  }}
                  placeholder='Search or type'
                  className={cn(
                    'ml-2 mt-3 mb-1 flex-1 bg-white z-10 outline-none placeholder:text-ui-helper border rounded px-2 w-[95%] h-8 text-sm',
                    inputProps?.className
                  )}
                  autoFocus
                  data-cy={dataCy}
                />
                <CommandList>
                  <>
                    {EmptyItem()}
                    {!selectFirstItem && <CommandItem value='-' className='hidden' />}
                    {Object.entries(options).map(([key, dropdowns]) => (
                      <CommandGroup
                        key={key}
                        heading={key}
                        className='h-full bg-white !overflow-visible'
                      >
                        <>
                          <div data-cy={`${dataCy}-dropdown`}>
                            {dropdowns.map((option) => {
                              return (
                                <div key={option.value}>
                                  <CommandItem
                                    value={option.label}
                                    disabled={option.disabled}
                                    id={option.label}
                                    onMouseDown={(e: {
                                      preventDefault: () => void;
                                      stopPropagation: () => void;
                                    }) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    onSelect={() => {
                                      selectionMode === 'multiple'
                                        ? onSelectMultiple(option)
                                        : onSelectSingle(option);
                                    }}
                                    className={cn(
                                      'cursor-pointer gap-2 truncate',
                                      option.disabled && 'cursor-default text-ui'
                                    )}
                                  >
                                    {selectionMode === 'multiple' && (
                                      <input
                                        type='checkbox'
                                        checked={selected.some(
                                          (selectedOption) => selectedOption.value === option.value
                                        )}
                                        onChange={() => {}}
                                      />
                                    )}
                                    {option.icon ? (
                                      <Icon name={option.icon} className='flex-shrink-0 mr-1' />
                                    ) : (
                                      ''
                                    )}
                                    {option.type && (
                                      <Icon
                                        name={getTypeIconPath(option.type)}
                                        className='flex-shrink-0 mr-1'
                                      />
                                    )}
                                    {option.label}
                                  </CommandItem>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      </CommandGroup>
                    ))}
                  </>
                </CommandList>
              </div>
            }
            open={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
            disabled={disabled}
          >
            <div
              className={cn('group rounded border border-ui-200 text-sm', className)}
              data-cy={`${dataCy}-input`}
            >
              <div className='relative flex flex-row items-center justify-start h-8 mx-2 truncate'>
                {selected.length ? (
                  selected.length === 1 ? (
                    <div className='flex-1 gap-2 truncate'>
                      {selected[0].type && (
                        <Icon
                          name={getTypeIconPath(selected[0].type)}
                          className='flex-shrink-0 inline-block mr-1'
                        />
                      )}
                      {selected[0].label}
                    </div>
                  ) : (
                    <div className='flex-1 truncate'>{getSelectedLabels()}</div>
                  )
                ) : (
                  <span className={clsx('typography-body text-ui-helper', placeholderClassName)}>
                    {placeholder}
                  </span>
                )}
                <Icon
                  className='absolute right-0 ml-2'
                  name={isPopoverOpen ? 'caret-up' : 'caret-down-regular'}
                  size={12}
                  color='#151515'
                />
              </div>
            </div>
          </PopoverV2>
        </Command>
      </>
    );
  }
);

ComboBox.displayName = 'ComboBox';
export default ComboBox;
