import { useSelector } from 'react-redux';

import Icon from '../../../Components/UI/Icon/Icon';

import { spreadsheetSliceName } from '../../../redux/constants';

// import { get } from '../../../Utils/API';
import { isReadOnly as _isReadOnly } from '../../../Utils/sharing';

import SheetSummary from '../../../Components/Spreadsheet/SheetStats/SheetSummary';
// import ColumnProfilePanel from '../../../Components/Spreadsheet/SheetStats/ColumnProfilePanel';
import { ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const DEFAULT_FILE_STATS = {
  fileRows: 0,
  fileSize: '0 size',
  columnCount: 0,
  ColumnCountByTypes: {},
  columnSummary: {},
  isEstimate: false,
};

const SheetStats = () => {
  // const [selectedColumnProfile, setSelectedColumnProfile] = useState({});
  const currentFile = useCurrentFile();
  const { columnDefs, fileStats } = useSelector((state) => state[spreadsheetSliceName]);
  const { metadata } = currentFile || {};

  const fileName = metadata?.FileName || '';
  const fileDescription = currentFile?.Note || '';
  const isReadOnly = _isReadOnly(currentFile);
  // const fileUuid = metadata?.FileUuid;

  const { fileRows, fileSize, columnCount, ColumnCountByTypes, columnSummary, isEstimate } =
    fileStats || DEFAULT_FILE_STATS;

  const getSummaryLabel = (value) => {
    switch (value) {
      case 'EmptyCount':
        return 'Empty Cells';
      case 'ColumnType':
        return 'Data Type';
      case 'DistinctCount':
        return '';
      case 'UniqueCount':
        return 'Unique Count';
      case 'EstimatedDistinctCount':
        return '';
      case 'EstimatedUniqueCount':
        return 'Unique Count*';
      default:
        return value;
    }
  };

  const getTableLabel = (value) => {
    switch (value) {
      case 'Int64':
      case 'UInt64':
      case 'Float64':
        return 'Number';
      case 'Boolean':
        return 'Boolean';
      case 'DateTime':
      case 'DateTime64':
        return 'Date';
      case 'String':
      case 'EmailAddress':
        return 'Text';
      default:
        return 'Other';
    }
  };

  const countColumnTypes = () => {
    const categorizedColumnCount = {
      Number: 0,
      Boolean: 0,
      Date: 0,
      Text: 0,
      Other: 0,
    };

    if (!ColumnCountByTypes) return categorizedColumnCount;
    Object.entries(ColumnCountByTypes)?.forEach(([key, value]) => {
      categorizedColumnCount[getTableLabel(key)] =
        categorizedColumnCount[getTableLabel(key)] + value;
    });
    return categorizedColumnCount;
  };

  // const handleSelectColumn = async (colId) => {
  //   const colRes = await get(`dataset/stats/${fileUuid}/${colId}`);
  //   setSelectedColumnProfile(colRes);
  // };

  const sheetSummary = () => {
    return (
      <SheetSummary
        fileName={fileName}
        fileDescription={fileDescription}
        isReadOnly={isReadOnly}
        fileSize={fileSize}
        fileRows={fileRows}
        columnCount={columnCount}
        columnsByType={countColumnTypes()}
        columnSummary={Object.entries(columnDefs)
          .filter(([, value]) => value.colId !== ROW_NUMBER_COLUMN_ID)
          .filter((value, i) => i < 20)
          .map(([, value]) => {
            return {
              columnName: value.headerName,
              stats: columnSummary[value.colId],
            };
          })}
        viewTypeOptions={[
          { id: 0, label: 'Chart', value: 'Chart', type: 'Chart' },
          { id: 1, label: 'Table', value: 'Table', type: 'Table' },
        ]}
        getSummaryLabel={(value) => getSummaryLabel(value)}
        getTableLabel={(value) => getTableLabel(value)}
        isEstimate={isEstimate}
      />
    );
  };

  // const columnProfile = () => {
  //   return (
  //     <ColumnProfilePanel
  //       columnOptions={Object.entries(columnDefs).map(([key = '', value], i) => {
  //         return {
  //           id: i,
  //           value: value.colId,
  //           label: value.headerName,
  //           type: value.fieldType,
  //         };
  //       })}
  //       selectedColumnProfile={selectedColumnProfile}
  //       handleSelectColumn={(value) => handleSelectColumn(value)}
  //       getTableLabel={(value) => getTableLabel(value)}
  //       getSummaryLabel={(value) => getSummaryLabel(value)}
  //       isEstimate={isEstimate}
  //     />
  //   );
  // };

  return (
    <div className='relative h-full overflow-scroll'>
      <div
        data-cy='header-text'
        className='sticky top-0 flex items-center w-full h-[41px] p-2 text-[15px] font-semibold gap-1 border-t border-b border-ui-200 bg-white'
      >
        <Icon name='lightbulb' size={20} />
        Sheet Stats
      </div>
      <div>
        {/* <Tabs
          tabs={[
            { label: 'Summary', content: sheetSummary() },
            { label: 'Column Profile', content: columnProfile() },
          ]}
        /> */}
        {sheetSummary()}
      </div>
    </div>
  );
};

export default SheetStats;
