import { useState } from 'react';

import { Button } from '@/Components/UI/Button/Button';
import LookupChooseFile from './LookupChooseFile';

import { convertIntToColumnName } from '@/Utils/convertIntToColumnName';

import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import Label from '@/Components/UI/Form/Label/Label';
import RadioInput from '@/Components/UI/Form/RadioInput/RadioInput';
import { Select } from '@/Components/UI/Form/Select/Select';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import ToggleSwitchInput from '@/Components/UI/Form/ToggleSwitchInput/ToggleSwitchInput';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

const CrossFileVLookup = ({
  setSelectedFile,
  selectedFile,
  setSelectedLookupColumn,
  selectedLookupColumn,
  selectedColumn,
  currentFileColumns,
  setSelectedColumn,
  additionalColumns,
  setAdditionalColumns,
  isTrueFalse,
  setIsTrueFalse,
  isNearMatch,
  setIsNearMatch,
}) => {
  const [isLookupFileModalOpen, setLookupFileModelOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAllColumns, setSelectAllColumns] = useState(false);

  const nearMatchTooltip =
    'Allow for case insensitive matches, ignores punctuation and whitespace.';
  const contentTooltip =
    'Value will insert additional columns from selected sheet. True/False will insert TRUE in new column when found.';

  const { Headers = [], HeaderToColumnMapping = [] } = selectedFile || {};

  // Traverse through Headers of selected file.
  // If header type matches with selectedColumnType, then add to options array
  const options = Headers.reduce((accumulatedOptions, header, idx) => {
    accumulatedOptions.push({
      label: header,
      value: HeaderToColumnMapping[idx] || convertIntToColumnName(idx + 1),
      id: `${idx + 1}`,
    });
    return accumulatedOptions;
  }, []);

  const allSelectedFileOptions = Headers.reduce((accumulatedOptions, header, idx) => {
    accumulatedOptions.push({
      label: header,
      value: HeaderToColumnMapping[idx] || convertIntToColumnName(idx + 1),
      id: `${idx + 1}`,
    });
    return accumulatedOptions;
  }, []);

  const onChangeTargetColumn = (targetColumnValue) => {
    currentFileColumns.map((option) => {
      if (option.value === targetColumnValue) {
        setSelectedColumn(option);
      }
    });
  };

  const onChangeLookupColumn = (lookupColumnValue) => {
    allSelectedFileOptions?.map((option) => {
      if (option.value === lookupColumnValue) {
        setSelectedLookupColumn(option);
      }
    });
  };

  const handleChange = (e) => {
    setIsTrueFalse(e.target.value);
    if (e.target.value === 'trueFalse') {
      setAdditionalColumns([]);
    }
  };

  const radioOptions = [
    {
      label: 'True/False',
      value: 'trueFalse',
    },
    {
      label: 'Value',
      value: 'value',
    },
  ];

  const filteredSearch = allSelectedFileOptions.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAllSelection = () => {
    if (!selectAllColumns) {
      setAdditionalColumns(filteredSearch);
      setSelectAllColumns(true);
    } else {
      setAdditionalColumns([]);
      setSelectAllColumns(false);
    }
  };

  const handleSelectChange = (event) => {
    const colId = event.target.name;
    additionalColumns.map((i) => i.value).includes(colId)
      ? setAdditionalColumns(additionalColumns.filter((i) => i.value !== colId))
      : setAdditionalColumns([
          ...additionalColumns,
          allSelectedFileOptions.find((i) => i.value === colId),
        ]);
  };

  const closeLookupChooseFileModal = () => setLookupFileModelOpen(false);

  return (
    <>
      <Modal
        isOpen={isLookupFileModalOpen}
        onClose={closeLookupChooseFileModal}
        title='Select File'
        iconName='file-search'
        size='x-large'
      >
        <LookupChooseFile
          setSelectedFile={setSelectedFile}
          closeModal={closeLookupChooseFileModal}
        />
        <hr className='mt-10 mb-5 solid' />
        <div className='flex justify-end gap-4 mt-5'>
          <Button variant='ghost' color='shadow' size='large' onClick={closeLookupChooseFileModal}>
            Cancel
          </Button>
        </div>
      </Modal>
      <div>
        <Select
          label='Select Column'
          placeholder='Column'
          options={currentFileColumns}
          onChange={onChangeTargetColumn}
          value={selectedColumn?.value}
        />
        <Label text='Select File' className='mt-3 mb-1' />
        <Button
          onClick={() => {
            setLookupFileModelOpen(true);
            setAdditionalColumns([]);
            setSelectAllColumns(false);
          }}
          disabled={!selectedColumn}
          color='oceanBlue'
          variant='outline'
          className='mb-3'
          iconName='upload-simple'
        >
          Browse
        </Button>
        {selectedFile ? (
          <span className='absolute w-[255px] ml-2 shadow-[0px_2px_4px_0px_#47474714]'>
            <div className='flex flex-row justify-between h-8 gap-1 p-1 border rounded'>
              <div className='text-ui-helper text-[13px] w-[230px] overflow-hidden truncate pl-1'>
                {selectedFile.FileName}
              </div>
              <Button
                onClick={() => setSelectedFile(null)}
                iconName='x'
                className='p-0'
                size='small'
                variant='ghost'
              />
            </div>
          </span>
        ) : null}
        <Select
          disabled={!selectedColumn}
          placeholder='Column'
          label='Select Lookup Column'
          options={options}
          value={selectedLookupColumn?.value}
          onChange={onChangeLookupColumn}
        />
        {selectedLookupColumn && (
          <>
            <div className='pt-4 include-column'>
              <div className='relative flex'>
                <ToggleSwitchInput
                  size='small'
                  label='Near Match'
                  labelOnLeftSide={false}
                  labelCss='px-2 underline decoration-dotted !text-sm  underline-offset-2 !font-medium'
                  value={isNearMatch}
                  onChange={() => setIsNearMatch(!isNearMatch)}
                />
                <Tooltip text={nearMatchTooltip} side='bottom' className='w-60 !leading-normal'>
                  <Icon name='info' size={15} color='#757575' />{' '}
                </Tooltip>
              </div>
            </div>
            <div className='py-4 new-column-content'>
              <div className='flex flex-row items-center gap-2'>
                <Label
                  text='New Column(s) Content'
                  className='underline decoration-dotted underline-offset-2'
                />
                <Tooltip text={contentTooltip} side='bottom' className='!leading-normal w-80'>
                  <Icon name='info' color='#757575' size={15} className='mb-[2px]' />
                </Tooltip>
              </div>
              <RadioInput value={isTrueFalse} options={radioOptions} onChange={handleChange} />
            </div>
            {isTrueFalse === 'value' && (
              <div className='flex flex-col gap-2 additional-column-scroll'>
                <TextInput
                  label='Additional Column(s)'
                  className='w-full'
                  placeholder='Search or Type'
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
                <div className='px-3 text-sm border rounded h-52 border-shadow-200'>
                  <div className='flex items-center h-8 my-1 text-sm'>
                    <CheckboxInput
                      checked={selectAllColumns}
                      onChange={handleAllSelection}
                      label='Select / Unselect All'
                      disabled={!filteredSearch.length}
                    />
                  </div>
                  <hr className='solid' />
                  <div className='flex flex-col h-[165px] overflow-scroll'>
                    {filteredSearch.length ? (
                      <>
                        {filteredSearch?.map((i) => {
                          return (
                            <div key={i} value={i?.value} className='my-2'>
                              <CheckboxInput
                                checked={additionalColumns?.map((i) => i.value)?.includes(i?.value)}
                                name={i.value}
                                onChange={handleSelectChange}
                                label={i?.label}
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className='w-full my-8 text-center'>No Results</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CrossFileVLookup;
