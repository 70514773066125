import { createSlice } from '@reduxjs/toolkit';
import { authenticationSliceName } from '../constants';

const initialState = {
  auth0IdToken: null,
};

export const authenticationSlice = createSlice({
  name: authenticationSliceName,
  initialState,
  reducers: {
    setAuth0IdToken: {
      prepare(auth0IdToken) {
        return { payload: auth0IdToken };
      },
      reducer(state, action) {
        state.auth0IdToken = action.payload;
      },
    },
    unsetAuth0IdToken(state) {
      state.auth0IdToken = null;
    },
  },
});

export const { setAuth0IdToken, unsetAuth0IdToken } = authenticationSlice.actions;

export default authenticationSlice.reducer;
