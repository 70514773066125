import { Button } from '@/Components/UI/Button/Button';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import Modal from '@/Components/UI/Modal/Modal'; // Assuming this is the correct import path
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import { MODAL_DELETE_ROW, MODAL_EXCEEDS_LIMITS, MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';
import { handleDeleteRow } from '@/helpers/spreadsheetHelper';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { setAlwaysConfirmDeleteRow } from '@/redux/reducers/spreadsheetSlice';

const DeleteRowModal = ({ show, hideModal, rowsToDelete = '', requestBody }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { totalNumberOfRows, filteredNumberOfRows } = useSelector(
    (state) => state[spreadsheetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);
  const [confirmStep, showConfirmStep] = useState(true);

  const deleteRow = () => {
    handleDeleteRow(rowsToDelete, requestBody);
  };
  const deleteConfirmationMessage = (deleteAction) => {
    if (deleteAction === '-matching-filter') {
      return (
        <span>
          This action will permanently delete <b>{filteredNumberOfRows} rows</b>. This will affect
          all <b className='font-bold'>Views</b> created for this sheet and cannot be undone.
        </span>
      );
    }
    if (deleteAction === '-not-matching-filter') {
      return (
        <span>
          This action will permanently delete <b>{totalNumberOfRows - filteredNumberOfRows} rows</b>
          . This will affect all <b className='font-bold'>Views</b> created for this sheet and
          cannot be undone.
        </span>
      );
    }
    return (
      <span>
        This will affect all <b>Views</b> created for this sheet. This action is permanent and
        cannot be undone.{' '}
      </span>
    );
  };

  const save = () => {
    hideModal();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setAlwaysConfirmDeleteRow(confirmStep));
    deleteRow();
    hideModal();
  };

  return (
    <Modal isOpen={show} onClose={hideModal} title='Are you sure?' iconName=''>
      <div>
        <div>{deleteConfirmationMessage(rowsToDelete)}</div>
        <div className='my-4'>
          <CheckboxInput
            checked={!confirmStep}
            onChange={() => showConfirmStep(!confirmStep)}
            label="Don't show this message again"
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' dataCy='cancel-btn' onClick={hideModal}>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' dataCy='save-btn' onClick={save}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DELETE_ROW })(DeleteRowModal);
