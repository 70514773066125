import { get, post } from '@/Utils/API';
import { URL_DATASETS } from '@/Utils/constants';
import clsx from 'clsx';
import csvDownload from 'json-to-csv-export';
import { format } from 'date-fns';

export const defaultFilter = {
  actions: [],
  categories: [],
  end: '',
  page: 0,
  pageSize: 5,
  query: '',
  start: '',
  users: [],
};

export const actions = [
  { label: 'Create', value: 'Create' },
  { label: 'Update', value: 'Update' },
  { label: 'Delete', value: 'Delete' },
];

export const types = [
  { label: 'Enrichments', value: 'Enrichments' },
  { label: 'Find & Replace', value: 'FindAndReplace' },
  { label: 'Combine Columns', value: 'CombineColumns' },
  { label: 'Split Column', value: 'SplitColumn' },
  { label: 'Rename Column(s)', value: 'RenameColumns' },
  { label: 'Change Data Type', value: 'ChangeDataType' },
  { label: 'Change Case', value: 'ChangeCase' },
  { label: 'Trim Whitespace', value: 'TrimWhitespace' },
  { label: 'Cleanup Company Name', value: 'CleanupCompanyName' },
  { label: 'Extract Domain Name', value: 'ExtractDomainName' },
  { label: 'Remove Duplicated', value: 'RemoveDuplicates' },
  { label: 'Delete Row(s)', value: 'DeleteRows' },
  { label: 'Delete Column(s)', value: 'DeleteColumns' },
  { label: 'First Row as Header', value: 'FirstRowAsHeader' },
  { label: 'Header as First Row', value: 'HeaderAsFirstRow' },
  { label: 'If', value: 'If' },
  { label: 'Insert Column(s)', value: 'InsertColumns' },
  { label: 'Formulator', value: 'Formulator' },
  { label: 'Cross File VLookup', value: 'CrossFileVLookup' },
  { label: 'Character Count', value: 'CharacterCount' },
  { label: 'Decode Base64 Content', value: 'DecodeBase64Content' },
  { label: 'Cleanup Unix Time', value: 'CleanupUnixTime' },
  { label: 'Explode Date', value: 'ExplodeDate' },
  { label: 'Explode Json', value: 'ExplodeJson' },
  { label: 'Paste', value: 'UpsertRows' },
  { label: 'Flag As', value: 'FlagAs' },
];

export const actionColor = (action) => {
  switch (action) {
    case 'Create':
      return 'text-pear-900';
    case 'Update':
      return ' text-ocean-blue-500';
    case 'Delete':
      return 'text-violet-web-900';
    default:
      return 'text-ui-secondary';
  }
};

export const formatEmptyIndicator = () => {
  return <p className='text-sm leading-10 text-center text-ui-helper'>No results found.</p>;
};
export const formatButton = (content) => {
  return (
    <div className='flex flex-row items-center gap-1 px-4 py-2 uppercase align-middle text-ui-helper'>
      {content}
    </div>
  );
};

export const returnTableBody = (data) => {
  const {
    User = '',
    Timestamp = '',
    Action: { type = '', category = '', message = '' },
  } = data;

  const formatTimestamp = (split) => {
    const formatted = format(new Date(Timestamp), 'MMM d yyyy  HH:mm').split('  ');
    const splitBy = split === 'date' ? formatted[0] : formatted[1];
    return splitBy;
  };

  const formatMessage = () => {
    const actionIndex = message.indexOf(' ');
    return (
      <>
        <span className='font-semibold'>{message.substring(0, actionIndex)}</span>
        <>{message.substring(actionIndex)}</>
      </>
    );
  };

  return (
    <>
      <th className='w-1/12 px-4 py-2 font-normal'>
        <div>{formatTimestamp('date')}</div>
        <div className='text-ui-helper'>{formatTimestamp('time')}</div>
      </th>
      <th className='w-3/12 px-4 py-2 font-normal'>{User}</th>
      <th className={clsx('w-1/12 px-4 py-2 uppercase', actionColor(category))}>{category}</th>
      <th className='w-2/12 px-4 py-2 font-normal truncate text-ui-helper'>{type}</th>
      <th className='w-3/12 px-4 py-2 font-normal max-w-[100px] overflow-scroll'>
        {formatMessage()}
      </th>
    </>
  );
};

export const handleFilterActivity = async (data, id = '', setActivity) => {
  try {
    const res = await post(`${URL_DATASETS}/${id}/activity/search`, data, true);

    if (Array.isArray(res?.entries)) {
      setActivity(res?.entries);
    }
  } catch (e) {
    setActivity([]);
    throw new Error(e);
  }
};

export const getTeamEmails = async (setUsers, user) => {
  let teamEmails = [];
  try {
    const autofillResponse = await get(
      'users/autofill?include_team_members=true&include_share_recipients=false'
    );
    teamEmails = autofillResponse.team_members.map((user) => ({
      label: user.email,
      value: user.email,
    }));
  } catch (e) {
    throw new Error(e);
  }
  setUsers([{ label: user, value: user }, ...teamEmails]);
};

export const getActivityCount = async (setCount, setTotalCount, fileUuid) => {
  let count = 1;
  try {
    const res = await post(`${URL_DATASETS}/${fileUuid}/activity/count`);
    count = res?.count || 1;
  } catch (e) {
    throw new Error(e);
  }
  setTotalCount(count);
  setCount(Math.ceil(count / 5) || 1);
};

export const getActivityLog = async (setActivity, id, page = 0) => {
  let activity = [];
  try {
    const res = await get(`${URL_DATASETS}/${id}/activity?page=${page}&pageSize=5`);
    activity = res.entries;
  } catch (e) {
    throw new Error(e);
  }
  setActivity(activity);
};

export const handleExportActivityLog = async (fileName, fileUuid) => {
  let activity = [];

  try {
    const res = await get(`${URL_DATASETS}/${fileUuid}/activity`);
    activity = res;
  } catch (e) {
    throw new Error(e);
  }

  const data = {
    data: activity,
    filename: `${fileName} - Activity Log`,
    delimiter: ',',
  };
  csvDownload(data);
};
