import NoResults from '@/Assets/icons/no-rows-icon.svg';

const CustomNoRowsComponent = () => {
  return (
    <div className='absolute text-sm font-semibold text-gray-700 right-1/2 bottom-45 font-overpass pl-14'>
      No results
      <div className='absolute left-0 bottom-[20px] w-[200px] h-[200px]'>
        <img src={NoResults} alt='Icon' className='' />
      </div>
    </div>
  );
};
export default CustomNoRowsComponent;
