import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import Icon from '../../../../Components/UI/Icon/Icon';
import InputField from '../../../../Components/UI/Form/InputField/InputField';
import { getTypeIconPath } from '../../../../Utils/getTypeIconPath.js';
import { useEffect, useState } from 'react';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
import { Button } from '../../Button/Button';

type Option = {
  label: string;
  value: string;
  type?: string;
  disabled?: boolean;
  checkbox?: boolean;
  checked?: boolean;
};

type Props = {
  placeholder?: string;
  label?: string | React.ReactNode;
  value: string | string[];
  options: Option[];
  onChange: (event: string) => void;
  onClick?: () => void;
  size?: 'small' | 'large';
  color?: 'shadow' | 'oceanBlue' | 'violetWeb';
  disabled?: boolean;
  helperText?: string;
  helperTextPosition?: 'top' | 'bottom';
  dataCy?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  canSelectMultiple?: boolean;
  hasSelectAllOption?: boolean;
  handleSelectAllOption?: () => void;
  isSelectAllOptionChecked?: boolean;
  handleMultipleSelection?: (event: string[]) => void;
  excludedOptions?: Option[] | [];
};

const selectColors = {
  shadow: 'border-shadow-200 text-ui-secondary !shadow-none placeholder-helper font-normal',
  oceanBlueLight: 'border-shadow-200 text-ui-secondary !shadow-none placeholder-helper font-normal',
  oceanBlue: 'border-ocean-blue-500 shadow-ocean-blue-100 text-ui placeholder-ui font-normal',
  violetWeb: 'border-violet-web-700 shadow-violet-web-100 text-ui placeholder-ui font-normal',
};

const activeColors = {
  shadow: 'bg-shadow text-ui',
  oceanBlue: 'bg-ocean-blue-500 text-ui',
  oceanBlueLight: 'bg-ocean-blue-100 text-ui rounded',
  violetWeb: 'bg-violet-web-700 text-ui',
};

const accessibilityColors = 'focus:outline-none';

export const Select = ({
  options,
  value,
  label,
  helperText,
  helperTextPosition = 'bottom',
  size = 'large',
  color = 'shadow',
  disabled,
  dataCy,
  onChange,
  onClick,
  buttonClassName,
  dropdownClassName = '',
  canSelectMultiple = false,
  hasSelectAllOption = false,
  handleSelectAllOption = () => {},
  isSelectAllOptionChecked = false,
  handleMultipleSelection = () => {},
  excludedOptions = [],
  placeholder = '',
}: Props) => {
  const [defaultOption, setDefaultOption] = useState<Option | undefined>(undefined);
  useEffect(() => {
    const defaultOption = [...options, ...excludedOptions].find((option) => option.value === value);
    setDefaultOption(defaultOption);
  }, [value, options, excludedOptions]);

  const hasOptions = options.length > 0;

  const handleChanges = (v: string | string[]) => {
    if (canSelectMultiple && Array.isArray(v)) {
      handleMultipleSelection(v);
    }

    if (typeof v === 'string') {
      onChange(v);
    }
  };

  return (
    <InputField
      dataCy={dataCy}
      label={label}
      helperText={helperText}
      helperTextPosition={helperTextPosition}
      color={color}
      disabled={disabled}
    >
      <div className='relative'>
        <Listbox
          value={value}
          defaultValue={value}
          onChange={handleChanges}
          multiple={canSelectMultiple}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              <Listbox.Button
                placeholder={!defaultOption ? placeholder : ''}
                className={clsx(
                  'w-full border appearance-none flex items-center bg-white px-3 rounded disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200 relative truncate',
                  size === 'large' ? 'shadow-spread h-8 text-sm' : 'h-6 text-[13px] leading-[18px]',
                  color && selectColors[color],
                  accessibilityColors,
                  buttonClassName
                )}
                data-cy={dataCy}
                onClick={onClick}
              >
                {!defaultOption && <span className='text-gray-300'>{placeholder}</span>}
                {defaultOption && defaultOption.type && (
                  <Icon name={getTypeIconPath(defaultOption.type)} className='flex-shrink-0 mr-1' />
                )}
                {!canSelectMultiple && defaultOption && defaultOption.label}
                {canSelectMultiple && Array.isArray(value) && value?.join(', ')}
                <Icon
                  className='absolute ml-2 right-2'
                  name={open ? 'caret-up' : 'caret-down-regular'}
                  size={12}
                  color='#151515'
                />
              </Listbox.Button>

              <Listbox.Options
                className={clsx(
                  'w-full border bg-white border-ui-100 rounded absolute z-10 mt-1 max-h-60 overflow-auto p-1',

                  size === 'large' ? 'text-sm' : 'text-xs',
                  color && selectColors[color],
                  accessibilityColors,
                  dropdownClassName
                )}
              >
                {hasSelectAllOption && canSelectMultiple && (
                  <Button
                    data-cy={'select-option-all'}
                    onClick={handleSelectAllOption}
                    variant='ghost'
                    className={clsx(
                      'relative leading-5 text-ui flex items-center cursor-pointer select-none py-2 pl-3 pr-9 whitespace-pre overflow-hidden border-b w-full !justify-start',
                      size === 'large' ? 'h-8 text-sm' : 'h-6 text-[13px]',
                      color && selectColors[color],
                      accessibilityColors
                    )}
                  >
                    <CheckboxInput
                      checked={isSelectAllOptionChecked}
                      onChange={() => {}}
                      className='mr-2'
                    />
                    Select All
                  </Button>
                )}
                {hasOptions ? (
                  options.map((option, i) => (
                    <Listbox.Option
                      data-cy={`select-option-${i + 1}`}
                      id={option.label + i}
                      className={({ active }: { active: boolean }) =>
                        clsx(
                          'relative leading-5 text-ui flex items-center cursor-pointer select-none py-2 pl-3 pr-9 whitespace-pre overflow-hidden',
                          size === 'large' ? 'h-8 text-sm' : 'h-6 text-[13px]',
                          color && selectColors[color],
                          accessibilityColors,
                          active ? activeColors[color] : 'text-ui',
                          option?.disabled && '!text-ui-helper'
                        )
                      }
                      key={option.label + i}
                      value={option.value}
                      disabled={option?.disabled}
                    >
                      {option.type && (
                        <Icon name={getTypeIconPath(option.type)} className='flex-shrink-0 mr-1' />
                      )}
                      {option?.checkbox && (
                        <CheckboxInput
                          checked={option?.checked || false}
                          onChange={() => {}}
                          className='mr-2'
                          disabled={option?.disabled}
                        />
                      )}

                      {option.label}
                    </Listbox.Option>
                  ))
                ) : (
                  <Listbox.Option
                    value='No Options'
                    disabled
                    className={({ active }: { active: boolean }) =>
                      clsx(
                        'relative leading-5 text-ui flex items-center cursor-not-allowed select-none py-2 pl-3 pr-9 whitespace-pre overflow-hidden',
                        size === 'large' ? 'h-8 text-sm' : 'h-6 text-[13px]',
                        color && selectColors[color],
                        accessibilityColors,
                        active ? activeColors[color] : 'text-ui'
                      )
                    }
                  >
                    No Options
                  </Listbox.Option>
                )}
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </div>
    </InputField>
  );
};
