export const getSortedElementByName = (a, b) => {
  const nameA = a.FileName.toLowerCase();
  const nameB = b.FileName.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const getSortedElementByLastUpdated = (a, b) => {
  const lastUpdatedA = a.LastUpdated;
  const lastUpdatedB = b.LastUpdated;
  if (lastUpdatedA < lastUpdatedB) return 1;
  if (lastUpdatedA > lastUpdatedB) return -1;
  return 0;
};
