import { get } from './API';
import { URL_DATASET } from './constants';

export async function getClientState(location) {
  const uuid = location.includes('/') ? getfileUUID(location) : location;
  try {
    const row = await get(`${URL_DATASET}/${uuid}`);
    return row;
  } catch (err) {
    throw new Error(err);
  }
}

export function getfileUUID(location) {
  const splitPath = location.pathname.split('/');
  return splitPath[splitPath.length - 1];
}
