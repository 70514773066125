// file type
export const FILE_TYPE_OMNIVORE = 0;
export const FILE_TYPE_EXPORTER = 1;
export const FILE_TYPE_EXTERNAL = 3;

export const FILE_STATUS_PROCESSING = 'processing';
export const FILE_STATUS_UPLOADING = 'uploading';
export const FILE_STATUS_ERROR = 'error';
export const FILE_STATUS_LOADING = 'loading';
export const FILE_STATUS_PROCESSED = 'processed';
export const FILE_STATUS_ENRICHING = 'enriching';

export const FILE_PROCESSING_STATE = [
  FILE_STATUS_PROCESSING,
  FILE_STATUS_UPLOADING,
  FILE_STATUS_LOADING,
];

export const FILE_NOTE_CHARACTER_LIMIT = 500;
export const FILE_NOTE_EXCEEDS_LIMIT = 'Description exceeds character count';
