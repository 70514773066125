import { useDebounce } from '@/hooks/useDebounce';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { get, put } from '@/Utils/API';
import { noteEndpoint } from '@/Utils/apiEndpoints';
import {
  FILE_PERMISSIONS,
  ORGANIZATION_PERMISSIONS,
  PUBLIC_LABEL,
  QUERY_PARAM_DIR,
  RESTRICTED_LABEL,
  TOAST_TYPE_ERROR,
  URL_DATASET,
  URL_DATASETS,
  URL_SPREADSHEET,
  userTeamNameKey,
} from '@/Utils/constants';
import { FILE_NOTE_CHARACTER_LIMIT } from '@/Utils/fileConstants';
import { getFolderStatus } from '@/Utils/getFolderStatus';
import { parseFileSize } from '@/Utils/parseFileSize';
import { isReadOnly, isSharedByMe, isWorldReadable } from '@/Utils/sharing';
import { formatUrlSafeFileName, getNumberValueSeparatedByCommas } from '@/Utils/utils';

import Icon from '@/Components/UI/Icon/Icon';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';

import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';

import ThreeDotsLoader from '@/Components/Loader/ThreeDotsLoader/ThreeDotsLoader';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import { openGridToolPanelByName } from '@/redux/reducers/spreadsheetSlice';

import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const SharingVisibility = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [sharingVisibilityStatus, setSharingVisibilityStatus] = useState('');
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();

  const uuid = currentFile?.metadata?.FileUuid;

  const getPublicFilePermissions = async () => {
    return await get(`${URL_DATASET}/${uuid}/${FILE_PERMISSIONS}`);
  };

  const getOrganizationFilePermissions = async () => {
    return await get(`${URL_DATASET}/${uuid}/${ORGANIZATION_PERMISSIONS}`);
  };

  const getSharingVisibilityStatus = async () => {
    setIsFetching(true);

    try {
      const publicFilePermissionsFetch = getPublicFilePermissions();
      const organizationFilePermissionsFetch = getOrganizationFilePermissions();

      const publicFilePermissions = await publicFilePermissionsFetch;
      const organizationFilePermissions = await organizationFilePermissionsFetch;

      const status = organizationFilePermissions?.permissions?.length
        ? getTeamNameLabel(user[userTeamNameKey])
        : publicFilePermissions?.permissions?.length
        ? PUBLIC_LABEL
        : RESTRICTED_LABEL;
      setSharingVisibilityStatus(status);
    } catch (error) {
      setSharingVisibilityStatus('-');
      throw new Error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getSharingVisibilityStatus();
  }, []);

  return (
    <>
      <div
        className='flex items-center file_properties__value file_properties__value-sharing'
        data-cy='sharing-visibility-value'
      >
        {isFetching ? <ThreeDotsLoader text='' /> : <span>{sharingVisibilityStatus}</span>}
      </div>
    </>
  );
};

const fileOwner = (file) => {
  const { Owner: owner } = file.metadata;
  const isLibraryPage = location.pathname.includes('datasets');

  return (
    <>
      {isLibraryPage ? (
        <div className='file_properties__value !lowercase'>{owner}</div>
      ) : (
        <Tooltip text={owner.length > 24 ? owner : ''} side='bottom'>
          <div className={'file_properties__value !w-52 !lowercase pl-[27px] truncate'}>
            {owner}
          </div>
        </Tooltip>
      )}
    </>
  );
};

const fileSize = (file) => {
  const { FileSize: fileSize } = file.metadata;

  return <div className='file_properties__value'>{parseFileSize(fileSize)}</div>;
};

const fileRows = (file) => {
  return (
    <div className='file_properties__value'>
      {getNumberValueSeparatedByCommas(file.metadata.FileRows)}
    </div>
  );
};

const filePropertiesColumns = {
  size: fileSize,
  rows: fileRows,
  columns: 'FileColumns',
  owner: fileOwner,
  'sharing visibility': () => <SharingVisibility />,
  ...(location.pathname.includes('spreadsheet') && {
    description: (file, dispatch) => {
      const openEditDescription = () => {
        const textAreaDesc = document.querySelector('textarea[data-cy="file-note-input"]');
        dispatch(openGridToolPanelByName('stats'));
        setTimeout(() => {
          if (textAreaDesc) {
            textAreaDesc.focus();
          }
        }, 500);
      };
      return (
        <>
          <div className='relative file_properties__value'>
            <span
              role='button'
              onKeyUp={() => {}}
              tabIndex='0'
              onClick={openEditDescription}
              className='absolute !left-[-88px] cursor-pointer'
            >
              <Icon name='pencil-simple' size={16} />
            </span>
          </div>
        </>
      );
    },
  }),
};

function EditFile({
  fileInfo,
  filename,
  fileNote,
  handleNoteChange,
  handleChange,
  isDirectory,
  columns = null,
  onKeyDown = () => {},
  showUrlAddress = false,
  isFilePropertiesDropdownOpen = false,
  onFileNameBlur = () => {},
  shouldFocusInput = false,
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(isFilePropertiesDropdownOpen || false);
  const { filePath, currentDir, libraryFiles, currentFile } = useSelector(
    (state) => state[datasetSliceName]
  );
  const {
    user: {
      userProperties: { maxFileRows },
    },
  } = useSelector((state) => state[userDataSliceName]);

  const prevFilePath = useRef(null);
  const [characterCount, setCharacterCount] = useState(0);
  const [isParentFolderShared, setIsParentFolderShared] = useState(false);
  const [showDescriptionUpdatedLabel, setShowDescriptionUpdatedLabel] = useState(false);
  const [textareaHasFocus, setTextareaHasFocus] = useState(false);

  const { Note: note } = fileInfo || {};
  const { FileUuid: uuid, FileRows } = fileInfo?.metadata || {};

  const isLibraryPage = location.pathname.includes('datasets');

  useEffect(() => {
    let isMounted = true;
    const currentFilePath = JSON.stringify(libraryFiles);

    if (prevFilePath.current !== currentFilePath) {
      getFolderStatus(currentDir, filePath, fileInfo).then((response) => {
        if (isMounted) {
          setIsParentFolderShared(response);
          prevFilePath.current = currentFilePath;
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [isOpen, fileInfo, currentDir, filePath, libraryFiles]);

  const isFolderPublic =
    isSharedByMe(fileInfo) ||
    isWorldReadable(fileInfo) ||
    (isParentFolderShared && !fileInfo.metadata.Type);

  const noteAutoSaveDelayMs = 1500;
  const debouncedFileNote = useDebounce(fileNote, noteAutoSaveDelayMs); // Using useDebounce hook

  const prevFileNoteRef = useRef();
  const fileNameInputRef = useRef(null);
  const fileNoteInputRef = useRef(null);

  useEffect(() => {
    fileNameInputRef.current.focus();
  }, [shouldFocusInput]);

  useEffect(() => {
    if (fileNote !== null) {
      if (prevFileNoteRef.current?.length > 0 && prevFileNoteRef.current !== fileNote) {
        setShowDescriptionUpdatedLabel(false);
        setCharacterCount(fileNoteInputRef.current?.value?.length);
      }
      prevFileNoteRef.current = fileNote;
    }
  }, [fileNote]);

  useEffect(() => {
    if (prevFileNoteRef.current !== debouncedFileNote) {
      saveFileNotes(debouncedFileNote);
      prevFileNoteRef.current = debouncedFileNote;
    }
  }, [debouncedFileNote]);

  const toggleFilePropertiesDropdown = () => setIsOpen(!isOpen);

  const saveFileNotes = async (value) => {
    if (value.length > FILE_NOTE_CHARACTER_LIMIT) return setShowDescriptionUpdatedLabel(false);
    const endpoint = noteEndpoint(uuid);
    const payload = { note: value };
    put(endpoint, payload).then((response) => {
      if (!response.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: 'Unable to save file notes',
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      } else {
        setShowDescriptionUpdatedLabel(true);
      }
    });
  };

  const readOnly = isReadOnly(fileInfo);
  const shareUrl = isFolderPublic
    ? `${window.location.origin}/${URL_DATASETS}?${QUERY_PARAM_DIR}=${uuid}`
    : `${window.location.origin}/${URL_SPREADSHEET}/${formatUrlSafeFileName(filename)}/${uuid}`;

  const inputErrorStyles = '!border !border-violet-web-700 shadow-xs shadow-violet-web-50';

  const urlComponent = (
    <div className='mb-4'>
      <div className='relative'>
        <TextInput
          className='w-full text-sm text-ui-helper'
          label='Link'
          type='input'
          readOnly
          name='filename'
          value={shareUrl}
        />
      </div>
    </div>
  );

  const nameComponent = (
    <div className='mb-4'>
      <div>
        <TextInput
          ref={fileNameInputRef}
          className={clsx('w-full', filename.trim() === '' && inputErrorStyles)}
          label={!isFilePropertiesDropdownOpen ? '' : isDirectory ? 'Folder Name' : 'File Name'}
          name='filename'
          dataCy='file-name-input'
          value={filename}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={isDirectory ? 'Folder Name' : 'File Name'}
          onKeyDown={onKeyDown}
          autoComplete='off'
          readOnly={readOnly}
          onBlur={onFileNameBlur}
          labelClassName={filename.trim() === '' && '!text-violet-web-900'}
        />
      </div>
    </div>
  );

  const notesComponent = (
    <div className='mb-4 file-notes'>
      <div className='relative file-note'>
        <Textarea
          ref={fileNoteInputRef}
          className={clsx(
            'w-full !min-h-[65px] max-h-64',
            fileNote?.length > FILE_NOTE_CHARACTER_LIMIT && inputErrorStyles
          )}
          label='Description'
          placeholder='Description...'
          onChange={(event) => handleNoteChange(event.target.value)}
          onBlur={() => {
            setShowDescriptionUpdatedLabel(false);
            setTextareaHasFocus(false);
          }}
          value={fileNote === null ? note : fileNote}
          readOnly={readOnly}
          onFocus={() => {
            setCharacterCount(fileNoteInputRef.current?.value?.length);
            setTextareaHasFocus(true);
          }}
        />
      </div>
      <div className='w-full'>
        <span
          hidden={!showDescriptionUpdatedLabel}
          className={clsx(
            'absolute left-4 mt-1 px-1 text-[10px] bg-sunrise-100 text-midnight-900 !uppercase rounded-sm font-semibold',
            textareaHasFocus ? 'opacity-100' : 'opacity-0'
          )}
        >
          Description updated!
        </span>
        <span
          hidden={readOnly}
          className={clsx(
            'absolute right-4 mt-1 px-1 text-[10px] bg-capri-100 text-capri-900 uppercase rounded-sm font-semibold',
            fileNote?.length > FILE_NOTE_CHARACTER_LIMIT &&
              '!bg-violet-web-50 !text-violet-web-900',
            textareaHasFocus ? 'opacity-100' : 'opacity-0'
          )}
        >
          {characterCount}/500
        </span>
      </div>
    </div>
  );

  return (
    <>
      {(showUrlAddress && !isDirectory && urlComponent) ||
        (isDirectory && isFolderPublic && urlComponent)}
      {nameComponent}
      {showUrlAddress && notesComponent}

      {!isFilePropertiesDropdownOpen && !readOnly && (
        <Button
          variant='ghost'
          color='shadow'
          dataCy='file-details-btn'
          onClick={toggleFilePropertiesDropdown}
          iconName={isOpen ? 'caret-down' : 'caret-right'}
          alt='right-arrow'
          className='hover:!bg-white active:!bg-white !px-0 mb-1'
        >
          {!showUrlAddress && <span className='font-semibold'>File Properties</span>}
        </Button>
      )}
      {isOpen && (
        <div className='mb-4 file_properties'>
          <div className='px-1 file_properties__table' data-cy='file-properties-table'>
            {Object.entries(filePropertiesColumns).map((row, idx) => {
              const [key, property] = row;
              let value;

              if (typeof property === 'function') {
                value = property(fileInfo, dispatch);
              } else {
                value = <div className='file_properties__value'>{fileInfo[property]}</div>;
              }
              // file may not have FileColumns field so use given columns (length of headers)
              if (key === 'columns' && columns !== null) {
                value = (
                  <div className='file_properties__value'>
                    {getNumberValueSeparatedByCommas(columns)}
                  </div>
                );
              }
              return (
                <div
                  key={idx}
                  className={clsx('text-sm capitalize flex', isLibraryPage ? '!mb-3 ' : '!mb-2')}
                >
                  <div className='file_properties__property'>{key}</div>
                  {value}
                </div>
              );
            })}
          </div>
          <div className='w-full px-1 text-sm leading-normal text-ui-secondary'>
            {!showUrlAddress && note}
          </div>
        </div>
      )}
    </>
  );
}

export default EditFile;
