import Snackbar from '@material-ui/core/Snackbar';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Modal from '@/Components/UI/Modal/Modal';
import { useSelector, useDispatch } from 'react-redux';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import {
  setReduxFilterModelAggregationsCnf,
  setReduxFilterModelCnf,
  setReduxSavedFilterHandle,
} from '@/redux/reducers/spreadsheetSlice';

import {
  FILTER_MODEL_AGGREGATE_CNF_,
  FILTER_MODEL_CNF_,
  MODAL_EXCEEDS_LIMITS,
  MODAL_FILTER_PANEL,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_SAVED_FILTER_COLUMNS_INCOMPATIBLE_ERROR,
  TOAST_TEXT_UNABLE_DELETE_FILTER_TEMPLATE_ERROR,
  TOAST_TYPE_ERROR,
  userRolesKey,
} from '@/Utils/constants';
import getCurrentAppliedFilters from '@/Utils/getCurrentAppliedFilters';
import parseArrayToMatrix from '@/Utils/parseArrayToMatrix';
import {
  DEFAULT_FILTER_VALUE,
  deleteFilterTemplate,
  getFilterTemplateOnSheet,
  saveFilterTemplate,
} from '@/Utils/saveFilter';

import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';
import { Button } from '@/Components/UI/Button/Button';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';
import FilterPanelAggregations from './FilterPanelAggregations';
import FilterPanelFooter from './FilterPanelFooter';
import FilterPanelOrClause from './FilterPanelOrClause';
import FilterPanelSave from './FilterPanelSave';
import FilterPanelTypeButtons from './FilterPanelTypeButtons';
import CopyableJsonViev from './CopyableJsonView';

import { generateFilterValue, handleDateModels } from '@/helpers/filterPanelHelper';

import Icon from '@/Components/UI/Icon/Icon';
import { TemporaryLabel } from '@/helpers/upgradeHelper';
import './style/index.scss';
import { bodyComponent } from '../UpgradeAccount/PapercutModal';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { showModal } from '@/redux/reducers/modalsSlice';

/**
 * Expected props:
 *  targetColumnId - optional preselected column such as from a column menu action, or null
 *  hideModal - callback for when the panel should toggle open/close
 *  show - whether the panel should be open or closed
 *
 *
 * The structure of filterModel is described in ServerSideRowModel.
 * But basically it's this:
 *  _cnf_: [ [ {...}, {...}, ... ], [...], [...], ... ]
 * where it's under the key _cnf_ and it's list of lists of filters.
 * The outer list is ANDs and the inner lists are ORs of filter objects.
 * In the state of this component, we omit the _cnf_ key and add it back when reading/writing from the AgGrid API.
 *
 */

const initialFiltersState = {
  filterModelCnf: [],
  filterModelAggregationsCnf: [],
};
function FilterPanel({ hideModal, targetColumnId, show }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { clientState, reduxSavedFilterHandle } = useSelector(
    (state) => state[spreadsheetSliceName]
  );
  const currentFile = useCurrentFile();
  const { filterModel, columnState } = clientState || {};

  const { isFileShared, WithinQuota } = currentFile || {};
  const canUserUseAggregateFilter = user.userProperties.canAggregateFilter || false;

  const [loading, setLoading] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [saveStatus, setSaveStatus] = useState('Save');
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [anyFilterData, setAnyFilterData] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [modalBodyScrollTop, setModalBodyScrollTop] = useState(0);
  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [activePanel, setActivePanel] = useState(FILTER_MODEL_CNF_);
  const [previewPopupIsOpen, setPreviewPopupIsOpen] = useState(false);
  const [filterHandleToDelete, setFilterHandleToDelete] = useState('');
  const [currentAppliedFilter, setCurrentAppliedFilter] = useState(null);
  const [isDisabledApplyButton, setIsDisabledApplyButton] = useState(true);
  const [localFiltersState, setLocalFiltersState] = useState(initialFiltersState);
  const [savedFilterHandle, setSavedFilterHandle] = useState(reduxSavedFilterHandle);

  const modalBodyRef = useRef();

  const isPreventedAddingFilterPanelCondition =
    (!currentFile?.WithinQuota || currentFile?.OverRowQuota) &&
    activePanel === FILTER_MODEL_CNF_ &&
    localFiltersState.filterModelCnf.length >= 1;
  const isPreventedAddingAggregationPanelCondition =
    (!currentFile?.WithinQuota || currentFile?.OverRowQuota) &&
    activePanel === FILTER_MODEL_AGGREGATE_CNF_ &&
    localFiltersState.filterModelAggregationsCnf.length >= 1;

  function handleLocalFilterModelCnfChange(newFilterModelCnf) {
    setLocalFiltersState((prevState) => ({
      ...prevState,
      filterModelCnf: newFilterModelCnf,
    }));
  }
  function handleLocalFilterModelAggregationsCnfChange(newFilterModelAggregationsCnf) {
    setLocalFiltersState((prevState) => ({
      ...prevState,
      filterModelAggregationsCnf: newFilterModelAggregationsCnf,
    }));
  }

  function togglePopup(option) {
    setDeletePopupIsOpen(true);
    setFilterHandleToDelete(option);
  }

  function initializeFilterModelFromClientState(onFinished) {
    const _filterModel = filterModel;
    handleLocalFilterModelCnfChange(getCurrentAppliedFilters(_filterModel?._cnf_));
    handleLocalFilterModelAggregationsCnfChange(
      getCurrentAppliedFilters(_filterModel?._aggregate_cnf_)
    );
    onFinished();
  }

  useEffect(() => {
    if (show) {
      const isGroupedColumns = columnState.length && columnState.find(({ rowGroup }) => rowGroup);
      if (isGroupedColumns && activePanel === FILTER_MODEL_CNF_) {
        setActivePanel(FILTER_MODEL_CNF_);
      }
      if (!isGroupedColumns && activePanel === FILTER_MODEL_AGGREGATE_CNF_) {
        setActivePanel(FILTER_MODEL_CNF_);
      }
      setLoading(true);
      initializeFilterModelFromClientState(() => {
        if (
          !localFiltersState[activePanel].length &&
          (isPreventedAddingFilterPanelCondition || isPreventedAddingAggregationPanelCondition)
        ) {
          addNewFilter(targetColumnId);
        }
        setLoading(false);
      });
    }
  }, [show]);

  function onResetFilterPanel() {
    if (activePanel === FILTER_MODEL_CNF_) {
      dispatch(setReduxFilterModelCnf([]));
      handleLocalFilterModelCnfChange([]);
    }
    if (activePanel === FILTER_MODEL_AGGREGATE_CNF_) {
      dispatch(setReduxFilterModelAggregationsCnf([]));
    }
    setSavedFilterHandle('');
    setCurrentAppliedFilter(null);
    hideModal();
  }

  function onCloseFilterPanel() {
    setSavedFilterHandle('');
    setCurrentAppliedFilter(null);
    setOpenSnackBar(false);
    setLoading(false);
    handleLocalFilterModelCnfChange([]);
    handleLocalFilterModelAggregationsCnfChange([]);
    hideModal();
  }

  function onChangeFilterType(andIndex) {
    const activeFilterModel = activePanel;
    const { filterModelAggregationsCnf, filterModelCnf } = localFiltersState;

    if (activeFilterModel === FILTER_MODEL_CNF_) {
      return handleLocalFilterModelCnfChange(
        filterModelCnf.map((e, i) => (i === andIndex ? { ...e, isChecked: !e.isChecked } : e))
      );
    }
    if (activeFilterModel === FILTER_MODEL_AGGREGATE_CNF_) {
      return handleLocalFilterModelAggregationsCnfChange(
        filterModelAggregationsCnf.map((e, i) =>
          i === andIndex ? { ...e, isChecked: !e.isChecked } : e
        )
      );
    }
  }
  function addNewFilter(columnOrUndefined) {
    const activeFilterModel = activePanel;
    setLocalFiltersState((prevState) => {
      const newFilter =
        activePanel === FILTER_MODEL_CNF_
          ? { colId: columnOrUndefined, isChecked: false, isCaseSensitive: false }
          : { colId: columnOrUndefined, isChecked: false };
      return {
        ...prevState,
        [activeFilterModel]: [...prevState[activeFilterModel], newFilter],
      };
    });
  }

  function onAddConditionButtonClick() {
    if (isFiltersOverLimit(1)) {
      dispatch(
        addMixpanelEvent({
          eventName:
            activePanel === FILTER_MODEL_AGGREGATE_CNF_
              ? 'Filter groups paywall (add condition button)'
              : 'Filter rows paywall (add condition button)',
          eventProps: { current_filename: currentFile?.metadata.FileName },
          userIncrementName: '# of filter use',
        })
      );

      return setIsUpgradeModalOpen(true);
    }

    if (isPreventedAddingAggregationPanelCondition || isPreventedAddingFilterPanelCondition) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    addNewFilter();
  }

  function onFilterModelElementChange(andIndex, newSubModelOrNull) {
    setLocalFiltersState((prevState) => {
      const activeFilterModel = activePanel;
      const updatedFilterModel = prevState[activeFilterModel].map((e, i) =>
        i === andIndex ? newSubModelOrNull : e
      );
      const filteredFilterModel = updatedFilterModel.filter((subModel) => subModel !== null);
      return {
        ...prevState,
        [activeFilterModel]: filteredFilterModel,
      };
    });
  }

  const handleDeleteSavedFilterTemplate = async () => {
    const deleted = await deleteFilterTemplate(filterHandleToDelete);
    if (!deleted.Success)
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UNABLE_DELETE_FILTER_TEMPLATE_ERROR,
        errorContext: deleted,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    if (savedFilterHandle === filterHandleToDelete) {
      setSavedFilterHandle('');
      handleLocalFilterModelCnfChange([{ colId: null, isChecked: false, isCaseSensitive: false }]);
      setCurrentAppliedFilter(DEFAULT_FILTER_VALUE);
    }
    setDeletePopupIsOpen(false);
    setFilterHandleToDelete('');
    setSavedFilters(
      savedFilters.filter(({ filterHandle }) => filterHandle !== filterHandleToDelete)
    );
  };
  async function applySavedFilterTemplate(filterHandle) {
    setLoading;
    let timeout = 0;
    setSavedFilterHandle(filterHandle);
    const template = await getFilterTemplateOnSheet(filterHandle);

    if (!template.name) {
      timeout = 500;
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_SAVED_FILTER_COLUMNS_INCOMPATIBLE_ERROR,
        errorContext: template,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      setCurrentAppliedFilter(DEFAULT_FILTER_VALUE);
    } else {
      const newFilterModel = getCurrentAppliedFilters(template.filterModel?._cnf_);
      handleLocalFilterModelCnfChange(newFilterModel);
      setCurrentAppliedFilter({
        label: template.name,
        value: template.id,
        filterHandle: template.id,
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, timeout);
    buildRowsFromFilterState();
  }

  function onApplyCnfModel() {
    const { filterModelCnf } = localFiltersState;
    const dateModels = filterModelCnf.filter((el) => el.filterType === 'date');
    if (dateModels.length) {
      handleDateModels(
        dateModels,
        showDateValidationNotification,
        applyFilterModelCnfFromState,
        hideModal
      );
    } else {
      applyFilterModelCnfFromState();
      hideModal();
    }
  }

  function onApplyAggregationModel() {
    const { filterModelAggregationsCnf } = localFiltersState;
    if (parseArrayToMatrix(filterModelAggregationsCnf).length > 0) {
      dispatch(setReduxFilterModelAggregationsCnf(parseArrayToMatrix(filterModelAggregationsCnf)));
      handleLocalFilterModelAggregationsCnfChange(parseArrayToMatrix(filterModelAggregationsCnf));
    } else {
      dispatch(setReduxFilterModelAggregationsCnf([]));
    }
  }

  // This must align with what the backend expects.
  // The interface is described in ServerSideDataSource.setFilterModel.
  function applyFilterModelCnfFromState() {
    const { filterModelCnf } = localFiltersState;
    const modelWithAnyColumnsValue = filterModelCnf.map((model, index) => {
      const newModel = { ...model };
      if (index === anyFilterData.index && model.colId === '*') {
        newModel.filter = newModel.filter
          ? [...newModel.filter, anyFilterData.newValue]
          : [anyFilterData.newValue];
      }
      return newModel;
    });
    if (
      parseArrayToMatrix(modelWithAnyColumnsValue).length > 0 &&
      modelWithAnyColumnsValue[0].colId
    ) {
      dispatch(
        setReduxFilterModelCnf(
          parseArrayToMatrix(
            anyFilterData?.newValue?.length ? modelWithAnyColumnsValue : filterModelCnf
          )
        )
      );
    } else {
      dispatch(setReduxFilterModelCnf([]));
    }
  }

  function showDateValidationNotification(message, isShow) {
    setErrorMessage(message);
    setOpenSnackBar(isShow);
  }

  function onClickApply() {
    if (isApplyButtonShowsUpgradeModal()) {
      dispatch(
        addMixpanelEvent({
          eventName:
            activePanel === FILTER_MODEL_AGGREGATE_CNF_
              ? 'Filter groups paywall (apply button)'
              : 'Filter rows paywall (apply button)',
          eventProps: {
            current_filename: currentFile?.metadata.FileName,
          },
          userIncrementName: '# of filter use',
        })
      );

      return setIsUpgradeModalOpen(true);
    }

    if (activePanel === FILTER_MODEL_AGGREGATE_CNF_) {
      onApplyAggregationModel();
      hideModal();
    } else {
      onApplyCnfModel();
    }

    dispatch(setReduxSavedFilterHandle(savedFilterHandle));
    setSavedFilterHandle(savedFilterHandle);

    dispatch(
      addMixpanelEvent({
        eventName: 'Filter Used',
        eventProps: { current_filename: currentFile?.metadata.FileName },
        userIncrementName: '# of filter use',
      })
    );
  }

  function isFiltersOverLimit(step) {
    const { filterModelAggregationsCnf, filterModelCnf } = localFiltersState;
    const activeFilterModelCnf =
      activePanel === FILTER_MODEL_CNF_ ? filterModelCnf : filterModelAggregationsCnf;
    return user.userProperties.maxFilters < activeFilterModelCnf.length + (step || 0);
  }
  function isApplyButtonShowsUpgradeModal() {
    return (
      (!canUserUseAggregateFilter && activePanel === FILTER_MODEL_AGGREGATE_CNF_) ||
      isFiltersOverLimit()
    );
  }
  function isApplyBtnDisabledForGroupsFilter() {
    const { filterModelAggregationsCnf } = localFiltersState;
    const relevantRows = filterModelAggregationsCnf?.filter((el) => {
      return el.colId && typeof el.colId !== 'object';
    });
    return !relevantRows.every((el) => {
      return (
        !!el?.colId &&
        !!el?.type &&
        (el?.type === 'isBlank' || el?.type === 'isNotBlank'
          ? true
          : Array.isArray(el?.filter)
          ? !!el?.filter?.length && el?.filter?.every((el) => !!Number(el) || el === '0')
          : Number(el?.filter))
      );
    });
  }

  useLayoutEffect(() => {
    const isDisabled =
      activePanel === FILTER_MODEL_CNF_
        ? localFiltersState[activePanel]
            .filter((element) => element.colId || element.filter || element.type)
            .some((filter) => !(filter.colId && filter.type && generateFilterValue(filter))) ||
          (localFiltersState.filterModelCnf.length === 1 &&
            localFiltersState.filterModelCnf[0].colId === undefined)
        : isApplyBtnDisabledForGroupsFilter();

    setIsDisabledApplyButton(isDisabled);
    if (!localFiltersState[activePanel].length) {
      addNewFilter(targetColumnId);
    }
  }, [
    localFiltersState,
    activePanel,
    localFiltersState.filterModelCnf.length,
    localFiltersState.filterModelAggregationsCnf.length,
  ]);

  const handleSaveShortcut = async () => {
    const response = await saveFilterTemplate(
      savedFilterHandle,
      localFiltersState.filterModelCnf,
      currentAppliedFilter?.label
    );
    if (response) setSaveStatus('Saved');
    setTimeout(() => setSaveStatus('Save'), 2000);
  };
  function mapAggregationsCnf() {
    const { filterModelAggregationsCnf } = localFiltersState;
    return filterModelAggregationsCnf.map((filterModelElement, i) => (
      <FilterPanelAggregations
        key={i}
        index={i}
        filterModelElement={filterModelElement}
        onFilterChange={onFilterModelElementChange}
        onChangeFilterType={onChangeFilterType}
        filterModel={filterModelAggregationsCnf}
        setAnyFilterData={setAnyFilterData}
        setIsDisabledApplyButton={() => {}}
        activePanel={activePanel}
      />
    ));
  }

  function mapFilterCnf() {
    const { filterModelCnf } = localFiltersState;
    return filterModelCnf.map((filterModelElement, i) => (
      <FilterPanelOrClause
        key={i}
        index={i}
        filterModelElement={filterModelElement}
        onFilterChange={onFilterModelElementChange}
        onChangeFilterType={onChangeFilterType}
        filterModel={filterModelCnf}
        setIsDisabledApplyButton={setIsDisabledApplyButton}
        modalBodyScrollTop={modalBodyScrollTop}
        setAnyFilterData={setAnyFilterData}
      />
    ));
  }
  function buildRowsFromFilterState() {
    if (loading) return <SkeletonLoader header={false} loadingBars={1} />;
    return activePanel === FILTER_MODEL_AGGREGATE_CNF_ ? mapAggregationsCnf() : mapFilterCnf();
  }

  function handleSaveShortcutIfValid(e) {
    if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
      if (currentAppliedFilter?.label && !isDisabledApplyButton) {
        handleSaveShortcut(e);
        e.preventDefault();
      }
    }
  }
  function handleClose(reason) {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  }

  function switchFilterModel(modelType) {
    setActivePanel(modelType);
  }

  return (
    <div role='button' tabIndex={0} onKeyDown={handleSaveShortcutIfValid}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        severity='success'
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={errorMessage}
      />
      <Modal
        isOpen={show}
        onClose={onCloseFilterPanel}
        modalClassName='!w-[1150px] !max-w-[1150px] !min-h-[272px]'
        dataCy='filter-panel-modal'
        articleID='69000774350'
        title={
          <>
            <div className='flex flex-row items-center gap-2 mb-2'>
              <Icon name='filter' size='30' />
              <h4>Filter</h4>
              {(isFileShared || !WithinQuota) && <TemporaryLabel />}
            </div>
            <FilterPanelTypeButtons
              switchFilterModel={switchFilterModel}
              activePanel={activePanel}
              canUserUseAggregateFilter={canUserUseAggregateFilter}
            />
            <div
              className='absolute !m-0 right-20 top-2'
              hidden={!user[userRolesKey].filter((i) => i === 'giga-feature-api-key').length}
            >
              <Button
                onClick={() => setPreviewPopupIsOpen(true)}
                size='small'
                className='!p-0 !m-0 !w-8 !h-8'
                variant='ghost'
              >
                <Icon name='gear' />
              </Button>
            </div>
          </>
        }
      >
        {!user?.is_anonymous && activePanel === FILTER_MODEL_CNF_ && (
          <FilterPanelSave
            onFilterChange={applySavedFilterTemplate}
            filterModelCnf={localFiltersState.filterModelCnf}
            savedFilters={savedFilters}
            setSavedFilters={setSavedFilters}
            saveStatus={saveStatus}
            setSaveStatus={setSaveStatus}
            isDisabledApplyButton={isDisabledApplyButton}
            savedFilterHandle={savedFilterHandle}
            setSavedFilterHandle={setSavedFilterHandle}
            setCurrentAppliedFilter={setCurrentAppliedFilter}
            currentAppliedFilter={currentAppliedFilter}
            togglePopup={togglePopup}
            openUpgradeModal={setIsUpgradeModalOpen}
          />
        )}
        <div className='header-panel'>
          <div
            tabIndex={0}
            role='button'
            onKeyDown={() => {}}
            onClick={hideModal}
            className='btn-close'
          />
        </div>
        <div
          onScroll={() => {
            const { scrollTop } = modalBodyRef.current;
            setModalBodyScrollTop(scrollTop);
          }}
          ref={modalBodyRef}
        >
          {loading ? (
            <SkeletonLoader header={false} loadingBars={1} />
          ) : (
            <>
              <div className='overflow-y-auto max-h-[430px] p-3'>
                <div className='filter-panel-container'>{buildRowsFromFilterState()}</div>
                <Button
                  color='shadow'
                  variant='outline'
                  size='large'
                  iconName='plus'
                  dataCy='add-btn'
                  onClick={onAddConditionButtonClick}
                >
                  <span className='flex flex-row items-center'>
                    Add Condition
                    {isFiltersOverLimit(1) && (
                      <Icon name='rocket-launch' size={20} color='#D9B500' className='ml-1' />
                    )}
                  </span>
                </Button>
              </div>
            </>
          )}
          <FilterPanelFooter
            onResetFilterPanel={onResetFilterPanel}
            hideModal={hideModal}
            onClickApply={onClickApply}
            isDisabledApplyButton={isDisabledApplyButton}
            isApplyButtonShowsUpgradeModal={isApplyButtonShowsUpgradeModal}
            isFiltersOverLimit={isFiltersOverLimit}
          />
        </div>

        <Modal
          size='x-small'
          isOpen={deletePopupIsOpen}
          onClose={() => {
            setDeletePopupIsOpen(false);
            setFilterHandleToDelete('');
          }}
          withoutCloseButton={true}
        >
          <div data-cy='saved-filters-delete-popup' className='mt-[-45px] mb-3'>
            Are you sure you want to delete this filter?
          </div>
          <div className='flex justify-end gap-2'>
            <Button
              variant='ghost'
              onClick={() => {
                setDeletePopupIsOpen(false);
                setFilterHandleToDelete('');
              }}
            >
              Cancel
            </Button>
            <Button
              color='oceanBlue'
              dataCy='saved-filters-delete-btn'
              onClick={handleDeleteSavedFilterTemplate}
            >
              Delete
            </Button>
          </div>
        </Modal>
        <Modal
          size='medium'
          title='Filter Model'
          isOpen={previewPopupIsOpen}
          onClose={() => setPreviewPopupIsOpen(false)}
        >
          <CopyableJsonViev
            data={{
              _aggregate_cnf_: filterModel?._aggregate_cnf_,
              _cnf_: filterModel?._cnf_,
            }}
          />
        </Modal>
      </Modal>
      <Modal
        title='You could use an upgrade'
        size='medium'
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      >
        {bodyComponent(() => setIsUpgradeModalOpen(false))}
      </Modal>
    </div>
  );
}
export default withModal({ name: MODAL_FILTER_PANEL })(FilterPanel);
