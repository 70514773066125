import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';

import { getNumberValueSeparatedByCommas } from '@/Utils/utils';
import { getAggregationOptionsByFieldType } from '../../../Utils/getAggreagationOptionsByFieldType';

import Icon from '../../../Components/UI/Icon/Icon';

import './style/index.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { datasetSliceName, spreadsheetSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { AGGREGATION_ERROR, MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

const styles = () => ({
  input: {
    fontFamily: 'Overpass',
    height: '15px',
    fontSize: '10px',
    color: '#424242',
    '&::placeholder': {
      opacity: 1,
      color: '#B0B0B0',
      fontWeight: 400,
    },
    cursor: 'pointer',
  },
  listbox: {
    boxShadow: 'none',
    padding: 0,
    border: '1px solid #D6D6D6',
    borderRadius: 0,
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#FFFFFF',
      borderLeft: '1px solid #D6D6D6',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#B0B0B0',
      borderRadius: '50px',
    },
  },
  option: {
    backgroundColor: '#FFFFFF',
    padding: '8px 12px',
    color: '#1B1B1B',
    fontFamily: 'Overpass',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    opacity: 1,
    borderBottom: '1px solid #D6D6D6',
    '&:hover': {
      color: '#1B1B1B',
    },
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  noOptions: {
    color: '#1B1B1B',
    height: '34px',
    fontSize: '12px',
    lineHeight: '18px',
    border: '1px solid #D9DBDF',
    padding: '8px',
    margin: 0,
  },
  paper: {
    borderRadius: 0,
  },
  root: {
    position: 'relative',
    width: 'auto',
    '& .MuiFormControl-fullWidth': {
      width: 'auto',
      maxWidth: '110px',
    },
  },
  inputRoot: {
    paddingLeft: '20px',
  },
  endAdornment: {
    position: 'absolute',
    left: 0,
    right: 'auto',
    marginLeft: '6px',
  },
});

const optionsWithPercent = ['Percent Empty', 'Percent Filled', 'Percent Unique'];
const defaultAggregationOption = {
  label: '',
  value: '',
  aggValue: '',
};

const FunctionSelect = ({ classes, agGrid, column, isCellAggSelector, aggCellValue }) => {
  const { isFileShared, WithinQuota, OverRowQuota, fileSize } = useSelector((state) => {
    const currentFile = state[datasetSliceName]?.currentFile || {};
    return {
      isFileShared: currentFile?.isFileShared,
      WithinQuota: currentFile?.WithinQuota,
      OverRowQuota: currentFile?.OverRowQuota,
      fileSize: currentFile?.metadata?.FileSize,
    };
  }, shallowEqual);

  const {
    aggregationEndpointValues,
    clientState,
    isAggregationEndpointValuesLoading,
    isDeletingRows,
    isDeletingColumns,
    isInsertingNewColumn,
  } = useSelector((state) => state[spreadsheetSliceName]);

  const dispatch = useDispatch();
  const isAggregationLoadingAndDisable =
    isAggregationEndpointValuesLoading ||
    isDeletingRows ||
    isDeletingColumns ||
    isInsertingNewColumn;

  const [selectedAggregationOption, setSelectedAggregationOption] =
    useState(defaultAggregationOption);
  const aggregationOptionRef = useRef(null);

  const { colDef, aggFunc } = column;

  const isAggregationsPrevented = (!WithinQuota || OverRowQuota) && !isFileShared && fileSize;
  const options = isAggregationsPrevented ? [] : getAggregationOptionsByFieldType(colDef.fieldType);
  const isGroupedData = agGrid.columnApi.getRowGroupColumns().length > 0;

  useEffect(() => {
    if (isAggregationsPrevented) return setSelectedAggregationOption(defaultAggregationOption);

    const aggFuncOption = options.find((el) => el.value === aggFunc);
    if (aggFunc) {
      const { aggFunc: aggregationName } =
        clientState.columnState?.find((el) => el.colId === colDef.field) || {};
      const { value: aggregationValue } =
        aggregationEndpointValues?.find((el) => el.column === colDef.field) || {};
      if (isGroupedData) {
        return setSelectedAggregationOption({
          ...aggFuncOption,
          aggValue: aggCellValue ?? aggregationValue,
        });
      } else {
        const displayValue = aggregationValue !== undefined ? aggregationValue : '';
        if (!aggregationName && !aggregationValue) {
          return setSelectedAggregationOption(defaultAggregationOption);
        }
        setSelectedAggregationOption({
          label: aggFuncOption?.label,
          value: aggregationName,
          aggValue: displayValue,
        });
      }
    }
  }, [
    agGrid.api,
    agGrid.columnApi,
    aggCellValue,
    aggFunc,
    column,
    isGroupedData,
    options,
    aggregationEndpointValues,
  ]);

  useEffect(() => {
    if (
      aggregationOptionRef.current &&
      aggregationOptionRef.current.scrollWidth > aggregationOptionRef.current.offsetWidth
    ) {
      const widthToAdd = column.colDef.minWidth + aggregationOptionRef.current.scrollWidth;
      agGrid.columnApi.setColumnWidth(column.colId, widthToAdd);
    }
  }, [agGrid.columnApi, aggregationOptionRef, column, selectedAggregationOption]);

  const ungroupedSelectChange = async (option) => {
    if (option.label === 'None') {
      agGrid.columnApi.removeValueColumn(colDef.field);
      agGrid.columnApi.setColumnAggFunc(colDef.field, '');
      return setSelectedAggregationOption(defaultAggregationOption);
    }
    agGrid.columnApi.addValueColumn(colDef.field);
    agGrid.columnApi.setColumnAggFunc(colDef.field, option.value);
    setSelectedAggregationOption({
      ...option,
      aggValue: aggregationEndpointValues.find((el) => el.column === colDef.field)?.value,
    });
  };

  const groupedSelectChange = (option) => {
    if (option.label === 'None') {
      agGrid.columnApi.removeValueColumn(colDef.field);
      agGrid.columnApi.setColumnAggFunc(colDef.field, '');
      setSelectedAggregationOption(defaultAggregationOption);
    } else {
      agGrid.columnApi.addValueColumn(colDef.field);
      agGrid.columnApi.setColumnAggFunc(colDef.field, option.value);
      setSelectedAggregationOption({ ...option, aggValue: aggCellValue });
    }
  };

  const trackMixpanelEvent = () => {
    dispatch(
      addMixpanelEvent({
        eventName: `Aggregation (${isGroupedData ? 'Grouped' : 'Ungrouped'})`,
        eventProps: {},
        userIncrementName: '# of aggregations used',
      })
    );
  };

  const getIsAggregationApplied = (selectedAggregationOption) => {
    const { aggValue, value } = selectedAggregationOption;
    return (
      aggValue ||
      aggValue === 0 ||
      (value && aggValue === '') ||
      (value && aggValue === 0) ||
      (value && aggValue === false)
    );
  };

  const isBoolean = (value) => {
    return typeof value === 'boolean';
  };
  const getUngroupedAggStyles = () => {
    return `not-agg-func-cell-select ${
      getIsAggregationApplied(selectedAggregationOption) ? '!bg-[#DAD8F6]' : ''
    }`;
  };
  const getGroupedAggStyles = () => {
    return 'aggregation-function-select-group agg-func-cell-select ';
  };
  return (
    <Autocomplete
      disabled={isAggregationLoadingAndDisable}
      className={`aggregation-function-select ${
        isCellAggSelector ? getGroupedAggStyles() : getUngroupedAggStyles()
      }`}
      options={options}
      value={
        isCellAggSelector && selectedAggregationOption.value === 'none'
          ? ''
          : selectedAggregationOption
      }
      getOptionLabel={(option) => option.label || ''}
      freeSolo={isAggregationsPrevented}
      onOpen={() => {
        if (isAggregationsPrevented) {
          return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
        }
      }}
      onChange={(event, option) => {
        trackMixpanelEvent(isGroupedData);
        isGroupedData ? groupedSelectChange(option) : ungroupedSelectChange(option);
      }}
      autoComplete
      disableClearable
      popupIcon={
        isCellAggSelector && selectedAggregationOption.value === 'none' ? (
          ''
        ) : isCellAggSelector ? (
          <Icon name='caret-down-regular' size={10} alt='chevron-down' className='text-black' />
        ) : (
          <Icon name='caret-down-regular' size={14} alt='chevron-down' />
        )
      }
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        paper: classes.paper,
        listbox: classes.listbox,
        option: classes.option,
        noOptions: classes.noOptions,
        input: ` ${classes.input} ${isAggregationLoadingAndDisable && '!text-ui-helper'}`,
        endAdornment: classes.endAdornment,
      }}
      renderInput={(params) => {
        const valueWithoutPercent = optionsWithPercent.includes(params.inputProps.value)
          ? params.inputProps.value.split('Percent ')[1]
          : params.inputProps.value;
        const isPercentageOption =
          selectedAggregationOption.aggValue !== undefined &&
          optionsWithPercent.includes(params.inputProps.value);

        const isEmptyValue =
          selectedAggregationOption.aggValue === '' || selectedAggregationOption.aggValue === 0;

        const isUndefinedGroupedData =
          isGroupedData && selectedAggregationOption.aggValue === undefined;

        const aggValue = isPercentageOption ? (
          <span>{selectedAggregationOption.aggValue}&#37;</span>
        ) : isEmptyValue ? (
          selectedAggregationOption.aggValue
        ) : isUndefinedGroupedData ? (
          ''
        ) : isBoolean(selectedAggregationOption.aggValue) ? (
          selectedAggregationOption.aggValue.toString()
        ) : (
          getNumberValueSeparatedByCommas(selectedAggregationOption.aggValue)
        );
        const changedParams = {
          ...params,
          inputProps: {
            ...params.inputProps,
            value:
              valueWithoutPercent === '' ||
              (isGroupedData && selectedAggregationOption.aggValue === undefined)
                ? ''
                : valueWithoutPercent,
          },
        };

        return (
          <div className='relative flex items-center aggregation-function-name'>
            <TextField {...changedParams} InputProps={{ ...params.InputProps, readOnly: true }} />
            {selectedAggregationOption.aggValue === AGGREGATION_ERROR ? (
              <Tooltip text='Failed to calculate aggregation. Please try again later.' side='right'>
                <Icon name='smiley-sad' className='mr-1' size={16} />
              </Tooltip>
            ) : (
              <span
                ref={aggregationOptionRef}
                className='aggregation-function-value'
                data-cy='aggregation-function-value'
              >
                {selectedAggregationOption.aggValue !== '' ? aggValue : ''}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(FunctionSelect);
