import { useEffect, useRef, useState } from 'react';

import withModal from '@/Components/Modals/withModalHOC';
import withUploadModal from '@/Components/Modals/withUploadModal';
import {
  MODAL_CREATE_FOLDER,
  MY_LIBRARY,
  MODAL_GENERAL_UPGRADE,
  PERMISSIONS,
  PERMISSION_WRITE,
  ROUTE_IMPORTS,
  URL_DATASET,
  userTeamKey,
  userTeamNameKey,
} from '@/Utils/constants';
import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { useDispatch, useSelector } from 'react-redux';

import { getFilesSizeInPercentage } from '@/Utils/utils';
import useOnClickOutsideHook from '@/hooks/useOnClickOutside';
import useViewportProvider from '@/hooks/useViewportProvider';

import NewFileMenu from '@/Components/NewFileMenu/NewFileMenu';
import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import { get } from '@/Utils/API';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import DatasetTree from '@/pages/DatasetPage/DatasetSidePanel/DatasetTree';
import LibrarySearch from '@/pages/DatasetPage/HeaderControlGroup/LibrarySearch';
import { showModal } from '@/redux/reducers/modalsSlice';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { setCurrentDataset } from '@/redux/reducers/datasetSlice';

const DatasetSidePanel = ({ openUploadModal, isButtonsDisabled = false }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useViewportProvider();

  const [isOpenAddMenu, setIsOpenAddMenu] = useState(false);
  const {
    folderStructure,
    sharedWithMeFolderStructure,
    sharedWithMyTeamFolderStructure,
    sortingData,
    currentDataset,
  } = useSelector((state) => state[datasetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const isSharedWithMeTabActive = currentDataset === 'Shared With Me';

  const [isExpandedPanel, setIsExpandedPanel] = useState(!isMobileScreen);
  const [currentFolderPermissions, setCurrentFolderPermissions] = useState([]);

  const addNewFileMenu = useRef(null);
  const teamLabel = getTeamNameLabel(user[userTeamNameKey]);

  const _setIsOpenAddMenu = () => {
    setIsOpenAddMenu(!isOpenAddMenu);
  };

  const onPanelClick = () => {
    if (!isMobileScreen) return;
    setIsExpandedPanel(!isExpandedPanel);
  };

  useEffect(() => {
    if (sortingData.currentDirectory !== '') {
      get(`${URL_DATASET}/${sortingData.currentDirectory}/${PERMISSIONS}`).then((response) => {
        setCurrentFolderPermissions(response.permissions);
      });
    }
  }, [sortingData]);

  useOnClickOutsideHook(isOpenAddMenu, addNewFileMenu, _setIsOpenAddMenu);

  const openPopupToUploadFile = () => {
    setIsOpenAddMenu(false);
    openUploadModal();
  };

  const isNewFileBtnDisabled = () => {
    const isAtSharedWithMeRoot = isSharedWithMeTabActive && sortingData?.currentDirectory === '';
    const readOnlyFolder =
      isSharedWithMeTabActive && !currentFolderPermissions.includes(PERMISSION_WRITE);
    const quotaExceeded = getFilesSizeInPercentage >= 100;

    return isAtSharedWithMeRoot || quotaExceeded || readOnlyFolder || isButtonsDisabled;
  };

  const datasetTreeSharedWithMyTeam =
    sharedWithMyTeamFolderStructure?.length > 0
      ? sharedWithMyTeamFolderStructure
      : [{ FileName: teamLabel, FileUuid: 0, Children: [] }];

  const datasetTreeOwnedByMe =
    folderStructure?.length > 0
      ? folderStructure
      : [{ FileName: 'Library', FileUuid: 0, Children: [] }];
  const datasetTreeSharedWithMe =
    sharedWithMeFolderStructure?.length > 0
      ? sharedWithMeFolderStructure
      : [{ FileName: 'Shared With Me', FileUuid: 0, Children: [] }];

  const datasetOptions = user[userTeamKey]
    ? [
        { folders: datasetTreeSharedWithMyTeam, isSharedWithMe: true, iconName: 'users-three' },
        { folders: datasetTreeOwnedByMe, isSharedWithMe: false, iconName: 'folder' },
        { folders: datasetTreeSharedWithMe, isSharedWithMe: true, iconName: 'folder-user' },
      ]
    : [
        { folders: datasetTreeOwnedByMe, isSharedWithMe: false, iconName: 'folder' },
        { folders: datasetTreeSharedWithMe, isSharedWithMe: true, iconName: 'folder-user' },
      ];

  const handleScheduledImportsClick = () => {
    dispatch(setCurrentDataset(MY_LIBRARY));
  };

  return (
    <div
      role='button'
      tabIndex={0}
      onKeyUp={() => {}}
      className={clsx(
        !isExpandedPanel && isMobileScreen && 'collapsed-panel',
        'container-left-panel hidden md:flex bg-white border-r border-ui-200'
      )}
      onClick={onPanelClick}
    >
      <div className='datasets-header__control-right'>
        <div className='side-panel__container'>
          <LibrarySearch />
          {isOpenAddMenu ? (
            <div ref={addNewFileMenu}>
              <NewFileMenu toggleMenu={_setIsOpenAddMenu} onClickUpload={openPopupToUploadFile} />
            </div>
          ) : null}

          <Button
            onClick={_setIsOpenAddMenu}
            dataCy='side-panel__add-new-btn'
            color='sunrise'
            disabled={isNewFileBtnDisabled()}
            iconName='plus'
            size='large'
            className='w-full mb-2'
          >
            {(isExpandedPanel || !isMobileScreen) && 'New'}
          </Button>

          <div className='flex flex-col gap-3 pt-4'>
            {user[userTeamKey] ? (
              <DatasetTree
                key={0}
                folders={datasetTreeSharedWithMyTeam}
                isSharedWithMe={datasetOptions[0].isSharedWithMe}
                icon='users-three'
                isExpandedPanel={isExpandedPanel}
                setIsExpandedPanel={setIsExpandedPanel}
                teamLabel={teamLabel}
              />
            ) : (
              <Button
                variant='ghost'
                className='!justify-start'
                onClick={() =>
                  dispatch(
                    showModal({
                      name: MODAL_GENERAL_UPGRADE,
                      props: { premiumFeature: 'teamWorkspace' },
                    })
                  )
                }
              >
                <div className='flex flex-row gap-2 text-ui'>
                  <Icon name='users-three' />
                  Add Team Workspace
                  <Icon name='plus' size={14} className='mt-[2px] ml-3' />
                </div>
              </Button>
            )}
            <DatasetTree
              key={1}
              folders={datasetTreeOwnedByMe}
              isSharedWithMe={false}
              icon='folder'
              isExpandedPanel={isExpandedPanel}
              setIsExpandedPanel={setIsExpandedPanel}
              teamLabel={teamLabel}
            />
            <DatasetTree
              key={2}
              folders={datasetTreeSharedWithMe}
              isSharedWithMe={true}
              icon='folder-user'
              isExpandedPanel={isExpandedPanel}
              setIsExpandedPanel={setIsExpandedPanel}
              teamLabel={teamLabel}
            />
          </div>
          <NavLink
            to={ROUTE_IMPORTS}
            className={({ isActive }) => {
              return `flex rounded h-8 pl-6 justify-start items-center mt-2 text-sm ${
                isActive
                  ? 'bg-midnight-100  bg-opacity-16 text-[#2F26A7]'
                  : 'hover:bg-shadow-300 hover:bg-opacity-24'
              }`;
            }}
            onClick={handleScheduledImportsClick}
          >
            <div className='w-5 mr-2'>
              <Icon
                name='clock-clockwise'
                weight={location.pathname.includes(ROUTE_IMPORTS) ? 'fill' : 'regular'}
                size={20}
              />
            </div>
            {(isExpandedPanel || !isMobileScreen) && 'Imports'}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default withModal({ name: MODAL_CREATE_FOLDER, destroyOnHide: false })(
  withUploadModal(DatasetSidePanel)
);
