import { useEffect, useState } from 'react';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Popover from '@/Components/UI/Popover/Popover';

const MoveFilePath = ({
  filePath,
  currentDir,
  navigateCurrPath = () => {},
  selectedFileWorkspace,
  goToAllWorkspaces = () => {},
  disabled = false,
  isAllWorkspaceItemVisible = true,
}) => {
  const [parentFolderList, setParentFolderList] = useState([]);

  const getParentFolderList = () => {
    const folderList = filePath.filter(({ FileUuid }) => FileUuid !== currentDir);
    setParentFolderList(folderList);
  };

  const renderParentFolderList = ({ close }) => {
    const onButtonClick = (FileName, FileUuid) => {
      navigateCurrPath(FileName, FileUuid);
      close();
    };

    return (
      <ul data-cy='move-file-path-dropdown-body'>
        {isAllWorkspaceItemVisible && (
          <li>
            <Button
              variant='ghost'
              color='shadow'
              onClick={goToAllWorkspaces}
              className='!w-full !justify-start'
              dataCy={`move-file-path-dropdown-item-${selectedFileWorkspace}`}
            >
              All Workspaces
            </Button>
          </li>
        )}
        <li>
          <Button
            variant='ghost'
            color='shadow'
            onClick={() => navigateCurrPath('', '')}
            className='!w-full !justify-start'
            dataCy={`move-file-path-dropdown-item-${selectedFileWorkspace}`}
          >
            {selectedFileWorkspace}
          </Button>
        </li>
        {parentFolderList.map(({ FileUuid, FileName }) => {
          return (
            <li key={FileUuid}>
              <Button
                variant='ghost'
                color='shadow'
                onClick={() => onButtonClick(FileName, FileUuid)}
                className='!w-full !justify-start !truncate'
                dataCy={`move-file-path-dropdown-item-${FileName}`}
              >
                {FileName}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    getParentFolderList();
  }, [filePath]);

  return (
    <div className='flex items-center justify-start mb-1 mr-2'>
      <Popover
        isButtonIcon={false}
        isStatic={false}
        isCloseButton={false}
        panelClassname='!w-[250px] !max-h-[200px] overflow-auto	'
        buttonClassname='!h-[24px] bg-shadow-200 !p-1'
        renderComponent={(props) => renderParentFolderList(props)}
        disabled={disabled}
      >
        <Icon dataCy='move-file-path-dropdown' name='tree-structure' color='#151515' size={16} />
      </Popover>
    </div>
  );
};

export default MoveFilePath;
