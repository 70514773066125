import Icon from '@/Components/UI/Icon/Icon';
import { Button } from '@/Components/UI/Button/Button';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import FilterPanelOrClause from '@/Components/Modals/FilterPanel/FilterPanelOrClause';
import clsx from 'clsx';

export const IfConditionsGroup = ({
  columns,
  filterModel,
  onChangeFilterType,
  onFilterChange,
  setAnyFilterData,
  modalHeaderRef,
  modalBodyScrollTop,
  addNewFilter,
  thenRowValue,
  onChangeThenRowValue,
  elseRowValue,
  onChangeElseRowValue,
  customJsClasses,
  ifGroupIndex,
  isLastConditionGroup,
  isValidConditions,
}) => {
  const searchSelectPopupIcon = <Icon name='caret-down-regular' />;
  return (
    <div>
      {filterModel.map((filterModelElement, i) => (
        <FilterPanelOrClause
          key={i}
          index={i}
          filterModelElement={filterModelElement}
          onFilterChange={onFilterChange}
          columns={columns}
          onChangeFilterType={onChangeFilterType}
          setIsDisabledApplyButton={() => {}}
          modalBodyScrollTop={modalBodyScrollTop}
          modalHeaderRef={modalHeaderRef}
          handleHideSetFilterDropdown={() => {}}
          setAnyFilterData={setAnyFilterData}
          ifCondition='If'
          filterModel={filterModel}
          customJsClasses={customJsClasses}
          ifGroupIndex={ifGroupIndex}
          searchSelectPopupIcon={searchSelectPopupIcon}
          isValidConditions={isValidConditions}
        />
      ))}
      <div className='mt-3 mb-3 ml-20'>
        <Button
          color='midnight'
          variant='ghost'
          size='small'
          iconName='plus-circle'
          dataCy='add-btn'
          onClick={(e) => addNewFilter(e, ifGroupIndex)}
        >
          Add Condition
        </Button>
      </div>
      <div className='flex gap-2 ml-20'>
        <TextInput
          dataCy={`then-${ifGroupIndex}`}
          label='Then'
          value={thenRowValue}
          onChange={(e) => onChangeThenRowValue(e.target.value, ifGroupIndex)}
          placeholder={'Value'}
          className={clsx(
            'w-72',
            !isValidConditions &&
              !thenRowValue &&
              '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          )}
        />
        <TextInput
          dataCy={`else-${ifGroupIndex}`}
          disabled={!isLastConditionGroup}
          label='Else'
          value={isLastConditionGroup ? elseRowValue : ''}
          onChange={onChangeElseRowValue}
          placeholder={!isLastConditionGroup ? '' : 'Value'}
          className='w-72'
        />
      </div>
    </div>
  );
};
