import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import EditFile from './EditFile';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';

import { unSelectAllFiles, updateFilenameInFilePath } from '@/redux/reducers/datasetSlice';

import { post } from '@/Utils/API';
import { MODAL_EDIT_FILE, RENAME, TOAST_TYPE_ERROR } from '@/Utils/constants';
import { datasetSliceName } from '@/redux/constants';

import { FILE_NOTE_CHARACTER_LIMIT } from '@/Utils/fileConstants';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';

const EditFileModal = ({ show, hideModal, refreshHeader }) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const { currentFile, currentDataset, currentDir } = useSelector(
    (state) => state[datasetSliceName]
  );
  const {
    FileName: originalFilename,
    FileUuid: uuid,
    IsDirectory: isDirectory,
    FieldsTypes: fieldsTypes,
  } = currentFile?.metadata || {};
  const { Note: originalFileNote } = currentFile || {};

  const [filename, setFilename] = useState(originalFilename || '');
  const [fileNote, setFileNote] = useState(originalFileNote || '');

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  const saveFileEdits = async () => {
    // Note: Adjusted to integrate file name saving and potential file note saving logic
    if (filename !== originalFilename) {
      await saveFileName();
    }

    // Refreshing views and closing modal after edits
    dispatch(unSelectAllFiles());

    hideModal();
  };

  const saveFileName = async () => {
    const payload = { uuid, filename };
    const endpoint = `${RENAME}/${uuid}`;
    const response = await post(endpoint, payload);
    if (!response?.Success) {
      const errorMessage = isDirectory ? 'Error renaming folder' : 'Error renaming file';
      showToast({
        type: TOAST_TYPE_ERROR,
        text: errorMessage,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      return false;
    }
    sendMessage({
      category: getWebSocketCategory(currentDataset),
      location: currentDir,
    });
    if (refreshHeader) {
      dispatch(updateFilenameInFilePath(filename));
    }
    return true;
  };

  useEffect(() => {
    setFilename(originalFilename);
    setFileNote(originalFileNote);
  }, [originalFilename, originalFileNote]);

  useEffect(() => {
    const fileNoteExceedsLimit = fileNote?.length > FILE_NOTE_CHARACTER_LIMIT;
    const fileNameIsEmpty = filename?.trim() === '';
    const propertiesAreUnchanged = filename === originalFilename && fileNote === originalFileNote;

    setIsSaveBtnDisabled(fileNoteExceedsLimit || fileNameIsEmpty || propertiesAreUnchanged);
  }, [filename, fileNote, originalFilename, originalFileNote]);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title={isDirectory ? 'Folder Properties' : 'File Properties'}
      iconName='pencil-simple'
      size='medium'
    >
      <EditFile
        fileInfo={currentFile}
        filename={filename}
        fileNote={fileNote}
        isDirectory={isDirectory}
        columns={fieldsTypes?.length}
        handleChange={setFilename}
        handleNoteChange={setFileNote}
        showUrlAddress
        isFilePropertiesDropdownOpen={true}
        shouldFocusInput={true}
      />
      <hr className='mt-10 mb-5 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={saveFileEdits}
          disabled={isSaveBtnDisabled}
          dataCy='save-btn'
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_EDIT_FILE })(EditFileModal);
