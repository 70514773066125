import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_MOVE_FILE } from '@/Utils/constants';
import withModal from './withModalHOC';
import MoveFile from '@/pages/DatasetPage/MoveFile/MoveFile';

const MoveFileModal = ({
  show,
  hideModal,
  file,
  folderName,
  folderId,
  size = 'medium',
  isMovingSelected,
}) => {
  const fileName = file?.metadata?.FileName || file?.FileName;
  const title = fileName ? `"${fileName}"` : '';

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      size={size}
      titleSize='small'
      title={`Move ${title}`}
      withoutCloseButton={size === 'small' ? true : false}
    >
      <MoveFile
        isMovingSelected={isMovingSelected}
        hideModal={hideModal}
        defaultFolder={{ folderName, folderId }}
      />
    </Modal>
  );
};

export default withModal({ name: MODAL_MOVE_FILE })(MoveFileModal);
