import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';

import { openChargeBeeManageBilling, openChargeBeeUpgrade } from '@/helpers/upgradeHelper';

import { userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import {
  DEFAULT_UPGRADE_SIZE,
  MODAL_CHECKOUT,
  MODAL_UPGRADE_ACCOUNT,
  upgradeAccountTypes,
  userRolesKey,
} from '@/Utils/constants';

const UpgradeButton = ({
  text = 'Upgrade',
  openChargeBee = false,
  upgradeSize = DEFAULT_UPGRADE_SIZE,
  dataCy = '',
  hideModal = () => {},
  className = '',
  color = 'sunrise',
  size = 'medium',
  addMixpanelEventParam = () => {},
  children = '',
  iconName = 'rocket-launch',
  useUserRoleCheck = true,
  variant = 'solid',
}) => {
  const dispatch = useDispatch();
  const { user, subscriptions } = useSelector((state) => state[userDataSliceName]);
  const { email } = user;
  const { hasBillingId } = user?.userProperties || {};
  const hasActiveSubscriptions = subscriptions?.length > 0;

  const upgradeAccount = () => {
    hideModal();
    openChargeBeeUpgrade(email, upgradeSize);
  };

  const manageBilling = () => {
    hideModal();
    openChargeBeeManageBilling();
  };

  const onMixpanelEvent = () => {
    dispatch(addMixpanelEvent(addMixpanelEventParam()));
  };

  const openCheckout = () => {
    if (hasActiveSubscriptions) {
      dispatch(
        showModal({
          name: MODAL_UPGRADE_ACCOUNT,
          props: { upgradeType: upgradeAccountTypes.checkout },
        })
      );
    } else {
      dispatch(showModal({ name: MODAL_CHECKOUT }));
    }
  };

  const handleUpgrade = () => {
    if (addMixpanelEventParam()) {
      onMixpanelEvent();
    }
    hideModal();

    if (hasBillingId && hasActiveSubscriptions) {
      manageBilling();
    } else if (!hasBillingId) {
      openChargeBee ? upgradeAccount() : openCheckout();
    }
  };

  return (
    <>
      {!useUserRoleCheck || !user[userRolesKey].includes('is-paying') ? (
        <Button
          variant={variant}
          color={color}
          size={size}
          className={`${className} !text-ui'`}
          onClick={handleUpgrade}
          dataCy={dataCy}
        >
          {children || (
            <span className='flex flex-row items-center'>
              {text}
              {iconName && <Icon name={iconName} color='#151515' size={16} className='ml-1' />}
            </span>
          )}
        </Button>
      ) : null}
    </>
  );
};

export default UpgradeButton;
