import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import Label from '@/Components/UI/Form/Label/Label';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  MODAL_DECODE_BASE64,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_APPLY_FUNCTION_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import showToast from '@/Components/Toast/showToastTemplate';
import withModal from '@/Components/Modals/withModalHOC';
import { showModal } from '@/redux/reducers/modalsSlice';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const DecodeBase64Modal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const options = useMemo(() => {
    return columnDefs?.map((colDef) => ({ label: colDef.headerName, value: colDef.field }));
  }, [columnDefs]);

  const [targetColumn, setTargetColumn] = useState(null);

  const decodeBase64 = async () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      hideModal();
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    let endpoint = '';
    try {
      const uuid = getFileUuidFromPath();
      endpoint = `decode-base64/${uuid}/${targetColumn.value}`;
      const result = await post(endpoint);
      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Decode Base64 Content' },
          userIncrementName: '# of function use',
        })
      );
      if (!result.Success) {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: result.Message,
          endpoint: endpoint,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
        return;
      }
      await dispatch(fetchOpenedFile(uuid));
    } catch (e) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_APPLY_FUNCTION_ERROR,
        errorContext: e,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      dispatch(setIsInsertingNewColumn(false));
      hideModal();
    }
  };

  useEffect(() => {
    if (targetColumnId && options) {
      const _targetColumn = options.find((option) => option.value === targetColumnId);
      setTargetColumn(_targetColumn);
    }
  }, [targetColumnId, options]);

  const closeDecodeBase64Popup = () => {
    hideModal();
    setTargetColumn(null);
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title='Decode Base64 content'
      articleID='69000818501'
      iconName='calculator'
    >
      <div className='decode-base64-container'>
        <Label text='Choose column' />
        <div className='mb-4'>
          <SearchSelect
            options={options}
            onChange={setTargetColumn}
            value={targetColumn}
            getOptionLabel={(option) => option.label}
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          onClick={closeDecodeBase64Popup}
          dataCy='cancel-btn'
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={decodeBase64}
          disabled={!targetColumn}
          dataCy='save-btn'
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_DECODE_BASE64 })(DecodeBase64Modal);
