import './index.scss';

const ThreeDotsLoader = ({ text = 'Loading', className = '' }) => (
  <div className={`dots-loading ${className}`} data-cy='dots-loader'>
    {text}
    <div className='dot-flashing' />
  </div>
);

export default ThreeDotsLoader;
