import { selectFiles, unselectFiles } from '@/redux/reducers/datasetSlice';
import { store } from '@/redux/store';
import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from './fileConstants';

export const unselectNestedFiles = (file) => {
  const nestedFiles = file?.Children?.filter(({ IsDirectory }) => !IsDirectory) || [];
  const nestedFolders = file?.Children?.filter(({ IsDirectory }) => IsDirectory) || [];
  store.dispatch(unselectFiles([file, ...nestedFiles]));

  if (nestedFolders.length) {
    nestedFolders.forEach((folder) => unselectNestedFiles(folder));
  }
};

export const selectNestedFiles = (file) => {
  const nestedFiles = file?.Children?.filter(({ IsDirectory }) => !IsDirectory) || [];
  const nestedFolders = file?.Children?.filter(({ IsDirectory }) => IsDirectory) || [];
  store.dispatch(selectFiles([file, ...nestedFiles]));

  if (nestedFolders.length) {
    nestedFolders.forEach((folder) => selectNestedFiles(folder));
  }
};

export const selectNestedFilesFiltered = (file) => {
  const nestedFiles = file.Children.filter(
    ({ IsDirectory, Status, Type }) =>
      !IsDirectory && Status !== FILE_STATUS_ERROR && Type !== FILE_TYPE_EXPORTER
  );
  const nestedFolders = file.Children?.filter(({ IsDirectory }) => IsDirectory);
  store.dispatch(selectFiles([file, ...nestedFiles]));

  if (nestedFolders.length) {
    nestedFolders.forEach((folder) => selectNestedFilesFiltered(folder));
  }
};
