import _agify from './agify.webp';
import _deepl from './deepl.webp';
import _apollo from './apollo.webp';
import _hunter from './hunter.webp';
import _placekey from './placekey.webp';
import _leadmagic from './leadmagic.webp';
import _openaigpt from './openaigpt.webp';
import _greynoise from './greynoise.webp';
import _scraperapi from './scraperapi.webp';
import _virustotal from './virustotal.webp';
import _revenueBase from './revenueBase.webp';
import _extractorapi from './extractorapi.webp';
import _peopledatalabs from './peopledatalabs.webp';
import _recordedfuture from './recordedfuture.webp';
import _emaillistverify from './emaillistverify.webp';
import CustomGigasheetEnrich from './customEnrich.webp';

export const agify = _agify;
export const apollo = _apollo;
export const customEnrich = CustomGigasheetEnrich;
export const deepl = _deepl;
export const leadmagic = _leadmagic;
export const emailEnrich = CustomGigasheetEnrich;
export const emaillistverify = _emaillistverify;
export const extractorapi = _extractorapi;
export const greynoise = _greynoise;
export const hunter = _hunter;
export const iPEnrich = CustomGigasheetEnrich;
export const openaigpt = _openaigpt;
export const peopledatalabs = _peopledatalabs;
export const placekey = _placekey;
export const recordedfuture = _recordedfuture;
export const revenueBase = _revenueBase;
export const scraperapi = _scraperapi;
export const virustotal = _virustotal;
