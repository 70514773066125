import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@/Components/UI/Button/Button';

import Modal from '@/Components/UI/Modal/Modal';
import showToast from '@/Components/Toast/showToastTemplate';
import withModal from '@/Components/Modals/withModalHOC';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';

import { post } from '@/Utils/API';
import {
  MODAL_CREATE_FOLDER,
  TOAST_TYPE_ERROR,
  TOAST_TEXT_CREATING_FOLDER_ERROR,
  FILES,
  DIRECTORY,
  userTeamNameKey,
} from '@/Utils/constants';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { getTeamNameLabel } from '@/helpers/datasetsHelper';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';

const CreateFolderModal = ({ show, hideModal, directory, isTeamWorkspace = false }) => {
  const { sendMessage } = useWebSocketContext();
  const { currentDataset } = useSelector((state) => state[datasetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const [foldername, setFoldername] = useState('');
  const inputRef = useRef(null);
  const focusTimeout = 300;

  const createFolder = async () => {
    const isUploadToTeam =
      getTeamNameLabel(user[userTeamNameKey]) === currentDataset || isTeamWorkspace;

    const endpoint = `${FILES}/${DIRECTORY}`;
    const payload = {
      name: foldername,
      parentDirectory: directory,
      uploadToTeam: isUploadToTeam,
    };
    const response = await post(endpoint, payload);
    if (response && response.Success) {
      onCloseModal();
      sendMessage({
        category: getWebSocketCategory(currentDataset),
        location: directory,
      });
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_CREATING_FOLDER_ERROR,
        errorContext: response,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const handleChange = (value) => setFoldername(value);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && foldername.trim() !== '') {
      createFolder();
    }
  };

  const onCloseModal = () => {
    setFoldername('');
    hideModal();
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        focusInput();
      }, focusTimeout);
      return () => clearTimeout(timer);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      focusInput();
    }
  }, [show, inputRef.current]);

  return (
    <Modal
      title='New Folder'
      size='small'
      isOpen={show}
      onClose={onCloseModal}
      titleSize='small'
      iconName='folder'
      dataCy='create-folder-modal'
    >
      <TextInput
        ref={inputRef}
        name='foldername'
        value={foldername}
        dataCy='create-folder-input'
        className='w-full'
        onChange={(e) => handleChange(e.target.value)}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={handleKeyDown}
        placeholder='Untitled Folder'
      />
      <hr className='mt-6 mb-4 solid' />
      <div className='flex justify-end gap-4'>
        <Button onClick={() => onCloseModal()} dataCy='cancel-btn' color='shadow'>
          Cancel
        </Button>
        <Button
          onClick={() => createFolder()}
          dataCy='save-btn'
          color='oceanBlue'
          disabled={foldername.trim() === ''}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CREATE_FOLDER })(CreateFolderModal);
