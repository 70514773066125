import React from 'react';
import { Popover as HeadlessPopover } from '@headlessui/react';
import Icon from '@/Components/UI/Icon/Icon';
import clsx from 'clsx';
import { Button } from '@/Components/UI/Button/Button';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

type renderComponentPropsType = {
  close?: () => void;
  open?: boolean;
  extraProps?: string;
};

type PopoverProps = {
  renderComponent?: (props: renderComponentPropsType) => React.ReactElement | void;
  content?: React.ReactNode;
  children: React.ReactNode;
  buttonClassname?: string;
  buttonTextClassname?: string;
  panelClassname?: string;
  tooltipCondition?: boolean;
  tooltipText?: string;
  isStatic?: boolean;
  isButtonIcon?: boolean;
  isCloseButton?: boolean;
  onClose?: () => void;
  disabled?: boolean;
};

const Popover = ({
  renderComponent = () => {},
  content,
  children,
  buttonClassname,
  buttonTextClassname,
  panelClassname,
  tooltipCondition = false,
  tooltipText = '',
  isStatic = true,
  isButtonIcon = true,
  isCloseButton = true,
  onClose = () => {},
  disabled = false,
}: PopoverProps) => {
  const onClosePopover = (cb: () => void) => {
    onClose();
    cb();
  };
  return (
    <HeadlessPopover as='div' className='relative inline-block'>
      {({ open, close }) => (
        <>
          {tooltipCondition && tooltipText && !open ? (
            <Tooltip text={tooltipText} asChild>
              <HeadlessPopover.Button
                className={clsx(
                  'inline-flex items-center justify-center h-8 px-3 text-base rounded text-ui-secondary hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-shadow-200 focus-visible:ring focus-visible:ring-ocean-blue-200 focus:outline-none disabled:opacity-40',
                  buttonClassname
                )}
                data-cy={'abc'}
              >
                <span className={buttonTextClassname}>{children}</span>
                {isButtonIcon && (
                  <Icon
                    name={open ? 'caret-down' : 'caret-right'}
                    size={18}
                    color='#151515'
                    className='inline-block ml-2'
                  />
                )}
              </HeadlessPopover.Button>
            </Tooltip>
          ) : (
            <HeadlessPopover.Button
              className={clsx(
                'inline-flex items-center justify-center h-8 px-3 text-base rounded text-ui-secondary hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-shadow-200 focus-visible:ring focus-visible:ring-ocean-blue-200 focus:outline-none disabled:opacity-40',
                buttonClassname
              )}
              disabled={disabled}
            >
              <span className={buttonTextClassname}>{children}</span>
              {isButtonIcon && (
                <Icon
                  name={open ? 'caret-down' : 'caret-right'}
                  size={18}
                  color='#151515'
                  className='inline-block ml-2'
                />
              )}
            </HeadlessPopover.Button>
          )}

          <HeadlessPopover.Panel
            focus
            static={isStatic}
            className={clsx(
              'absolute z-10 w-[25rem] border rounded px-3 py-2 transform bg-white shadow-lg',
              panelClassname || 'inset-x-0'
            )}
          >
            {isCloseButton && (
              <Button
                onClick={() => close()}
                iconName='x'
                color='shadow'
                variant='ghost'
                className='float-right'
              />
            )}
            {content}
            {renderComponent({ close: () => onClosePopover(close), open })}
          </HeadlessPopover.Panel>
        </>
      )}
    </HeadlessPopover>
  );
};

export default Popover;
