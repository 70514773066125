import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import withFilterPanel from './withFilterPanelHOC';
import { Button } from '@/Components/UI/Button/Button';
import FilterPanelMultiValue from './FilterPanelMultiValue';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import CustomToggleSwitch from '@/Components/CustomToggleSwitch/CustomToggleSwitch';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';

import { NUMBER_CONDITIONS, prettyPrintCondition } from '@/Utils/filterConditions';
import { onColumnFilterTypeChange } from '@/helpers/filterPanelHelper';
import clsx from 'clsx';
import { getFilterType } from '@/Utils/getFilterType';
import InRange from './FilterPanelInRange';

const FilterPanelAggregations = ({
  filterModelElement,
  index,
  onFilterChange,
  onChangeFilterType,
  setAnyFilterData,
  setIsDisabledApplyButton,
  activePanel,
  isMultiValueEnabled,
  onRemove,
  blankConditions,
  filterOptions,
  filterModel,
}) => {
  const {
    columnDefs,
    clientState: { columnState },
  } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const { maxFilters } = user.userProperties;

  const { colId, isChecked, type, filter, filterTo } = filterModelElement;

  const isFirstFilter = index === 0;

  const filterTypeOptions = NUMBER_CONDITIONS.map((el) => ({
    value: el,
    label: prettyPrintCondition(el),
  }));

  const columnsOptions = columnDefs.reduce((acc, column) => {
    const isAggFuncReturnsNumber = (type, aggFunc) => {
      if (getFilterType(type) !== 'number') {
        if (aggFunc === 'min' || aggFunc === 'max' || aggFunc === 'first' || aggFunc === 'last') {
          return false;
        }
        return true;
      }
      return true;
    };
    const correspondingColumn = columnState.find(
      ({ colId, aggFunc }) =>
        column.colId === colId && aggFunc && isAggFuncReturnsNumber(column.fieldType, aggFunc)
    );

    if (correspondingColumn) {
      acc.push({
        value: column.colId,
        label: `(${correspondingColumn.aggFunc}) ${column.headerName}`,
        type: 'Int64',
      });
    }
    return acc;
  }, []);

  const selectedColumn = columnsOptions?.find(({ value }) => value === colId) || null;

  const onChangeModelKeyValue = (key, modelValue) => {
    const filterType = 'number';
    const _filterModelElement = { ...filterModelElement, filterType };
    if (key === 'colId' && modelValue !== _filterModelElement.colId) {
      onFilterChange(index, {
        ..._filterModelElement,
        [key]: modelValue,
      });
    } else if (key === 'type') {
      onColumnFilterTypeChange(
        key,
        index,
        modelValue,
        _filterModelElement,
        onFilterChange,
        activePanel
      );
    } else {
      onFilterChange(index, {
        ..._filterModelElement,
        [key]: modelValue,
      });
    }
  };

  const isComponentDisabled = maxFilters <= index;

  useEffect(() => {
    if (index === 0 && !filterModelElement.colId && columnsOptions.length) {
      onFilterChange(index, {
        ...filterModelElement,
        colId: columnsOptions[0].value,
        filterType: 'number',
        type: 'equalsAny',
      });
    }
  }, []);

  return (
    <div
      className={clsx(
        'filter-row',
        isChecked && !isFirstFilter && 'filter-row-checked',
        isComponentDisabled && 'relative'
      )}
    >
      <span
        className={clsx(isComponentDisabled && '!cursor-not-allowed absolute w-full h-full z-10')}
      />
      {isFirstFilter ? (
        <h4>Where</h4>
      ) : (
        <CustomToggleSwitch
          onChange={() => onChangeFilterType(index)}
          isChecked={isChecked}
          switcher='AndOrSwitcher'
          disabled={isComponentDisabled}
        />
      )}
      <div className='select-filter_wrapper'>
        <SearchSelect
          defaultValue={selectedColumn}
          options={columnsOptions}
          onChange={(newValue) => {
            onChangeModelKeyValue('colId', newValue.value);
          }}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.label}
          maxWidth={160}
          noOptionsText='Set an aggregation to filter by'
          isDisabled={isComponentDisabled}
        />
        <SearchSelect
          placeholder='Operator'
          isDisabled={!colId || isComponentDisabled}
          defaultValue={colId ? type : ''}
          options={filterTypeOptions}
          onChange={(newValue) => onChangeModelKeyValue('type', newValue.value)}
          getOptionLabel={(option) => option.label || prettyPrintCondition(option)}
        />
        {!blankConditions && type !== 'inRange' && (
          <div className='w-56'>
            <FilterPanelMultiValue
              type={type}
              cypressTextFieldName={'filter-panel-input'}
              showRegexInvalidBlock={false}
              setIsDisabledApplyButton={setIsDisabledApplyButton}
              isMultiple={isMultiValueEnabled}
              disabled={!type || isComponentDisabled}
              defaultValue={colId ? filter : ''}
              currFieldType='Float64'
              onChange={(newValue) => onChangeModelKeyValue('filter', newValue)}
              indexInFilterModel={index}
              setAnyFilterData={setAnyFilterData}
              isBlurAction
            />
          </div>
        )}
        {type === 'inRange' && !blankConditions && (
          <InRange
            onChange={onChangeModelKeyValue}
            defaultStartValue={filter}
            defaultEndValue={filterTo}
            id={index}
            modelType={type}
            indexInFilterModel={index}
            ifCondition={false}
            isValidConditions={false}
            type={type}
            cypressTextFieldName={'filter-panel-input'}
            isWrongRegexValue={false}
            setIsDisabledApplyButton={setIsDisabledApplyButton}
            isMultiValueEnabled={isMultiValueEnabled}
            disabled={!type || isComponentDisabled}
            defaultValue={colId ? filter : ''}
            currFieldType='Float64'
            setAnyFilterData={setAnyFilterData}
            colId={colId}
            filter={filter}
            filterTo={filterTo}
          />
        )}
      </div>
      {filterModel.length === 1 ? null : (
        <Button
          onClick={onRemove}
          variant='ghost'
          size='large'
          data-cy='remove-btn'
          iconName='trash'
          color='violetWeb'
          className='z-20'
        />
      )}
    </div>
  );
};

export default withFilterPanel(FilterPanelAggregations);
