import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { spreadsheetSliceName } from '@/redux/constants';
import { Button } from '@/Components/UI/Button/Button';
import SortColumnsRow from '@/Components/Modals/SortPanel/SortColumnsRow';

const SortColumns = ({ sortData, setSortData }) => {
  const {
    columnDefs,
    clientState: { sortModel },
  } = useSelector((state) => state[spreadsheetSliceName]);

  const initialSortedColumns = useMemo(() => {
    const _sortedColumns = sortModel.map((column) => {
      const correspondingColumn = columnDefs.find(({ field }) => column.colId === field);
      return {
        value: column.colId,
        label: correspondingColumn?.headerName,
        type: correspondingColumn?.fieldType,
        sortDirection: column.sort,
      };
    });
    return _sortedColumns;
  }, [sortModel, columnDefs]);

  const addNewColumn = () => {
    const moreThanOneEmptySelectPresent =
      sortData.filter((column) => column.value === '').length >= 1;
    if (moreThanOneEmptySelectPresent) return;

    const newColumn = { value: '', label: '', type: '', sortDirection: 'asc' };

    setSortData([...sortData, newColumn]);
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...sortData];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setSortData(updatedList);
  };

  useEffect(() => {
    if (sortData.length === 0) {
      setSortData([{ value: '', label: '', type: '', sortDirection: 'asc' }]);
    }
  }, [sortData]);

  useEffect(() => {
    setSortData(initialSortedColumns);
  }, []);

  return (
    <div className='relative'>
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId='list-container'>
          {(provided) => (
            <div className='list-container' {...provided.droppableProps} ref={provided.innerRef}>
              {sortData.map((sortedColumn, index) => (
                <Draggable
                  key={`${sortedColumn.value}-key`}
                  draggableId={`${sortedColumn.value}-id`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className='item-container'
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <SortColumnsRow
                        key={index}
                        rowIndex={index}
                        sortedColumn={sortedColumn}
                        sortData={sortData}
                        setSortData={setSortData}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='add-column-to-sort-container'>
        <Button onClick={addNewColumn} variant='ghost' iconName='plus-circle' color='oceanBlue'>
          Add Another Sort Column
        </Button>
      </div>
    </div>
  );
};

export default SortColumns;
