import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from 'date-fns';

export function inRelativeTime(targetDate, currentDate = new Date()) {
  const formattedTargetDate = new Date(targetDate);
  const isFuture = formattedTargetDate > currentDate;

  const diffSeconds = Math.abs(differenceInSeconds(formattedTargetDate, currentDate));
  const diffMinutes = Math.abs(differenceInMinutes(formattedTargetDate, currentDate));
  const diffHours = Math.abs(differenceInHours(formattedTargetDate, currentDate));
  const diffDays = Math.abs(differenceInDays(formattedTargetDate, currentDate));
  const diffWeeks = Math.abs(differenceInWeeks(formattedTargetDate, currentDate));
  const diffMonths = Math.abs(differenceInMonths(formattedTargetDate, currentDate));
  const diffYears = Math.abs(differenceInYears(formattedTargetDate, currentDate));

  let relativeTime;

  if (diffSeconds < 60) {
    relativeTime = diffSeconds === 1 ? '1 second' : `${diffSeconds} seconds`;
  } else if (diffMinutes < 60) {
    relativeTime = diffMinutes === 1 ? '1 minute' : `${diffMinutes} minutes`;
  } else if (diffHours < 24) {
    relativeTime = diffHours === 1 ? '1 hour' : `${diffHours} hours`;
  } else if (diffDays < 7) {
    relativeTime = diffDays === 1 ? '1 day' : `${diffDays} days`;
  } else if (diffWeeks < 4) {
    relativeTime = diffWeeks === 1 ? '1 week' : `${diffWeeks} weeks`;
  } else if (
    diffMonths < 12 ||
    (diffMonths === 12 && formattedTargetDate.getDate() <= currentDate.getDate())
  ) {
    relativeTime = '1 month';
  } else {
    relativeTime = diffYears === 1 ? '1 year' : `${diffYears} years`;
  }

  return isFuture ? `in ${relativeTime}` : `${relativeTime} ago`;
}
