import { get, http_delete, post } from '@/Utils/API';
import { handleUnauthorizedResponseAndReload } from './utils';

export const getHeaderCommentThreads = async (fileHandle) => {
  const response = await get(`dataset/${fileHandle}`, true);
  handleUnauthorizedResponseAndReload(response, 'comments');
  const metadata = await response?.json();

  if (metadata?.Comments?.threads) {
    return metadata?.Comments?.threads;
  }
  return [];
};

export const getColumnComments = async (fileHandle, column) => {
  return await get(`dataset/${fileHandle}/${column}/comments`);
};

export const getCellComments = async (fileHandle, column, row) => {
  return await get(`dataset/${fileHandle}/${column}/${row}/comments`);
};

export const getAllComments = async (fileHandle) => {
  return await get(`dataset/${fileHandle}/comments`);
};

const addComment = async (fileHandle, column, row, commentText, taggedEmails, isColumnComment) => {
  let endpointRoute = `dataset/${fileHandle}/${column}/${row}/comment`;
  if (isColumnComment) {
    endpointRoute = `dataset/${fileHandle}/${column}/comment`;
  }
  const payload = {
    comment: commentText,
    tagged_emails: taggedEmails,
  };
  await post(endpointRoute, payload);
};

export const addColumnComment = async (fileHandle, column, commentText, taggedEmails) => {
  await addComment(fileHandle, column, -1, commentText, taggedEmails, true);
};

export const addCellComment = async (fileHandle, column, row, commentText, taggedEmails) => {
  await addComment(fileHandle, column, row, commentText, taggedEmails, false);
};

export const deleteCellComment = async (fileHandle, column, row, commentId) => {
  await http_delete(`dataset/${fileHandle}/${column}/${row}/comment`, commentId);
};

export const deleteColumnComment = async (fileHandle, column, commentId) => {
  await http_delete(`dataset/${fileHandle}/${column}/comment`, commentId);
};

export const getAutofillEmails = async (includeTeamMembers, includeShareRecipients) => {
  try {
    const autofillResponse = await get(
      `users/autofill?include_team_members=${includeTeamMembers}&include_share_recipients=${includeShareRecipients}`
    );

    const teamMemberEmails = autofillResponse.team_members.map((user) => user.email);
    const shareRecipientEmails = autofillResponse.share_recipients.map((user) => user.email);

    return [...new Set([...teamMemberEmails, ...shareRecipientEmails])]
      .map((email) => ({
        label: email,
        value: email,
        type: 'String',
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  } catch (e) {
    console.error(e);
  }
};
