import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import withModal from '@/Components/Modals/withModalHOC';
import CustomMultiSelect from '@/Components/CustomMultiSelect/CustomMultiSelect';
import { Button } from '@/Components/UI/Button/Button'; // Assuming the Button component is correctly imported

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import shieldSymbols from '@/Utils/shieldSymbols';
import {
  MODAL_COMBINED_COLUMNS,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { showModal } from '@/redux/reducers/modalsSlice';

const CombinedColumnsModal = ({ targetColumnId, hideModal, show }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const [combinedColumnsData, setCombinedColumnsData] = useState(null);
  const [separatorString, setSeparatorString] = useState('');
  const [columnName, setColumnName] = useState('');

  const [allColumns, targetColumn] = useMemo(() => {
    const _allColumns = columnDefs
      ?.filter((col) => col.colId !== ROW_NUMBER_COLUMN_ID)
      .map(({ field, headerName }) => ({
        label: headerName,
        value: field,
      }));
    const _targetColumn = _allColumns?.find((column) => column.value === targetColumnId) || null;

    return [_allColumns, _targetColumn];
  }, [columnDefs, targetColumnId]);

  const combinedColumns = async () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    const _columnName =
      columnName.trim().length > 0
        ? columnName
        : allColumns.map((c) => c.label).join(separatorString || '');

    const uuid = getFileUuidFromPath();
    try {
      const shieldedSeparator = shieldSymbols(separatorString);

      await post(`combine-columns/${uuid}`, {
        columns: combinedColumnsData.map((column) => column.value),
        separator: shieldedSeparator,
        name: _columnName,
      });

      await dispatch(fetchOpenedFile(uuid));
      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'CombineColumns' },
          userIncrementName: '# of function use',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
      hideModal();
    }
  };

  const closeCombinedColumnsPopup = () => {
    hideModal();
    setCombinedColumnsData(null);
  };

  const isSaveBtnDisabled = !combinedColumnsData || combinedColumnsData.length < 2;

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='large'
      size='medium'
      title='Combine Columns'
      articleID='69000812821'
      dataCy='combined-column-modal'
      iconName='arrows-merge'
    >
      <div className='combined-columns-container'>
        <CustomMultiSelect
          initialColumns={allColumns}
          updateSelectedColumns={setCombinedColumnsData}
          defaultValue={targetColumn}
        />
        <div className='mb-4'>
          <TextInput
            name='separator'
            label='Separator'
            value={separatorString}
            onChange={(e) => setSeparatorString(e.target.value)}
            className='w-full'
          />
        </div>
        <div className='mb-4'>
          <TextInput
            name='name'
            label='Column Name'
            value={columnName}
            onChange={(e) => setColumnName(e.target.value)}
            className='w-full'
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex items-center justify-end gap-4 px-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          dataCy='cancel-btn'
          onClick={closeCombinedColumnsPopup}
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          dataCy='save-btn'
          onClick={combinedColumns}
          disabled={isSaveBtnDisabled}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_COMBINED_COLUMNS })(CombinedColumnsModal);
