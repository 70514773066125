import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, node }) => {
  const [container] = useState(() => node || document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container);
    return () => document.body.removeChild(container);
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
