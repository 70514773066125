import withModal from '@/Components/Modals/withModalHOC';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MODAL_SORT_COLUMN } from '@/Utils/constants';
import { setSortModel } from '@/redux/reducers/spreadsheetSlice';
import SortColumns from './SortColumns';

import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import { TemporaryLabel } from '@/helpers/upgradeHelper';
import './style/index.scss';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const SortPanelPopup = ({ show, hideModal }) => {
  const [sortData, setSortData] = useState([]);
  const currentFile = useCurrentFile();
  const { WithinQuota } = currentFile || {};

  const dispatch = useDispatch();

  const onApplyClick = () => {
    const formattedSortData = sortData
      .filter(({ value }) => value !== '')
      .map(({ value, sortDirection }) => ({
        colId: value,
        sort: sortDirection,
      }));
    dispatch(setSortModel(formattedSortData));
    hideModal();
  };

  const onResetClick = () => {
    dispatch(setSortModel([]));
    hideModal();
  };

  return (
    <>
      <Modal
        modalTitleClasses='!mt-0'
        title='Sort'
        articleID='69000818494'
        isOpen={show}
        onClose={hideModal}
      >
        <div className='absolute top-6 left-[67px]'>{!WithinQuota ? <TemporaryLabel /> : null}</div>
        <SortColumns sortData={sortData} setSortData={setSortData} />
        <div className='flex flex-row justify-between mt-6'>
          <Button onClick={onResetClick}>Reset</Button>
          <div className='flex gap-2'>
            <Button variant='ghost' onClick={hideModal}>
              Cancel
            </Button>
            <Button dataCy='save-btn' color='oceanBlue' onClick={onApplyClick}>
              Apply
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withModal({ name: MODAL_SORT_COLUMN })(SortPanelPopup);
