import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, post } from '@/Utils/API';
import { userTeamNameKey } from '../../Utils/constants';
import { settingsSliceName } from '../constants';
import { StatusCodes } from 'http-status-codes';

const initialState = {
  user: null,
  settingsPage: 0,
  isSettingsPageLoading: false,
  teamMembers: [],
  teamName: '',
  subscriptionDetails: {
    isPremium: false,
    filesSize: '0GB',
    filesSizePercentage: '0%',
    maxStorageSize: '',
    enrichmentCredits: { Limit: 0, Used: 0 },
    enrichmentPercentage: '0%',
    externalName: '',
    totalActiveAmount: 0,
    billingPeriodUnit: 'month',
    nextBillingAt: '',
  },
  enterpriseClickhouseConnectionDetails: {
    enabled: false,
    host: '',
    port: 8123,
    username: 'default',
    password: '',
    ssl: false,
    error: '',
  },
  isLoading: false,
};

// Function to get external_name for a subscription item of type "plan"
function getExternalNameForPlan(subscriptionData) {
  for (const subscriptionObject of subscriptionData) {
    const subscriptionItems = subscriptionObject.subscription_items;
    for (const item of subscriptionItems) {
      if (item.item_type === 'plan') {
        return item.item_external_name;
      }
    }
  }
  return null; // Return null if no plan type item is found
}

// Function to get the sum of all subscription items amounts
function getSumOfSubscriptionAmounts(subscriptionData) {
  let totalAmount = 0;

  for (const subscriptionObject of subscriptionData) {
    const subscriptionItems = subscriptionObject.subscription_items;
    for (const item of subscriptionItems) {
      totalAmount += item.amount;
    }
  }

  return totalAmount;
}

export const getSubscriptionDetails = createAsyncThunk(
  `${settingsSliceName}/getSubscriptionDetails`,
  async (args, { getState }) => {
    const state = getState();
    const { filesSize, filesSizePercentage } = state.dataset;
    const { user, subscriptions } = state.userData;
    const { maxStorageSize, hasBillingId } = user.userProperties;

    const fetchEnrichmentCredits = await get('user/enrichment-credits', true);
    const enrichmentCredits = await fetchEnrichmentCredits.json();

    const { Used, Limit } = enrichmentCredits;
    const enrichmentPercentage = Number((Used / Limit) * 100);
    const externalName = getExternalNameForPlan(subscriptions);
    const totalActiveAmount = getSumOfSubscriptionAmounts(subscriptions);
    const billingPeriodUnit = subscriptions[0]?.billing_period_unit || 'month';
    const nextBillingAt = subscriptions[0]?.next_billing_at || '';

    return {
      filesSize,
      filesSizePercentage,
      maxStorageSize,
      isPremium: hasBillingId,
      enrichmentCredits,
      enrichmentPercentage,
      externalName,
      totalActiveAmount,
      billingPeriodUnit,
      nextBillingAt,
    };
  }
);

export const refreshEnterpriseClickhouseConnectionDetails = createAsyncThunk(
  `${settingsSliceName}/getEnterpriseClickhouseConnectionDetails`,
  // eslint-disable-next-line no-unused-vars
  async (arg, { rejectWithValue }) => {
    try {
      const response = await get('external-database/connections/clickhouse', true);
      if (response.status === StatusCodes.OK) {
        const connectionDetails = await response.json();
        return { status: response.status, connectionDetails: connectionDetails };
      } else if (response.status === StatusCodes.NOT_FOUND) {
        return { status: response.status, connectionDetails: null };
      } else {
        return rejectWithValue(response.status);
      }
    } catch (error) {
      return rejectWithValue("Couldn't fetch enterprise clickhouse connection details");
    }
  }
);

export const getTeamDetails = createAsyncThunk(
  `${settingsSliceName}/getTeamDetails`,
  async (args, { getState }) => {
    const state = getState();
    const { user } = state.userData;

    const teamName = user[userTeamNameKey] ? user[userTeamNameKey] : null;

    const fetchTeamMembers = await get(
      'users/autofill?include_team_members=true&include_share_recipients=false',
      true
    );
    const teamMembersResponse = await fetchTeamMembers.json();
    const teamMembers = teamMembersResponse['team_members'].map((i) => i.email);

    return {
      teamMembers,
      teamName,
    };
  }
);

export const removeUserFromTeam = createAsyncThunk(
  `${settingsSliceName}/removeUserFromTeam`,
  async () => {
    await post('user/remove-from-team');
  }
);

export const settingsSlice = createSlice({
  name: settingsSliceName,
  initialState,
  reducers: {
    setSettingsPage(state, action) {
      state.settingsPage = action.payload;
    },
    setIsSettingsPageLoading(state, action) {
      state.isSettingsPageLoading = action.payload;
    },
    setEnterpriseClickhouseConnectionDetails(state, action) {
      state.enterpriseClickhouseConnectionDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      const {
        enrichmentCredits,
        isPremium,
        filesSize,
        filesSizePercentage,
        maxStorageSize,
        enrichmentPercentage,
        externalName,
        totalActiveAmount,
        billingPeriodUnit,
        nextBillingAt,
      } = action.payload;
      state.subscriptionDetails.isPremium = isPremium;
      state.subscriptionDetails.filesSize = filesSize;
      state.subscriptionDetails.filesSizePercentage = filesSizePercentage;
      state.subscriptionDetails.maxStorageSize = maxStorageSize;
      state.subscriptionDetails.enrichmentCredits = enrichmentCredits;
      state.subscriptionDetails.enrichmentPercentage = enrichmentPercentage;
      state.subscriptionDetails.externalName = externalName;
      state.subscriptionDetails.totalActiveAmount = totalActiveAmount;
      state.subscriptionDetails.billingPeriodUnit = billingPeriodUnit;
      state.subscriptionDetails.nextBillingAt = nextBillingAt;
      state.isLoading = false;
    });
    builder.addCase(getSubscriptionDetails.rejected, (state) => {
      state.error = { Limit: 0, Used: 0 };
      state.isLoading = false;
    });
    builder.addCase(getTeamDetails.pending, (state) => {
      state.isSettingsPageLoading = true;
    });
    builder.addCase(getTeamDetails.fulfilled, (state, action) => {
      const { teamMembers, teamName } = action.payload;
      state.teamMembers = teamMembers;
      state.teamName = teamName;
      state.isSettingsPageLoading = false;
    });
    builder.addCase(getTeamDetails.rejected, (state) => {
      state.error = [];
      state.isSettingsPageLoading = false;
    });
    builder.addCase(removeUserFromTeam.fulfilled, (state) => {
      state.teamMembers = [];
    });
    builder.addCase(removeUserFromTeam.rejected, (state) => {
      state.teamMembers = [];
    });
    builder.addCase(refreshEnterpriseClickhouseConnectionDetails.fulfilled, (state, action) => {
      state.enterpriseClickhouseConnectionDetails.error = '';
      if (action?.payload?.status === StatusCodes.OK) {
        state.enterpriseClickhouseConnectionDetails = action.payload.connectionDetails;
      } else if (action?.payload?.status === StatusCodes.NOT_FOUND) {
        state.enterpriseClickhouseConnectionDetails =
          initialState.enterpriseClickhouseConnectionDetails;
      }
    });
    builder.addCase(refreshEnterpriseClickhouseConnectionDetails.rejected, (state) => {
      state.enterpriseClickhouseConnectionDetails.error = 'Failed to fetch connection details.';
    });
  },
});

export const {
  setSettingsPage,
  setEnterpriseClickhouseConnectionDetails,
  setIsSettingsPageLoading,
} = settingsSlice.actions;

export default settingsSlice.reducer;
