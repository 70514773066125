import withModal from '@/Components/Modals/withModalHOC';
import Modal from '@/Components/UI/Modal/Modal';

import { MODAL_UPGRADE_TEAM_MEMBER } from '@/Utils/constants';

const UpgradeTeamMemberPopup = ({ hideModal, show }) => {
  const actions = {
    save: () => {
      hideModal();
    },
    cancel: () => {
      hideModal();
    },
  };

  return (
    <Modal size='small' isOpen={show} title='Account upgrade' onClose={actions.cancel}>
      <div className='mb-4'>
        <p>Contact your Gigasheet team administrator to request an upgrade.</p>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_UPGRADE_TEAM_MEMBER })(UpgradeTeamMemberPopup);
