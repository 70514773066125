import Modal from '@/Components/UI/Modal/Modal';
import {
  MODAL_BUSINESS_PAYWALL,
  MODAL_CHECKOUT,
  MODAL_GENERAL_UPGRADE,
  userTeamKey,
} from '@/Utils/constants';
import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { showModal } from '@/redux/reducers/modalsSlice';
import { setUserClosedUpgradeRequiredModal } from '@/redux/reducers/spreadsheetSlice';
import { useDispatch, useSelector } from 'react-redux';
import withModal from '../withModalHOC';
import { upgradeHandlerByType } from './UpgradeHelper';

const GeneralUpgradeModal = ({ show, hideModal, premiumFeature = '' }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state[userDataSliceName]);
  const { currentFile, filesSize: usedStorage } = useSelector((state) => state[datasetSliceName]);

  const hasBillingId = user?.userProperties?.hasBillingId;
  const isTeamMember = user[userTeamKey];
  const isTeamAdmin = isTeamMember && hasBillingId;
  const currentFileSize = currentFile?.metadata?.FileSize;
  const maxStorageSize = user?.userProperties?.maxFileSize;

  const manageBillingModal = () => dispatch(showModal({ name: MODAL_CHECKOUT }));
  const requestTrial = () => dispatch(showModal({ name: MODAL_BUSINESS_PAYWALL }));

  const onClose = () => {
    dispatch(setUserClosedUpgradeRequiredModal(true));
    hideModal();
  };

  const upgradeType = premiumFeature
    ? premiumFeature
    : isTeamMember && !isTeamAdmin
    ? 'teamMember'
    : 'general';

  const modalContent = upgradeHandlerByType(
    hasBillingId,
    usedStorage,
    currentFileSize,
    maxStorageSize,
    manageBillingModal,
    requestTrial,
    onClose
  )[upgradeType];

  return (
    <Modal
      title={modalContent.title}
      isOpen={show}
      onClose={onClose}
      titleCss='px-2'
      titlePosition={modalContent.align}
      modalClassName='!max-w-[1000px]'
      childrenCss='!p-0'
    >
      {modalContent.subhead}
      <iframe
        title='Upgrade Modal'
        src='https://webcontent.gigasheet.com/'
        height='550px'
        width='100%'
        allowFullScreen
      ></iframe>
      {modalContent.footer}
    </Modal>
  );
};

export default withModal({ name: MODAL_GENERAL_UPGRADE })(GeneralUpgradeModal);
