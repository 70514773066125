import { useDispatch } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal';
import ShareFile from '@/Components/Modals/ShareFile/ShareFile';

import { MODAL_SHARE_FILE } from '@/Utils/constants';
import { hideAllModals } from '@/redux/reducers/modalsSlice';

import './style/index.scss';
import withModal from '../withModalHOC';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ShareFileModal = ({ hideModal, show, accessRequestUserEmail }) => {
  const dispatch = useDispatch();

  const currentFile = useCurrentFile();
  const { FileName: fileName } = currentFile?.metadata || {};

  const closeShareModal = () => {
    dispatch(hideAllModals());
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeShareModal}
      title={`Share "${fileName}"`}
      articleID='69000833373'
      modalClassName='!w-[476px] !max-w-[476px]'
    >
      <ShareFile accessRequestUserEmail={accessRequestUserEmail} closePopup={hideModal} />
    </Modal>
  );
};

export default withModal({ name: MODAL_SHARE_FILE })(ShareFileModal);
