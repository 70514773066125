import clsx from 'clsx';
import Icon from '../Icon/Icon';

type Option = {
  label: string;
  value: string;
  helperText?: string;
  onClick?: () => void;
  disabled?: boolean;
};

type Props = {
  options: Option[];
  additionalClasses?: string;
};

export const StackedList = ({ options }: Props) => {
  const hasOptions = options.length > 0;
  return (
    <div className='flex flex-col w-full p-1 bg-white border rounded border-shadow-200'>
      {hasOptions
        ? options.map((option, i) => {
            return (
              <button
                disabled={option.disabled}
                onClick={option.onClick}
                type='button'
                key={option.label}
                className={clsx(
                  'text-ui text-left p-2 !rounded-none justify-between min-h-2 flex flex-row gap-2 hover:bg-[#C8C3FA29] items-center',
                  options.length !== i + 1 && 'border-b border-shadow-200',
                  option.disabled && 'hover:!bg-white'
                )}
              >
                <div className='flex flex-col gap-2 leading-5 w-fit'>
                  <p>{option.label}</p>
                  {option.helperText && <p>{option.helperText}</p>}
                </div>
                {!option.disabled && <Icon name='arrow-right' size={12} />}
              </button>
            );
          })
        : null}
    </div>
  );
};
