export const convertToGB = (size) => {
  try {
    if (!size) {
      return 0;
    }
    // Extract the numerical part and the unit
    const match = size.match(/([\d.]+)\s*(\w+)/);
    if (!match) {
      console.error('Invalid format: ', size);
      return 0;
    }

    const value = parseFloat(match[1]);
    const unit = match[2].toUpperCase();

    // Convert to GB
    switch (unit) {
      case 'KB':
        return value / 1024 / 1024;
      case 'MB':
        return value / 1024;
      case 'GB':
        return value;
      case 'TB':
        return value * 1024;
      default:
        console.error('Unknown unit: ', unit);
        return 0;
    }
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const convertBillingPeriod = (str) => {
  try {
    const billingPeriod = typeof str === 'string' ? str.toLowerCase() : '';
    switch (billingPeriod) {
      case 'year':
        return 'yr';
      default:
        return 'mo';
    }
  } catch (e) {
    console.error(e);
    return 'mo';
  }
};

export const convertTimestampToDate = (timestamp) => {
  try {
    if (!timestamp) {
      return '';
    }
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(date);
  } catch (e) {
    console.error(e);
    return '';
  }
};
