import { useEffect, useState, useRef } from 'react';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import Icon from '../../../Components/UI/Icon/Icon';

const ItemValue = ({ value }) => {
  const viewRowDataRef = useRef();
  const [isCopiedValue, setIsCopiedValue] = useState(false);
  let timerId;

  const onCopyValue = (value) => {
    navigator.clipboard.writeText(value);
    setIsCopiedValue(true);
  };

  const resetTooltipMessage = () => {
    timerId = setTimeout(() => setIsCopiedValue(false), 250);
  };

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  return (
    <>
      <div ref={viewRowDataRef} className='view-row-data-item-value'>
        {value}
      </div>
      <Tooltip
        container={viewRowDataRef}
        text={isCopiedValue ? 'copied' : 'copy'}
        side='bottom'
        asChild
      >
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          className='view-row-data-item-value-copy'
          onClick={() => onCopyValue(value)}
          onMouseLeave={resetTooltipMessage}
        >
          <Icon name='copy' alt='copy' className='text-cyan-700' size={18} />
        </div>
      </Tooltip>
    </>
  );
};

export default ItemValue;
