import { datasetSliceName } from '@/redux/constants';
import {
  setCurrentDirectory,
  setFilePath,
  setIsLoadingLibrary,
} from '@/redux/reducers/datasetSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../UI/Button/Button';
import Icon from '../UI/Icon/Icon';
import Popover from '../UI/Popover/Popover';
import { useNavigate } from 'react-router-dom';
import { URL_DATASETS } from '@/Utils/constants';
import { useWebSocketContext } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from '@/pages/DatasetPage/WebSocketLibrary/WebSocketContainer';

const MobilePath = ({ selectedMobileTab }) => {
  const navigate = useNavigate();
  const { sendMessage } = useWebSocketContext();
  const { filePath, currentDir } = useSelector((state) => state[datasetSliceName]);
  const [currentFolderName, setCurrentFolderName] = useState('');
  const [parentFolderList, setParentFolderList] = useState([]);

  const { currentDataset } = useSelector((state) => state[datasetSliceName]);

  const dispatch = useDispatch();

  const getCurrentFolderName = () => {
    const getCurrentFolder = filePath.find(({ FileUuid }) => FileUuid === currentDir);
    setCurrentFolderName(getCurrentFolder?.FileName || '');
  };

  const getParentFolderList = () => {
    const folderList = filePath.filter(({ FileUuid }) => FileUuid !== currentDir);
    setParentFolderList(folderList);
  };

  const navigateCurrPath = (id) => {
    dispatch(setIsLoadingLibrary(true));
    dispatch(setCurrentDirectory(id));
    sendMessage({
      location: id ? id : '',
      category: getWebSocketCategory(currentDataset),
    });
    if (!id) dispatch(setFilePath([]));
    navigate(`/${URL_DATASETS}/${id}`);
  };

  const renderParentFolderList = ({ close }) => {
    const onButtonClick = (FileUuid) => {
      navigateCurrPath(FileUuid);
      close();
      navigate(`/${URL_DATASETS}/${FileUuid}`);
    };

    return (
      <ul>
        <li>
          <Button
            variant='ghost'
            color='shadow'
            onClick={() => navigateCurrPath('')}
            className='!w-full !justify-start'
            dataCy={selectedMobileTab === 1 ? 'shared-with-me' : 'library'}
          >
            {selectedMobileTab === 1 ? 'Shared With Me' : 'Library'}
          </Button>
        </li>
        {parentFolderList.map(({ FileUuid, FileName }) => {
          return (
            <li key={FileUuid}>
              <Button
                variant='ghost'
                color='shadow'
                onClick={() => onButtonClick(FileUuid)}
                className='!w-full !justify-start !truncate'
              >
                {FileName}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    getCurrentFolderName();
    getParentFolderList();
  }, [filePath]);

  return (
    <div className='flex items-center justify-start mb-1'>
      <Popover
        isButtonIcon={false}
        isStatic={false}
        isCloseButton={false}
        panelClassname='!w-[250px] !max-h-[200px] !overflow-scroll'
        buttonClassname='!h-[24px] bg-shadow-200 !p-1'
        renderComponent={(props) => renderParentFolderList(props)}
      >
        <Icon dataCy='mobile-nav-button' name='tree-structure' color='#151515' size={16} />
      </Popover>
      <div className='mb-1 ml-2'>
        <Icon name='caret-right' color='#707070' size={12} />
      </div>
      <div className='inline text-[13px] text-ui ml-2 text-ellipsis overflow-hidden whitespace-nowrap max-w-[250px]'>
        {currentFolderName}
      </div>
    </div>
  );
};

export default MobilePath;
