import { useEffect, useState, useMemo } from 'react';

import Icon from '@/Components/UI/Icon/Icon';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';

import toNonTechnicalType from './convertorToNonTechnicalType';
import { formatIPElements, formatNumberElements } from './radioElements';

import '../style/index.scss';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { ROW_NUMBER_COLUMN_ID, ROW_NUMBER_COLUMN_NAME } from '@/Utils/gridConstants';
import Label from '@/Components/UI/Form/Label/Label';

const typeOptions = [
  { value: 'number', label: 'Number', type: 'Int64', format: 'decimal' },
  { value: 'plain_text', label: 'Plain Text', type: 'String', format: 'plain_text' },
  { value: 'ip_address', label: 'IP Address', type: 'IPv4', format: 'ipv4' },
  { value: 'date', label: 'Date', type: 'DateTime64', format: 'plain_text' },
  { value: 'plain_text', label: 'Email Address', type: 'EmailAddress', format: 'email_address' },
  { value: 'plain_text', label: 'Zip Code', type: 'ZipCode', format: 'zip_code' },
];

const ChangeDataType = ({
  options,
  targetColumn,
  isConfirmStep,
  setTargetColumn,
  setSaveBtnDisabled,
  updateChangeDataTypeState,
}) => {
  const [format, setFormat] = useState('');
  const [isConfirmDialogChecked, setIsConfirmDialogChecked] = useState(false);
  const [castType, setCastType] = useState('');
  const [srcType, setSrcType] = useState('');
  const [technicalSrcType, setTechnicalSrcType] = useState('');
  const memoizedColumnsOptions = (options) => {
    return options.filter(
      ({ value, label }) => value !== ROW_NUMBER_COLUMN_ID || label !== ROW_NUMBER_COLUMN_NAME
    );
  };

  const excludeIdColumnOptions = useMemo(() => memoizedColumnsOptions(options), [options]);

  useEffect(() => {
    setSaveBtnDisabled(true);
    if (!targetColumn) return;

    onOriginalColumnChange(targetColumn);
  }, [targetColumn]);

  useEffect(() => {
    updateChangeDataTypeState({
      sourceColumn: targetColumn,
      castType: castType,
      format: format,
      isConfirmDialogChecked: isConfirmDialogChecked,
      srcType: srcType,
      technicalSrcType: technicalSrcType,
    });
  }, [targetColumn, castType, format, isConfirmDialogChecked, srcType, technicalSrcType]);

  const onOriginalColumnChange = (column) => {
    setSaveBtnDisabled(true);
    setTargetColumn(column);
    setSrcType(toNonTechnicalType(column.type));
    setTechnicalSrcType(column.type);
    setCastType('');
  };

  const onTypeChange = (column) => {
    setCastType(column.value);
    setFormat(column.format);
    setSaveBtnDisabled(false);
  };

  const onCheckedConfirmDialog = (event) => {
    setIsConfirmDialogChecked(event.target.checked);
  };

  return (
    <div data-cy='change-data-type-container'>
      <div hidden={isConfirmStep}>
        <div className='flex justify-between mb-2'>
          <Label text='Original Column' />
          <div
            hidden={srcType === ''}
            data-cy='custom-header-right'
            className='bg-[#bcecf0] p-1 h-6 leading-4 text-[13px]'
          >
            Type: {srcType}
          </div>
        </div>
        <div className='mb-4'>
          <SearchSelect
            options={excludeIdColumnOptions}
            placeholder='Select Column'
            onChange={onOriginalColumnChange}
            setMenuIsOpen={null}
            defaultValue={targetColumn}
            getOptionLabel={(option) => option.label}
          />
        </div>
        <Label text='New Column Type' />
        <div className='mb-4'>
          <SearchSelect
            options={
              srcType === 'Boolean' || srcType === 'IP Address'
                ? typeOptions.filter((option) => option.type !== 'DateTime64')
                : typeOptions
            }
            placeholder='Column Type'
            onChange={onTypeChange}
            setMenuIsOpen={null}
            isDisabled={!targetColumn}
          />
        </div>
        <div
          id='change-data-type-format-container'
          hidden={['', 'plain_text', 'date'].includes(castType)}
          className='format-container'
        >
          <p className='format-container-title'>Format</p>
          <div hidden={castType !== 'ip_address'}>
            {formatIPElements(format).map((element, index) => {
              const { radioFormat, checked, text } = element;
              return (
                <div className='format-container-radio-block' key={index}>
                  <input
                    type='radio'
                    name='format_ip'
                    id={`format_ip-${index}`}
                    onChange={() => setFormat(radioFormat)}
                    checked={checked}
                  />
                  <label className='format-container-radio-label' htmlFor={`format_ip-${index}`}>
                    {text}
                  </label>
                </div>
              );
            })}
          </div>
          <div hidden={castType !== 'number'}>
            {formatNumberElements(format).map((element, index) => {
              const { radioFormat, checked, text } = element;
              return (
                <div className='format-container-radio-block' key={index}>
                  <input
                    type='radio'
                    name='format_number'
                    id={`number_ip-${index}`}
                    onChange={() => setFormat(radioFormat)}
                    checked={checked}
                  />
                  <label className='format-container-radio-label' htmlFor={`number_ip-${index}`}>
                    {text}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div hidden={!isConfirmStep}>
        <p>
          You may lose some data. The original column will be hidden. You can toggle its visibility
          in the
          <Icon name='columns' size={22} />
          Columns panel.
        </p>
        <div className='show-confirm-dialog-checkbox'>
          <CheckboxInput
            onChange={onCheckedConfirmDialog}
            isChecked={isConfirmDialogChecked}
            label='Don’t show this message again'
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeDataType;
