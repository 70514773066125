import { useEffect } from 'react';

const useOnClickOutsideHook = (isEventActual, ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (isEventActual && ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    };

    document.addEventListener('click', listener, true);
    return () => {
      document.removeEventListener('click', listener, true);
    };
  }, [isEventActual, ref, handler]);
};

export default useOnClickOutsideHook;
