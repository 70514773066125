import { combineReducers } from '@reduxjs/toolkit';

import {
  authenticationSliceName,
  datasetSliceName,
  modalsSliceName,
  sharedSliceName,
  spreadsheetSliceName,
  enrichmentTemplatesSliceName,
  settingsSliceName,
  combineFilesSliceName,
  buildSliceName,
} from '../constants';

import authenticationSliceReducer from './authenticationSlice';
import datasetSliceReducer from './datasetSlice';
import modalsSliceReducer from './modalsSlice';
import sharedSliceReducer from './sharedSlice';
import spreadsheetSliceReducer from './spreadsheetSlice';
import userDataSliceReducer from './userDataSlice';
import enrichmentTemplatesReducer from './enrichmentTemplates';
import settingsSliceReducer from './settingsSlice';
import combineFilesSliceReducer from './combineFilesSlice';
import buildSliceReducer from './buildSlice';

const rootReducer = combineReducers({
  userData: userDataSliceReducer,
  [datasetSliceName]: datasetSliceReducer,
  [modalsSliceName]: modalsSliceReducer,
  [sharedSliceName]: sharedSliceReducer,
  [spreadsheetSliceName]: spreadsheetSliceReducer,
  [authenticationSliceName]: authenticationSliceReducer,
  [enrichmentTemplatesSliceName]: enrichmentTemplatesReducer,
  [settingsSliceName]: settingsSliceReducer,
  [combineFilesSliceName]: combineFilesSliceReducer,
  [buildSliceName]: buildSliceReducer,
});

export default rootReducer;
