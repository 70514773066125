import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import PasswordInput from '@/Components/UI/Form/PasswordInput/PasswordInput';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { useEffect, useState } from 'react';
import { Button } from '@/Components/UI/Button/Button';
import HelperText from '@/Components/UI/HelperText/HelperText';
import { useDispatch, useSelector } from 'react-redux';
import {
  refreshEnterpriseClickhouseConnectionDetails,
  setEnterpriseClickhouseConnectionDetails,
} from '@/redux/reducers/settingsSlice';
import { settingsSliceName } from '@/redux/constants';
import showToast from '@/Components/Toast/showToastTemplate';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@/Utils/constants';
import { postRaw } from '@/Utils/API';
import { StatusCodes } from 'http-status-codes';
import Spinner from '@/Components/UI/Spinner/Spinner';

const inputStyle =
  'w-full active:border-ocean-blue-400 focus:border-ocean-blue-500 focus:ring-2 focus:ring-midnight-100';

const FieldWrapper = ({ children }) => <div className='w-full'>{children}</div>;

const LinkToClickhouse = () => {
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(false);
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [ssl, setSSL] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enterpriseClickhouseConnectionDetails } = useSelector(
    (state) => state[settingsSliceName]
  );

  const isError = enterpriseClickhouseConnectionDetails?.error?.length > 0;

  useEffect(() => {
    dispatch(refreshEnterpriseClickhouseConnectionDetails());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: 'Failed to fetch Clickhouse connection details. Please try again later.',
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
    setEnabled(enterpriseClickhouseConnectionDetails.enabled);
    setHost(enterpriseClickhouseConnectionDetails.host);
    setPort(enterpriseClickhouseConnectionDetails.port);
    setUsername(enterpriseClickhouseConnectionDetails.username);
    setPassword(enterpriseClickhouseConnectionDetails.password);
    setSSL(enterpriseClickhouseConnectionDetails.ssl);
    setPasswordTouched(false);
  }, [enterpriseClickhouseConnectionDetails, isError]);

  const save = () => {
    setLoading(true);
    const connectionDetails = {
      enabled,
      host,
      port: parseInt(port, 10),
      username,
      password,
      ssl,
    };
    dispatch(setEnterpriseClickhouseConnectionDetails(connectionDetails));
    const endpoint = 'external-database/connections/clickhouse';
    postRaw(endpoint, connectionDetails).then((response) => {
      setLoading(false);
      let toastType = TOAST_TYPE_SUCCESS;
      let text = 'Clickhouse connection details saved successfully.';
      if (response.status !== StatusCodes.OK) {
        toastType = TOAST_TYPE_ERROR;
        if (response.status === StatusCodes.BAD_REQUEST) {
          text = 'Check that your connection details are valid and try again.';
        } else {
          text = 'Failed to save Clickhouse connection details. Please try again later.';
        }
      }
      showToast({
        type: toastType,
        text: text,
        endpoint: endpoint,
        payload: connectionDetails,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    });
  };

  const handlePasswordFocus = () => {
    if (!passwordTouched) {
      setPassword('');
      setPasswordTouched(true);
    }
  };

  const isDisabled = isError || loading || !enabled;

  return (
    <div className='w-full h-full px-16'>
      <div className='max-w-[640px] flex flex-col w-full gap-6 p-6 pb-8 bg-white border'>
        <FieldWrapper>
          <CheckboxInput
            label='Enabled'
            disabled={isError || loading}
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
          <HelperText
            className='mt-2 break-words'
            text='When enabled Gigasheet will use this Clickhouse instance to store data. If disabled, Gigasheet will use its own storage.'
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextInput
            className={inputStyle}
            disabled={isDisabled}
            label='Host'
            helperText='Clickhouse needs to be reachable. Use a public IP or a domain name and make sure firewall rules are set up correctly.'
            value={host}
            onChange={(e) => setHost(e.target.value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextInput
            className={inputStyle}
            disabled={isDisabled}
            label='Port'
            value={port}
            onChange={(e) => setPort(e.target.value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextInput
            className={inputStyle}
            disabled={isDisabled}
            label='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <PasswordInput
            label='Password'
            disabled={isDisabled}
            showKeyDisabled={true}
            name='password'
            placeholder='Enter password'
            className={inputStyle}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handlePasswordFocus}
          />
        </FieldWrapper>
        <FieldWrapper>
          <CheckboxInput
            label='Use SSL'
            disabled={isDisabled}
            checked={ssl}
            onChange={(e) => setSSL(e.target.checked)}
          />
        </FieldWrapper>
        <div className='flex flex-col gap-2 text-sm font-semibold text-ui'>
          <Button disabled={loading} className='font-normal w-36' onClick={save}>
            {loading ? <Spinner color='midnight' /> : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LinkToClickhouse;
