import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@/Components/UI/Button/Button';
import ShowToast from '../Toast/showToastTemplate';

import { post } from '@/Utils/API';

import { ReactComponent as UnlockIcon } from '@/Assets/icons/Modal_Icons/icon_lock.svg';
import { ReactComponent as EnvelopIcon } from '@/Assets/icons/Modal_Icons/icon_envelop.svg';

import {
  REQUEST_ACCESS,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';

import './index.scss';

const AccessForm = () => {
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [message, setMessage] = useState('');
  const { uuid } = useParams();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  const folderId = searchParam?.get('folder');

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const endpoint = `${REQUEST_ACCESS}/${uuid || folderId}`;
    const payload = {
      message,
    };
    const { Success, Error } = await post(endpoint, payload);

    if (Success) {
      setMessage('');
      setIsRequestSend(true);
    } else {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: Error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      throw new Error(Error);
    }
  };

  const onReturnBtnClick = () => {
    navigate('/', { replace: true });
  };

  const icon = !isRequestSend ? <UnlockIcon /> : <EnvelopIcon />;

  const title = !isRequestSend ? 'Uh oh, you do not have access.' : 'Your request has been sent!';
  const subTitle = !isRequestSend
    ? 'You don’t have access to this sheet. You may request access by contacting the owner.'
    : 'You will receive an email when your access has been granted. ';

  return (
    <div className='spreadsheet-access-form_wrapper'>
      <div className='spreadsheet-access-form'>
        {icon}
        <p className='spreadsheet-access-form_title'>{title}</p>
        <p className='spreadsheet-access-form_subtitle'>{subTitle}</p>
        {!isRequestSend && (
          <form className='spreadsheet-access-form_form' onSubmit={onSubmit}>
            <label htmlFor='message'>Add a note to the file owner (optional):</label>
            <textarea
              id='message'
              cols='30'
              rows='4'
              placeholder='Message (optional)'
              value={message}
              onChange={onMessageChange}
              maxLength='200'
            />
            <Button type='submit' size='large' variant='solid' color='sunrise'>
              Request Access
            </Button>
          </form>
        )}
        {isRequestSend && (
          <Button
            onClick={onReturnBtnClick}
            iconName=''
            size='large'
            variant='solid'
            color='shadow'
          >
            RETURN TO LIBRARY
          </Button>
        )}
      </div>
    </div>
  );
};

export default AccessForm;
