import React from 'react';

export const replaceAtSign = ({ autofillOptions, commentText }) => {
  const parts = [];
  let lastIndex = 0;

  autofillOptions.forEach(({ value }) => {
    const regex = new RegExp(`@${value}`, 'g');
    let match;
    while ((match = regex.exec(commentText)) !== null) {
      parts.push(commentText.slice(lastIndex, match.index));

      parts.push(
        <mark key={match.index} className='bg-[#33BBFF29] rounded'>
          {match[0]}
        </mark>
      );

      lastIndex = regex.lastIndex;
    }
  });

  parts.push(commentText.slice(lastIndex));

  return parts.length > 0 ? parts : [commentText];
};
