import Modal from '@/Components/UI/Modal/Modal';
import { MODAL_INVITE_TEAM } from '@/Utils/constants';
import withModal from '../withModalHOC';
import Onboarding from '@/pages/OnboardingPage/Onboarding';

const InviteTeamPopup = ({ show, hideModal }) => {
  return (
    <Modal isOpen={show} onClose={hideModal} size='small' title='gigasheet'>
      <Onboarding inviteUsersStepOnly onCloseModal={hideModal} />
    </Modal>
  );
};

export default withModal({ name: MODAL_INVITE_TEAM })(InviteTeamPopup);
