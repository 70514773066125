import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CrossFileVLookup from './CrossFileVLookup';
import withModal from '@/Components/Modals/withModalHOC';
import showToast from '@/Components/Toast/showToastTemplate';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  MODAL_CROSS_FILE_VLOOKUP,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import './style/index.scss';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const CrossFileVLookupModal = ({ show, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const [isTrueFalse, setIsTrueFalse] = useState('trueFalse');
  const [isNearMatch, setIsNearMatch] = useState(false);
  const [additionalColumns, setAdditionalColumns] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedLookupColumn, setSelectedLookupColumn] = useState(null);

  const { options, targetColumn, setTargetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
  });

  const onCloseModal = () => {
    dispatch(hideAllModals());
    setTargetColumn(null);
    setSelectedFile(null);
    setSelectedLookupColumn(null);
    setIsTrueFalse('trueFalse');
    setAdditionalColumns([]);
    setIsNearMatch(false);
  };

  const crossFile = async () => {
    const uuid = getFileUuidFromPath();
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    onCloseModal();
    dispatch(setIsInsertingNewColumn(true));
    try {
      const { FileUuid: againstHandle } = selectedFile;
      const selectedLookupColumnId = selectedLookupColumn.value;
      const additionalColumnsIds = additionalColumns.map((col) => col.value);

      const endpoint = `lookup/${uuid}/${targetColumn.value}`;
      const payload = {
        referenceFile: {
          handle: againstHandle,
          column: selectedLookupColumnId,
          additionalColumns: additionalColumnsIds,
        },
        matchConditions: {
          caseInsensitive: isNearMatch,
          ignorePunctuation: isNearMatch,
          ignoreWhitespace: isNearMatch,
        },
      };
      const response = await post(endpoint, payload, true);

      if (!response.Success) {
        const catchMaxQuery = response.Message.includes('maximum');
        const errorMessage = catchMaxQuery
          ? 'The result size of the lookup is too big'
          : 'Unable to apply function';
        showToast({
          type: TOAST_TYPE_ERROR,
          text: errorMessage,
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      }

      dispatch(fetchOpenedFile(uuid));
      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Cross File VLookup' },
          userIncrementName: '# of function use',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const additionalFields = isTrueFalse === 'value' && !additionalColumns.length;
  const disabled = !selectedFile || !selectedLookupColumn || !isTrueFalse || additionalFields;

  return (
    <Modal
      size='medium'
      isOpen={show}
      onClose={onCloseModal}
      title='Cross File VLookup'
      iconName='file-search'
      hasQuestionMark
      articleID={69000668623}
    >
      <CrossFileVLookup
        additionalColumns={additionalColumns}
        setAdditionalColumns={setAdditionalColumns}
        isTrueFalse={isTrueFalse}
        setIsTrueFalse={setIsTrueFalse}
        isNearMatch={isNearMatch}
        setIsNearMatch={setIsNearMatch}
        currentFileColumns={options}
        selectedColumn={targetColumn}
        setSelectedColumn={setTargetColumn}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setSelectedLookupColumn={setSelectedLookupColumn}
        selectedLookupColumn={selectedLookupColumn}
      />
      <hr className='my-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' onClick={onCloseModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' onClick={crossFile} disabled={disabled} dataCy='save-btn'>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CROSS_FILE_VLOOKUP })(CrossFileVLookupModal);
