import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@/Components/UI/Modal/Modal'; // Adjust the import path as necessary
import { Button } from '@/Components/UI/Button/Button';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';
import withModal from '@/Components/Modals/withModalHOC';
import ShowToast from '@/Components/Toast/showToastTemplate';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName } from '@/redux/constants';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import { post } from '@/Utils/API';
import shieldSymbols from '@/Utils/shieldSymbols';
import {
  MODAL_SPLIT_COLUMN,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import Label from '@/Components/UI/Form/Label/Label';
import { showModal } from '@/redux/reducers/modalsSlice';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const SplitColumnModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const [separator, setSeparator] = useState('');

  const { options, setTargetColumn, targetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
  });

  const closeSplitColumnModal = () => {
    hideModal();
    setTargetColumn(null);
    setSeparator('');
  };

  const splitColumn = async () => {
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      closeSplitColumnModal();
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      const uuid = getFileUuidFromPath();
      const shieldedSeparator = shieldSymbols(separator);

      const endpoint = `split-column/${uuid}`;
      const payload = {
        column: targetColumn.value,
        separator: shieldedSeparator,
      };
      const response = await post(endpoint, payload, true);

      if (!response?.Success) {
        ShowToast({
          type: TOAST_TYPE_ERROR,
          text: response?.Message || TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
      } else {
        await dispatch(fetchOpenedFile(uuid));

        dispatch(
          addMixpanelEvent({
            eventName: 'Function Used',
            eventProps: { FunctionName: 'Split Column' },
            userIncrementName: '# of function use',
          })
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
      closeSplitColumnModal();
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeSplitColumnModal}
      title='Split Column'
      articleID='69000776209'
      iconName='arrows-split'
    >
      <div className='split-columns-container'>
        <div className='mb-4'>
          <Label text='Choose column' className='mb-2' />
          <SearchSelect
            options={options}
            onChange={setTargetColumn}
            value={targetColumn}
            getOptionLabel={(option) => option.label}
          />
        </div>
        <div className='mb-4'>
          <TextInput
            name='separator'
            label='Separator'
            className='w-full'
            value={separator}
            onChange={(e) => setSeparator(e.target.value)}
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          onClick={closeSplitColumnModal}
          dataCy='cancel-btn'
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={splitColumn}
          disabled={!separator || !targetColumn}
          dataCy='save-btn'
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_SPLIT_COLUMN })(SplitColumnModal);
