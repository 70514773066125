import { getClientState } from '@/Utils/ClientStateCommon';
import ErrorInfoBlock from '../pages/DatasetPage/ErrorInfoBlock/ErrorInfoBlock';
import {
  FILE_PROCESSING_STATE,
  FILE_STATUS_ERROR,
  FILE_STATUS_LOADING,
  FILE_STATUS_PROCESSED,
  FILE_STATUS_PROCESSING,
} from '../Utils/fileConstants';
import { parseFileSize } from '../Utils/parseFileSize';
import { formatUrlSafeFileName, openInNewTab } from '../Utils/utils';

export const getTeamNameLabel = (teamName) => {
  if (!teamName) return 'Team Workspace';
  return `${teamName} Workspace`;
};

export const getFileSize = (row) => {
  if (row.Status === FILE_STATUS_ERROR) return <ErrorInfoBlock row={row} />;

  if (row.Status === FILE_STATUS_PROCESSING) return 'Processing';

  if (row.Status === FILE_STATUS_LOADING) return 'Loading';

  return parseFileSize(row.FileSize);
};

export const onEditFolder = (callback) => ({
  title: 'Folder Properties',
  icon: 'info',
  callback,
  dataCy: 'actions-dropdown-info',
});

export const onOpenFile = (callback) => ({
  title: 'Open File',
  icon: 'arrow-square-out',
  callback,
  dataCy: 'actions-dropdown-open',
});

export const onOpenFolder = (callback) => ({
  title: 'Open Folder',
  icon: 'arrow-square-out',
  callback,
  dataCy: 'actions-dropdown-open-folder',
});

export const onEditFile = (callback) => ({
  title: 'File Properties',
  icon: 'info',
  callback,
  dataCy: 'actions-dropdown-info',
});

export const onShareFile = (callback) => ({
  title: 'Share',
  icon: 'user-plus',
  callback,
  dataCy: 'actions-dropdown-share',
});

export const onCopyFile = (callback) => ({
  title: 'Make a copy',
  icon: 'copy',
  callback,
  dataCy: 'actions-dropdown-copy',
});

export const onDeleteFile = (callback) => ({
  title: 'Delete',
  icon: 'trash',
  callback,
  dataCy: 'actions-dropdown-delete',
});

export const onRemoveAccess = (callback) => ({
  title: 'Remove Access',
  icon: 'trash',
  callback,
  dataCy: 'actions-dropdown-remove_access',
});

export const onExport = ({ openExportFileModal, openSplitToMultipleCVSsPopup, canSplitSheet }) => ({
  title: 'Download',
  icon: 'export',
  callback: () => {},
  dataCy: 'actions-dropdown-export',
  submenu: [
    {
      title: 'Export CSV',
      submenu: [],
      callback: openExportFileModal,
    },
    {
      title: 'Split to Multiple CSVs',
      submenu: [],
      callback: openSplitToMultipleCVSsPopup,
      premiumFeature: !canSplitSheet,
    },
  ],
});
export const onDownloadFile = (callback) => ({
  title: 'Download',
  icon: 'export',
  callback,
  dataCy: 'actions-dropdown-download',
});

export const onCopyExportUrl = (callback) => ({
  title: 'Copy URL',
  icon: 'link-simple-horizontal',
  callback,
  dataCy: 'actions-dropdown-copy-export-url',
});

export const onMoveFile = (callback) => ({
  title: 'Move',
  icon: 'arrows-out-cardinal',
  callback,
  dataCy: 'actions-dropdown-move',
});

export const onSheetHistory = (callback, canUseAuditLog) => ({
  title: 'Sheet History',
  icon: 'clock-counter-clockwise',
  callback,
  dataCy: 'actions-dropdown-sheet-history',
  premiumFeature: !canUseAuditLog,
});

export const getTheLastUploadedFileId = (allFiles) => {
  return [...allFiles].sort((a, b) => {
    if (b.metadata.CreatedAt > a.metadata.CreatedAt) {
      return 1;
    }
    if (b.metadata.CreatedAt < a.metadata.CreatedAt) {
      return -1;
    }
    return 0;
  })[0].metadata['FileUuid'];
};

export const openProcessedFile = (processingFiles, allFiles) => {
  if (!processingFiles) return;
  let stillProcessing = processingFiles;

  const findProcessedFile = (file, allFiles) => {
    // Look through allFiles to see if file has processed successfully
    const foundFile = allFiles?.filter(
      ({ metadata: { FileUuid } }) => FileUuid === file.metadata.FileUuid
    )[0];

    if (!foundFile) return;

    const { Status, IsDirectory, FileName, FileUuid } = foundFile.metadata;
    if (foundFile && Status === FILE_STATUS_PROCESSED && !IsDirectory) {
      // If it has processed successfully, open it in a new tab
      openInNewTab(`spreadsheet/${formatUrlSafeFileName(FileName)}/${FileUuid}`);
      // and remove it from the list of processing files
      stillProcessing = processingFiles.filter((i) => i.metadata.FileUuid !== FileUuid);
    }
  };

  processingFiles.forEach((file) => findProcessedFile(file, allFiles));

  return stillProcessing;
};

export const getFieldValueFromColumnState = (key, currentFile) => {
  const getDisplayName = (colId) => {
    const index = Object.values(currentFile.metadata.HeaderToColumnMapping).indexOf(colId);
    return currentFile.metadata.Headers[index];
  };

  const fieldValue = currentFile.metadata.ClientState.columnState.reduce((acc, el) => {
    if (el[key]) {
      const row = {
        id: el.colId,
        displayName: getDisplayName(el.colId),
        field: el.colId,
      };

      if (key === 'aggFunc') {
        row.aggFunc = el[key];
      }

      return [...acc, row];
    }

    return acc;
  }, []);

  return fieldValue;
};

export const _isCanExportFilteredGroupedRolePaywall = ({ row, canExportFilteredGrouped }) => {
  const isFilterApplied =
    row?.ClientState?.filterModel &&
    Object.values(row?.ClientState?.filterModel)?.some((el) => el?.length);

  const isGroupApplied =
    row?.ClientState?.columnState?.length &&
    row?.ClientState?.columnState?.some(({ rowGroup }) => rowGroup);

  const isCanExportFilteredGroupedRolePaywall =
    !canExportFilteredGrouped && (isFilterApplied || isGroupApplied);
  return !!isCanExportFilteredGroupedRolePaywall;
};

export const createGridRequestData = async ({ fileUuid, canExportFilteredGrouped = false }) => {
  const metadata = await getClientState(fileUuid);
  const currentFile = { metadata };

  const isCanExportFilteredGroupedRolePaywall = _isCanExportFilteredGroupedRolePaywall({
    row: currentFile.metadata,
    canExportFilteredGrouped,
  });

  const groupKeys = currentFile.metadata.ClientState.expandedNodes.length
    ? [
        currentFile.metadata.ClientState.expandedNodes[
          currentFile.metadata.ClientState.expandedNodes.length - 1
        ].nestedNodeID,
      ]
    : [];

  const getGridRequestData = {
    startRow: 0,
    rowGroupCols: getFieldValueFromColumnState('rowGroup', currentFile),
    valueCols: getFieldValueFromColumnState('aggFunc', currentFile),
    pivotCols: currentFile.metadata.ClientState.isPivotEnabled
      ? getFieldValueFromColumnState('pivot', currentFile)
      : [],
    pivotMode: currentFile.metadata.ClientState.isPivotEnabled,
    groupKeys,
    filterModel: currentFile.metadata.ClientState.filterModel,
    sortModel: currentFile.metadata.ClientState.sortModel,
  };

  return { getGridRequestData, isCanExportFilteredGroupedRolePaywall, currentFile };
};

export const areFilesLoading = (allFiles) => {
  const loadingFiles = [];

  allFiles?.map((file) => {
    if (FILE_PROCESSING_STATE.includes(file.metadata.Status)) {
      loadingFiles.push(file);
    }
  });

  const areFilesLoading = loadingFiles?.length > 0;

  const singleFileLoadingStatus =
    loadingFiles.length === 1 ? loadingFiles[0].metadata.StatusDetails?.percent : '';

  return { areFilesLoading, singleFileLoadingStatus };
};
