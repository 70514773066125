import FilterPanelMultiValue from '@/Components/Modals/FilterPanel/FilterPanelMultiValue';

function InRange({
  onChange,
  modelType,
  indexInFilterModel,
  ifCondition,
  isValidConditions = true,
  isWrongRegexValue,
  isMultiValueEnabled,
  setIsDisabledApplyButton,
  currFieldType,
  setAnyFilterData,
  colId,
  filter,
  filterTo,
}) {
  const onChangeFrom = (fromValue) => {
    return onChange('filter', fromValue);
  };

  const onChangeTo = (toValue) => {
    return onChange('filterTo', toValue);
  };

  return (
    <>
      <div>
        <FilterPanelMultiValue
          type={modelType}
          cypressTextFieldName={'filter-panel-input-in_range_from'}
          showRegexInvalidBlock={isWrongRegexValue}
          setIsDisabledApplyButton={setIsDisabledApplyButton}
          isMultiple={isMultiValueEnabled}
          disabled={!modelType}
          defaultValue={colId ? filter : ''}
          currFieldType={currFieldType}
          onChange={onChangeFrom}
          indexInFilterModel={indexInFilterModel}
          setAnyFilterData={setAnyFilterData}
          ifCondition={ifCondition}
          isValidConditions={isValidConditions}
          customProperties={{ inRangeType: 'from' }}
        />
      </div>
      <div className='ml-3'>
        <FilterPanelMultiValue
          type={modelType}
          cypressTextFieldName={'filter-panel-input-in_range_to'}
          showRegexInvalidBlock={isWrongRegexValue}
          setIsDisabledApplyButton={setIsDisabledApplyButton}
          isMultiple={isMultiValueEnabled}
          disabled={!modelType}
          defaultValue={colId ? filterTo : ''}
          currFieldType={currFieldType}
          onChange={onChangeTo}
          setAnyFilterData={setAnyFilterData}
          ifCondition={ifCondition}
          isValidConditions={isValidConditions}
          customProperties={{ inRangeType: 'to' }}
        />
      </div>
    </>
  );
}

export default InRange;
