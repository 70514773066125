import { useAuth0 } from '@auth0/auth0-react';
import Modal from '@/Components/UI/Modal/Modal'; // Ensure correct import path
import { Button } from '@/Components/UI/Button/Button';

import { MODAL_REQUIRE_ACCOUNT } from '@/Utils/constants';
import withModal from '@/Components/Modals/withModalHOC';
import { getAuthNonceAndSetInStorage } from '@/Utils/utils';

const RequireAccountModal = ({ show, hideModal }) => {
  const { loginWithRedirect } = useAuth0();
  const authStateNonce = getAuthNonceAndSetInStorage();

  const signIn = () => {
    loginWithRedirect({ appState: { authStateNonce } });
  };

  return (
    <Modal isOpen={show} onClose={hideModal} title='Sign in' iconName='eye'>
      <p>This action requires you to be signed in.</p>
      <hr className='mt-10 mb-5 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button color='oceanBlue' size='large' onClick={signIn} dataCy='save-btn'>
          Sign in
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_REQUIRE_ACCOUNT })(RequireAccountModal);
