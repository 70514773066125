import {
  PERMISSION_DENIED,
  PERMISSION_NONE,
  PERMISSION_READ,
  PERMISSION_WRITE,
  URL_DATASET,
  USER_FILE_PERMISSIONS,
} from '../Utils/constants';
import { get } from '@/Utils/API';
import { StatusCodes } from 'http-status-codes';

export const isSharedWithMe = (file) => {
  return file?.permissions?.length > 0;
};

export const isSharedByMe = (file) => {
  return file?.shared?.restricted?.length > 0 || file?.shared?.public?.length > 0;
};

export const isWorldReadable = (file) => {
  return file?.shared?.public?.length > 0;
};

export const isSharedWithTeam = (file) => {
  return file?.shared?.organization?.length > 0;
};

export const isReadOnly = (file) => {
  return isSharedWithMe(file) && !file?.permissions?.includes(PERMISSION_WRITE);
};

// Get any inherited user file permissions from the parent folder
export const getInheritedUserFilePermissionsFromParentFolder = async (fileUuid) => {
  try {
    const metadata = await get(`${URL_DATASET}/${fileUuid}`);
    const parentFolderHandle = metadata?.ParentDirectory;
    const hasParentFolder = parentFolderHandle?.length > 0;

    if (hasParentFolder) {
      return await get(
        `${URL_DATASET}/${parentFolderHandle}/${USER_FILE_PERMISSIONS}?includeInherited=true`
      );
    } else {
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};

// Convert user file permissions to access level
export const accessLevelFromUserFilePermissions = (userFilePermissions) => {
  try {
    const isDeniedLvl = userFilePermissions.some(
      ({ permission_id }) => permission_id === `${PERMISSION_DENIED}`
    );
    const isWriteLvl =
      !isDeniedLvl &&
      userFilePermissions.some(({ permission_id }) => permission_id === `${PERMISSION_WRITE}`);
    const isReadOnly =
      !isDeniedLvl &&
      !isWriteLvl &&
      userFilePermissions.some(({ permission_id }) => permission_id === `${PERMISSION_READ}`);

    if (userFilePermissions?.length === 0) {
      return PERMISSION_NONE;
    } else if (isReadOnly) {
      return PERMISSION_READ;
    } else if (isWriteLvl) {
      return PERMISSION_WRITE;
    } else if (isDeniedLvl) {
      return PERMISSION_DENIED;
    } else {
      return PERMISSION_NONE;
    }
  } catch (e) {
    console.error(e);
    return PERMISSION_NONE;
  }
};

// Call API to get organization file permissions for provided handle and check if any exist
export const IsFileSharedWithTeam = async (handle) => {
  try {
    const orgPermissions = await GetOrganizationFilePermissions(handle);
    return orgPermissions.length > 0;
  } catch (e) {
    console.error(e);
    return false;
  }
};

// Call API to get user file permissions for provided handle and check if shared with me. Default to not including inherited permissions (from parent folder(s)).
export const IsFileSharedWithMe = async (user, handle, includeInherited = false) => {
  try {
    const userFilePermissions = await GetUserFilePermissions(user, handle, includeInherited);
    return (
      userFilePermissions.length > 0 &&
      [PERMISSION_READ, PERMISSION_WRITE].includes(
        accessLevelFromUserFilePermissions(userFilePermissions)
      )
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

// Get organization file permissions for provided handle
export const GetOrganizationFilePermissions = async (handle) => {
  try {
    const orgPermissionsResponse = await get(`dataset/${handle}/organization-permissions`, true);
    if (orgPermissionsResponse.status === StatusCodes.OK) {
      const orgPermissionsJson = await orgPermissionsResponse.json();
      const orgPermissions = orgPermissionsJson?.permissions;
      if (Array.isArray(orgPermissions)) {
        return orgPermissions;
      } else {
        console.error('Unexpected response from organization file permissions endpoint');
        return [];
      }
    } else {
      console.warn(
        `Failed to get organization file permissions. Status code: ${orgPermissionsResponse.status}`
      );
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};

// Get user file permissions for provided handle
export const GetUserFilePermissions = async (user, handle, includeInherited = false) => {
  try {
    const userFilePermissionsResponse = await get(
      `dataset/${handle}/user-file-permissions?includeInherited=${includeInherited}`,
      true
    );
    if (userFilePermissionsResponse.status === StatusCodes.OK) {
      const userFilePermissions = (await userFilePermissionsResponse.json()).filter(
        (permission) => permission.user_id === user.email
      );
      if (Array.isArray(userFilePermissions)) {
        return userFilePermissions;
      } else {
        console.error('Unexpected response from user file permissions endpoint');
        return [];
      }
    } else {
      console.warn(
        `Failed to get user file permissions. Status code: ${userFilePermissionsResponse.status}`
      );
      return [];
    }
  } catch (e) {
    console.error(e);
    return [];
  }
};
