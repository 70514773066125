export const getFilterType = (fieldType) => {
  switch (fieldType) {
    case 'Int64':
    case 'UInt64':
      return 'number';
    case 'Float64':
      return 'number';
    case 'metadata_tag':
      return 'number';
    case 'DateTime':
    case 'DateTime64':
      return 'date';
    case 'Boolean':
      return 'boolean';
    default:
      return 'text';
  }
};
