import React from 'react';

import {
  Tooltip as TooltipBase,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from '@/Components/UI/Shadcn/Tooltip';
import clsx from 'clsx';

interface TooltipProps {
  text?: string;
  children: React.ReactElement;
  side?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
  open?: boolean;
  asChild?: boolean;
}

const Tooltip = ({ text, children, side = 'top', className, open, asChild }: TooltipProps) => {
  return (
    <TooltipProvider delayDuration={0}>
      <TooltipBase open={open}>
        <TooltipTrigger onClick={(event) => event.preventDefault()} asChild={asChild}>
          {children}
        </TooltipTrigger>
        {text && (
          <TooltipContent
            side={side}
            className={clsx('uppercase', className)}
            onPointerDownOutside={(event) => {
              event.preventDefault();
            }}
          >
            {<div>{text}</div>}
            <TooltipArrow width={11} height={5} />
          </TooltipContent>
        )}
      </TooltipBase>
    </TooltipProvider>
  );
};

export default Tooltip;
