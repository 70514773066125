import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { datasetSliceName } from '@/redux/constants';
import {
  selectFile,
  unSelectFileById,
  updateCombineFolderStructure,
} from '@/redux/reducers/datasetSlice';

import getColumnsCount from '@/Utils/getColumnsCount';
import { parseRowCount } from '@/Utils/DatasetTree/parseRowCount';
import { FILE_STATUS_ERROR, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';
import { selectNestedFilesFiltered, unselectNestedFiles } from '@/Utils/selectionOfNestedFiles';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';

const FileToCombine = ({
  file,
  openedDirectories,
  setOpenedDirectories,
  marginForNestedFiles = 0,
  nestedFileNameMaxWidth = 300,
  isExpandAllButtonClicked = false,
  isCollapseAllButtonClicked = false,
  setIsExpandAllButtonClicked = () => {},
  setIsCollapseAllButtonClicked = () => {},
}) => {
  const { selectedFiles } = useSelector((state) => state[datasetSliceName]);
  const dispatch = useDispatch();

  const { FileUuid, FileName, FileRows, IsDirectory, Children } = file;

  const rowCount = FileRows === '-1' ? '' : parseRowCount(FileRows);
  const colsCount = getColumnsCount(file);

  const getClassNameForIcons = (IsDirectory, FileUuid) => {
    if (IsDirectory && !openedDirectories.includes(FileUuid)) return 'directory';
    if (IsDirectory && openedDirectories.includes(FileUuid)) return 'directory-opened';
    return 'file';
  };

  const onFileClick = (IsDirectory, FileUuid) => {
    if (!IsDirectory) return;
    if (!openedDirectories.includes(FileUuid)) {
      dispatch(updateCombineFolderStructure(FileUuid));
      setOpenedDirectories([...openedDirectories, FileUuid]);
    } else {
      setOpenedDirectories(openedDirectories.filter((id) => id !== FileUuid));
    }
  };

  const onFilesSelect = (file) => {
    if (file.IsDirectory) {
      if (selectedFiles.some(({ FileUuid }) => file.FileUuid === FileUuid)) {
        unselectNestedFiles(file);
      } else {
        selectNestedFilesFiltered(file);
      }
    } else {
      if (selectedFiles.some(({ FileUuid }) => file.FileUuid === FileUuid)) {
        dispatch(unSelectFileById(file.FileUuid));
      } else {
        dispatch(selectFile(file));
      }
    }
  };

  const handleExpandAllClick = () => {
    dispatch(updateCombineFolderStructure(FileUuid));
    setOpenedDirectories([...openedDirectories, FileUuid]);
    setIsExpandAllButtonClicked(false);
  };

  const handleCollapseAllClick = () => {
    setOpenedDirectories(openedDirectories.filter((id) => id !== FileUuid));
    setIsCollapseAllButtonClicked(false);
  };
  useEffect(() => {
    if (IsDirectory) {
      dispatch(updateCombineFolderStructure(FileUuid));
    }
  }, []);

  useEffect(() => {
    if (IsDirectory) {
      if (isExpandAllButtonClicked && openedDirectories.length) {
        handleExpandAllClick();
      }
      if (isCollapseAllButtonClicked) {
        handleCollapseAllClick();
      }
    }
  }, [isExpandAllButtonClicked, isCollapseAllButtonClicked, openedDirectories]);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return <>{str}</>;
    } else {
      return (
        <Tooltip text={FileName} side='right' className='w-96 !text-left uppercase'>
          {`${str.slice(0, num)}...`}
        </Tooltip>
      );
    }
  };

  return (
    <div data-cy={`lib-combine-container-${FileName}`} className='flex flex-col'>
      <div
        role='button'
        tabIndex={0}
        onKeyUp={() => {}}
        className='combine-datasets-row'
        data-cy={`lib-combine-open-folder-${FileName}`}
        onClick={() => onFileClick(IsDirectory, FileUuid)}
      >
        <div
          className={`combine-datasets-name ${getClassNameForIcons(IsDirectory, FileUuid)}`}
          style={{ marginLeft: marginForNestedFiles }}
        >
          <label
            data-cy={`lib-combine-row-checkbox-label-${FileName}`}
            className='combine-datasets-checkbox'
          >
            <input
              data-cy={`lib-combine-row-checkbox-${FileName}`}
              type='checkbox'
              checked={selectedFiles.some((file) => file.FileUuid === FileUuid)}
              onChange={() => onFilesSelect(file)}
            />
            <span className='custom-checkbox' />
          </label>
          <span
            data-cy={`lib-combine-fileName-${FileName}`}
            style={{ maxWidth: nestedFileNameMaxWidth }}
            className='!text-sm !text-ui-secondary'
          >
            {truncateString(FileName, 40)}
          </span>
        </div>
        {!file.IsDirectory && (
          <div className='combine-datasets-cols'>
            <span>{rowCount}</span>
            <span>{colsCount}</span>
          </div>
        )}
      </div>
      {openedDirectories.includes(FileUuid) &&
        Children &&
        Children.filter(
          ({ Status, Type }) => Status !== FILE_STATUS_ERROR && Type !== FILE_TYPE_EXPORTER
        ).map((file) => (
          <FileToCombine
            key={file.FileUuid}
            file={file}
            openedDirectories={openedDirectories}
            setOpenedDirectories={setOpenedDirectories}
            marginForNestedFiles={marginForNestedFiles + 31}
            nestedFileNameMaxWidth={nestedFileNameMaxWidth - 31}
          />
        ))}
    </div>
  );
};

export default FileToCombine;
