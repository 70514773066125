import { useDispatch } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { hideModal as closeModal } from '@/redux/reducers/modalsSlice';

import {
  LINK_HOW_TO_ZIP_A_FILE,
  MODAL_UPLOAD_FILE,
  MODAL_UPLOAD_FILE_WARNING,
} from '@/Utils/constants';

const ZipWarningPopup = ({
  hideModal,
  show,
  continueUpload,
  fileSize,
  isUppyCanBeCloseAfterCheck,
}) => {
  const dispatch = useDispatch();

  const zipWarningActions = {
    save: () => {
      hideModal();
      continueUpload(true); // remove unzipped files over 500 mb from being uploaded
      if (isUppyCanBeCloseAfterCheck) {
        dispatch(closeModal({ name: MODAL_UPLOAD_FILE }));
      }
      window.open(LINK_HOW_TO_ZIP_A_FILE, '_blank', 'noopener,noreferrer');
    },
    cancel: () => {
      hideModal();
      continueUpload(false); // continue uploading all files
    },
  };

  return (
    <Modal
      size='small'
      isOpen={show}
      title='Increase Upload Speed'
      onClose={zipWarningActions.cancel}
    >
      <div>
        <p>
          You are trying to upload a<span className='font-semibold'>{` ${fileSize} `}</span>
          unzipped file. This might take a while. Zip it first to speed up the upload by 10x.
        </p>
      </div>
      <div className='flex flex-row justify-between p-4 mt-6 text-right border-t border-ui-200'>
        <Button color='oceanBlue' dataCy='cancel-btn' onClick={zipWarningActions.cancel}>
          Continue without Zipping
        </Button>
        <Button color='shadow' dataCy='cancel-btn' onClick={zipWarningActions.save}>
          Exit to Zip File
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_UPLOAD_FILE_WARNING })(ZipWarningPopup);
