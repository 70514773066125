import { useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { ROW_NUMBER_COLUMN_NAME } from '@/Utils/gridConstants';
import {
  setIsCustomColumnHeaderContextMenuOpen,
  setCustomColumnHeaderContextMenuPosition,
  setCustomContextMenuColumn,
  setIsRenamePopoverOpen,
  setRenameColumnData,
} from '@/redux/reducers/spreadsheetSlice';

import Icon from '../UI/Icon/Icon';
import { useHover } from '../../hooks/useHoverHook';
import useOnClickOutside from '../../hooks/useClickOutside';
import { getTypeIconPath } from '../../Utils/getTypeIconPath';
import useSingleAndDoubleClick from '../../hooks/useSingleDoubleClick';
import { spreadsheetSliceName, userDataSliceName } from '../../redux/constants';

import '@/pages/SpreadsheetPage/index.scss';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const CustomColumnHeader = ({ api, column, displayName }) => {
  const {
    colDef,
    actualWidth,
    aggFunc,
    aggregationActive,
    autoHeaderHeight,
    colId,
    instanceId,
    lastLeftPinned,
    left,
    maxWidth,
    menuVisible,
    minWidth,
    moving,
    oldLeft,
    originalParent,
    parent,
    pinned,
    pivotActive,
    primary,
    rowGroupActive,
    sortIndex,
    tooltipEnabled,
    tooltipFieldContainsDots,
    visible,
  } = column;
  const {
    allowedAggFuncs,

    autoHeight,
    enablePivot,
    enableRowGroup,
    enableValue,
    field,
    fieldType,
    filter,
    headerName,
    lockPinned,
    resizable,
    sortable,
    suppressMenu,
    wrapText,
  } = colDef || {};
  const serializableColumn = {
    actualWidth,
    aggFunc,
    aggregationActive,
    autoHeaderHeight,
    colId,
    instanceId,
    lastLeftPinned,
    left,
    maxWidth,
    menuVisible,
    minWidth,
    moving,
    oldLeft,
    originalParent,
    parent,
    pinned,
    pivotActive,
    primary,
    rowGroupActive,
    sortIndex,
    tooltipEnabled,
    tooltipFieldContainsDots,
    visible,
    colDef: {
      allowedAggFuncs,
      colId,

      autoHeight,
      enablePivot,
      enableRowGroup,
      enableValue,
      field,
      fieldType,
      filter,
      headerName,
      lockPinned,
      resizable,
      sortable,
      suppressMenu,
      wrapText,
    },
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();
  const [clickedColumnHeaderId, setClickedColumnHeaderId] = useState(null);
  const {
    clientState: { sortModel },
    isCustomColumnHeaderContextMenuOpen,
    appliedFiltersMap,
  } = useSelector((state) => state[spreadsheetSliceName]);

  const refButton = useRef(null);
  const headerCell = useRef(null);

  const [hoverRef] = useHover();

  useOnClickOutside(headerCell, () => setClickedColumnHeaderId(false));

  const fieldId = column.colDef.field || api.columnModel.rowGroupColumns[0]?.colId;
  const isMobileExperienceActive = localStorage.getItem('isMobileExperience') === 'true';

  const sortDirection = useMemo(() => {
    const _sortModelIndex = sortModel.findIndex((_sortModel) => _sortModel.colId === fieldId);
    if (_sortModelIndex !== 0) return null;
    return sortModel[_sortModelIndex].sort;
  }, [sortModel, fieldId]);

  const onOpenColumnHeaderContextMenu = () => {
    if (!isCustomColumnHeaderContextMenuOpen) {
      const buttonRect = refButton.current.getBoundingClientRect();
      const additionalPadding = 37;
      dispatch(
        setCustomColumnHeaderContextMenuPosition({
          x: buttonRect.x - additionalPadding,
          y: buttonRect.height,
        })
      );
      dispatch(setIsCustomColumnHeaderContextMenuOpen(true));
      dispatch(setCustomContextMenuColumn(serializableColumn));
    } else {
      dispatch(setIsCustomColumnHeaderContextMenuOpen(false));
    }
  };

  const onSingleColumnHeaderClick = () => {
    onOpenColumnHeaderContextMenu();
    setClickedColumnHeaderId(null);
    setClickedColumnHeaderId(column.colId);
  };

  const hasHeaderCommentThreads = () => {
    const headerCommentThreads = currentFile?.HeaderCommentThreads;
    if (headerCommentThreads?.length && headerCommentThreads?.length > 0) {
      return headerCommentThreads.some((thread) => thread.column === column.colId);
    } else {
      return false;
    }
  };

  const onDoubleColumnHeaderClick = () => {
    if (
      !user?.is_anonymous &&
      currentFile?.WithinQuota &&
      column.colDef.headerName !== ROW_NUMBER_COLUMN_NAME
    ) {
      dispatch(setRenameColumnData({ colId: colId, columnName: headerName })),
        dispatch(setIsRenamePopoverOpen(true));
    }
  };

  const onColumnHeaderClick = useSingleAndDoubleClick(
    onSingleColumnHeaderClick,
    onDoubleColumnHeaderClick,
    175
  );

  const isColumnMenuActive =
    column.colDef.field === clickedColumnHeaderId && isCustomColumnHeaderContextMenuOpen;
  return (
    <Tooltip
      side='bottom'
      text={hoverRef.current?.offsetWidth < hoverRef.current?.scrollWidth ? displayName : ''}
      className='max-w-xs'
      asChild
    >
      <div
        role='button'
        tabIndex={0}
        onKeyDown={() => null}
        className={clsx(
          'ag-cell-label-container',
          isMobileExperienceActive && 'pointer-events-none md:pointer-events-auto',
          !isMobileExperienceActive && 'pointer-events-auto',
          hasHeaderCommentThreads() && 'custom-header-with-comment-threads'
        )}
        onClick={onColumnHeaderClick}
        ref={headerCell}
      >
        <div
          data-cy={`applied-filter-${headerName}`}
          className={clsx('ag-header-viewport', {
            'bg-[#ebebeb]': isColumnMenuActive && !appliedFiltersMap[fieldId],
            'bg-sunrise-200 applied-filter': appliedFiltersMap[fieldId],
            'bg-sunrise-300': isColumnMenuActive && appliedFiltersMap[fieldId],
          })}
        >
          <div className='sort-arrows'>
            {sortDirection === 'asc' && <Icon name='sort-ascending' size={16} color='#151515' />}
            {sortDirection === 'desc' && <Icon name='sort-descending' size={16} color='#151515' />}
          </div>
          <div
            className={clsx('ag-header-cell-text text-[10px] font-semibold', {
              'text-midnight-500': isColumnMenuActive,
            })}
            ref={hoverRef}
          >
            <Icon
              color={`${isColumnMenuActive ? '#120A6E' : '#85858'}`}
              name={getTypeIconPath(column.colDef.fieldType || 'Group')}
              size={18}
            />
            {displayName}
          </div>
        </div>
        <div
          ref={refButton}
          className={
            isCustomColumnHeaderContextMenuOpen
              ? 'ag-header-cell-menu-button active'
              : 'ag-header-cell-menu-button'
          }
        >
          <Icon name='list' size={12} color={`${isColumnMenuActive ? '#120A6E' : '#85858'}`} />
        </div>
      </div>
    </Tooltip>
  );
};

export default CustomColumnHeader;
