import clsx from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Icon from '../../../Components/UI/Icon/Icon';

const buttonSizes = {
  small: 'h-6 px-3 text-xs',
  medium: 'h-8 px-3 text-sm',
  large: 'h-10 px-3 text-base',
};

const buttonIconSizes = {
  small: 'h-6 w-6 text-xs',
  medium: 'h-8 w-8 text-sm',
  large: 'h-10 w-10 text-base',
};

const linkSizes = {
  micro: 'h-3 text-xs',
  small: 'h-3.5 text-xs',
  medium: 'h-4 text-sm',
};

const iconColors: Record<string, any> = {
  solid: {
    shadow: '#151515', // text-ui
    sunrise: '#151515', // text-ui
    oceanBlue: '#FFFFFF', // text-white
    violetWeb: '#151515', // text-ui
    capri: '#FFFFFF', // text-white
    midnight: '#FFFFFF', // text-white
    pear: '#151515', // text-ui
    carrot: '#151515',
  },
  outline: {
    shadow: '#474747', // text-ui-secondary
    sunrise: '#8c7500', // text-sunrise-900
    oceanBlue: '#463AD4', // text-ocean-blue-500
    violetWeb: '#9A00B1', // text-violet-web-900
    capri: '#0079B6', // text-capri-900
    midnight: '#120A6E', // text-midnight-500
    pear: '#6D741B', // text-pear-900
    carrot: '#B66E0C', // text-carrot-800
  },
  ghost: {
    shadow: '#474747', // text-ui-secondary
    sunrise: '#8c7500', // text-sunrise-900
    oceanBlue: '#292094', // text-ocean-blue-700
    violetWeb: '#9A00B1', // text-violet-web-900
    capri: '#0079B6', // text-capri-900
    midnight: '#120A6E', // text-midnight-500
    pear: '#6D741B', // text-pear-900
    carrot: '#B66E0C', // text-carrot-800
  },
};

const buttonVariants = {
  solid: {
    shadow: 'bg-shadow-200 text-ui hover:bg-shadow-300 active:bg-shadow-400 focus:bg-shadow-200',
    sunrise: 'bg-sunrise-500 text-ui hover:bg-sunrise-600 active:bg-sunrise-700',
    oceanBlue: 'bg-ocean-blue-500 text-white hover:bg-ocean-blue-600 active:bg-ocean-blue-700',
    violetWeb:
      'bg-violet-web-500 text-ui hover:bg-violet-web-600 active:text-white active:bg-violet-web-700',
    capri: 'bg-capri-500 text-white hover:bg-capri-600 active:bg-capri-700',
    midnight: 'bg-midnight-500 text-white hover:bg-midnight-600 active:bg-midnight-700',
    pear: 'bg-pear-500 text-ui hover:bg-pear-600 active:bg-pear-700',
    carrot: 'bg-carrot-500 text-ui hover:bg-carrot-700 active:bg-carrot-800 active:text-white',
  },
  outline: {
    shadow:
      'text-ui-secondary border border-shadow-600 hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-shadow-300 focus:bg-opacity-24 active:border-shadow-700 active:bg-shadow-200',
    sunrise:
      'text-sunrise-900 border border-sunrise-900 hover:bg-sunrise-100 hover:bg-opacity-24 focus:bg-opacity-24 active:bg-sunrise-100',
    oceanBlue:
      'text-ocean-blue-500 border border-ocean-blue-500 hover:bg-ocean-blue-100 hover:bg-opacity-24 focus:bg-opacity-24 active:bg-ocean-blue-100 ed:text-ocean-blue-700 active:text-ocean-blue-700',
    violetWeb:
      'text-violet-web-900 border border-violet-web-900 hover:bg-violet-web-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-violet-web-100',
    capri:
      'text-capri-900 border border-capri-900 hover:bg-capri-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-capri-100',
    midnight:
      'text-midnight-500 border border-midnight-500 hover:bg-midnight-100 hover:bg-opacity-16 focus:bg-opacity-24 active:bg-midnight-100',
    pear: 'text-pear-900 border border-pear-900 hover:bg-pear-100 hover:bg-opacity-24 focus:bg-opacity-24 active:bg-pear-100',
    carrot:
      'text-carrot-800 border border-carrot-800 hover:bg-carrot-100 hover:bg-opacity-24 focus:bg-opacity-24 active:bg-carrot-100',
  },
  ghost: {
    shadow:
      'text-ui-secondary hover:bg-shadow-300 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-shadow-200',
    sunrise:
      'text-sunrise-900 hover:bg-sunrise-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-sunrise-100',
    oceanBlue:
      'text-ocean-blue-700 hover:bg-ocean-blue-100 hover:bg-opacity-24 focus:bg-opacity-24 active:bg-ocean-blue-100 active:text-ocean-blue-900',
    violetWeb:
      'text-violet-web-900 hover:bg-violet-web-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-violet-web-100',
    capri:
      'text-capri-900 hover:bg-capri-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-capri-100',
    midnight:
      'text-midnight-500 hover:bg-midnight-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-midnight-100',
    pear: 'text-pear-900 hover:bg-pear-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-pear-100',
    carrot:
      'text-carrot-800 hover:bg-carrot-100 hover:bg-opacity-24 focus:bg-opacity-16 active:bg-carrot-100 active:text-carrot-900',
  },
};

const accessibilityColors =
  'focus-visible:ring focus-visible:ring-ocean-blue-200 focus:outline-none';

const disabledColors = 'disabled:opacity-40';

const linkTextColors = {
  shadow: 'text-ui-helper',
  sunrise: 'text-sunrise-900',
  oceanBlue: 'text-ocean-blue-500',
  violetWeb: 'text-violet-web-800',
  capri: 'text-capri-900',
  pear: 'text-pear-800',
  carrot: 'text-carrot-800',
  midnight: 'text-midnight-500',
};

const linkIconColors = {
  shadow: '#707070', //text-ui-helper
  sunrise: '#8c7500', //text-sunrise-900
  oceanBlue: '#463AD4', //text-ocean-blue-500
  violetWeb: '#C400E1', //text-violet-web-800
  capri: '#0079B6', //text-capri-900
  pear: '#889121', //text-pear-800
  carrot: '#B66E0C', //text-carrot-800
  midnight: '#120A6E', //text-midnight-500
};

interface Events {
  onClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'solid' | 'outline' | 'ghost';
  size?: 'small' | 'medium' | 'large';
  color?: 'shadow' | 'sunrise' | 'oceanBlue' | 'violetWeb' | 'capri' | 'midnight' | 'pear';
  dataCy?: string;
  dataTestId?: string;
  iconName?: string;
} & Events;

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  size?: 'micro' | 'small' | 'medium';
  to: string;
  color?:
    | 'shadow'
    | 'sunrise'
    | 'oceanBlue'
    | 'violetWeb'
    | 'capri'
    | 'midnight'
    | 'pear'
    | 'carrot';
  dataCy?: string;
  dataTestId?: string;
  iconName?: string;
} & Events;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'solid',
      color = 'shadow',
      size = 'medium',
      disabled = false,
      dataCy,
      dataTestId,
      onClick,
      onMouseDown = () => {},
      children,
      iconName,
      ...rest
    },
    ref
  ) => {
    const [iconSize, setIconSize] = useState(18);
    const [buttonIsFocused, setButtonIsFocused] = useState(false);
    const [buttonIsHovered, setButtonIsHovered] = useState(false);
    const [iconTextColor, setIconTextColor] = useState('#151515');

    useEffect(() => {
      switch (size) {
        case 'small':
          setIconSize(16);
          break;
        case 'medium':
          setIconSize(18);
          break;
        case 'large':
          setIconSize(20);
          break;
      }
    }, []);

    useEffect(() => {
      let iconColor = iconColors[variant][color];
      if (buttonIsFocused) {
        iconColor = 'currentColor';
      }
      setIconTextColor(iconColor);
    }, [buttonIsFocused, color, iconTextColor, variant]);

    useEffect(() => {
      let iconColor = iconColors[variant][color];
      if (buttonIsHovered) {
        iconColor = 'currentColor';
      }
      setIconTextColor(iconColor);
    }, [buttonIsHovered, color, iconTextColor, variant]);

    return (
      <button
        ref={ref}
        type={type}
        data-cy={dataCy}
        data-testid={dataTestId}
        disabled={disabled}
        className={clsx(
          'inline-flex items-center justify-center rounded',
          buttonVariants[variant][color],
          iconName && !children ? buttonIconSizes[size] : buttonSizes[size],
          accessibilityColors,
          disabledColors,
          className
        )}
        onClick={onClick}
        onFocus={() => setButtonIsFocused(true)}
        onBlur={() => setButtonIsFocused(false)}
        onMouseEnter={() => setButtonIsHovered(true)}
        onMouseLeave={() => setButtonIsHovered(false)}
        onMouseDown={onMouseDown}
        {...rest}
      >
        {iconName && (
          <span className='mb-[2px] pointer-events-none '>
            <Icon name={iconName} size={iconSize} color={iconTextColor} />
          </span>
        )}
        {children && <div className={clsx('truncate', iconName && 'pl-1')}>{children}</div>}
      </button>
    );
  }
);

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      id,
      to,
      onClick,
      title,
      target,
      className = '',
      color = 'capri',
      size = 'small',
      dataCy,
      dataTestId,
      children,
      iconName,
    },
    ref
  ) => {
    const [iconSize, setIconSize] = useState(14);

    useEffect(() => {
      switch (size) {
        case 'micro':
          setIconSize(12);
          break;
        case 'small':
          setIconSize(14);
          break;
        case 'medium':
          setIconSize(16);
          break;
      }
    }, [size]);

    if (to?.startsWith('http') || to?.startsWith('mailto')) {
      return (
        <a
          id={id}
          data-cy={dataCy}
          data-testid={dataTestId}
          ref={ref}
          href={to}
          onClick={onClick}
          className={clsx(
            'inline-flex space-x-1 items-center justify-start',
            linkTextColors[color],
            linkSizes[size],
            size === 'micro' ? 'tracking-wide font-extrabold' : '',
            className
          )}
          title={title}
          target={target}
        >
          {iconName && <Icon name={iconName} size={iconSize} color={linkIconColors[color]} />}
          {children && <span>{children}</span>}
        </a>
      );
    } else {
      return (
        <ReactRouterLink
          id={id}
          data-cy={dataCy}
          data-testid={dataTestId}
          ref={ref}
          to={to}
          onClick={onClick}
          className={clsx(
            'inline-flex space-x-1 items-center justify-start',
            linkTextColors[color],
            linkSizes[size],
            size === 'micro' ? 'tracking-wide font-extrabold' : '',
            className
          )}
          title={title}
          target={target}
        >
          {iconName && <Icon name={iconName} size={iconSize} color={linkIconColors[color]} />}
          {children && <span>{children}</span>}
        </ReactRouterLink>
      );
    }
  }
);
