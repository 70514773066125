import { useRef, useState } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { sidebarSvg } from '@/Assets/icons';
import SvgHelper from '@/Components/UI/SvgHelper';
import useOnClickOutside from '@/hooks/useClickOutside';
import { showModal } from '@/redux/reducers/modalsSlice';
import withUploadModal from '@/Components/Modals/withUploadModal';
import { datasetSliceName, modalsSliceName } from '@/redux/constants';
import { clearLibrarySearchResult } from '@/redux/reducers/datasetSlice';
import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import {
  MODAL_AUTOMATION_API,
  MODAL_INVITE_TEAM,
  URL_DATASETS,
  URL_SETTINGS,
  userRolesKey,
} from '@/Utils/constants';

import Icon from '../UI/Icon/Icon';
import footerMenu from './sideBarFooter';
import getSideBarMenu from './sideBarMenu';
import { Button } from '../UI/Button/Button';
import { addMixpanelEvent } from '../../redux/reducers/sharedSlice';

import './style/index.scss';
import { getSubscriptionDetails, setSettingsPage } from '@/redux/reducers/settingsSlice';

const LeftSidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const { modalsQueue } = useSelector((state) => state[modalsSliceName]);
  const { isPublicResource } = useSelector((state) => state[datasetSliceName]);
  const { filesSize, filesSizePercentage } = useSelector((state) => state.dataset);

  const [isLeftSidebarOpened, setIsLeftSidebarOpened] = useState(false);

  const sideBarRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const { hasBillingId, maxStorageSize } = user?.userProperties || {};
  const isFileListPage = window.location.pathname.includes('datasets');

  const openFreshChat = () => window.fcWidget.open();

  const getGenerateApiKeyLink = () => {
    return {
      href: '#',
      target: '_self',
      rel: 'nofollow',
      className: 'generate-api-key-btn',
      iconName: 'webhooks-logo',
      alt: 'Automation API',
      text: 'Automation API',
      dataCy: 'automation-modal-btn',
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(
          addMixpanelEvent({
            eventName: 'API key request (left sidebar)',
            eventProps: {},
            userIncrementName: '# of button click',
          })
        );
        dispatch(showModal({ name: MODAL_AUTOMATION_API }));
      },
    };
  };

  const menuItems = [...getSideBarMenu(isFileListPage, openFreshChat), getGenerateApiKeyLink()];

  useOnClickOutside(sideBarRef, () => {
    if (!isLeftSidebarOpened || modalsQueue.length > 0) return;
    setIsLeftSidebarOpened(false);
  });

  return (
    <div
      role='button'
      onKeyUp={() => {}}
      tabIndex='0'
      className={clsx('left-sidebar hidden md:flex', isLeftSidebarOpened && 'expanded')}
      ref={sideBarRef}
      onClick={() => setIsLeftSidebarOpened(true)}
    >
      {isLeftSidebarOpened && (
        <div
          role='button'
          onKeyUp={() => {}}
          tabIndex='0'
          className='left-sidebar__arrow'
          onClick={(event) => {
            setIsLeftSidebarOpened(false);
            event.stopPropagation();
          }}
        >
          <SvgHelper svgName='open_sidebar' />
        </div>
      )}
      <div className='left-sidebar__main'>
        <div
          role='button'
          onKeyUp={() => {}}
          tabIndex='0'
          data-cy='company-logo-as-btn'
          className='company-logo'
          onClick={() => setIsLeftSidebarOpened(!isLeftSidebarOpened)}
        >
          <div
            alt='logo'
            data-cy='company-logo-img'
            className={`company-logo-img ${
              !isLeftSidebarOpened ? 'left-sidebar-tooltip menu-btn' : ''
            }`}
          />
        </div>
        <div className='navigation-container'>
          <Link
            to={`/${URL_DATASETS}`}
            reloadDocument={isPublicResource}
            onClick={(event) => {
              if (isFileListPage) {
                setIsLeftSidebarOpened(!isLeftSidebarOpened);
              }
              dispatch(clearLibrarySearchResult());
              event.stopPropagation();
            }}
            className={`library-btn ${isFileListPage ? 'active' : ''}  ${
              !isLeftSidebarOpened ? 'left-sidebar-tooltip' : ''
            }`}
            data-cy='sidebar-library-btn'
          >
            <img src={sidebarSvg.ArchiveIcon} alt='archive' />
            <span className='ml-2'>Library</span>
          </Link>
          <Button
            variant='ghost'
            onClick={(e) => {
              e.stopPropagation();
              dispatch(showModal({ name: MODAL_INVITE_TEAM }));
            }}
          >
            <div className='flex'>
              <Icon name='users' color='#fff' />
              {isLeftSidebarOpened && <span className='ml-2 text-white'>Invite Team</span>}
            </div>
          </Button>
          {menuItems.map((element, index) => {
            if (!element) return null;
            return (
              <a
                key={index}
                href={element.href}
                target={element.target}
                rel={element.rel}
                onClick={(event) => element.onClick(event)}
                className={`${!isLeftSidebarOpened ? 'left-sidebar-tooltip' : ''} ${
                  element.className
                }`}
                data-cy={element.dataCy}
              >
                {element.iconSrc ? (
                  <img src={element.iconSrc} alt={element.alt} />
                ) : element.iconName ? (
                  <Icon name={element.iconName} />
                ) : null}
                <span className='ml-2'>{element.text}</span>
              </a>
            );
          })}
          {hasBillingId ? (
            <Link
              to={`/${URL_SETTINGS}`}
              onClick={(event) => {
                dispatch(getSubscriptionDetails());
                dispatch(setSettingsPage(2));
                event.stopPropagation();
              }}
              className={clsx('manage-billing-btn', !isLeftSidebarOpened && 'left-sidebar-tooltip')}
              data-cy='sidebar-manage-billing-btn'
            >
              <Icon name='gear' color='#fff' />
              <span className='ml-2'>Manage Billing</span>
            </Link>
          ) : null}
        </div>
        {isLeftSidebarOpened && (
          <>
            <div className='storage-usage'>
              <div>
                <img data-cy='sidebar-storage-img' src={sidebarSvg.CloudIcon} alt='cloud' />
                <div>
                  <span>Storage</span>
                  <span>{filesSizePercentage}%</span>
                </div>
              </div>
              <div className='fill-bar'>
                <div style={{ width: `${filesSizePercentage}%`, maxWidth: '100%' }} />
              </div>
              <p>
                <span data-cy='sidebar-files-size'>{filesSize}</span> of{' '}
                <span data-cy='sidebar-max-storage-size'>{maxStorageSize}</span>
              </p>
            </div>
            {user && !user[userRolesKey].includes('is-paying') && (
              <UpgradeButton
                color='pear'
                dataCy='sidebar-upgrade-btn'
                className='gap-1 uppercase'
              />
            )}
          </>
        )}
      </div>
      {isLeftSidebarOpened && (
        <div className='left-sidebar__footer'>
          <ul>
            {footerMenu.map((element, index) => {
              const { href, target, rel, onClick, text } = element;
              return (
                <li key={index}>
                  <a href={href} target={target} rel={rel} onClick={(event) => onClick(event)}>
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
          <p>© {currentYear} Gigasheet, Inc. Patented</p>
        </div>
      )}
    </div>
  );
};

export default withUploadModal(LeftSidebar);
