// Utility functions for parsing enrichment recipes

// These are the keys in the recipe that should be included as-is in the call to the backend.
// The key name must match in the backend API call and the recipe json, and they must not be modified during the template filling step.
export const PASSTHROUGH_RECIPE_KEYS = ['bulk', 'response_type', 'retry_properties'];

// userFieldInputs are formatted as the fields part of the recipe object, a list of objects where each has templateValueIfSet with the user input.
// the user input in template_value_if_set is either a column like `****B****` or a string like `asdfasdf`.
// if template_value_if_set is empty string, that means the user did not set a value for that field
export function fillRecipeWithValues(unfilledRecipe, userFieldInputs) {
  const fieldValues = getFieldValuesMap(unfilledRecipe, userFieldInputs);
  const recipe = replaceRecipeTemplateByCorrespondingFieldValue(unfilledRecipe, fieldValues);
  const out = {
    method: recipe.method,
    url_template: recipe.url_template,
    headers_template: recipe.headers_template,
    body_template: recipe.body_template,
    output_path_map: recipe.output_path_map,
    id: recipe.id, // not changed by the template step but has a different key in the backend API
  };
  // add all passthrough keys
  for (const f of PASSTHROUGH_RECIPE_KEYS) {
    if (recipe[f]) {
      out[f] = recipe[f];
    }
  }
  return out;
}

// returns an object where the keys are field IDs and the value is what value the field should have
// takes into account user inputs as well as default values from the recipe
export function getFieldValuesMap(unfilledRecipe, userFieldInputs) {
  const userValues = userFieldInputs
    .filter((x) => x.template_value_if_set)
    .reduce((o, curr) => {
      o[curr.id] = curr.template_value_if_set;
      return o;
    }, {});
  const out = {};
  for (const field of unfilledRecipe.fields) {
    if (field.id in userValues) {
      out[field.id] = field.template_value_if_set.replace('****', userValues[field.id]);
    } else {
      out[field.id] = field.template_value_if_unset;
    }
  }
  return out;
}

export function replaceRecipeTemplateByCorrespondingFieldValue(unfilledRecipe, fieldValuesMap) {
  const templateRegex = /\*\*\*\*(\d+)\*\*\*\*/g;

  function getFieldValueById(id) {
    return fieldValuesMap[id];
  }

  function replaceTemplate(match, id) {
    return getFieldValueById(parseInt(id)) || '';
  }

  function replaceTemplatesRecursively(obj) {
    if (typeof obj === 'string') {
      return obj.replace(templateRegex, replaceTemplate);
    }

    if (Array.isArray(obj)) {
      return obj.map(replaceTemplatesRecursively);
    }

    if (typeof obj === 'object') {
      const replacedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          replacedObj[key] = replaceTemplatesRecursively(obj[key]);
        }
      }
      return replacedObj;
    }

    return obj;
  }

  return replaceTemplatesRecursively(unfilledRecipe);
}
