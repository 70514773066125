import { Button } from '@/Components/UI/Button/Button';
import ToggleSwitchInput from '@/Components/UI/Form/ToggleSwitchInput/ToggleSwitchInput';
import Icon from '@/Components/UI/Icon/Icon';
import PopoverV2 from '@/Components/UI/Popover/PopoverV2';
import { http_delete, get, patch } from '@/Utils/API';
import { URL_AI } from '@/Utils/constants';
import { useCallback, useEffect, useRef, useState } from 'react';

const SheetAssistantSettings = ({
  handle,
  resetAssistantResponse,
  isAnonymous,
}: {
  handle: string;
  resetAssistantResponse: () => void;
  isAnonymous: boolean;
}) => {
  const [preAnalysisIsChecked, setPreAnalysisIsChecked] = useState(false);
  const isInitialRender = useRef(true);

  const clearSheetAssistantHistory = useCallback(async () => {
    return await http_delete(`${URL_AI}/${handle}/logs`);
  }, [handle]);

  useEffect(() => {
    const fetchPreAnalysisStatus = async () => {
      if (isAnonymous) return;
      try {
        const response = await get('user/metadata');
        if (response.Success === false) {
          throw new Error(`GET error: ${response.Message}`);
        }
        const { feature_preanalysis } = response;
        setPreAnalysisIsChecked(feature_preanalysis === 'enabled');
      } catch (err) {
        console.error(`Failed to fetch feature_preanalysis: ${err}`);
      }
    };

    fetchPreAnalysisStatus();
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const togglePreAnalysis = async () => {
      let data;
      if (preAnalysisIsChecked) {
        data = { feature_preanalysis: 'enabled' };
      } else {
        data = { feature_preanalysis: 'disabled' };
      }
      await patch('user/metadata', data);
    };

    togglePreAnalysis();
  }, [preAnalysisIsChecked]);

  return (
    <PopoverV2
      disabled={isAnonymous}
      content={
        <div className='p-4'>
          <span className='typography-sm text-midnight-600'>Settings</span>
          <hr className='mb-4' />
          <ToggleSwitchInput
            label='Automatically analyze data?'
            value={preAnalysisIsChecked}
            onChange={setPreAnalysisIsChecked}
            size='small'
          />
          <hr className='my-4' />
          <Button
            onClick={() => {
              clearSheetAssistantHistory().then((response) => {
                if (response.Success === false) {
                  throw new Error(`DELETE error: ${response.Message}`);
                } else {
                  resetAssistantResponse();
                }
              });
            }}
            className='w-full typography-sm'
          >
            Clear Chat History
          </Button>
        </div>
      }
    >
      <Button variant='ghost'>
        <Icon name='gear' size={16} />
      </Button>
    </PopoverV2>
  );
};

export default SheetAssistantSettings;
