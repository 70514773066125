import { fillRecipeWithValues, PASSTHROUGH_RECIPE_KEYS } from './EnrichmentRecipeParser';
import { spreadsheetSliceName } from '../../redux/constants';
import { store } from '../../redux/store';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import md5 from 'md5';

export const getBodyForRequest = (unfilledRecipe, userFieldValues, isPreview = true) => {
  const recipe = fillRecipeWithValues(unfilledRecipe, userFieldValues);
  const {
    clientState: { sortModel, filterModel },
  } = store.getState()[spreadsheetSliceName];

  const body = {
    method: recipe.method,
    url_template: recipe.url_template,
    headers: recipe.headers_template, // the name mismatch is correct, backend API expects headers but recipes use headers_template
    body_template: recipe.body_template,
    filter_model: filterModel || {},
    sort_model: sortModel,
    recipe_id: recipe.id, // the name mismatch is correct, backend API expects recipe_id but recipes use id
  };
  if (!isPreview) {
    body.output_path_map = recipe.output_path_map;
  }
  for (const f of PASSTHROUGH_RECIPE_KEYS) {
    if (recipe[f]) {
      body[f] = recipe[f];
    }
  }
  return body;
};

export const truncateText = (text, cutCount = 70) => {
  if (!text) return;
  if (text.length > cutCount) {
    return text.slice(0, cutCount - 3) + '...';
  }
  return text;
};

export const getPersistedValuesKey = (enrichmentTemplateId, fieldId, keyId) => {
  const fileUuid = getFileUuidFromPath();
  return md5(`${fileUuid}_${enrichmentTemplateId}_${fieldId}_${keyId}`);
};
