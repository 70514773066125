import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { Button } from '../../Button/Button';
import Tooltip from '../../Tooltip/Tooltip';

type Props = {
  label: string;
  name: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  placeholder?: string;
  helperText?: string;
  color?: 'shadow' | 'oceanBlue' | 'violetWeb' | 'pear';
  size?: 'small' | 'large';
  disabled?: boolean;
  showKeyDisabled?: boolean;
  dataCy?: string;
  className?: string;
  inputWrapperClassName?: string;
  labelClassName?: string;
};

const colors = {
  shadow: 'border-shadow-200 text-secondary !shadow-none placeholder-helper font-normal',
  oceanBlue: 'border-ocean-blue-500 shadow-ocean-blue-100 text-ui placeholder-ui font-normal',
  violetWeb: 'border-violet-web-500 shadow-violet-web-100 text-ui placeholder-ui font-normal',
  pear: 'border-pear-500 shadow-pear-100 text-ui placeholder-ui font-normal',
};

const accessibilityColors = 'focus:outline-none';

const PasswordInput = ({
  label,
  value,
  onChange,
  onFocus,
  name,
  placeholder = 'Not Set',
  helperText,
  color = 'shadow',
  size = 'large',
  disabled = false,
  showKeyDisabled = false,
  dataCy,
  className,
  inputWrapperClassName,
  labelClassName,
}: Props) => {
  const [showText, setShowText] = useState<boolean>(false);

  return (
    <div className='relative'>
      <InputField
        label={label}
        helperText={helperText}
        helperTextPosition='bottom'
        color={color}
        disabled={disabled}
        labelClassName={labelClassName}
      >
        <div className={clsx('flex', inputWrapperClassName)}>
          <input
            type={showText ? 'text' : 'password'}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            data-cy={dataCy}
            className={clsx(
              'relative border flex items-center bg-white pr-6 pl-3 rounded disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200',
              size === 'large' ? 'shadow-spread h-8 text-sm' : 'h-6 text-[13px] leading-[18px]',
              accessibilityColors,
              colors[disabled ? 'shadow' : color],
              className
            )}
          />
          {!showKeyDisabled ? (
            <Tooltip text={!showText ? 'show key' : 'hide key'} asChild>
              <Button
                iconName={!showText ? 'eye' : 'eye-slash'}
                onClick={() => setShowText(!showText)}
                className='flex  justify-center items-center  transform rotate-0 ml-[-6px] bg-gray-300 w-[32px] h-[32px]'
              />
            </Tooltip>
          ) : null}
        </div>
      </InputField>
    </div>
  );
};

export default PasswordInput;
