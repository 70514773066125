import { useEffect, useState } from 'react';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import { useDispatch, useSelector } from 'react-redux/es/exports';

import {
  _isCanExportFilteredGroupedRolePaywall,
  createGridRequestData,
  getFileSize,
  getTeamNameLabel,
  onCopyExportUrl,
  onCopyFile,
  onDeleteFile,
  onDownloadFile,
  onEditFile,
  onEditFolder,
  onExport,
  onMoveFile,
  onOpenFile,
  onOpenFolder,
  onRemoveAccess,
  onShareFile,
  onSheetHistory,
} from '@/helpers/datasetsHelper';

import { Mixpanel } from '@/Mixpanel';

import { datasetSliceName, spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import {
  addFilesToLibraryFiles,
  addFilesToTeamLibrary,
  clearLibrarySearchResult,
  selectFile,
  setCurrentDirectory,
  setCurrentFile,
  setIsLoadingLibrary,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';

import { get, post, put } from '@/Utils/API';
import { getClientState } from '@/Utils/ClientStateCommon';
import { parseRowCount } from '@/Utils/DatasetTree/parseRowCount';
import { noteEndpoint } from '@/Utils/apiEndpoints';
import {
  COPY,
  FILE,
  MODAL_AUDIT_LOG,
  MODAL_BUSINESS_PAYWALL,
  MODAL_DELETE_FILE,
  MODAL_EDIT_FILE,
  MODAL_EXCEEDS_LIMITS,
  MODAL_EXPORT_DOWNLOAD,
  MODAL_EXPORT_SPREADSHEET,
  MODAL_MOVE_FILE,
  MODAL_PAPERCUT,
  MODAL_REMOVE_ACCESS,
  MODAL_SHARE_FILE,
  MODAL_SPLIT_SHEET,
  MODAL_UPGRADE_ACCOUNT,
  MODAL_UPGRADE_REQUIRED,
  MODAL_WARNING_MESSAGE,
  MY_LIBRARY,
  OPT_OUT,
  PERMISSION_WRITE,
  ROUTE_DATASETS,
  SHARE,
  SHARED_WITH_ME,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  TOAST_TYPE_SUCCESS,
  URL_DATASET,
  upgradeAccountTypes,
  userTeamNameKey,
} from '@/Utils/constants';
import {
  FILE_STATUS_ERROR,
  FILE_STATUS_PROCESSED,
  FILE_STATUS_PROCESSING,
  FILE_TYPE_EXPORTER,
  FILE_TYPE_EXTERNAL,
  FILE_TYPE_OMNIVORE,
} from '@/Utils/fileConstants';
import { getFileTypeByName } from '@/Utils/getFileTypeByName';
import rowClassNameGenerator from '@/Utils/rowClassNames';
import {
  checkFileExceedsQuota,
  formatUrlSafeFileName,
  openInNewTab,
  openSpreadsheet,
} from '@/Utils/utils';

import showToast from '@/Components/Toast/showToastTemplate';
import Icon from '@/Components/UI/Icon/Icon';

import { convertToBytes } from '@/Utils/convertToBytes';
import { exportAllRows } from '@/helpers/exportHelper';
import FileActions from '@/pages/DatasetPage/FileActions/FileActions';
import FileNameCell from '@/pages/DatasetPage/FileNameCell';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { prepareClientState } from '@/redux/reducers/spreadsheetSlice';
import { useNavigate } from 'react-router-dom';
import getColumnsCount from '@/Utils/getColumnsCount';
import { format } from 'date-fns';
import { useWebSocketContext } from './WebSocketLibrary/WebSocketContext';
import { getWebSocketCategory } from './WebSocketLibrary/WebSocketContainer';

const OverLimit = ({
  currentFileSize,
  maxStorageSize,
  maxFileSize,
  currentFileRows,
  maxFileRows,
  isOverQuota,
  filesSize,
}) => {
  const isFileSizeOverQuota =
    convertToBytes(currentFileSize) >= convertToBytes(maxFileSize) &&
    convertToBytes(filesSize) < convertToBytes(maxStorageSize);
  const isFileRowOverQuota = currentFileRows >= maxFileRows;
  const reasonOfOverQuota = () => {
    if (isOverQuota) {
      return isFileSizeOverQuota ? 'max file size limits' : 'storage limit';
    }
    if (isFileRowOverQuota) {
      return 'ROW LIMIT';
    }
  };
  const amountOfQuotaOverrun = () => {
    if (isOverQuota) {
      return isFileSizeOverQuota ? maxFileSize : maxStorageSize;
    }
    if (isFileRowOverQuota) {
      return `${parseRowCount(Number(maxFileRows))} rows`;
    }
  };
  const reasonValue =
    (isOverQuota && currentFileSize) ||
    (isFileRowOverQuota && `${parseRowCount(currentFileRows)} rows`);

  return (
    <Tooltip
      text={`This file is ${reasonValue} and exceeds the account ${reasonOfOverQuota()} of ${amountOfQuotaOverrun()}. Limited
    preview available.`}
      side='bottom'
      className='w-80 !text-left uppercase'
    >
      <div className='flex items-center rounded-2xl bg-carrot-100 h-6 p-2.5 whitespace-nowrap text-carrot-900 w-fit'>
        <Icon name='warning-circle' size='16' />
        <span className='ml-1'>Over Limit</span>
      </div>
    </Tooltip>
  );
};

const RowErrorIcon = ({ errorMessage }) => {
  return (
    <Tooltip text={errorMessage} side='bottom' className='w-80 !text-left uppercase'>
      <div className='inline-flex items-center h-6 p-1 ml-1 rounded-2xl bg-carrot-100 whitespace-nowrap text-carrot-900 w-fit'>
        <Icon name='warning-circle' size='16' />
      </div>
    </Tooltip>
  );
};

export const Row = ({
  row,
  selectedFilesData,
  onDragStart,
  onDrop,
  onCheckboxChange,
  isDraggingRow,
  isAnonymous,
  isSharedParentFolder,
  hideCheckbox,
}) => {
  const dispatch = useDispatch();
  const { sendMessage } = useWebSocketContext();
  const { user } = useSelector((state) => state[userDataSliceName]);
  const { canSplitSheet, canExportFilteredGrouped } = user?.userProperties || {};
  const { processingFiles, sortingData, filesSize, currentFile, currentDataset, currentDir } =
    useSelector((state) => state[datasetSliceName]);

  const { isInsertingNewColumn, isDeletingRows, isDeletingColumns } = useSelector(
    (state) => state[spreadsheetSliceName]
  );

  const isSharedWithMeTabActive = currentDataset === SHARED_WITH_ME;
  const isSharedWithMyTeamTabActive = currentDataset === getTeamNameLabel(user[userTeamNameKey]);

  const [, setShouldDisableActions] = useState(false);

  const navigate = useNavigate();
  const { metadata } = row;
  const fileSize = getFileSize(metadata);
  const isFolder = metadata.IsDirectory;
  const isSharedByMe = metadata.IsSharedByMe;
  const isProcessingStatus = metadata.Status === FILE_STATUS_PROCESSING;
  const columns = isFolder ? '' : getColumnsCount(metadata);
  const rowCount = metadata.FileRows === '-1' ? '' : parseRowCount(metadata.FileRows);
  const isRowChecked = selectedFilesData.find((item) => item.FileUuid === metadata.FileUuid);
  const exceedsQuota = checkFileExceedsQuota(filesSize, fileSize, user);
  const isOverFileRowsLimit = metadata?.FileRows > user?.userProperties?.maxFileRows;
  const canUseAuditLog = user?.userProperties?.canUseAuditLog;

  const rowClass = rowClassNameGenerator(metadata, [], selectedFilesData, isDraggingRow);

  const toastText = <div className=''>Sheet saved! Find it in the Library</div>;

  useEffect(() => {
    setShouldDisableActions(isInsertingNewColumn || isDeletingRows || isDeletingColumns);
  }, [isInsertingNewColumn, isDeletingRows, isDeletingColumns]);

  // If this file is included in the processingFiles queue, and it has successfully processed, then refresh the dataset
  // This will trigger the file to open in a new tab
  useEffect(() => {
    if (
      processingFiles.find(({ FileUuid }) => metadata.FileUuid === FileUuid) &&
      metadata.Status === FILE_STATUS_PROCESSED
    ) {
      sendMessage({
        location: `${currentDir}`,
        category: getWebSocketCategory(currentDataset),
      });
    }
  }, [metadata.Status]);

  const onDeleteButtonClick = () => {
    if (currentFile?.isFileShared) {
      put(`${FILE}/${metadata.FileUuid}/${SHARE}/${OPT_OUT}`, null).then(() => {
        sendMessage({
          location: `${currentDir}`,
          category: getWebSocketCategory(currentDataset),
        });
      });
    } else {
      if (!isRowChecked) dispatch(selectFile(metadata));

      dispatch(
        showModal({
          name: MODAL_DELETE_FILE,
          props: {
            filesToDelete: isRowChecked ? selectedFilesData : [...selectedFilesData, metadata],
          },
        })
      );
    }
  };

  const shouldShowFileActions = () => {
    return (
      !isSharedWithMeTabActive ||
      (!isAnonymous && !(metadata?.IsDirectory && row?.permissions?.length === 0))
    );
  };

  const accessCanBeRemoved = (row) => {
    return !isAnonymous && row?.permissions?.length > 0;
  };

  const setPlaceholderFile = ({ FileUuid, FileName }, { Message }) => {
    // Similar to setting fake files in UppyUploaderMulti,
    // files will remain in list of allDisplayedFiles until fully processed and added to allFiles
    const filesFakeEntries = [
      {
        metadata: {
          FileName: FileName + ' COPY',
          FileColumns: 0,
          FileSize: FileUuid,
          FileRows: 0,
          ParentDirectory: sortingData.currentDirectory,
          LastUpdated: new Date().toISOString(),
          Status: 'loading',
          FileUuid: Message,
          Deleting: 0,
          DetailedStatus: '',
          FieldsTypes: [],
          Headers: [],
          Public: false,
          isNew: true,
        },
      },
    ];
    currentDataset !== SHARED_WITH_ME && currentDataset !== MY_LIBRARY
      ? dispatch(addFilesToTeamLibrary(filesFakeEntries))
      : dispatch(addFilesToLibraryFiles(filesFakeEntries));
  };

  const onCopyFileClick = async () => {
    if (exceedsQuota) {
      dispatch(
        showModal({
          name: MODAL_UPGRADE_ACCOUNT,
          props: { upgradeType: upgradeAccountTypes.maxStorageSize },
        })
      );
    } else {
      try {
        const row = await getClientState(metadata.FileUuid);

        const isCanExportFilteredGroupedRolePaywall = _isCanExportFilteredGroupedRolePaywall({
          row,
          canExportFilteredGrouped,
        });

        if (isCanExportFilteredGroupedRolePaywall) {
          return dispatch(showModal({ name: MODAL_WARNING_MESSAGE }));
        }

        showToast({
          type: TOAST_TYPE_INFO,
          text: 'Your file is being copied and will be available once it is complete.',
        });

        const bodyData = {
          state: prepareClientState(row?.ClientState),
          folderHandle: isSharedWithMeTabActive ? '' : row?.ParentDirectory,
        };
        const endpoint = `${FILE}/${metadata.FileUuid}/${COPY}`;
        const res = await post(endpoint, bodyData, true);

        if (res.Success) {
          setPlaceholderFile(metadata, res);
          showToast({
            type: TOAST_TYPE_SUCCESS,
            customBody: () => toastText,
          });
        } else {
          showToast({
            type: TOAST_TYPE_ERROR,
            text: `Unable to copy ${metadata.FileName}.`,
            errorContext: res,
            endpoint: endpoint,
            payload: bodyData,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const openExportFileModal = async () => {
    if (
      (typeof row?.within_quota === 'boolean' && !currentFile?.within_quota) ||
      isOverFileRowsLimit
    ) {
      return dispatch(
        showModal({
          name:
            metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }

    const { getGridRequestData, isCanExportFilteredGroupedRolePaywall } =
      await createGridRequestData({
        fileUuid: metadata?.FileUuid,
        canExportFilteredGrouped,
      });

    if (!isCanExportFilteredGroupedRolePaywall) {
      exportAllRows(getGridRequestData, metadata, false, dispatch);
    }

    dispatch(
      showModal({
        name: MODAL_EXPORT_SPREADSHEET,
        props: { isCanExportFilteredGroupedRolePaywall },
      })
    );
  };

  const openSplitToMultipleCVSsPopup = async () => {
    if (
      (typeof row?.within_quota === 'boolean' && !currentFile?.within_quota) ||
      isOverFileRowsLimit
    ) {
      return dispatch(
        showModal({
          name:
            metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }

    dispatch(
      showModal({
        name: canSplitSheet ? MODAL_SPLIT_SHEET : MODAL_PAPERCUT,
      })
    );

    !canSplitSheet &&
      dispatch(
        addMixpanelEvent({
          eventName: 'Split to Multiple CSVs paywall',
          eventProps: { FunctionName: 'Split to Multiple CSVs' },
          userIncrementName: '# of clicks',
        })
      );
  };

  const getPresignedUrl = async () => {
    const endpoint = `${URL_DATASET}/${metadata?.FileUuid}/download-export`;
    const data = await get(endpoint);
    if (data?.presignedUrl) {
      navigator.clipboard.writeText(data?.presignedUrl);
      showToast({ type: TOAST_TYPE_SUCCESS, text: 'Link copied to clipboard!' });
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: 'Unable to copy link. Please try again later.',
        errorContext: data,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const editFolderPopup = onEditFolder(() => dispatch(showModal({ name: MODAL_EDIT_FILE })));
  const editFileModal = onEditFile(() => dispatch(showModal({ name: MODAL_EDIT_FILE })));
  const openFile = onOpenFile(() =>
    openInNewTab(`spreadsheet/${formatUrlSafeFileName(metadata.FileName)}/${metadata.FileUuid}`)
  );
  const shareFileModal = onShareFile(() => dispatch(showModal({ name: MODAL_SHARE_FILE })));
  const copyFile = onCopyFile(onCopyFileClick);
  const deleteFileModal = onDeleteFile(onDeleteButtonClick, exceedsQuota);
  const onExportFile = onExport({
    openExportFileModal,
    openSplitToMultipleCVSsPopup,
    canSplitSheet,
  });
  const downloadModal = onDownloadFile(() => dispatch(showModal({ name: MODAL_EXPORT_DOWNLOAD })));
  const copyExportUrl = onCopyExportUrl(() => getPresignedUrl());
  const removeAccessModal = onRemoveAccess(() =>
    dispatch(showModal({ name: MODAL_REMOVE_ACCESS }))
  );
  const moveFile = onMoveFile(() => {
    dispatch(
      showModal({
        name: MODAL_MOVE_FILE,
        props: {
          file: currentFile,
          folderName: '',
          folderId: row.ParentDirectory || row.metadata.ParentDirectory,
        },
      })
    );
  });

  const sheetHistory = onSheetHistory(
    () => dispatch(showModal({ name: canUseAuditLog ? MODAL_AUDIT_LOG : MODAL_BUSINESS_PAYWALL })),
    canUseAuditLog
  );

  const openFolder = onOpenFolder(() => onFolderClick(metadata));

  const _setCurrentFile = async (file) => {
    const uuid = file.metadata.FileUuid;
    const note = await get(noteEndpoint(uuid));

    const newCurrentFile = {
      ...file,
      Note: note,
    };
    dispatch(setCurrentFile(newCurrentFile));
  };

  const onExportClick = (metadata) => {
    if (metadata.Status === FILE_STATUS_PROCESSED) {
      _setCurrentFile(row);
      dispatch(showModal({ name: MODAL_EXPORT_DOWNLOAD }));
    }
  };

  const onFolderClick = async (metadata) => {
    if (isAnonymous) {
      window.open(`${ROUTE_DATASETS}?folder=${metadata.FileUuid}`, '_self');
    } else {
      _setCurrentFile(row);
      dispatch(setIsLoadingLibrary(true));
      dispatch(clearLibrarySearchResult());
      dispatch(setCurrentDirectory(metadata.FileUuid));
      navigate(`/datasets/${metadata.FileUuid}`, { replace: true });
      dispatch(unSelectAllFiles());
      sendMessage({
        location: `${metadata.FileUuid}`,
        category: getWebSocketCategory(currentDataset),
      });
    }
  };

  const onFileClick = (e) => {
    e.stopPropagation();
    if (metadata.Type === FILE_TYPE_EXPORTER) {
      return onExportClick(metadata);
    } else {
      if (
        (metadata.Type === FILE_TYPE_OMNIVORE || metadata.Type === FILE_TYPE_EXTERNAL) &&
        metadata.Status === FILE_STATUS_PROCESSED
      ) {
        openSpreadsheet(metadata.FileUuid, metadata.FileName);
      }
    }
  };

  const onFileOrFolderClick = async (event, row) => {
    if (isFolder) return onFolderClick(row);

    return onFileClick(event);
  };

  const isOwnedByUser = (file) => {
    return file?.metadata?.Owner === user.email;
  };

  const isSharedDirectlyWithUser = (file) => {
    return file?.permissions?.length > 0;
  };
  const isWritePermission = (file) => {
    return file?.permissions?.includes(PERMISSION_WRITE) > 0;
  };

  const getDropdownItems = () => {
    if (isFolder) {
      if (!isOwnedByUser(currentFile)) {
        if (isSharedDirectlyWithUser(currentFile)) {
          return isWritePermission(currentFile)
            ? [openFolder, editFolderPopup, shareFileModal, sheetHistory, removeAccessModal]
            : [openFolder, editFolderPopup, sheetHistory, removeAccessModal];
        } else {
          return [];
        }
      } else {
        return [openFolder, editFolderPopup, shareFileModal, moveFile, deleteFileModal];
      }
    } else {
      if (metadata.Status !== FILE_STATUS_PROCESSED) {
        return [deleteFileModal];
      }
      if (metadata.Type === FILE_TYPE_EXPORTER) {
        return [downloadModal, copyExportUrl, deleteFileModal];
      } else {
        if (!isOwnedByUser(currentFile)) {
          if (isSharedDirectlyWithUser(currentFile)) {
            return isWritePermission(currentFile)
              ? [
                  openFile,
                  editFileModal,
                  shareFileModal,
                  sheetHistory,
                  removeAccessModal,
                  copyFile,
                  onExportFile,
                ]
              : [openFile, editFileModal, sheetHistory, removeAccessModal, copyFile, onExportFile];
          } else {
            return [openFile];
          }
        } else {
          if ((row.within_quota && !isOverFileRowsLimit) || isSharedWithMyTeamTabActive) {
            return [
              openFile,
              editFileModal,
              shareFileModal,
              sheetHistory,
              copyFile,
              moveFile,
              deleteFileModal,
              onExportFile,
            ];
          } else {
            return [openFile, editFileModal, shareFileModal, copyFile, moveFile, deleteFileModal];
          }
        }
      }
    }
  };

  const rowCheckbox = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isAnonymous && !hideCheckbox ? (
        <label
          data-cy={`${metadata.FileName}-checkbox`}
          role='presentation'
          onClick={(event) => event.stopPropagation()}
          className='checkbox-btn'
        >
          <input
            type='checkbox'
            name='checkbox'
            className='file-checkbox'
            id={metadata.FileUuid + 'checkBox'}
            disabled={isProcessingStatus || (isSharedWithMeTabActive && !accessCanBeRemoved(row))}
            checked={isRowChecked || ''}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => onCheckboxChange(event, metadata)}
          />
        </label>
      ) : null}
      <FileNameCell
        isFolder={isFolder}
        row={row}
        fileSize={fileSize}
        isSharedByMe={isSharedByMe}
        currentFile={row}
        isSharedParentFolder={isSharedParentFolder}
      />
    </div>
  );

  useEffect(() => {
    if (metadata.Status === FILE_STATUS_ERROR) {
      const { FileName, DetailedStatus, FileRows } = metadata;
      const fileType = getFileTypeByName(FileName);
      Mixpanel.track('File Error', {
        'File Type': fileType,
        'Error Message': DetailedStatus,
        'Row Count': FileRows,
      });
    }
  }, [metadata.Status]);

  return (
    <>
      <tr
        id={'tr_' + metadata.FileName.replaceAll('.csv', '')}
        data-cy={
          'tr_' + metadata.FileName + '_' + (fileSize === 'Loading' ? 'processing' : 'processed')
        }
        key={metadata.fileName}
        className={rowClass}
        tabIndex='0'
        role='link'
        onClick={(event) => onFileOrFolderClick(event, metadata)}
        draggable={metadata.Status !== FILE_STATUS_ERROR || fileSize !== 'Loading'}
        onDragStart={(e) => onDragStart(e, metadata)}
        onDrop={(e) => onDrop(e, metadata)}
      >
        <td>{rowCheckbox}</td>
        <td data-cy={`${metadata.FileName}-rowsCount`}>
          <div className='inline-flex items-center'>
            {isFolder || metadata.Type === FILE_TYPE_EXPORTER ? (
              ''
            ) : isOverFileRowsLimit &&
              metadata.Status === FILE_STATUS_PROCESSED &&
              !isSharedWithMeTabActive &&
              !isSharedWithMyTeamTabActive ? (
              <OverLimit
                maxStorageSize={user?.userProperties?.maxStorageSize}
                isOverQuota={false}
                maxFileRows={user?.userProperties?.maxFileRows}
                currentFileRows={metadata?.FileRows}
                filesSize={filesSize}
              />
            ) : (
              rowCount
            )}
          </div>
        </td>
        <td data-cy={`${metadata.FileName}-columnsCount`}>
          <div className='inline-flex items-center'>
            {metadata.Type === FILE_TYPE_EXPORTER ? '' : columns}
            {metadata.Status &&
              metadata.Status === 'processed' &&
              metadata.DetailedStatus &&
              metadata.DetailedStatus !== '' &&
              !Number.isInteger(parseInt(metadata.DetailedStatus, 10)) && (
                <RowErrorIcon errorMessage={metadata.DetailedStatus} />
              )}
          </div>
        </td>
        <td className='file-size-container whitespace-nowrap'>
          {!row.within_quota &&
          typeof row?.within_quota === 'boolean' &&
          metadata.Status !== FILE_STATUS_ERROR ? (
            <OverLimit
              currentFileSize={fileSize}
              maxStorageSize={user?.userProperties?.maxStorageSize}
              maxFileSize={user?.userProperties?.maxFileSize}
              isOverQuota={!row?.within_quota}
              filesSize={filesSize}
            />
          ) : (
            fileSize
          )}
        </td>
        <td>{format(new Date(metadata.LastUpdated), 'MMM d, yyyy')}</td>
        {!isAnonymous ? (
          <td data-testid={`${metadata.FileName}-owner`} data-cy='td-owner'>
            {metadata.Owner}
          </td>
        ) : null}
        {shouldShowFileActions() ? (
          <td>
            <div className='!max-h-10'>
              {isFolder ? (
                <FileActions
                  dataCy={`${metadata.FileName}-actions`}
                  onDropdownClick={(event) => {
                    event.stopPropagation();
                    _setCurrentFile(row);
                  }}
                  row={metadata}
                  className='actions-btn'
                  dropdownItems={getDropdownItems()}
                />
              ) : (
                <div>
                  <FileActions
                    dataCy={`${metadata.FileName}-actions`}
                    onDropdownClick={(event) => {
                      event.stopPropagation();
                      _setCurrentFile(row);
                    }}
                    row={metadata}
                    className='actions-btn'
                    dropdownItems={getDropdownItems()}
                  />
                </div>
              )}
            </div>
          </td>
        ) : null}
      </tr>
    </>
  );
};
