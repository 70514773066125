import './index.scss';

const SkeletonLoader = ({ header = true, loadingBars = 4 }) => {
  return (
    <div data-cy='skeleton-loader' className='skeleton'>
      {header && (
        <div className='loader header'>
          <div className='line' style={{ width: 292 }} />
          <div className='line' style={{ width: 101 }} />
        </div>
      )}
      <div className='loader'>
        {Array(loadingBars)
          .fill('')
          .map((_, i) => (
            <div className='line' key={i} />
          ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
