import { type KeyboardEvent, useRef, useState, type ChangeEvent } from 'react';
import clsx from 'clsx';

import { Button } from '../UI/Button/Button';
import AutocompleteWrapper, { AutocompleteItem } from '../AutocompleteWrapper/AutocompleteWrapper';

import useOnClickOutside from '@/hooks/useClickOutside';

type CustomMultiEmailInput = {
  autocompleteList: AutocompleteItem[];
  onInputChange?: (v: string[]) => void;
  isValidEmail: (v: string) => boolean;
  emailTags: string[];
  placeholder?: string;
};

const CustomMultiEmailInput = ({
  autocompleteList,
  emailTags,
  onInputChange = () => {},
  isValidEmail,
  placeholder = '',
}: CustomMultiEmailInput) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<HTMLInputElement>(null);

  const [inputEmailValue, setInputEmailValue] = useState('');
  const [isAutoComplete, setIsAutocomplete] = useState(false);

  useOnClickOutside(autocompleteRef, () => setIsAutocomplete(false));

  const handleInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputEmailValue(e.target.value);
  };

  const validateEmailAndSet = (value?: string) => {
    const _inputEmailValue = value || inputEmailValue;

    if (isValidEmail(_inputEmailValue)) {
      onInputChange([...emailTags, _inputEmailValue]);
      setInputEmailValue('');
      inputRef.current?.focus();
    } else {
      setInputEmailValue(_inputEmailValue);
    }
  };

  const deleteLastAddedEmail = () => {
    onInputChange(emailTags.slice(0, emailTags.length - 1));
  };

  const deleteEmail = (emailToDelete: string) => {
    const filteredArray = emailTags.filter((email) => email !== emailToDelete);
    onInputChange(filteredArray);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      validateEmailAndSet();
    }

    if (e.key === 'Backspace' && inputEmailValue === '') {
      deleteLastAddedEmail();
    }
  };

  return (
    <div className='relative'>
      <AutocompleteWrapper
        ref={autocompleteRef}
        autocompleteList={autocompleteList}
        inputValue={inputEmailValue}
        onAutocompleteValueClick={validateEmailAndSet}
        disabled={!isAutoComplete}
        showMatchAfter={0}
      >
        <div
          className={clsx(
            ' w-full border focus-within:border-ocean-blue-500 focus-within:ring-2 focus-within:ring-midnight-100 px-3 rounded'
          )}
        >
          {emailTags.map((email) => {
            return (
              <div
                key={email}
                className='flex items-center p-1 my-1 mr-2 rounded bg-opacity-40 w-fit bg-ocean-blue-100'
              >
                <span className='break-all'>{email}</span>
                <Button
                  iconName='x'
                  dataCy='delete-email'
                  onClick={() => deleteEmail(email)}
                  className='!rounded-full'
                  size='small'
                  variant='ghost'
                />
              </div>
            );
          })}
          <input
            data-cy='multi-emails-input'
            ref={inputRef}
            className='w-full h-8 my-1 outline-none placeholder:text-sm'
            value={inputEmailValue}
            onChange={handleInputValue}
            type='email'
            onKeyDown={handleKeyDown}
            onBlur={() => validateEmailAndSet()}
            onFocus={() => setIsAutocomplete(true)}
            placeholder={placeholder}
          />
        </div>
      </AutocompleteWrapper>
    </div>
  );
};

export default CustomMultiEmailInput;
