import zuora from './zuora.svg';
import abicart from './abicart.svg';
import activecampaign from './activecampaign.svg';
import adwords from './adwords.svg';
import AmazonADS from './Amazon_ADS.svg';
import AmazonS3Logo from './Amazon-S3-Logo.svg';
import amplitude from './amplitude.svg';
import appsflyer from './appsflyer.svg';
import asana from './asana.svg';
import auth0 from './auth0.svg';
import aws_redshift from './aws_redshift.svg';
import awscognito from './aws-cognito.svg';
import bexioaglogovector from './bexio-ag-logo-vector.svg';
import bigcommerce from './bigcommerce.svg';
import bigquery from './bigquery.svg';
import bing_ads from './bing_ads.svg';
import bizzabo from './bizzabo.svg';
import blobstorage from './blob-storage.svg';
import BusinessCentral_scalable from './BusinessCentral_scalable.svg';
import campaign_monitor from './campaign_monitor.svg';
import chargebee from './chargebee.svg';
import chargify from './chargify.svg';
import cin7bluestacked from './cin7-blue-stacked.svg';
import clickup from './clickup.svg';
import clockify from './clockify.svg';
import Cloud_Firestore from './Cloud_Firestore.svg';
import cloudstorage from './cloud-storage.svg';
import confluence from './confluence.svg';
import contentful from './contentful.svg';
import copper from './copper.svg';
import dearsystems from './dearsystems.svg';
import ecwid from './ecwid.svg';
import eventbrite from './eventbrite.svg';
import exact from './exact.svg';
import facebook_ads from './facebook_ads.svg';
import Firebase_Authentication from './Firebase_Authentication.svg';
import forecast from './forecast.svg';
import freshsalesvectorlogo from './freshsales-vector-logo.svg';
import Freshworksvectorlogo from './Freshworks-vector-logo.svg';
import databricksvectorlogo from './databricks-vector-logo.svg';
import Databrickslogo from './Databricks-logo.svg';
import front from './front.svg';
import fulfil from './fulfil.svg';
import fullstory from './fullstory.svg';
import gambiogmbhlogovector from './gambio-gmbh-logo-vector.svg';
import github from './github.svg';
import gitlab from './gitlab.svg';
import Google_Analytics from './Google_Analytics.svg';
import google_search_console from './google_search_console.svg';
import harvest from './harvest.svg';
import heap from './heap.svg';
import helpscout from './helpscout.svg';
import hubspot from './hubspot.svg';
import ibm_db2 from './ibm_db2.svg';
import intercom from './intercom.svg';
import invoiced from './invoiced.svg';
import iterable from './iterable.svg';
import jira from './jira.svg';
import judgeme from './judge-me.svg';
import klaviyo from './klaviyo.svg';
import kustomer from './kustomer.svg';
import LinkedIn_Logo from './LinkedIn_Logo.svg';
import listrak_logo_240x90_web from './listrak_logo_240x90_web.svg';
import Logo_Box from './Logo_Box.svg';
import Logo_Dropbox from './Logo_Dropbox.svg';
import Logo_GoogleDrive from './Logo_GoogleDrive.svg';
import Logo_OneDrive from './Logo_OneDrive.svg';
import looker from './looker.svg';
import magento from './magento.svg';
import mailchimp from './mailchimp.svg';
import mailerlite from './mailerlite.svg';
import mailgun from './mailgun.svg';
import mailjet from './mailjet.svg';
import Mailshake from './Mailshake.svg';
import make from './make.svg';
import marketo from './marketo.svg';
import mavenlink from './mavenlink.svg';
import MCCFullLogo_netoHQ from './MCC-Full-Logo_netoHQ.svg';
import Microsoft_Dynamics_Logo from './Microsoft_Dynamics_Logo.svg';
import mixpanel_WordmarkPurple from './mixpanel_Wordmark-Purple.svg';
import mondaycom from './mondaycom.svg';
import mongodb from './mongodb.svg';
import mysql from './mysql.svg';
import netsuite from './netsuite.svg';
import odoo from './odoo.svg';
import omnisend from './omnisend.svg';
import onfleet from './onfleet.svg';
import ordoro_logo_stacked from './ordoro_logo_stacked.svg';
import ordwaylabslogo from './ordway-labs-logo.svg';
import outreach from './outreach.svg';
import pagerduty from './pagerduty.svg';
import pardot from './pardot.svg';
import paypal from './paypal.svg';
import persistiq from './persistiq.svg';
import Pipedrive_Logo from './Pipedrive_Logo.svg';
import postgres from './postgres.svg';
import Power_BI_Logo from './Power_BI_Logo.svg';
import prestashop from './prestashop.svg';
import procore_logo from './procore_logo.svg';
import qblogostacked from './qb-logo-stacked.svg';
import quickbutik from './quickbutik.svg';
import recharge from './recharge.svg';
import Recurly_R_Logomark_Purple from './Recurly_R_Logomark_Purple.svg';
import redash from './redash.svg';
import redis from './redis.svg';
import reviewsioicon from './reviewsio-icon.svg';
import s3 from './s3.svg';
import saasopticsbecomingmaxio from './saasopticsbecoming-maxio.svg';
import sageIntacctlogo from './sageIntacct-logo.svg';
import salesforce from './salesforce.svg';
import sendgrid from './sendgrid.svg';
import sendinblue from './sendinblue.svg';
import shipbob from './shipbob.svg';
import shopify from './shopify.svg';
import shoplazza from './shoplazza.svg';
import shoptetLogo from './shoptetLogo.svg';
import skuvaultlogo from './skuvault-logo.svg';
import snapchat from './snapchat.svg';
import snowflake from './snowflake.svg';
import Square_Jewel_Black from './Square_Jewel_Black.svg';
import stampedlogovertical from './stamped-logo-vertical.svg';
import statflo from './statflo.svg';
import stripe from './stripe.svg';
import surveymonkey from './surveymonkey.svg';
import toggltrack_logotype_stacked_darkpurple from './toggl-track_logotype_stacked_dark-purple.svg';
import trello from './trello.svg';
import trustpilot from './trustpilot.svg';
import twitter from './twitter.svg';
import typeform from './typeform.svg';
import unleashed from './unleashed.svg';
import vilkas from './vilkas.svg';
import wannafind from './wannafind.svg';
import woocommerce from './woocommerce.svg';
import xero from './xero.svg';
import yotpo from './yotpo.svg';
import zapier from './zapier.svg';
import zendesk from './zendesk.svg';
import zoho from './zoho.svg';
import zoom from './zoom.svg';
import NoIconConnector from './no-icon-connector.svg';
import close from './close.svg';
import travefy from './travefy.svg';
import noResults from './noResults.svg';

export const connectorsSVG = {
  noResults,
  NoIconConnector,
  zuora,
  abicart,
  activecampaign,
  adwords,
  AmazonADS,
  AmazonS3Logo,
  amplitude,
  appsflyer,
  asana,
  auth0,
  aws_redshift,
  awscognito,
  bexioaglogovector,
  bigcommerce,
  bigquery,
  bing_ads,
  bizzabo,
  blobstorage,
  BusinessCentral_scalable,
  campaign_monitor,
  chargebee,
  chargify,
  cin7bluestacked,
  clickup,
  clockify,
  close,
  Cloud_Firestore,
  cloudstorage,
  confluence,
  contentful,
  copper,
  dearsystems,
  ecwid,
  eventbrite,
  exact,
  facebook_ads,
  Firebase_Authentication,
  forecast,
  freshsalesvectorlogo,
  Freshworksvectorlogo,
  front,
  fulfil,
  fullstory,
  gambiogmbhlogovector,
  github,
  gitlab,
  Google_Analytics,
  google_search_console,
  harvest,
  heap,
  helpscout,
  hubspot,
  ibm_db2,
  intercom,
  invoiced,
  iterable,
  jira,
  judgeme,
  klaviyo,
  kustomer,
  LinkedIn_Logo,
  listrak_logo_240x90_web,
  Logo_Box,
  Logo_Dropbox,
  Logo_GoogleDrive,
  Logo_OneDrive,
  looker,
  magento,
  mailchimp,
  mailerlite,
  mailgun,
  mailjet,
  Mailshake,
  make,
  marketo,
  mavenlink,
  MCCFullLogo_netoHQ,
  Microsoft_Dynamics_Logo,
  mixpanel_WordmarkPurple,
  mondaycom,
  mongodb,
  mysql,
  netsuite,
  odoo,
  omnisend,
  onfleet,
  ordoro_logo_stacked,
  ordwaylabslogo,
  outreach,
  pagerduty,
  pardot,
  paypal,
  persistiq,
  Pipedrive_Logo,
  postgres,
  Power_BI_Logo,
  prestashop,
  procore_logo,
  qblogostacked,
  quickbutik,
  recharge,
  Recurly_R_Logomark_Purple,
  redash,
  redis,
  reviewsioicon,
  s3,
  saasopticsbecomingmaxio,
  sageIntacctlogo,
  salesforce,
  sendgrid,
  sendinblue,
  shipbob,
  shopify,
  shoplazza,
  shoptetLogo,
  skuvaultlogo,
  snapchat,
  snowflake,
  Square_Jewel_Black,
  stampedlogovertical,
  statflo,
  stripe,
  surveymonkey,
  toggltrack_logotype_stacked_darkpurple,
  travefy,
  trello,
  trustpilot,
  twitter,
  typeform,
  unleashed,
  vilkas,
  wannafind,
  woocommerce,
  xero,
  yotpo,
  zapier,
  zendesk,
  zoho,
  zoom,
  databricksvectorlogo,
  Databrickslogo,
};
