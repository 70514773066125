import withModal from '@/Components/Modals/withModalHOC';
import { Button, Link } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import { LINK_TEAMS_SUPPORT, MODAL_CONFIRM_LEAVE_TEAM } from '@/Utils/constants';
import { removeUserFromTeam as _removeUserFromTeam } from '@/redux/reducers/settingsSlice';
import { useDispatch } from 'react-redux';

const ConfirmLeaveTeamModal = ({ show, hideModal }) => {
  const dispatch = useDispatch();

  const removeUserFromTeam = () => {
    dispatch(_removeUserFromTeam());
    hideModal();
  };

  return (
    <Modal size='x-small' withoutTitle={true} isOpen={show} onClose={hideModal}>
      <div className='flex flex-col items-center justify-between gap-6 text-center'>
        <div className='flex flex-col gap-2'>
          <div className='text-xl font-bold text-ocean-blue-900'>Are you sure?</div>
          <div className='px-2 text-sm'>
            Please{' '}
            <Link to={LINK_TEAMS_SUPPORT} target='_blank' size='medium'>
              contact support
            </Link>{' '}
            or your team admin to get reinstated.
          </div>
        </div>
        <div className='flex flex-row justify-end gap-4 mb-2'>
          <Button onClick={hideModal}>Cancel</Button>
          <Button onClick={removeUserFromTeam} color='oceanBlue'>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CONFIRM_LEAVE_TEAM })(ConfirmLeaveTeamModal);
