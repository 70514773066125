const getTopRangeRowsIndexAndRowCount = (cellRanges) => {
  const { topRowIndex, rowCount } = (cellRanges ?? []).reduce(
    (acc, el) => {
      const elStartRow = el.startRow.rowIndex;
      const elEndRow = el.endRow.rowIndex;
      const elTopRowIndex = Math.min(elStartRow, elEndRow);
      const elBottomRowIndex = Math.max(elStartRow, elEndRow);
      const elRowCount = elBottomRowIndex - elTopRowIndex + 1;
      return {
        topRowIndex: elTopRowIndex < acc.topRowIndex ? elTopRowIndex : acc.topRowIndex,
        rowCount: acc.rowCount + elRowCount,
      };
    },
    { topRowIndex: Infinity, rowCount: 0 }
  );
  return { topRowIndex, rowCount };
};

export default getTopRangeRowsIndexAndRowCount;
