import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Toast from '@/Components/Toast/Toast';
import { Button } from '@/Components/UI/Button/Button';

import { ContactSupportBody } from '@/helpers/upgradeHelper';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';

import { post } from '@/Utils/API';
import { MODAL_DATA_CONNECTORS_REQUESTED } from '@/Utils/constants';

const ContactSupportButton = ({ text = 'Contact Support', buttonColor = 'sunrise' }) => {
  const dispatch = useDispatch();

  const handleContactSupport = async () => {
    try {
      const requestSpaceResponse = await post('request-more-space');

      if (!requestSpaceResponse.Success) {
        toast(
          ({ closeToast }) => (
            <Toast closeToast={closeToast} type='error'>
              <ContactSupportBody closeToast={closeToast} />
            </Toast>
          ),
          { autoClose: false, closeButton: false, closeOnClick: false }
        );
      } else {
        dispatch(
          showModal({
            name: MODAL_DATA_CONNECTORS_REQUESTED,
            props: {
              body: 'A member of our support team will reach out shortly with more details and next steps.',
            },
          })
        );
      }
    } catch (e) {
      toast(
        ({ closeToast }) => (
          <Toast closeToast={closeToast} type='error'>
            <ContactSupportBody closeToast={closeToast} />
          </Toast>
        ),
        { autoClose: false, closeButton: false, closeOnClick: false }
      );
    } finally {
      hideAllModals();
    }
  };
  return (
    <Button color={buttonColor} onClick={handleContactSupport}>
      {text}
    </Button>
  );
};

export default ContactSupportButton;
