import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import {
  MODAL_CLEANUP_COMPANY_NAME,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
} from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';

import '../style/index.scss';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import Label from '@/Components/UI/Form/Label/Label';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const CleanupCompanyNameModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);

  const { options, setTargetColumn, targetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
    predicate: ({ type }) => type === 'String',
  });

  const closeCleanupCompanyNameModal = () => {
    hideModal();
    setTargetColumn(null);
  };

  const cleanupCompanyName = async () => {
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      hideModal();
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      const uuid = getFileUuidFromPath();

      await post(`clean-company-name/${uuid}/${targetColumn.value}`, {}, true);
      await dispatch(fetchOpenedFile(uuid));

      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Clean Company Name' },
          userIncrementName: '# of function use',
        })
      );
      setTargetColumn(null);
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeCleanupCompanyNameModal}
      titleSize='large'
      size='small'
      title='Cleanup Company Name'
      articleID='69000833750'
      iconName='hash'
    >
      <div className='cleanup-company-name-container'>
        <Label text='Choose column' />
        <div className='mb-4'>
          <SearchSelect
            options={options}
            onChange={(_targetColumn) => setTargetColumn(_targetColumn)}
            defaultValue={targetColumn}
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid' />
      <div className='flex items-center justify-end gap-4 px-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          dataCy='cancel-btn'
          onClick={closeCleanupCompanyNameModal}
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          dataCy='save-btn'
          onClick={cleanupCompanyName}
          disabled={!targetColumn}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_CLEANUP_COMPANY_NAME })(CleanupCompanyNameModal);
