import { useState, useRef } from 'react';
import Arrow from '@/Assets/icons/upward-arrow_icon.svg';

import useOnClickOutsideHook from '../../../hooks/useOnClickOutside';

import './style/index.scss';

const ErrorInfoBlock = ({ row }) => {
  const [isShownErrorHover, hoverErrorInfo] = useState(false);
  const [isShownErrorClick, clickErrorInfo] = useState(false);
  const refErrorBlock = useRef(null);

  const _clickErrorInfo = () => {
    clickErrorInfo(!isShownErrorClick);
  };
  useOnClickOutsideHook(isShownErrorClick, refErrorBlock, _clickErrorInfo);

  return (
    <div
      role='button'
      tabIndex={0}
      onKeyUp={() => {}}
      onMouseEnter={() => hoverErrorInfo(true)}
      onMouseLeave={() => hoverErrorInfo(false)}
      onClick={(event) => event.stopPropagation()}
      className='error-details'
    >
      <button onClick={_clickErrorInfo} className='error-details-status'>
        Error
        {(isShownErrorClick || isShownErrorHover) && (
          <img className='absolute top-[24px] left-[26px]' src={Arrow} alt='arrow' />
        )}
      </button>
      {(isShownErrorClick || isShownErrorHover) && (
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          className='error-details-block'
          ref={refErrorBlock}
          onClick={(e) => e.stopPropagation()}
        >
          <div className='error-details-block-text'>{row.DetailedStatus}</div>
        </div>
      )}
    </div>
  );
};

export default ErrorInfoBlock;
