import Modal from '@/Components/UI/Modal/Modal';
import { useEffect, useState } from 'react';
import withModal from '../withModalHOC';

import {
  LINK_DATA_TYPES_SUPPORT,
  MODAL_CURRENCY,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';
import RadioInput from '@/Components/UI/Form/RadioInput/RadioInput';
import { Button } from '@/Components/UI/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { spreadsheetSliceName } from '@/redux/constants';
import { http_delete, put } from '@/Utils/API';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import ComboBox from '@/Components/UI/Shadcn/ComboBox';
import { formatEmptyIndicator } from '../AuditLog/auditHelper';
import Label from '@/Components/UI/Form/Label/Label';

const options = [
  {
    value: 'yes',
    dataCy: 'currency-yes-option',
    label: 'Yes',
  },
  {
    value: 'no',
    dataCy: 'currency-no-option',
    label: 'No',
  },
];

const CurrencyFormat = ({ hideModal, show }) => {
  const [isCurrencyFormat, setIsCurrencyFormat] = useState('yes');
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumnsIds, setSelectedColumnsIds] = useState([]);

  const { columnDefs, isInsertingNewColumn: loading } = useSelector(
    (state) => state[spreadsheetSliceName]
  );

  const dispatch = useDispatch();

  const handleRadioInputClick = (e) => {
    setIsCurrencyFormat(e.target.value);
  };

  const handleApplyButtonClick = async () => {
    dispatch(setIsInsertingNewColumn(true));
    const fileUUID = getFileUuidFromPath();
    const endpoint = (id) => `dataset/${fileUUID}/column/${id}/currency`;
    let payload;
    try {
      if (isCurrencyFormat === 'yes') {
        payload = {
          currency_code: 'USD',
        };
        const response = await Promise.allSettled(
          selectedColumnsIds.map((id) => put(endpoint(id), payload))
        );

        response.forEach((el, i) => {
          if (el.status === 'rejected' || !el.value.Success) {
            ShowToast({
              type: TOAST_TYPE_ERROR,
              text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
              errorContext: el.reason || el.value.Message,
              endpoint: endpoint(selectedColumnsIds[i]),
              payload: payload,
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
            });
          }
        });
      } else {
        const response = await Promise.allSettled(
          selectedColumnsIds.map((id) => http_delete(`dataset/${fileUUID}/column/${id}/currency`))
        );

        response.forEach((el, i) => {
          if (el.status === 'rejected' || !el.value.Success) {
            ShowToast({
              type: TOAST_TYPE_ERROR,
              text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
              errorContext: el.reason || el.value.Message,
              endpoint: endpoint(selectedColumnsIds[i]),
              fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
            });
          }
        });
      }
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      dispatch(fetchOpenedFile(fileUUID));
      dispatch(setIsInsertingNewColumn(false));
      hideModal();
    }
  };

  const handleSelectChange = (v) => {
    const idsList = v.map(({ value }) => value);
    setSelectedColumnsIds(idsList);
  };

  const onQuestionMarkButtonClick = () => {
    window.open(LINK_DATA_TYPES_SUPPORT, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    const numberTypeList = columnDefs?.filter(
      ({ fieldType, colId }) =>
        colId !== 'A' &&
        (fieldType === 'UInt64' || fieldType === 'Int64' || fieldType === 'Float64')
    );
    const convertedList = numberTypeList.map((col) => ({
      label: col.headerName,
      value: col.colId,
      icon: 'hash-straight',
    }));
    setColumnsOptions(convertedList);
  }, []);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      size='small'
      titleSize='small'
      title='Currency'
      dataCy='currency-modal'
      iconName='currency-dollar-simple'
      subhead='Apply currency formatting to number fields'
      isQuestionMarkButton
      onQuestionMarkButtonClick={onQuestionMarkButtonClick}
    >
      <>
        <div className='flex-col'>
          <Label text={'Choose Column(s)'} className='mb-2' />
          <ComboBox
            options={columnsOptions}
            placeholder='Column'
            hidePlaceholderWhenSelected={true}
            onChange={handleSelectChange}
            emptyIndicator={formatEmptyIndicator()}
            label={'Choose Column(s)'}
            dataCy='currency-column-selector'
          />
        </div>
        <div className='mt-4'>
          <Label text={'Currency Format?'} className='mb-2' />
          <div className='text-[10px] font-extrabold pl-1 text-ui-helper'>
            {isCurrencyFormat === 'yes'
              ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(1234)
              : ''}
          </div>
          <RadioInput
            onChange={handleRadioInputClick}
            options={options}
            value={isCurrencyFormat}
            disabled={loading}
            className={'min-w-[150px] flex justify-start'}
          />
        </div>
        <div className='flex justify-end gap-4 mt-6'>
          <Button color='shadow' variant='ghost' onClick={hideModal} dataCy='cancel-button'>
            Cancel
          </Button>
          <Button
            dataCy='apply-button'
            color='oceanBlue'
            onClick={handleApplyButtonClick}
            disabled={!selectedColumnsIds.length || loading}
          >
            Apply
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default withModal({ name: MODAL_CURRENCY })(CurrencyFormat);
