import { useEffect, useRef, useState } from 'react';

import withUploadModal from '@/Components/Modals/withUploadModal';
import './index.scss';
import { Button } from '@/Components/UI/Button/Button';
import { postRaw } from '@/Utils/API';
import {
  clearLibrarySearchResult,
  setIsLoadingLibrary,
  setLibrarySearchResult,
  unSelectAllFiles,
} from '@/redux/reducers/datasetSlice';
import { useDispatch, useSelector } from 'react-redux';
import showToast from '@/Components/Toast/showToastTemplate';
import { ROUTE_IMPORTS, ROUTE_NEXUS, TOAST_TYPE_ERROR } from '@/Utils/constants';
import { datasetSliceName } from '@/redux/constants';
import Icon from '@/Components/UI/Icon/Icon';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';

const LibrarySearch = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { librarySearchResult } = useSelector((state) => state[datasetSliceName]);
  const dispatch = useDispatch();
  const searchLibraryInputRef = useRef(null);

  const isNexusPage = window.location.href.includes(ROUTE_NEXUS);
  const isImportsPage = window.location.href.includes(ROUTE_IMPORTS);

  const isSearchInputDisabled = isNexusPage || isImportsPage;

  useEffect(() => {
    if (searchLibraryInputRef.current) {
      searchLibraryInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      resetSearch();
    } else {
      const debounceTimerDelay = 500;
      const debounceTimer = setTimeout(() => {
        searchLibrary();
      }, debounceTimerDelay);
      return () => {
        clearTimeout(debounceTimer);
      };
    }
  }, [searchTerm]);

  useEffect(() => {
    if (!librarySearchResult) {
      searchLibraryInputRef.current.value = '';
    }
  }, [librarySearchResult]);

  useEffect(() => {
    if (librarySearchResult && !loading) {
      searchLibraryInputRef.current.focus();
    }
    if (!loading && searchTerm === '') {
      resetSearch();
    }
  }, [librarySearchResult, loading]);

  const searchLibrary = () => {
    setLoading(true);
    dispatch(setIsLoadingLibrary(true));
    dispatch(unSelectAllFiles());
    const endpoint = 'library/search';
    const payload = { searchTerm: `${searchTerm}` };
    postRaw(endpoint, payload).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setLoading(false);
          dispatch(setLibrarySearchResult(data));
          searchLibraryInputRef.current.focus();
        });
      } else {
        showToast({
          type: TOAST_TYPE_ERROR,
          text: 'Search failed unexpectedly. Try again later.',
          errorContext: response,
          endpoint: endpoint,
          payload: payload,
          fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
        });
        setLoading(false);
        dispatch(clearLibrarySearchResult());
        searchLibraryInputRef.current.focus();
      }
    });
  };

  const resetSearch = () => {
    dispatch(clearLibrarySearchResult());
    searchLibraryInputRef.current.focus();
    searchLibraryInputRef.current.select();
    searchLibraryInputRef.current.value = '';
    setSearchTerm('');
    dispatch(unSelectAllFiles());
  };

  return (
    <div className='relative flex items-center justify-between mb-4'>
      <div className='relative flex items-center w-full'>
        <Icon
          size={15}
          name='magnifying-glass'
          color='#120A6E'
          className='absolute ml-3 pointer-events-none'
        />
        <TextInput
          name='filename'
          data-cy='input-library-search'
          className='h-8 pl-10 pr-9 rounded !rounded-tr-none !rounded-br-none !text-sm focus:border-ocean-blue-500 focus:ring-0 shadow-[1px_1px_1px_rgba(133,133,133,0.1)] !w-full'
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Search'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && searchTerm.length !== 0) searchLibrary();
          }}
          maxLength='99'
          ref={searchLibraryInputRef}
          disabled={isSearchInputDisabled}
        />
        {searchTerm.length > 0 && (
          <Button
            dataCy='reset-search-btn'
            onClick={resetSearch}
            color='shadow'
            variant='ghost'
            className='absolute right-0 !rounded-none'
          >
            <Icon size={15} name='x' color='#120A6E' />
          </Button>
        )}
      </div>
    </div>
  );
};

export default withUploadModal(LibrarySearch);
