import { useDispatch, useSelector } from 'react-redux';
import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import { setIsLoadingLibrary } from '@/redux/reducers/datasetSlice';
import { Button } from '@/Components/UI/Button/Button';
import { showModal } from '@/redux/reducers/modalsSlice';
import withUploadModal from '@/Components/Modals/withUploadModal';
import ShowToast from '../Toast/showToastTemplate';

import {
  BLANK,
  BROWSE_UPLOAD,
  FILES,
  MODAL_CREATE_FOLDER,
  MODAL_UPGRADE_ACCOUNT,
  PERMISSION_WRITE,
  PERMISSIONS,
  SHARED_WITH_ME,
  TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
  TOAST_TYPE_ERROR,
  upgradeAccountTypes,
  URL_DATASET,
} from '@/Utils/constants';
import { openSpreadsheet } from '@/Utils/utils';
import { get, post } from '@/Utils/API';

import { convertToBytes } from '@/Utils/convertToBytes';
import { useEffect, useState } from 'react';

const NewFileMenu = ({ toggleMenu, openUploadModal, isButtonsDisabled = false }) => {
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);

  const { libraryFiles, currentDir, filesSize, sortingData, currentDataset, filePath } =
    useSelector((state) => state[datasetSliceName]);

  const isSharedWithMeTabActive = currentDataset === SHARED_WITH_ME;

  const { user } = useSelector((state) => state[userDataSliceName]);
  const { maxStorageSize } = user?.userProperties || {};

  useEffect(() => {
    const { currentDirectory } = sortingData;
    if (currentDirectory) {
      get(`${URL_DATASET}/${currentDirectory}/${PERMISSIONS}`).then(({ permissions }) => {
        setPermissions(permissions);
      });
    }
  }, [sortingData]);

  const isNewFolderButtonDisabled = () => {
    const isAtSharedWithMeRoot = isSharedWithMeTabActive && sortingData?.currentDirectory === '';
    const readOnly = isSharedWithMeTabActive && !permissions.includes(PERMISSION_WRITE);
    const tooDeepFolderStructure = filePath?.length >= 9;
    return tooDeepFolderStructure || isAtSharedWithMeRoot || readOnly || isButtonsDisabled;
  };

  const handleNewBlankSheet = async (event) => {
    if (convertToBytes(filesSize) >= convertToBytes(maxStorageSize)) {
      toggleMenu(event);
      return dispatch(
        showModal({
          name: MODAL_UPGRADE_ACCOUNT,
          props: { upgradeType: upgradeAccountTypes.maxStorageSize },
        })
      );
    }

    dispatch(setIsLoadingLibrary(true));
    const defaultName = 'Untitled Sheet';
    const newNumber =
      libraryFiles.reduce((acc, { metadata: { FileName } }) => {
        if (FileName.startsWith(defaultName)) {
          const number = parseInt(FileName.slice(defaultName.length).trim(), 10);
          return isNaN(number) ? acc : Math.max(acc, number);
        }
        return acc;
      }, 0) + 1;

    const filename = `${defaultName} ${newNumber}`;
    let endpoint = '';
    let payload = {};

    try {
      endpoint = `${FILES}/${BLANK}`;
      payload = {
        filename,
        folderHandle: currentDir,
        numCols: 10,
        numRows: 10,
      };
      const result = await post(endpoint, payload);
      if (result.Success) openSpreadsheet(result.Handle, filename);
      else throw new Error(result);
    } catch (error) {
      ShowToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_GENERAL_SPREADSHEET_ERROR,
        errorContext: error,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      dispatch(setIsLoadingLibrary(false));
      toggleMenu(event);
    }
  };

  return (
    <div
      className='absolute z-50 w-full mt-12 shadow-custom'
      style={{ boxShadow: '0px 4px 8px 0px rgba(133, 133, 133, 0.16)' }}
    >
      <ul className='list-none bg-white border border-gray-200 rounded-md p-0 m-0 w-[15rem]'>
        <MenuItem
          title='New Folder'
          dataCy='new-folder-btn'
          onClick={(event) => {
            toggleMenu(event);
            dispatch(
              showModal({
                name: MODAL_CREATE_FOLDER,
                props: { directory: sortingData.currentDirectory },
              })
            );
          }}
          disabled={isNewFolderButtonDisabled()}
          hasBorder
        />
        <MenuItem
          title='File Upload'
          dataCy='file-upload-btn'
          onClick={() => openUploadModal({ openUploadType: BROWSE_UPLOAD })}
        />
        <MenuItem title='Blank Sheet' dataCy='blank-sheet-btn' onClick={handleNewBlankSheet} />
      </ul>
    </div>
  );
};

const MenuItem = ({ title, onClick, dataCy, disabled = false, hasBorder = false }) => (
  <li className={`flex w-full ${hasBorder ? 'border-b border-gray-400' : ''}`}>
    <Button
      onClick={onClick}
      disabled={disabled}
      dataCy={dataCy}
      className='box-content p-[11px_16px] h-6 text-sm font-normal leading-7 text-black bg-transparent flex !justify-start w-full hover:text-decoration-none hover:bg-[#C8C3FA24]'
    >
      <div className='flex px-0 mx-0'>
        <span>{title}</span>
      </div>
    </Button>
  </li>
);

export default withUploadModal(NewFileMenu);
