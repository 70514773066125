import React, { forwardRef, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Button } from '@/Components/UI/Button/Button';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import AutocompleteWrapper from '@/Components/AutocompleteWrapper/AutocompleteWrapper';
import useOnClickOutside from '@/hooks/useClickOutside';

import './style/index.scss';

const CommentInput = forwardRef(
  (
    { placeholder, sendCreateCommentRequest, requestIsSending, autofillOptions = [] },
    textareaForwardedRef
  ) => {
    const [commentText, setCommentText] = useState('');
    const [showAutofill, setShowAutofill] = useState(false);
    const [taggedEmails, setTaggedEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');

    const autofillEmailList = autofillOptions.map((el) => el.value);

    const autofillRef = useRef();
    useOnClickOutside(autofillRef, () => setShowAutofill(false));

    useEffect(() => {
      focusTextArea();
    }, []);

    const getEmailFromText = (text) => {
      return autofillEmailList.filter((email) => text.includes(`@${email}`));
    };

    const onSubmit = () => {
      sendCreateCommentRequest(commentText, taggedEmails);
      setCommentText('');
      setTaggedEmails([]);
    };

    const insertAutofillEntry = (email) => {
      setCommentText((prev) => {
        const activeCaretIndex = textareaForwardedRef?.current?.selectionStart;
        const charsBeforeIndex = prev.slice(0, activeCaretIndex);
        const charsAfterIndex = prev.slice(activeCaretIndex);
        return charsBeforeIndex + email.replace(currentEmail, '') + charsAfterIndex;
      });
      setTaggedEmails((prev) => {
        prev.push(email);
        return prev;
      });
    };

    const isValidEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onSelectAutofillContact = (selectedOption) => {
      let email = selectedOption;
      if (selectedOption.value) {
        email = selectedOption.value;
      }
      if (isValidEmail(email)) {
        insertAutofillEntry(email);
      } else {
        setCommentText((prev) => {
          return `${prev.slice(0, -1)}`;
        });
      }
      setCurrentEmail('');
      setShowAutofill(false);
      focusTextArea();
    };

    const getCurrentEmail = (v) => {
      const regex = /(?:\s@|^@)([^@\s]+)$/;
      const match = regex.exec(v);

      if (showAutofill && match) {
        setCurrentEmail(match[1] || '');
      } else {
        setCurrentEmail('');
      }
    };

    const onCommentInputChange = (value) => {
      setCommentText(value.replace(/[\n\r]/g, ''));
      setTaggedEmails(getEmailFromText(value));
      const activeCaretIndex = textareaForwardedRef?.current?.selectionStart;
      const leftPartOfValue = value.slice(0, activeCaretIndex);
      const properCharacter = leftPartOfValue.length === 1 ? '@' : ' @';

      if (leftPartOfValue.endsWith(properCharacter)) {
        setShowAutofill(true);
      }
      if (leftPartOfValue.endsWith(' ') || !leftPartOfValue) {
        setShowAutofill(false);
        setCurrentEmail('');
      } else {
        getCurrentEmail(leftPartOfValue);
      }
    };

    const focusTextArea = () => {
      textareaForwardedRef.current?.focus();
    };

    return (
      <div>
        <AutocompleteWrapper
          ref={autofillRef}
          autocompleteList={autofillOptions}
          inputValue={currentEmail}
          onAutocompleteValueClick={onSelectAutofillContact}
          className='-bottom-18'
          disabled={!showAutofill}
        >
          <div className='pb-6'>
            <hr className='solid' />
            <Textarea
              placeholder={placeholder}
              className={clsx(
                'border-0 !text-ui-secondary w-full h-[38px] placeholder:italic resize-none pl-4 pr-9',
                '!h-[80px]'
              )}
              onChange={(e) => {
                onCommentInputChange(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !requestIsSending && commentText.length > 0) onSubmit();
              }}
              dataCy='comment-textarea'
              value={commentText}
              ref={textareaForwardedRef}
            />
          </div>
        </AutocompleteWrapper>

        <div className={clsx('absolute flex items-end right-1 bottom-1')}>
          <Button
            disabled={requestIsSending || commentText.length === 0}
            onClick={onSubmit}
            iconName='paper-plane-right'
            size='medium'
            color='oceanBlue'
            variant='outline'
            className='border-0'
            dataCy='comment-submit-btn'
          />
        </div>
      </div>
    );
  }
);

export default CommentInput;
