import clsx from 'clsx';
import { useRef, useState } from 'react';

import { Button } from '@/Components/UI/Button/Button';
import showToast from '@/Components/Toast/showToastTemplate';
import Icon from '@/Components/UI/Icon/Icon';

import useOnClickOutsideHook from '@/hooks/useOnClickOutside';

import { TOAST_TEXT_UNABLE_SAVE_FILTER_ERROR, TOAST_TYPE_ERROR } from '@/Utils/constants';

import {
  generateFilterHandle,
  getAllFilterTemplates,
  LOADING_FILTER_VALUE,
  saveFilterTemplate,
} from '@/Utils/saveFilter';
import { useDispatch, useSelector } from 'react-redux';
import { userDataSliceName } from '@/redux/constants';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import './style/index.scss';
import SelectSavedFilter from './SelectSavedFilter';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const FilterPanelSave = ({
  onFilterChange,
  filterModelCnf,
  savedFilters,
  setSavedFilters,
  isDisabledApplyButton,
  savedFilterHandle,
  setSavedFilterHandle,
  saveStatus,
  setSaveStatus,
  setCurrentAppliedFilter,
  currentAppliedFilter,
  togglePopup,
  openUpgradeModal,
}) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const fileName = currentFile?.metadata.FileName || '';

  const [tempFilterName, setTempFilterName] = useState('');
  const [tempHandle, setTempHandle] = useState('');

  const [requireName, warnRequireName] = useState(false);
  const [isNameTaken, setNameTaken] = useState(false);

  const [isDropdownOpen, toggleDropdown] = useState(false);
  const refActionsBlock = useRef();

  const [isSaveFieldOpen, toggleSaveField] = useState(false);
  const refSaveAsField = useRef();

  const {
    user: {
      userProperties: { maxSavedFilters },
      email,
    },
  } = useSelector((store) => store[userDataSliceName]);

  const exceededSavedFilters = maxSavedFilters <= savedFilters.filter((i) => !i.isShared)?.length;

  const _toggleDropdown = () => {
    if (isDropdownOpen) toggleDropdown(!isDropdownOpen);
    if (isSaveFieldOpen) toggleSaveField(!isSaveFieldOpen);

    if (!isSaveFieldOpen) {
      setNameTaken(false);
      warnRequireName(false);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    toggleDropdown(!isDropdownOpen);
    toggleSaveField(false);
  };

  useOnClickOutsideHook(isDropdownOpen, refActionsBlock, _toggleDropdown);
  useOnClickOutsideHook(isSaveFieldOpen, refSaveAsField, _toggleDropdown);

  const onOpen = async () => {
    setSavedFilters([LOADING_FILTER_VALUE]);

    const templates = await getAllFilterTemplates();

    const savedFilters = templates.map((f) => ({
      label: f.Name,
      value: f.Id,
      filterHandle: f.Id,
      isShared: f.Owner !== email,
    }));

    setSavedFilters(savedFilters || []);
  };

  const saveAsOptions = currentAppliedFilter?.filterHandle
    ? [
        { title: 'Save As', alt: 'saveAs' },
        { title: 'Save As Copy', alt: 'saveAsCopy' },
      ]
    : [{ title: 'Save As', alt: 'saveAs' }];

  const handleClickSave = async (e, saveType) => {
    if (exceededSavedFilters) {
      openUpgradeModal();
      dispatch(
        addMixpanelEvent({
          eventName: 'Save extra filter paywall',
          eventProps: { current_filename: fileName },
          userIncrementName: '# of clicks',
        })
      );
      return;
    }
    if (saveType === 'save' && currentAppliedFilter?.label) {
      setSaveStatus('Saving...');
      await saveFilterTemplate(savedFilterHandle, filterModelCnf, currentAppliedFilter.label);
      setTempFilterName('');
      setSaveStatus('Saved');
    } else {
      const response = await Promise.all([onOpen(), generateFilterHandle()]);
      setTempFilterName('');
      setTempHandle(response[1].Message);
      toggleSaveField(true);
    }

    if (saveType === 'saveAsCopy' && currentAppliedFilter?.label)
      setTempFilterName(`${currentAppliedFilter.label} COPY`);
    setTimeout(() => setSaveStatus('Save'), 2000);
  };

  const handleSave = async (handle, filterName) => {
    setSaveStatus('Saving...');
    _toggleDropdown();

    setTempFilterName(filterName);
    setNameTaken(false);
    const response = await saveFilterTemplate(handle, filterModelCnf, filterName);
    if (response) {
      setCurrentAppliedFilter({
        label: filterName,
        value: handle,
        filterHandle: handle,
      });
      setSavedFilterHandle(handle);
      setSaveStatus('Saved');
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UNABLE_SAVE_FILTER_ERROR,
        errorContext: response,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }

    setTimeout(() => setSaveStatus('Save'), 2000);
  };

  const handleOverwrite = () => {
    const matchingFilterHandle = savedFilters.filter((i) => i.label === tempFilterName)[0]
      .filterHandle;
    handleSave(matchingFilterHandle, tempFilterName);
  };

  const handleCancel = () => {
    setTempHandle('');
    setTempFilterName('');
    warnRequireName(false);
    setNameTaken(false);
    toggleSaveField(false);
  };

  const handleChange = (value) => {
    warnRequireName(false);
    setTempFilterName(value);
    setNameTaken(savedFilters.map((f) => f.label.toLowerCase()).includes(value.toLowerCase()));
  };

  const confirmFilterName = () =>
    tempFilterName && tempFilterName.trim().length && !requireName
      ? handleSave(tempHandle, tempFilterName)
      : warnRequireName(true);

  return (
    <div data-cy='save-filter' className='flex mt-2 save-filter-container'>
      <SelectSavedFilter
        onOpen={onOpen}
        onFilterChange={(value) => onFilterChange(value)}
        savedFilters={savedFilters}
        currentAppliedFilter={currentAppliedFilter}
        togglePopup={togglePopup}
        exceededSavedFilters={exceededSavedFilters}
      />

      <Button
        dataCy='save-filters-btn'
        color='shadow'
        disabled={isDisabledApplyButton}
        onClick={(e) => handleClickSave(e, 'save')}
        className={clsx(
          '!self-center',
          currentAppliedFilter?.filterHandle && '!rounded-l !rounded-r-none '
        )}
      >
        <span className='flex flex-row items-center justify-between'>
          <span className='mt-1'>{saveStatus}</span>
          {exceededSavedFilters && <Icon name='rocket-launch' color='#D9B500' className='ml-1' />}
        </span>
      </Button>

      {currentAppliedFilter?.filterHandle && (
        <Button
          color='shadow'
          disabled={isDisabledApplyButton}
          iconName='caret-down'
          onClick={handleClick}
          className='!border-l !border-l-ui-300 !rounded-r !rounded-l-none !px-2 !self-center'
        />
      )}

      {isDropdownOpen && !isSaveFieldOpen && (
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          ref={refActionsBlock}
          className='right-0 z-40 save-filter-dropdown top-9'
          onClick={(event) => event.stopPropagation()}
        >
          {saveAsOptions.map(({ title, alt }, index) => (
            <button
              key={index}
              className='text-sm save-as-btn'
              onClick={(e) => handleClickSave(e, alt)}
            >
              {title}
            </button>
          ))}
        </div>
      )}

      {isSaveFieldOpen && (
        <div
          ref={refSaveAsField}
          className='absolute right-0 z-30 flex flex-col p-3 save-as-filter-field top-9 w-[307px]'
          data-cy='saved-filter-label'
        >
          <TextInput
            onKeyDown={(e) => {
              if (e.key === 'Enter') confirmFilterName();
            }}
            label='Filter Name:'
            value={tempFilterName}
            placeholder='Save As'
            onChange={(e) => handleChange(e.target.value)}
            color={isNameTaken || requireName ? 'violetWeb' : 'shadow'}
            className='w-full'
            dataCy='saved-filters-input'
          />

          <Button
            dataCy='saved-filters-cancel-btn'
            size='small'
            variant='ghost'
            iconName='x'
            onClick={handleCancel}
            color='shadow'
            className='absolute right-9 top-2 !mr-1'
          />
          <Button
            dataCy='saved-filters-save-btn'
            size='small'
            iconName='check'
            onClick={confirmFilterName}
            color='oceanBlue'
            className={clsx(
              (!tempFilterName || isNameTaken || requireName) && 'opacity-40 cursor-default',
              'absolute right-3 top-2'
            )}
          />

          {isNameTaken && (
            <div className='name-taken-block'>
              Filter with same name already exists
              <div className='flex justify-between overwrite-name-block'>
                <p>Overwrite previous filter?</p>
                <div>
                  <Button
                    size='small'
                    onClick={handleOverwrite}
                    color='pear'
                    className='!mr-1 !uppercase'
                  >
                    yes
                  </Button>
                  <Button size='small' onClick={handleCancel} color='shadow' className='!uppercase'>
                    no
                  </Button>
                </div>
              </div>
            </div>
          )}
          {requireName && <div className='name-taken-block'>Filter must have name</div>}
        </div>
      )}
    </div>
  );
};

export default FilterPanelSave;
