import React from 'react';
import Icon from '../Icon/Icon';

interface AlertMessageProps {
  children: React.ReactNode;
  iconName?: string;
}
const AlertMessage = ({ children, iconName = 'info' }: AlertMessageProps) => {
  return (
    <div className='flex flex-row gap-2 p-2 mb-3 text-sm border rounded border-capri-900 bg-capri-100 bg-opacity-80'>
      <Icon name={iconName} size={18} color='#0079B6' />
      <div className='max-w-[90%]'>{children}</div>
    </div>
  );
};
export default AlertMessage;
