import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getHotjarTiers } from '@/Utils/getHotjarTiers';
import { userMetadataKey, userRolesKey } from '@/Utils/constants';
import FreshChat from 'react-freshchat';

const ExternalScripts = () => {
  const isIframe = window.location !== window.parent.location;
  const { user } = useSelector((state) => state.userData);

  return (
    <>
      {user && user.email && !isIframe && (
        <FreshChat
          token='596234c4-5651-494a-9205-ba64694a6d26'
          locale='en-us'
          config={{
            headerProperty: {
              hideChatButton: true,
            },
          }}
          onInit={(widget) => {
            widget.user.setProperties({
              email: user.email,
              firstName: user.nickname,
              roles: user[userRolesKey][0] || '',
              job: user[userMetadataKey]['onboarding']
                ? user[userMetadataKey]['onboarding']['actions'].toString()
                : '',
            });
          }}
        />
      )}
      {user?.email &&
        !user?.email.endsWith('@gigasheet.co') &&
        !user?.email.endsWith('@gigasheet.com') && (
          <>
            <Helmet>
              <script>{`
        if (window.location.hostname === 'app.gigasheet.com' || window.location.hostname === 'test.gigasheet.com') {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NJSQCCT')}
      `}</script>
            </Helmet>
            <Helmet>
              <script>{`
          if (window.location.hostname === 'app.gigasheet.com' || window.location.hostname === 'test.gigasheet.com') {
              var tiers = ${getHotjarTiers(user)};
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2118521,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              window.hj('identify', '${user.email}', tiers);
            }
          `}</script>
            </Helmet>
          </>
        )}

      <Helmet>
        <script>{`
      (function(g,u,i,d,e,s){
        g[e]=g[e]||[];
        var f=u.getElementsByTagName(i)[0];
        var k=u.createElement(i);
        k.async=true;
        k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';
        f.parentNode.insertBefore(k,f);
        if(g[d])return;
        var ug=g[d]={q:[]};
        ug.c=function(n){return function(){ug.q.push([n,arguments])};};
        var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];
        for(var j=0;j<m.length;j+=1){
          ug[m[j]]=ug.c(m[j]);}
        })(window,document,'script','userGuiding','userGuidingLayer','672807054ID');
    `}</script>
      </Helmet>
    </>
  );
};

export default ExternalScripts;
