import clsx from 'clsx';
import { motion } from 'framer-motion';

export default function ProgressBar({ percentCompleted = '' }) {
  return (
    <div className='border-x border-shadow-200'>
      <div className='w-full h-1 bg-capri-100'>
        {percentCompleted ? (
          <div
            className={clsx(
              'animate-pulse h-1 bg-capri-800 via-capri-500 bg-gradient-to-b from-capri-100',
              percentCompleted !== '100' ? 'rounded-r-full' : 'rounded-none'
            )}
            style={{
              width: `${percentCompleted}%`,
              maxWidth: '100%',
              minWidth: '2%',
            }}
          ></div>
        ) : (
          <motion.div
            animate={{
              x: ['0%', '65%', '0%'],
            }}
            transition={{
              duration: 4,
              ease: 'easeInOut',
              repeat: Infinity,
            }}
          >
            <div
              className='w-full h-1 rounded animate-pulse bg-capri-800 via-capri-500 bg-gradient-to-b from-capri-100'
              style={{ width: '35%' }}
            ></div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
