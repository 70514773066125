import clsx from 'clsx';

type Props = {
  text: string;
  color?: 'shadow' | 'capri' | 'violetWeb' | 'pear';
  className?: string;
  lowercase?: boolean;
  dataCy?: string;
};

// Helper function to parse URLs in text and convert them to anchor tags
const parseTextWithLinks = (text: string) => {
  const urlRegex = /((https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(:\d+)?(\/\S*)?))/g;

  const segments = text.split(urlRegex);

  return segments.map((segment, index) => {
    if (urlRegex.test(segment)) {
      const url = segment.startsWith('http') ? segment : `https://${segment}`;
      return (
        <a key={index} href={url} target='_blank' rel='noopener noreferrer' className='text-link'>
          {segment}
        </a>
      );
    }

    return <span key={index}>{segment}</span>;
  });
};

const colors = {
  shadow: 'text-ui-helper bg-none !shadow-none placeholder-helper',
  capri: 'text-capri-900 bg-capri-100 placeholder-ui',
  violetWeb: 'text-violet-web-900 bg-violet-web-100  placeholder-ui',
  pear: 'text-pear-900 bg-pear-100 placeholder-ui',
};

const HelperText = ({ text, color = 'shadow', className, lowercase, dataCy }: Props) => {
  return (
    <p
      data-cy={dataCy ? `helperText-${dataCy}` : ''}
      className={clsx(
        'tracking-wider items-center text-[10px] rounded-sm font-extrabold px-1',
        color && colors[color],
        className && className,
        !lowercase && 'uppercase'
      )}
    >
      {parseTextWithLinks(text)}
    </p>
  );
};

export default HelperText;
