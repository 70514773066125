import Modal from '@/Components/UI/Modal/Modal';
import { useEffect } from 'react';
import withModal from '../withModalHOC';
import UpgradeMessage from '@/Components/UpgradeMessage/UpgradeMessage';
import { MODAL_WARNING_MESSAGE } from '@/Utils/constants';
import { Button } from '@/Components/UI/Button/Button';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { useDispatch } from 'react-redux';

const WarningMessageModal = ({ show, hideModal }) => {
  const dispatch = useDispatch();

  const onClose = () => {
    hideModal();
  };

  useEffect(() => {
    if (show) {
      dispatch(
        addMixpanelEvent({
          eventName: 'Make a copy (upgrade to make a copy with filters and groups)',
          eventProps: { 'Export Type': 'All Rows' },
          userIncrementName: '# of exports',
        })
      );
    }
  }, [show]);

  return (
    <Modal
      title='Make a copy'
      isOpen={show}
      size='small'
      onClose={onClose}
      titleSize='large'
      iconName='copy'
    >
      <UpgradeMessage />
      <div>
        <hr className='solid mt-6'></hr>
        <div>
          <div className='flex flex-row justify-end gap-4 mt-4'>
            <Button dataCy='btn-cancel' onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_WARNING_MESSAGE })(WarningMessageModal);
