import { IllustrationSVG } from '@/Assets/icons';
import UpgradeButton from '@/Components/Modals/UpgradeAccount/UpgradeButton';
import withModal from '@/Components/Modals/withModalHOC';
import { MODAL_PAPERCUT } from '@/Utils/constants';

import { Button } from '@/Components/UI/Button/Button';
import Modal from '@/Components/UI/Modal/Modal';
import './style/index.scss';

const whatsIncluded = [
  'Advanced Filters',
  'Combine up to 100 files',
  'More space and larger files',
  'View more rows per page',
  'Premium support',
];

export const bodyComponent = (hideModal) => {
  return (
    <>
      <div className='flex flex-row'>
        <div className='flex flex-col w-6/12 gap-6 text-sm'>
          <div className=''>
            Upgrade to unlock this feature and get more power with Gigasheet Premium.
          </div>

          <div className='flex flex-col gap-2 '>
            <div className='pb-1 text-sm font-semibold'>Here&apos;s what&apos;s included:</div>
            <div className='pl-2'>
              {whatsIncluded.map((option) => {
                return (
                  <li className='text-sm' key={option}>
                    {option}
                  </li>
                );
              })}
            </div>
          </div>

          <div className='flex flex-col items-center justify-center gap-2 pb-2 align-center'>
            <UpgradeButton />
            <Button onClick={hideModal} variant='ghost'>
              Maybe Later
            </Button>
          </div>
        </div>

        <div className='absolute top-0 right-0 h-full pt-8 rounded-r-md bg-pear-100'>
          <img src={IllustrationSVG.Planet} alt='no-results' />
        </div>
      </div>
    </>
  );
};

const PapercutModal = ({ show, hideModal }) => {
  return (
    <Modal title='You could use an upgrade' size='medium' isOpen={show} onClose={hideModal}>
      {bodyComponent(hideModal)}
    </Modal>
  );
};

export default withModal({ name: MODAL_PAPERCUT })(PapercutModal);
