// convertIntToColumnName
export function convertIntToColumnName(index) {
  if (index === 0) {
    return void 0;
  }

  const arr = [];
  let i = 0;
  let output = '';

  while (index > 0) {
    arr[i] = index % 26;
    index = Math.floor(index / 26);
    i++;
  }

  for (let j = 0; j < i - 1; j++) {
    if (arr[j] <= 0) {
      arr[j] += 26;
      arr[j + 1] = arr[j + 1] - 1;
    }
  }

  for (let j = i; j >= 0; j--) {
    if (arr[j] > 0) {
      output += String.fromCharCode(arr[j] + 64);
    }
  }

  return output;
}
