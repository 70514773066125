import { useState, useMemo, useEffect } from 'react';
import { Combobox } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import { Button } from '@/Components/UI/Button/Button';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import Icon from '@/Components/UI/Icon/Icon';

import { spreadsheetSliceName } from '@/redux/constants';
import { getTypeIconPath } from '@/Utils/getTypeIconPath';
import { FILES, HEADERS, MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import { put } from '@/Utils/API';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const RenameColumnsPopupBody = ({ hideModal }) => {
  const { columnDefs, isInsertingNewColumn } = useSelector((state) => state[spreadsheetSliceName]);
  const [columns, setColumns] = useState([]);
  const [findValue, setFindValue] = useState('');
  const [replaceValue, setReplaceValue] = useState('');
  const [query, setQuery] = useState('');
  const [selectedColumnsPreview, setSelectedColumnsPreview] = useState([]);
  const [filteredColumns, setFilteredColumns] = useState(['']);

  const dispatch = useDispatch();
  const currentFile = useCurrentFile();

  const _columns = useMemo(() => {
    return columnDefs.reduce((acc, { colId, headerName, fieldType }) => {
      if (colId !== ROW_NUMBER_COLUMN_ID) {
        return [...acc, { colId, headerName, fieldType, isChecked: false }];
      }

      return acc;
    }, []);
  }, [columnDefs]);

  const getSelectedColumnPreview = () => {
    const selectedColumns = columns.filter((col) => col.isChecked && col.colId !== 'selectAll');

    if (!selectedColumns.length) return setSelectedColumnsPreview([]);

    const updatedSelectedColumns = selectedColumns.map((col) => {
      const headerNamePreview = findValue
        ? col.headerName.replaceAll(findValue, replaceValue)
        : col.headerName;

      return { ...col, headerNamePreview };
    });

    setSelectedColumnsPreview(updatedSelectedColumns);
  };

  const handleColumnNameQuery = (query) => {
    const filteredColumns =
      query === ''
        ? columns
        : columns.filter((col) => {
            if (col.colId === 'selectAll') {
              return true;
            }
            return col.headerName.toLowerCase().includes(query.toLowerCase());
          });

    setFilteredColumns(filteredColumns);
  };

  const handleSelectColumn = (col) => {
    const updatedColumns = columns.map((el) => {
      if (col.colId === 'selectAll' && el.headerName.toLowerCase().includes(query.toLowerCase())) {
        return { ...el, isChecked: !col.isChecked };
      } else {
        if (el.colId === col.colId) {
          return { ...el, isChecked: !el.isChecked };
        }
        if (el.colId === 'selectAll') {
          return { ...el, isChecked: false };
        }
      }
      return el;
    });
    setColumns(updatedColumns);
  };

  const renderSelectedColumnPreview = () => {
    const maxPreviewColumn = 4;
    const numberOfSelectedColumns = selectedColumnsPreview.length;
    const _selectedColumnsPreview = selectedColumnsPreview.filter(
      (_, i) => i < maxPreviewColumn || i === numberOfSelectedColumns - 1
    );

    if (_selectedColumnsPreview.length >= maxPreviewColumn + 1) {
      _selectedColumnsPreview.splice(maxPreviewColumn, 0, {
        colId: '...',
        headerNamePreview: '...',
      });
    }

    return _selectedColumnsPreview.map((col) => {
      return <li key={col.colId}>{col.headerNamePreview}</li>;
    });
  };

  const onRenameButtonClick = async () => {
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(setIsInsertingNewColumn(true));
    const uuid = getFileUuidFromPath();

    const headers = {};

    selectedColumnsPreview.forEach(({ colId, headerName, headerNamePreview }) => {
      if (headerName !== headerNamePreview) {
        headers[colId] = headerNamePreview;
      }
    });

    const body = {
      headers,
    };

    try {
      await put(`${FILES}/${uuid}/${HEADERS}`, body);
      await dispatch(fetchOpenedFile(uuid));
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const isRenameButtonDisabled =
    !findValue || isInsertingNewColumn || !selectedColumnsPreview.length;

  useEffect(() => {
    if (_columns.length) {
      const selectAllItem = {
        colId: 'selectAll',
        headerName: 'Select All',
        fieldType: false,
        isChecked: false,
      };
      setColumns([selectAllItem, ..._columns]);
    }
  }, [_columns]);

  useEffect(() => {
    getSelectedColumnPreview();
  }, [findValue, replaceValue, columns]);

  useEffect(() => {
    if (columns.length) {
      const updatedColumns = columns.map((el) => {
        return { ...el, isChecked: false };
      });
      setColumns(updatedColumns);
    }
  }, [query]);

  useEffect(() => {
    handleColumnNameQuery(query);
  }, [columns]);

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between gap-6'>
        <div className='grow'>
          <span className='flex items-center h-5 mb-2 text-sm font-semibold w-fit text-ui'>
            Select Columns
          </span>
          <div className='p-2 border rounded border-ui-200 w-72 h-52'>
            <div className=''>
              <Combobox multiple>
                <Combobox.Input
                  onChange={(event) => setQuery(event.target.value)}
                  className='mb-3 w-full border flex items-center bg-white pr-6 pl-3 rounded disabled:bg-ui-secondary !disabled:text-helper disabled:border-shadow-200 outline-none h-8 focus:outline-none text-sm border-shadow-200 text-secondary !shadow-none placeholder-helper font-normal'
                  value={query}
                />
                <Combobox.Options static className='overflow-auto h-36'>
                  {filteredColumns.length <= 1 ? (
                    <span className='flex justify-center text-sm text-ui-helper'>No options</span>
                  ) : (
                    filteredColumns.map((col) => {
                      return (
                        <Combobox.Option key={col?.colId} onClick={() => handleSelectColumn(col)}>
                          <div
                            className={clsx(
                              'flex justify-start items-center px-2 py-1 hover:bg-midnight-100 rounded text-sm whitespace-nowrap cursor-pointer mb-[2px]',
                              col?.isChecked && col.colId !== 'selectAll' ? 'bg-midnight-100' : ''
                            )}
                          >
                            <CheckboxInput
                              onChange={() => handleSelectColumn(col)}
                              checked={col.isChecked}
                            />
                            {col?.fieldType && (
                              <span className='w-4 ml-2'>
                                <Icon name={getTypeIconPath(col?.fieldType)} size={16} />
                              </span>
                            )}
                            <span className='flex items-center mt-1 ml-2'>{col?.headerName}</span>
                          </div>
                        </Combobox.Option>
                      );
                    })
                  )}
                </Combobox.Options>
              </Combobox>
            </div>
          </div>
        </div>
        <div className='w-full grow'>
          <TextInput
            value={findValue}
            label='Find'
            onChange={(e) => setFindValue(e.target.value)}
            className='!w-full  !mb-4'
          />
          <TextInput
            dataCy='replaceWith-input'
            label='Replace With'
            onChange={(e) => setReplaceValue(e.target.value)}
            placeholder=''
            value={replaceValue}
            className='!w-full'
          />
        </div>
      </div>
      <div className='mt-5'>
        <span className='flex items-center h-5 mb-2 text-sm font-semibold w-fit text-ui'>
          Preview
        </span>
        <div className='w-full p-3 text-sm border rounded border-ui-200 bg-ui h-36 text-ui-helper'>
          <ul>{renderSelectedColumnPreview()}</ul>
        </div>
      </div>
      <div className='flex flex-row items-center justify-end py-4 mt-6 text-right border-t border-ui-200'>
        <div className='flex flex-row gap-4'>
          <Button variant='ghost' color='shadow' dataCy='cancel-btn' onClick={hideModal}>
            Cancel
          </Button>
          <Button
            color={'oceanBlue'}
            dataCy='rename-btn'
            onClick={onRenameButtonClick}
            disabled={isRenameButtonDisabled}
          >
            Rename
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RenameColumnsPopupBody;
