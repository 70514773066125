import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enrichmentTemplatesSliceName, spreadsheetSliceName } from '@/redux/constants';
import { Button } from '@/Components/UI/Button/Button';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import { replaceHeadersWithColumnIds } from '@/Components/Modals/Enrichment/DataEnrichment/fields/ColumnReferenceTextFieldHelper';
import {
  updatePersistedValues,
  updateUserInputValueById,
} from '@/redux/reducers/enrichmentTemplates';
import { getPersistedValuesKey } from '@/Utils/enrichmentUtils/EnrichmentUtils';
import { ROW_NUMBER_COLUMN_ID } from '@/Utils/gridConstants';
import ComboBox from '@/Components/UI/Shadcn/ComboBox';
import { getTypeIconPath } from '@/Utils/getTypeIconPath';

export const ColumnReferenceTextField = ({ field, onChange }) => {
  const [valueWithHeaders, setValueWithHeaders] = useState('');
  const [showCombobox, setShowCombobox] = useState(false);
  const [usedColumns, setUsedColumns] = useState([]);
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);
  const { chosenEnrichmentTemplate, persistedValues } = useSelector(
    (state) => state[enrichmentTemplatesSliceName]
  );
  const textareaRef = useRef();
  const dispatch = useDispatch();
  const PERSISTED_VALUES_KEY_VALUE_WITH_HEADERS = 'PERSISTED_VALUES_KEY_VALUE_WITH_HEADERS';
  const PERSISTED_VALUES_KEY_VALUE_WITH_COL_ID = 'PERSISTED_VALUES_KEY_VALUE_WITH_COL_ID';

  useEffect(() => {
    const _valueWithHeaders =
      persistedValues[
        getPersistedValuesKey(
          chosenEnrichmentTemplate.id,
          field.id,
          PERSISTED_VALUES_KEY_VALUE_WITH_HEADERS
        )
      ];
    const _valueWithColIds =
      persistedValues[
        getPersistedValuesKey(
          chosenEnrichmentTemplate.id,
          field.id,
          PERSISTED_VALUES_KEY_VALUE_WITH_COL_ID
        )
      ];
    if (_valueWithHeaders?.length > 0 && _valueWithColIds?.length > 0) {
      setValueWithHeaders(_valueWithHeaders);
      onChange(_valueWithColIds);
      dispatch(updateUserInputValueById(dataToRedux(_valueWithColIds)));
    }
  }, []);

  const options = useMemo(() => {
    return columnDefs
      .filter((c) => c.colId !== ROW_NUMBER_COLUMN_ID)
      .map((colDef) => ({
        label: colDef.headerName,
        value: colDef.field,
        type: colDef.fieldType,
        icon: getTypeIconPath(colDef.fieldType),
      }));
  }, [columnDefs]);

  const dataToRedux = (value) => {
    return {
      ...field,
      template_value_if_set: value,
    };
  };

  const onChangeTargetColumn = (newTargetColumn) => {
    setShowCombobox(false);
    insertColumnReference(newTargetColumn[0]);
  };

  const handlePromptChange = (event) => {
    updateValueWithHeaders(event.target.value);
    const valueWithColumnIds = replaceHeadersWithColumnIds(event.target.value, columnDefs);
    updateValueWithColIds(valueWithColumnIds);
    dispatch(updateUserInputValueById(dataToRedux(valueWithColumnIds)));
  };

  const updateValueWithHeaders = (newValueWithHeaders) => {
    setValueWithHeaders(newValueWithHeaders);
    dispatch(
      updatePersistedValues({
        key: getPersistedValuesKey(
          chosenEnrichmentTemplate.id,
          field.id,
          PERSISTED_VALUES_KEY_VALUE_WITH_HEADERS
        ),
        value: newValueWithHeaders,
      })
    );
  };

  const updateValueWithColIds = (newValueWithColIds) => {
    onChange(newValueWithColIds);
    dispatch(
      updatePersistedValues({
        key: getPersistedValuesKey(
          chosenEnrichmentTemplate.id,
          field.id,
          PERSISTED_VALUES_KEY_VALUE_WITH_COL_ID
        ),
        value: newValueWithColIds,
      })
    );
  };

  const insertColumnReference = (newTargetColumnValue) => {
    const { selectionStart, selectionEnd } = textareaRef.current;
    const newValueWithHeaders =
      valueWithHeaders.slice(0, selectionStart) +
      `****${newTargetColumnValue.label}****` +
      valueWithHeaders.slice(selectionEnd);

    updateValueWithHeaders(newValueWithHeaders);
    updateValueWithColIds(replaceHeadersWithColumnIds(newValueWithHeaders, columnDefs));
    setUsedColumns([...usedColumns, newTargetColumnValue]);
  };

  const toggleShowReferenceColumn = () => {
    setShowCombobox(!showCombobox);
  };

  return (
    <div className='mb-5 border rounded'>
      <div className='border-b bg-ui'>
        <Button
          dataCy='insert-column-reference-btn'
          color='shadow'
          iconName='brackets-curly'
          size='small'
          variant='outline'
          className='m-2'
          onClick={toggleShowReferenceColumn}
        >
          <div className='text-[13px]'>Insert Column Reference</div>
        </Button>
      </div>
      <div className='absolute w-64'>
        {showCombobox ? (
          <ComboBox
            placeholder='Column reference'
            options={options}
            className='w-full'
            onChange={onChangeTargetColumn}
            dataCy='column-reference-searchselect'
            isOpenbyDefault={true}
          />
        ) : null}
      </div>
      <div>
        <Textarea
          placeholder='Enter Your Prompt'
          value={valueWithHeaders}
          onChange={handlePromptChange}
          className='p-4 text-sm font-normal border-0 h-28'
          dataCy='column-reference-textarea'
          ref={textareaRef}
        />
      </div>
    </div>
  );
};
