import { Button } from '@/Components/UI/Button/Button';

import HelperText from '@/Components/UI/HelperText/HelperText';
import Icon from '@/Components/UI/Icon/Icon';

const DeleteDuplicates = ({
  allColumns = [],
  selectedColumns = [],
  selectButtons = [],
  toggleColumnSelection = () => {},
}) => {
  return (
    <>
      <div className='delete-duplicates-body'>
        <h6>Columns</h6>
        <p data-cy='duplicates-description' className='mb-3'>
          Select 1 or more columns to be checked for duplicates
        </p>
        <div className='delete-duplicates-select-columns'>
          {allColumns.map(({ value, label }, i) => (
            <div key={i}>
              <input
                data-cy={label}
                className='file-checkbox'
                type='checkbox'
                key={value}
                value={value}
                onChange={(e) => toggleColumnSelection(e)}
                checked={selectedColumns.includes(value)}
              />
              <label htmlFor={value}>{label}</label>
            </div>
          ))}
        </div>
        <div className='flex justify-around my-4'>
          {selectButtons.map(({ name, callback, cypressAttribute }) => (
            <Button
              dataCy={cypressAttribute}
              key={name}
              color='shadow'
              onClick={callback}
              className='w-full mx-2'
            >
              {name}
            </Button>
          ))}
        </div>
      </div>
      <div className='flex items-center'>
        <Icon name='info' size={16} color='#0079B6' />
        <HelperText
          dataCy='deduplicates-instruction'
          className='font-semibold leading-3 uppercase !bg-transparent !text-xs'
          color='capri'
          text='Selecting multiple columns will remove duplicates of resulting value pairs'
        />
      </div>
    </>
  );
};

export default DeleteDuplicates;
