import { useState, useRef } from 'react';

import clsx from 'clsx';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import useOnClickOutsideHook from '@/hooks/useOnClickOutside';
import { FILE_STATUS_PROCESSING, FILE_TYPE_EXPORTER } from '@/Utils/fileConstants';

import Icon from '@/Components/UI/Icon/Icon';
import './style/index.scss';

const FileActions = ({
  dataCy = '',
  onDropdownClick,
  row,
  className,
  dropdownItems = [{ title: '', callback: () => {}, className: '' }],
}) => {
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const [menuOverflow, handleMenuOverflow] = useState(false);
  const refActionsBlock = useRef();

  const _toggleDropdown = () => {
    toggleDropdown(!isDropdownOpen);
  };

  const handleClick = (e) => {
    handleMenuOverflow(window.innerHeight - e.clientY <= 250);
    e.stopPropagation();
    onDropdownClick(e);
    toggleDropdown(!isDropdownOpen);
  };

  useOnClickOutsideHook(isDropdownOpen, refActionsBlock, _toggleDropdown);

  return (
    <div className='actions-container'>
      <Tooltip text='More' side='bottom' asChild>
        <button
          type='button'
          data-testid={dataCy}
          data-cy='btn-file-actions'
          className={`round-btn ${className} ${isDropdownOpen ? 'active' : ''}`}
          onClick={handleClick}
          disabled={row.Status === FILE_STATUS_PROCESSING && row.Type === FILE_TYPE_EXPORTER}
        />
      </Tooltip>

      {isDropdownOpen && dropdownItems.length > 0 && (
        <div
          role='button'
          tabIndex={0}
          onKeyUp={() => {}}
          className='actions-dropdown '
          style={{ top: `${menuOverflow ? '' : '32px'}`, bottom: `${menuOverflow ? '38px' : ''}` }}
          ref={refActionsBlock}
          onClick={(event) => event.stopPropagation()}
        >
          {dropdownItems.map((el, index) => (
            <DropDownItem
              key={index}
              {...el}
              dropdownItemsLength={dropdownItems.length}
              toggleDropdown={toggleDropdown}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FileActions;

const DropDownItem = ({
  title,
  dataCy,
  icon,
  callback,
  premiumFeature = false,
  submenu = [],
  dropdownItemsLength,
  toggleDropdown,
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleClick = () => {
    toggleDropdown(false);
    callback();
  };

  return (
    <button
      style={{ borderRadius: `${dropdownItemsLength === 1 ? '5px' : ''}` }}
      className={clsx(
        'relative item-template items-center inline-flex text-ui hover:text-white',
        premiumFeature && '!text-ui-secondary !text-opacity-50 !hover:text-white'
      )}
      data-cy={dataCy}
      onClick={handleClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <span className='inline-flex items-center mr-2'>
        <Icon name={icon} size={22} color='currentcolor' />
      </span>
      {title}
      {premiumFeature && (
        <span className='inline-flex items-center ml-6'>
          <Icon name='rocket-launch' size={18} color='#D9B500' />
        </span>
      )}
      {!!submenu.length && (
        <span className='absolute top-0 bottom-0 flex items-center right-2'>
          <Icon name='caret-right' weight='fill' size={12} color='currentcolor' />
        </span>
      )}
      {isHover && !!submenu.length && (
        <div className='absolute -translate-x-full bottom-0 left-0 !w-64 bg-[#FCFCFF] border border-[#26323829] !rounded shadow-[5px_5px_9px_-3px_rgba(34,60,80,0.07)]'>
          {submenu.map(({ title, dataCy, callback, premiumFeature = false }, index) => (
            <button
              key={index}
              style={{ borderRadius: `${dropdownItemsLength === 1 ? '5px' : ''}` }}
              className={clsx(
                'relative justify-between item-template items-center inline-flex text-ui hover:text-white',
                premiumFeature && '!text-ui-secondary !text-opacity-50 !hover:text-white'
              )}
              data-cy={dataCy}
              onClick={callback}
            >
              <span>{title}</span>
              {premiumFeature && (
                <span className='inline-flex items-center mr-2'>
                  <Icon name='rocket-launch' size={18} color='#D9B500' />
                </span>
              )}
            </button>
          ))}
        </div>
      )}
    </button>
  );
};
