import { useState } from 'react';

import Modal from '@/Components/UI/Modal/Modal'; // Adjust the import path as necessary
import { Button } from '@/Components/UI/Button/Button';
import showToast from '@/Components/Toast/showToastTemplate';

import { get } from '@/Utils/API';
import {
  MODAL_EXPORT_DOWNLOAD,
  TOAST_TEXT_UNABLE_TO_EXPORT_FILE_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASET,
} from '@/Utils/constants';
import withModal from '../withModalHOC';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const DOWNLOAD_DELAY = 5000;

const ExportDownloadModal = ({ show, hideModal, FileId }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const currentFile = useCurrentFile();
  const { FileUuid: uuid } = currentFile?.metadata || {};

  const downloadExport = async () => {
    setIsDisabled(true);
    let endpoint = '';
    try {
      endpoint = `${URL_DATASET}/${FileId || uuid}/download-export`;
      const data = await get(endpoint);
      if (data && data.presignedUrl) {
        window.location.href = data.presignedUrl;
      }
      setTimeout(() => {
        setIsDisabled(false);
      }, DOWNLOAD_DELAY);
    } catch (e) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_UNABLE_TO_EXPORT_FILE_ERROR,
        errorContext: e,
        endpoint: endpoint,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    } finally {
      hideModal();
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title='Download Export'
      size='x-small'
      iconName='export'
    >
      <hr className='mb-5 solid' />
      <div className='flex justify-end gap-4'>
        <Button variant='ghost' color='shadow' size='large' onClick={hideModal} dataCy='cancel-btn'>
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={downloadExport}
          disabled={isDisabled}
          dataCy='save-btn'
        >
          Download
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_EXPORT_DOWNLOAD })(ExportDownloadModal);
