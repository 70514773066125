import clsx from 'clsx';
import { Label } from '../../Shadcn/Label';
import { Switch } from '../../Shadcn/Switch';
import React, { useState } from 'react';

const generateRandomId = () => {
  return Math.random().toString(36).substr(2, 10);
};

type Props = {
  label?: string;
  labelCss?: string;
  value: boolean;
  onChange: (checked: boolean, name?: string) => void;
  color?: 'oceanBlue';
  disabled?: boolean;
  size?: 'small' | 'medium';
  name?: string;
  additionalStyles?: string;
  dataCy?: string;
  labelOnLeftSide?: boolean;
  children?: React.ReactElement;
};

const ToggleSwitchInput = ({
  label,
  labelCss,
  value,
  onChange,
  disabled,
  size = 'medium',
  name,
  additionalStyles,
  dataCy,
  labelOnLeftSide = true,
  children,
}: Props) => {
  const [randomId] = useState(generateRandomId);

  const labelElement = (
    <Label htmlFor={randomId} className={clsx('typography-sm', labelCss)}>
      {label}
    </Label>
  );

  const handleOnChange = (checked: boolean) => {
    onChange(checked, name);
    const hiddenInput = document.getElementById(`hidden-${randomId}`) as HTMLInputElement;

    if (hiddenInput) {
      hiddenInput.value = checked ? 'true' : 'false';
    }
  };

  return (
    <div
      className={clsx(
        'flex items-center',
        { 'justify-between': labelOnLeftSide },
        additionalStyles
      )}
    >
      {labelOnLeftSide && labelElement}
      <Switch
        data-cy={`${dataCy}-switch`}
        checked={value}
        id={randomId}
        onCheckedChange={handleOnChange}
        disabled={disabled}
        size={size}
      />

      {name && (
        <input
          type='checkbox'
          id={`hidden-${randomId}`}
          name={name}
          data-cy={`${dataCy}-checkbox`}
          checked={value}
          onChange={() => {}}
          style={{ display: 'none' }}
        />
      )}
      {!labelOnLeftSide && labelElement}
      {children}
    </div>
  );
};

export default ToggleSwitchInput;
