import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { get, post } from '@/Utils/API';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import Spinner from '@/Components/UI/Spinner/Spinner';
import { Button, Link } from '@/Components/UI/Button/Button';
import ShowToast from '@/Components/Toast/showToastTemplate';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { getNumberValueSeparatedByCommas } from '@/Utils/utils';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { setNewEnrichmentStarted } from '@/redux/reducers/sharedSlice';
import TextInputWithIcon from '@/Components/UI/Form/TextInputWithIcon.tsx/TextInputWithIcon.tsx';
import {
  enrichmentTemplatesSliceName,
  spreadsheetSliceName,
  userDataSliceName,
} from '@/redux/constants';
import {
  getSelectedFieldNamesFromIndex,
  transformResponseDataToTableFormat,
} from '@/helpers/enrichmentHelper';
import {
  MODAL_DATA_ENRICHMENT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_CUSTOM_ENRICHMENT_SUCCESS,
  TOAST_TYPE_ERROR,
  TOAST_TYPE_INFO,
  userRolesKey,
} from '@/Utils/constants';
import {
  resetEnrichment,
  setEnrichmentCategory,
  setEnrichmentOutputPathTable,
  setEnrichmentResponseType,
  setOutputPathFilteredData,
  setOutputPathMapForApplyRequest,
  setSelectedOutputPathBool,
  setUniqueColumns,
} from '@/redux/reducers/enrichmentTemplates';

import FieldTable from '../FieldTable';
import withModal from '../../withModalHOC';
import { enrichmentRecipes } from './recipes/index.js';
import { EnrichmentListItem } from './EnrichmentListItem';
import { EnrichmentNoResults } from './EnrichmentNoResults';
import { EnrichmentFieldsForm } from './EnrichmentFieldsForm.jsx';
import { EnrichmentNavigation } from './EnrichmentNavigation/EnrichmentNavigation';
import { getBodyForRequest } from '../../../../Utils/enrichmentUtils/EnrichmentUtils';
import { setEnrichmentCredits } from '@/redux/reducers/spreadsheetSlice';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { openChargeBeeUpgrade } from '@/helpers/upgradeHelper';
const limitOfSelectedColumn = 100;

const DataEnrichment = ({ show, hideModal }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();

  const {
    chosenEnrichmentTemplate,
    enrichmentCategory,
    enrichmentOutputPathTable,
    enrichmentResponseType,
    outputPathFilteredData,
    outputPathMapForApplyRequest,
    selectedOutputPathBool,
    uniqueColumns,
    userInputValues,
  } = useSelector((state) => state[enrichmentTemplatesSliceName]);
  const { filteredNumberOfRows, enrichmentCredits } = useSelector(
    (state) => state[spreadsheetSliceName]
  );
  const { user } = useSelector((state) => state[userDataSliceName]);

  const { email } = user || {};

  const fileId = getFileUuidFromPath();

  const [searchValue, setSearchValue] = useState('');
  const [outputPathLoading, setOutputPathLoading] = useState(false);
  const [isFetchingUniqueColumns, setIsFetchingUniqueColumns] = useState(false);
  const [isLoadingEnrichCredits, setIsLoadingEnrichCredits] = useState(false);

  const [step, setStep] = useState(1);

  // TODO after requirements
  const [, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const searchedSortedRecipes = enrichmentRecipes.filter(
    (item) =>
      item.endpoint_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.vendor_name.toLowerCase().includes(searchValue.toLowerCase())
  );
  const filteredCategory = searchedSortedRecipes.filter((enrich) => {
    return enrich.category.includes(enrichmentCategory);
  });

  const handleSetStep = (step) => setStep(step);

  const goBack = () => {
    if (step === 1) return;
    if (step === 2) {
      dispatch(resetEnrichment());
    }
    setStep(step - 1);
  };
  const onCancel = () => {
    setStep(1);
    hideModal();
  };

  const getEnrichmentPathMap = async () => {
    setError(false);
    setErrorMessage('');
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    setOutputPathLoading(true);
    try {
      const body = getBodyForRequest(chosenEnrichmentTemplate, userInputValues);
      setStep(3);
      const response = await post(`enrich/user-defined-http/${fileId}/preview`, body);
      if (response.success) {
        if (response?.response_data?.length > 0 || response?.response_failures?.length > 0) {
          const enrichPreviewTableData =
            response?.response_data?.length > 0
              ? response.response_data
              : response?.response_failures;
          const includedOutputPaths = enrichPreviewTableData.map((subArray) => {
            const includedPaths = chosenEnrichmentTemplate?.output_path_map.reduce(
              (acc, pathItem) => {
                const matchingItem = subArray.find(
                  (item) => JSON.stringify(pathItem.path) === JSON.stringify(item.path)
                );
                if (matchingItem) {
                  acc.push(matchingItem);
                } else {
                  acc.push({ path: pathItem.path, value: null });
                }
                return acc;
              },
              []
            );
            return includedPaths;
          });
          dispatch(
            setEnrichmentOutputPathTable(transformResponseDataToTableFormat(includedOutputPaths))
          );
          if (response?.response_type) {
            dispatch(setEnrichmentResponseType(response?.response_type));
          }
          setOutputPathLoading(false);
        } else {
          setError(true);
          setErrorMessage('Results not found');
        }
      } else {
        setError(true);
        setErrorMessage(response.Message || response.message || '');
      }
    } catch (e) {
      setOutputPathLoading(false);
      setError(true);
    }
  };

  const onApplyEnrichmentBtnClick = async () => {
    if (outputPathMapForApplyRequest.length > limitOfSelectedColumn) {
      return ShowToast({
        type: TOAST_TYPE_ERROR,
        text: `You have reached the maximum size of ${limitOfSelectedColumn} selected columns. Please adjust your selection to continue`,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }

    await fetchEnrichmentCredits();
    fetchUniqueColumnValues();

    setStep(4);
  };

  const onRunBtnClick = async () => {
    const { success } = await post(
      `enrich/user-defined-http/${fileId}/apply`,
      getBodyForRequest(
        {
          ...chosenEnrichmentTemplate,
          output_path_map: outputPathMapForApplyRequest,
          response_type: enrichmentResponseType,
        },
        userInputValues,
        false
      )
    );
    if (success) {
      dispatch(setNewEnrichmentStarted(true));
      ShowToast({ type: TOAST_TYPE_INFO, text: TOAST_TEXT_CUSTOM_ENRICHMENT_SUCCESS });
      dispatch(resetEnrichment());
      onCancel();
    }
  };

  const fetchUniqueColumnValues = async () => {
    setIsFetchingUniqueColumns(true);
    try {
      const uniqueValues = filteredNumberOfRows || 0;
      dispatch(
        setUniqueColumns(
          user[userRolesKey].includes('giga-feature-customenrichfull')
            ? uniqueValues
            : uniqueValues > 1000
            ? 1000
            : uniqueValues
        )
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsFetchingUniqueColumns(false);
    }
  };

  const fetchEnrichmentCredits = async () => {
    setIsLoadingEnrichCredits(true);
    try {
      const response = await get('user/enrichment-credits');
      if (!response?.Limit) {
        dispatch(setEnrichmentCredits({ Limit: 0, Used: 0 }));
      }
      dispatch(setEnrichmentCredits(response));
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoadingEnrichCredits(false);
    }
  };

  useLayoutEffect(() => {
    setStep(1);
    show && step === 1 && fetchEnrichmentCredits();
    show && dispatch(setEnrichmentCategory('Explore'));
  }, [show]);

  useEffect(() => {
    searchValue.length > 0 && dispatch(setEnrichmentCategory('Explore'));
  }, [searchValue]);

  useEffect(() => {
    if (enrichmentOutputPathTable.length && selectedOutputPathBool.length) {
      const fieldsToApply = getSelectedFieldNamesFromIndex(
        selectedOutputPathBool,
        Object.entries(enrichmentOutputPathTable[0])
      );

      if (fieldsToApply && fieldsToApply.length > 0) {
        dispatch(
          setOutputPathMapForApplyRequest(
            fieldsToApply.map((field) => {
              return { path: field[1].fieldNamePath, name: field[0] };
            })
          )
        );
      } else {
        dispatch(setOutputPathMapForApplyRequest([]));
      }
    } else {
      dispatch(setOutputPathMapForApplyRequest([]));
    }
  }, [selectedOutputPathBool, enrichmentOutputPathTable, outputPathFilteredData, dispatch]);

  const isDisabledTextBtn = () => {
    return userInputValues?.some((input) => {
      return (
        input.required &&
        (input['template_value_if_set'] === null ||
          input['template_value_if_set'] === undefined ||
          input['template_value_if_set'].trim() === '')
      );
    });
  };

  const cachedSetSearchValue = useCallback(setSearchValue, []);

  const upgradeAccount = () => {
    hideModal();
    openChargeBeeUpgrade(email);
  };

  const step1 = () => {
    return (
      <div className='flex'>
        <div className='mr-[18px]'>
          <div className='w-[169px]'>
            <div className='w-full pb-[28px]'>
              <TextInputWithIcon
                onChange={(e) => cachedSetSearchValue(e.target.value)}
                value={searchValue}
                className='w-full'
                placeholder='Search'
                dataCy='search-enrichments'
              />
            </div>
            <div className='flex p-2 border-pear-900 bg-pear-100 rounded mb-[24px] border border-solid text-shadow-700'>
              <div className='flex justify-center items-start mr-2'>
                <Icon name='coins' color='#6D741B' />
              </div>
              <div className='text-sm'>
                {isLoadingEnrichCredits ? (
                  <div className='flex justify-center w-full'>
                    <Spinner size='small' color='shadow' className={'inline-block'} />
                  </div>
                ) : (
                  <div className='flex flex-col'>
                    <div className='pl-1'>
                      {getNumberValueSeparatedByCommas(
                        Math.max(0, enrichmentCredits.Limit - enrichmentCredits.Used)
                      )}{' '}
                      credits
                    </div>
                    <div>
                      <Button
                        variant='ghost'
                        color='sunrise'
                        className='!p-0 !text-[10px] !font-bold'
                        size='small'
                        onClick={upgradeAccount}
                      >
                        <div className='flex items-center'>
                          <Icon name='arrow-right' size={10} />
                          <span className='uppercase px-1'>buy credits </span>
                          <Icon name='arrow-right' size={10} />
                        </div>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <EnrichmentNavigation setSearchValue={cachedSetSearchValue} />
          </div>
        </div>
        <motion.div layout className='h-[80vh] w-full  overflow-y-auto overflow-x-hidden'>
          <AnimatePresence>
            {filteredCategory.length ? (
              <div
                data-cy='enrichment-container'
                className=' grid grid-cols-1 mt-[-6px] sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2'
              >
                {filteredCategory.map((recipe, i) => (
                  <EnrichmentListItem
                    key={recipe.id + i}
                    recipe={recipe}
                    handleSetStep={handleSetStep}
                  />
                ))}
              </div>
            ) : (
              <EnrichmentNoResults />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    );
  };
  const step2 = () => {
    return (
      <>
        <EnrichmentFieldsForm />
        <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
          <Button color='shadow' dataCy='reset-field-btn' className='float-left' onClick={goBack}>
            Back
          </Button>

          <Button
            className='mr-[16px]'
            variant='ghost'
            color='shadow'
            dataCy='cancel-btn'
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            disabled={isDisabledTextBtn()}
            color={'oceanBlue'}
            dataCy='save-btn'
            onClick={getEnrichmentPathMap}
          >
            Test
          </Button>
        </div>
      </>
    );
  };

  const commonDescription = () => {
    return (
      <p className='pr-8 mb-4 ml-8 -mt-6 text-base font-semibold text-ui-secondary'>
        Gigasheet will make multiple API requests, using the values from each row in your sheet; up
        to your credit limit.{' '}
        <Link
          to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
          target='_blank'
          className='inline-block'
          size='medium'
        >
          Contact support
        </Link>{' '}
        for full access.
      </p>
    );
  };

  const step3 = () => {
    return (
      <>
        {commonDescription()}
        {errorMessage ? (
          <div className='text-center flex items-center justify-center min-h-[14rem] flex-col gap-y-2'>
            <p className='text-base font-semibold text-midnight'>Error Running Request</p>
            <p>{errorMessage}</p>
          </div>
        ) : outputPathLoading ? (
          <div className='text-center flex items-center justify-center min-h-[14rem]'>
            <Spinner size='medium' color='shadow' className={'inline-block'} />
            <p className='inline-block ml-2'>Determining Fields..</p>
          </div>
        ) : (
          <>
            <InputField label='Select Fields to Import' helperTextPosition='top' />
            <FieldTable
              setSelectedFields={(data) => {
                dispatch(setSelectedOutputPathBool(data));
              }}
              responseData={enrichmentOutputPathTable}
              setFilteredResponseData={(data) => {
                dispatch(setOutputPathFilteredData(data));
              }}
            />
          </>
        )}
        <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
          <Button color='shadow' dataCy='reset-field-btn' className='float-left' onClick={goBack}>
            Back
          </Button>

          <Button
            className='mr-[16px]'
            variant='ghost'
            color='shadow'
            dataCy='cancel-btn'
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            color={'oceanBlue'}
            dataCy='save-btn'
            onClick={onApplyEnrichmentBtnClick}
            disabled={!outputPathMapForApplyRequest.length}
          >
            Apply
          </Button>
        </div>
      </>
    );
  };

  const step4 = () => (
    <>
      {commonDescription()}
      <div className='text-left flex items-start justify-center min-h-[14rem] flex-col gap-y-2'>
        {isFetchingUniqueColumns || isLoadingEnrichCredits ? (
          <div className='flex items-center justify-center w-full'>
            <Spinner size='medium' color='shadow' className={'inline-block'} />
          </div>
        ) : (
          <>
            {enrichmentCredits.Used + uniqueColumns <= enrichmentCredits.Limit ? (
              <>
                <p className='text-base font-semibold text-midnight'>Are you sure?</p>
                <p className='mb-4 text-sm font-normal text-ui'>
                  {`${uniqueColumns} requests will be run. You have used ${enrichmentCredits.Used} of ${enrichmentCredits.Limit} enrichment credits. Depending on the third-party service used this could incur
      additional charges.`}
                </p>
                <p className='text-sm font-semibold text-ui'>
                  Are you sure you&apos;d like to proceed?
                </p>
              </>
            ) : (
              <p className='w-full mb-4 text-sm font-normal text-center text-ui'>
                <p className='p-1 text-base font-semibold text-midnight'>
                  Unable to run enrichment.
                </p>
                {`This request exceeds remaining ${Math.max(
                  0,
                  enrichmentCredits.Limit - enrichmentCredits.Used
                )} of ${enrichmentCredits.Limit} enrichment credits. `}
                <Link
                  to='mailto:support@gigasheet.com?subject=How do I run more than 100 rows?'
                  target='_blank'
                  className='inline-block'
                  size='medium'
                >
                  Contact support
                </Link>{' '}
                for more information.
              </p>
            )}
          </>
        )}
      </div>

      <div className='pt-4 mt-4 text-right border-t border-ui-200 gap-x-2'>
        <Button color='shadow' dataCy='reset-field-btn' className='float-left' onClick={goBack}>
          Back
        </Button>

        <Button className='mr-[16px]' variant='ghost' color='shadow' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            isFetchingUniqueColumns ||
            enrichmentCredits.Used + uniqueColumns >= enrichmentCredits.Limit
          }
          variant='solid'
          dataCy='run-btn'
          color='sunrise'
          onClick={onRunBtnClick}
        >
          Run
        </Button>
      </div>
    </>
  );

  const expectedModalSize = () => {
    if (step === 1) return 'x-large';
    else if (chosenEnrichmentTemplate?.modal_size) return chosenEnrichmentTemplate?.modal_size;
    else if (step === 3 || step === 4) return 'large';
    else return 'small';
  };

  return (
    <Modal
      title='Enrichments'
      subhead='Add new information to your data from third-party sources with your credits'
      iconName='atom'
      size={expectedModalSize()}
      isOpen={show}
      onClose={() => {
        cachedSetSearchValue('');
        dispatch(hideAllModals());
      }}
      articleID={69000774345}
      shouldCloseOnBackgroundClick={false}
    >
      <>
        <div>
          {step === 1 && step1()}
          {step === 2 && step2()}
          {step === 3 && step3()}
          {step === 4 && step4()}
        </div>
      </>
    </Modal>
  );
};

export default withModal({ name: MODAL_DATA_ENRICHMENT })(DataEnrichment);
