import { useEffect, useState } from 'react';
import { isEmail } from 'react-multi-email';
import { useDispatch } from 'react-redux';

import {
  filterHighestPermissionId,
  sortByUserId,
} from '@/Components/Modals/ShareFile/ShareFileUtils';
import { Select as SelectTailwind } from '@/Components/UI/Form/Select/Select';

import { setNavigationIconStatus } from '@/redux/reducers/sharedSlice';

import {
  ACCESS_DENIED_OPTION_INDEX,
  DELETE_OPTION_INDEX,
  FAKE_TEAM_OPTION_VALUE,
  PERMISSION_DENIED,
  PERMISSION_NONE,
  PERMISSION_READ,
  PERMISSION_WRITE,
  PUBLIC_OPTION_VALUE,
  TEAM_OPTION_VALUE,
} from '@/Utils/constants';

import '@/pages/DatasetPage/style/index.scss';
import 'react-multi-email/style.css';
import Select from 'react-select';
import {
  accessLevelFromUserFilePermissions,
  getInheritedUserFilePermissionsFromParentFolder,
} from '@/Utils/sharing';
import CustomMultiEmailInput from '@/Components/CustomMultiEmailInput/CustomMultiEmailInput';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';

const InvitePeopleBody = ({
  owner,
  fileUuid,
  userFilePermissions,
  activeAccessOption,
  accessOptions,
  restrictedPermissionsOptions,
  emailTags,
  isLoading,
  message,
  setMessage,
  currentUserEmail,
  setIndividualUserFilePermissions,
  onSelectChange,
  onEmailsChange,
  initialUserPermission,
  setInitialUserPermission,
  initialOrganizationPermission,
  onOrganizationPermissionChange,
  autocompleteList,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(initialUserPermission);
  const [userFilePermission, setUserFilePermission] = useState(null);

  const handlePermissionChange = (selectedPermission, userFilePermission) => {
    if (
      currentUser === userFilePermission.user_id &&
      selectedPermission !== userFilePermission.permission_id
    ) {
      setIsOpenPopup(true);
      setSelectedPermission(selectedPermission);
      setUserFilePermission(userFilePermission);
    } else {
      onPermissionChange(selectedPermission, userFilePermission);
    }
  };

  const onPermissionChange = async (selectedPermission, userFilePermission) => {
    const selectedPermissionValue = parseInt(selectedPermission, 10);
    const parentFolderInheritedUserFilePermissions =
      await getInheritedUserFilePermissionsFromParentFolder(fileUuid);
    const parentInheritedAccessLevel = accessLevelFromUserFilePermissions(
      parentFolderInheritedUserFilePermissions
    );

    // when selected permission is the same as what is already set through parent inheritance we can simply remove the
    // explicit permission on the file as it will be inherited implicitly and this would keep the database table clean
    if (
      selectedPermissionValue !== userFilePermission.permission_id &&
      selectedPermissionValue === parentInheritedAccessLevel
    ) {
      setIndividualUserFilePermissions(userFilePermission.user_id, []);
      return;
    }

    if (`${selectedPermissionValue}` === DELETE_OPTION_INDEX) {
      let newPermissions = [];
      if (parentInheritedAccessLevel !== PERMISSION_NONE) {
        newPermissions = [PERMISSION_DENIED];
      }
      setIndividualUserFilePermissions(userFilePermission.user_id, newPermissions);
    } else if (selectedPermissionValue !== userFilePermission.permission_id) {
      let newPermissions = [selectedPermissionValue];
      if (selectedPermissionValue === PERMISSION_WRITE) {
        newPermissions = [PERMISSION_READ, PERMISSION_WRITE];
      }
      setIndividualUserFilePermissions(userFilePermission.user_id, newPermissions);
    }
  };

  const isPublicAccess = () => {
    return activeAccessOption?.value === PUBLIC_OPTION_VALUE;
  };

  const isTeamAccess = () => {
    return activeAccessOption?.value === TEAM_OPTION_VALUE;
  };

  const handleAccessOption = () => {
    const isTeamAccessIncluded = accessOptions.some(({ value }) => value === TEAM_OPTION_VALUE);
    return isTeamAccessIncluded
      ? accessOptions.filter(({ value }) => value !== FAKE_TEAM_OPTION_VALUE)
      : accessOptions;
  };

  const validateEmail = (newEmail) => {
    const hasAccessDeniedPermission = userFilePermissions
      .filter(({ user_id }) => user_id === newEmail)
      .some(({ permission_id }) => permission_id === ACCESS_DENIED_OPTION_INDEX);

    return (
      isEmail(newEmail) &&
      !emailTags.includes(newEmail) &&
      newEmail !== currentUserEmail &&
      (hasAccessDeniedPermission ||
        !userFilePermissions?.some(({ user_id }) => user_id === newEmail))
    );
  };

  const submitPermissionsChange = () => {
    onPermissionChange(selectedPermission, userFilePermission);
    setIsOpenPopup(false);
  };

  useEffect(() => {
    if (!isLoading) {
      if (isTeamAccess()) {
        dispatch(setNavigationIconStatus('users-three'));
      } else if (isPublicAccess()) {
        dispatch(setNavigationIconStatus('globe'));
      } else if (!userFilePermissions?.length) {
        dispatch(setNavigationIconStatus('lock-simple'));
      } else {
        dispatch(setNavigationIconStatus('users'));
      }
    }
  }, [userFilePermissions, activeAccessOption, dispatch, isLoading]);

  return (
    <>
      <div className='mb-3 general-invite' data-cy='general-invite'>
        <span className='invite-by-email-label'>General Access</span>
        <div className='flex gap-2'>
          <div className='w-full grow'>
            <Select
              id='change-permissions'
              isSearchable={false}
              classNamePrefix='permissions-select'
              value={activeAccessOption}
              onChange={onSelectChange}
              options={handleAccessOption()}
              components={{
                IndicatorSeparator: () => null,
              }}
              isDisabled={currentUser !== owner}
            />
          </div>
          {isTeamAccess() && (
            <div className='w-36'>
              <SelectTailwind
                dataCy='change-individual-permissions'
                value={initialOrganizationPermission}
                onChange={(value) => {
                  onOrganizationPermissionChange(value);
                }}
                options={restrictedPermissionsOptions.filter((option) => option.value >= 0)}
                buttonClassName='w-36 !h-[38px]'
              />
            </div>
          )}
        </div>
        <span className='invite-by-email-hint'>
          {isPublicAccess()
            ? 'Anyone on the internet with the link can view'
            : isTeamAccess()
            ? 'Only members of your team can view'
            : 'Only people invited can view or edit'}
        </span>
      </div>
      <div className='mb-3 invite-by-email'>
        <span className='invite-by-email-label'>Invite by email</span>
        <div className='flex gap-2'>
          <div className='w-full grow'>
            <CustomMultiEmailInput
              autocompleteList={autocompleteList}
              placeholder={emailTags.length ? '' : 'Enter email address'}
              onInputChange={onEmailsChange}
              isValidEmail={validateEmail}
              emailTags={emailTags}
            />
          </div>
          {emailTags.length ? (
            <div className='w-36'>
              <SelectTailwind
                dataCy='change-individual-permissions'
                value={initialUserPermission}
                onChange={(value) => {
                  setInitialUserPermission(value);
                }}
                options={restrictedPermissionsOptions.filter((option) => option.value >= 0)}
                buttonClassName='w-36 !h-10'
              />
            </div>
          ) : null}
        </div>
      </div>

      {emailTags.length > 0 && (
        <textarea
          data-cy='textarea-message-invite-by-email'
          value={message}
          maxLength='200'
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Write a message...'
          className='invite-by-email-message'
        />
      )}
      <div className='shared-with' hidden={emailTags.length !== 0}>
        <p className='invite-by-email-label'>Shared With</p>
        <div className='shared-with-user owner'>
          <p>
            {owner} {owner === currentUserEmail && <span style={{ fontWeight: 600 }}>(You)</span>}
          </p>
          <p>Owner</p>
        </div>
        {filterHighestPermissionId(sortByUserId(userFilePermissions)).map(
          (userFilePermission, index) => {
            return (
              <div
                data-cy={`shared-with-row-index-${index + 1}`}
                className='my-2 shared-with-user'
                key={index}
              >
                <p data-cy='user-id' className='m-0 overflow-hidden break-words'>
                  {userFilePermission.user_id}
                </p>
                <div data-cy='permission'>
                  <SelectTailwind
                    dataCy='change-individual-permissions'
                    value={userFilePermission.permission_id}
                    onChange={(value) => {
                      handlePermissionChange(value, userFilePermission);
                    }}
                    options={restrictedPermissionsOptions}
                    buttonClassName='w-36'
                  />
                </div>
              </div>
            );
          }
        )}
        <Modal
          isOpen={isOpenPopup}
          size='small'
          titleSize='small'
          title='Update permission?'
          onClose={() => setIsOpenPopup(false)}
        >
          <p>This will change your permissions. You won&apos;t be able to share</p>
          <div className='flex justify-end gap-2 mt-4'>
            <Button
              variant='outline'
              color='oceanBlue'
              onClick={() => setIsOpenPopup(false)}
              dataCy='cancel-button'
            >
              Cancel
            </Button>
            <Button color={'oceanBlue'} onClick={submitPermissionsChange} dataCy='submit-button'>
              Okay
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InvitePeopleBody;
