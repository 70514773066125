import React from 'react';
import { IfConditionsGroup } from './IfConditionsGroup';
import { Button } from '@/Components/UI/Button/Button';

const IfConditionsBlock = ({
  columns,
  filterModel,
  onChangeFilterType,
  onFilterChange,
  setAnyFilterData,
  modalHeaderRef,
  modalBodyScrollTop,
  addNewFilter,
  thenRowValue,
  onChangeThenRowValue,
  elseRowValue,
  onChangeElseRowValue,
  ifGroupIndex,
  onDeleteConditionsGroup,
  isExpanded,
  isLastConditionGroup,
  toggleGroupView,
  totalNumberOfConditionsGroup,
  ref,
  isValidConditions,
}) => {
  return (
    <div className={`rounded overflow-hidden border border-ui-200 ${ifGroupIndex && 'mt-3'}`}>
      <div className='flex items-center justify-between border-b bg-ui border-ui-200'>
        <div className='flex items-center'>
          <Button
            onClick={() => toggleGroupView(ifGroupIndex)}
            variant='ghost'
            size='medium'
            dataCy='remove-btn'
            iconName={isExpanded ? 'caret-up' : 'caret-down-regular'}
            color='midnight'
          />
          <span
            data-cy={`condition-group-${ifGroupIndex + 1}`}
            className='text-sm font-semibold'
          >{`Condition Group ${ifGroupIndex + 1}`}</span>
        </div>
        {totalNumberOfConditionsGroup > 1 && (
          <Button
            onClick={() => onDeleteConditionsGroup(ifGroupIndex)}
            variant='ghost'
            size='large'
            dataCy='remove-btn'
            iconName='trash'
            color='violetWeb'
          />
        )}
      </div>
      <div className={`pt-3 pb-6 pl-2 ${!isExpanded && 'hidden'}`} ref={ref}>
        <IfConditionsGroup
          columns={columns}
          filterModel={filterModel}
          onChangeFilterType={onChangeFilterType}
          onFilterChange={onFilterChange}
          setAnyFilterData={setAnyFilterData}
          modalHeaderRef={modalHeaderRef}
          modalBodyScrollTop={modalBodyScrollTop}
          addNewFilter={addNewFilter}
          thenRowValue={thenRowValue}
          onChangeThenRowValue={onChangeThenRowValue}
          elseRowValue={elseRowValue}
          onChangeElseRowValue={onChangeElseRowValue}
          ifGroupIndex={ifGroupIndex}
          isLastConditionGroup={isLastConditionGroup}
          isValidConditions={isValidConditions}
        />
      </div>
    </div>
  );
};

export default IfConditionsBlock;
