import { enrichmentTemplatesSliceName } from '@/redux/constants';
import { setChosenEnrichmentTemplate } from '@/redux/reducers/enrichmentTemplates';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truncateText } from '../../../../Utils/enrichmentUtils/EnrichmentUtils';
import { EnrichmentField } from './EnrichmentField';
import { getEnrichmentByRecipeId } from './recipes/index.js';

export const EnrichmentFieldsForm = () => {
  const dispatch = useDispatch();
  const { chosenEnrichmentTemplate, userInputValues, enrichmentId } = useSelector(
    (state) => state[enrichmentTemplatesSliceName]
  );

  useEffect(() => {
    dispatch(setChosenEnrichmentTemplate(getEnrichmentByRecipeId(enrichmentId)));
  }, []);
  const { endpoint_name, endpoint_description } = chosenEnrichmentTemplate || {};
  return (
    <div className='pl-[16px] pr-[16px]'>
      <div>
        <div
          data-cy='enrichment-name'
          className='flex items-center mb-[5px] font-extrabold  tracking-[-.02em] text-lg leading-[23px] font-overpass'
        >
          {endpoint_name}
        </div>
        <div
          data-cy='enrichment-description'
          className='text-xs font-normal leading-5 font-overpass mb-[12px] text-[#474747] overflow-hidden'
        >
          {truncateText(endpoint_description, 150)}
          {endpoint_name === 'Google Search Scrape' ? (
            <div className='overflow-hidden text-xs font-normal leading-5 font-overpass text-ocean-blue'>
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.scraperapi.com/?fp_ref=gigasheet'
              >
                Register for an API key.
              </a>
            </div>
          ) : null}
        </div>
      </div>
      {userInputValues?.map((field) => (
        <div key={field.id}>
          <EnrichmentField field={field} />
        </div>
      ))}
    </div>
  );
};
