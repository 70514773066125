import { useDispatch, useSelector } from 'react-redux';

import { modalsSliceName } from '@/redux/constants';
import { hideModal as hide } from '@/redux/reducers/modalsSlice';
const withModal = ({ name, destroyOnHide = true }) => {
  return (Modal) => (ownProps) => {
    const dispatch = useDispatch();
    const { modalsQueue } = useSelector((state) => state[modalsSliceName]);
    const modalState = modalsQueue[modalsQueue.length - 1];

    const hideModal = () => dispatch(hide({ name }));

    const { name: currentModalName, props = {} } = modalState || {};
    const shouldShowModal = name === currentModalName;

    if (!shouldShowModal && destroyOnHide) return null;

    return <Modal {...ownProps} {...props} show={shouldShowModal} hideModal={hideModal} />;
  };
};

export default withModal;
