export const replaceHeadersWithColumnIds = (text, columnDefs) => {
  let newText = text;
  const placeholderPrefix = '****';

  const startingIndices = [];
  let indexOccurence = newText.indexOf(placeholderPrefix, 0);
  while (indexOccurence >= 0) {
    startingIndices.push(indexOccurence);
    indexOccurence = newText.indexOf(placeholderPrefix, indexOccurence + 1);
  }

  startingIndices
    .reduce((accumulator, currentValue, currentIndex, array) => {
      if (currentIndex % 2 === 0) {
        accumulator.push(array.slice(currentIndex, currentIndex + 2));
      }
      return accumulator;
    }, [])
    .map((e) => {
      const _startIndex = e[0] + 4;
      const [, _endIndex] = e;
      const _header = newText.slice(_startIndex, _endIndex);
      return {
        startIndex: _startIndex,
        endIndex: _endIndex,
        header: _header,
        colId: columnDefs.find((c) => c.headerName === _header).colId,
      };
    })
    .reverse()
    .forEach((position) => {
      newText =
        newText.slice(0, position.startIndex) +
        `${position.colId}` +
        newText.slice(position.endIndex);
    });

  return escapeSpecialChars(newText);
};

export const escapeSpecialChars = (s) => {
  return JSON.stringify(s).slice(1, -1);
};
