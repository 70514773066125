import DOMPurify from 'dompurify';

const regexURL = /\[.*\]\((http.*)\)/;
const regexTextInsideBrackets = /\[(.*)\]\(http.*\)/;
// This regexp matches things that look like this:
// [anything except a close bracket](http then anything except a close parenthesis)
const markdownLinkElements = /(\[[^\]]*\]\(http[^\)]*\))/g;

export const getUrlInsideBrackets = (str) => {
  if (!str) {
    return '';
  }

  const urlMatch = str.match(regexURL);
  return urlMatch.length > 0 ? urlMatch[1] : '';
};

export const getLinkTextInsideBrackets = (str) => {
  if (!str) {
    return '';
  }

  const textMatch = str.match(regexTextInsideBrackets);
  return textMatch.length > 0 ? textMatch[1] : '';
};

const replaceLink = (e) => {
  const url = getUrlInsideBrackets(e);
  const text = getLinkTextInsideBrackets(e);
  return `<a href=${url} target='_blank' rel='noopener noreferrer'>${text}</a>`;
};

export const convertStringToMarkdownList = (str) => {
  if (!str) {
    return '';
  }

  const sanitizer = DOMPurify.sanitize;
  const content = str.replace(markdownLinkElements, replaceLink);
  return sanitizer(content, { ADD_ATTR: ['target'] });
};
