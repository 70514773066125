import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';
import SearchSelect from '@/Components/SearchSelect/SearchSelect';

import { useFunctionModal } from '@/hooks/useFunctionModal.ts';

import { spreadsheetSliceName } from '@/redux/constants';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';

import { post } from '@/Utils/API';
import { MODAL_EXPLODE_DATE, MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import Modal from '@/Components/UI/Modal/Modal';
import TimezonePicker from '@/Components/UI/TimezonePicker/TimezonePicker';
import { Button } from '@/Components/UI/Button/Button';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const ExplodeDateModal = ({ show, hideModal, targetColumnId }) => {
  const dispatch = useDispatch();
  const currentFile = useCurrentFile();
  const { columnDefs } = useSelector((state) => state[spreadsheetSliceName]);

  const [timezoneValue, setTimezoneValue] = useState('Etc/UTC');

  const { options, targetColumn, setTargetColumn } = useFunctionModal({
    columnDefs,
    targetColumnId,
  });

  const onlyDataTypeOptions = options.filter(
    ({ type }) => type === 'DateTime64' || type === 'DateTime'
  );

  const explodeDate = async () => {
    setTargetColumn(null);
    dispatch(hideAllModals());
    if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
      return dispatch(showModal({ name: MODAL_UPGRADE_REQUIRED }));
    }
    dispatch(setIsInsertingNewColumn(true));

    try {
      const uuid = getFileUuidFromPath();
      await post(`extract-date/split-date/${uuid}/${targetColumn.value}`, {
        timezone: timezoneValue,
      });
      await dispatch(fetchOpenedFile(uuid));

      dispatch(
        addMixpanelEvent({
          eventName: 'Function Used',
          eventProps: { FunctionName: 'Explode date' },
          userIncrementName: '# of function use',
        })
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };

  const closeExplodeDateModal = () => {
    hideModal();
    setTargetColumn(null);
  };

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title='Explode Date'
      articleID='69000814031'
      iconName='clock'
    >
      <div className='extract-day-of-week-container'>
        <div className='mb-4'>
          <InputField label='Choose Date Column'>
            <SearchSelect
              options={onlyDataTypeOptions}
              onChange={setTargetColumn}
              value={targetColumn}
              getOptionLabel={(option) => option.label}
            />
          </InputField>
        </div>
        <div className='relative mb-4'>
          <TimezonePicker
            label='Choose time zone offset'
            value={timezoneValue}
            onChange={setTimezoneValue}
          />
        </div>
      </div>
      <hr className='mt-10 mb-5 solid'></hr>
      <div className='flex justify-end gap-4'>
        <Button
          variant='ghost'
          color='shadow'
          size='large'
          onClick={closeExplodeDateModal}
          dataCy='cancel-btn'
        >
          Cancel
        </Button>
        <Button
          color='oceanBlue'
          size='large'
          onClick={explodeDate}
          disabled={targetColumn === null}
          dataCy='save-btn'
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_EXPLODE_DATE })(ExplodeDateModal);
