import {
  JSXElementConstructor,
  ReactElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ReactDOMServer from 'react-dom/server';
import DOMPurify from 'dompurify';
import clsx from 'clsx';
import ThreeDotsLoader from '@/Components/Loader/ThreeDotsLoader/ThreeDotsLoader';

interface SheetAssistantChatMessageProps {
  messageContent: string | ReactElement<any, string | JSXElementConstructor<any>>;
  messageRole: 'user' | 'assistant';
  assistantResponseIsLoading?: boolean;
  className?: string;
  animationClass?: string;
  isMobileExperienceActive: boolean;
  shouldSanitize?: boolean;
}

const SheetAssistantChatMessage = memo(
  forwardRef<HTMLDivElement, SheetAssistantChatMessageProps>(
    (
      {
        messageContent,
        messageRole,
        assistantResponseIsLoading = false,
        className = '',
        animationClass = '',
        isMobileExperienceActive,
        shouldSanitize = false,
      }: SheetAssistantChatMessageProps,
      forwardedRef
    ) => {
      const [activeAnimationClass, setActiveAnimationClass] = useState(animationClass);

      const internalRef = useRef<HTMLDivElement | null>(null);

      const handleAnimationEnd = () => {
        setActiveAnimationClass('');
      };

      const setRef = useCallback(
        (node) => {
          internalRef.current = node;

          if (typeof forwardedRef === 'function') {
            forwardedRef(node);
          } else if (forwardedRef) {
            forwardedRef.current = node;
          }
        },
        [forwardedRef]
      );

      useEffect(() => {
        const element = internalRef.current;
        if (element) {
          element.addEventListener('animationend', handleAnimationEnd);
          return () => {
            element.removeEventListener('animationend', handleAnimationEnd);
          };
        }
      }, [internalRef]);

      const replaceNewLinesWithBreaks = (text: string) => {
        return text.replace(/\n/g, '<br />');
      };

      const createMarkup = (
        content: string | ReactElement<any, string | JSXElementConstructor<any>>
      ) => {
        if (!content) return { __html: '' };

        let htmlContent;
        if (typeof content === 'string') {
          // Replace '\n' with '<br />' regardless of sanitization
          const textWithBreaks = replaceNewLinesWithBreaks(content);
          htmlContent = shouldSanitize ? DOMPurify.sanitize(textWithBreaks) : textWithBreaks;
        } else {
          const htmlString = ReactDOMServer.renderToString(content);
          const formattedHtmlString = replaceNewLinesWithBreaks(htmlString);
          htmlContent = shouldSanitize
            ? DOMPurify.sanitize(formattedHtmlString)
            : formattedHtmlString;
        }

        return { __html: htmlContent };
      };

      const getMessageClassNames = (className: string) => {
        const baseClasses = 'm-1 bg-white border rounded typography-body !text-ui-secondary px-3';
        const mobileClasses = isMobileExperienceActive ? 'max-h-fit' : '';
        const floatDirection = messageRole === 'user' ? 'self-end' : 'self-start';

        return clsx(baseClasses, mobileClasses, floatDirection, className, activeAnimationClass);
      };

      const Loader = () => (
        <div ref={setRef} className={clsx('w-[20%] py-4', getMessageClassNames(className))}>
          <ThreeDotsLoader text='' />
        </div>
      );

      const Message = () => {
        return (
          <div
            ref={setRef}
            className={clsx('w-[85%] py-2 break-words', getMessageClassNames(className))}
          >
            {shouldSanitize ? (
              <div dangerouslySetInnerHTML={createMarkup(messageContent)} />
            ) : typeof messageContent === 'string' ? (
              <div
                dangerouslySetInnerHTML={{ __html: replaceNewLinesWithBreaks(messageContent) }}
              />
            ) : (
              messageContent
            )}
          </div>
        );
      };

      return assistantResponseIsLoading ? <Loader /> : <Message />;
    }
  )
);

export default SheetAssistantChatMessage;
