import { memo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { enrichmentTemplatesSliceName } from '@/redux/constants';
import { setEnrichmentCategory } from '@/redux/reducers/enrichmentTemplates';

import clsx from 'clsx';
import Icon from '@/Components/UI/Icon/Icon';

interface EnrichmentCategoryItemProps {
  category: { name: string; icon: string };
  setSearchValue: (value: string) => void;
}

const commonEnrichmentCategories = [
  { name: 'Explore', icon: 'infinity' },
  { name: 'API Key Required', icon: 'key' },
  { name: 'Keyless Entry', icon: 'lock-key-open' },
];

const specificEnrichmentCategories = [
  { name: 'AI & Search', icon: 'magic-wand' },
  { name: 'Companies', icon: 'buildings' },
  { name: 'Cybersecurity', icon: 'shield-star' },
  { name: 'Email', icon: 'envelope-simple' },
  { name: 'People', icon: 'users-simple' },
  { name: 'Places', icon: 'map-pin' },
  { name: 'Translation', icon: 'translate' },
  { name: 'Custom', icon: 'sliders' },
];

export const EnrichmentCategoryItem: FC<EnrichmentCategoryItemProps> = ({
  category,
  setSearchValue,
}) => {
  const dispatch = useDispatch();

  const { enrichmentCategory } = useSelector(
    (state: { [enrichmentTemplatesSliceName: string]: { enrichmentCategory: string } }) =>
      state[enrichmentTemplatesSliceName]
  );

  const selectEnrichCategory = (categoryName: string) => {
    setSearchValue('');
    dispatch(setEnrichmentCategory(categoryName));
  };

  return (
    <div
      role='button'
      onKeyUp={() => {}}
      tabIndex={0}
      onClick={() => selectEnrichCategory(category.name)}
      className={clsx(
        'flex flex-no-wrap items-center cursor-pointer h-[32px] mb-[2px] rounded pl-[8px] hover:bg-[#EBEBEB]',
        enrichmentCategory === category.name && 'bg-[#EBEBEB]'
      )}
    >
      <span className='inline-block align-middle'>
        <Icon name={category.icon} color='#0F085B' />
      </span>
      <div className='h-[17px] inline-block align-middle ml-[6px] text-sm text-shadow-700 text-[#0F085B]'>
        {category.name}
      </div>
    </div>
  );
};

interface EnrichmentNavigationProps {
  setSearchValue: (value: string) => void;
}

export const EnrichmentNavigation: FC<EnrichmentNavigationProps> = memo(({ setSearchValue }) => {
  return (
    <div>
      {commonEnrichmentCategories.map((category) => (
        <EnrichmentCategoryItem
          key={category.name}
          category={category}
          setSearchValue={setSearchValue}
        />
      ))}
      <div className='w-full border-t border-[#d6d6d6] h-[16px] mt-[14px]' />
      {specificEnrichmentCategories.map((category) => (
        <EnrichmentCategoryItem
          key={category.name}
          category={category}
          setSearchValue={setSearchValue}
        />
      ))}
    </div>
  );
});
