import { useEffect, useRef } from 'react';

import DotsLoader from '@/Components/Loader/DotsLoader';
import UpgradeMessage from '@/Components/UpgradeMessage/UpgradeMessage';

import { Link } from '@/Components/UI/Button/Button';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import { Select } from '@/Components/UI/Form/Select/Select';
import TextInput from '@/Components/UI/Form/TextInput/TextInput';
import Icon from '@/Components/UI/Icon/Icon';
import { LINK_SAVE_AS_NEW_SHEET } from '@/Utils/constants';

const MakeCopyFile = ({
  folder,
  isFolderLoading,
  setFolder,
  fileName,
  setName,
  isKeepShareSettings,
  setKeepShareSettings,
  currentView,
  flattenCurrentView,
  options,
  isFileShared,
  isCanExportFilteredGroupedRolePaywall = null,
  isSaveResultsView,
}) => {
  const fileNameRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    setTimeout(() => {
      fileNameRef.current?.select();
    }, 0);

    return () => {
      setKeepShareSettings(false);
      flattenCurrentView(false);
    };
  }, []);

  return (
    <div className=''>
      {isCanExportFilteredGroupedRolePaywall && <UpgradeMessage />}

      <div className='pb-3'>
        <TextInput
          className='w-full'
          ref={fileNameRef}
          label='Name'
          placeholder='Name'
          onChange={onNameChange}
          value={fileName}
          dataCy='make-copy-file-name-input'
          disabled={!isSaveResultsView}
        />
      </div>

      <div className='pb-3'>
        {isFolderLoading ? (
          <div style={{ marginTop: '23px', marginBottom: '22px' }}>
            <DotsLoader />
          </div>
        ) : (
          <Select
            label='Folder'
            value={options.find((i) => i.value === folder)?.value}
            options={options}
            onChange={(option) => setFolder(options.find((i) => i.value === option)?.value)}
            placeholder='Select Folder'
          />
        )}
      </div>

      <div className='flex flex-col gap-3 pt-1'>
        {isSaveResultsView && !isFileShared && (
          <div className=''>
            <CheckboxInput
              label='Keep Current Share Settings'
              onChange={() => setKeepShareSettings(!isKeepShareSettings)}
              checked={isKeepShareSettings}
            />
          </div>
        )}
        {isSaveResultsView && (
          <div className='flex flex-row items-center'>
            <CheckboxInput
              label='Flatten Groups'
              onChange={() => flattenCurrentView(!currentView)}
              checked={currentView}
            />
            <Link to={LINK_SAVE_AS_NEW_SHEET} target='__blank' className='pb-[2px]'>
              <Icon name='info' />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MakeCopyFile;
