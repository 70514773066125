import { useMemo, useState } from 'react';

import { StatusCodes } from 'http-status-codes';
import { useDispatch, useSelector } from 'react-redux';

import { post } from '@/Utils/API';
import Modal from '@/Components/UI/Modal/Modal';
import { Button } from '@/Components/UI/Button/Button';
import { spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { Select } from '@/Components/UI/Form/Select/Select';
import showToast from '@/Components/Toast/showToastTemplate';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import { fetchOpenedFile } from '@/redux/reducers/datasetSlice';
import { getFileUuidFromPath } from '@/Utils/getFileUuidFromPath';
import InputField from '@/Components/UI/Form/InputField/InputField';
import { hideAllModals, showModal } from '@/redux/reducers/modalsSlice';
import { setIsInsertingNewColumn } from '@/redux/reducers/spreadsheetSlice';
import {
  MODAL_DATA_ENRICHMENT,
  MODAL_EXCEEDS_LIMITS,
  MODAL_IP_GEOLOCATION_ENRICHMENT,
  MODAL_UPGRADE_REQUIRED,
  TOAST_TEXT_ENRICHMENT_ERROR,
  TOAST_TEXT_ENRICHMENT_RUNNING_ERROR,
  TOAST_TEXT_INCORRECT_API_KEY_ERROR,
  TOAST_TEXT_QUOTA_EXCEEDED_ERROR,
  TOAST_TYPE_ERROR,
} from '@/Utils/constants';

import withModal from '../../withModalHOC';
import { AvailableCredits } from './AvailableCredits';
import { useCurrentFile } from '@/hooks/useCurrentFile';

function IPGeolocationModal({ show }) {
  const {
    columnDefs,
    clientState: { filterModel },
  } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();

  const [targetColumn, setTargetColumn] = useState(null);
  const [step, setStep] = useState(1);

  const options = useMemo(() => {
    return columnDefs
      .map((colDef) => ({ label: colDef.headerName, value: colDef.field, type: colDef.fieldType }))
      .filter(({ type }) => type === 'IPv4');
  }, [columnDefs]);
  const dispatch = useDispatch();

  const onChangeTargetColumn = (newTargetColumn) => {
    setTargetColumn(newTargetColumn);
  };

  const enrich = async () => {
    dispatch(hideAllModals());
    dispatch(setIsInsertingNewColumn(true));

    const uuid = getFileUuidFromPath();
    const payload = {
      filterModel: { filterModel: filterModel || null },
      enrichments: [
        {
          provider: 'geo',
          type: 'IP',
          key: null,
        },
      ],
    };
    dispatch(
      addMixpanelEvent({
        eventName: 'Enrichment Used',
        eventProps: { 'Enrichment Types': 'geo' },
        userIncrementName: '# of enrichment use',
      })
    );

    let endpoint = '';
    try {
      endpoint = `enrichments/${uuid}/${targetColumn}`;
      const enrichmentResult = await post(endpoint, payload);
      dispatch(fetchOpenedFile(uuid));
      if (!enrichmentResult.Success) {
        if (enrichmentResult?.status === StatusCodes.FORBIDDEN) {
          return showToast({
            type: TOAST_TYPE_ERROR,
            text: enrichmentResult.Message || TOAST_TEXT_INCORRECT_API_KEY_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        } else if (enrichmentResult.status === StatusCodes.TOO_MANY_REQUESTS) {
          return showToast({
            type: TOAST_TYPE_ERROR,
            text: enrichmentResult.Message || TOAST_TEXT_QUOTA_EXCEEDED_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        } else {
          return showToast({
            type: TOAST_TYPE_ERROR,
            text: enrichmentResult.Message || TOAST_TEXT_ENRICHMENT_RUNNING_ERROR,
            errorContext: enrichmentResult,
            endpoint: endpoint,
            payload: payload,
            fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
          });
        }
      }
    } catch (err) {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: err.message || TOAST_TEXT_ENRICHMENT_ERROR,
        errorContext: err,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
      throw new Error(err);
    } finally {
      dispatch(setIsInsertingNewColumn(false));
    }
  };
  const ipGeoLocationEnrich = () => {
    return (
      <div className='enrichment-service-row'>
        <InputField label='IP Column' />
        <Select
          value={targetColumn || ''}
          color='oceanBlueLight'
          options={options}
          onChange={onChangeTargetColumn}
          isSmall={true}
        />

        <div className='p-4 mt-4 text-right border-t border-ui-200'>
          <Button
            color='shadow'
            dataCy='reset-field-btn'
            className='float-left'
            onClick={() => {
              dispatch(showModal({ name: MODAL_DATA_ENRICHMENT }));
            }}
          >
            Back
          </Button>

          <Button
            variant='ghost'
            color='shadow'
            dataCy='cancel-btn'
            className='mr-[16px]'
            onClick={() => {
              dispatch(hideAllModals());
            }}
          >
            Cancel
          </Button>

          <Button
            color={'oceanBlue'}
            dataCy='save-btn'
            onClick={() => {
              if (!currentFile?.WithinQuota || currentFile?.OverRowQuota) {
                return dispatch(
                  showModal({
                    name:
                      currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
                        ? MODAL_UPGRADE_REQUIRED
                        : MODAL_EXCEEDS_LIMITS,
                  })
                );
              }
              setStep(2);
            }}
            disabled={!targetColumn}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title='Data Enrichments'
      iconName='atom'
      isOpen={show}
      onClose={() => dispatch(hideAllModals())}
      articleID={69000774345}
      shouldCloseOnBackgroundClick={false}
    >
      {step === 1 && ipGeoLocationEnrich()}
      {step === 2 && (
        <AvailableCredits
          onBack={() => setStep(1)}
          onCancel={() => {
            dispatch(hideAllModals());
          }}
          onRun={enrich}
        />
      )}
    </Modal>
  );
}

export default withModal({ name: MODAL_IP_GEOLOCATION_ENRICHMENT })(IPGeolocationModal);
