import { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { getFilterType } from '@/Utils/getFilterType';
import { LINK_SUPPORT_DOCS_REGEX } from '@/Utils/constants';

import { stylesMultiValue, stylesMultiValueNewDesign } from '@/helpers/filterPanelHelper';

import { searchSVG } from '@/Assets/icons';

import './style/index.scss';

const FilterPanelMultiValue = ({
  type,
  showRegexInvalidBlock,
  defaultValue,
  onChange,
  isMultiple,
  classes,
  disabled,
  setIsDisabledApplyButton,
  cypressTextFieldName = '',
  currFieldType,
  indexInFilterModel,
  setAnyFilterData,
  isBlurAction,
  ifCondition,
  isValidConditions = true,
  customProperties = {},
}) => {
  const [value, setValue] = useState(null);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [placeholder, setPlaceholder] = useState('');

  const _classes = ifCondition ? stylesMultiValueNewDesign() : classes;

  const onSelectChange = (newValue) => {
    let inputValue = newValue;
    if (getFilterType(currFieldType) === 'number') {
      inputValue = Array.isArray(newValue)
        ? newValue?.map((value) => value.trim())
        : newValue.trim();
    }
    setValue(inputValue);
    onChange(inputValue);
  };

  const onBlurAction = (e) => {
    const { value: newValue } = e.target;
    if (newValue) {
      onSelectChange([...new Set([...(value || []), newValue])]);
      if (Number(newValue)) {
        setIsValidNumber(false);
      }
    }
  };

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    let newPlaceholder = '';
    const inRangeType = customProperties?.inRangeType;
    if (!value?.length) {
      if (type === 'inRange') {
        switch (inRangeType) {
          case 'from':
            newPlaceholder = 'Value From';
            break;
          case 'to':
            newPlaceholder = 'Value To';
            break;
        }
      } else {
        newPlaceholder = 'Value';
      }
    }
    setPlaceholder(newPlaceholder);
  }, [customProperties, value]);

  useEffect(() => {
    if (currFieldType?.includes('Int') && value?.length) {
      setIsValidNumber(
        Array.isArray(value)
          ? [...value].every((i) => Number.isInteger(Number(i)))
          : Number.isInteger(Number(value))
      );
    } else if (currFieldType?.includes('Float') && value?.length) {
      setIsValidNumber(Array.isArray(value) ? [...value].every((i) => !isNaN(i)) : !isNaN(value));
    } else setIsValidNumber(true);
  }, [currFieldType, value]);

  useEffect(() => {
    if (!isValidNumber || showRegexInvalidBlock) setIsDisabledApplyButton(true);
  }, [isValidNumber, showRegexInvalidBlock]);

  const flagInvalidInput = showRegexInvalidBlock || !isValidNumber;

  return (
    <>
      <Autocomplete
        freeSolo
        className={`multi-value-select ${ifCondition && '!w-40 !static'}`}
        multiple={isMultiple}
        disabled={disabled}
        defaultValue={defaultValue}
        selectOnFocus={true}
        value={value || ''}
        limitTags={1}
        options={[]}
        onBlur={(event) => {
          if (isBlurAction && isMultiple) {
            onBlurAction(event);
          }
        }}
        classes={{
          root: `${flagInvalidInput ? _classes.rootError : _classes.root}`,
          focused: _classes.focused,
          paper: _classes.paper,
          input: `${_classes.input} ${ifCondition ? 'ifFunction' : ''}`,
          inputRoot: `${flagInvalidInput ? _classes.inputRootError : _classes.inputRoot} ${
            !isValidConditions &&
            !value &&
            '!border-violet-web-700 !shadow-[0_0_0_2px_rgb(252,230,255)]'
          }  ${ifCondition ? 'ifFunction' : ''}`,
          tag: _classes.tag,
          endAdornment: _classes.endAdornment,
        }}
        getOptionLabel={(option) => option.toString()}
        onChange={(event, newValue) => {
          if (isMultiple) onSelectChange(newValue);
        }}
        onInputChange={(event, newValue) => {
          setIsDisabledApplyButton(!newValue?.length);
          setAnyFilterData({ newValue, index: indexInFilterModel });
          if (!isMultiple) onSelectChange(newValue);
        }}
        renderTags={(values, getTagProps) => {
          if (Array.isArray(values) && values) {
            return values.map((option, index) => (
              <div className='multi-value-filter-tags' key={index}>
                {index > 0 ? 'OR' : ''}
                <Chip
                  size='small'
                  className='multi-value-tags'
                  label={option}
                  {...getTagProps({ index })}
                />
              </div>
            ));
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            data-cy={cypressTextFieldName}
            autoFocus={indexInFilterModel === 0}
          />
        )}
      />
      <div className='relative'>
        {isMultiple && <span className={'multi-value-block'}>Press ENTER to add value(s)</span>}
        {showRegexInvalidBlock && (
          <div className={'validation-block'}>
            Invalid expression
            <a
              href={LINK_SUPPORT_DOCS_REGEX}
              target='_blank'
              rel='noreferrer'
              className='validation-block-help-text'
            >
              Need Help
              <img alt='help' src={searchSVG.HelpMark} />
            </a>
          </div>
        )}

        {!isValidNumber && <div className='validation-block'>Must be valid number</div>}
      </div>
    </>
  );
};

export default withStyles(stylesMultiValue)(FilterPanelMultiValue);
