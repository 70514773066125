import { useEffect, useRef, useState } from 'react';

import { Button } from '@/Components/UI/Button/Button';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import clsx from 'clsx';

const SheetAssistantDialogSection = ({
  assistantResponseIsLoading,
  sendRequest,
  renderFeedbackFooter,
}) => {
  const textAreaRef = useRef(null);
  const [isTextAreaExpended, setIsTextAreaExpended] = useState(false);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [scrollBarWidth, setScrollBarWidth] = useState(false);
  const [userInput, setUserInput] = useState('');

  const minUserInputLength = 35;

  useEffect(() => {
    const textarea = textAreaRef?.current;
    if (textarea) {
      if (!isTextAreaExpended || userInput?.length < minUserInputLength) {
        setIsTextAreaExpended(textarea?.scrollHeight > textarea?.clientHeight);
      }
    }

    if (isTextAreaExpended) {
      setHasScrollBar(textarea?.scrollHeight > textarea?.clientHeight);
    }

    if (hasScrollBar) {
      setScrollBarWidth(textarea?.offsetWidth - textarea?.clientWidth);
    }
  }, [userInput, isTextAreaExpended, hasScrollBar]);

  return (
    <div className='flex flex-col gap-2'>
      <>
        <div className='relative flex'>
          <div className='flex-grow'>
            <Textarea
              ref={textAreaRef}
              placeholder='Show me...'
              disabled={assistantResponseIsLoading}
              className={clsx(
                '!text-ui-secondary w-full h-[38px] placeholder:italic resize-none pr-9 focus:!border-ocean-blue focus:!ring-[2px] focus:!ring-ocean-blue-100',
                isTextAreaExpended && '!h-[80px]'
              )}
              onChange={(e) => {
                setUserInput(e.target.value.replace(/[\n\r]/g, ''));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !assistantResponseIsLoading && userInput.trim().length) {
                  sendRequest(userInput);
                  setUserInput('');
                }
              }}
              value={userInput}
            />
          </div>
          <div
            className={clsx('absolute flex items-end right-0 bottom-0')}
            style={{ right: hasScrollBar && scrollBarWidth ? `${scrollBarWidth}px` : 0 }}
          >
            <Button
              disabled={assistantResponseIsLoading || userInput.trim().length === 0}
              onClick={() => {
                sendRequest(userInput);
                setUserInput('');
              }}
              iconName='paper-plane-tilt'
              size='medium'
              color='oceanBlue'
              variant='ghost'
              className='!h-[38px]'
            />
          </div>
        </div>
        {renderFeedbackFooter()}
      </>
    </div>
  );
};

export default SheetAssistantDialogSection;
