const footerMenu = [
  {
    href: 'https://www.gigasheet.com/terms-of-use',
    target: '_blank',
    rel: 'noopener noreferrer',
    onClick: (event) => event.stopPropagation(),
    text: 'Terms of Use',
  },
  {
    href: 'https://www.gigasheet.com/privacy',
    target: '_blank',
    rel: 'noopener noreferrer',
    onClick: (event) => event.stopPropagation(),
    text: 'Privacy',
  },
  {
    href: 'https://www.gigasheet.com/security',
    target: '_blank',
    rel: 'noopener noreferrer',
    onClick: (event) => event.stopPropagation(),
    text: 'Security',
  },
];
export default footerMenu;
