import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import withModal from '@/Components/Modals/withModalHOC';

import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';

import { MODAL_EXPORT_SPREADSHEET, URL_DATASETS } from '@/Utils/constants';

import { Button, Link } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';
import Modal from '@/Components/UI/Modal/Modal';
import UpgradeMessage from '@/Components/UpgradeMessage/UpgradeMessage';
import './style/index.scss';

function ExportFileModal({ show, hideModal, isCanExportFilteredGroupedRolePaywall = false }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && isCanExportFilteredGroupedRolePaywall) {
      dispatch(
        addMixpanelEvent({
          eventName: 'File Export (upgrade to make a copy with filters and groups)',
          eventProps: { 'Export Type': 'All Rows' },
          userIncrementName: '# of exports',
        })
      );
    }
  }, [show, isCanExportFilteredGroupedRolePaywall]);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      title={isCanExportFilteredGroupedRolePaywall ? 'Unable to Download' : 'Download Started'}
      size='small'
    >
      <div className='mt-[-10px]' data-cy='export-file-modal'>
        {isCanExportFilteredGroupedRolePaywall ? (
          <UpgradeMessage text='to unlock filtered and grouped exports,' />
        ) : (
          <div className='flex flex-col gap-4'>
            <div className='text-sm' data-cy='export-file-modal-body'>
              Your data is being exported and will be available to download once it is complete.
            </div>
            <div className='flex flex-row gap-2 p-2 mb-3 text-sm border rounded border-capri-900 bg-capri-100 bg-opacity-80'>
              <Icon name='info' size={18} color='#0079B6' />
              <div>
                A copy of your export will be available in your{' '}
                <Link to={`${window.location.origin}/${URL_DATASETS}`} color='capri' size='medium'>
                  Library
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className='flex items-end justify-end w-full pt-2'>
        <Button onClick={hideModal} dataCy='dismiss-btn'>
          {isCanExportFilteredGroupedRolePaywall ? 'Cancel' : 'Dismiss'}
        </Button>
      </div>
    </Modal>
  );
}

export default withModal({ name: MODAL_EXPORT_SPREADSHEET })(ExportFileModal);
