import withModal from '../withModalHOC';
import { MODAL_ZAPIER_FULL_EXPERIENCE } from '@/Utils/constants';
import Modal from '@/Components/UI/Modal/Modal';
import { useSelector } from 'react-redux';
import { userDataSliceName } from '@/redux/constants';
import './zapier-elements.css';
import { useEffect } from 'react';

type AutomationAPIProps = { show: boolean; hideModal: () => void };
const ZAPIER_FULL_EXPERIENCE_SCRIPT_URL =
  'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';

function loadZapierElementsScript() {
  const script = document.createElement('script');
  script.type = 'module';
  script.src = ZAPIER_FULL_EXPERIENCE_SCRIPT_URL;
  document.head.appendChild(script);
}

const ZapierFullExperience = ({ show, hideModal }: AutomationAPIProps) => {
  const { user } = useSelector((state: any) => state[userDataSliceName]);

  useEffect(() => {
    if (!document.querySelector(`script[src="${ZAPIER_FULL_EXPERIENCE_SCRIPT_URL}"]`)) {
      loadZapierElementsScript();
    }
  }, []);

  return (
    <Modal
      isOpen={show}
      onClose={hideModal}
      titleSize='large'
      titleCss='flex !justify-center'
      size='x-large'
      title='Automation API'
    >
      <div data-cy='zapier-full-experience-modal'>
        <zapier-full-experience
          sign-up-email={user?.email}
          sign-up-first-name={user?.first_name}
          sign-up-last-name={user?.last_name}
          client-id={process.env.REACT_APP_ZAPIER_CLIENT_ID}
          theme='light'
          app-search-bar-display='show'
        />
      </div>
    </Modal>
  );
};

export default withModal({ name: MODAL_ZAPIER_FULL_EXPERIENCE, destroyOnHide: true })(
  ZapierFullExperience
);
