import AlertMessage from '@/Components/UI/AlertMessage/AlertMesage';
import { Button } from '@/Components/UI/Button/Button';
import CheckboxInput from '@/Components/UI/Form/CheckboxInput/CheckboxInput';
import Textarea from '@/Components/UI/Form/Textarea/Textarea';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import UpgradeButton from '../UpgradeAccount/UpgradeButton';

const PublishToWeb = ({
  isLoading,
  isPublic,
  tabs,
  tab,
  selectTab,
  publishSetting,
  setPublishSetting,
  handlePublishSetting,
  isPublishToWebDisabled,
}) => {
  return (
    <div className='flex flex-col gap-2 pb-4'>
      <Tab.Group selectedIndex={tab} onChange={selectTab}>
        <Tab.List className='flex flex-row border-b border-gray-200 rounded-t bg-ui'>
          {tabs.map((tab) => (
            <Tab
              disabled={!isPublic || isPublishToWebDisabled}
              key={tab.label}
              value={tab.label}
              className={({ selected }) =>
                clsx(
                  'whitespace-nowrap py-1 font-normal text-[13px] bg-transparent outline-none flex flex-row items-center px-6 gap-1 justify-center',
                  selected && 'border-b-ocean-blue-600 border-b-2 text-ocean-blue-600',
                  !selected && 'text-ui-secondary border-0'
                )
              }
            >
              {tab.label}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      {isLoading ? (
        <SkeletonLoader header={false} loadingBars={2} />
      ) : (
        <>
          {isPublic && !isPublishToWebDisabled ? (
            <Textarea
              className='h-36 !max-h-36 text-ui-helper !text-[13px] !resize-none break-all'
              value={tabs[tab].content}
              readonly={true}
              disabled={true}
            />
          ) : null}

          <div className='flex gap-2'>
            <CheckboxInput
              onChange={() => setPublishSetting(!publishSetting)}
              checked={publishSetting}
              disabled={isPublishToWebDisabled}
              dataCy='publish-to-web-checkbox'
            />
            <div className='text-[13px] text-ui-secondary'>
              {isPublic && !isPublishToWebDisabled
                ? 'I understand the unpublishing will affect active embeds.'
                : 'By clicking this box I agree to make this sheet public and acknowledge viewers may be able to access the underlying data for the published sheet.'}
            </div>
          </div>
          <Button
            onClick={handlePublishSetting}
            variant={isPublic && !isPublishToWebDisabled ? 'outline' : 'solid'}
            size='small'
            color='oceanBlue'
            disabled={!publishSetting || isPublishToWebDisabled}
            className={clsx('!px-0  mt-2', isPublic && !isPublishToWebDisabled ? '!w-28' : '!w-16')}
            dataCy='publish-to-web-button'
          >
            {isPublic && !isPublishToWebDisabled ? 'Unpublish Sheet' : 'Publish'}
          </Button>

          {isPublishToWebDisabled && (
            <div className='mt-4' data-cy='alert-message-body'>
              <AlertMessage>
                <span
                  data-cy='alert-message-title'
                  className='inline-block text-sm font-semibold mb-2'
                >
                  Embed Your Sheet with a Business Plan
                </span>

                <div className='inline-block text-ui-secondary'>
                  <div className='flex justify-start items-center'>
                    <UpgradeButton
                      text='Upgrade to a Business plan'
                      color=''
                      size=''
                      className='underline'
                      iconName=''
                      dataCy='upgrade-button'
                      useUserRoleCheck={false}
                    />

                    <p>&nbsp;to insert and</p>
                  </div>
                  <p> manipulate your data within an outside platform.</p>
                </div>
              </AlertMessage>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PublishToWeb;
