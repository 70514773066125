import { Provider } from 'react-redux';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
// Do not move these lines of code below the App component import,
// = import 'ag-grid-community/styles/ag-grid.css';
// = import 'ag-grid-community/styles/ag-theme-alpine.css';
// as it will result in broken styles in components where ag-grid is used.
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import App from '@/App';
import { Mixpanel } from '@/Mixpanel';
import { store } from '@/redux/store';
import { createRoot } from 'react-dom/client';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { getBaseUrl, postToLogoutChannel } from '@/Utils/utils';
import ViewportProvider from '@/Components/ViewportProvider/ViewportProvider';

import config from './auth_config.json';
import { setQueryParams } from './Utils/setQueryParams';

import 'react-toastify/dist/ReactToastify.css';
import './tailwind.css';
import './index.scss';
import { COMMIT_ID } from '@/config';
import { setCommitId } from '@/redux/reducers/buildSlice';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-038746 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Gigasheet, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Gigasheet )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Gigasheet )_need_to_be_licensed___( Gigasheet )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 22 April 2024 )____[v2]_MTcxMzc0MDQwMDAwMA==4825086afd4fc8a7e8db72778053c8f9'
);
const baseUri = getBaseUrl('webapp');
const url = getBaseUrl('api');

const healthCheck = async () => {
  const requestOptions = { method: 'GET' };
  try {
    const response = await fetch(`${url}/healthcheck`, requestOptions);
    if (response.status !== 200) {
      response.text().then((data) => Mixpanel.track('healthcheck', { errorMessage: data }));
    }
  } catch (e) {
    Mixpanel.track('healthcheck', { errorMessage: 'Health check call failed' });
  }
};

const isRanByCypress = () => {
  return (
    localStorage.getItem('cypressGigasheetAPIToken') !== null &&
    getBaseUrl('webapp') === 'http://localhost:3000'
  );
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRedirectCallback = (appState) => {
    const startLogoutSequence = () => {
      postToLogoutChannel();
    };
    if (location.hash.includes('error') || location.search.includes('error')) {
      startLogoutSequence();
    } else {
      if (appState?.referrerId) {
        localStorage.setItem('signup_referrer', appState?.referrerId);
      }
      healthCheck();
      const returnTo = isRanByCypress()
        ? localStorage.getItem('cypressCallbackUrl')
        : appState?.returnTo || window.location.pathname;

      if (appState?.c) {
        setQueryParams({ c: appState?.c });
      } else {
        if (appState?.authStateNonce === localStorage.getItem('authStateNonce')) {
          navigate(returnTo);
        } else {
          startLogoutSequence();
        }
      }
    }
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

store.dispatch(setCommitId(COMMIT_ID));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={config.visibleDomain}
      clientId={config.clientId}
      redirectUri={baseUri}
      scope={config.scope}
      audience={`${config.auth0IdentityURL}/userinfo`}
    >
      <Provider store={store}>
        <ViewportProvider>
          <App />
        </ViewportProvider>
      </Provider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
);
