import { useCallback, useEffect, useMemo, useState } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { Button } from '@/Components/UI/Button/Button';
import { Select } from '@/Components/UI/Form/Select/Select';
import { addMixpanelEvent } from '@/redux/reducers/sharedSlice';
import {
  changeIndividualColumnState,
  setExpandedNodes,
  setGroupedNodeState,
  updateGroupedNodeState,
  removeGroupedNodeState,
} from '@/redux/reducers/spreadsheetSlice';
import Tooltip from '@/Components/UI/Tooltip/Tooltip';
import { convertExpandedNodesToGroupedNodeState } from '@/helpers/spreadsheetHelper';
import { flushSync } from 'react-dom';
import { datasetSliceName, spreadsheetSliceName, userDataSliceName } from '@/redux/constants';
import { useCurrentFile } from '@/hooks/useCurrentFile';
import { showModal } from '@/redux/reducers/modalsSlice';
import { MODAL_EXCEEDS_LIMITS, MODAL_UPGRADE_REQUIRED } from '@/Utils/constants';

const DEFAULT_COLUMN_OPTION = { value: '', field: '', label: '', type: '' };

const GroupBy = () => {
  const dispatch = useDispatch();

  const { columnDefs, clientState } = useSelector((state) => state[spreadsheetSliceName]);
  const { user } = useSelector((state) => state[userDataSliceName]);
  const currentFile = useCurrentFile();
  const { fileName } = useSelector((state) => {
    const currentFile = state[datasetSliceName]?.currentFile || {};
    return currentFile?.metadata;
  }, shallowEqual);

  const [groupData, setGroupData] = useState([DEFAULT_COLUMN_OPTION]);
  const [dateFunctionMap, setDateFunctionMap] = useState({});

  useEffect(() => {
    let newDateFunctionMap = {};
    clientState.columnState
      .filter((col) => col.groupFunc)
      .forEach((col) => {
        newDateFunctionMap = { ...newDateFunctionMap, [col.colId]: col.groupFunc };
      });
    setDateFunctionMap(newDateFunctionMap);
  }, [clientState.columnState]);

  const [groupedColumns, unGroupedColumns] = useMemo(() => {
    const grouped = [];
    const unGrouped = [];

    columnDefs.forEach((colDef) => {
      const correspondingColumnState = clientState.columnState.find(
        (state) => state.colId === colDef.field
      );
      const columnData = {
        value: colDef.field,
        label: colDef.headerName,
        field: colDef.field,
        type: colDef.fieldType,
      };

      if (!correspondingColumnState || !correspondingColumnState.rowGroup) {
        unGrouped.push(columnData);
      } else {
        grouped.push({ ...columnData, rowGroupIndex: correspondingColumnState.rowGroupIndex });
      }
    });

    grouped.sort((a, b) => a.rowGroupIndex - b.rowGroupIndex);
    return [grouped, unGrouped];
  }, [clientState.columnState, columnDefs]);

  const addNewColumn = useCallback(() => {
    if ((!currentFile?.WithinQuota || currentFile?.OverRowQuota) && groupData.length >= 1) {
      return dispatch(
        showModal({
          name:
            currentFile?.metadata?.Owner === user?.email || currentFile?.OverRowQuota
              ? MODAL_UPGRADE_REQUIRED
              : MODAL_EXCEEDS_LIMITS,
        })
      );
    }
    if (groupData.every((column) => column.field !== '')) {
      setGroupData((prevData) => [...prevData, DEFAULT_COLUMN_OPTION]);
    }
  }, [groupData]);

  const addGroupingByColumn = useCallback(
    (prevColField, prevColId, rowGroupIndex) => {
      if (prevColId) {
        dispatch(
          changeIndividualColumnState({
            colId: prevColId,
            rowGroup: false,
            rowGroupIndex: null,
            hide: false,
          })
        );
      }
      if (prevColField) {
        dispatch(
          changeIndividualColumnState({
            colId: prevColField,
            rowGroup: true,
            rowGroupIndex,
            hide: true,
          })
        );
      }

      dispatch(
        addMixpanelEvent({
          eventName: 'Group By Used',
          eventProps: { Location: 'tools panel', current_filename: fileName },
          userIncrementName: '# of group by use',
        })
      );
    },
    [dispatch, fileName]
  );

  const addDateFunction = useCallback(
    (value, groupedColumn) => {
      setDateFunctionMap((prevMap) => ({ ...prevMap, [groupedColumn.field]: value }));
      dispatch(
        changeIndividualColumnState({
          colId: groupedColumn.field,
          hide: true,
          rowGroup: true,
          rowGroupIndex: groupedColumn.rowGroupIndex,
          groupFunc: value,
        })
      );
    },
    [dispatch]
  );

  const removeGroupingByColumn = useCallback(
    (colId) => {
      dispatch(
        changeIndividualColumnState({ colId, hide: false, rowGroup: false, rowGroupIndex: null })
      );
      dispatch(removeGroupedNodeState({ field: colId }));
    },
    [dispatch]
  );

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) return;

      const reorderedGroupData = Array.from(groupData);
      const [removed] = reorderedGroupData.splice(source.index, 1);
      reorderedGroupData.splice(destination.index, 0, removed);

      setGroupData(reorderedGroupData);

      reorderedGroupData.forEach((column, index) => {
        dispatch(changeIndividualColumnState({ colId: column.field, rowGroupIndex: index }));
      });
    },
    [dispatch, groupData]
  );

  const handleExpandAll = (field) => {
    if (clientState.expandedNodes.length) {
      const convertedGroupedNodeState = convertExpandedNodesToGroupedNodeState(clientState);
      flushSync(() => {
        dispatch(setGroupedNodeState(convertedGroupedNodeState));
        dispatch(setExpandedNodes([]));
      });
    }
    dispatch(
      updateGroupedNodeState({
        field,
        update: { defaultExpanded: true, collapsedNodes: [], expandedNodes: [] },
      })
    );
  };

  const handleCollapseAll = (field) => {
    if (clientState.expandedNodes.length) {
      const convertedGroupedNodeState = convertExpandedNodesToGroupedNodeState(clientState);
      flushSync(() => {
        dispatch(setGroupedNodeState(convertedGroupedNodeState));
        dispatch(setExpandedNodes([]));
      });
    }
    dispatch(
      updateGroupedNodeState({
        field,
        update: { defaultExpanded: false, collapsedNodes: [], expandedNodes: [] },
      })
    );
  };

  useEffect(() => {
    if (groupData.length === 0) {
      setGroupData([DEFAULT_COLUMN_OPTION]);
    }
  }, [groupData]);

  useEffect(() => {
    setGroupData(groupedColumns);
  }, [groupedColumns]);

  const dateFunctionOptions = [
    {
      value: '',
      label: 'Exact Date',
    },
    {
      value: 'year',
      label: 'Year',
    },
    {
      value: 'quarter',
      label: 'Quarter',
    },
    {
      value: 'month',
      label: 'Month',
    },
    {
      value: 'dayOfMonth',
      label: 'Day of Month',
    },
    {
      value: 'dayOfWeek',
      label: 'Day of Week',
    },
    {
      value: 'weekOfYear',
      label: 'Week of Year',
    },
    {
      value: 'hour',
      label: 'Hour',
    },
    {
      value: 'minute',
      label: 'Minute',
    },
    {
      value: 'second',
      label: 'Second',
    },
  ];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-area'>
        {(provided) => (
          <div
            className='flex flex-col items-start pb-[10px] ml-[6px]'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {groupData.map((groupedColumn, index) => {
              const columnState = clientState.groupedNodeState?.[groupedColumn.field] || {};
              const { defaultExpanded, collapsedNodes = [], expandedNodes = [] } = columnState;

              const isExpandDisabled = defaultExpanded && collapsedNodes.length === 0;
              const isCollapseDisabled = !defaultExpanded && expandedNodes.length === 0;

              return (
                <div key={groupedColumn.field || `group-${index}`}>
                  <Draggable
                    key={groupedColumn.field || `draggable-${index}`}
                    draggableId={groupedColumn.field || index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='flex items-center w-full mb-[8px]'
                      >
                        <div className='mr-3 w-[320px]'>
                          <Select
                            placeholder='Column'
                            dataCy={`group-by-${groupedColumn.label}`}
                            value={groupedColumn.value}
                            onChange={(value) =>
                              addGroupingByColumn(value, groupedColumn.field, index)
                            }
                            options={unGroupedColumns}
                            excludedOptions={groupedColumns}
                          />
                        </div>
                        <div className='flex items-center gap-2' data-cy={`group-by-row-${index}`}>
                          <p className='flex items-center font-overpass text-[15px] font-normal leading-[24px] text-[#424242] cursor-grab before:content-moveGrabber before:h-[18px] before:w-[18px] before:mr-[3px] hover:before:content-moveGrabberHover' />
                          {groupedColumn && groupedColumn.field && (
                            <>
                              <Tooltip text='Expand All' side='top' asChild>
                                <Button
                                  onClick={() => handleExpandAll(groupedColumn.field)}
                                  color='oceanBlue'
                                  size='large'
                                  variant='outline'
                                  iconName='arrows-out-simple'
                                  dataCy='expand-all-button'
                                  disabled={isExpandDisabled}
                                />
                              </Tooltip>
                              <Tooltip text='Collapse All' side='top' asChild>
                                <Button
                                  onClick={() => handleCollapseAll(groupedColumn.field)}
                                  color='oceanBlue'
                                  size='large'
                                  variant='outline'
                                  iconName='arrows-in-simple'
                                  dataCy='collapse-all-button'
                                  disabled={isCollapseDisabled}
                                />
                              </Tooltip>
                            </>
                          )}
                          <Button
                            dataCy='remove-group-btn'
                            disabled={index === 0 && !groupedColumn.field}
                            onClick={() => removeGroupingByColumn(groupedColumn.field)}
                            color='shadow'
                            variant='ghost'
                            size='large'
                            iconName='x'
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                  {groupedColumn.type === 'DateTime64' && (
                    <div className='flex w-full mb-3'>
                      <div className='mr-4 font-overpass text-sm font-normal leading-6 text-gray-700 h-[13px]'>
                        Date function
                      </div>
                      <div className='w-[122px]'>
                        <Select
                          size='small'
                          dataCy={'group-by-date-function'}
                          options={dateFunctionOptions}
                          value={
                            dateFunctionMap[groupedColumn.field] || dateFunctionOptions[0].value
                          }
                          onChange={(value) => addDateFunction(value, groupedColumn)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {provided.placeholder}
            <Button
              onClick={addNewColumn}
              size='small'
              variant='ghost'
              iconName='plus-circle'
              color='oceanBlue'
              className='mt-1 text-sm'
              dataCy='group-by-add-column-btn'
            >
              <span className='text-sm'>Add Group</span>
            </Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default GroupBy;
