import { Button, Link } from '@/Components/UI/Button/Button';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { datasetSliceName, userDataSliceName } from '@/redux/constants';
import {
  refreshSearchResult,
  selectFile,
  selectMultipleFiles,
  setCurrentDataset,
  setIsInvalidFolderHandle,
  setIsLoadingLibrary,
  setSortingData,
} from '@/redux/reducers/datasetSlice';
import { showModal } from '@/redux/reducers/modalsSlice';

import { put } from '@/Utils/API';
import {
  LOCAL_STORAGE_CHECKOUT_PERIOD,
  LOCAL_STORAGE_IMPORT_PROVIDER,
  MODAL_CHECKOUT,
  MODAL_DELETE_FILE,
  MODAL_UPGRADE_ACCOUNT,
  MY_LIBRARY,
  QUERY_PARAM_CHECKOUT,
  SESSION_EXPIRED_OR_UNAUTHORIZED,
  SHARED_WITH_ME,
  TOAST_TEXT_ADD_FILE_TO_FOLDER_ERROR,
  TOAST_TYPE_ERROR,
  URL_DATASETS,
  sortDirection,
  upgradeAccountTypes,
  userRolesKey,
  userTeamKey,
  userTeamNameKey,
} from '@/Utils/constants';
import { FILE_PROCESSING_STATE, FILE_STATUS_PROCESSING } from '@/Utils/fileConstants';
import { getFolderStatus } from '@/Utils/getFolderStatus';
import { selectNestedFiles, unselectNestedFiles } from '@/Utils/selectionOfNestedFiles';
import { mapUserRoles } from '@/Utils/userConversion/userConversion';
import { getSelectionRange } from '@/Utils/utils';

import useViewportProvider from '@/hooks/useViewportProvider';

import DatasetSidePanel from '@/pages/DatasetPage/DatasetSidePanel/DatasetSidePanel';
import EmptyFiles from '@/pages/DatasetPage/EmptyFiles/EmptyFiles';
import HeaderControlGroup from '@/pages/DatasetPage/HeaderControlGroup/HeaderControlGroup';
import LoadingOverlayReact from '@/pages/DatasetPage/LoadingOverlay';
import { MobileRow } from '@/pages/DatasetPage/MobileRow';
import ProgressBar from '@/pages/DatasetPage/ProgressBar/ProgressBar';
import { Row } from '@/pages/DatasetPage/Row';
import SkeletonLoader from '@/pages/DatasetPage/SkeletonLoader/SkeletonLoader';
import NoSearchResults from './NoSearchResults/NoSearchResults';

import AccessForm from '@/Components/AccessForm/AccessForm';
import MetaTag from '@/Components/MetaTag';
import MobilePath from '@/Components/MobilePath/MobilePath';
import withUploadModal from '@/Components/Modals/withUploadModal';
import showToast from '@/Components/Toast/showToastTemplate';
import Icon from '@/Components/UI/Icon/Icon';
import MobileDropMenu from '@/Components/UI/MobileDropMenu/MobileDropMenu';
import { Tabs } from '@/Components/UI/Tabs/Tabs';

import { clearCheckoutLocaleStorage } from '@/Components/Modals/Checkout/Checkout';
import { moveToDirEndpoint } from '@/Utils/apiEndpoints';
import { areFilesLoading, getTeamNameLabel } from '@/helpers/datasetsHelper';
import ImportPage from '@/pages/ImportPage/ImportPage';
import FileStatus from './ProgressBar/FileStatus';
import { getWebSocketCategory } from './WebSocketLibrary/WebSocketContainer';
import {
  WEBSOCKET_CATEGORY_LIBRARY,
  WEBSOCKET_CATEGORY_SHARED_WITH_ME,
  useWebSocketContext,
} from './WebSocketLibrary/WebSocketContext';
import './style/index.scss';

export const Datasets = ({ openUploadModal }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { sendMessage } = useWebSocketContext();
  const [isMobileDropMenuOpen, setIsMobileDropMenuOpen] = useState(false);
  const [isDraggingRow, setIsDraggingRow] = useState(false);
  const [isParentFolderShared, setIsParentFolderShared] = useState(false);
  const [mobileActiveTabIndex, setMobileActiveTabIndex] = useState(0);

  const {
    error,
    loading,
    filePath,
    currentDir,
    sortingData,
    selectedFiles,
    libraryFiles,
    sharedWithMeFiles,
    sharedWithMyTeamFiles,
    isLoadingLibrary,
    currentFile,
    librarySearchResult,
    currentDataset,
    isInvalidFolderHandle,
    isUserHasSharedFolderAccess,
  } = useSelector((state) => state[datasetSliceName]);

  const { user, subscriptions } = useSelector((state) => state[userDataSliceName]);

  const { isMobileScreen } = useViewportProvider();
  const prevFilePath = useRef(null);
  const isSharedWithMyTeamTabActive = currentDataset === getTeamNameLabel(user[userTeamNameKey]);
  const datasetFilesMap = {
    [MY_LIBRARY]: libraryFiles,
    [SHARED_WITH_ME]: sharedWithMeFiles,
    [getTeamNameLabel(user[userTeamNameKey])]: sharedWithMyTeamFiles,
  };

  useEffect(() => {
    let isMounted = true;
    const currentFilePath = filePath.length;

    const debounceTimeout = setTimeout(() => {
      if (prevFilePath.current !== currentFilePath) {
        getFolderStatus(currentDir, filePath, currentFile).then((response) => {
          if (isMounted) {
            prevFilePath.current = currentFilePath;
            setIsParentFolderShared(response);
          }
        });
      }
    }, 300);

    return () => {
      clearTimeout(debounceTimeout);
      isMounted = false;
    };
  }, [currentDir, filePath, libraryFiles, currentFile]);

  useEffect(() => {
    document.title = 'Library - Gigasheet';

    // loaded dragging row 'file' icon
    const draggingElement = document.createElement('div');
    draggingElement.classList.add('dragging-element');
    document.body.appendChild(draggingElement);

    if (user?.[userRolesKey]?.length > 0) {
      window.userGuiding?.identify(user.email, {
        email: user.email,
        ...mapUserRoles(user[userRolesKey]),
      });
    }
  }, []);

  useEffect(() => {
    if (isLoadingLibrary) {
      dispatch(setIsLoadingLibrary(false));
    }
  }, [libraryFiles]);

  useEffect(() => {
    if (error === SESSION_EXPIRED_OR_UNAUTHORIZED) {
      // eslint-disable-next-line no-restricted-globals
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    const subscriptionsLoaded = subscriptions;
    const checkoutRequested = localStorage.getItem(LOCAL_STORAGE_CHECKOUT_PERIOD);
    const checkoutInProgress = searchParams.has(QUERY_PARAM_CHECKOUT);
    const hasActiveSubscriptions = subscriptions?.length > 0;

    if (subscriptionsLoaded && (checkoutRequested || checkoutInProgress)) {
      if (hasActiveSubscriptions && !checkoutInProgress) {
        clearCheckoutLocaleStorage();
        dispatch(
          showModal({
            name: MODAL_UPGRADE_ACCOUNT,
            props: { upgradeType: upgradeAccountTypes.checkout },
          })
        );
      } else {
        dispatch(showModal({ name: MODAL_CHECKOUT }));
      }
    }
  }, [subscriptions]);

  const sortTable = (_columnName) => {
    const { direction, columnName } = sortingData;

    let _sortDirection = 1;
    if (columnName === _columnName) {
      if (direction === 1) {
        _sortDirection = -1;
      } else if (direction === -1) {
        _sortDirection = 1;
      }
    }

    dispatch(
      setSortingData({
        direction: _sortDirection,
        columnName: _columnName,
        currentDirectory: filePath[filePath?.length - 1]?.FileUuid || '',
      })
    );
    sendMessage({
      location: `${currentDir}`,
      category: getWebSocketCategory(currentDataset),
    });
    dispatch(refreshSearchResult({ showLoader: true }));
  };

  const createButton = (content, sortParameter) => {
    const { direction, columnName } = sortingData;
    return (
      <button
        data-cy={`library-${sortParameter}`}
        type='button'
        className={`${
          columnName === sortParameter
            ? direction === 1
              ? sortDirection.ascending
              : direction === -1
              ? sortDirection.descending
              : ''
            : ''
        }`}
        onClick={() => {
          sortTable(sortParameter);
        }}
      >
        {content}
        <div>
          <div className={'arrow-up'} />
          <div className={'arrow-down'} />
        </div>
      </button>
    );
  };

  const createMobileSortButton = (content, sortParameter) => {
    const { direction, columnName } = sortingData;
    return (
      <Button
        dataTestId='all-sorting-params'
        dataCy={`mobile-library-table-sorting-name-${content}`}
        className={'w-full !justify-start !text-ui relative'}
        variant='ghost'
        onClick={() => {
          sortTable(sortParameter);
        }}
        iconName={
          columnName === sortParameter
            ? direction === 1
              ? 'arrow-up'
              : direction === -1
              ? 'arrow-down'
              : ''
            : ''
        }
        size='large'
      >
        <span
          data-cy={`mobile-content-sort-name-${content}`}
          className={clsx(columnName !== sortParameter && 'pl-4')}
        >
          {content}
        </span>
        {columnName === sortParameter && (columnName === 'FileName' || columnName === 'Owner') && (
          <span data-cy='mobile-content-sort-text' className='absolute right-2 top-2'>
            (A-Z)
          </span>
        )}
        {columnName === sortParameter &&
          (columnName === 'LastUpdated' ||
            columnName === 'FileSize' ||
            columnName === 'FileColumns' ||
            columnName === 'FileRows') && (
            <span data-cy='mobile-content-sort-number' className='absolute right-2 top-2'>
              (1-9)
            </span>
          )}
      </Button>
    );
  };

  const getColumnDisplayValue = (columnName) => {
    switch (columnName) {
      case 'FileName':
        return 'File Name';
      case 'FileRows':
        return 'Rows';
      case 'FileColumns':
        return 'Cols';
      case 'FileSize':
        return 'Size';
      case 'LastUpdated':
        return 'Last Modified';
      case 'Owner':
        return 'Owner';
      default:
        return '';
    }
  };

  const onChange = (event, targetFile) => {
    event.stopPropagation();

    if (!event.nativeEvent.shiftKey) {
      if (event.target.checked) return selectNestedFiles(targetFile);
      return unselectNestedFiles(targetFile);
    }

    const lastSelectedFile = selectedFiles[selectedFiles.length - 1];

    if (!lastSelectedFile) return dispatch(selectFile(targetFile));

    const allFiles = datasetFilesMap[currentDataset]
      .reduce((acc, file) => {
        if (file.metadata.Children && Symbol.iterator in Object(file.metadata.Children)) {
          return [...acc, file.metadata, ...file.metadata.Children];
        } else {
          return [...acc, file.metadata];
        }
      }, [])
      .map((file, fileIdx) => ({ ...file, fileIdx }));

    const lastSelectedFileIdx = allFiles.findIndex(
      (file) => file.FileUuid === lastSelectedFile?.FileUuid
    );
    const currentSelectedFileIdx = allFiles.findIndex(
      (file) => file.FileUuid === targetFile.FileUuid
    );

    if (lastSelectedFileIdx === -1 || currentSelectedFileIdx === -1) {
      // Handle if lastSelectedFile or targetFile not found in allFiles array
      return;
    }

    const incrementor =
      targetFile.IsDirectory && targetFile.Children ? targetFile.Children.length : 0;

    const [start, end] = getSelectionRange(lastSelectedFileIdx, currentSelectedFileIdx);

    const _selectedFiles = allFiles.slice(start, end + incrementor);

    dispatch(selectMultipleFiles(_selectedFiles));
  };

  const dropFile = async (fileUuid, folderUuid) => {
    const endpoint = moveToDirEndpoint(fileUuid);
    const payload = {
      dest_dir_handle: `${folderUuid}`,
    };
    const response = await put(endpoint, payload);
    if (response && response.Success) {
      sendMessage({
        location: `${currentDir}`,
        category: getWebSocketCategory(currentDataset),
      });
    } else {
      showToast({
        type: TOAST_TYPE_ERROR,
        text: TOAST_TEXT_ADD_FILE_TO_FOLDER_ERROR,
        errorContext: response,
        endpoint: endpoint,
        payload: payload,
        fileAndFunction: '__FILE_AND_FUNCTION_NAME__',
      });
    }
  };

  const onDrop = (e, row) => {
    const files = JSON.parse(e.dataTransfer.getData('file'));
    if (row.IsDirectory) {
      files.map((element) => {
        if (element.FileUuid === row.FileUuid) {
          return;
        }
        const selectedFile = document.getElementById(`${element.FileUuid}checkBox`);
        if (selectedFile?.checked) {
          selectedFile.click();
        }
        return dropFile(element.FileUuid, row.FileUuid);
      });
      e.stopPropagation();
    } else if (row.IsDirectory === false && row.ParentDirectory !== '') {
      files.map((element) => {
        const selectedFile = document.getElementById(`${element.FileUuid}checkBox`);
        if (selectedFile?.checked) {
          selectedFile.click();
        }
        return dropFile(element.FileUuid, row.ParentDirectory);
      });
      e.stopPropagation();
    }
    return setIsDraggingRow(false);
  };

  const onDragStart = (e, row) => {
    const selectedFile = document.getElementById(`${row.FileUuid}checkBox`);
    if (!selectedFile.checked) {
      selectedFile.click();
    }
    const transferItem = e.dataTransfer;
    const transferArray = [...selectedFiles, row];

    const topLevelParentDirectory = libraryFiles[0].metadata.ParentDirectory;

    const uuidArray = transferArray
      .filter(({ ParentDirectory }) => ParentDirectory === topLevelParentDirectory)
      .map((file) => file.FileUuid);
    const uniqueIdArray = [...new Set(uuidArray)];
    const filesAmount = uniqueIdArray.length;

    const draggingElement = document.createElement('div');
    draggingElement.classList.add('dragging-element');
    draggingElement.innerHTML =
      filesAmount === 1 ? `Move ${filesAmount} file` : `Move ${filesAmount} files`;
    document.body.appendChild(draggingElement);

    transferItem.setDragImage(draggingElement, 100, 50);
    transferItem.setData('file', JSON.stringify(transferArray));
    transferItem.effectAllowed = 'move';
    setIsDraggingRow(true);
  };

  const onDragOver = (event) => {
    event.preventDefault();

    if (isDraggingRow) {
      // linter shows issue, why empty, don`t know
    } else {
      openUploadModal();
    }
  };

  const SortDropdownButton = () => {
    const { columnName, direction } = sortingData;
    const properColumnName =
      columnName === '' ? 'Last Modified' : getColumnDisplayValue(columnName);
    return (
      <Button
        dataCy={`mobile-sort-by-btn-${properColumnName}`}
        onClick={() => {
          setIsMobileDropMenuOpen(true);
        }}
        variant='outline'
        iconName={direction === 1 ? 'arrow-up' : direction === -1 ? 'arrow-down' : 'arrow-up'}
        className='!w-full relative !justify-start !text-ui mb-2'
      >
        {properColumnName}
        <Icon
          dataCy='caret-down'
          name='caret-down'
          className='absolute inline-block right-3 top-2'
        />
      </Button>
    );
  };

  const loadingStatus = areFilesLoading(datasetFilesMap[currentDataset]);

  const LibraryContent = () => {
    return (
      <>
        <SortDropdownButton />
        {Array.isArray(librarySearchResult) && !librarySearchResult.length ? (
          <NoSearchResults />
        ) : !libraryFiles?.length ? (
          <EmptyFiles />
        ) : (
          (librarySearchResult ? librarySearchResult : libraryFiles)?.map((row, indx) => {
            return (
              <React.Fragment key={row.metadata.FileUuid}>
                {!FILE_PROCESSING_STATE.includes(row.metadata.Status) ||
                row.metadata.IsDirectory ? (
                  <MobileRow
                    rowIndex={indx}
                    key={row.metadata.FileUuid}
                    row={row}
                    isAnonymous={false}
                    isSharedParentFolder={isParentFolderShared}
                  />
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </>
    );
  };

  const SharedWithMeContent = () => {
    return (
      <>
        <SortDropdownButton />
        {!sharedWithMeFiles?.length ? (
          <EmptyFiles />
        ) : (
          sharedWithMeFiles?.map((row, indx) => {
            return (
              <React.Fragment key={row.metadata.FileUuid}>
                {row.metadata.Status !== FILE_STATUS_PROCESSING || row.metadata.IsDirectory ? (
                  <MobileRow
                    rowIndex={indx}
                    key={row.metadata.FileUuid}
                    row={row}
                    isAnonymous={false}
                  />
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </>
    );
  };

  const TeamWorkspaceContent = () => {
    return (
      <>
        <SortDropdownButton />
        {!sharedWithMyTeamFiles?.length ? (
          <EmptyFiles />
        ) : (
          sharedWithMyTeamFiles?.map((row, indx) => {
            return (
              <React.Fragment key={row.metadata.FileUuid}>
                {row.metadata.Status !== FILE_STATUS_PROCESSING || row.metadata.IsDirectory ? (
                  <MobileRow
                    rowIndex={indx}
                    key={row.metadata.FileUuid}
                    row={row}
                    isAnonymous={false}
                  />
                ) : null}
              </React.Fragment>
            );
          })
        )}
      </>
    );
  };

  const onMobileTabChange = (index) => {
    if (index === 0) {
      sendMessage({ location: '', category: WEBSOCKET_CATEGORY_LIBRARY });
      dispatch(setCurrentDataset(MY_LIBRARY));
    }
    if (index === 1) {
      sendMessage({ location: '', category: WEBSOCKET_CATEGORY_SHARED_WITH_ME });
      dispatch(setCurrentDataset(SHARED_WITH_ME));
    }
    //prevented as in prod for now.
    if (index === 2) {
      return;
      // sendMessage({ location: '', category: WEBSOCKET_CATEGORY_SHARED_WITH_TEAM });
      // dispatch(setCurrentDataset(getTeamNameLabel(user[userTeamNameKey])));
    }
    setMobileActiveTabIndex(index);
  };

  const MobileTabs = () => {
    const tabs = [
      { label: librarySearchResult ? 'Search Results' : 'Library', content: LibraryContent },
    ];

    if (!librarySearchResult) {
      tabs.push({ label: 'Shared With Me', content: SharedWithMeContent });

      if (user[userTeamKey]) {
        tabs.push({
          label: getTeamNameLabel(user[userTeamNameKey]),
          content: TeamWorkspaceContent,
        });
      }
    }

    return (
      <Tabs tabs={tabs} selectedIndex={mobileActiveTabIndex} onTabChange={onMobileTabChange} />
    );
  };

  if (!isUserHasSharedFolderAccess) {
    return <AccessForm />;
  }

  if (error) {
    throw new Error(error);
  }

  if (!libraryFiles || !user || (isSharedWithMyTeamTabActive && !sharedWithMeFiles)) {
    return <SkeletonLoader />;
  } else if (localStorage.getItem(LOCAL_STORAGE_IMPORT_PROVIDER) !== null) {
    return <ImportPage />;
  } else {
    return isInvalidFolderHandle ? (
      <div className='flex items-center justify-center w-full h-full'>
        <div>
          <div>Bummer, that &apos;s not a valid folder</div>
          <div className='block text-center mt-[10px]'>
            <Link
              onClick={() => {
                sendMessage({
                  location: '',
                  category: WEBSOCKET_CATEGORY_LIBRARY,
                });
                dispatch(setIsInvalidFolderHandle(false));
              }}
              color='oceanBlue'
              size='large'
              className='font-semibold underline'
              to={`/${URL_DATASETS}`}
            >
              Return to Library
            </Link>
          </div>
        </div>
      </div>
    ) : (
      <div className='datasets transformAnimation fadeIn'>
        <MetaTag documentTitle={'Library'} />

        <DatasetSidePanel openUploadModal={openUploadModal} />

        <div className='flex flex-col w-full p-4 pb-0 overflow-hidden overflow-x-auto bg-ui'>
          <HeaderControlGroup
            libraryContent={datasetFilesMap[currentDataset]}
            openUploadModal={openUploadModal}
          />
          <div className='w-full'>
            {loadingStatus.areFilesLoading ? (
              <React.Fragment>
                <ProgressBar percentCompleted={loadingStatus.singleFileLoadingStatus} />
              </React.Fragment>
            ) : null}
          </div>

          {(loading || isLoadingLibrary) && <LoadingOverlayReact />}
          <div id='dropdiv' className='hidden grow md:block table-wrapper' onDragOver={onDragOver}>
            {Array.isArray(librarySearchResult) && !librarySearchResult.length ? (
              <NoSearchResults />
            ) : !datasetFilesMap[currentDataset]?.length ? (
              <EmptyFiles />
            ) : (
              <table className='table w-full'>
                <thead>
                  <tr
                    className={clsx(
                      'border-x border-ui-200 bg-ui-secondary',
                      currentDataset !== SHARED_WITH_ME &&
                        currentDataset !== 'Library' &&
                        'border-t'
                    )}
                  >
                    <th>{createButton('File Name', 'FileName')}</th>
                    <th>{createButton('Rows', 'FileRows')}</th>
                    <th>{createButton('Cols', 'FileColumns')}</th>
                    <th>{createButton('Size', 'FileSize')}</th>
                    <th>{createButton('Last Modified', 'LastUpdated')}</th>
                    <th>{createButton('Owner', 'Owner')}</th>
                    <th />
                  </tr>
                </thead>

                <tbody className='border !rounded-b border-ui-secondary border-ui-200'>
                  {datasetFilesMap[currentDataset]?.map((row) => {
                    return (
                      <React.Fragment key={row.metadata.FileUuid}>
                        {FILE_PROCESSING_STATE.includes(row.metadata.Status) ? (
                          <tr className=''>
                            <th colSpan='7'>
                              <FileStatus
                                row={row.metadata}
                                cancelUpload={(row) =>
                                  dispatch(
                                    showModal({
                                      name: MODAL_DELETE_FILE,
                                      props: {
                                        filesToDelete: [row],
                                      },
                                    })
                                  )
                                }
                              />
                            </th>
                          </tr>
                        ) : null}
                      </React.Fragment>
                    );
                  })}

                  {(librarySearchResult
                    ? librarySearchResult
                    : datasetFilesMap[currentDataset]
                  )?.map((row) => {
                    const { metadata } = row;
                    return (
                      <React.Fragment key={metadata.FileUuid}>
                        {!FILE_PROCESSING_STATE.includes(metadata.Status) ||
                        metadata.IsDirectory ? (
                          <Row
                            key={metadata.FileUuid}
                            row={row}
                            selectedFilesData={selectedFiles}
                            onCheckboxChange={onChange}
                            onDrop={onDrop}
                            onDragStart={onDragStart}
                            isDraggingRow={isDraggingRow}
                            isAnonymous={false}
                            isSharedParentFolder={isParentFolderShared}
                            hideCheckbox={librarySearchResult}
                          />
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {isMobileScreen && (
            <div data-cy='mobile-library-tabs-wrapper' className='relative block md:hidden'>
              {!!filePath.length && (
                <div className='absolute top-0 left-0 right-0 border-b border-gray-200 bg-ui'>
                  <MobilePath selectedMobileTab={mobileActiveTabIndex} />
                </div>
              )}
              {MobileTabs()}
            </div>
          )}
        </div>

        <MobileDropMenu
          isOpen={isMobileDropMenuOpen}
          onClose={() => setIsMobileDropMenuOpen(false)}
        >
          <p data-cy='mobile-sort-by-text' className='px-3 py-2 font-semibold border-b'>
            Sort By
          </p>
          {createMobileSortButton('File Name', 'FileName')}
          {createMobileSortButton('Rows', 'FileRows')}
          {createMobileSortButton('Cols', 'FileColumns')}
          {createMobileSortButton('Size', 'FileSize')}
          {createMobileSortButton('Last Modified', 'LastUpdated')}
          {createMobileSortButton('Owner', 'Owner')}
        </MobileDropMenu>
      </div>
    );
  }
};

export default withUploadModal(Datasets);
