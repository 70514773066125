import Icon from '../UI/Icon/Icon';
import UpgradeButton from '../Modals/UpgradeAccount/UpgradeButton';

type UpgradeMessagePropsTypes = {
  text?: string;
};

const UpgradeMessage = ({
  text = 'to make a copy with filters and groups',
}: UpgradeMessagePropsTypes) => {
  return (
    <div className='flex rounded border border-pear-900 bg-pear-100 p-2 text-sm mb-4'>
      <div>
        <Icon name='rocket-launch' color='#6D741B' className='mr-2' />
      </div>
      <div>
        <UpgradeButton className='!underline !p-0 !h-min !bg-transparent mr-1 !text-pear-900 !font-bold'>
          Upgrade
        </UpgradeButton>
        <span className='font-bold '>{text}</span> or remove filters and groups to proceed.
      </div>
    </div>
  );
};

export default UpgradeMessage;
