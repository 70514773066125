import Modal from '@/Components/UI/Modal/Modal';
import GroupBy from './GroupBy';

import { MODAL_GROUP_BY } from '@/Utils/constants';
import { TemporaryLabel } from '@/helpers/upgradeHelper';
import withModal from '@/Components/Modals/withModalHOC';
import { useCurrentFile } from '@/hooks/useCurrentFile';

const GroupByModal = ({ show, hideModal }) => {
  const currentFile = useCurrentFile();
  const { isFileShared, WithinQuota } = currentFile || {};

  const closeGroupByModal = () => {
    hideModal();
  };

  return (
    <Modal
      isOpen={show}
      onClose={closeGroupByModal}
      title={
        <div className='flex flex-row'>
          <h4>Group</h4>
          {(isFileShared || !WithinQuota) && <TemporaryLabel />}
        </div>
      }
      iconName='group'
      size='medium'
      modalClassName='!w-[530px]'
      hasQuestionMark={true}
      articleID={69000774343}
      modalTitleClasses='mt-0'
    >
      <GroupBy show={show} />
    </Modal>
  );
};

export default withModal({ name: MODAL_GROUP_BY })(GroupByModal);
