import clsx from 'clsx';

import { Button } from '@/Components/UI/Button/Button';
import Icon from '@/Components/UI/Icon/Icon';

const MoveFileRow = ({
  fileUuid,
  fileName,
  isSelected,
  setSoftSelectedFileDirectory,
  setSelected,
  setSelectedFileDirectory,
  folderIcon = 'folder-filled',
}) => {
  return (
    <div
      role='button'
      tabIndex={0}
      onKeyDown={() => null}
      className={clsx(
        'flex items-center justify-between border-b cursor-pointer border-ui text-ui h-9 typography-body',
        isSelected(fileUuid) && 'bg-midnight-100'
      )}
      onClick={() => {
        setSoftSelectedFileDirectory(fileUuid);
      }}
      data-cy={`move-file-dropdown-${fileName}`}
    >
      <span className='flex items-center justify-center h-8 gap-3 px-2'>
        <input
          type='radio'
          name='move-file-option'
          className='w-4 h-4 accent-midnight'
          value={fileUuid}
          checked={isSelected(fileUuid)}
          onChange={() => setSoftSelectedFileDirectory(fileUuid)}
        />
        <Icon name={folderIcon} size={18} />
        <span className='mt-1'>{fileName}</span>
      </span>
      <Button
        color='shadow'
        variant='ghost'
        iconName='caret-right'
        onClick={(event) => {
          event.stopPropagation();
          setSelected(fileName);
          setSelectedFileDirectory(fileUuid);
          setSoftSelectedFileDirectory(null);
        }}
        className='!inline-flex'
        dataCy={`move-file-dropdown-${fileName}-btn`}
      />
    </div>
  );
};

export default MoveFileRow;
