import { useEffect, useState } from 'react';

import Icon from '@/Components/UI/Icon/Icon';

import './style/index.scss';

const CustomMultiSelect = ({ initialColumns, updateSelectedColumns, defaultValue }) => {
  const initialAllColumns = defaultValue
    ? initialColumns.filter((el) => defaultValue && el.value !== defaultValue.value)
    : initialColumns;
  const initialSelectedValue = defaultValue ? [defaultValue] : [];
  const [allColumns, setAllColumns] = useState(initialAllColumns);
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedValue);

  useEffect(() => {
    updateSelectedColumns(selectedColumns);
  }, [selectedColumns, updateSelectedColumns]);

  const selectColumn = (column) => {
    setSelectedColumns([...selectedColumns, column]);
    setAllColumns(allColumns.filter((el) => el !== column));
  };

  const removeAllSelectedColumns = () => {
    setSelectedColumns([]);
    setAllColumns(initialColumns);
  };

  const compareColumnId = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  const removeColumn = (column) => {
    const sortColumnsArr = [...allColumns, column].sort(compareColumnId);
    setSelectedColumns(selectedColumns.filter((el) => el !== column));
    setAllColumns(sortColumnsArr);
  };

  const searchColumn = (event) => {
    const term = event.target.value;
    if (!term) {
      setAllColumns(initialColumns);
    }
    setAllColumns(
      initialColumns
        .filter((el) => !selectedColumns.some((col) => el.label === col.label))
        .filter((el) => el.label.toLowerCase().includes(term.toLowerCase()))
    );
  };

  return (
    <div className='custom-multiselect-container'>
      <div>
        <h4>Choose columns</h4>
        <div className='all-columns'>
          <span className='input-wrapper'>
            <input type='text' placeholder='Search...' onChange={(event) => searchColumn(event)} />
          </span>
          <div>
            {allColumns.map((column) => (
              <button key={column.value} type='button' onClick={() => selectColumn(column)}>
                {column.label}
                <span className='image-wrapper'>
                  <Icon name='plus' size={9} alt='plus-icon' className=''></Icon>
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div>
        <h4>Selected columns</h4>
        <div className='selected-columns'>
          <button
            type='button'
            className='remove-all-btn'
            onClick={() => removeAllSelectedColumns()}
          >
            Remove all
          </button>
          <div>
            {selectedColumns &&
              selectedColumns.map((column) => (
                <button key={column.value} type='button' onClick={() => removeColumn(column)}>
                  {column.label}
                  <span className='image-wrapper'>
                    <Icon name='x' size={9} alt='close-icon' className='text-midnight-600'></Icon>
                  </span>
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMultiSelect;
