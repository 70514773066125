import { createSlice } from '@reduxjs/toolkit';
import { modalsSliceName } from '../constants';

const initialState = {
  modalsQueue: [],
};

const modalsSlice = createSlice({
  name: modalsSliceName,
  initialState,
  reducers: {
    showModal(state, action) {
      const { name, props } = action.payload;
      state.modalsQueue.push({ name, props });
    },
    hideModal(state, action) {
      const { name } = action.payload;
      const modalIndexToDestroy = state.modalsQueue.findIndex(({ name: _name }) => name === _name);
      if (modalIndexToDestroy === -1) return;
      state.modalsQueue.splice(modalIndexToDestroy, 1);
    },
    hideAllModals(state) {
      state.modalsQueue = [];
    },
  },
});

export const { hideModal, showModal, hideAllModals } = modalsSlice.actions;

export default modalsSlice.reducer;
